// EPayPaymentType // ALL = -1, // UNKNOWN = 0, // CARI = 1, // CREDITCARD = 2, // CREDITCARD_NON_3D = 3, // SOFORT = 4, // IDEAL = 5

<template>
   <div id="paymentChoice-type-container">
      <template v-if="LoadingWithSpinner">
         <div class="loading-with-spinner-blocker"></div>
      </template>

      <div class="paymentChoice-information-boxes">
         <!-- Price Difference Alert -->
         <UIAlert status="info_red1" :rounded="false" v-if="typeof WarningLabel == 'string'">
            <span> {{ WarningLabel }}</span>
         </UIAlert>
         <UIAlert status="info_green" :rounded="false">
            <div class="paymentChoice-information-box-wrapper">
               <div class="paymentChoice-information-row" v-if="PaymentType == 1">
                  <span>{{ $t(`${this.$options.name}_lblRemainingBalance`) }} :</span>
                  <span class="paymentChoice-information-accent">{{ Balance | price(Currency) }}</span>
               </div>
               <div class="paymentChoice-information-row" v-if="CommissionEnabled">
                  <span>{{ $t(`${this.$options.name}_lblTotal`) }} :</span>
                  <span class="paymentChoice-information-accent">{{ Total | price(Currency) }} </span>
               </div>
               <div class="paymentChoice-information-row" v-if="CommissionEnabled">
                  <span>{{ $t(`${this.$options.name}_lblCommission`) }} :</span>
                  <span class="paymentChoice-information-accent">{{ Commission | price(Currency) }}</span>
               </div>
               <div
                  class="paymentChoice-information-row"
                  v-if="partialPaymentOrderDetailModel?.voucherDetail?.isPartialPaid && partialPaymentEnabled && partialPaymentOrderDetailModel?.voucherDetail?.status == 15">
                  <span>{{ $t(`${this.$options.name}_lblPartialPaymentDepositAmount`) }} :</span>
                  <span class="paymentChoice-information-accent">{{ partialPaymentModel.secondPaymentPrice | price(Currency) }}</span>
               </div>
               <div class="paymentChoice-information-row" v-if="partialPaymentEnabled && isChecked">
                  <span>{{ $t(`${this.$options.name}_lblPartialPaymentDepositAmount`) }} :</span>
                  <span class="paymentChoice-information-accent">{{ partialPaymentModel.firstPaymentPrice | price(Currency) }}</span>
               </div>
               <div class="paymentChoice-information-row">
                  <span>{{ $t(`${this.$options.name}_lblGrandTotal`) }} :</span>
                  <span class="paymentChoice-information-accent" v-if="partialPaymentOrderDetailModel?.voucherDetail?.status == 2">{{ GrandTotal | price(Currency) }}</span>
                  <span class="paymentChoice-information-accent" v-else>{{
                     partialPaymentOrderDetailModel?.voucherDetail?.isPartialPaid ? this.partialPaymentModel.secondPaymentPrice : GrandTotal | price(Currency)
                  }}</span>
               </div>

               <p v-if="CommissionEnabled" class="mt-3" v-html="this.$i18n.t(`${this.$options.name}_txtCommissionInfo_${paymentTypeLabel()}`)"></p>
            </div>
         </UIAlert>

         <!-- PriceDifference -->
         <UIAlert status="info_orange" :rounded="false" v-if="PriceDifferenceEnabled" class="price-difference-info-box">
            <slot>
               <UICheckBox v-model="priceDifferenceConfirmation" :label="PricedifferenceAmountPlaceholder()" id="price-difference-confirm" hotel />
            </slot>
         </UIAlert>

         <UIAlert v-if="Note && Note.length" status="info_yellow" :rounded="false">
            <span>{{ Note }}</span>
         </UIAlert>
      </div>
      <template>
         <GtsInfoWidget
            v-if="partialPaymentOrderDetailModel?.voucherDetail?.isPartialPaid && partialPaymentOrderDetailModel?.voucherDetail?.status == 15"
            :slotable="false"
            :slotIcon="'gts-icon icon-rounded-tick'"
            icon="gts-icon icon-partial-payment-shadow"
            :htmlContent="replacePartialPaymentFirstPayment">
         </GtsInfoWidget>
         <GtsInfoWidget
            v-if="partialPaymentOrderDetailModel?.voucherDetail?.isPartialPaid && partialPaymentOrderDetailModel?.voucherDetail?.status == 15"
            :class="deactive ? 'deactive' : ''"
            icon="gts-icon icon-partial-payment-shadow"
            :htmlContent="replacePartialPaymentSecondPayment"
            deactive>
         </GtsInfoWidget>
      </template>

      <div v-if="partialPaymentEnabled">
         <CheckoutPartialPaymentDetails
            :partialPaymentModel="partialPaymentModel"
            :isChecked="isChecked"
            :toggleEnabled="!partialPaymentOrderDetailModel?.voucherDetail?.isPartialPaid"
            @toggleChange="sendToggleChange"></CheckoutPartialPaymentDetails>
      </div>

      <PaymentCreditcard ref="creditcard" v-if="[2, 3].includes(PaymentType)" @IsAmexCard="IsAmexCard" />
      <div class="balance-update-form-item" v-if="isBalanceUpdate">
         <label class="txt-black">{{ $t("balanceUpdate_lblAmount") }}</label>
         <div class="ui-input-group">
            <UIInput v-input="{ type: 'numeric', required: true, requiredMsg: $t('common_cannotBeZero') }" id="input_creditCardFullName" textRight v-model="balanceUpdate.amount" />
            <div class="input-group-prepend">
               <span class="ui-input-group-text">.00 {{ "EUR" | currency }}</span>
            </div>
         </div>
         <div>
            <label class="txt-black">{{ $t("balanceUpdate_lblNote") }}</label>
            <div class="balance-update-form-item --note">
               <UIInput type="text" id="input_balanceUpdateNote" v-model="balanceUpdate.note" />
            </div>
         </div>
      </div>

      <!-- ProgressGifStatus -->
      <template v-if="ProgressGifStatus">
         <div v-if="paymentChoice.enabled" class="paymentChoice-actions-container">
            <UIButton
               className="default_outline"
               :text="$t(`paymentChoiceItem_stepBack`)"
               @click.native="handlerStepBack"
               v-if="$parent.isHeadless && !$parent.hidePrevBtn"
               :isDisabled="LoadingWithSpinner" />

            <!-- $t(`${$options.name}_btn${trxTypeLabel(trxType)}`) -->
            <!-- :text="$t(`${$options.name}_btn${trxTypeLabel(trxType)}`)" -->
            <template>
               <div v-if="partialPaymentOrderDetailModel?.voucherDetail?.isPartialPaid && partialPaymentOrderDetailModel?.voucherDetail?.status == 2">
                  <UIButton
                     :disabled="PriceDifferenceEnabled && priceDifferenceConfirmation != true && paymentChoice.trxTypes[0] == 2"
                     :key="componentKey"
                     :icon="paymentChoice.trxTypes[0] == 3 ? 'ts-icon icon-partial-payment' : ''"
                     :className="'partialPay'"
                     :text="paymentChoice.trxTypes[0] == 3 ? partialPaymentBtnText : $t(`${$options.name}_btn${trxTypeLabel(paymentChoice.trxTypes[0])}`)"
                     :isSpinnerActive="LoadingWithSpinner"
                     @click.native="funcClick(paymentChoice.trxTypes[0])" />
               </div>

               <div v-else>
                  <div v-for="(trxType, index) in TrxTypes" :key="index + componentKey1">
                     <KeepAlive>
                        <UIButton
                           :isDisabled="(PriceDifferenceEnabled && priceDifferenceConfirmation != true && trxType == 3) || LoadingWithSpinner"
                           :key="index + componentKey"
                           :icon="isChecked && trxType == 3 ? 'ts-icon icon-partial-payment' : ''"
                           :className="trxTypeClassName(trxType)"
                           :text="isChecked && trxType == 3 ? partialPaymentBtnText : $t(`${$options.name}_btn${trxTypeLabel(trxType)}`)"
                           :isSpinnerActive="LoadingWithSpinner"
                           @click.native="funcClick(trxType)" />
                     </KeepAlive>
                  </div>
               </div>
            </template>
         </div>
      </template>
   </div>
</template>

<script>
import { financeService, paymentService } from "@/service/index.js";
import UIAlert from "@/components/unitary/Alert.vue";
import UIButton from "@/components/unitary/Button.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";
import PaymentCreditcard from "@/components/structural/payment/PaymentCreditcard.vue";
import Popup from "@/gts-popup.js";
import debounce from "lodash.debounce";
import CheckoutPartialPaymentDetails from "../hotel/checkout/CheckoutPartialPaymentDetails.vue";
import GtsInfoWidget from "@/components/utils/GtsInfoWidget.vue";
import moment from "moment";

export default {
   name: "PaymentChoiceItem",
   props: {
      paymentChoice: Object,
      isBalanceUpdate: Boolean,
      partialPaymentEnabled: Boolean,
      partialPaymentModel: Object,
      isChecked: Boolean,
      partialPaymentOrderDetailModel: Object,
   },
   components: {
      UIButton,
      UIAlert,
      UIInput,
      CheckoutPartialPaymentDetails,
      PaymentCreditcard,
      UICheckBox,
      GtsInfoWidget,
   },
   data() {
      return {
         isCardTypeChanged: false,
         cardData: {},
         componentKey1: 0,
         deactive: true,
         priceDifferenceConfirmation: false,
         componentKey: 0,
         balanceUpdate: {
            amount: "",
            commissionTotalAmount: 0,
            grandTotal: 0,
            note: "",
         },
         isPartialOrderDetail: false,
      };
   },
   computed: {
      ProgressGifStatus() {
         return !this.$store.state.app.progress.status;
      },
      lastBookingDateTime() {
         return moment(this.partialPaymentOrderDetailModel.voucherDetail.bookingDateTime).format("DD.MM.YYYY HH:mm");
      },
      partialPaymentBtnText() {
         return this.$t("paymentPurchaseWidget_partialPay");
      },
      formattedFirstPaymentDate() {
         return moment(this.partialPaymentModel.firstPaymentDate).format("DD.MM.YYYY HH:mm");
      },
      formattedSecondPaymentDate() {
         return moment(this.partialPaymentModel.secondPaymentDate).format("DD.MM.YYYY HH:mm");
      },
      replacePartialPaymentFirstPayment() {
         var paidPriceText = "<span style='font-weight: 600;'>" + this.$options.filters.price(this.partialPaymentModel.firstPaymentPrice, this.partialPaymentModel.currency) + "</span>";
         var dateText = "<span style='font-weight: 600;'>" + this.lastBookingDateTime + "</span>";
         return this.$t("paymentChoice_partialPaymentPaidText").replace("#VALUE#", dateText).replace("#VALUE1#", paidPriceText);
      },
      replacePartialPaymentSecondPayment() {
         var paidPriceText = "<span style='font-weight: 600;'>" + this.$options.filters.price(this.partialPaymentModel.secondPaymentPrice, this.partialPaymentModel.currency) + "</span>";
         var dateText = "<span style='font-weight: 600;margin-left:1rem'>" + this.formattedSecondPaymentDate + "</span>";
         return this.$t("paymentChoice_partialPaymentRemainingText").replace("#VALUE#", dateText).replace("#VALUE1#", paidPriceText);
      },

      WarningLabel() {
         return this.$parent?.warning;
      },
      PriceDifferenceEnabled() {
         const isCheckout = this.$route.name == "FlightCheckout";
         if (isCheckout) {
            return this.$store.state.flight.checkout.pricedifference.status;
         } else {
            return false;
         }
      },
      LoadingWithSpinner() {
         return this.$parent.loadingWithSpinner;
      },
      TrxTypes() {
         return this.paymentChoice ? this.paymentChoice.trxTypes : [];
      },
      PaymentType() {
         return this.paymentChoice ? this.paymentChoice.paymentType : 0;
      },
      Total() {
         if (this.isBalanceUpdate) {
            return Number(this.balanceUpdate.amount);
         } else {
            return this.paymentChoice ? this.paymentChoice.amount : -1;
         }
      },
      CommissionEnabled() {
         return this.paymentChoice ? this.paymentChoice.commissionEnabled : false;
      },
      Commission() {
         if (this.isBalanceUpdate) {
            return this.balanceUpdate.commissionTotalAmount;
         } else {
            return this.paymentChoice ? this.paymentChoice.commission : -1;
         }
      },
      GrandTotal() {
         if (this.isBalanceUpdate) {
            return this.balanceUpdate.grandTotal;
         } else {
            return this.paymentChoice ? this.paymentChoice.total : -1;
         }
      },
      Balance() {
         return this.paymentChoice ? this.paymentChoice.balance : -1;
      },
      Currency() {
         return this.paymentChoice ? "EUR" : "";
      }, //this.paymentChoice.currency
      Note() {
         const self = this;
         if (self.paymentChoice.note) return this.$i18n.t(`${this.$options.name}_${self.paymentChoice.note}`);
         else return "";
      },
   },
   methods: {
      IsAmexCard: debounce(function (data) {
         this.cardData = data;
         if (data.isAmex) {
            const query = {
               agencyId: this.$store.state.user.user.agencyId,
               paymentType: this.paymentChoice.paymentType,
               amount: this.Total,
               cardBin: data.creditcard.pan,
               currency: this.Currency,
            };
            if (this.Total != 0) {
               paymentService.amexCommission(query).then((response) => {
                  if (this.isBalanceUpdate) {
                     this.balanceUpdate.commissionTotalAmount = this.$options.filters.price(response.commissionAmount, response.currency);
                     this.balanceUpdate.grandTotal = this.$options.filters.price(response.totalAmount, response.currency);
                  } else {
                     this.paymentChoice.commission = this.$options.filters.price(response.commissionAmount, response.currency);
                     this.paymentChoice.total = this.$options.filters.price(response.totalAmount, response.currency);
                  }
               });
            }
         }
      }, 500),
      sendToggleChange(isChecked) {
         this.$emit("toggleChange", isChecked);
      },
      funcUpdateCommission: debounce(function () {
         const self = this;

         if (self.balanceUpdate.amount != "") {
            // PAGE_GET 1 , PAYMENT_TAKE 2, PAYMENT_CALLBACK 3 , COMMISION_DETECT 4 , UNQUIRY 6
            let request = {
               amount: Number(self.balanceUpdate.amount),
               paymentType: self.paymentChoice.paymentType,
               trxType: 4,
            };
            financeService.updateBalance(request).then((response) => {
               if (response.result.success) {
                  self.balanceUpdate.commissionTotalAmount = this.$options.filters.price(response.commission, response.currency);
                  self.balanceUpdate.grandTotal = this.$options.filters.price(response.total, response.currency);
               }
            });
         } else if (self.isCardTypeChanged) {
            let request = {
               amount: Number(self.paymentChoice.amount),
               paymentType: self.paymentChoice.paymentType,
               trxType: 4,
            };
            financeService.updateBalance(request).then((response) => {
               if (response.result.success) {
                  this.paymentChoice.commission = this.$options.filters.price(response.commission, response.currency);
                  this.paymentChoice.total = this.$options.filters.price(response.total, response.currency);
               }
            });
         } else {
            self.commisionTotalAmount = this.$options.filters.price(0);
         }
      }, 50),
      handlerStepBack() {
         console.log(this.$parent.$parent.$parent);
         this.$parent.$parent.$parent.activeStep = 0;
      },
      PricedifferenceAmountPlaceholder() {
         const fmt = this.$i18n.t(`flightCheckoutPayment_priceDifference`);
         const amount = this.$store.state.flight.checkout.pricedifference.value;
         const amountReplace = this.$options.filters.price(amount);
         return fmt.replace("AMOUNTPLACEHOLDER", amountReplace);
      },
      paymentTypeLabel() {
         switch (this.paymentChoice.paymentType) {
            case 1:
               return "CARI";
            case 2:
               return "CREDITCARD";
            case 3:
               return "CREDITCARD";
            case 4:
               return "SOFORT";
            case 5:
               return "IDEAL";
         }
         return "";
      },
      trxTypeLabel(trxType) {
         switch (trxType) {
            case 1:
               return "CHECKOUT";
            case 2:
               return "RESERVE";
            case 3:
               return "TICKET";
            case 4:
               return "REVERSE";
            case 5:
               return "LEAVE";
            case 6:
               return "INQUIRY";
         }
         return "";
      },
      trxTypeClassName(trxType) {
         switch (trxType) {
            case 1:
               return ""; //CHECKOUT
            case 2:
               return "default_outline"; //RESERVE
            case 3:
               if (this.isChecked) {
                  return "partialPay";
               } else {
                  return "default"; //TICKET
               }
            case 4:
               return "warning"; //REVERSE
            case 5:
               return "attention"; //LEAVE
            case 6:
               return ""; //INQUIRY
         }
         return "";
      },
      fetchEntry(trxType) {
         const entry = {
            trxType: trxType,
            trxTypeLabel: this.trxTypeLabel(trxType),
            paymentType: this.paymentChoice.paymentType,
            paymentTypeLabel: this.paymentTypeLabel(),
            vposId: this.paymentChoice.vposId,
            creditcard: {},
         };
         if ([2, 3].includes(this.paymentChoice.paymentType)) {
            entry.creditcard = this.$refs.creditcard.fetchEntry();
         }

         return entry;
      },
      funcClick(trxType) {
         const self = this;
         let data = this.fetchEntry(trxType);

         if (this.isBalanceUpdate && (this.balanceUpdate.amount == "0" || this.balanceUpdate.amount == "")) {
            Popup.warning(this.$i18n.t("BalanceUpdate_popup_Validation_ERROR"), this.$i18n.t("BalanceUpdate_popup_Validation_AMOUNT"));
            return;
         }

         const ccControl = trxType == 2 || trxType == 4 || trxType == 5 ? "creditcard" : undefined;
         self.$validate(undefined, ccControl).then((res) => {
            if (res.success) {
               // console.log("$validate", res.success, res, data);
               self.$emit("click", data);
            } else {
               const autoScroll = document.querySelector("#validation-error-auto-scroller");
               if (autoScroll) {
                  document.querySelector("#validation-error-auto-scroller").scrollIntoView();
               }
            }
         });
      },
   },
   watch: {
      "paymentChoice.paymentType": {
         handler: function () {
            this.isCardTypeChanged = false;
         },
      },
      "$store.state.flight.checkout.response": {
         handler: function () {
            const rndInt = Math.floor(Math.random() * 1000) + 1;

            this.componentKey = rndInt;
         },
         deep: true,
      },
      cardData: {
         handler: function () {
            if (this.cardData.isAmex) {
               this.IsAmexCard(this.cardData);
               if (this.paymentChoice.paymentType == 2) {
                  this.isCardTypeChanged = true;
               }
            } else if (this.isBalanceUpdate) this.funcUpdateCommission();
            else if (this.isCardTypeChanged) this.funcUpdateCommission();
         },
      },
      "balanceUpdate.amount": function () {
         if (this.balanceUpdate.amount == "00") {
            this.balanceUpdate.amount.replace(/^0+/, "");
            return;
         }
         const newValue = this.balanceUpdate.amount.replace(/^0+/, "");
         this.balanceUpdate.amount = newValue;
         if (this.cardData.isAmex) {
            this.IsAmexCard(this.cardData);
         } else if (this.isBalanceUpdate) this.funcUpdateCommission();
         else if (this.isCardTypeChanged) this.funcUpdateCommission();
         this.$emit("updateAmount", this.balanceUpdate.amount);
      },
      "balanceUpdate.note": function () {
         this.$emit("updateNote", this.balanceUpdate.note);
      },
      isChecked() {
         this.componentKey1++;
      },
   },
};
</script>

<style lang="scss" scoped>
#paymentChoice-type-container {
   width: 100%;

   .loading-with-spinner-blocker {
      position: fixed;
      background: transparent;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
   }

   @media (min-width: 1200px) {
      // width: 800px;
   }
   .balance-update-form-item {
      margin-top: 0.75rem;
      .ui-input-group {
         display: flex;

         &.--note {
            .g-input-wrapper {
               width: -webkit-fill-avaible;
            }
         }
      }
      .ui-input-group-text {
         display: flex;
         align-items: center;
         padding: 0.675rem 0.75rem;
         font-size: 1rem;
         font-weight: 400;
         line-height: 1.5;
         color: #212529;
         text-align: center;
         white-space: nowrap;
         background-color: #e9ecef;
         border: 1px solid #ced4da;
         border-radius: 0.25rem;
      }
   }
   .paymentChoice-information-boxes {
      width: 100%;
      @media (min-width: 1200px) {
         // width: 750px;
      }
      .deactive {
         filter: grayscale(100%);
         border: 2px solid #f3dcdc;
         background: white !important;
      }
      .price-difference-info-box {
         margin-top: 1.125rem;
      }

      .paymentChoice-information-box-wrapper {
         display: flex;
         flex-direction: column;
         width: 100%;

         .paymentChoice-information-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1rem;
            max-width: 500px;

            @media (max-width: 400px) {
               display: flex;
               flex-direction: column;
            }

            span {
               display: flex;
               align-items: center;
               font-size: var(--normal);
               font-weight: 400;
               color: var(--txt-black);

               @media (min-width: 992px) {
                  min-width: 500px;
                  word-wrap: break-word;
               }

               &.paymentChoice-information-accent {
                  font-size: var(--large);
                  font-weight: 600;
                  min-width: 200px;
               }
            }
         }
      }
   }

   .paymentChoice-actions-container {
      display: flex;
      justify-content: flex-start;
      margin-top: 2rem;
      > div {
         display: flex;
      }
      button {
         margin: 0 0.5rem;

         &:first-child {
            margin-left: 0;
         }
      }
   }
}
</style>
