import { PUBLIC_HTTP, PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";
import store from "@/store";
import Popup from "@/gts-popup.js";
import i18n from "@/fmt/i18n.js";

const USER_STARTUP_ENDPOINT = "api/user/startup";
const USER_LOGIN_ENDPOINT = "api/user/login";
const REFRESH_TOKEN_ENDPOINT = "/api/user/refreshjwt";
const USER_RESET_ENDPOINT = "api/user/pwdresetinit";
const RESET_PASSWORD = "api/user/pwdresetconfirm";
const GET_PROFILE_ENDPOINT = "api/agency/getprofile";
const LIST_USERS = "api/agency/listusers";
const LIST_USER_AUTHS_ENDPOINT = "api/agency/listuserauths";
const SET_USER_AUTHS_ENDPOINT = "api/agency/setuserauths";
const GET_USER_SETTINGS_ENPOINT = "api/user/getusersettings";
const SET_USER_SETTINGS_ENPOINT = "api/user/setusersettings";
const USER_ENABLE_OTP = "api/user/enabletotp";
const USER_GET_TOKEN = "api/user/getssotoken";

export var userStartup = (requestModel) => {
   const promise = PUBLIC_HTTP.post(USER_STARTUP_ENDPOINT, requestModel);

   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         store.commit("app/setEmailConfirmed", true);
         return response.data;
      } else {
         store.commit("app/setEmailConfirmed", false);
      }
   });

   return responseData;
};

export var userLogin = (requestModel) => {
   const promise = PUBLIC_HTTP.post(USER_LOGIN_ENDPOINT, requestModel);
   const responseData = promise.then((response) => {
      if (!response.data.result.success) {
         var htmlContent = "";
         if (response.data.result.subCode == "TOTP_RESET" && response.data.totpQrCodeUrl != "") {
            htmlContent = `
                    <div class="gts-popup-html-content-inner">
                        <p style="text-align: center;"><strong>${i18n.t("alert_warningPopupTitle")}</strong></p>
                        <p style="text-align: center;">Token kodunuz eksik veya hatalıdır.</p>
                        <p style="margin: 24px 0; text-align: center;"><img src="${response.data.totpQrCodeUrl}" /></p>
                        <p style="text-align: center;"><small><strong>Token Tanımlama İçin</strong></small></p>
                        <p style="text-align: center;">Akıllı telefonunuza <strong>Google Authenticator</strong> uygulamasını indiriniz.</p>
                        <p style="text-align: center;">Uygulamanın telefonunuza kurulması aşamasında ekrandaki karekodu okutunuz.</p>
                    </div>
                `;

            Popup.warning("", "", htmlContent, 50000);
         } else {
            Popup.result(response.data.result);
         }
      }
      return response.data;
   });

   return responseData;
};
export var userEnableOtp = (requestModel) => {
   const promise = PRIVATE_HTTP().post(USER_ENABLE_OTP, requestModel);
   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         if (!requestModel.totp) {
            var htmlContent = "";

            if (response.data.result.subCode == "OK" && response.data.totpQrCodeUrl != "") {
               htmlContent = `
                       <div class="gts-popup-html-content-inner">
                           <p><img src="${response.data.totpQrCodeUrl}" /></p>
                       </div>
                   `;
               return htmlContent;
            } else {
               Popup.result(response.data.result);
            }
         } else {
            return response.data;
         }
      } else {
         return response.data;
      }
   });

   return responseData;
};
export var resetPassword = (requestModel) => {
   const promise = PUBLIC_HTTP.post(USER_RESET_ENDPOINT, requestModel);
   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         if (requestModel.sendEmail) {
            Popup.success(i18n.t("popup_successTitle"), i18n.t("resetPassword_emailHasBeenSent"));
         } else {
            return response.data;
         }
      } else {
         Popup.result(response.data.result);
      }

      return response.data;
   });

   return responseData;
};

export var refreshToken = (requestModel) => {
   const promise = PUBLIC_HTTP.post(REFRESH_TOKEN_ENDPOINT, requestModel);
   const responseData = promise.then((response) => response.data);

   return responseData;
};
export var changePassword = (requestModel) => {
   const promise = PUBLIC_HTTP.post(RESET_PASSWORD, requestModel);
   const responseData = promise.then((response) => response.data);

   return responseData;
};

export var getProfile = (requestModel) => {
   const promise = PRIVATE_HTTP().post(GET_PROFILE_ENDPOINT, requestModel);
   const responseData = promise
      .then((response) => {
         if (response.data.result.success) {
            return response.data;
         } else {
            Popup.result(response.data.result);
         }
      })
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         } else {
            Popup.warning("getProfile Catch Error", error);
         }
      });

   return responseData;
};

export var listUserAuths = (requestModel) => {
   const promise = PRIVATE_HTTP().post(LIST_USER_AUTHS_ENDPOINT, requestModel);
   const responseData = promise
      .then((response) => {
         if (response.data.result.success) {
            return response.data;
         } else {
            Popup.result(response.data.result);
         }
      })
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         } else {
            Popup.warning("listUserAuths Catch Error", error);
         }
      });

   return responseData;
};

export var setUserAuths = (requestModel) => {
   const promise = PRIVATE_HTTP().post(SET_USER_AUTHS_ENDPOINT, requestModel);
   const responseData = promise.then((response) => {
      return response.data;
   });

   return responseData;
};
export var listUsers = (requestModel) => {
   const promise = PRIVATE_HTTP().post(LIST_USERS, requestModel);
   const responseData = promise
      .then((response) => {
         if (response.data.result.success) {
            return response.data;
         } else {
            Popup.result(response.data.result);
         }
      })
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         } else {
            Popup.warning("listUsers Catch Error", error);
         }
      });

   return responseData;
};

export var getUserSettings = (requestModel) => {
   const promise = PRIVATE_HTTP().post(GET_USER_SETTINGS_ENPOINT, requestModel);
   const responseData = promise.then((response) => {
      return response.data;
   });

   return responseData;
};

export var setUserSettings = (requestModel) => {
   const promise = PRIVATE_HTTP().post(SET_USER_SETTINGS_ENPOINT, requestModel);
   const responseData = promise.then((response) => {
      return response.data;
   });

   return responseData;
};

export var getUserToken = (requestModel) => {
   const promise = PRIVATE_HTTP().post(USER_GET_TOKEN, requestModel);
   const responseData = promise.then((response) => {
      return response.data;
   });

   return responseData;
};
