<template>
   <div class="order-detail-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <div class="container-lg px-md-3 px-lg-4" :class="isPageLoad ? (this.$store.state.direction.isRoutedDirection ? 'mt-3' : '') : 'data-mask'">
            <!-- {{ orderSummaryHeaderInfo }} -->
            <!-- {{ $t("orderDetailPage_orderSummaryHeader_contactEmailLabel") }} -->
            <!-- {{ orderError }} -->
            <OrderSummaryHeader :orderSummaryObj="orderSummaryHeaderInfo" v-cloak />
            <OrderError v-if="orderError != []" :order-error="orderError" />
            <OrderDetailTab
               :pnrs="orderPnrs"
               :pageLoad="isPageLoad"
               :vouchers="orderVouchers"
               @agency-commission-extra="handlerAgencyCommissionExtra"
               :tnrs="orderTnrs"
               :rnrs="orderRnrs"
               :orderId="orderSummaryHeaderInfo.orderId"
               :packages="packages"
               v-cloak />
            <ServiceCenter :order-id="orderSummaryHeaderInfo.orderId" v-cloak v-show="isPageLoad" :packages="packages" />
         </div>
         <!-- <div class="order-detail-page-inner container" :class="isPageLoad ? '' : 'data-mask'" v-if="false">
            <OrderDetailTab :pnrs="orderPnrs" :vouchers="orderVouchers" :tnrs="orderTnrs" v-cloak />
            <ServiceCenter :order-id="orderSummaryHeaderInfo.orderId" v-cloak />
         </div> -->
      </Main>
   </div>
</template>

<script>
import Popup from "@/gts-popup.js";
import router from "@/router/index.js";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";
import OrderSummaryHeader from "@/components/unitary/order/OrderSummaryHeader.vue";
import OrderError from "@/components/unitary/order/OrderError.vue";
import OrderDetailTab from "@/components/structural/order/OrderDetailTab.vue";
import ServiceCenter from "@/components/unitary/order/ServiceCenter.vue";
import response from "@/placeholders/orderDetail.js";
import { orderService } from "@/service/index.js";

export default {
   name: "OrderDetailPage",
   components: {
      Aside,
      Navbar,
      Main,
      OrderSummaryHeader,
      OrderError,
      OrderDetailTab,
      ServiceCenter,
   },
   data() {
      return {
         packages: {
            type: -1,
            currency: "EUR",
            total: "",
         },
         searchQuery: {
            orderUuid: this.$route.params.orderUuid,
         },
         orderSummaryHeaderInfo: {
            orderId: 0,
            status: "",
            agency: {},
            users: {},
            contacts: {},
            gsaType: 0,
         },
         orderError: [],
         orderPnrs: [],
         orderVouchers: [],
         orderTnrs: [],
         orderRnrs: [],
         isPageLoad: false,
      };
   },
   created() {
      const self = this;

      // prevent history mode bugs
      self.$store.state.app.documentViewer.request.identifiers = [];

      window.scrollTo(0, 0);

      // DUMMY RESPONSE
      self.orderSummaryHeaderInfo.agency = {
         agencyId: response.orderDetail.header.agencyId,
         agencyEmail: response.orderDetail.header.agencyEmail,
         agencyName: response.orderDetail.header.agencyName,
         agencyPhone: this.$options.filters.phone(response.orderDetail.header.agencyPhone),
      };
      self.orderSummaryHeaderInfo.contacts = response.orderDetail.header.contacts;
      self.orderSummaryHeaderInfo.users = response.orderDetail.header.users;

      // self.orderSummaryHeaderInfo.agencyName = response.orderDetail.header.agencyName;
      // self.orderSummaryHeaderInfo.orderId = response.orderDetail.header.orderId;
      // if (response.orderDetail.header.users[0]) {
      //    self.orderSummaryHeaderInfo.passengerEmail = response.orderDetail.header.users[0].email;
      //    self.orderSummaryHeaderInfo.passengerPhone = this.$options.filters.phone(response.orderDetail.header.users[0].phone);
      // }
      // self.orderSummaryHeaderInfo.status = response.orderDetail.header.orderStatus;

      self.$store.state.app.documentViewer.request.orderId = response.orderDetail.header.orderId;
      self.$store.state.app.documentViewer.request.orderUuid = response.orderDetail.header.orderUuid;

      orderService.searchOrderEntities(self.searchQuery).then((value) => {
         if (value.result.success) {
            switch (value.entities[0].type) {
               case 2: // PNR
                  value.entities.forEach((_, index) => {
                     if (index != 0) {
                        const clonedPnr = JSON.parse(JSON.stringify(response.orderDetail.pnrs[0]));
                        response.orderDetail.pnrs.push(clonedPnr);
                     }
                  });
                  response.orderDetail.pnrs.forEach((pnrItem) => {
                     pnrItem.tabIndex = `P${Math.floor(1000 + Math.random() * 9000)}`;
                     self.orderPnrs.push(pnrItem);
                     if (pnrItem.pnr.status == 3 || pnrItem.pnr.status == 2) {
                        self.$store.state.app.documentViewer.request.identifiers.push({
                           identifier: pnrItem.pnr.id.toString(),
                           subIdentifiers: [],
                        });
                     }
                  });
                  break;
               case 3: // VOUCHER
                  response.orderDetail.vouchers.map((voucherItem) => {
                     voucherItem.tabIndex = `V${Math.floor(1000 + Math.random() * 9000)}`;
                     self.orderVouchers.push(voucherItem);
                     if (voucherItem.voucher.status == 3 || voucherItem.voucher.status == 2 || voucherItem.voucher.status == 15) {
                        self.$store.state.app.documentViewer.request.identifiers.push({
                           identifier: voucherItem.voucher.id.toString(),
                           subIdentifiers: [],
                        });
                     }
                  });
                  break;
               case 4: // TNR
                  response.orderDetail.tnrs.map((tnrItem) => {
                     tnrItem.tabIndex = `V${Math.floor(1000 + Math.random() * 9000)}`;
                     self.orderTnrs.push(tnrItem);
                     if (tnrItem.tnr.status == 3 || tnrItem.tnr.status == 2) {
                        self.$store.state.app.documentViewer.request.identifiers.push({
                           identifier: tnrItem.tnr.tnrId.toString(),
                           subIdentifiers: [],
                        });
                     }
                  });
                  break;
               case 5: // RNR
                  response.orderDetail.rnrs.map((rnrItem) => {
                     rnrItem.tabIndex = `V${Math.floor(1000 + Math.random() * 9000)}`;
                     self.orderRnrs.push(rnrItem);
                     if (rnrItem.rnr.status == 3 || rnrItem.rnr.status == 2) {
                        self.$store.state.app.documentViewer.request.identifiers.push({
                           identifier: rnrItem.rnr.id.toString(),
                           subIdentifiers: [],
                        });
                     }
                  });
                  break;
            }
         }
      });

      //DUMMY RESPONSE END

      this.$store.commit("order/updateOrderUuid", self.searchQuery.orderUuid);

      orderService.getOrderDetail(self.searchQuery).then((response) => {
         if (!response.result.success) {
            Popup.result(response.result).then(() => {
               router.push({ name: "OrderSearch" });
            });
         }
         const packageData = {
            type: response.orderDetail.header.type,
            currency: response.orderDetail.header.currency,
            total: response.orderDetail.header.total,
         };
         this.packages = packageData;
         //29.Aug
         self.orderSummaryHeaderInfo.orderId = response.orderDetail.header.orderId;
         this.$store.commit("app/setOrderGsaType", response.orderDetail.header.gsaType);
         self.orderSummaryHeaderInfo.status = response.orderDetail.header.orderStatus;
         self.orderSummaryHeaderInfo.agency = {
            agencyId: response.orderDetail.header.agencyId,
            agencyEmail: response.orderDetail.header.agencyEmail,
            agencyName: response.orderDetail.header.agencyName,
            agencyPhone: this.$options.filters.phone(response.orderDetail.header.agencyPhone),
         };
         self.orderSummaryHeaderInfo.contacts = response.orderDetail.header.contacts;
         self.orderSummaryHeaderInfo.users = response.orderDetail.header.users;

         self.$store.commit("transfer/updatePassengerInfo", {
            email: response.orderDetail.header.contacts[0].email,
            phone: response.orderDetail.header.contacts[0].phone,
         });

         // self.orderSummaryHeaderInfo.agencyId = response.orderDetail.header.agencyId;
         // self.orderSummaryHeaderInfo.agencyName = response.orderDetail.header.agencyName;
         // self.orderSummaryHeaderInfo.orderId = response.orderDetail.header.orderId;
         // if (response.orderDetail.header.users[0]) {
         //    self.orderSummaryHeaderInfo.passengerEmail = "deneme";
         //    self.orderSummaryHeaderInfo.passengerPhone = this.$options.filters.phone(response.orderDetail.header.users[0].phone);
         // }
         // self.orderSummaryHeaderInfo.status = response.orderDetail.header.orderStatus;

         // self.orderError = response.orderDetail.error;

         self.$store.state.app.documentViewer.request.orderId = response.orderDetail.header.orderId;
         self.$store.state.app.documentViewer.request.orderUuid = response.orderDetail.header.orderUuid;

         // this.$options.filters.enumOrderStatus(response.orderDetail.header.orderStatus);
         // self.orderSummaryHeaderInfo.userEmail = "deneme";
         // self.orderSummaryHeaderInfo.userPhone = this.$options.filters.phone(response.orderDetail.header.agencyPhone);

         self.orderError = [];

         self.orderVouchers.length = 0;
         response.orderDetail.vouchers.map((voucherItem) => {
            voucherItem.tabIndex = `V${Math.floor(1000 + Math.random() * 9000)}`;
            self.orderVouchers.push(voucherItem);
            if (voucherItem.error != null) {
               self.orderError.push(voucherItem.error);
            }

            if (voucherItem.voucher.status == 3 || voucherItem.voucher.status == 2 || voucherItem.voucher.status == 15) {
               self.$store.state.app.documentViewer.request.identifiers.push({
                  identifier: voucherItem.voucher.id.toString(),
                  subIdentifiers: [],
               });
            }
         });

         self.orderPnrs.length = 0;
         response.orderDetail.pnrs.map((pnrItem) => {
            pnrItem.tabIndex = `P${Math.floor(1000 + Math.random() * 9000)}`;
            self.orderPnrs.push(pnrItem);
            if (pnrItem.error != null) {
               self.orderError.push(pnrItem.error);
            }

            if (pnrItem.pnr.status == 3 || pnrItem.pnr.status == 2) {
               self.$store.state.app.documentViewer.request.identifiers.push({
                  identifier: pnrItem.pnr.id.toString(),
                  subIdentifiers: [],
               });
            }
         });

         self.orderTnrs.length = 0;
         response.orderDetail.tnrs.map((tnrItem) => {
            tnrItem.tabIndex = `V${Math.floor(1000 + Math.random() * 9000)}`;
            self.orderTnrs.push(tnrItem);
            if (tnrItem.error != null) {
               self.orderError.push(tnrItem.error);
            }

            if (tnrItem.tnr.status == 3 || tnrItem.tnr.status == 2) {
               self.$store.state.app.documentViewer.request.identifiers.push({
                  identifier: tnrItem.tnr.tnrId.toString(),
                  subIdentifiers: [],
               });
            }
         });
         self.orderRnrs.length = 0;
         response.orderDetail.rnrs.map((rnrItem) => {
            rnrItem.tabIndex = `V${Math.floor(1000 + Math.random() * 9000)}`;
            self.orderRnrs.push(rnrItem);
            if (rnrItem.error != null) {
               self.orderError.push(rnrItem.error);
            }

            if (rnrItem.rnr.status == 3 || rnrItem.rnr.status == 2) {
               self.$store.state.app.documentViewer.request.identifiers.push({
                  identifier: rnrItem.rnr.id.toString(),
                  subIdentifiers: [],
               });
            }
         });
         self.isPageLoad = true;
      });
   },
   methods: {
      handlerAgencyCommissionExtra(obj) {
         let searchQuery = {
            orderId: this.orderPnrs[obj.pnrIndex - 1]?.pnr?.orderId,
            entityId: this.orderPnrs[obj.pnrIndex - 1]?.pnr?.id,
            entityType: 2,
            agencyCommissionExtra: obj.agencyCommissionExtra,
            currency: "EUR",
         };
         console.log("searchQuery", searchQuery);
         orderService.setAgencyExtraCommission(searchQuery).then((value) => {
            if (value.result.success) {
               window.location.reload();
            }
         });
      },
   },
   watch: {
      isPageLoad: {
         handler: function (status) {
            router;
            const self = this;
            if (status && self.$route.query.reserved) {
               const ticketReserved = {
                  type: "SUCCESS",
                  message: self.$i18n.t(`flightOrderDetail_ticketReservedNotificaiton`),
                  count: false,
               };
               self.$store.commit("app/setNotification", ticketReserved);
               router.push(this.$route.path);
            } else if (status && self.$route.query.issued) {
               const ticketReserved = {
                  type: "SUCCESS",
                  message: self.$i18n.t(`flightOrderDetail_ticketIssuedNotificaiton`),
                  count: false,
               };
               self.$store.commit("app/setNotification", ticketReserved);
               router.push(this.$route.path);
            } else if (status && self.$route.query.taskopen) {
               setTimeout(function () {
                  window.scrollTo(0, document.body.scrollHeight);
                  router.push(self.$route.path);
               }, 1000);
            }
         },
      },
   },
};
</script>
<style lang="scss">
footer {
   position: inherit !important;
}
.block {
   margin: 0 !important;
}
</style>
<style lang="scss" scoped>
.order-detail-page-wrapper {
   @media (min-width: 992px) {
      .container-lg {
         max-width: 960px;
      }
   }
   @media (min-width: 1200px) {
      .container-lg {
         max-width: 1140px;
      }
   }
   @media (min-width: 1400px) {
      .container-lg {
         // max-width: 1320px;
         max-width: 1500px;
      }
   }
}
</style>
