<template>
   <div class="ancillary-ssr-c">
      <template v-for="pax in paxes">
         <div class="ancillary-ssr-pax-item" :key="pax.paxNumber">
            <section class="awpi-left">
               {{ pax.paxNumber }}
            </section>
            <section class="awpi-right">
               <template v-for="(segment, segmentIndex) in pax.segments">
                  <div class="ancillary-ssr-segment-wrapper" :key="segmentIndex">
                     <!-- Passenger Information -->
                     <div class="passenger-info-wrapper" :class="segmentIndex == 0 ? '' : 'invisible'">
                        <span class="passenger-name">{{ pax.name }}</span>
                        <span class="passenger-type">{{ pax.paxType }}</span>
                     </div>
                     <!-- Route Information -->
                     <div class="route-info">
                        <img class="airline-logo" :src="handlerAirlineImg(segment.marketingAirlineCode)" />
                        <span class="route-from">{{ segment.from }}</span>
                        <i class="flight-icon"></i>
                        <span class="route-to">{{ segment.to }}</span>
                     </div>

                     <div class="route-date">
                        <span>{{ segment.departureDate | FormatterDate }}</span>
                     </div>
                     <div class="ssrs-wrapper">
                        <template v-for="(ssr, key, index) in segment.ssrs">
                           <template v-if="ssr.hasSelection">
                              <span class="ssr-selected" :key="index">{{ ssr.hasSelection }}</span>
                           </template>
                           <template v-else-if="ssr.items.length > 0">
                              <AncillaryDropdown
                                 :items="ssr.items"
                                 :identifier="key"
                                 :key="index"
                                 @selected="(value) => handlerSelection(value, { paxNumber: pax.paxNumber, segmentIndex: segmentIndex, index })"
                                 @unselect="handlerUnselect" />
                           </template>
                        </template>
                     </div>
                  </div>
               </template>
            </section>
         </div>
      </template>

      <div class="ancillary-ssr-amount-wrapper" v-if="total">
         <div class="total-amount-rows">
            <div class="total-amount-col">
               <span class="txt-bold"> {{ $t("ancillariesServicesModal_lblGrandTotal") }} : </span>
            </div>
            <div class="total-amount-col">
               <span class="txt-black txt-xlarge">
                  {{ total.total | price(total.currency) }}
               </span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import AncillaryDropdown from "@/components/structural/flight/order-detail/ancillaries/base/AncillaryDropDown.vue";
export default {
   name: "AncillaryWheelchair",
   components: {
      AncillaryDropdown,
   },
   props: {
      paxes: Array,
      searchUuid: String,
      ssrType: String,
      total: Object,
   },
   data() {
      return {
         selections: this.ssrType == "meals" ? this.$parent.ssrTicketRequestModel : this.$parent.wheelChairTicketRequestModel,
      };
   },

   created() {},
   methods: {
      handlerUnselect(val) {
         if (this.selections[val.paxIndex] && this.selections[val.paxIndex][val.segment]) {
            this.$delete(this.selections[val.paxIndex], val.segment);
            if (Object.keys(this.selections[val.paxIndex]).length === 0) {
               this.$delete(this.selections, val.paxIndex);
            }
         }
      },
      handlerSelection(val) {
         if (this.selections[val.paxIndex]) {
            this.$set(this.selections[val.paxIndex], val.segment, val.type.identifier);
         } else {
            this.$set(this.selections, val.paxIndex, { [val.segment]: val.type.identifier });
         }
      },
      handlerAirlineImg(airlineLogo) {
         return `${this.$constants.cdn}/airline_34x34/${airlineLogo}.svg`;
      },
   },
   watch: {
      selections: {
         handler: function (obj) {
            const hasSelection = Object.keys(obj).length > 0;
            if (this.ssrType == "meals" || this.ssrType == "ssrs") {
               this.$parent.ssrTicketRequestModel = this.selections;
            } else {
               this.$parent.wheelChairTicketRequestModel = this.selections;
               this.$parent.wheelChairCanConfirmed = !hasSelection;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.ancillary-ssr-c {
   height: 500px;
   overflow-y: auto;

   @media only screen and (max-width: 768px) {
      // height: auto;
   }

   .ancillary-ssr-pax-item {
      // border: 1px solid;
      background-color: #f9fafb;
      border: 1px solid #edeef1;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 768px) {
         padding: 1rem 0;
      }

      .awpi-left {
         border-right: 1px solid #b2bac7;
         padding: 1rem 2rem;
         font-size: 1.5rem;
         @media only screen and (max-width: 768px) {
            display: none;
         }
      }

      .awpi-right {
         width: 100%;
         margin-left: 1rem;
         @media only screen and (max-width: 768px) {
            margin-left: 0;
         }
         .ancillary-ssr-segment-wrapper {
            padding: 0.5rem 1rem;
            display: flex;
            align-items: flex-start;
            // margin-bottom: 1rem;
            border-bottom: 2px dashed #edeef1;
            margin-bottom: 0.5rem;
            @media only screen and (max-width: 768px) {
               flex-direction: column;
            }
            .route-info {
               display: flex;
               align-items: center;
               margin-right: 2rem;
               flex-shrink: 0;

               img.airline-logo {
                  margin-right: 1rem;
                  width: 32px;
               }

               .flight-icon {
                  width: 32px;
                  height: 32px;
                  display: block;
                  margin: 0 0.125rem;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjU5MSAyMC45OUwxNy4xNjU5IDE0LjM5QzE3LjI3NDMgMTQuMjM0OCAxNy40MTcxIDE0LjEwNjYgMTcuNTgzIDE0LjAxNTNDMTcuNzQ5IDEzLjkyNDEgMTcuOTMzNyAxMy44NzIzIDE4LjEyMjkgMTMuODYzOUMxOS41ODgyIDEzLjc5MzIgMjAuOTQyNCAxMy42NzYyIDIyLjM5OTggMTMuNjI0QzIyLjc3MzUgMTMuNTQ4NyAyMy4xMTI1IDEzLjM1MzYgMjMuMzY1MyAxMy4wNjgxQzIzLjYxOCAxMi43ODI3IDIzLjc3MDggMTIuNDIyNiAyMy44MDAzIDEyLjA0MjRDMjMuODE4NyAxMS42MjQ3IDIzLjY4NzcgMTEuMjE0MSAyMy40MzA2IDEwLjg4NDRDMjMuMTczNiAxMC41NTQ3IDIyLjgwNzQgMTAuMzI3NCAyMi4zOTc4IDEwLjI0MzRDMjAuOTE2NiAxMC4xODk5IDE5LjU0MTkgMTAuMDY5NiAxOC4wNTI5IDkuOTk2MkMxNy44ODMgOS45ODg3MyAxNy43MTY1IDkuOTQ2MzIgMTcuNTYzOCA5Ljg3MTZDMTcuNDExMSA5Ljc5Njg5IDE3LjI3NTQgOS42OTE0OSAxNy4xNjUyIDkuNTYxOThMMTIuNTg3NyAzLjEzMzgyTDEwLjE4OTggMi43NTcxTDEyLjk2NjQgMTAuMjM0MUw4LjQzNzA3IDEwLjUyNTZMNi4yNzA1NSA4LjE4MzI5TDQuMzgwMzEgOC4xODMyOUw2LjE4Mzk3IDEyLjA2MzZMNC4zODAzMSAxNS45NDM5TDYuMjcwNTUgMTUuOTQzOUw4LjQzNzczIDEzLjM3ODhMMTIuOTY0NCAxMy42MTc0TDEwLjE4NzkgMjEuMzY5NEwxMi41OTEgMjAuOTlaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K");
               }

               span.route-from,
               span.route-to {
                  font-weight: 500;
                  font-size: 1.25rem;
               }
            }
            .route-date {
               margin: 0 1rem;
               display: flex;
               align-items: center;
            }
            .ssrs-wrapper {
               display: flex;
               flex-direction: column;
               align-items: flex-end;
               width: 100%;
               gap: 0.5rem;

               .ssr-selected {
                  opacity: 0.5;
                  position: relative;
                  padding: 0.5rem;
                  border: 1px solid #b2bac7;
                  border-radius: 0.5rem;
                  font-weight: 500;
                  color: #585858;
                  background-color: #eee;
                  width: -moz-fit-content;
                  width: fit-content;
                  min-width: 190px;
               }
            }

            .passenger-info-wrapper {
               display: flex;
               flex-direction: column;
               flex-shrink: 0;
               margin-right: 3rem;

               span {
                  &.passenger-name {
                     font-weight: 600;
                  }
                  &.passenger-type {
                     font-size: 0.9rem;
                  }
               }
            }

            &:last-child {
               border-bottom: none;
            }
         }
      }
   }
   .ancillary-ssr-amount-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .total-amount-rows {
         // border: 1px solid;
         // padding: 1rem;
         display: flex;
         .total-amount-col {
            display: flex;
            align-items: center;
            padding: 0 1rem;
         }
      }
   }
}
</style>
