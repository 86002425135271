const boardTypeList = {
    boardTypes: [
        {
            id: 2,
            priority: 1,
            nameTr: "Oda & Kahvaltı",
            nameEn: "Bed & Breakfast",
            nameDe: "Übernachtung und Frühstück",
            filterableOnAgency: true
        },
        {
            id: 3,
            priority: 2,
            nameTr: "Sadece Oda",
            nameEn: "Room Only",
            nameDe: "Nur Zimmer",
            filterableOnAgency: true
        },
        {
            id: 1,
            priority: 3,
            nameTr: "Yarım Pansiyon",
            nameEn: "Half Board",
            nameDe: "Halbpension",
            filterableOnAgency: true
        },
        {
            id: 4,
            priority: 5,
            nameTr: "Herşey Dahil",
            nameEn: "All Inclusive",
            nameDe: "All Inclusive",
            filterableOnAgency: true
        },
        {
            id: 15,
            priority: 5,
            nameTr: "Ala Carte Herşey Dahil",
            nameEn: "Ala Carte All Inclusive",
            nameDe: "Ala Carte Mit All Inclusive",
            filterableOnAgency: true
        },
        {
            id: 5,
            priority: 6,
            nameTr: "Ultra Herşey Dahil",
            nameEn: "Ultra All Inclusive",
            nameDe: "Ultra All Inclusive",
            filterableOnAgency: true
        },
        {
            id: 8,
            priority: 7,
            nameTr: "Alkolsüz Ultra Herşey Dahil",
            nameEn: "Nonalcohol Ultra All Inclusive",
            nameDe: "Ultra All Inclusive Alkoholfrei",
            filterableOnAgency: true
        },
        {
            id: 6,
            priority: 8,
            nameTr: "Tam Pansiyon",
            nameEn: "Full Board",
            nameDe: "Vollpension",
            filterableOnAgency: true
        },
        {
            id: 7,
            priority: 9,
            nameTr: "Alkolsüz Herşey Dahil",
            nameEn: "Nonalcohol All Inclusive",
            nameDe: "All Inclusive Alkoholfrei",
            filterableOnAgency: true
        },
        {
            id: 9,
            priority: 9,
            nameTr: "Tam Pansiyon +",
            nameEn: "Full Board Plus",
            nameDe: "Vollpension Plus",
            filterableOnAgency: true
        },
        {
            id: 11,
            priority: 10,
            nameTr: "Soft Herşey Dahil ",
            nameEn: "Soft All Inclusive",
            nameDe: "Soft All Inclusive",
            filterableOnAgency: true
        },
        {
            id: 12,
            priority: 11,
            nameTr: "Akşam Yemeği",
            nameEn: "Dinner",
            nameDe: "Abendessen",
            filterableOnAgency: true
        },
        {
            id: 13,
            priority: 13,
            nameTr: "Öğle ve Akşam Yemeği",
            nameEn: "Lunch and Dinner",
            nameDe: "Mittagessen und Abendessen",
            filterableOnAgency: true
        },
        {
            id: 14,
            priority: 14,
            nameTr: "Öğle Yemeği",
            nameEn: "Lunch",
            nameDe: "Mittagessen",
            filterableOnAgency: true
        }
    ]
};

export default boardTypeList;