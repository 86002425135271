<template>
   <div class="flight-page-wrapper">
      <Aside />
      <Main>
         <Navbar ref="basketMobile" />

         <!-- Flight Search helpers -->
         <transition name="fade" mode="out-in">
            <FlightInformationPopup ref="flightInformationPopup" />
         </transition>
         <FlightLazyLoad v-if="lazyLoad.animation" />
         <!-- Flight Search Grid -->
         <div id="flight-search-wrapper">
            <div class="row gx-3 gx-md-2 gx-lg-3 gx-xxl-3">
               <div id="flight-filter-column" class="col-md-1 col-lg d-none d-xxl-block">
                  <!-- FlightSearch Filters -->
                  <FlightFilters :legs="searchResponse?.legs" v-if="searchResponse" />
               </div>
               <div class="col-lg-12 col-xl-10 col-xxl-9">
                  <!-- Common Search Tab -->

                  <SearchTab :active-tab="1" @search-response="handlerSearchResponse" @throw-new-search="handlerThrowNewSearch" />

                  <!-- FlightSearch Result-->
                  <!-- {{ cheapestFamilyPrice }} -->

                  <transition name="big data" mode="out-in">
                     <FlightSearchResults
                        :search-response="searchResponse"
                        :family-prices="familyPrices"
                        :cheapest="cheapestFamilyPrice"
                        :packageOffers="packageOffers"
                        @throw-new-search="handlerThrowNewSearch"
                        v-if="searchResponse" />
                  </transition>
               </div>
               <div class="col-md-1 col-lg d-none d-xl-block" id="flight-basket-desktop">
                  <!-- FlightBasket -->
                  <FlightBasket :basket="basket.response" v-if="!$store.state.app.clientInformations.isMobile" />
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import { flightService } from "@/service/index.js";
import { generateUuid } from "@/utils.js";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";
import FlightInformationPopup from "@/components/structural/flight/search/FlightInformationPopup.vue";
import FlightLazyLoad from "@/components/structural/flight/search/FlightLazyLoad.vue";
import SearchTab from "@/components/unitary/SearchTab.vue";
import FlightFilters from "@/components/structural/flight/search/filters/FlightFilters.vue";
import FlightSearchResults from "@/components/structural/flight/search/FlightSearchResults.vue";
import FlightBasket from "@/components/structural/flight/search/FlightBasket.vue";
import { collapseLeg } from "@/helper/flight.js";
export default {
   name: "FlightPage",
   components: {
      Aside,
      Navbar,
      Main,
      SearchTab,
      FlightSearchResults,
      FlightInformationPopup,
      FlightLazyLoad,
      FlightFilters,
      FlightBasket,
   },
   data() {
      return {
         sameDayFlight: false,
         searchResponse: null,
         lazyLoad: {
            interval: null,
            animation: false,
         },
         basket: {
            status: false,
            response: null,
         },
         familyPrices: {
            familyPrices: {},
            cheapestFamilyPrices: {},
         },
         packageOffers: [],
      };
   },
   computed: {
      cheapestFamilyPrice() {
         if (!this.searchResponse?.legs || Object.keys(this.searchResponse.legs).length <= 1) {
            return null; // Eğer `legs` 1'den az veya eşitse işlemi atla.
         }
         if (this.basket.response?.checkoutEnabled) {
            const familyPrices = this.basket.response.familiesPrices || {};
            const totalPrice = Object.values(familyPrices).reduce((sum, price) => sum + price, 0);
            return {
               identifier: null,
               price: this.$options.filters.price(totalPrice),
            };
         }
         const keys = Object.keys(this.familyPrices.cheapestFamilyPrices).filter((key) => this.familyPrices.cheapestFamilyPrices[key] > 0);

         if (keys.length === 0) {
            return;
         }

         const cheapestKey = keys.reduce((cheapestID, currentID) => (this.familyPrices.cheapestFamilyPrices[currentID] < this.familyPrices.cheapestFamilyPrices[cheapestID] ? currentID : cheapestID));
         return {
            identifier: cheapestKey,
            price: this.$options.filters.price(this.familyPrices.cheapestFamilyPrices[cheapestKey]),
         };
      },
   },
   methods: {
      handlerPrepareForNewSearch() {
         this.$refs.flightInformationPopup.status = false;
         this.searchResponse = null;
         this.packageOffers = [];
         this.handlerClearLazyLoad();
         this.$store.commit("flight/emptyFilters");
         this.$store.commit("flight/emptyBasket");
         localStorage.setItem("mobileBasketAutoShown", false);
      },
      handlerThrowNewSearch() {
         this.handlerPrepareForNewSearch();
         this.$refs.flightInformationPopup.status = true;
         const query = this.$store.state.flight.search.request;

         this.sameDayFlight = query.legs[1].departureDate == query.legs[1]?.returnDate;

         delete query.searchUuid;
         query.uuid = generateUuid();

         flightService.flightSearch(query).then((response) => {
            this.$router.push({ name: "FlightSale", params: { uuid: query.uuid } });
            this.handlerSearchResponse(response);
         });
      },
      handlerSearchResponse(response) {
         if (response?.result.success) {
            this.$refs.flightInformationPopup.status = false;
            this.searchResponse = response;
            this.showResults = true;

            if (response.searchTripType > 0 && !response.completed) {
               this.handlerLazyLoad();
            }
         } else {
            this.handlerPrepareForNewSearch();
         }
      },
      handlerLazyLoad() {
         const lazyload = 6;
         let time = 1;
         this.lazyLoad.animation = true;

         //re-identify the request
         const query = this.$store.state.flight.search.request;
         query.searchUuid = this.searchResponse?.searchUuid;

         if (this.searchResponse && !this.searchResponse?.completed) {
            this.lazyLoad.interval = setInterval(() => {
               if (this.searchResponse?.completed) {
                  this.handlerClearLazyLoad();
                  return;
               } else {
                  if (time > lazyload) {
                     this.handlerClearLazyLoad();
                  } else {
                     this.handlerSelectBasket().then((result) => {
                        if (result.success) {
                           this.handlerLazySearch(query);
                        }
                     });
                  }
               }

               time++;
            }, 4000);
         }
      },
      handlerClearLazyLoad() {
         clearInterval(this.lazyLoad.interval);
         this.lazyLoad.animation = false;
      },
      waitForUuid() {
         return new Promise((resolve) => {
            const check = () => {
               if (this.$route.params.uuid) {
                  resolve();
               } else {
                  setTimeout(check, 100);
               }
            };
            check();
         });
      },
      async handlerSelectBasket() {
         await this.waitForUuid();

         return new Promise((res) => {
            let basketQuery = {
               legsFlights: this.$store.state.flight.basket,
               searchUuid: this.searchResponse?.searchUuid,
               uuid: this.$route.params.uuid,
            };

            flightService
               .flightSelect(basketQuery)
               .then((response) => {
                  console.log("Select İsteği Atıldı");
                  if (response.result.success) {
                     const offersCount = Object.keys(response.offers).length;
                     if (offersCount > 0) {
                        this.basket.response = response;
                     }

                     this.familyPrices = {
                        familyPrices: response.familiesPrices,
                        cheapestFamilyPrices: response.flightsCheapestFamilyPrices,
                        packagePrice: Object.values(response.offers).map((entry) => entry.price),
                        checkoutEnabled: response.checkoutEnabled,
                     };
                  }
                  res({ success: true });
               })
               .catch((err) => {
                  console.log("🚀 ~ flightService.flightSelect ~ err", err);
               });
         });
      },
      handlerLazySearch(query) {
         flightService.flightSearch(query).then((response) => {
            this.searchResponse.completed = response?.completed;
            //packageOffers
            this.handlerPackageOffers(response.packageOffers);

            Object.keys(response.legs).forEach((leg) => {
               Object.keys(response.legs[leg].flights).forEach((flight) => {
                  // $set for reactivity,
                  const relatedFlights = this.searchResponse?.legs[leg].flights;
                  const flightIdentifier = response.legs[leg].flights[flight].identifier;
                  const flightContent = response.legs[leg].flights[flight];
                  this.$set(relatedFlights, flightIdentifier, flightContent);
               });
            });
         });
      },
      handlerTriggerBasket() {
         this.$store.commit("app/setIsProgressGifActive", true);
         this.handlerSelectBasket().then((result) => {
            if (result.success) {
               this.$store.commit("app/setIsProgressGifActive", false);
            }
         });
      },

      handlerPackageOffers(offer) {
         if (offer.length != 0) {
            this.packageOffers = offer;
         }
      },
   },
   watch: {
      "$store.state.flight.basket": {
         handler: function (basket) {
            //Collapse selected legs
            collapseLeg(basket, this.searchResponse?.searchTripType);
            const basketCount = Object.keys(basket).length;
            if (basketCount == 0 && this.searchResponse) {
               this.handlerTriggerBasket();
               this.basket.response = null;
            } else if (this.searchResponse) {
               this.handlerTriggerBasket();
            } else {
               this.basket.response = null;
            }
         },
         deep: true,
      },

      "basket.response.offers": {
         handler: function (offers) {
            if (offers) {
               const basketCount = Object.keys(this.$store.state.flight.basket).length;
               this.$refs.basketMobile._data.basket.response = this.basket.response;

               // const mobileBasketAlreadyShownController =
               //    basketCount == this.searchResponse.searchTripType &&
               //    Object.keys(this.searchResponse.legs).length == this.$refs.basketMobile._data.basket.count;

               const mobileBasketAlreadyShown = localStorage.getItem("mobileBasketAutoShown");

               if (mobileBasketAlreadyShown == "false") {
                  if (basketCount == this.searchResponse?.searchTripType || basketCount == Object.keys(this.searchResponse?.legs).length) {
                     this.$refs.basketMobile._data.basket.status = true;
                     localStorage.setItem("mobileBasketAutoShown", true);
                  }
               }
            }
         },
      },
   },
};
</script>

<style lang="scss">
.gts-fluid {
   max-width: none !important;
   padding: 0 0.5rem;

   @media (min-width: 576px) {
      padding: 0 1.5rem;
   }
}
.flight-page-inner,
.hotel-page-inner {
   margin-top: 0;

   @media (min-width: 576px) {
      margin-top: 3rem;
   }

   #mobile-filter-basket {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;

      button {
         width: 100%;
         background-color: #fff;
         border-radius: 0.5rem;
         padding: 1rem 1rem;
         color: var(--primary);
         font-weight: 500;
         font-size: var(--large);
         box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      }
   }

   #mobile-informations {
      background-color: var(--primary);
      .legs {
         color: #fff;
         display: flex;
         width: 100%;
         align-items: center;
         justify-content: space-around;
         span {
            font-size: var(--xxlarge);
            font-weight: 600;
         }
      }
   }
}

#flight-search-wrapper,
#transfer-search-wrapper,
#rent-a-car-search-wrapper {
   margin-top: 3rem;
   padding-left: 0.75rem;
   padding-right: 0.75rem;

   @media (min-width: 992px) {
      padding-left: 1rem;
      padding-right: 1rem;
   }

   @media (min-width: 768px) {
      padding-left: 1rem;
      padding-right: 1rem;
   }
   @media (min-width: 1200px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
   }
   @media (min-width: 1400px) {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
   }
   @media (min-width: 1560px) {
      padding-left: 1rem;
      padding-right: 1rem;
   }

   #flight-filter-column {
   }
}
</style>
