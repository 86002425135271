<template>
   <div class="flight-leg-container">
      <section class="leg-header">
         <div class="leg-header-left-wrapper">
            <div class="leg-header-left-inner-wrapper-top">
               <!-- Route Informations -->
               <div class="leg-header-route-information">
                  <i class="d-none d-md-block gts-icon" :class="IsRoundTrip ? 'icon-direction-arrow-left' : 'icon-direction-arrow-right'"></i>
                  <span class="leg-header-route-title">{{ legRoute }}</span>
               </div>

               <!-- Date Change -->
               <div class="date-container">
                  <button class="gts-animation" @click="handlerSearchForNewDay('subtract')">
                     <i class="gts-icon icon-arrow-left icon-xs"></i>
                  </button>
                  <span class="date-display">{{ legDate | FormatterDate }}</span>
                  <button class="gts-animation" @click="handlerSearchForNewDay('add')">
                     <i class="gts-icon icon-arrow-right icon-xs"></i>
                  </button>
               </div>
            </div>
            <div class="leg-header-left-inner-wrapper-bottom">
               <UISelectBox id="select_customerGender" :options="options" v-model="FilterBy" @input="handleSelectFilter" light-theme />
            </div>
         </div>

         <!-- Leg Header Right -->
         <div class="leg-header-right-wrapper" @click="handlerLegToggler()">
            <div class="leg-header-toggler-wrapper">
               <i class="gts-icon icon-arrow-down"></i>
            </div>
         </div>
      </section>

      <!-- Leg Header -->
      <section class="leg-header" v-if="false">
         <div class="header-left">
            <div class="header-route-information">
               <i class="gts-icon" :class="IsRoundTrip ? 'icon-direction-arrow-left' : 'icon-direction-arrow-right'"></i>
               <h6>{{ legRoute }}</h6>
            </div>
            <div class="filter-mobile">
               <UISelectBox id="select_customerGender" :options="options" v-model="selectedDefault" @input="handleSelectFilter" light-theme v-if="false" />
               <select name="" id="">
                  <option v-for="(option, optionIndex) in options" :name="option.name" :value="option.value" :key="optionIndex">
                     {{ option.name }}
                  </option>
               </select>
            </div>
         </div>
         <div class="header-right">
            <div class="leg-controller-container is-hidden-mobile">
               <div class="date-container">
                  <button class="gts-animation" @click="handlerSearchForNewDay('subtract')">
                     <i class="gts-icon icon-arrow-left icon-xs"></i>
                  </button>
                  <span class="date-display">{{ legDate | FormatterDate }}</span>
                  <button class="gts-animation" @click="handlerSearchForNewDay('add')">
                     <i class="gts-icon icon-arrow-right icon-xs"></i>
                  </button>
               </div>

               <UISelectBox id="select_customerGender" :options="options" v-model="selectedDefault" @input="handleSelectFilter" light-theme v-if="true" />
            </div>

            <div class="leg-arrow-container" @click="handlerLegToggler()">
               <UITooltip :tooltip-title="$t('flightLegHeader_show/hideResults')" tooltip-icon="gts-icon icon-arrow-down" tooltip-position="bottom" noPadding> </UITooltip>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import UITooltip from "@/components/utils/GtsToolTips.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import moment from "moment";
export default {
   name: "FlightLegs",
   props: {
      legRoute: String,
      legDate: String,
      legIndex: Number,
      filterBy: String,
   },
   components: {
      UITooltip,
      UISelectBox,
   },
   data() {
      return {
         selectedDefault: "priceincrease",
         legToggler: true,
         options: [
            { id: 1, name: this.$i18n.t(`common_price_fpincrease`), value: "fpincrease" },
            { id: 2, name: this.$i18n.t(`common_price_fpdecrease`), value: "fpdecrease" },
            { id: 3, name: this.$i18n.t(`common_price_increase`), value: "priceincrease" },
            { id: 4, name: this.$i18n.t(`common_price_decrease`), value: "pricedecrease" },
            { id: 5, name: this.$i18n.t(`common_departure_increase`), value: "departureincrease" },
            { id: 6, name: this.$i18n.t(`common_departure_decrease`), value: "departuredecrease" },
            { id: 7, name: this.$i18n.t(`common_arrivaltime_increase`), value: "arrivaltimeincrease" },
            { id: 8, name: this.$i18n.t(`common_arrivaltime_decrease`), value: "arrivaltimedecrease" },
            { id: 9, name: this.$i18n.t(`common_totaltraveltime_increase`), value: "totaltraveltimeincrease" },
            { id: 10, name: this.$i18n.t(`common_totaltraveltime_decrease`), value: "totaltraveltimedecrease" },
            { id: 11, name: this.$i18n.t(`common_totalwaitingtime_increase`), value: "totalwaitingtimeincrease" },
            { id: 12, name: this.$i18n.t(`common_totalwaitingtime_decrease`), value: "totalwaitingtimedecrease" },
            { id: 13, name: this.$i18n.t(`common_transfers_increase`), value: "transferincrease" },
            { id: 14, name: this.$i18n.t(`common_transfers_crease`), value: "transferdecrease" },
         ],
      };
   },
   computed: {
      FilterBy: {
         get() {
            return this.filterBy;
         },
         set(newValue) {
            this.$emit("flight-filters", { filterName: newValue });
         },
      },
      handlerDate() {
         return moment(this.dateInformation).format("DD.MM.YYYY");
      },
      IsRoundTrip() {
         const self = this;
         return self.$store.state.flight.search.searchType == "round-trip" && self.legIndex == 2;
      },
   },
   methods: {
      handleSelectFilter(selection) {
         this.$emit("flight-filters", { filterName: selection });
      },
      handlerLegToggler() {
         this.$emit("clear-selection");
         const otherFilter = this.legIndex == 1 ? 2 : 1;

         if (this.$store.state.flight.filters[otherFilter] != undefined) {
            this.$store.state.flight.filters[otherFilter].filters.hours.arrival = [0, 24];
            this.$store.state.flight.filters[otherFilter].filters.hours.departure = [0, 24];
         }
      },
      handlerThrowNewSearch() {
         this.$parent.$parent.$emit("throw-new-search");
      },
      handlerSearchForNewDay(prevORnext) {
         const searchTripType = this.$parent._props.tripType;
         const requestLegs = this.$store.state.flight.search.request.legs;
         var currentDay;
         if (this.legIndex == 2 && searchTripType == 2) {
            currentDay = this.$store.state.flight.search.request.legs[this.legIndex - 1].returnDate;
            const newDate = moment(currentDay, this.$constants.dateFormat)[prevORnext](1, "day").format(this.$constants.dateFormat);
            requestLegs[this.legIndex - 1].returnDate = newDate;
         } else {
            currentDay = this.$store.state.flight.search.request.legs[this.legIndex].departureDate;
            const newDate = moment(currentDay, this.$constants.dateFormat)[prevORnext](1, "day").format(this.$constants.dateFormat);
            requestLegs[this.legIndex].departureDate = newDate;
         }
         this.handlerThrowNewSearch();
      },
   },
};
</script>

<style lang="scss" scoped>
.flight-leg-container {
   .leg-header {
      display: flex;
      justify-content: space-between;
      background-color: var(--primary);
      border-radius: 0.5rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .leg-header-left-wrapper {
         width: 100%;
         padding: 1rem 1rem;

         .leg-header-left-inner-wrapper-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // background: lightblue;
            width: 100%;
            .leg-header-route-information {
               display: flex;
               align-items: center;
               span.leg-header-route-title {
                  color: #fff;
                  font-weight: 600;
                  font-size: var(--xlarge);
                  margin-left: 0.5rem;
               }
               i {
                  filter: brightness(10);
               }
            }
            .date-container {
               font-size: 1rem;
               color: #fff;
               font-weight: 600;
               display: flex;
               align-items: center;

               span {
                  padding: 0 0.5rem;

                  &.date-display {
                     font-size: var(--large);
                  }
               }
               button {
                  outline: 0;
                  border: 0;
                  background-color: transparent;
                  border-radius: 50%;
                  padding: 0.2rem;
                  width: 30px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  i {
                     filter: brightness(100);
                  }
               }
            }
         }
         .leg-header-left-inner-wrapper-bottom {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 0.5rem;

            ::v-deep {
               .g-select-box-container {
                  width: fit-content;
               }
               .g-select-box {
                  min-height: 28px !important;
               }
            }
         }
      }
      .leg-header-right-wrapper {
         display: flex;
         cursor: pointer;

         .leg-header-toggler-wrapper {
            border-top-right-radius: 0.5rem;
            background-color: #00000030;
            padding: 2rem 1rem;

            &:hover {
               background-color: #00000050;
            }

            i.icon-arrow-down {
               filter: brightness(10);
            }
         }
      }
   }
}
</style>

<style lang="scss" scoped>
.flight-leg-container3 {
   ::v-deep {
      .g-select-box {
         min-height: 20px !important;
      }
   }
   .leg-header {
      background: var(--primary);
      border-radius: 0.5rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (min-width: 768px) {
         align-items: flex-start;
      }

      .header-left {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         // margin-left: 0.5rem;
         margin-left: 1rem;
         padding-top: 1rem;

         @media (min-width: 992px) {
            margin-left: 1rem;
         }

         .header-route-information {
            display: flex;
            align-items: center;
            h6 {
               color: #fff;
               display: flex;
               font-size: 1.375rem;
               font-weight: 600;
            }
            i {
               margin-right: 0.25rem;
               filter: brightness(10);
               transform: scale(0.8);
               display: none;
               @media (min-width: 992px) {
                  margin-right: 1rem;
                  transform: scale(1);
                  display: block;
               }
            }
         }

         .filter-mobile {
            margin-top: 0.5rem;

            @media (min-width: 576px) {
               display: none;
            }
         }
      }
      .header-right {
         display: flex;

         .leg-arrow-container {
            background-color: #00000030;
            padding: 0 1rem;
            display: flex;
            align-items: center;
            border-top-right-radius: 0.5rem;
            cursor: pointer;

            @media (min-width: 992px) {
               padding: 0 1rem;
            }

            ::v-deep i {
               filter: brightness(10);
            }

            &:hover {
               background-color: #00000050;
            }
         }

         .leg-controller-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 0.75rem;
            padding: 1rem 0.5rem;
            .date-container {
               font-size: 1rem;
               color: #fff;
               font-weight: 600;
               display: flex;
               margin-bottom: 0.75rem;
               align-items: center;

               span {
                  padding: 0 0.5rem;

                  &.date-display {
                     font-size: var(--large);
                  }
               }
               button {
                  outline: 0;
                  border: 0;
                  background-color: transparent;
                  border-radius: 50%;
                  padding: 0.2rem;
                  width: 30px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  i {
                     filter: brightness(100);
                  }
               }
            }
         }
      }
   }
}
</style>

<style lang="scss">
.flight-leg-container3 {
   .leg-controller-container {
      .control {
         select {
            padding: 0 0.5rem !important;
            height: auto !important;
         }
         .select:not(.is-multiple) {
            height: auto !important;
         }
      }
   }
}
</style>
