export const EHtlVoucherStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "BOOKED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REBOOKED",
   8: "ERROR",
   10: "PENDING_BOOKED",
   11: "PENDING_RESERVED",
   12: "PENDING_CANCELED",
   13: "PENDING_VOIDED",
   14: "PENDING_REFUNDED",
   15: "PARTIAL_PAID",
};

export const EHtlRoomPriceStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "BOOKED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REBOOKED",
   8: "ERROR",
   9: "PARTIAL_PAID",
   10: "PENDING_RESERVED",
   11: "PENDING_BOOKED",
   12: "PENDING_CANCELED",
   13: "PENDING_VOIDED",
   14: "PENDING_REFUNDED",
};

export const EHtlGuestType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "ADULT",
   2: "CHILD",
};

export const EReportHotelDateType = {
   "-1": "ALL",
   0: "UNKNOWN",
   2: "SALE",
   3: "CHECK_IN",
   4: "CHECK_OUT",
};

export const EHtlRefundType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "REFUNDABLE",
   2: "NONREFUNDABLE",
};

export const EHtlPriceRestrictionType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NO_RESTRICTION",
   2: "MIN_STAY",
   3: "STOP",
   4: "RELEASE",
   5: "QUOTA",
};
export const EHtlPolicyType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "CANCELLATION",
   2: "NO_SHOW",
};
export const EHtlAmountType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "RATIO",
   2: "FIXED",
};

export const EOdrSearchHotelDateType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "PROCESS",
   2: "CHECKIN",
};
