import Vue from "vue";
import moment from "moment";
import i18n from "./fmt/i18n";
import store from "@/store";
import { flightDuration } from "@/helper/flight.js";

// // @/enums folder
// import * as AccountingEnums from "@/enums/accountingEnums.js";
// import * as FlightEnums from "@/enums/flightEnums.js";
// import * as HotelEnums from "@/enums/hotelEnums.js";
// import * as TransferEnums from "@/enums/transferEnums";
// import * as RentacarEnums from "@/enums/rentacarEnums";
// import * as OrderEnums from "@/enums/orderEnums.js";
// import * as PaymentEnums from "@/enums/paymentEnums.js";
// import * as UserEnums from "@/enums/userEnums.js";
// import * as TaskEnums from "@/enums/taskEnums.js";
// import * as CommonEnums from "@/enums/commonEnums.js";
// import * as DashboardEnums from "@/enums/dashboardEnums.js";

import * as FlightFilters from "@/filters/flightFilters.js";
import "./enum.filters";

// //Uses
// FlightEnums;
// CommonEnums;
FlightFilters;
// HotelEnums;
// TransferEnums;
// RentacarEnums;
// AccountingEnums;
// OrderEnums;
// PaymentEnums;
// UserEnums;
// TaskEnums;
// DashboardEnums;

Vue.filter("duration", (value) => {
   return flightDuration(value);
});

Vue.filter("fakiePhoto", (value) => {
   let pp = value.split(" ");
   if (pp.length > 1) {
      return pp[0][0] + pp[pp.length - 1][0];
   } else {
      return pp[0][0];
   }
});

Vue.filter("ancillary", (value) => {
   switch (value) {
      case 1:
         return "baggage";
      case 2:
         return "seat";

      case 3:
         return "food";
      case 4:
         return "wheelchair";
      case 5:
         return "other";
   }
});
Vue.filter("ago", (value) => {
   return moment(value).fromNow(true);
});
Vue.filter("phone", (value) => {
   let hasNull = Object.keys(value).every((e) => {
      return value[e] == null;
   });
   if (!hasNull) {
      return `${value.country} ${value.city} ${value.number}`;
   } else {
      return "";
   }
});

//Html tag'lerinin temizlenmesi için kullanılabilecek filter
Vue.filter("stripHTML", function (value) {
   const div = document.createElement("div");
   div.innerHTML = value;
   const text = div.textContent || div.innerText || "";
   return text;
});

//SPA Genelinde Price Format İçin Kullanılması Gereken Price Filter
Vue.filter("price", (value, currency) => {
   if (value == null) return "";

   let displayCurrency = currency;
   if (currency === "USD") {
      displayCurrency = "USD";
   } else if (currency === "TRY") {
      displayCurrency = "TRY";
   } else {
      displayCurrency = "EUR";
   }

   let display = value.toLocaleString("de-DE", {
      style: "currency",
      currency: displayCurrency,
      minimumFractionDigits: 2,
   });

   if (currency === "USD") {
      display = display.replace("USD", "$");
   } else if (currency === "TRY") {
      display = display.replace("TRY", "₺");
   } else if (currency === false) {
      display = display.replace("€", "");
   } else {
      display = display.replace("EUR", "€");
   }

   return display;
});

//Old Price Format
Vue.filter("price2", (value) => {
   if (value == null) return "";
   let display = value.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
   });
   return display;
});

//Old Price Format
//Standartlaştırma sonrası filtreler de elden geçmeli .text meselesi..
Vue.filter("price3", (value) => {
   if (value == null) return "";
   let display = value.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
   });
   return display;
});

// Flight Enums End

Vue.filter("currency", (value) => {
   switch (value) {
      case "EUR":
         return "€";
      case "USD":
         return "$";
      case "TL":
         return "₺";
      default:
         return "€";
   }
});

Vue.filter("FormatterDateTime", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("DD.MM.YYYY HH:mm:ss");
});

Vue.filter("FormatterDate", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("DD.MM.YYYY");
});

Vue.filter("FormatterDD_MMMM_YYYY", (value) => {
   if (!value) {
      return "";
   }
   const DD = moment(value).format("DD");
   const MMMM = i18n.t(`common_${moment(value).format("MMMM").toLowerCase()}`);
   const YYYY = moment(value).format("YYYY");
   return `${DD} ${MMMM} ${YYYY}`;
});

Vue.filter("FormatterDD_MM_YYYY_HH_mm", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("DD.MM.YYYY HH:mm");
});

Vue.filter("FormatterTime", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("HH:mm");
});
Vue.filter("FormatterTimeNoZero", (value) => {
   if (!value) {
      return "";
   }
   if (moment(value).format("HH:mm") == "00:00") {
      return "";
   } else {
      return moment(value).format("HH:mm");
   }
});
Vue.filter("FormatterDD", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("DD");
});
Vue.filter("FormatterMMM", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("MMM");
});
Vue.filter("FormatterYYYY", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("YYYY");
});

//TODO:FormatterDate kullan
//FormatterXYZ şeklinde formatter ile başlayabilirler!!!

Vue.filter("fullDateFormat", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("DD.MM.YYYY");
});

Vue.filter("fullDateTextFormat", (value) => {
   if (!value) {
      return "";
   }
   const DD = moment(value).format("DD");
   const MMMM = i18n.t(`common_${moment(value).format("MMMM").toLowerCase()}`);
   const YYYY = moment(value).format("YYYY");
   return `${DD} ${MMMM} ${YYYY}`;
});

Vue.filter("fullDateShortMonth", (value) => {
   if (!value) {
      return "";
   }
   const DD = moment(value).format("DD");
   const MMM = i18n.t(`common_${moment(value).format("MMM").toLowerCase()}`);
   const YYYY = moment(value).format("YYYY");
   return `${DD} ${MMM} ${YYYY}`;
});
Vue.filter("basketDate", (value) => {
   if (!value) {
      return "";
   } else {
      const DD = moment(value).format("DD");
      const MMM = i18n.t(`common_${moment(value).format("MMM").toLowerCase()}`);
      const ddd = i18n.t(`common_${moment(value).format("ddd").toLowerCase()}`);

      // let dt = String(moment(value).format("dddd")).substring(0, 3);
      return `${DD} ${MMM} ${ddd}`;
   }
});

Vue.filter("TimeFormat", (value) => {
   if (!value) {
      return "";
   }

   return moment(value).format("HH:mm");
});

Vue.filter("onlyDay", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("DD");
});
Vue.filter("onlyDayFullName", (value) => {
   if (!value) {
      return "";
   }
   return i18n.t(`common_${moment(value).format("dddd").toLowerCase()}`);
});

Vue.filter("onlyMonth", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).lang(i18n.locale).format("MMMM");
});

Vue.filter("onlyYear", (value) => {
   if (!value) {
      return "";
   }
   return moment(value).format("YYYY");
});
Vue.filter("cancellationPolicyItemDate", (value) => {
   if (!value) {
      return "";
   }

   return moment(value).format("DD.MM.YYYY HH:mm");
});

Vue.filter("fullPhone", (value) => {
   if (!value) {
      return "";
   }

   return `${value.country}${value.city}${value.number}`;
});

Vue.filter("notification", (value) => {
   switch (value) {
      case "TASK_CHAT":
         return "task-chat";
      case "INVOICE_CREATE":
         return "invoice-create";
      case "NOTIFICATION":
         return "default";
      case "SUCCESS":
         return "success";
      case "WARNING":
         return "warning";
   }
});
Vue.filter("notificationType", (value) => {
   switch (value) {
      case 1:
         return "task-chat";
      case 4:
         return "invoice-create";
      default:
         return "default";
   }
});

Vue.filter("gender", (value) => {
   switch (value) {
      case 1:
         return i18n.t("common_male");
      case 2:
         return i18n.t("common_female");
   }
});

Vue.filter("guestType", (value) => {
   switch (value) {
      case 1:
         return i18n.t("common_adult");
      case 2:
         return i18n.t("common_child");
      case 3:
         return i18n.t("common_infant");
   }
});

Vue.filter("facilitiesUrl", (value) => {
   if (!value) {
      return "";
   }

   return `${store.state.hotel.facilitiesBaseUrl}${value}.svg`;
});

Vue.directive("click-outside", {
   bind(el, binding, vnode) {
      el.clickOutsideEvent = (event) => {
         if (!(el === event.target || el.contains(event.target)) && event.target.className.indexOf("modal-trigger-btn") <= 0 && event.target.className.indexOf("deny-click-outside") <= 0) {
            vnode.context[binding.expression](event);
         }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
   },
   unbind(el) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
   },
});

const generateUuid = () => {
   var dt = new Date().getTime();
   var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
   });
   // var uuid = "5eb26642-aa4f-4a03-bad5-9bd43dbe712a";
   return uuid;
};

const clearObject = (obj) => {
   var objItems = Object.getOwnPropertyNames(obj);
   for (var i = 0; i < objItems.length; i++) {
      obj[objItems[i]] = "";
   }
};

const setCookie = (name, value, days, hours) => {
   let expires = "";
   const date = new Date();
   if (days > 0) {
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
   }
   if (hours > 0) {
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
   }
   expires = `; expires=${date.toUTCString()}`;

   const secureFlag = "; secure";

   document.cookie = `${name}=${value}${expires}; path=/${secureFlag}`;
};

const getCookie = (name) => {
   const nameEQ = `${name}=`;
   const ca = document.cookie.split(";");

   for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
         c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) == 0) {
         return c.substring(nameEQ.length, c.length);
      }
   }

   return null;
};
const deleteCookie = (name) => {
   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const getAllCookies = () => {
   const allCookies = document.cookie.split(";");
   let str = "";

   for (let i = 0; i < allCookies.length; i++) {
      str += allCookies[i].replace(/\s/g, "") + "\n";
   }

   return str;
};

const sleep = (milliseconds) => {
   return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export { generateUuid, clearObject, setCookie, getCookie, deleteCookie, getAllCookies, sleep };
