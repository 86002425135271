<template>
   <div class="flight-leg-wrapper">
      <!-- Flight Info Board -->
      <section class="flight-info-board">
         <h2>{{ leg.index }}. {{ $t("flightInformationsComponent_flight") }}</h2>
         <span class="flight-color masked-background-dark" :style="{ 'background-color': $options.filters.airlineColor(handlerLegColor) }"></span>
      </section>

      <div class="segments-wrapper">
         <div class="segment-inner-container" v-for="(segment, segmentIndex) in leg.segments" :key="segmentIndex">
            <!-- Segment Left Container -->
            <section class="segment-left">
               <!-- Departure Informations -->
               <div class="segment-information-box">
                  <span class="airport-name-abbr masked"> {{ segment.departureAirportCode }}</span>
                  <span class="airport-name masked"> {{ handlerAirportName(segment, "departure") }}</span>

                  <!-- <time :datetime="segment.time" class="masked" :class="segment.departureDateTimeChanged ? 'time-change-info' : ''"> -->

                  <time class="time-information masked" :class="segment.departureDateTimeChanged ? 'has-change' : ''">
                     {{ segment.departureDateTime | fullDateTextFormat }} {{ segment.departureDateTime | TimeFormat }}
                  </time>
               </div>

               <!-- Flight Informations -->
               <div class="segment-information-box centered">
                  <img
                     :src="[segment.marketingAirlineCode ? `${$constants.cdn}/airline_34x34/${segment.marketingAirlineCode}.svg` : '']"
                     @error="$event.target.src = `${$constants.cdn}/airline_34x34/00.svg`"
                     alt=""
                     width="48"
                     height="48"
                     class="masked-img" />

                  <span class="flight-number masked">{{ segment.flightNumber }}</span>

                  <time v-if="segment.travelTime" class="masked">
                     <UITooltip :tooltip-title="$t('flightInformationsComponent_travelDuration')" tooltip-icon="" tooltip-position="bottom" no-padding>
                        {{ segment.travelTime | duration }}
                     </UITooltip>
                  </time>
                  <span class="flight-operated-airline masked" v-if="segment.operatingAirlineCode != segment.marketingAirlineCode">
                     {{ $t("flightCard_operatedBy") }} {{ $t(`airlineEnum_${segment.operatingAirlineCode}`) }}
                  </span>
               </div>

               <!-- Arrival Informations -->
               <div class="segment-information-box">
                  <span class="airport-name-abbr masked">{{ segment.arrivalAirportCode }}</span>
                  <span class="airport-name masked">{{ handlerAirportName(segment, "arrival") }}</span>
                  <div class="time-information masked">
                     <time class="time-information masked" :class="segment.departureDateTimeChanged ? 'has-change' : ''">
                        {{ segment.arrivalDateTime | fullDateTextFormat }} {{ segment.arrivalDateTime | TimeFormat }}
                     </time>
                  </div>
               </div>
            </section>

            <!-- Segment Right Container -->
            <section class="segment-right">
               <div class="segment-row">
                  <div class="info-box">
                     <span class="info-title">{{ $t("flightInformationsComponent_cabinType") }}</span>
                     <div class="info-content masked" :class="segment.cabinTypeChanged ? 'has-change' : ''">
                        {{ segment.cabinType | EFltCabinType }}
                     </div>
                  </div>
                  <div class="info-box">
                     <span class="info-title">{{ $t("flightInformationsComponent_class") }}</span>
                     <div class="info-content masked" :class="segment.flightClassChanged ? 'has-change' : ''">{{ segment.flightClass }}</div>
                  </div>
               </div>

               <div class="segment-row">
                  <div class="info-box">
                     <span class="info-title">{{ $t("flightInformationsComponent_baggageAllowance") }}</span>
                     <div class="info-content masked">
                        <ul class="info-baggages">
                           <template v-for="(paxbag, paxkey, index) in segment.paxesBaggages">
                              <li :key="index" v-if="paxbag.length > 0">{{ $t(`common_${paxkey.toLowerCase()}`) }}: {{ paxbag }}</li>
                           </template>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </div>
   </div>
</template>

<script>
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "FlightLeg",
   props: {
      leg: Object,
   },
   components: {
      UITooltip,
   },

   computed: {
      handlerLegColor() {
         const self = this;
         let color = null;
         Object.keys(self.leg.segments).forEach((segment) => {
            color = self.leg.segments[segment].marketingAirlineCode;
         });

         return color;
      },
   },
   methods: {
      handlerAirportName(segment, type) {
         const propertyName = `${type}AirportNameDic`;
         switch (this.$store.state.user.user.language) {
            case 1:
               return segment[propertyName]?.TURKISH;
            case 2:
               return segment[propertyName]?.ENGLISH;
            case 3:
               return segment[propertyName]?.GERMAN;
            default:
               return segment[propertyName]?.ENGLISH;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.flight-leg-wrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   margin-bottom: 1rem;
   position: relative;

   @media (min-width: 576px) {
      flex-direction: row;
   }

   .flight-info-board {
      border-radius: 10px;
      display: inline-flex;
      position: relative;
      overflow: hidden;
      flex-grow: 0;
      min-width: 45px;

      border: 1px solid #cccccc;

      h2 {
         writing-mode: horizontal-tb;
         text-orientation: unset;
         transform: rotate(0);
         text-align: center;
         font-size: var(--large);
         font-weight: 600;
         margin-left: 0.75rem !important;
         margin-right: 0.75rem !important;
         padding: 0.5rem 0;

         @media (min-width: 576px) {
            padding: 0;
            writing-mode: tb;
            transform: rotate(180deg);
         }
      }
      .flight-color {
         width: 24px;
         height: 24px;
         border-radius: 50%;
         display: inline-block;
         position: absolute;
         right: 0.5rem;
         top: 0;
         bottom: 0;
         margin: auto;

         @media (min-width: 576px) {
            width: 32px;
            height: 32px;
            right: 0;
            width: 4px;
            height: 70%;
            border-radius: 10px;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
         }
      }
   }
   .segments-wrapper {
      width: 100%;
      margin-top: 1rem;

      @media (min-width: 576px) {
         margin-left: 1rem;
         margin-top: 0;
      }

      .segment-inner-container {
         display: flex;
         background: #fafafa;
         border-radius: 12px;
         border: 1.5px solid #d3d3d3;
         position: relative;
         padding: 1rem;
         margin: 0.75rem 0;
         flex-direction: column;

         @media (min-width: 576px) {
            flex-direction: row;
         }

         .segment-left {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            @media (min-width: 576px) {
               width: 75%;
               border-right: 1px solid #ccc;
            }

            .segment-information-box {
               display: flex;
               flex-direction: column;
               width: 100%;
               padding: 0 1rem;

               span.airport-name-abbr {
                  font-size: var(--large);
                  font-weight: 600;
                  color: var(--pastel-primary);
               }
               span.airport-name {
                  font-size: var(--small);
               }
               span.flight-number {
                  font-weight: 500;
                  font-size: var(--small);
               }
               span.flight-operated-airline {
                  font-weight: 400;
                  font-size: var(--xsmall);
                  color: #ff4c4c;
               }
               .time-information {
                  display: flex;
                  align-items: center;

                  time {
                     font-size: var(--normal);
                     font-weight: 500;
                  }
               }

               &.centered {
                  align-items: center;
               }

               &.centered {
                  time {
                     font-size: var(--small);
                     strong {
                        font-weight: 400;
                     }
                  }
               }
            }
         }
         .segment-right {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: left;
            padding: 0 1rem;
            border-top: 1px solid #d3d3d3;
            margin-top: 0.5rem;
            padding-top: 0.5rem;

            @media (min-width: 576px) {
               width: 25%;
               flex-direction: column;
               border-top: none;
               margin-top: 0;
               padding-top: 0;
            }

            .segment-row {
               display: flex;
               margin-bottom: 0.5rem;
               .info-box {
                  width: 100%;
                  padding: 0 1rem;
                  .info-title {
                     font-size: var(--small);
                     opacity: 0.8;
                     white-space: nowrap;
                  }
                  .info-content {
                     font-size: var(--large);
                     font-weight: 600;
                     white-space: nowrap;
                     text-transform: capitalize;

                     ul.info-baggages {
                        li {
                           font-size: var(--normal);
                           font-weight: 500;
                           color: var(--txt-black);
                        }
                     }
                  }
               }
            }
         }

         &:first-child {
            margin-top: 0 !important;
         }
         &:last-child {
            margin-bottom: 0 !important;
         }
      }
   }
}
</style>
<style lang="scss">
.has-change {
   color: #ff4c4c;
   font-weight: 500;
   position: relative;

   @keyframes attention {
      0% {
         opacity: 0;
      }
      80% {
         opacity: 1;
      }
      100% {
         opacity: 1;
      }
   }

   &::before {
      content: "";
      opacity: 0;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: -0.75rem;
      border-radius: 50%;
      background-color: #ff4c4c;
      animation-name: attention;
      animation-fill-mode: backwards;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
      animation-duration: 1s;
   }
}
</style>
