<template>
   <div class="hotel-card-debugger-container">
      <div class="hotel-card-debugger-inner-container">
         <span>{{ isCacheData == true ? "Cache Data" : "Online Data" }}</span>
         <span>Provider Name : {{ providerName }}</span>
         <span> Identifier : {{ debuggerData }}</span>
         <div v-if="cancellationPolicy.length != 0">
            <div class="cancellation-policy" v-for="(item, index) in cancellationPolicy" :key="index">
               <span>------------------------------------</span>
               <span
                  >Cancellation Policies :
                  <span>{{ $options.filters.EHtlPolicyType(item.policyType) }}</span>
               </span>
               <span>Policy Type : {{ $options.filters.EHtlAmountType(item.type) }}</span>
               <span>FromDate : {{ item.fromDate }} || ToDate : {{ item.toDate }} </span>
               <span>Value : {{ item.value }} || Currency : {{ item.currency }} </span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "CacheRoomCardDebugger",
   props: {
      debuggerData: String,
      isCacheData: Boolean,
      cancellationPolicy: Array,
      providerName: String,
   },
};
</script>

<style lang="scss" scoped>
.hotel-card-debugger-container {
   background-color: #30353c;
   color: #32cd32;
   font-family: monospace;
   font-weight: 500;
   font-size: 1rem;
   padding: 0.5rem;
   margin: 0.5rem;
   border-radius: 0.5rem;

   .hotel-card-debugger-inner-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;

      .cancellation-policy {
         display: flex;
         flex-direction: column;
      }
   }
}
</style>
