import i18n from "@/fmt/i18n.js";
export const ancillariesListFunction = (ancillaryChoices) => {
   ancillaryChoices;
   const ancillariesList = ["baggage", "onlineCheckin", "divide", "reissue", "seat", "wheelchair", "meal", "ssr"];
   return ancillariesList.reduce((acc, ancillary, index) => {
      acc[ancillary] = {
         name: ancillary == "ssr" ? i18n.t(`ancillariesServices_SunFlex3`) : i18n.t(`ancillariesServices_${ancillary}`),
         description: ancillary == "ssr" ? i18n.t(`ancillariesServices_descriptionSunFlex3`) : i18n.t(`ancillariesServices_description${ancillary}`),
         showDescription: false,
         type: index + 1,
         order: index + 1,
         icon: ancillary == "ssr" ? `icon-ancillary-c-sunflex3` : `icon-ancillary-c-${ancillary}`,
         isLoading: false,
         enabled: ancillaryChoices[ancillary],
      };
      return acc;
   }, {});
};
