<template>
   <div class="reissue-search-container">
      <div class="reissue-search-inputs-wrapper" v-if="false">
         <AncillaryReissueInputLocation ref="departureAirport" label="Departure Airport" :autocomplete-input="flight.departureAirportPlaceholder" />
      </div>

      <template v-for="(leg, legIndex) in Legs">
         <AncillaryReissueSearchLeg :key="legIndex" :leg="leg" @selected-flight="handlerSelectFlight" :leg-control="LegShow" :airports="airports" :allow-details="AllowCalculation" />
      </template>

      <template v-if="showPrices">
         <AncillaryReissuePriceView :payment-choice="calculator.paymentChoiceDetail" />
      </template>
      <template v-else>
         <AncillaryReissueCalculator v-if="AllowCalculation" @calculate="handlerReissueCalculate" :calculator="calculator" />
      </template>
   </div>
</template>

<script>
// import { generateUuid } from "@/utils.js";
import { flightService } from "@/service/index.js";
import AncillaryReissueSearchLeg from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueSearchLeg.vue";
import AncillaryReissueCalculator from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueCalculator.vue";
import AncillaryReissuePriceView from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissuePriceView.vue";
import moment from "moment";
// import AncillaryReissueDatepicker from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueDatepicker.vue";
// import AncillaryReissueInputLocation from "@/components/structural/flight/order-detail/ancillaries/ancillary-reissue/AncillaryReissueInputLocation.vue";
export default {
   name: "AncillaryReissueSearch",
   props: {
      legs: Object,
      searchUuid: String,
      flights: Array,
      airports: Object,
   },
   components: {
      AncillaryReissueSearchLeg,
      AncillaryReissueCalculator,
      AncillaryReissuePriceView,
      // AncillaryReissueInputLocation,
      // AncillaryReissueDatepicker,
   },
   data() {
      return {
         showPrices: false,
         departureFlight: null,
         calculator: {
            calculating: false,
            result: {},
            paymentChoiceDetail: {},
         },
      };
   },

   computed: {
      Legs() {
         return this.legs;
      },

      LegShow() {
         let identifier = {};
         if (Object.keys(this.Legs).length == 1) {
            const propName = Object.keys(this.Legs)[0] == 1 ? "departureLeg" : "returnLeg";
            identifier[propName] = this.Legs[Object.keys(this.Legs)[0]]?.isSelected;
         } else {
            identifier = {
               departureLeg: this.Legs[1].isSelected,
               returnLeg: this.Legs[2]?.isSelected,
            };
         }

         return identifier;
      },
      AllowCalculation() {
         return Object.keys(this.LegShow).every((item) => {
            if (this.LegShow[item] != undefined) {
               return this.LegShow[item];
            }
         });
      },

      IsSameDay() {
         if (Object.keys(this.Legs).length == 2) {
            const legOneDate = moment(this.Legs[1].date).format("YYYY-MM-DD");
            const legTwoDate = moment(this.Legs[2].date).format("YYYY-MM-DD");
            return legOneDate == legTwoDate && this.LegShow.departureLeg ? true : false;
         } else {
            return false;
         }
      },
   },
   methods: {
      handlerReissueCalculateControll(query) {
         // console.log("handlerReissueCalculateControll Legs", this.legs);
         // console.log("handlerReissueCalculateControll Query", query);

         let arr = [];

         query.flightIdentifiers.forEach((identifier, index) => {
            const segmentLength = this.legs[index + 1].flights[identifier].segments.length;
            const dateIdentifier = index + 1 == 1 ? "arrivalDateTime" : "departureDateTime";
            const dateTime = this.legs[index + 1].flights[identifier].segments[segmentLength - 1][dateIdentifier];
            arr.push(dateTime);
         });

         let start = moment(arr[0]);
         let end = moment(arr[1]);
         let duration = moment.duration(end.diff(start));

         if (this.IsSameDay) {
            return duration.asHours() > 2 ? true : false;
         } else {
            return true;
         }
      },
      handlerReissueCalculate() {
         this.calculator.calculating = true;
         const query = {
            uuid: sessionStorage.getItem("reissueUuid"),
            searchUuid: this.searchUuid,
            flightIdentifiers: [],
         };

         Object.keys(this.legs).forEach((leg) => {
            Object.keys(this.legs[leg].flights).forEach((flight) => {
               if (this.legs[leg].flights[flight].selected) {
                  query.flightIdentifiers.push(flight);
               }
            });
         });
         // console.log("handlerReissueCalculate", query.flightIdentifiers.length);
         const handlerReissueCalculateControll = query.flightIdentifiers.length == 2 ? this.handlerReissueCalculateControll(query) : true;

         flightService.ancillaryReissueCalculate(query).then((res) => {
            if (handlerReissueCalculateControll) {
               if (res.result.success) {
                  this.calculator.calculating = false;
                  this.calculator.paymentChoiceDetail = res.paymentChoiceDetail;
                  this.$set(this.calculator.paymentChoiceDetail, "totalAmount", res.totalAmount);
                  this.showPrices = true;
               } else {
                  this.calculator.paymentChoiceDetail = {};
                  this.calculator.result = res.result;
                  this.calculator.calculating = false;
               }
            } else {
               this.calculator.paymentChoiceDetail = {};
               this.calculator.result = {
                  success: false,
                  app: "",
                  service: "",
                  code: "ERROR",
                  subCode: "FLIGHT_SAME_DAY",
                  message: "",
               };
               this.calculator.calculating = false;
            }
         });
      },
      handlerSelectFlight(obj) {
         if (obj.legIndex == 1) {
            const segmentLength = this.legs[obj.legIndex].flights[obj.identifier].segments.length;
            const firstFlightDepartureTime = this.legs[obj.legIndex].flights[obj.identifier].segments;
            firstFlightDepartureTime;
            this.departureFlight = firstFlightDepartureTime[segmentLength - 1].arrivalDateTime;
         }

         this.$emit("select-flight", obj);
      },
   },
   watch: {
      AllowCalculation: {
         handler: function (value) {
            if (value == false) {
               this.showPrices = false;
               this.calculator.result = {};
            }
         },
      },
      showPrices: {
         handler: function (value) {
            if (value) {
               this.$emit("allow-payment-step", true);
               this.$parent.paymentChoiceDetail = this.calculator.paymentChoiceDetail;
            } else {
               this.$emit("allow-payment-step", false);
               this.$parent.paymentChoiceDetail = {};
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.reissue-search-container {
   width: 100%;
   min-height: 400px;
   .reissue-calculator-wrapper {
   }

   .reissue-search-inputs-wrapper {
      background-color: #f4f6fa;
      border-radius: 1rem;
      border: 1px solid;
      padding: 1rem;
      margin-bottom: 1rem;
   }
}
</style>
