import { PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";
import Popup from "@/gts-popup.js";
import i18n from "@/fmt/i18n.js";

const AUTOCOMPLETE = "api/autoComplete/airport";
const SEARCH = "api/flightsearch/search";
const SELECT = "api/flightsearch/select";
const CHECKOUT = "api/flightsale/checkout";
const RERESERVE = "api/flightsale/rereserve";
const TICKET_RESERVED = "api/flightsale/ticketreserved";
const REVERSE_INQUIRY = "api/flightreverse/flightreverseinquiry";
const ANCILLARY_BAGGAGE = "api/flightancillary/searchbaggages";
const TICKET_BAGGAGES = "api/flightancillary/ticketbaggages";
const GET_PAYMENT_CHOICE_DETAIL = "api/payment/getpaymentchoicedetail";
const REVERSE = "api/flightreverse/flightreverse";
const POLICY = "api/flightinfo/flightpolicy";
const ANCILLARY_DIVIDE = "api/flightoperation/divide";
const ANCILLARY_REISSUE_VALIDATE = "api/flightreissue/validate";
const ANCILLARY_REISSUE_SEARCH = "api/flightreissue/search";
const ANCILLARY_REISSUE_CALCULATE = "api/flightreissue/calculate";
const ANCILLARY_REISSUE_CONFIRM = "api/flightreissue/confirm";
const ANCILLARY_SEARCH_SEAT = "/api/flightancillary/searchseats";
const ANCILLARY_INIT_SEAT = "/api/flightancillary/initseats";
const ANCILLARY_SELECT_SEATS = "/api/flightancillary/selectseats";
const ANCILLARY_TICKET_SEATS = "/api/flightancillary/ticketseats";
const ANCILLARY_SEARCHWHEELCHAIR = "/api/flightancillary/searchwheelchairs";
const ANCILLARY_TICKETWHEELCHAIR = "/api/flightancillary/ticketwheelchairs";
const ANCILLARY_SEARCHWMEAL = "/api/flightancillary/searchmeals";
const ANCILLARY_SELECT_MEAL = "/api/flightancillary/selectmeals";
const ANCILLARY_TICKET_MEAL = "/api/flightancillary/ticketmeals";
const ANCILLARY_SEARCHSSRS = "/api/flightancillary/searchssrs";
const ANCILLARY_SELECTSSRS = "/api/flightancillary/selectssrs";
const ANCILLARY_TICKETSSRS = "/api/flightancillary/ticketssrs";

export var flightSearch = (requestModel) => {
   let timeout = 45 * 1000; // 45 sn
   const promise = PRIVATE_HTTP(timeout).post(SEARCH, requestModel);
   const resData = promise
      .then((response) => {
         if (!response.data.result.success) {
            Popup.result(response.data.result);
         }
         return response.data;
      })
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return resData;
};

export var flightAutocomplete = (request) => {
   const promise = PRIVATE_HTTP().post(AUTOCOMPLETE, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var flightSelect = (requestModel) => {
   const promise = PRIVATE_HTTP().post(SELECT, requestModel);
   const resData = promise
      .then((response) => {
         if (!response.data.result.success) {
            if (response.data.result.code == "EXPIRED" || response.data.result.code == "REJECTED") {
               let subTitle = i18n.t(`RESPONSE_${response.data.result.app}_${response.data.result.service}_${response.data.result.code}_${response.data.result.subCode}`);
               Popup.info(i18n.t("popup_informationTitle"), subTitle);
            } else {
               Popup.result(response.data.result);
            }
         }
         return response.data;
      })
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return resData;
};

export var checkout = (requestModel) => {
   let timeout = 5 * 60 * 1000; // 5 dk
   const promise = PRIVATE_HTTP(timeout).post(CHECKOUT, requestModel);
   const resData = promise
      .then((response) => {
         if (!response.data.result.success) {
            if (response.data.result.code == "EXPIRED") {
               Popup.result(response.data.result);
            } else if (response.data.result.code == "FAILURE" && response.data.result.subCode == "PRICE_DIFFERENCE") {
               let subTitleMerged = `RESPONSE_${response.data.result.app}_${response.data.result.service}_${response.data.result.code}_${response.data.result.subCode}`;
               let subTitle = `${i18n.t(subTitleMerged)}`;
               let priceDifference = response.data.reserveOrTicketResponse.priceChangeAfterReserved;
               Popup.info(i18n.t("popup_informationTitle"), subTitle.replace("{PRICEDIFFERENCE}", priceDifference));
            } else {
               Popup.result(response.data.result);
            }
         }
         return response.data;
      })
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return resData;
};

export var flightReReserve = (request) => {
   let timeout = 60 * 1000; // 60 sn
   const promise = PRIVATE_HTTP(timeout).post(RERESERVE, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var flightTicketReserved = (request) => {
   let timeout = 5 * 60 * 1000; // 5 dk
   const promise = PRIVATE_HTTP(timeout).post(TICKET_RESERVED, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var flightReverseInquiry = (request) => {
   const promise = PRIVATE_HTTP().post(REVERSE_INQUIRY, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var flightReverse = (request) => {
   let timeout = 2 * 60 * 1000; // 2dk
   const promise = PRIVATE_HTTP(timeout).post(REVERSE, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var flightPolicy = (request) => {
   const promise = PRIVATE_HTTP().post(POLICY, request);
   return promise.then((response) => {
      return response.data;
   });
   //TODO:CLient'ında PopUp Gösterimi Olacak!!! (HALİL)
};

export var ancillaryInitSeat = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_INIT_SEAT, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var ancillarySearchSeat = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_SEARCH_SEAT, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillarySelectSeats = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_SELECT_SEATS, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryTicketSeats = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_TICKET_SEATS, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var ancillaryBaggages = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_BAGGAGE, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryDivide = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_DIVIDE, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryReissueValidate = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_REISSUE_VALIDATE, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryReissueSearch = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_REISSUE_SEARCH, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryReissueCalculate = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_REISSUE_CALCULATE, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryReissueConfirm = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_REISSUE_CONFIRM, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ticketBaggages = (request) => {
   let timeout = 5 * 60 * 1000; // 5 dk
   const promise = PRIVATE_HTTP(timeout).post(TICKET_BAGGAGES, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillarySearchWheelChair = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_SEARCHWHEELCHAIR, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryTicketWheelChair = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_TICKETWHEELCHAIR, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillarySearchMeal = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_SEARCHWMEAL, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillarySelecMeal = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_SELECT_MEAL, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryTicketMeal = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_TICKET_MEAL, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillarySearchSsrs = (request) => {
   let timeout = 5 * 60 * 1000; // 5dk
   const promise = PRIVATE_HTTP(timeout).post(ANCILLARY_SEARCHSSRS, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillarySelecSsrs = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_SELECTSSRS, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var ancillaryTicketSsrs = (request) => {
   const promise = PRIVATE_HTTP().post(ANCILLARY_TICKETSSRS, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var getPaymentChoiceDetail = (request) => {
   const promise = PRIVATE_HTTP().post(GET_PAYMENT_CHOICE_DETAIL, request);
   return promise.then((response) => {
      return response.data;
   });
};
