// FlightLocation component'i geliştirilecek

<template>
   <div id="flight-search-form-container">
      <!-- Iterative legs container -->
      <template v-for="(leg, legIndex) in legs">
         <div class="iterative-legs-wrapper row" :key="legIndex" :class="!isMultiLeg ? 'multi-leg-mode' : ''">
            <div class="leg-inputs-wrapper col-12 col-md-11 col-lg-10">
               <div class="row gx-4 gy-3">
                  <div class="col-12 col-lg" :class="[isMultiLeg ? 'col-md-6' : 'col-md-4', isFormValid == false && leg.fromDestinations.length == 0 ? 'g-form-invalid' : '']">
                     <!-- Departure Airport -->
                     <FlightLocation
                        :label="$t('searchComponentFlight_autocompleteDeparture')"
                        :chips="leg.fromDestinations"
                        ref="departure"
                        :tabindex="1"
                        :leg-index="legIndex"
                        :allow-chips="isMultiLeg"
                        @is-form-valid="isFormValid = true"
                        @update-destination="handlerUpdateDepartureLocation" />
                  </div>

                  <!-- Swap Airports -->
                  <div class="input-swap-container gts-animation d-none d-lg-block">
                     <i class="gts-icon icon-swap icon-sm" @click="handlerSwapDestinations(legIndex)"></i>
                  </div>

                  <div class="col-12 col-lg" :class="[isMultiLeg ? 'col-md-6' : 'col-md-4', isFormValid == false && leg.toDestinations.length == 0 ? 'g-form-invalid' : '']">
                     <!-- Arrival Airport -->
                     <FlightLocation
                        :label="$t('searchComponentFlight_autocompleteArrival')"
                        :chips="leg.toDestinations"
                        ref="arrival"
                        :tabindex="2"
                        :leg-index="legIndex"
                        :allow-chips="isMultiLeg"
                        @is-form-valid="isFormValid = true"
                        @update-destination="handlerUpdateArrivalLocation"
                        arrival />
                  </div>

                  <div class="col-12 col-lg" :class="[isMultiLeg ? 'col-md-6' : 'col-md-4', isFormValid == false && leg.departureDate == null ? 'g-form-invalid' : '']">
                     <!-- Departure Date -->
                     <FlightDatePicker
                        :label="$t('searchComponentFlight_departureDate')"
                        v-model="leg.departureDate"
                        :sibling-date="legs.length > 1 && legIndex != 0 ? legs[legIndex - 1].departureDate : leg.returnDate"
                        @input="handlerDepartureDateChange"
                        :is-multileg="!isMultiLeg"
                        :events="events" />
                  </div>
                  <div class="col-12 col-md-6 col-lg" v-if="isMultiLeg">
                     <!-- Arrival Date -->
                     <FlightDatePicker
                        :label="$t('searchComponentFlight_returnDate')"
                        v-model="leg.returnDate"
                        :sibling-date="leg.departureDate"
                        @input="handlerReturnDateChange"
                        :events="events"
                        return
                        allow-clear />
                  </div>
               </div>
            </div>

            <!-- Add / Remove Leg Controllers -->
            <div class="leg-controls-wrapper col-2 col-md-1 col-lg-2" :class="!isMultiLeg && legIndex == legs.length - 1 ? 'leg-controls-wrapper-last-child' : ''">
               <div class="leg-controls-aligner">
                  <div class="remove-leg-wrapper" @click="handlerRemoveLeg(legIndex)" v-if="legIndex > 0">
                     <i class="gts-icon icon-remove-leg"></i>
                  </div>
                  <div class="add-leg-wrapper" @click="handlerAddLeg" v-if="legIndex == legs.length - 1 && legIndex != 4">
                     <UITooltip :tooltip-title="$t('flightSearchForm_lblMultiLeg')" tooltip-icon="gts-icon icon-add-leg gts-animation" tooltip-position="bottom"></UITooltip>
                     <!-- <i class="gts-icon icon-add-leg"></i>** -->
                  </div>
               </div>
            </div>
         </div>
      </template>

      <!-- Unitary form elements -->
      <div class="unitary-form-wrapper row">
         <div class="col-12 col-md-12 col-lg-10">
            <div class="row gx-3 gy-3 gx-lg-4">
               <div class="col-6 col-md-3 col-lg">
                  <FlightPaxes :inputLabel="$t('searchComponentFlight_paxes')" @update-paxes="handlerUpdatePaxes" ref="paxes" />
               </div>
               <!-- Swap Airports -->
               <div class="input-swap-container gts-animation d-none d-lg-block invisible">
                  <i class="gts-icon icon-swap icon-sm"></i>
               </div>
               <div class="col-6 col-md-3 col-lg">
                  <FlightSelect :label="$t('searchComponentFlight_cabinTypes')" select-identifier="cabintypes" :tabindex="5" @update-selection="handlerUpdateCabinTypes" />
               </div>
               <div class="col-6 col-md-3 col-lg">
                  <FlightSelect :label="$t('searchComponentFlight_stops')" select-identifier="stops" :tabindex="6" @update-selection="handlerUpdateStops" />
               </div>
               <div class="col-6 col-md-3 col-lg">
                  <FlightSelect :label="$t('searchComponentFlight_airlines')" select-identifier="airlines" :showControls="true" :tabindex="7" @update-selection="handlerUpdateAirlines" />
               </div>
            </div>
         </div>
         <div class="flight-search-cta-wrapper col-12 col-md-12 col-lg-2">
            <div class="flight-search-cta"><UIButton className="flight" :text="$t('common_search')" @click.native="handlerSearchFlight" /></div>
         </div>
      </div>
   </div>
</template>

<script>
/*
 *   Page Notes
 *   1) Leg Model and content created on this page.
 *   2) On submit, Leg model will be converted into request model and save to the Flight/State
 */

import FlightLocation from "./FormElements/FlightLocation.vue";
import FlightDatePicker from "./FormElements/FlightDatePicker.vue";
import FlightPaxes from "./FormElements/FlightPaxes.vue";
import FlightSelect from "./FormElements/FlightSelects.vue";
import UIButton from "@/components/unitary/Button.vue";
import moment from "moment";
import { flightChips } from "@/helper/flight.js";
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "FlightSearchForm",
   components: {
      FlightLocation,
      FlightDatePicker,
      FlightPaxes,
      FlightSelect,
      UIButton,
      UITooltip,
   },
   data() {
      return {
         legs: [
            {
               fromDestinations: [],
               toDestinations: [],
               departureDate: moment().format(this.$constants.dateFormat),
               returnDate: null,
            },
         ],
         paxes: { adult: 1 },
         paxSubType: 0,
         cabinTypes: [1, 2],
         transfers: [0, 1],
         airlines: [],

         //26.11.2022 ↑

         allowSearch: false,

         isFormValid: null,
         events: [],
      };
   },
   mounted() {
      // this.$refs.departure[0].$el.querySelector("input").focus();
   },
   computed: {
      Waiting() {
         return this.waiting;
      },
      isMultiLeg() {
         if (this.legs.length == 1) {
            return true;
         } else {
            return false;
         }
      },
   },
   methods: {
      handlerSearchFlight() {
         this.isFormValid = this.legs.some((leg) => {
            return leg.fromDestinations.length == 0 || leg.toDestinations.length == 0 || leg.departureDate == null;
         });

         if (!this.isFormValid) {
            this.handlerCreateRequest();
         } else {
            this.isFormValid = false;
         }
      },
      handlerCreateRequest() {
         this.$parent.$emit("search-response", null);

         //Generate leg model for request Obj
         const legModel = {};
         this.legs.forEach((leg, legIndex) => {
            // index for request Model
            const index = legIndex + 1;
            legModel[index] = {
               fromDestinations: flightChips(leg.fromDestinations),
               toDestinations: flightChips(leg.toDestinations),
               index: index,
               departureDate: leg.departureDate,
               returnDate: leg.returnDate ?? null,
            };
         });

         //Request Obj
         const query = {
            cabinTypes: this.cabinTypes,
            paxes: this.paxes,
            paxSubType: this.paxSubType,
            airlines: this.airlines,
            agencyCommissionExtra: this.$store.state.flight.search.request.agencyCommissionExtra,
            transfers: this.transfers,
            legs: legModel,
         };

         //Generate information popup
         this.informationpopup = {
            legs: query.legs,
            paxes: query.paxes,
         };

         this.$store.commit("flight/updateSearchRequest", query);

         this.$parent.$emit("throw-new-search");
      },

      //form updates
      handlerUpdateCabinTypes(selection) {
         this.cabinTypes = selection;
         this.$store.commit("flight/updateCabinTypes", selection);
      },
      handlerUpdateStops(selection) {
         this.transfers = selection;
         this.$store.commit("flight/updateSegments", selection);
      },
      handlerUpdateAirlines(selection) {
         this.airlines = selection;
         this.$store.commit("flight/updateAirlines", selection);
      },
      handlerUpdatePaxes(selection) {
         this.paxes = selection.paxes;
         this.paxSubType = selection.ethnic;
         this.$store.commit("flight/updatePaxes", selection.paxes);
      },
      handlerSwapDestinations(legIndex) {
         let tempFrom = this.legs[legIndex].fromDestinations;
         let tempTo = this.legs[legIndex].toDestinations;
         this.legs[legIndex].fromDestinations = tempTo;
         this.legs[legIndex].toDestinations = tempFrom;
         tempFrom = "";
         tempTo = "";
      },
      handlerUpdateDepartureLocation(departureInfo) {
         this.legs[departureInfo.legIndex].fromDestinations.push(departureInfo.selection);
         this.isFormValid = true;
      },
      handlerUpdateArrivalLocation(arrivalInfo) {
         this.legs[arrivalInfo.legIndex].toDestinations.push(arrivalInfo.selection);
         this.isFormValid = true;
      },
      handlerDepartureDateChange(dprtDate) {
         if (this.isMultiLeg) {
            const departureDate = moment(dprtDate, this.$constants.dateFormat)._d;
            const returnDate = moment(this.legs[0].returnDate, this.$constants.dateFormat)._d;

            if (departureDate > returnDate) {
               this.legs[0].returnDate = null;
            }
         }
      },
      handlerReturnDateChange(rtrnDate) {
         if (this.isMultiLeg) {
            const returnDate = moment(rtrnDate, this.$constants.dateFormat)._d;
            const departureDate = moment(this.legs[0].departureDate, this.$constants.dateFormat)._d;

            if (returnDate < departureDate) {
               this.legs[0].departureDate = null;
            }
         }
      },
      //form controls
      handlerAddLeg() {
         const maxLegCount = 5;
         if (this.legs.length < maxLegCount) {
            const leg = {
               fromDestinations: [],
               toDestinations: [],
               departureDate: null,
            };
            this.legs.push(leg);
         }
         this.legs[0].returnDate = null;
         this.isFormValid = null;
      },
      handlerRemoveLeg(legIndex) {
         this.legs.splice(legIndex, 1);
      },
   },

   watch: {
      legs: {
         handler: function (legs) {
            const self = this;
            self.events.length = 0;
            legs.forEach((leg) => {
               if (leg.departureDate != null) {
                  self.events.push({
                     date: moment(leg.departureDate, this.$constants.dateFormat)._d,
                     type: "is-departure",
                  });
               }
            });

            if (legs.length > 1) {
               // this.legs.sort((a, b) => (a.departureDate > b.departureDate ? -1 : 1));
               legs.forEach((leg, index) => {
                  const prevRow = legs[index - 1]?.departureDate;
                  const currentRow = leg?.departureDate;

                  if (prevRow > currentRow) {
                     this.legs[index].departureDate = null;
                     // console.log("prevRow", prevRow);
                  }
               });
            }
         },
         deep: true,
      },
      "$store.state.flight.search.request.legs": {
         handler: function (legs) {
            Object.keys(legs).forEach((leg, index) => {
               this.legs[index].departureDate = legs[leg].departureDate;
               this.legs[index].returnDate = legs[leg].returnDate;
            });
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.add-leg-wrapper {
   cursor: pointer;
   transform: scale(1.125);
}
#flight-search-form-container {
   .iterative-legs-wrapper {
      margin-bottom: 1rem;

      &.multi-leg-mode {
         border: 1px solid #eee;
         margin-bottom: 1rem;
         padding: 1rem 0;
         border-radius: 0.5rem;
         background-color: #f5f5f5;

         ::v-deep .paxes-inner-wrapper,
         ::v-deep .autocomplete-inner-wrapper,
         ::v-deep .datepicker-inner-wrapper,
         ::v-deep .flightform-inner-wrapper,
         ::v-deep .g-input-text-wrapper > input {
            background: #fff;
         }

         .leg-controls-wrapper {
            margin-top: 24px;
            background: transparent !important;
            border: none !important;
         }
      }
      .leg-inputs-wrapper {
      }
      .leg-controls-wrapper {
         margin-top: 24px;
         border-radius: 0.5rem;
         // display: flex;
         // justify-content: flex-end;
         // width: 100%;
         // border: 1px solid #ccc;
         // background-color: #f8f8f8;

         &.leg-controls-wrapper-last-child {
            margin-top: 0 !important;
         }

         @media (min-width: 768px) {
            border: 1px solid #ccc;
            background-color: #f8f8f8;
         }

         @media (min-width: 992px) {
            margin-top: 0;
            background-color: transparent;
            border: none;
         }
         .leg-controls-aligner {
            display: flex;
            align-items: center;
            height: 100%;

            @media (min-width: 992px) {
               margin-top: 14px;
               background-color: transparent;
               border: none;
            }

            > div > i {
               width: 36px !important;
               height: 36px !important;
               cursor: pointer;
            }
            @media (min-width: 768px) and (max-width: 991.98px) {
               margin-top: 0px;
               flex-direction: column;
               justify-content: center;
               padding: 0 0.5rem;
               border-radius: 0.5rem;

               > div {
                  margin: 0.25rem 0;
               }
            }
            @media (min-width: 992px) {
               > div {
                  margin-right: 1rem;
                  &:last-child {
                     margin-right: 0;
                  }
               }
            }
         }
      }
   }

   .unitary-form-wrapper {
      margin-top: 1.5rem;
      .flight-search-cta-wrapper {
         margin-top: 1.5rem;
         .flight-search-cta {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            button {
               width: 100%;
               @media (min-width: 992px) {
                  margin-bottom: 2px;
               }
            }
         }
      }
   }

   .input-swap-container {
      width: 22px;
      height: auto;
      position: relative;
      display: block;
      margin-top: 48px;
      cursor: pointer;

      i {
         position: absolute;
         left: 0;
         bottom: 0;
         top: 0;
         margin: auto;
      }
   }

   ::v-deep .paxes-inner-wrapper,
   ::v-deep .autocomplete-inner-wrapper,
   ::v-deep .datepicker-inner-wrapper,
   ::v-deep .flightform-inner-wrapper {
      border: 1px solid #ccc;
      background: #f8f8f8;
      // background: red;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 0.75rem;
      box-sizing: border-box;
      // border-radius: 20rem;
      border-radius: 0.5rem;
      position: relative;
      min-height: 48px;
      cursor: default !important;

      &:focus-within {
         background-color: #f9fdff;
         border: 1px solid var(--accent-primary);
         outline: 0.25rem solid var(--accent-primary-lightest);
      }
   }
}
</style>
