<template>
   <div class="hotel-card-debugger-container">
      <div class="hotel-card-debugger-inner-container">
         <span>{{ firstResultText }}</span>
         <div v-for="(value, key) in channels" :key="key" class="channel-item">
            <span> {{ value.channelName }} : {{ channelIdMatcher(value.channelId) }}</span>
         </div>
         <span>{{ secondResultText }}</span>
      </div>
   </div>
</template>

<script>
export default {
   name: "SearchResultDebugger",
   props: {
      duration: String,
      searchListCount: Number,
      channels: {},
      channelCount: {},
   },
   methods: {
      channelIdMatcher(channelId) {
         return this.channelCount && this.channelCount[channelId] ? this.channelCount[channelId] : 0;
      },
   },
   computed: {
      firstResultText() {
         return this.$t("hotelCard_lblSearchResultInspectFirst").replace("#VALUE1#", this.duration);
      },
      secondResultText() {
         return this.$t("hotelCard_lblSearchResultInspectSecond").replace("#VALUE1#", this.searchListCount);
      },
   },
};
</script>

<style lang="scss" scoped>
.hotel-card-debugger-container {
   background-color: #30353c;
   color: #32cd32;
   font-family: monospace;
   font-weight: 500;
   font-size: 1rem;
   padding: 0.5rem;
   margin: 0.5rem;
   border-radius: 0.5rem;
   width: fit-content;
   .hotel-card-debugger-inner-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      .channel-item {
         display: flex;
         flex-direction: column;
      }

      .cancellation-policy {
         display: flex;
         flex-direction: column;
      }
   }
}
</style>
