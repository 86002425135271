import Vue from "vue";
import App from "./App.vue";
import i18n from "./fmt/i18n";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import firebaseMessaging from "./firebase.js";
import constants from "@/constants/constants.js";
import SmartTable from "@/components/utils/SmartTable/SmartTableComp.vue";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/grid.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");

import "buefy/dist/buefy.min.css";

import "@/assets/css/gts-base.css";
import "@/assets/css/gts-fonts.css";
import "@/assets/css/gts-placeholder.scss";

// import "./directives2/gts-validation-directives-old.js";
import "./directives/gts-custom-directives.js";
import "./directives/validator.js";
import "./directives/gts-instructions.js";

import GtsDropdownData from "@/enums/_GtsDropdownData.js";
import "@/enums/index.js";

// import "./assets/css/gts-validation.css";
import { errorManager } from "./helper/errormanager";

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENV !== "LOCAL") {
   Vue.config.errorHandler = (err, vm, info) => {
      errorManager.handleVueError(err, vm, info);
   };
   Vue.config.warnHandler = function (msg, vm, trace) {
      errorManager.handleVueWarn(msg, vm, trace);
   };
   window.onerror = function (message, source, lineno, colno, error) {
      errorManager.handleWindowError(message, source, lineno, colno, error);
   };
   window.addEventListener("unhandledrejection", function (event) {
      if (event.reason.response.status !== 401) {
         errorManager.handleWindowUnhandledRejection(event);
      }
   });
}

Vue.prototype.$messaging = firebaseMessaging;

Vue.use(constants);
Vue.use(VueTour);
Vue.use(Buefy);

Vue.component("SmartTable", SmartTable);

Vue.prototype.$GtsDropdownData = new GtsDropdownData();

const consoleMsg = `%c ver.${process.env.PACKAGE_VERSION} development mode:${process.env.VUE_APP_ENV == "LOCAL" ? "on" : "off"}, Version:${process.env.VERSION}`;
console.log(consoleMsg, "color:#000; font-size: 12px; background-color:lime; padding:.25rem .5rem; border-radius:.125rem");

if (process.env.VUE_APP_ENV !== "LOCAL") {
   console.log = () => {
      return undefined;
   };
}

new Vue({
   name: "GTS-SPA",
   i18n,
   router,
   store,
   render: (h) => h(App),
}).$mount("#app");
