<template>
   <div id="checkout-notes-container" class="gts-box with-border">
      <span class="section-title">{{ $t("checkoutNotes_lblInformations") }}</span>

      <div class="checkout-notes-wrapper">
         <UIAlert status="info_yellow" :rounded="false" v-for="(note, noteIndex) in notes" :key="'note' + noteIndex">
            <slot>
               <section v-html="note"></section>
            </slot>
         </UIAlert>
         <UIAlert status="info_red" :rounded="false" v-for="(warning, warningIndex) in warnings" :key="'warning' + warningIndex">
            <slot>
               <section v-html="warning"></section>
            </slot>
         </UIAlert>
      </div>
   </div>
</template>

<script>
import UIAlert from "@/components/unitary/Alert.vue";
export default {
   name: "CheckoutNotes",
   components: {
      UIAlert,
   },
   data() {
      return {
         notes: null,
         warnings: null,
      };
   },
   watch: {
      "$store.state.flight.checkout.response.notes": {
         handler: function (notes) {
            const self = this;
            self.notes = notes;
         },
      },
      "$store.state.flight.checkout.response.warnings": {
         handler: function (warnings) {
            const self = this;
            self.warnings = warnings;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#checkout-notes-container {
   .checkout-notes-wrapper {
      > div {
         margin: 1.5rem 0;
      }
   }
}
</style>
<style lang="scss">
#checkout-notes-container {
   .checkout-notes-wrapper {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
         font-weight: 700;
      }
      section {
         width: 100%;

         strong {
            font-weight: 700 !important;
         }

         ul {
            padding-left: 0 !important;
         }
         p {
            margin: 0 !important;
            margin-bottom: 0.5rem !important;
         }
      }
   }
}
</style>
