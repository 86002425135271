const ghostLanguage = {
    tr: {
        "25 gtsGrid_item": "25",
        accountTransactionList_filter_btnList: "Listele",
        accountTransactionList_filter_lblFromDate: "Başlangıç Tarihi",
        accountTransactionList_filter_lblToDate: "Bitiş Tarihi",
        accountTransactionList_tblMain_thBalance: "Bakiye",
        accountTransactionList_tblMain_thBalanceBegin: "Devir Bakiyesi",
        accountTransactionList_tblMain_thBalanceEnd: "Son Bakiye",
        accountTransactionList_tblMain_thCredit: "Alacak",
        accountTransactionList_tblMain_thCurrency: "Para birimi",
        accountTransactionList_tblMain_thDate: "Tarih",
        accountTransactionList_tblMain_thDebt: "Borç",
        accountTransactionList_tblMain_thEntity: "Ürün",
        accountTransactionList_tblMain_thInfo: "Açıklama",
        accountTransactionList_tblMain_thOrderId: "Order ID",
        accountTransactionList_tblMain_thProvider: "Tedarikçi",
        accountTransactionList_tblMain_thTrxSum: "İşlemler Toplamı",
        accountTransactionList_tblMain_thTrxType: "İşlem Tipi",
        accountTransactionList_tblMain_thUserEmail: "Kullanıcı E-mail",
        accountTransactionList_tblMain_title: "Hesap Hareketleri",
        accountTransactionList_tblMain_trProvider: "Hotel Provider",
        accountTransactionList_tblSumm_thBalance: "Bakiye",
        accountTransactionList_tblSumm_thCredit: "Alacak",
        accountTransactionList_tblSumm_thDebt: "Borç",
        accountTransactionList_tblSumm_thTrxType: "İşlem Tipi",
        accountTransactionList_tblSumm_title: "Hesap Hareketleri Özet",
        accountTransactionList_title: "Hesap Hareketleri",
        addTask_success: "Başarılı",
        addWidget_btnSave: "KAYDET",
        addWidget_lblAddWidget: "Widget Ekle",
        addWidget_lblFlightForthComingTitle: "Yaklaşan Uçuşlar",
        addWidget_lblFlightGirosTitle: "Uçuş - Ciro",
        addWidget_lblFlightPerformanceTitle: "Uçuş Performans",
        addWidget_lblFlightReservationsTitle: "Yaklaşan Rezervasyonlar - Uçak",
        addWidget_lblHotelCheckInTitle: "Yaklaşan Check-Inler",
        addWidget_lblHotelPerformanceTitle: "Otel Performans",
        addWidget_lblHotelReservationsTitle: "Yaklaşan Rezervasyonlar - Otel",
        addWidget_lblRentACarWidgetTitle: "Yaklaşan Araç Kiralama",
        addWidget_lblTranferWidgetTitle: "Yaklaşan Transferler",
        agencyAccountComponent_availableBalance: "Kullanılabilir Bakiye",
        agencyAccountComponent_balance: "Bakiye",
        agencyAccountComponent_balanceUpdate: "Bakiye Yükleme",
        agencyAccountComponent_definedCredit: "Tanımlı Kredi",
        AgencyExtraCommission_checkOutInformation: "Toplam Ücret'e Eklenir.",
        AgencyExtraCommission_title: "Ekstra Komisyon",
        AGENT_API_FLIGHT_CHECKOUT_EXPIRED_CHECKOUT: "Uzun süredir işlem yapmadığınız için fiyatlar güncellenmiş olabilir.  Aramanızı yenileyerek işleminize devam edebilirsiniz.",
        airlineEnum_6Y: "SmartLynx Havayolu",
        airlineEnum_QS: "Smartwings Havayolu",
        alert_emptyAmount: "Lütfen tutar giriniz.",
        alert_emptyHotelList: "Aradığınız kriterlere uygun otel bulunamamıştır. Lütfen arama kriterlerinizi değiştirerek tekrar deneyiniz.",
        alert_warningPopupTitle: "Bir Hata Oluştu.",
        ancillariesServices_baggage: "Ekstra Bagaj",
        ancillariesServices_checkIn: "Online Check-in",
        ancillariesServices_descriptionsunflex3: "SunFlex 3, Uçuşunuzdan 3 gün öncesine kadar ücretsiz değişiklik (+Fiyat farkı)",
        ancillariesServices_divide: "Divide",
        ancillariesServices_food: "Yemek Hizmeti",
        ancillariesServices_lblMealsFooterInfo: "Tüm soru ve önerileriniz için Service Center ekibimiz ile iletişime geçebilirsiniz.",
        ancillariesServices_lblWheelChairFooterInfo: "Tekerlekli sandalye hizmetini değiştirmek için lütfen Servis Center ekibimizle iletişime geçin. Değişiklikler bu sayfa üzerinden yapılamaz.",
        ancillariesServices_meal: "Yemek Seçimi",
        ancillariesServices_meals: "Yemek",
        ancillariesServices_onlineCheckin: "Online Check-in",
        ancillariesServices_reissue: "Uçuş Değiştir",
        ancillariesServices_relatedPNRInfos: "Ek hizmetler PNR ve Yolcu bazlıdır. Yukarıdaki ek hizmetler {PNR} nolu PNR için oluşturulmuştur.",
        ancillariesServices_relatedPPaymentMethods: "Aldığınız ek hizmetlerin ödemelerini Cari, Kredi Kartı, Sofort ve Ideal ödeme yöntemleri ile yapabilirsiniz.",
        ancillariesServices_seat: "Koltuk Seç",
        ancillariesServices_selectfood: "Yemek Seçimi",
        ancillariesServices_selectssr: "Sun Flex 3 Seçin",
        ancillariesServices_selectwheelchair: "T. Sandalyeyi Seçin",
        ancillariesServices_SunFlex3: "Sun Flex 3",
        ancillariesServices_title: "Ek Hizmetler",
        ancillariesServices_wheelChair: "T. Sandalye",
        ancillariesServicesModal_baggageContentSuccess: "Bagaj alımınız başarılı bir şekilde gerçekleşmiştir.",
        ancillariesServicesModal_baggageFailedTitle: "Hata",
        ancillariesServicesModal_baggageSuccessTitle: "Başarılı",
        ancillariesServicesModal_baggeFailedMessage: "Bagaj alım sırasında hata aldınız. Lütfen Service Center ile iletişime geçerek, tekrar deneyiniz.",
        ancillariesServicesModal_btnClose: "KAPAT",
        ancillariesServicesModal_btnConfirm: "ONAYLA",
        ancillariesServicesModal_btnTryAgain: "Yeniden Ara",
        ancillariesServicesModal_BuyWithCurrentPaymentMethod: "Cari Satın Al",
        ancillariesServicesModal_contentFailed: "Bagaj satın alım sırasında hata aldınız. Lütfen Service Center ile iletişime geçiniz.",
        ancillariesServicesModal_contentSuccess: "Almış olduğunuz ek bagajlarınızı <br/><strong>{ORDER}</strong> numaralı siparişinize işledik.",
        ancillariesServicesModal_divideAfterDividePNR: "Yeni PNR",
        ancillariesServicesModal_divideCurrentPNR: "Ana PNR",
        ancillariesServicesModal_divideFailedMessage: "PNR Ayırma işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        ancillariesServicesModal_divideFailedTitle: "Hatalı",
        ancillariesServicesModal_divideSuccessMessage: "PNR Ayırma işleminiz başarılıdır. Yeni Pnr {PNR} numaranız oluşturulmuştur.",
        ancillariesServicesModal_divideSuccessTitle: "Başarılı",
        ancillariesServicesModal_infos: "Ek Hizmet alırken, Kredi kartı ve Sofort ile yapacağınız ödemelerde banka komisyonu eklenir.",
        ancillariesServicesModal_infoTextReissueStep2: "Lütfen değiştirmek istediğiniz uçuşu seçin.",
        ancillariesServicesModal_infoTxtPlsSelectFlights: "Reissue yapmak istediğiniz uçuşları {ICON} buton yardımı ile seçiniz.",
        ancillariesServicesModal_lblAgencyExtraComm: "Acente Ekstra Komisyon",
        ancillariesServicesModal_lblArrivalAirport: "Nereye",
        ancillariesServicesModal_lblArrivalAirportPlaceholder: "Nereye",
        ancillariesServicesModal_lblArrivalDate: "Varış Tarihi",
        ancillariesServicesModal_lblCancelSelection: "Seçimi İptal Et",
        ancillariesServicesModal_lblDepartureAirport: "Nereden",
        ancillariesServicesModal_lblDepartureAirportPlaceholder: "Nereden",
        ancillariesServicesModal_lblDepartureDate: "Kalkış Tarihi",
        ancillariesServicesModal_lblExtraComm: "Ekstra Komisyon",
        ancillariesServicesModal_lblFlight: "Uçuş",
        ancillariesServicesModal_lblGrandTotal: "Toplam Ödenecek",
        ancillariesServicesModal_lblPayment: "Ödeme Adımı",
        ancillariesServicesModal_lblReserve: "REZERVE ET",
        ancillariesServicesModal_lblSearch: "Ara",
        ancillariesServicesModal_lblSearchResultCount: "Sonuç görüntüleniyor.",
        ancillariesServicesModal_lblSSR: "Sun Flex 3",
        ancillariesServicesModal_lblSSRDesc: "SunFlex 3, Uçuşunuzdan 3 gün öncesine kadar ücretsiz değişiklik (+Fiyat farkı)",
        ancillariesServicesModal_lblTotalSalesAmount: "Toplam",
        ancillariesServicesModal_mealsContentSuccess: "Yemek alımınız başarılı bir şekilde gerçekleşmiştir.",
        ancillariesServicesModal_mealsFailedMessage: "Yemek alım sırasında hata aldınız. Lütfen Service Center ile iletişime geçerek, tekrar deneyiniz.",
        ancillariesServicesModal_mealsFailedTitle: "Hata",
        ancillariesServicesModal_mealsSuccessTitle: "Başarılı",
        ancillariesServicesModal_NO_BRAND_FOUND: "Rezervasyonunuzun brandi havayolunda bulunamadı. Lütfen Service Center ile iletişime geçiniz.",
        ancillariesServicesModal_NO_FLIGHT_FOUND: "Arama kriterlerinize uygun uçuş bulunamadı.",
        ancillariesServicesModal_otherPaymentMethods: "Diğer Yöntemler",
        ancillariesServicesModal_paymentStep: "Ödeme Sayfası",
        ancillariesServicesModal_reissueErrorMessage: "Değişiklik sorgusunda bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        ancillariesServicesModal_reissueErrorNoSearchResultText: "Havayolu tarafından yanıt alınamamıştır. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        ancillariesServicesModal_reissueErrorNoSearchResultTitle: "Bir Hata Oluştu.",
        ancillariesServicesModal_reissueErrorText: "Hatalı",
        ancillariesServicesModal_reissueErrorTitle: "Değişiklik işlemi sırasında hata aldınız. Lütfen Service Center ekibi ile iletişime geçiniz.",
        ancillariesServicesModal_reissueSuccessText: "Değişiklik işleminiz başarı ile gerçekleştirilmiştir. Yeni biletinizi kontrol edebilirsiniz.",
        ancillariesServicesModal_reissueSuccessTitle: "Başarılı",
        ancillariesServicesModal_resetDivide: "Sıfırla",
        ancillariesServicesModal_stepDivide: "PNR Ayırma",
        ancillariesServicesModal_stepFlightSearchResult: "Arama Sonucu",
        ancillariesServicesModal_stepFlightSelection: "Değişiklik Seçimi",
        ancillariesServicesModal_stepMealSelection: "Yemek Seçimi",
        ancillariesServicesModal_stepPayment: "Ödeme Sayfası",
        ancillariesServicesModal_stepReissuePayment: "Ödeme Sayfası",
        ancillariesServicesModal_stepReissueResult: "Sonuç",
        ancillariesServicesModal_stepResult: "Sonuç",
        ancillariesServicesModal_stepSeatSelection: "Koltuk Seçimi",
        ancillariesServicesModal_stepSelectBaggage: "Bagaj Seçimi",
        ancillariesServicesModal_stepSssr: "Sun Flex Seçimi",
        ancillariesServicesModal_stepSunFlex3: "Sun Flex",
        ancillariesServicesModal_stepWheelChairSelection: "Tekerlekli Sandalye Seçimi",
        ancillariesServicesModal_sunFlexErrorMessage: "Sun Flex alım sırasında hata aldınız. Lütfen Service Center ile iletişime geçerek, tekrar deneyiniz.",
        ancillariesServicesModal_sunFlexSuccessMessage: "SunFlex 3 rezervasyonunuz başarı ile yapılmıştır.",
        ancillariesServicesModal_titleFailed: "Hatalı",
        ancillariesServicesModal_titleSuccess: "Başarılı",
        ancillariesServicesModal_wheelchairErrorMessage: "Tekerlekli Sandalye alım sırasında hata aldınız. Lütfen Service Center ile iletişime geçerek, tekrar deneyiniz.",
        ancillariesServicesModal_wheelchairErrorTitle: "Hatalı",
        ancillariesServicesModal_wheelchairSuccessMessage: "Tekerlekli Sandalye alım işleminiz başarı ile gerçekleşmiştir.",
        ancillariesServicesModal_wheelchairSuccessTitle: "Başarılı",
        ancillarReissue_btnCalculateReissue: "Değişiklik Tutarını Hesapla",
        ancillarReissue_lblCalculateDescription: "Değişiklik bedelini hesaplama yaparak görebilirsiniz.",
        ancillarReissue_lblCalculateTitle: "Değişiklik Hesaplama",
        ancillarReissue_lblCalculatingFailedDescription: "İşleminiz sırasında hata oluşmuştur. Yeniden arama yaparak işleminize devam edebilirsiniz.",
        ancillarReissue_lblCalculatingFailedDescriptionFLIGHT_SAME_DAY: "Seçtiğiniz uçuşları lütfen kontrol ediniz.",
        ancillarReissue_lblCalculatingFailedTitle: "Hata",
        ancillarReissue_lblFlightResultTitleAirline: "Havayolu",
        ancillarReissue_lblFlightResultTitleArrival: "Nereye",
        ancillarReissue_lblFlightResultTitleDeparture: "Nereden",
        ancillarReissue_lblFlightResultTitleFlightDate: "Uçuş Tarihi",
        ancillarReissue_lblFlightResultTitleFlightNu: "Uçuş No",
        ancillarReissue_lblFlightResultTitleFrom: "Nereden",
        ancillarReissue_lblFlightResultTitleStop: "Aktarma",
        ancillarReissue_lblFlightResultTitleTo: "Nereye",
        ancillarReissue_lblPreviousStep: "Önceki Adım",
        ancillaryReissueSelection_lblPnrHasAncillaries: "Eski rezervasyonunuz için satın aldığınız SSR' lar reissue sonrası rezervasyonunuza aktarılmayacaktır.",
        ancillarySeatMap_btnCancel: "İptal",
        ancillarySeatMap_btnNextStep: "Sonraki",
        ancillarySeatMap_btnPayment: "Ödeme",
        ancillarySeatMap_btnPreviousStep: "Önceki Adım",
        ancillarySeatMap_lblAvailable: "Seçilebilir",
        ancillarySeatMap_lblCancel: "İptal",
        ancillarySeatMap_lblErrorBtn: "Hata",
        ancillarySeatMap_lblExtraLegRoom: "XL Koltuk",
        ancillarySeatMap_lblGrandTotal: "Genel Toplam",
        ancillarySeatMap_lblNotAvailable: "Seçilemez",
        ancillarySeatMap_lblPassenger: "Yolcu",
        ancillarySeatMap_lblPaxType: "Kişi Tipi",
        ancillarySeatMap_lblPayment: "Ödeme",
        ancillarySeatMap_lblPrice: "Fiyat",
        ancillarySeatMap_lblResult: "Sonuç",
        ancillarySeatMap_lblResultEXCEPTION: "Bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        ancillarySeatMap_lblResultEXPIRED: "Uzun süredir işlem yapmadığınız için fiyatlar güncellenmiş olabilir.  Aramanızı yenileyerek işleminize devam edebilirsiniz.",
        ancillarySeatMap_lblResultFAILURE: "Bilinmeyen bir hata ile karşılaştık. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        ancillarySeatMap_lblResultREJECTED: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        ancillarySeatMap_lblResultSUCCESS: "Koltuk alımınız başarılı bir şekilde gerçekleşmiştir.",
        ancillarySeatMap_lblResultTIMEOUT: "İşleminiz zaman aşımına uğradı.  Lütfen tekrar deneyiniz.",
        ancillarySeatMap_lblSeat: "Koltuk",
        ancillarySeatMap_lblSelected: "Seçilen",
        ancillarySeatMap_lblSuccessBtn: "Başarılı",
        announcement_homePage: "Ana Sayfa",
        announcement_showOriginalImage: "Resmi Büyüt",
        announcement_updateTime: "Son Güncelleme Tarihi",
        announcement_urlCopied: "Kopyalandı",
        AnnouncementManagementList_thThumbnailImage: "Ön Resim",
        aside_accTransactionList: "Hesap Hareketleri",
        aside_balanceUpdate: "Bakiye Yükleme",
        aside_dashBoard: "Dashboard Anasayfa",
        aside_flightSalesReport: "Uçuş Satış Raporu",
        aside_flightSearch: "Uçuş Ara",
        aside_hotelSalesReport: "Otel Satış Raporu",
        aside_hotelSearch: "Otel Ara",
        aside_invoiceList: "Fatura Listesi",
        aside_notifications: "Bildirimler",
        aside_orderSearch: "Order Arama",
        aside_packageSearch: "Paket Ara",
        aside_rentACarSalesReport: "Araç Kiralama Satış Raporu",
        aside_rentACarSearch: "Araç Kiralama Ara",
        aside_schmetterlingSalesReport: "Paket Satış Raporu",
        aside_serviceCenter: "Service Center",
        aside_targetAchievements: "Teşvik Hedefi",
        aside_transferSalesReport: "Transfer Satış Raporu",
        aside_transferSearch: "Transfer Ara",
        BALANCE_UPDATE_REJECTED: "Bir Hata Oluştu.",
        BALANCE_UPDATE_REJECTED_INSUFFICIENT_PAYMENT: "Ödeme sırasında bir hata aldınız.",
        BALANCE_UPDATE_SUCCESS: "Başarılı",
        BALANCE_UPDATE_SUCCESS_BALANCE_UPDATED: "Bakiyeniz başarılı bir şekilde yüklenmiştir.",
        BALANCE_UPDATE_VALIDATION: "Bir Hata Oluştu.",
        BALANCE_UPDATE_VALIDATION_AMOUNT: "Lütfen tutar giriniz.",
        balanceUpdate_btnDeposit: "BAKIYE YÜKLE",
        balanceUpdate_lblAmount: "Yüklenecek Bakiye",
        balanceUpdate_lblAvailableBalance: "Kullanılabilir Bakiye",
        balanceUpdate_lblBalance: "Bakiye",
        balanceUpdate_lblCommission: "Komisyon",
        balanceUpdate_lblInfo: "Açıklama",
        balanceUpdate_lblLoan: "Tanımlı Kredi",
        balanceUpdate_lblNote: "Açıklama",
        balanceUpdate_lblTotal: "Toplam",
        BalanceUpdate_popup_REJECTED: "Bir Hata Oluştu.",
        BalanceUpdate_popup_REJECTED_INSUFFICIENT_PAYMENT: "İşleminiz sırasında bir hata aldınız.  Ödemeniz tahsil edilememiştir.",
        BalanceUpdate_popup_SUCCESS: "Başarılı",
        BalanceUpdate_popup_SUCCESS_BALANCE_UPDATED: "Bakiyeniz başarılı bir şekilde yüklenmiştir.",
        BalanceUpdate_popup_Validation: "Bir Hata Oluştu.",
        BalanceUpdate_popup_Validation_AMOUNT: "Lütfen tutar giriniz.",
        BalanceUpdate_popup_Validation_ERROR: "Hata",
        BalanceUpdate_progressGifStep1: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        BalanceUpdate_progressGifStep2: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        balanceUpdate_title: "Bakiye Yükleme",
        balanceUpdateComponent_balanceUpdateForm_commissionLabel: "Komisyon",
        balanceUpdateComponent_balanceUpdateForm_descriptionLabel: "Açıklama",
        balanceUpdateComponent_balanceUpdateForm_loadedBalanceLabel: "Yüklenecek Bakiye",
        balanceUpdateComponent_balanceUpdateForm_totalLabel: "Toplam",
        balanceUpdateComponent_paymentChoicesTab_creditCardAlertText: "Kredi karti ile yüklemelerde sabit %2 banka komisyon oranı eklenecektir.",
        balanceUpdateComponent_paymentChoicesTab_sofortAlertText: "Sofort ile yüklemelerde 1-500€ arasında sabit 3€, 501€ dan itibaren sabit 5€ banka komisyonu eklenecektir.",
        balanceUpdatePage_buttonText: "BAKIYE YÜKLE",
        balanceUpdatePage_pageTitle: "Bakiye Yükleme",
        balanceUpdateSummaryComponent_availableBalanceLabel: "Kullanılabilir Bakiye",
        balanceUpdateSummaryComponent_balanceLabel: "Bakiye",
        balanceUpdateSummaryComponent_loanLabel: "Tanımlı Kredi",
        basketComponentFlight_baggageInformationChanges: "Bagaj bilgileriniz değişmiştir. Lütfen kontrol ediniz.",
        basketComponentFlight_basket: "Sepet",
        basketComponentFlight_btnBaggageInformationConfirmation: "ONAYLIYORUM",
        basketComponentFlight_closeBasket: "KAPAT",
        basketComponentFlight_goToCheckout: "İLERLE",
        basketComponentFlight_newItemAddedorUpdated: "Sepete Ürün Eklendi veya Sepet Güncellendi.",
        basketComponentFlight_package: "Paket",
        basketComponentFlight_sendOffer: "Teklif Gönder",
        basketComponentFlight_single: "Tekli",
        basketComponentFlight_singleOfferIsCheaper: "Tekli Fiyat Daha Uygun!",
        basketComponentFlight_total: "Toplam",
        basketComponentHotel_sendOffer: "Teklif Gönder",
        cancellationPoliciesComponent_cancellationDateLabel: "Ücretsiz İptal Tarihi",
        cancellationPolicy_lblFreeCancellationDate: "Ücretsiz İptal Tarihi",
        cancellationPolicy_lblNoCancellationPolicy: "İptal Koşulu",
        cancellationPolicy_lblNoLastBookingDateDescription: "Bu rezervasyonun iptali durumunda iadesi bulunmamaktadır.",
        cancellationPolicy_lblPackageSchmetterlingCancellationPolicy: "İptal koşulları ve uçuş veya konaklama ile ilgili tüm kuralları görmek için lütfen Smart Booking sistemindeki satış raporu bölümünden, ilgili rezervasyonun detaylarına bakınız.",
        changeDetails_lblarrivalAirportCode: "Nereye",
        changeDetails_lblarrivalDateTime: "Varış Zamanı",
        changeDetails_lblbrandCode: "Brand Code",
        changeDetails_lblbrandName: "Brand Name",
        changeDetails_lblcabinType: "Kabin Tipi",
        changeDetails_lbldepartureAirportCode: "Nereden",
        changeDetails_lbldepartureDateTime: "Kalkış Saati",
        changeDetails_lblflightClass: "Sınıf",
        changeDetails_lblflightIndex: "Flight Index",
        changeDetails_lblflightNumber: "Uçuş No",
        changeDetails_lblflightRoute: "Uçak Rota",
        changeDetails_lblmarketingAirlineCode: "Marketing Havayolu",
        changeDetails_lbloperatingAirlineCode: "Operating Havayolu",
        changeDetails_lblsegmentIndex: "Segment Index",
        changeDetails_lblsegmentRoute: "Segment Rota",
        changeHistory_lblHistory: "Eski Uçuş Bilgisi",
        changeHistory_lblLatestEntry: "Yeni Uçuş Bilgisi",
        changeHistoryTable_lblTaskOpeningTime: "Task Açılış Zamanı",
        changeHistoryTable_lblType: "Tip",
        changeHistoryTable_thArrival: "Varış Zamanı",
        changeHistoryTable_thBrandCode: "Brand Code",
        changeHistoryTable_thBrandName: "Brand Name",
        changeHistoryTable_thCabinType: "Kabin Tipi",
        changeHistoryTable_thClass: "Sınıf",
        changeHistoryTable_thDeparture: "Kalkış Zamanı",
        changeHistoryTable_thFlightNo: "Uçuş No",
        changeHistoryTable_thFrom: "Nereden",
        changeHistoryTable_thMarketingOperating: "Marketing/ Operating",
        changeHistoryTable_thRoute: "Uçuş",
        changeHistoryTable_thTo: "Nereye",
        changeItemDetails_lblBody: "Dikkat! Uçuş detaylarınız değişmiştir.  Aşağıda belirtilen rezervasyon için uçuş/saat değişikliği olmuştur. Lütfen yolcunuzla iletişime geçip, değişiklikle ilgili yazılı geri dönüş sağlayınız",
        changeItemDetails_lblClosing: "Saygılarımızla",
        changeItemDetails_lblGreeting: "Sayın Acentemiz,",
        chatBubbleComponent_sent: "Gönderildi",
        chatTabComponent_placeholder: "Bir mesaj yazın...",
        checkout_coPayCancelBtn: "REZERVASYON IPTAL",
        checkout_lblMetaPolicy: "Ücretler ve Diğer Bilgiler",
        checkout_lblTaxInformation: "Vergi Bilgileri",
        checkoutBasket_basket_addExtraCommission: "Ekstra Komisyon Ekle",
        checkoutBasket_basket_infos: "Bilgiler",
        checkoutBasket_basket_pnrInfos: "Bu işlem için #VALUE# PNR oluşacaktır.",
        checkoutBasket_titleBasket: "Sepet",
        checkoutContact_bntFillWithAgentInfo: "Acente Kullanıcısı Bilgilerini Kullan",
        checkoutContact_bntFillWithFirstPaxInfo: "İlk Yolcu Bilgilerini Kullan",
        checkoutContact_contactInformations: "İletişim Bilgileri",
        checkoutContact_informationText: "Satın alma işlemi tamamlandığında işlem bilgileriniz e-mailinize ve/veya cep telefonunuza gönderilecektir.",
        checkoutContact_lblFilledWithAgentInfos: "Acente kullanıcısı bilgileri ile biletleme yapılır.",
        checkoutContact_lblFilledWithFirstPaxInfos: "İlk yolcu bilgileri ile biletleme yapılır.",
        checkoutContact_vldMin4: "Şehir kodunu maximum 4 hane girebilirsiniz.",
        checkoutFlightPayment_alertNoReservationAndNoBookingBtns: "Seçtiğiniz brand için havayolu fiyat vermemektedir.  Seçiminizi değiştirerek yeniden deneyebilirsiniz.",
        checkoutFlightPayment_CHECKOUT_FAILED: "İşleminiz sırasında hata oluşmuştur. Yeniden arama yaparak işleminize devam edebilirsiniz.",
        checkoutFlightPayment_INSUFFICIENT_AGENCY_BALANCE: "Kullanılabilir bakiyeniz yetersizdir.  Lütfen ekibimiz ile iletişime geçiniz.",
        checkoutFlightPayment_lblBooking: "SATIN AL",
        checkoutFlightPayment_lblReservationCancel: "REZERVASYON IPTAL",
        checkoutFlightPayment_lblReservationRedirect: "REZERVASYONA GİT",
        checkoutFlightPayment_lblReserve: "REZERVE ET",
        checkoutFlightPayment_NOT_SALES_OFFICE: "Satış ofisi değildir. Lütfen kullanıcınızı veya acentenizi kontrol ediniz.",
        checkoutFlightPayment_SELECTED_BRANDS_NOT_VALID_NOW: "Seçtiğiniz brand için havayolu fiyat vermemektedir.  Seçiminizi değiştirerek yeniden deneyebilirsiniz.",
        checkoutNotes_lblInformations: "Bilgiler",
        common_add: "EKLE",
        common_address: "Adres",
        common_adult: "Yetişkin",
        common_adultPluralExt: "",
        common_agencyCommission: "Acente Komisyon",
        common_agencyExtraCommission: "Acente Ekstra Komisyon",
        common_agencyId: "Acente ID",
        common_agencyName: "Acente İsmi",
        common_agencyNote: "Acente Notu",
        common_airlines: "Havayolu",
        common_all: "Tümünü Göster",
        common_allow: "Kabul Et",
        common_and: "ve",
        common_apr: "Nis",
        common_april: "Nisan",
        common_arrivalFlightNumber: "Uçuş No",
        common_arrivaltime_decrease: "Varış Saati, Azalan",
        common_arrivaltime_increase: "Varış Saati, Artan",
        common_aug: "Ağu",
        common_august: "Ağustos",
        common_basket: "Sepet",
        common_birthday: "Doğum Tarihi",
        common_booking: "SATIN AL",
        common_cancel: "İptal",
        common_cancellationTime: "İptal Zamanı",
        common_cannotBeEmpty: "Lütfen doldurunuz.",
        common_capacity_decrease: "Kapasite, Azalan",
        common_capacity_increase: "Kapasite, Artan",
        common_checkIn: "Giriş Tarihi",
        common_checkOut: "Çıkış Tarihi",
        common_child: "Çocuk",
        common_childPluralExt: "",
        common_chooseChildAge: "Yaş",
        common_city: "Alan Kodu",
        common_clear: "Temizle",
        common_clearAll: "Temizle",
        common_close: "Kapat",
        common_code: "Kod",
        common_confirmationTitle: "Onay Aşaması",
        common_countryCode: "Ülke Kodu",
        common_countryPhoneCode: "Ülke Telefon Kodu",
        common_countryPhoneCodeShort: "Ülke Kodu",
        common_currency: "Para birimi",
        common_day: "Gün",
        common_dec: "Ara",
        common_december: "Aralık",
        common_decline: "Reddet",
        common_departure_decrease: "Kalkış, Azalan",
        common_departure_increase: "Kalkış, Artan",
        common_departureFlightNumber: "Uçuş No",
        common_departureTime: "Kalkış Zamanı",
        common_description: "Açıklama",
        common_deselectAll: "Bırak",
        common_disabled: "Engelli",
        common_disabledCompanion: "Engelli Refakatçisi",
        common_discount: "İndirimli",
        common_district: "Bölge",
        common_email: "E-mail",
        common_ethnic: "Etnik (VFR)",
        common_eticket: "E-Bilet",
        common_etickets: "E-Bilet",
        common_eticketStatus: "E-Bilet Durumu",
        common_extraSeat: "Extra Seat",
        common_feb: "Şub",
        common_february: "Şubat",
        common_female: "Bayan",
        common_flight: "Uçak",
        common_flightNumber: "Uçuş No",
        common_flightNumberOptional: "Uçuş No",
        common_fri: "Cum",
        common_friday: "Cuma",
        common_fullName: "Ad Soyad",
        common_gender: "Cinsiyet",
        common_generalGrandTotal: "Genel Toplam Ücret",
        common_generalTotalPrice: "Toplam Ücret",
        common_gotodashboard: "Ana Sayfaya Git",
        common_grandTotal: "Toplam",
        common_gsa: "Gsa",
        common_gsaName: "Gsa Adı",
        common_guest: "Misafir",
        common_hide: "Gizle",
        common_hotel: "Otel",
        common_hotelName: "Otel Adı",
        common_hour: "Saat",
        common_id: "ID",
        common_infant: "Bebek",
        common_info: "Bilgiler",
        common_information: "Bilgi",
        common_insertedDate: "Oluşturulma Zamanı",
        common_insertedUser: "Oluşturan Kullanıcı",
        common_itemsSelected: "öğe seçildi",
        common_jan: "Oca",
        common_january: "Ocak",
        common_jul: "Tem",
        common_july: "Temmuz",
        common_jun: "Haz",
        common_june: "Haziran",
        common_languagePreference: "Dil Tercihi",
        common_lblGrandTotal: "Genel Toplam",
        common_lblMediaOrientationMsg: "Lütfen cihazınızı dik konuma getirerek işleme devam ediniz.",
        common_list: "Listele",
        common_male: "Bay",
        common_mar: "Mar",
        common_march: "Mart",
        common_marketingAirlineCodes: "Havayolu",
        common_may: "Mayıs",
        common_minute: "Dakika",
        common_mon: "Pzt",
        common_monday: "Pazartesi",
        common_my: "May",
        common_name: "Ad",
        common_nameSurname: "Ad Soyad",
        common_nationalIdentity: "T.C. No.",
        common_nationality: "Uyruk",
        common_nodata: "Tabloda herhangi bir veri mevcut değil.",
        common_nothingSelected: "Hiçbiri Seçilmedi",
        common_nov: "Kas",
        common_november: "Kasım",
        common_oct: "Eki",
        common_october: "Ekim",
        common_officialName: "Resmi İsim",
        common_ok: "Tamam",
        common_okbtn: "Tamam",
        common_open: "Açık",
        common_optionExpiryTime: "Opsiyon Bitiş Zamanı",
        common_order: "Order",
        common_orderId: "Order ID",
        common_passenger: "Yolcu",
        common_paxCount: "Kişi Sayısı",
        common_person: "Yetişkin",
        common_phone: "Telefon",
        common_photoGallery: "Fotoğraflar",
        common_pnrs: "Pnr",
        common_pnrStatus: "Pnr Durumu",
        common_pnrStatuses: "Pnr Durumu",
        common_price: "Fiyat",
        common_price_decrease: "Fiyat, Azalan",
        common_price_fpdecrease: "Paket Fiyatı, Azalan",
        common_price_fpincrease: "Paket Fiyatı, Artan",
        common_price_increase: "Fiyat, Artan",
        common_pritax: "Vergi",
        common_provider: "Tedarikçi",
        common_providerName: "Tedarikçi İsmi",
        common_purchase: "SATIN AL",
        common_readLess: "Daralt",
        common_readMore: "Devamını Oku",
        common_recommended: "Önerilen",
        common_removeRoom: "Odayı Kaldır",
        common_reservation: "REZERVE ET",
        common_reset: "Sıfırla",
        common_resetbtn: "Sıfırla",
        common_reversable: "İPTAL ET",
        common_room: "Oda",
        common_rooms: "Odalar",
        common_roomSelect: "Oda Seç",
        common_salesTotal: "Satış Toplamı",
        common_saleTime: "Satış Zamanı",
        common_sat: "Cmt",
        common_saturday: "Cumartesi",
        common_save: "Kaydet",
        common_search: "Ara",
        common_searchFlight: "Uçuş",
        common_searchHotel: "Otel",
        common_searchPackages: "Paketler",
        common_searchRentACar: "Araç Kiralama",
        common_searchRoom: "Oda Seç",
        common_searchTransfer: "Transfer",
        common_segment: "Segment",
        common_selectAll: "Tümünü Seç",
        common_sep: "Eyl",
        common_september: "Eylül",
        common_serviceFee: "Hizmet Bedeli",
        common_shortName: "Kısa İsim",
        common_show: "Göster",
        common_showAllOption: "Tümünü Göster",
        common_showLess: "Tüm Özellikleri Gizle",
        common_showLessOption: "Daha Az Göster",
        common_showMore: "Tüm Özellikleri Göster",
        common_showMoreOption: "Daha Fazla Göster",
        common_star_decrease: "Yıldız, Azalan",
        common_star_increase: "Yıldız, Artan",
        common_status: "Durum",
        common_success: "Başarılı",
        common_sun: "Paz",
        common_sunday: "Pazar",
        common_surname: "Soyad",
        common_tax: "Vergi",
        common_thu: "Per",
        common_thursday: "Perşembe",
        common_timezone: "Saat dilimi",
        common_toggle: "Göster / Gizle",
        common_total: "Toplam",
        common_total_penalty: "Toplam Ceza Tutarı",
        common_total_refund: "Toplam İade Tutarı",
        common_totalPrice: "Toplam Ücret",
        common_totalRefund: "İade Toplamı",
        common_totaltraveltime_decrease: "Top. Sey. Süresi, Azalan",
        common_totaltraveltime_increase: "Top. Sey. Süresi, Artan",
        common_totalwaitingtime_decrease: "Top. Bek. Süresi, Azalan",
        common_totalwaitingtime_increase: "Top. Bek. Süresi, Artan",
        common_transfers_crease: "Aktarma Adedi, Azalan",
        common_transfers_increase: "Aktarma Adedi, Artan",
        common_tue: "Sal",
        common_tuesday: "Salı",
        common_type: "Tip",
        common_unknown: "Bilinmiyor",
        common_unknownChange: "Bilinmeyen değişiklik detayı.",
        common_update: "Güncelle",
        common_userEmail: "Kullanıcı E-mail",
        common_voucher: "Voucher",
        common_wed: "Çar",
        common_wednesday: "Çarşamba",
        common_writeANote: "Not",
        common_zwischenstopps: "Aktarmalar",
        creditCardComponent_cvvLabel: "Güvenlik Kodu",
        creditCardComponent_holderLabel: "Kart Üzerindeki Ad Soyad",
        creditCardComponent_monthLabel: "Ay",
        creditCardComponent_panLabel: "Kart No",
        creditCardComponent_yearLabel: "Yıl",
        customcancel_lblcustomCancel: "İptal",
        customerList_customerList: "Müşteri Listesi",
        customerManagement_btnAddNewCustomer: "Yeni Müşteri Ekle",
        customerManagement_deleteCustomer: "Müşteri Sil",
        customerManagement_editCustomer: "Müşteri Düzenle",
        customerManagement_popupCustomerAddedSubtitle: "Müşteri eklendi.",
        customerManagement_popupCustomerDeleteSubtitle: "Müşteri Silindi.",
        customerManagement_popupCustomerEditSubtitle: "Müşteri güncellendi.",
        customerManagement_popupNewCustomerWillBeAdded: "Müşteri eklenecek. Onaylıyor musunuz?",
        customerManagement_thBirthdate: "Doğum Tarihi",
        customerManagement_thEmail: "E-mail",
        customerManagement_thNameSurname: "İsim-Soyisim",
        customerManagement_thNationalIdentity: "T.C. No.",
        customerManagement_thNationality: "Vatandaşlık",
        customerManagement_thPassportNumber: "Pasaport No",
        customerManagement_thPhone: "Telefon",
        customerSet_btnAdd: "Ekle",
        customerSet_btnClose: "KAPAT",
        customerSet_lblAgencyName: "Acente Adı",
        customerSet_lblBirthdate: "Doğum Tarihi",
        customerSet_lblCityCode: "Şehir Kodu",
        customerSet_lblCountryCode: "Ülke Kodu",
        customerSet_lblDateofExpiry: "Geçerlilik Tarihi",
        customerSet_lblEmail: "E-mail",
        customerSet_lblGender: "Cinsiyet",
        customerSet_lblHesCode: "HES Kodu",
        customerSet_lblMilesCard: "Mil No",
        customerSet_lblName: "İsim",
        customerSet_lblNationalIdentity: "T.C. No.",
        customerSet_lblNationality: "Vatandaşlık",
        customerSet_lblPassportNumber: "Pasaport No",
        customerSet_lblPhoneNumber: "Telefon",
        customerSet_lblSerialNumber: "Seri No",
        customerSet_lblSurname: "Soy İsim",
        customerSettings_lblAction: "Düzenle",
        customerSettings_lblAddNewCustomer: "Yeni Müşteri Ekle",
        customerSettings_lblBirthday: "Doğum Tarihi",
        customerSettings_lblCustomerSettings: "Müşteri İşlemleri",
        customerSettings_lblEmail: "E-mail",
        customerSettings_lblFullName: "İsim-Soyisim",
        customerSettings_lblNationalIdentity: "T.C. No.",
        customerSettings_lblNationality: "Vatandaşlık",
        customerSettings_lblPhone: "Telefon",
        dashboard_flightForthComings: "Yaklaşan Uçuşlar",
        dashboard_flightGiros: "Ciro Raporu - Uçak",
        dashboard_flightPerformance: "Uçuş Performans",
        dashboard_flightReservations: "Yaklaşan Rezervasyonlar - Uçak",
        dashboard_hotelPerformance: "Otel Performans",
        dashboard_hotelReservations: "Yaklaşan Rezervasyonlar - Otel",
        dashboard_hotelUpcomingCheckins: "Yaklaşan Check-Inler",
        dashboard_rentACarUpcoming: "Yaklaşan Araç Kiralama",
        dashboard_reportsGiro: "Satış Raporları",
        dashboard_reservations: "Rezervasyonlar",
        dashboard_transferUpcoming: "Yaklaşan Rezervasyonlar - Transfer",
        dashboard_upcomings: "Satın Alınanlar",
        dashboardAnnouncements_noContent: "Anons bulunmuyor.",
        dashboardCampaigns_noContent: "Kampanya bulunmuyor.",
        dashboardGiro_allProductsTotal: "Toplam Brüt Satışlar",
        dashboardGiro_apexAjet: "Ajet",
        dashboardGiro_apexCorendon: "XC",
        dashboardGiro_apexFlight: "Uçak",
        dashboardGiro_apexHotel: "Otel",
        dashboardGiro_apexOther: "Diğerleri",
        dashboardGiro_apexPegasus: "PC",
        dashboardGiro_apexRentACar: "Araç Kiralama",
        dashboardGiro_apexSunexpress: "XQ",
        dashboardGiro_apexTransfer: "Transfer",
        dashboardGiro_apexTurkishAirlines: "TK",
        dashboardGiro_giroInfoText: "Satış Raporunda gerçekleştirilmiş olan cirolar; iptal, iade ve değişiklik işlemlerinden arındırılmamış olan brüt ciroları göstermektedir.",
        dashboardGiro_gross: "Brüt",
        dashboardGiro_issuedEntityCount2: "PNR",
        dashboardGiro_issuedEntityCount3: "Voucher",
        dashboardGiro_issuedEntityCount4: "TNR",
        dashboardGiro_issuedEntityCount5: "RNR",
        dashboardGiro_issuedEntityCountDesc2: "(Alındı)",
        dashboardGiro_issuedEntityCountDesc3: "(Alındı)",
        dashboardGiro_issuedEntityCountDesc4: "(Alındı)",
        dashboardGiro_issuedEntityCountDesc5: "(Alındı)",
        dashboardGiro_issuedEtickets: "Pax",
        dashboardGiro_issuedPaxCount2: "Pax",
        dashboardGiro_issuedPaxCount3: "Pax",
        dashboardGiro_issuedPaxCountDesc2: "(Alındı)",
        dashboardGiro_issuedPaxCountDesc3: "(Alındı)",
        dashboardGiro_issuedPNR: "Pnr",
        dashboardGiro_issuedRoomCount3: "Oda",
        dashboardGiro_issuedRoomCountDesc3: "(Alındı)",
        dashboardGiro_net: "Net",
        dashboardGiro_reservedEntityCount2: "PNR",
        dashboardGiro_reservedEntityCount3: "Voucher",
        dashboardGiro_reservedEntityCountDesc2: "(Rezerve)",
        dashboardGiro_reservedEntityCountDesc3: "(Rezerve)",
        dashboardGiro_reservedEtickets: "Pax",
        dashboardGiro_reservedPaxCount2: "Pax",
        dashboardGiro_reservedPaxCountDesc2: "(Rezerve)",
        dashboardGiro_reservedPNR: "Pnr",
        dashboardGiro_total: "Toplam",
        dashboardPage_announcements: "Duyurular",
        dashboardPage_arrange_off: "Sayfayı Düzenle - KAPALI",
        dashboardPage_arrange_on: "Sayfayı Düzenle - AÇIK",
        dashboardPage_campaigns: "Kampanyalar",
        dashboardPage_carModelRoute: "Araç / Teslim Alacağı Yer",
        dashboardPage_checkInDate: "Giriş Tarihi",
        dashboardPage_driverName: "İsim-Soyisim",
        dashboardPage_flight_Flights: "Yaklaşan Uçuşlar",
        dashboardPage_flight_Performance: "Uçuş Performans",
        dashboardPage_flight_Reservations: "Yaklaşan Rezervasyonlar - Uçak",
        dashboardPage_flightMyReservations: "Rezervasyonlarım - Uçak",
        dashboardPage_guestName: "Ad Soyad",
        dashboardPage_hotel_Checkins: "Yaklaşan Otel Girişleri",
        dashboardPage_hotel_Performance: "Otel Performans",
        dashboardPage_hotel_Reservations: "Yaklaşan Rezervasyonlar - Otel",
        dashboardPage_hotelMyCheckIns: "Check-In'lerim",
        dashboardPage_hotelMyReservations: "Rezervasyonlarım - Otel",
        dashboardPage_hotelName: "Otel Adı",
        dashboardPage_incompleteOrder: "Günlük İşlemler",
        dashboardPage_lastBookingDateTime: "Son Satın Alma Tarihi",
        dashboardPage_lblAnnouncementDetail: "Duyuru Detayı",
        dashboardPage_lblAnnouncementList: "Duyuru Listesi",
        dashboardPage_lblCampaignList: "Kampanya Listesi",
        dashboardPage_lblReadMore: "Devamını Oku",
        dashboardPage_lblShowDetails: "Detayları Göster",
        dashboardPage_lblValidDayCount: "Son #VALUE# Gün",
        dashboardPage_provideRoute: "Tedarikçi / Rota",
        dashboardPage_providerRoute: "Rota",
        dashboardPage_rentDate: "Alınış Zamanı",
        dashboardPage_reservationStatus: "Rezervasyon Durumu",
        dashboardPage_RNR: "RNR",
        dashboardPage_showOldPosts: "Eski Gönderileri Göster",
        dashboardPage_Time: "Zaman",
        dashboardPage_TNR: "TNR",
        dashboardPage_totalGiro: "Günlük Ciro",
        dashboardPage_transferDate: "Transfer Tarihi",
        dashboardPage_travelerName: "İsim-Soyisim",
        dashboardPage_welcomeBack: "Tekrar Hoşgeldiniz",
        dashboardPage_widgetBookingDetails: "Kayıt Bilgisi",
        dashboardPage_widgetBookingNumber: "Kayıt No.",
        dashboardPage_widgetFlightMyPastReservations: "Geçmiş Rezervasyonlarımı yenile",
        dashboardPage_widgetFlightMyReservations: "Rezervasyonlarım - Uçak",
        dashboardPage_widgetForthComingFlights: "Yaklaşan Uçuşlar",
        dashboardPage_widgetGiroAll: "Tümü",
        dashboardPage_widgetGiroFlight: "Uçak",
        dashboardPage_widgetHotelName: "Otel Adı",
        dashboardPage_widgetMyReservations: "Yaklaşan Uçuşlar",
        dashboardPage_widgetNameSurname: "İsim Soyisim",
        dashboardPage_widgetPastReservations: "Geçmiş Rezervasyonlarım - Uçak",
        dashboardPage_widgetPnr: "PNR",
        dashboardPage_widgetProviderRoute: "Tedarikçi / Rota",
        dashboardPage_widgetReservationDetails: "Rezervasyon Bilgisi",
        dashboardPage_widgetReservationNumber: "Rezervasyon No.",
        dashboardPage_widgetReservationsAll: "Tümü",
        dashboardPage_widgetReservationsFlight: "Uçak",
        dashboardPage_widgetReservationsHotel: "Otel",
        dashboardPage_widgetRnr: "RNR",
        dashboardPage_widgetRoute: "Araç / Rota",
        dashboardPage_widgetTime: "Zaman",
        dashboardPage_widgetTnr: "TNR",
        dashboardPage_widgetUpcomingCharterFlights: "Yaklaşan Charter Uçuşlar",
        dashboardPage_widgetUpcomingsAll: "Tümü",
        dashboardPage_widgetUpcomingsFlight: "Uçak",
        dashboardPage_widgetUpcomingsHotel: "Otel",
        dashboardPage_widgetUpcomingsRentACar: "Araç Kiralama",
        dashboardPage_widgetUpcomingsTransfer: "Transfer",
        dashboardPage_widgetVoucher: "Voucher",
        dashboardTable_last72Hours: "Son 72 saat",
        dashboardTable_onlyFlightChanges: "Saat Değişikliği",
        dashboardTable_onlyOnlineCheckIns: "Online Check-in",
        dashboardTable_onlyPartialPaids: "Sadece Parçalı Alındı",
        dashboardTable_onlyPastReservations: "Geçmiş Rezervasyonlar",
        dashboardTable_onlyWKSC: "Sadece Saat Değişikliği",
        dashboardTable_print: "Yazdır.",
        DocumentModal_lblEmail: "E-mail",
        documentViewer_copyToClipboard: "Kopyala.",
        documentViewer_couldntSendMail: "Mail gönderilemedi.",
        documentViewer_deselectAll: "Tümünü Bırak",
        documentViewer_documentCannotGet: "Döküman yüklenirken bir hata oluştu!",
        documentViewer_generatePDF: "Yenile",
        documentViewer_mailSentSuccessful: "Mail başarılı bir şekilde gönderilmiştir.",
        documentViewer_mailSentSuccessfully: "Mail başarılı bir şekilde gönderilmiştir.",
        documentViewer_openPdf: "Pdf Görüntüle.",
        documentViewer_paxBasedInfoTitle: "Kişi bazlı yazdırma.",
        documentViewer_phEmail: "E-Mailler",
        documentViewer_popupCopiedToClipboard: "Kopyalandı",
        documentViewer_printDocument: "Yazdır.",
        documentViewer_selectAll: "Tümünü Seç",
        documentViewer_sendEmail: "E-mail gönder.",
        documentViewer_sendToWhatsapp: "Whatsapp ile gönder.",
        documentViewer_showCancelPolicies: "İptal Koşullları Göster / Gizle",
        documentViewer_showOptionDate: "Opsiyon Tarihi Göster / Gizle",
        documentViewer_showPrice: "Fiyatlı-Fiyatsız",
        documentViewer_documentCannotGet_documentCannotGet: "Doküman bulunamadı.",
        EAccTransactionTypeGroup_BONUS: "Bonus",
        EAccTransactionTypeGroup_CC_COMMISSION: "KK Komisyon",
        EAccTransactionTypeGroup_CC_PAYMENT: "KK Ödeme",
        EAccTransactionTypeGroup_F_COM_VPOS: "KK Komisyon Uçak",
        EAccTransactionTypeGroup_F_PAY_VPOS: "KK Ödeme Uçak",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY: "Uçak Bileti Ancillary",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY_REV: "Uçak Bileti Ancillary İade",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY_REVERSE: "Uçak Bileti Ancillary İade",
        EAccTransactionTypeGroup_FLIGHT_COMMISSION: "Uçak Komisyon",
        EAccTransactionTypeGroup_FLIGHT_COMMISSION_REVERSE: "Uçak Komisyon İade",
        EAccTransactionTypeGroup_FLIGHT_FARE: "Uçak Bileti Satış",
        EAccTransactionTypeGroup_FLIGHT_FARE_REVERSE: "Uçak Bileti İade",
        EAccTransactionTypeGroup_H_COM_VPOS: "KK Komisyon Otel",
        EAccTransactionTypeGroup_H_PAY_VPOS: "KK Ödeme Otel",
        EAccTransactionTypeGroup_HOTEL_COMMISSION: "Otel Komisyon",
        EAccTransactionTypeGroup_HOTEL_COMMISSION_REVERSE: "Otel Komisyon İade",
        EAccTransactionTypeGroup_HOTEL_FARE: "Otel Satış",
        EAccTransactionTypeGroup_HOTEL_FARE_REVERSE: "Otel İade",
        EAccTransactionTypeGroup_PAYMENT: "Ödeme",
        EAccTransactionTypeGroup_R_COM_VPOS: "KK Komisyon Rent A Car",
        EAccTransactionTypeGroup_R_PAY_VPOS: "KK Ödeme Rent A Car",
        EAccTransactionTypeGroup_RENTACAR_ANCILLARY: "Rent A Car Ek Hizmet",
        EAccTransactionTypeGroup_RENTACAR_ANCILLARY_REVERSE: "Rent A Car Ek Hizmetler İade",
        EAccTransactionTypeGroup_RENTACAR_COMMISSION: "Rent A Car Komisyon",
        EAccTransactionTypeGroup_RENTACAR_COMMISSION_REVERSE: "Rent A Car Komisyon İade",
        EAccTransactionTypeGroup_RENTACAR_EXTRA: "Rent A Car Ekstra Satış",
        EAccTransactionTypeGroup_RENTACAR_EXTRA_REVERSE: "Rent A Car Ekstra İade",
        EAccTransactionTypeGroup_RENTACAR_FARE: "Rent A Car Satış",
        EAccTransactionTypeGroup_RENTACAR_FARE_REVERSE: "Rent A Car İade",
        EAccTransactionTypeGroup_REV_CC_PAYMENT: "KK Ödeme İade",
        EAccTransactionTypeGroup_REV_F_PAY_VPOS: "KK Ödeme Uçak İade",
        EAccTransactionTypeGroup_REV_H_COM_VPOS: "KK Komisyon Otel İade",
        EAccTransactionTypeGroup_REV_H_PAY_VPOS: "KK Ödeme Otel İade",
        EAccTransactionTypeGroup_REV_R_PAY_VPOS: "KK Komisyon Rent A Car İade",
        EAccTransactionTypeGroup_REV_T_COM_VPOS: "KK Komisyon Transfer İade",
        EAccTransactionTypeGroup_REV_T_PAY_VPOS: "KK Ödeme Transfer İade",
        EAccTransactionTypeGroup_SYSTEM_ERROR_CORRECTION: "Sistem Hatası Düzeltmeleri",
        EAccTransactionTypeGroup_T_COM_VPOS: "KK Komisyon Transfer",
        EAccTransactionTypeGroup_T_PAY_VPOS: "KK Ödeme Transfer",
        EAccTransactionTypeGroup_TRANSFER: "Transfer",
        EAccTransactionTypeGroup_TRANSFER_COMMISSION: "Transfer Komisyon",
        EAccTransactionTypeGroup_TRANSFER_COMMISSION_REVERSE: "Transfer Komisyon İade",
        EAccTransactionTypeGroup_TRANSFER_FARE: "Transfer Satış",
        EAccTransactionTypeGroup_TRANSFER_FARE_REVERSE: "Transfer İade",
        EAccTransactionTypeGroup_UNKNOWN: "Tanımsız",
        EAccTransactionTypeGroup_VIRTUAL_CREDIT: "Virtual Credit",
        EComApplication_AGENT_SPA: "AGENT VUE",
        EComGender_FEMALE: "Bayan",
        EComGender_MALE: "Bay",
        EFltCabinType_BUSINESS: "Business",
        EFltCabinType_ECONOMY: "Ekonomi",
        EFltCabinType_UNKNOWN: "Tanımsız",
        EFltEticketStatus_11: "Rezerve - Beklemede",
        EFltEticketStatus_ALL: "Tümü",
        EFltEticketStatus_BAGGAGE: "Bagaj",
        EFltEticketStatus_CANCELED: "İptal",
        EFltEticketStatus_ERROR: "Hatalı",
        EFltEticketStatus_FOOD: "Yemek",
        EFltEticketStatus_ISSUED: "Alındı",
        EFltEticketStatus_MEAL: "Yemek",
        EFltEticketStatus_NEW: "Hatalı -Yeni",
        EFltEticketStatus_OPEN: "Açık",
        EFltEticketStatus_OTHER: "Diğer",
        EFltEticketStatus_PENDING: "Alınıyor - Beklemede",
        EFltEticketStatus_PENDING_ISSUED: "Alınıyor - Beklemede",
        EFltEticketStatus_PENDING_REFUNDED: "İade Edildi - Pending",
        EFltEticketStatus_PENDING_RESERVED: "Rezerve - Pending",
        EFltEticketStatus_PENDING_VOIDED: "Void - Pending",
        EFltEticketStatus_REFUNDED: "İade",
        EFltEticketStatus_REISSUED: "Değişiklik",
        EFltEticketStatus_RESERVED: "Rezerve",
        EFltEticketStatus_SEAT: "Koltuk",
        EFltEticketStatus_UNKNOWN: "Tanımsız",
        EFltEticketStatus_VOIDED: "Void",
        EFltEticketStatus_WHEELCHAIR: "T. Sandalye",
        EFltPaxType_ADULT: "Yetişkin",
        EFltPaxType_ADULT_WITH_INFANT: "Yetişkin ve Bebek",
        EFltPaxType_ALL: "Tümü",
        EFltPaxType_CHILD: "Çocuk",
        EFltPaxType_DISABLED_COMPANION: "Engelli Refakatçisi",
        EFltPaxType_EXTRA_SEAT: "Extra Seat",
        EFltPaxType_INFANT: "Bebek",
        EFltPaxType_UNKNOWN: "Tanımsız",
        EFltPnrStatus_9: "Açık",
        EFltPnrStatus_ALL: "Tümü",
        EFltPnrStatus_CANCELED: "İptal",
        EFltPnrStatus_ERROR: "Hatalı",
        EFltPnrStatus_ISSUED: "Alındı",
        EFltPnrStatus_NEW: "Hatalı -Yeni",
        EFltPnrStatus_OPEN: "Açık Bilet",
        EFltPnrStatus_PENDING: "Alınıyor - Beklemede",
        EFltPnrStatus_PENDING_CANCELED: "İptal - Pending",
        EFltPnrStatus_PENDING_CANCELLED: "İptal - Pending",
        EFltPnrStatus_PENDING_ISSUED: "Alındı - Pending",
        EFltPnrStatus_PENDING_REFUNDED: "İade Edildi - Pending",
        EFltPnrStatus_PENDING_RESERVED: "Rezerve - Pending",
        EFltPnrStatus_PENDING_VOIDED: "Void - Pending",
        EFltPnrStatus_REFUNDED: "İade Edildi",
        EFltPnrStatus_REISSUED: "Değişiklik",
        EFltPnrStatus_RESERVED: "Rezerve",
        EFltPnrStatus_UNKNOWN: "Tanımsız",
        EFltPnrStatus_VOIDED: "Void",
        EFltSeatTypeEnum_AISLE: "Koridor",
        EFltSeatTypeEnum_EXIT: "Acil Çıkış",
        EFltSeatTypeEnum_EXTRA_LEGROOM: "XL Koltuk",
        EFltSeatTypeEnum_STANDARD: "Standart",
        EFltSeatTypeEnum_UNKNOWN: "Tanımsız",
        EFltSeatTypeEnum_WINDOW: "Cam Kenarı",
        EFltSsrFoodType_AVML: "Asyatik Vejetaryen",
        EFltSsrFoodType_BBML: "Bebek yemeği",
        EFltSsrFoodType_CHML: "Çocuk Yemeği",
        EFltSsrFoodType_CNML: "Tavuk Yemeği",
        EFltSsrFoodType_DBML: "Diyabetik yemek",
        EFltSsrFoodType_FPML: "Meyve Tabağı",
        EFltSsrFoodType_FSML: "Balık Yemeği",
        EFltSsrFoodType_GFML: "Glutensiz yemek",
        EFltSsrFoodType_GPML: "Grup SSR Atıştırmalık & 1 iç.",
        EFltSsrFoodType_HNML: "Hint Yemeği",
        EFltSsrFoodType_KSML: "Koşer yemeği",
        EFltSsrFoodType_LCML: "Düşük kalorili yemek",
        EFltSsrFoodType_LFML: "Düşük Kolesterollü yemek",
        EFltSsrFoodType_LPML: "Düşük Proteinli Yemek",
        EFltSsrFoodType_LSML: "Tuzsuz yemek",
        EFltSsrFoodType_MOML: "Müslüman Yemeği",
        EFltSsrFoodType_NLML: "Laktoz İçermeyen Yemek",
        EFltSsrFoodType_NOML: "Yemek Yok",
        EFltSsrFoodType_PMIB: "Kahvaltı Tabağı",
        EFltSsrFoodType_PMIC: "Tavuk Şiş Kebap",
        EFltSsrFoodType_PMID: "Kızarmış Çıtır Tavuk",
        EFltSsrFoodType_PMIE: "Dana Gulaş",
        EFltSsrFoodType_SFML: "Deniz mahsulleri yemeği",
        EFltSsrFoodType_SPML: "Special Yemek",
        EFltSsrFoodType_TBML: "T/O 2 atıştırmalık & 1 su",
        EFltSsrFoodType_VGML: "Vejetaryen Yemeği",
        EFltSsrFoodType_VJML: "Katı Vejetaryen Yemeği",
        EFltSsrFoodType_VLML: "Ovo -Locto Vejetaryen Yemek",
        EFltSsrFoodType_VOML: "Oriental (Uzak doğu) yemeği",
        EFltSsrMealType_AVML: "Asyatik Vejetaryen",
        EFltSsrMealType_BBML: "Bebek Yemeği",
        EFltSsrMealType_BLML: "Hazmı Kolay Yemek",
        EFltSsrMealType_CHML: "Çocuk Yemeği",
        EFltSsrMealType_CNML: "Tavuk Yemeği",
        EFltSsrMealType_DBML: "Diyabetik yemek",
        EFltSsrMealType_EFltSsrMealType: "Çocuk Yemeği",
        EFltSsrMealType_FPML: "Meyve Tabağı",
        EFltSsrMealType_FSML: "Balık Yemeği",
        EFltSsrMealType_GFML: "Glutensiz Yemek",
        EFltSsrMealType_GPML: "Grup SSR Atıştırmalık & 1 iç.",
        EFltSsrMealType_HNML: "Hint Yemeği",
        EFltSsrMealType_KSML: "Koşer yemeği",
        EFltSsrMealType_LCML: "Düşük kalorili yemek",
        EFltSsrMealType_LFML: "Düşük Kolesterollü yemek",
        EFltSsrMealType_LPML: "Düşük Proteinli Yemek",
        EFltSsrMealType_LSML: "Tuzsuz yemek",
        EFltSsrMealType_MOML: "Müslüman Yemeği",
        EFltSsrMealType_NLML: "Laktoz İçermeyen Yemek",
        EFltSsrMealType_NOML: "Yemek Yok",
        EFltSsrMealType_PMIB: "Kahvaltı Tabağı",
        EFltSsrMealType_PMIC: "Tavuk Şiş Kebap",
        EFltSsrMealType_PMID: "Kızarmış Çıtır Tavuk",
        EFltSsrMealType_PMIE: "Dana Gulaş",
        EFltSsrMealType_PRIBAS_SUN_CHML: "Çocuk Menüsü",
        EFltSsrMealType_PRIBAS_SUN_PMDB: "Kahvaltı Tabağı",
        EFltSsrMealType_PRIBAS_SUN_PMDC: "Tavuk Şiş Kebap",
        EFltSsrMealType_PRIBAS_SUN_PMDD: "Kızarmış Çıtır Tavuk",
        EFltSsrMealType_PRIBAS_SUN_PMDE: "Dana Gulaş",
        EFltSsrMealType_PRIBAS_SUN_PMIB: "Kahvaltı Tabağı",
        EFltSsrMealType_PRIBAS_SUN_PMIC: "Tavuk Şiş Kebap",
        EFltSsrMealType_PRIBAS_SUN_PMID: "Kızarmış Çıtır Tavuk",
        EFltSsrMealType_PRIBAS_SUN_PMIE: "Dana Gulaş",
        EFltSsrMealType_PRIBAS_SUN_SCSM: "Snack Box & 1 su",
        EFltSsrMealType_PRIBAS_SUN_SPDB: "Sun Premium Omlet & 2 İçecek",
        EFltSsrMealType_PRIBAS_SUN_SPDC: "Sun Premium Köfte & 2 İçecek",
        EFltSsrMealType_PRIBAS_SUN_SPDD: "Sun Premimum Patlıcan & 2 İçecek",
        EFltSsrMealType_PRIBAS_SUN_SPDE: "Sun Premium Tavuk & 2 İçecek",
        EFltSsrMealType_PRIBAS_SUN_SPIB: "Sun Premium Köfte & 2 İçecek",
        EFltSsrMealType_PRIBAS_SUN_SPIC: "Sun Premium Tavuk & 2 İçecek",
        EFltSsrMealType_PRIBAS_SUN_SPID: "Sun Premium Ravioli & 2 içecek",
        EFltSsrMealType_PRIBAS_SUN_SPIE: "Sun Premium Salata & 2 içecek",
        EFltSsrMealType_PRIBAS_SUN_SPVG: "Falafel (vegan) & 1 alkolsüz içecek",
        EFltSsrMealType_PRIBAS_SUN_SPVL: "Meze Tabağı (vejetaryen) & 1 alkolsüz içecek",
        EFltSsrMealType_PRIBAS_SUN_VGML: "Falafel (vegan)",
        EFltSsrMealType_PRIBAS_SUN_VLML: "Meze Tabağı (vejetaryen)",
        EFltSsrMealType_SFML: "Deniz mahsulleri yemeği",
        EFltSsrMealType_SPML: "Special Yemek",
        EFltSsrMealType_TAML: "T/O tatlı atışt. & 1 su",
        EFltSsrMealType_TBML: "T/O 2 atıştırmalık & 1 su",
        EFltSsrMealType_VGML: "Vegan Yemek",
        EFltSsrMealType_VJML: "Katı Vejetaryen Yemeği",
        EFltSsrMealType_VOML: "Oriental (Uzak doğu) yemeği",
        EFltSsrType_BAGGAGE: "Bagaj",
        EFltSsrType_CABIN_BAG: "Cabin Bag",
        EFltSsrType_CHECKIN_AT_AIRPORT: "Check-in",
        EFltSsrType_FOOD: "Yemek",
        EFltSsrType_MEAL: "Yemek",
        EFltSsrType_OTHER: "Diğer",
        EFltSsrType_REISSUE_ALLOWANCE: "Sun Flex 3",
        EFltSsrType_SEAT: "Koltuk",
        EFltSsrType_WHEELCHAIR: "T. Sandalye",
        EFltSsrWheelchairType_WCBD: "Kuru akülü sandalye.",
        EFltSsrWheelchairType_WCHC: "Hiç yürüyemeyen yolcular ve merdiven çıkamayan yolcular.",
        EFltSsrWheelchairType_WCHR: "Uzun mesafede yürüyemeyen yolcular.",
        EFltSsrWheelchairType_WCHS: "Merdiven çıkamayan yolcular.",
        EFltSsrWheelchairType_WCLB: "Lityum batarya ile çalışan sandalye.",
        EFltSsrWheelchairType_WCMP: "Kişiye özel el gücüyle kullanılan sandalye.",
        EHtlAmountType_FIXED: "Sabit",
        EHtlAmountType_RATIO: "Oran",
        EHtlGuestType_ADULT: "Yetişkin",
        EHtlGuestType_CHILD: "Çocuk",
        EHtlPolicyType_CANCELLATION: "İptal",
        EHtlPolicyType_NO_SHOW: "No Show",
        EHtlPriceRestrictionType_ALL: "Hepsi",
        EHtlPriceRestrictionType_MIN_STAY: "MinimumStay",
        EHtlPriceRestrictionType_NO_RESTRICTION: "Kısıtlama Yok",
        EHtlPriceRestrictionType_QUOTA: "Quota",
        EHtlPriceRestrictionType_RELEASE: "Release",
        EHtlPriceRestrictionType_STOP: "Stop",
        EHtlPriceRestrictionType_UNKNOWN: "Tanımsız",
        EHtlRefundType_NONREFUNDABLE: "İade Edilemez",
        EHtlRefundType_REFUNDABLE: "İade Edilebilir",
        EHtlVoucherStatus_ALL: "Tümü",
        EHtlVoucherStatus_BOOKED: "Alındı",
        EHtlVoucherStatus_CANCELED: "Rezerveden İptal",
        EHtlVoucherStatus_ERROR: "Hatalı",
        EHtlVoucherStatus_NEW: "Yeni",
        EHtlVoucherStatus_PARTIAL_PAID: "Parçalı Alındı",
        EHtlVoucherStatus_REBOOKED: "Yeniden Oluşturuldu",
        EHtlVoucherStatus_REFUNDED: "Cezalı İptal",
        EHtlVoucherStatus_RESERVED: "Rezerve",
        EHtlVoucherStatus_UNKNOWN: "Tanımsız",
        EHtlVoucherStatus_VOIDED: "Alındıdan İptal",
        "enum_airport-checkin": "Havalimanı Check-in",
        enum_all: "Tümü",
        enum_AVML: "Asyatik Vejetaryen",
        enum_baggage: "Bagaj",
        enum_BBML: "Bebek yemeği",
        enum_BLML: "Hazmı kolay yemek",
        enum_booked: "Alındı",
        enum_business: "Business",
        "enum_cabin-bag": "1 Parça Kabin Bagajı",
        enum_canceled: "İptal",
        enum_cancellationPolicies_fixed: "{0} - {1} tarihleri arasında yapılan iptallerde toplam konaklama bedelinden {2}{3} kadar kesinti uygulanır.",
        enum_cancellationPolicies_fixed_no_show: "{0} itibari ile yapılan iptallerde {1}{2} No-Show bedeli uygulanır.",
        enum_cancellationPolicies_ratio: "{0} - {1} tarihleri arasında yapılan iptallerde toplam konaklama bedelinin {2}{3} kadar kesinti uygulanır.",
        enum_cancellationPolicies_ratio_no_show: "{0} itibari ile yapılan iptallerde {1}{2} No-Show bedeli uygulanır.",
        enum_cancelled: "İptal",
        enum_cc_commission: "KK Komisyon",
        enum_cc_payment: "KK Ödeme",
        enum_checkin_at_airport: "Havalimanı Check-in",
        enum_CHML: "Çocuk Yemeği",
        enum_close: "Kapalı",
        enum_closed: "Kapalı",
        enum_CNML: "Tavuk Yemeği",
        enum_completed: "Tamamlandı",
        enum_date1: "Başlangıç Tarihi",
        enum_DBML: "Diyabetik yemek",
        enum_duplicate: "Çift",
        enum_economy: "Ekonomi",
        enum_eHtlAmountType_ALL: "Tümü",
        enum_eHtlAmountType_FIXED: "Sabit",
        enum_eHtlAmountType_RATIO: "{0} - {1} tarihleri arasında yapılan iptallerde toplam konaklama bedelinin {2}{3} kadar kesinti uygulanır.",
        enum_eHtlAmountType_UNKNOWN: "Tanımsız",
        enum_EHtlRoomPriceStatus_NEW: "Yeni",
        enum_EHtlRoomPriceStatus_RESERVED: "Rezerve",
        enum_EHtlVoucherStatus_ALL: "Tümü",
        enum_EHtlVoucherStatus_BOOKED: "Alındı",
        enum_EHtlVoucherStatus_CANCELLED: "Rezerveden İptal",
        enum_EHtlVoucherStatus_ERROR: "Hatalı",
        enum_EHtlVoucherStatus_NEW: "Yeni",
        enum_EHtlVoucherStatus_REBOOKED: "Yeniden Oluşturuldu",
        enum_EHtlVoucherStatus_REFUNDED: "Cezalı İptal",
        enum_EHtlVoucherStatus_RESERVED: "Rezerve",
        enum_EHtlVoucherStatus_UNKNOWN: "Tanımsız",
        enum_EHtlVoucherStatus_VOIDED: "Alındıdan İptal",
        enum_enum_replied_by_agency: "Acenteden Yanıt Geldi",
        enum_error: "Hatalı",
        enum_f_com_vpos: "KK Komisyon Uçak",
        enum_f_pay_vpos: "KK Ödeme Uçak",
        enum_fcadom: "Uçak Yurtiçi / Fatura / Cari Hesap",
        enum_fcaint: "Uçak Yurtdışı / Fatura / Cari Hesap",
        enum_fccdom: "Uçak Yurtiçi / Fatura / Kredi Kartı",
        enum_fccint: "Uçak Yurtdışı / Fatura / Kredi Kartı",
        enum_fcodom: "Uçak Yurtiçi /Fatura / Kredi Kartı Komisyon",
        enum_fcoint: "Uçak Yurtdışı / Fatura / Kredi Kartı Komisyon",
        enum_FPML: "Meyve Tabağı",
        enum_FSML: "Balık Yemeği",
        enum_GFML: "Glutensiz yemek",
        "enum_hand-wallet": "Sadece 1 Adet Koltuk Altına Sığacak El Çantası Max 40x30x15 cm boyutlarında",
        enum_HNML: "Hint Yemeği",
        enum_hotel: "Otel",
        enum_insurance: "Sigorta",
        enum_invoice: "Fatura",
        enum_issued: "Alındı",
        enum_KSML: "Koşer yemeği",
        enum_LCML: "Düşük kalorili yemek",
        enum_LFML: "Düşük Kolesterollü yemek",
        enum_LPML: "Düşük Proteinli Yemek",
        enum_LSML: "Tuzsuz yemek",
        enum_meal: "Yemek",
        enum_MOML: "Müslüman Yemeği",
        enum_new: "Yeni",
        enum_NLML: "Laktoz İçermeyen Yemek",
        enum_open: "Açık",
        enum_other: "other",
        enum_package_info: "Paket Bilgi Taskı",
        enum_partial_paid: "Parçalı Alındı",
        enum_payment: "Ödeme",
        enum_payment_reverse: "Ödeme İptal - İade",
        enum_pending_canceled: "İptal - Pending",
        enum_pending_issued: "Alındı - Pending",
        enum_pending_refunded: "İade Edildi - Pending",
        enum_pending_reserved: "Rezerve - Pending",
        enum_pending_voided: "Void - Pending",
        enum_pnr: "Pnr",
        enum_pnr_add: "Manuel Pnr Yükleme",
        enum_pnr_ancillary: "Ek Hizmetler",
        enum_pnr_ancillary_rev: "Uçak Bileti Ancillary İade",
        enum_pnr_commission: "Uçak Komisyon",
        enum_pnr_commission_reverse: "Uçak Komisyon İade",
        enum_pnr_correction: "Pnr Düzeltme",
        enum_pnr_divide: "Pnr - Divide",
        enum_pnr_fare: "Uçak Bileti Satış",
        enum_pnr_fare_reverse: "Uçak Bileti İade",
        enum_pnr_open: "Açık Bilet",
        enum_pnr_reissue: "Değişiklik",
        enum_pnr_reverse: "İptal - İade",
        enum_pnr_update: "Pnr Güncelleme",
        enum_pnr_update_pax: "Yolcu Bilgisi Güncelleme",
        enum_processing: "İşlem sürüyor",
        enum_receiving_pending: "Alınıyor - Beklemede",
        enum_refunded: "İade Edildi",
        "enum_reissue-allowance": "Sun Flex 3",
        enum_reissued: "Değişiklik",
        enum_reopen: "Yeniden açıldı",
        enum_replied_by_agency: "Acenteden Yanıt Geldi",
        enum_replied_by_provider: "Tedarikçi Yanıtladı.",
        enum_reserved: "Rezerve",
        enum_rev_f_com_vpos: "KK Komisyon Uçak İade",
        enum_rev_f_pay_vpos: "KK Ödeme Uçak İade",
        Enum_rnr_ancillary: "Rnr Ancillary",
        enum_rnr_reissue: "Araç Kiralama Değişiklik",
        enum_rnr_reverse: "Araç Kiralama İptal",
        enum_seat: "Koltuk",
        enum_SFML: "Deniz mahsulleri yemeği",
        enum_sunflex: "Kalkıştan 3 gün öncesine kadar yeniden rezervasyon imkanı (+fiyat farkı).",
        enum_tnr_add: "Transfer Ekleme",
        enum_tnr_reissue: "Transfer Değişiklik",
        enum_tnr_reverse: "Transfer İptal",
        enum_transfer: "Transfer",
        enum_unknown: "Tanımsız",
        enum_VGML: "Vejetaryen Yemeği",
        enum_VJML: "Katı Vejetaryen Yemeği",
        enum_VLML: "Ovo -Locto Vejetaryen Yemek",
        enum_void: "Void",
        enum_voided: "Void",
        enum_VOML: "Oriental (Uzak doğu) yemeği",
        enum_voucher_add: "Voucher Ekleme",
        enum_voucher_reissue: "Otel Değişiklik",
        enum_voucher_reverse: "İptal - İade",
        enum_voucher_roomdateprice_update: "Voucher Güncelleme",
        enum_voucher_update: "Voucher Güncelleme",
        Enum_waiting: "Bekleniyor",
        enum_waiting_on_agency: "Acenteden Yanıt Bekleniyor",
        enum_waiting_on_operator: "Operatörden Yanıt Bekleniyor",
        Enum_waiting_on_provider: "Operatörden Yanıt Bekleniyor",
        enum_WCBD: "Kuru akülü sandalye.",
        enum_WCHC: "Hiç yürüyemeyen yolcular ve merdiven çıkamayan yolcular.",
        enum_WCHR: "Uzun mesafede yürüyemeyen yolcular.",
        enum_WCHS: "Merdiven çıkamayan yolcular.",
        enum_WCLB: "Lityum batarya ile çalışan sandalye.",
        enum_WCMP: "Kişiye özel el gücüyle kullanılan sandalye.",
        enum_wheelchair: "T. Sandalye",
        enum_wont_fix: "Düzelmeyecek",
        EOdrEntityType_HOTEL: "VOUCHER",
        EOdrEntityType_INSURANCE: "Sigorta",
        EOdrEntityType_PNR: "PNR",
        EOdrEntityType_TRANSFER: "TRANSFER",
        EOdrEntityType_UNKNOWN: "Tanımsız",
        EOdrSearchHotelDateType_CHECKIN: "Giriş Tarihi",
        EOdrSearchHotelDateType_PROCESS: "Satış Tarihi",
        EOdrSearchRentACarDateType_PICKUP: "Alınış",
        EOdrSearchRentACarDateType_PROCESS: "Satış",
        EOdrType_FLIGHT: "Uçak",
        EPayCardBrand_UNKNOWN: "Tanımsız",
        EProcessType_FLIGHT_ONLINE_ANCILLARY_SALE: "Online Ancillary Satış",
        ERacAncillaryType_ALL: "Tümü",
        ERacAncillaryType_BABY_SEAT: "Bebek/Çocuk Koltuğu",
        ERacAncillaryType_EXTRA_DAMAGE: "Ekstra Hasar",
        ERacAncillaryType_EXTRA_PICKUP_DROPOFF: "Farklı Noktadan Al veya Bırak",
        ERacAncillaryType_EXTRA_TRAFFIC_PENALTY: "Ekstra Trafik Cezası",
        ERacAncillaryType_INSURANCE_ECO: "Lastik - Cam - Far Sigortası",
        ERacAncillaryType_INSURANCE_PREMIUM: "Sigorta",
        ERacAncillaryType_INTERNET: "İnternet",
        ERacAncillaryType_MANDAT_ONEWAY_TRANSFER: "Tek Yön Transfer",
        ERacAncillaryType_NAVIGATION: "Navigasyon",
        ERacAncillaryType_SNOW_CHAIN: "Kar Zinciri",
        ERacAncillaryType_SNOW_TIRE: "Kar Lastiği",
        ERacAncillaryType_UNKNOWN: "Tanımsız",
        ERacBodyType_ALL: "Tümü",
        ERacBodyType_HATCHBACK: "Hatchback",
        ERacBodyType_MINIVAN: "Minivan",
        ERacBodyType_SEDAN: "Sedan",
        ERacBodyType_SUV: "Suv",
        ERacBodyType_UNKNOWN: "Tanımsız",
        ERacBodyType_VAN: "Van",
        ERacFacilityType_AIRPORT_SURCHARGE: "Havaalanı Ek Ücreti",
        ERacFacilityType_ALL: "Tümü",
        ERacFacilityType_COLLISION_DAMAGE_WAIVER: "Kasko",
        ERacFacilityType_FREE_CANCELLATION: "Ücretsiz İptal (son 24 saat)",
        ERacFacilityType_THEFT_WAIVER: "Hırsızlık Sigortası",
        ERacFacilityType_THIRD_PARTY_LIABILITY_INSURANCE: "Üçüncü Kişilere Karşı Yükümlülük",
        ERacFacilityType_UNKNOWN: "Tanımsız",
        ERacFacilityType_UNLIMITED_MILEAGE: "Sınırsız Kilometre",
        ERacFuelType_ALL: "Tümü",
        ERacFuelType_DIESEL: "Dizel",
        ERacFuelType_ELECTRIC: "Elektrik",
        ERacFuelType_GASOLINE: "Benzin",
        ERacFuelType_UNKNOWN: "Tanımsız",
        ERacGearType_ALL: "Tümü",
        ERacGearType_AUTOMATIC: "Otomatik",
        ERacGearType_MANUEL: "Manuel",
        ERacGearType_UNKNOWN: "Tanımsız",
        ERacPassengerCapacity_3: "3 Yolcu",
        ERacPassengerCapacity_5: "5 Yolcu",
        ERacPassengerCapacity_7: "7 Yolcu",
        ERacPassengerCapacity_9: "9 Yolcu",
        ERacRnrStatus_ALL: "Tümü",
        ERacRnrStatus_CANCELED: "İptal Edildi",
        ERacRnrStatus_ERROR: "Hatalı",
        ERacRnrStatus_ISSUED: "Alındı",
        ERacRnrStatus_NEW: "Yeni",
        ERacRnrStatus_OPEN: "Açık",
        ERacRnrStatus_PENDING: "Alınıyor - Beklemede",
        ERacRnrStatus_REFUNDED: "İade Edildi",
        ERacRnrStatus_REISSUED: "Değişiklik",
        ERacRnrStatus_RESERVED: "Rezerve",
        ERacRnrStatus_VOIDED: "Void",
        EReportHotelDateType_CHECK_IN: "Giriş",
        EReportHotelDateType_CHECK_OUT: "Çıkış",
        EReportHotelDateType_SALE: "Satış",
        EReportRentACarDateType_PICKUP: "Alınış Zamanı",
        EReportRentACarDateType_PROCESS: "Satış Tarihi",
        EReportRentACarReportType_ANCILLARY: "Ek Hizmetler",
        EReportRentACarReportType_VEHICLE: "Araç",
        EReportSalesType_REISSUE: "Değişiklik",
        EReportSalesType_SALE: "Satış",
        EReportTransferDateType_ALL: "Tümü",
        EReportTransferDateType_SALE: "Satış",
        EReportTransferDateType_TRANSFER: "Transfer Tarihi",
        EReportTransferDateType_UNKNOWN: "Tanımsız",
        "ERROR_CODES_AGENT_API.FLIGHT_TICKET.SUCCESS.PAYMENT_POST": "İşleminiz başarılı bir şekilde gerçekleştirilmiştir.",
        "ERROR_CODES_AGENT_API.HOTEL_TICKET_RESERVED.REJECTED.INSUFFICIENT_PAYMENT": "Ödeme sırasında bir hata aldınız.",
        "ERROR_CODES_FLIGHT_API.FLIGHT_RESERVATION.PARAMETER.INVALID_PASSENGER_INFO": "Lütfen girmiş olduğunuz yolcu bilgilerini kontrol ediniz.",
        ErrorReportList_thEntityType: "Ürün Tipi",
        ESchmetterlingOrderStatus_ALL: "Tümü",
        ESchmetterlingOrderStatus_BOOKED: "Alındı",
        ESchmetterlingOrderStatus_CANCELED: "İptal Edildi",
        ESchmetterlingOrderStatus_DISPLAYED: "Gösterim Başarılı",
        ESchmetterlingOrderStatus_OPTIONAL_BOOKED: "Opsiyonel",
        ESchmetterlingOrderStatus_RESERVED: "Rezerve",
        ESchmetterlingOrderStatus_UNKNOWN: "Tanımsız",
        ETaskClosingType_ALL: "Tümü",
        ETaskClosingType_CANCELED: "İptal Edildi",
        ETaskClosingType_COMPLETED: "Tamamlandı",
        ETaskClosingType_DUBLICATE: "Dup kayit",
        ETaskClosingType_UNKNOWN: "Tanımsız",
        ETaskClosingType_WONT_FIX: "Düzelmeyecek",
        ETaskStatus_ALL: "Tümü",
        ETaskStatus_CLOSED: "Kapalı",
        ETaskStatus_OPEN: "Açık",
        ETaskStatus_UNKNOWN: "Tanımsız",
        ETaskStatus_waiting_on_agency: "Acenteden Yanıt Bekleniyor",
        ETaskSubStatus_8: "Yanıtlandı",
        ETaskSubStatus_ALL: "Tümü",
        ETaskSubStatus_CLOSED: "Kapalı",
        ETaskSubStatus_NEW: "Yeni",
        ETaskSubStatus_PROCESSING: "İşlem sürüyor",
        ETaskSubStatus_REPLIED_BY_AGENCY: "Acenteden Yanıt Geldi",
        ETaskSubStatus_REPLIED_BY_PROVIDER: "Tedarikçi Yanıtladı.",
        ETaskSubStatus_UNKNOWN: "Tanımsız",
        ETaskSubStatus_WAITING: "Bekleniyor",
        ETaskSubStatus_WAITING_ON_AGENCY: "Acenteden Yanıt Bekleniyor",
        ETaskSubStatus_WAITING_ON_OPERATOR: "Operatörden Yanıt Bekleniyor",
        ETaskSubStatus_WAITING_ON_PROVIDER: "Tedarikçiden Yanıt Bekleniyor",
        ETaskSubStatusETaskSubStatus_REPLIED_BY_PROVIDER: "Tedarikçiden Yanıt Geldi",
        ETaskSubType_BASIC: "Basit",
        ETaskSubType_FLIGHT_CHANGE_CONFIRM_MAJOR: "Planlı Değişiklik",
        ETaskSubType_FLIGHT_CHANGE_CONFIRM_MINOR: "Zorunlu Değişiklik",
        ETaskType_7: "Manuel Satış",
        ETaskType_ALL: "Tümü",
        ETaskType_PAYMENT_REVERSE: "Ödeme İptal - İade",
        ETaskType_PNR_ADD: "Manuel Pnr Yükleme",
        ETaskType_PNR_ANCILLARY: "Pnr Ek Hizmetler",
        ETaskType_PNR_CORRECTION: "Pnr Düzeltme",
        ETaskType_PNR_DIVIDE: "Pnr - Divide",
        ETaskType_PNR_FLIGHT_CHANGE_CONFIRMATION: "Oto - Değişiklik Bildirimi",
        ETaskType_PNR_OPEN: "Açık Bilet",
        ETaskType_PNR_REISSUE: "Pnr Değişiklik",
        ETaskType_PNR_RESERVE: "Pnr İptal - İade",
        ETaskType_PNR_REVERSE: "Pnr İptal - İade",
        ETaskType_PNR_UPDATE: "Pnr Güncelleme",
        ETaskType_PNR_UPDATE_PAX: "Yolcu Bilgisi Güncelleme",
        ETaskType_TNR_ADD: "Manuel TNR Yükleme",
        ETaskType_TNR_REISSUE: "Transfer Değişiklik",
        ETaskType_TNR_REVERSE: "TNR İptal İade",
        ETaskType_UNKNOWN: "Tanımsız",
        ETaskType_VOUCHER_ADD: "Manuel Voucher Yükleme",
        ETaskType_VOUCHER_NOTE: "Acente Notu",
        ETaskType_VOUCHER_RECONFIRMATION: "Rekonfirmasyon Taskı",
        ETaskType_VOUCHER_REISSUE: "Otel Değişiklik",
        ETaskType_VOUCHER_REVERSE: "Otel İptal - İade",
        ETaskType_VOUCHER_ROOMGUEST_UPDATE: "Otel Misafir Değişikliği",
        ETaskType_VOUCHER_UPDATE: "Statü Güncelleme",
        ETasLogStatus_FLIGHT: "Uçak",
        ETrfFacilityType_UNKNOWN: "48 saate kadar ücretsiz iptal edilebilir",
        ETrfFacilityType_VOIDABLE_BEFORE_24H: "24 saate kadar ücretsiz iptal edilebilir",
        ETrfFacilityType_VOIDABLE_BEFORE_36H: "36 saate kadar ücretsiz iptal edilebilir",
        ETrfLegStatus_ALL: "Tümü",
        ETrfLegStatus_CANCELED: "İptal Edildi",
        ETrfLegStatus_ERROR: "Hatalı",
        ETrfLegStatus_ISSUED: "Alındı",
        ETrfLegStatus_NEW: "Yeni",
        ETrfLegStatus_OPEN: "Açık",
        ETrfLegStatus_PENDING: "Beklemede",
        ETrfLegStatus_REFUNDED: "İade Edildi",
        ETrfLegStatus_REISSUED: "Değişiklik",
        ETrfLegStatus_RESERVED: "Rezerve",
        ETrfLegStatus_UNKNOWN: "Tanımsız",
        ETrfLegStatus_VOIDED: "Void",
        ETrfTnrStatus_ALL: "Tümü",
        ETrfTnrStatus_CANCELED: "İptal Edildi",
        ETrfTnrStatus_ERROR: "Hatalı",
        ETrfTnrStatus_ISSUED: "Alındı",
        ETrfTnrStatus_NEW: "Yeni",
        ETrfTnrStatus_OPEN: "Açık",
        ETrfTnrStatus_PENDING: "Beklemede",
        ETrfTnrStatus_REFUNDED: "İade Edildi",
        ETrfTnrStatus_REISSUED: "Değişiklik",
        ETrfTnrStatus_RESERVED: "Rezerve",
        ETrfTnrStatus_UNKNOWN: "Tanımsız",
        ETrfTnrStatus_VOIDED: "Void",
        ETrfTransferType_ALL: "Tümü",
        ETrfTransferType_GROUP: "Grup",
        ETrfTransferType_INDIVIDUAL: "Bireysel",
        ETrfTransferType_UNKNOWN: "Tanımsız",
        ETrfTravelerType_ADULT: "Yetişkin",
        ETrfTravelerType_CHILD: "Çocuk",
        ETrfTravelerType_INFANT: "Bebek",
        ETrfVehicleCategory_ALL: "Tümü",
        ETrfVehicleCategory_CAR: "Binek Araç",
        ETrfVehicleCategory_MIDIBUS: "Midibüs",
        ETrfVehicleCategory_MINIBUS: "Minibüs",
        ETrfVehicleCategory_MINIVAN: "Minivan",
        ETrfVehicleCategory_MINIVAN_VIP: "VIP Minivan",
        ETrfVehicleCategory_OTOBUS: "Otobüs",
        ETrfVehicleCategory_UNKNOWN: "Tanımsız",
        ETrfVehicleCategory_VAN: "Van",
        filterComponent_boardType_titleLabel: "Pansiyon Tipi",
        filterComponent_class_selectedClassCount_label: "Yıldız seçildi",
        filterComponent_class_titleLabel: "Yıldız",
        filterComponent_facilities_titleLabel: "İmkanlar",
        filterComponent_information_titleLabel: "Arama Daraltma",
        filterComponent_prices_titleLabel: "Fiyat Aralığı (Toplam Tutar)",
        flight_fillPaxRows: "Doldur",
        flight_fillPaxRowsCancelBtn: "İptal",
        flight_fillPaxRowsContent: "Yolcu bilgilerini otomatik doldurmak ister misiniz?",
        flight_fillPaxRowsTooltip: "Yolcuları Otomatik Doldur",
        flight_fillPaxRowsTooltipHighlighter: "Yolcu iletişimini kullan.",
        flight_fillPaxRowsTtitle: "Yolcu Bilgileri",
        flight_flightCabinType: "Kabin Tipi",
        flight_flightClass: "Sınıf",
        flight_operatingAirline: "Havayolu",
        flight_paxBirthDate: "Doğum Tarihi",
        flight_paxInfo: "Yolcu Bilgileri",
        flight_paxNameSurname: "İsim-Soyisim",
        flight_paxType: "Kişi Tipi",
        FLIGHT_PRIBAS_KEY57: "1 küçük parça el bagajı (max. 4kg ve 40x30x20cm)",
        flightBrands_lblBaggageInfoUnknown: "Bagaj Bilinmiyor",
        flightBrands_lblBrandCalculate: "Hesapla",
        flightCard_directFlight: "Aktarmasız",
        flightCard_operatedBy: "Uçuşu yapan",
        flightCard_showDetails: "Detaylar",
        flightCardComponent_oneWay: "Tek Yön",
        flightcardcomponent_operatedBy: "Uçuşu yapan",
        flightChange_lblApprove: "Değişikliği Onayla",
        flightChange_lblPnrReissue: "Değişiklik Talebi",
        flightChange_lblPnrReverse: "İptal Talebi",
        flightChangeAlert_lblMsg: "Uçuşunuzda değişiklik veya iptal mevcuttur. Lütfen kontrol ediniz.",
        flightChangeAlert_lblTitle: "Bilgi",
        flightCheckout_agencyExtraCCommission: "Acente Ekstra Komisyon Ekleniyor.",
        FlightCheckout_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "Kullanıcınızın bu işlemi yapmaya yetkisi yoktur. Lütfen acentenizi kontrol ediniz.",
        FlightCheckout_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Rezervasyon yapılamaz. Lütfen aramanızı yenileyerek işleme devam ediniz.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "İşleminiz sırasında fiyat farkı oluşmuştur.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_PRICE_DIFFERENCE_AFTER_RESERVATION: "İşleminiz sırasında fiyat farkı oluşmuştur.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "Ödeme sırasında bir hata aldınız.",
        flightCheckout_BrandPriceUnavailable: "Havayolundan bu paket için fiyat alınamadı.",
        flightCheckout_brandSelection: "Brand değiştiriliyor.",
        FlightCheckout_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "Bilinmeyen bir hata ile karşılaştık.",
        FlightCheckout_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNMAPPED_ERROR: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        flightCheckout_lblAgencyExtraCommission: "Acente Extra Komisyon",
        flightCheckout_lblBaseTotal: "Net Fiyat",
        flightCheckout_lblGrandTotal: "Genel Toplam",
        flightCheckout_SELECTED_BRANDS_NOT_VALID_NOW: "Seçtiğiniz brand için havayolu fiyat vermemektedir.  Seçiminizi değiştirerek yeniden deneyebilirsiniz.",
        flightCheckout_SSR_rezervationDisabled: "SSR eklediğiniz için hava yolu rezervasyon izni vermemektedir. Direkt satın alma işlemi gerçekleştirmeniz gerekmektedir.",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "Bir Hata Oluştu.",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Hata",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "Fiyat Farkı",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "Bir Hata Oluştu.",
        FlightCheckout_TITLE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "Hatalı",
        flightCheckoutPayment_priceDifference: "AMOUNTPLACEHOLDER Fiyat Farkını Kabul Ediyor Musunuz ?",
        flightCheckoutPayment_transectionProgress: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        flightInformationsComponent_baggageAllowance: "Bagaj Hakkı",
        flightInformationsComponent_cabinType: "Kabin Tipi",
        flightInformationsComponent_class: "Sınıf",
        flightInformationsComponent_flight: "Uçuş",
        flightInformationsComponent_flightInformation: "Uçuş Bilgileri",
        flightInformationsComponent_timeChanged: "Uçuş bilgilerinizde değişiklik mevcuttur.",
        flightInformationsComponent_travelDuration: "Uçuş Süresi",
        "flightLegHeader_show/hideResults": "Sonuçları Göster / Gizle",
        flightMatrix_showHideAll: "Tüm Havayolları",
        FlightOffer_flight: "Uçuş",
        flightOrderDetail_btnCalculatePenalty: "İade Tutarını Hesapla",
        flightOrderDetail_lblAgencyExtraCommission: "Acente Extra Komisyon",
        flightOrderDetail_lblAgencyServiceFee: "Acente Hiz. Bedeli",
        flightOrderDetail_lblAncillaryTotal: "Ek Hizmetler Toplam",
        flightOrderDetail_lblBaseTotal: "Net Fiyat",
        flightOrderDetail_lblCalculatingPenalty: "İade Tutarı Hesaplanıyor",
        flightOrderDetail_lblCalculatingPenaltyTxt: "Tutarınız hesaplanıyor. Lütfen bekleyiniz.",
        flightOrderDetail_lblCancellationWithPenalty: "Ceza ile İade",
        flightOrderDetail_lblCancellationWithPenaltyTxt: "Ceza bedelini ve iade tutarını hesaplama yaparak görebilirsiniz.",
        flightOrderDetail_lblGrandTotal: "Genel Toplam",
        flightOrderDetail_lblPackageGrandTotal: "Paket Genel Toplamı",
        flightOrderDetail_lblPaymentFeeTotal: "Kredi Kartı İşlem Ücreti",
        flightOrderDetail_lblRefundWithPenalty: "İade Tutarı",
        flightOrderDetail_lblReissueTotal: "Değişiklik Toplamı",
        flightOrderDetail_lblReverseTotal: "İade Toplamı",
        flightOrderDetail_lblReverseTotalAncillary: "Ek Hizmetler İade",
        flightOrderDetail_lblSupplierReturn: "Tedarikçi İadesi",
        flightOrderDetail_msgRESERVATED_FLIGHT_CHANGED: "Uçuşta saat değişikliği mevcuttur. Lütfen Service Center ile iletişime geçiniz.",
        flightOrderDetail_ticketIssuedNotificaiton: "Başarılı bir şekilde oluşturdunuz, detaylarınız aşağıdadır.",
        flightOrderDetail_ticketReservedNotificaiton: "Biletinizi başarılı bir şekilde rezerve ettiniz.",
        flightOrderDetail_txtFlight: "Uçuş",
        flightPassengerInformationsComponent_passengerInformations: "Yolcu Bilgileri",
        flightPayment_infoCreditCard: "Kredi karti ile yüklemelerde sabit %2 banka komisyon oranı eklenecektir.",
        flightPayment_infoSofort: "Sofort ile yüklemelerde 1-500€ arasında sabit 3€, 501€ dan itibaren sabit 5€ banka komisyonu eklenecektir.",
        FlightPayment_INSUFFICIENT_AGENCY_BALANCE: "Kullanılabilir bakiyeniz yetersizdir.  Lütfen ekibimiz ile iletişime geçiniz.",
        FlightPaymentWidget_btnReverse: "Cezasız İptal",
        FlightPaymentWidget_popup_REVERSE_FLIGHT: "Biletiniz iptal edilecek. Onaylıyor musunuz ?",
        FlightPaymentWidget_popup_title: "Bilgi",
        flightPriceInformationsComponent_priceInformations: "Fiyat Bilgileri",
        flightSaleReport_currency: "Para birimi",
        flightSaleReport_flightDate: "Uçuş Tarihi",
        flightSaleReport_paxCount: "Kişi Sayısı",
        flightSaleReport_pnrCount: "Pnr Adedi",
        flightSaleReport_refundTotal: "İade Tutarı",
        flightSaleReport_totalAmount: "Satış Tutarı",
        flightSaleReport_transactionDate: "İşlem Tarihi",
        flightSalesReport_airlineList: "Havayolu",
        flightSalesReport_creationFromDate: "Başlangıç Tarihi",
        flightSalesReport_creationToDate: "Bitiş Tarihi",
        flightSalesReport_currency: "Para birimi",
        flightSalesReport_flightDate: "Uçuş Tarihi",
        flightSalesReport_optEticket: "E-Bilet",
        flightSalesReport_optPNR: "Pnr",
        flightSalesReport_paxCount: "Kişi Sayısı",
        flightSalesReport_pnrCount: "Pnr Adedi",
        flightSalesReport_pnrStatus: "Pnr Durumu",
        flightSalesReport_providerList: "Tedarikçi Listesi",
        flightSalesReport_refundTotal: "İade Tutarı",
        flightSalesReport_reportType: "Rapor Tipi",
        flightSalesReport_showReissues: "Değişiklikleri Göster",
        flightSalesReport_title: "Uçuş Satış Raporu",
        flightSalesReport_totalAmount: "Satış Tutarı",
        flightSalesReport_transactionDate: "İşlem Tarihi",
        flightSalesReport_userList: "Kullanıcı Listesi",
        flightSearchForm_lblMultiLeg: "Çoklu Uçuş",
        flightSearchResult_lblFamilyPricesToggler: "En Düşük Paket Fiyatları Göster",
        flightSearchResult_lblOkay: "Anladım",
        flightSearchResult_tourFamilyPricesToggler: "En düşük paketli ücreti görmek için kullanabilirsiniz.",
        FlightSelect_AGENT_API_FLIGHT_CHECKOUT_EXCEPTION_CHECKOUT: "Bilinmeyen bir hata ile karşılaştık.",
        FlightSelect_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Uzun süredir işlem yapmadığınız için fiyatlar güncellenmiş olabilir. Aramanızı yenileyerek işleminize devam edebilirsiniz.",
        FlightSelect_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Lütfen 'Eşlenik' uçuşları seçiniz, ya da 'Tek Yön Fiyatı' olmayan bir seçiminiz varsa kaldırınız.",
        FlightSelect_TITLE_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Fiyat bilgisi değişmiş olabilir. Lütfen yeniden arama yaparak işleminize devam ediniz.",
        FlightSelect_TITLE_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Lütfen 'Eşlenik' uçuşları seçiniz, ya da 'Tek Yön Fiyatı' olmayan bir seçiminiz varsa kaldırınız.",
        flightsResults_noFlightWarning: "Uçuş Bulunamadı.",
        girosWidget_gross: "Brüt",
        girosWidget_net: "Net",
        girosWidget_others: "DİĞER",
        gtsDataTableComponent_checkIn: "Online Check-in",
        gtsDataTableComponent_daily: "Günlük",
        gtsDataTableComponent_emptyEntity: "empty entity",
        gtsDataTableComponent_firstLabel: "İlk",
        gtsDataTableComponent_flightChange: "Saat Değişikliği",
        gtsDataTableComponent_lastLabel: "Son",
        gtsDataTableComponent_lblWKSC: "Saat Değişikliği",
        gtsDataTableComponent_monthly: "Aylık",
        gtsDataTableComponent_nextLabel: "Sonraki",
        gtsDataTableComponent_partialPayment: "Kalan Ödeme",
        gtsDataTableComponent_performanceBarChartdaily: "Günlük Pax Adedi",
        gtsDataTableComponent_performanceBarChartmonthly: "Aylık Pax Adedi",
        gtsDataTableComponent_performanceBarChartweekly: "Haftalık Pax Adedi",
        gtsDataTableComponent_prevLabel: "Önceki",
        gtsDataTableComponent_rowsPerPageLabel: "Sayfada Göster",
        gtsDataTableComponent_totalGiro: "Toplam Ciro",
        gtsDataTableComponent_totalPax: "Toplam Pax",
        gtsDataTableComponent_totalPnr: "Toplam Pnr",
        gtsDataTableComponent_totalSearch: "Toplam Arama",
        gtsDataTableComponent_totalVoucher: "Toplam Voucher",
        gtsDataTableComponent_weekly: "Haftalık",
        gtsGrid_btnNextPage: "Sonraki",
        gtsGrid_btnPrevPage: "Önceki",
        gtsGrid_item: "öğe göster",
        gtsGrid_showAll: "Tüm öğeler",
        gtsGrid_totalItem: "öğe gösterildi",
        guestItem_txtGuest: "Misafir",
        hamburgerMenu_accTransactionList: "Hesap Hareketleri",
        hamburgerMenu_balanceUpdate: "Bakiye Yükleme",
        hamburgerMenu_customerManagement: "Müşteri İşlemleri",
        hamburgerMenu_darkMode: "Gece Modu",
        hamburgerMenu_dashBoard: "Pano",
        hamburgerMenu_financeWithSubs: "Finans",
        hamburgerMenu_flightSaleReport: "Uçuş Satış Raporu",
        hamburgerMenu_flightSalesReport: "Uçuş Satış Raporu",
        hamburgerMenu_flightSearch: "Uçuş Ara",
        hamburgerMenu_hotelSaleReport: "Otel Satış Raporu",
        hamburgerMenu_hotelSalesReport: "Otel Satış Raporu",
        hamburgerMenu_hotelSearch: "Otel Ara",
        hamburgerMenu_invoiceList: "Fatura Listesi",
        hamburgerMenu_lightMode: "Gündüz Modu",
        hamburgerMenu_logOut: "Çıkış",
        hamburgerMenu_orderSearch: "Order Arama",
        hamburgerMenu_profileSettings: "Profil Ayarları",
        hamburgerMenu_rentACarSalesReport: "Rent A Car Satış Raporu",
        hamburgerMenu_rentACarSearch: "Rent A Car Ara",
        hamburgerMenu_reportsWithSubs: "Raporlar",
        hamburgerMenu_serviceCenter: "Service Center",
        hamburgerMenu_settings: "Ayarlar",
        hamburgerMenu_transferSalesReport: "Transfer Satış Raporu",
        hamburgerMenu_transferSearch: "Transfer Ara",
        hamburgerMenu_userAuthorization: "Kullanıcı Yetkileri",
        hamburgerMenu_userSettings: "Kullanıcı Ayarları",
        "HOTEL_API.TICKET.EXCEPTION.error.hotel.not.accepts.only.man": "Bay konaklamalar bu otel tarafından kabul edilmiyor. Lütfen sayfayı kapatıp yeniden arama yapınız.",
        hotel_lblAdult: "Yetişkin",
        hotel_lblChild: "Çocuk",
        hotel_lblCustomerNationality: "Çıkış Ülkesi",
        hotel_lblDateInfo: "Konaklama Tarihi:",
        hotel_lblDestinationName: "Gidilecek Yer:",
        hotel_lblInfant: "Bebek",
        hotel_lblManuelRequest: "Aradığınız lokasyonu/oteli şu anda bulamadık. Benim İçin Bul butonuna basarak bu bize talebinizi iletebilirsiniz.",
        hotel_lblRoom: "Oda",
        hotel_lblRoomInfos: "Oda Bilgileri:",
        hotel_manuelFormTitle: "Size nasıl ulaşabiliriz?",
        hotel_manuelRequestCancel: "İptal",
        hotel_manuelRequestConfirm: "Benim İçin Bul",
        hotel_manuelRequestModalTitle: "Aradığınızı bulamadınız mı? Sizin için bulalım.",
        HOTEL_API_TICKET_HOTEL_EXCEPTION_GTS_BOOKING: "İşleminiz yürütülürken oda kotası tükenmiştir. Lütfen sayfayı kapatıp yeniden arama yapınız ya da Service Center ekibimizle iletişime geçiniz.",
        hotelAutocomplete_lblCity: "Şehir",
        hotelAutocomplete_lblDisctrict: "Bölge",
        hotelAutocomplete_lblHotel: "Otel",
        hotelAutocomplete_lblLastSearches: "Son Aramalarınız",
        hotelAutocomplete_phDestination: "Otel, Bölge veya Şehir Adı",
        hotelBasket_btnClear: "Temizle",
        hotelBasket_lblAddOnPrice: "Add-On Fiyatı",
        hotelBasket_lblGrandTotal: "Genel Toplam",
        hotelBasket_lblRoomPrice: "Oda Fiyatı",
        hotelBasket_lblTitle: "Sepet",
        hotelBasketComponent_roomPriceLabel: "Oda Fiyatı",
        hotelCacheCheckout_lblCancellationPolicy: "İptal Politikaları",
        hotelCacheCheckout_lblPassengerInformations: "Misafir Bilgileri",
        HotelCacheCheckoutBook_WarningEmptyAreaContent: "Lütfen bilgileri kontrol edip tekrar deneyiniz.",
        HotelCacheCheckoutBook_WarningEmptyAreaHead: "Hata",
        hotelCacheOrderDetail_lblInfoDontForgetSave: "Bilgileri kaydetmeyi unutmayınız.",
        hotelCacheOrderDetail_lblPaymentInformations: "Ödeme Bilgileri",
        hotelCacheOrderDetail_lblSummary: "Konaklama Bilgileri",
        hotelCacheSearch_addOn: "Add-On",
        hotelCacheSearch_lblHotelNotSelectedWarning: "Herhangi bir otel seçmediniz...",
        hotelCacheSearch_lblMultiHotelSelect: "Çoklu Otel Seçimi",
        hotelCacheSearch_lblSearchInfo: "Bu sayfada wholesale otel arama kuralları ekranında satışa açık olan tedarikçilerin otelleri listelenmektedir.",
        hotelCacheSearch_lblSelectedHotels: "Seçilen Oteller",
        HotelCacheSearch_MultiSelectWarningPopup: "Seçtiğiniz bir ya da birden fazla otel Cache Listesinde bulunamamıştır.",
        HotelCacheSearch_MultiSelectWarningPopupInfo: "Çoklu otel eklemek için seçiniz...",
        HotelCacheSearch_WarningMultiSelectInfo: "Çoklu seçimden tekli seçime döndüğünüz için otel arama listeniz sıfırlanmıştır.",
        HotelCacheSearch_WarningMultiSelectPopup: "Bilgi",
        HotelCacheSearch_WarningPopup: "Bilgi",
        HotelCacheSearch_WarningPopupEmpty: "Hata",
        HotelCacheSearch_WarningPopupEmptyInfo: "Lütfen arama kutucuğunu doldurarak gelen listeden bir seçim yapınız.",
        HotelCacheSearch_WarningPopupInfo: "Lütfen arama yaptığınız otelin Cache Listesinde olduğundan emin olunuz!",
        HotelCacheSearch_WarningPopupToCheckout: "Hata",
        HotelCacheSearch_WarningPopupTopInfo: "Arama kriterlerini kontrol edip, tekrar deneyiniz.",
        hotelCard_btnFacilityShowLess: "Tüm Özellikleri Gizle",
        hotelCard_btnFacilityShowMore: "Tüm Özellikleri Göster",
        hotelCard_btnReadLess: "Daralt",
        hotelCard_btnReadMore: "Devamını Oku",
        hotelCard_btnSearchRoom: "Oda Seç",
        hotelCard_lblBoutiqueHotel: "Butik Otel",
        hotelCard_lblCalendarBetween: "Seçilen tarihler arasında müsaitlik bulunmamaktadır.",
        hotelCard_lblCalendarInfo: "Lütfen giriş ve çıkış tarihinizi seçiniz...",
        hotelCard_lblCalendarMinStay: "Seçmiş olduğunuz tarihlerde minimum #VALUE# gece konaklama şartı vardır.",
        hotelCard_lblCalendarRelease: "Seçilen tarihlerde girişe #VALUE# gün ve daha az gün kala rezervasyon yapılamamaktadır.",
        hotelCard_lblCalendarSelectNights: "Tarih seçiniz...",
        hotelCard_lblCalendarStopType: "Lütfen tarih aralığını değiştiriniz.",
        hotelCard_lblCalendarSuccess: "Tarih seçimi başarılı...",
        hotelCard_lblChangeDate: "Tarih Değiştir",
        hotelCard_lblDescription: "Otel Açıklaması",
        hotelCard_lblDiscounted: "İndirimli",
        hotelCard_lblhasAddon: "Add-On",
        hotelCard_lblhasAddonTitle: "Add-On",
        hotelCard_lblhasMandatoryAddonTitle: "Zorunlu Add-On",
        hotelCard_lblKontenjan: "Seçmiş olduğunuz tarihlerde müsaitlik bulunmamaktadır.",
        hotelCard_lblLocationTitle: "Lokasyon & Adres Bilgileri",
        hotelCard_lblMinimum: "Seçmiş olduğunuz tarihlerde minimum #VALUE# gece konaklama şartı vardır.",
        hotelCard_lblMinimumStay: "Minimum #VALUE# gece.",
        hotelCard_lblNights: "Gece",
        hotelCard_lblPartialPayment: "Parçalı Ödeme",
        hotelCard_lblQuota: "Seçmiş olduğunuz tarihlerde müsaitlik bulunmamaktadır.",
        hotelCard_lblRecommended: "Önerilen",
        hotelCard_lblRelease: "Seçilen tarihlerde girişe #VALUE# gün ve daha az gün kala rezervasyon yapılamamaktadır.",
        hotelCard_lblSearchResultInspectFirst: "Arama sonucunda #VALUE1# saniye sürede",
        hotelCard_lblSearchResultInspectSecond: "Sağlayıcılarından #VALUE1# adet otel listelenmiştir.",
        hotelCard_lblSpecialCategory: "Özel Kategori",
        hotelCard_lblStop: "Seçmiş olduğunuz tarihlerde müsaitlik bulunmamaktadır.",
        hotelCard_noMatchingBoardType: "Bu oda misafirleri için bu pansiyon tipinde fiyat bulunamamıştır, lütfen sepetteki pansiyon tipinizi değiştiriniz.",
        hotelCard_room: "Oda",
        hotelCard_tabInfo: "Bilgi",
        hotelCard_tabPhotoGallery: "Galeri",
        hotelCard_tabRooms: "Oda Seçimi",
        hotelcheckout_addOnCardfree: "#AGE_RANGE# yaş aralığı ücretsizdir",
        hotelcheckout_addOnCardWarning: "#VALUE# yaşındaki çocukların katılmasına izin verilmemektedir.",
        hotelCheckout_addOnInsert: "Add- On Ekle",
        hotelCheckout_addOnMandatory: "Zorunlu Add-On",
        hotelCheckout_addOnNonMandatory: "Zorunlu Olmayan Add-On",
        hotelCheckout_addOnReadLess: "Daralt",
        hotelCheckout_addOnReadMore: "Devamını Oku",
        hotelCheckout_addOnRemove: "Add- On Kaldır",
        hotelCheckout_agencyExtraCCommission: "Acente Ekstra Komisyon Ekleniyor.",
        hotelCheckout_cmbAgencyCommission: "Acente Komisyon",
        hotelCheckout_lblAccomodationInfo: "Konaklama Tutarı",
        hotelCheckout_lblAddOnTotalPrice: "Add-On Fiyatı",
        hotelCheckout_lblAgencyCommission: "Acente Komisyon",
        hotelCheckout_lblAgencyExtraCommission: "Acente Ekstra Komisyon",
        hotelCheckout_lblBoardType: "Pansiyon Tipi",
        hotelCheckout_lblCheckin: "Giriş Tarihi",
        hotelCheckout_lblCheckout: "Çıkış Tarihi",
        hotelCheckout_lblCityTax: "#VALUE1#€ City Tax may be charged at the hotel",
        hotelCheckout_lblContactInfo: "İletişim Bilgileri",
        hotelCheckout_lblEmail: "E-mail",
        hotelCheckout_lblGrandTotal: "Genel Toplam",
        hotelCheckout_lblHotelAgentTerms: "Acente Kullanım Koşulları",
        hotelCheckout_lblHotelAnd: "ve",
        hotelCheckout_lblHotelDescriptions: "Otele giriş saati 14:00 çıkış saati 12:00dir. Geç giriş yapacak misafirler için tarafımıza bilgi verilmesi gerekmektedir. Aynı gün ve hafta sonu girişli rezervasyonlar için lütfen destek ekibimizden müsaitlik alınız ve re-konfirmasyon isteyiniz.",
        hotelCheckout_lblHotelDescriptionsAlert: "01.01.2023 tarihi itibari ile yürürlüğe giren 7194 sayılı Kanunun 52 nci maddesinin birinci fıkrasının (b) bendi hükmüne göre; 01.01.2023 itibarıyla gerçekleşen konaklamalarda; Türkiyedeki tüm konaklama tesislerine, misafirlerden konaklama vergisi alınması tüm misafirlerden konaklama vergisi tahsil edilmesi konusunda tebliğ gitmiştir. Konaklama vergisini tahsil etmekle alakalı otellere farklı alternatif yöntemler aynı anda sunulduğu için bazı otellerde misafirlerinizden konaklama vergisi giriş esnasında tahsil edilebilir. Kanun ve uygulaması yeni olduğu için misafir memnuniyetsizliği yaşanmaması adına misafirlerinizi bilgilendirmenizi önemle hatırlatırız. Not: Konu ile alakalı GTSnin konaklama tesisleri üzerinde herhangi bir yönlendirme ve yaptırımı dolayısı ile sorumluluğu yoktur.",
        hotelCheckout_lblHotelDescriptionsRateHawkMetaPolicy: "Ücretler ve Diğer Bilgiler",
        hotelCheckout_lblHotelDescriptionsTitle: "Otel Açıklamaları",
        hotelCheckout_lblHotelProviderInstructionsDescriptions: "Lütfen rezervasyonu onaylamadan önce satın aldığınız tedarikçi açıklamalarını kontrol ediniz.",
        hotelCheckout_lblHotelProviderInstructionsTitle: "Tedarikçi Açıklamaları",
        hotelCheckout_lblHotelSupplierTerms: "Tedarikçi Kullanım Koşulları",
        hotelCheckout_lblHotelTerms: "Sözleşmeleri okudum, onaylıyorum.",
        hotelCheckout_lblLastBookingDate: "Son Satın Alma Tarihi",
        hotelCheckout_lblLastBookingDateDescription: "Yukarıda belirtilen tarihe kadar satın alma işlemi gerçekleştirilmeyen rezervasyonlar otomatik olarak iptal olacaktır.",
        hotelCheckout_lblPartialPaymentAvailable: "Parçalı Ödeme Koşulları",
        hotelCheckout_lblPartialPaymentCancelInfo: "Yukarıda belirtilen tarihlere kadar ödemesi tamamlanmayan rezervasyonlar otomatik olarak iptal edilecektir.",
        hotelCheckout_lblPartialPaymentCancellationTerms: "İptal Koşulları",
        hotelCheckout_lblPartialPaymentCancellationTermsInfo: "Rezervasyon iptali durumunda aşağıdaki cezai şartlar uygulanır.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText: "#VALUE# tarihine kadar, iptal için #VALUE1# herhangi bir #VALUE2#ceza ücreti alınmayacaktır.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText1: "#VALUE# tarihine kadar, iptal için herhangi bir #VALUE1#ceza ücreti#VALUE2# alınmayacaktır.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText2: "#VALUE1# tarihine kadar iptal durumunda #VALUE2# ceza ücreti uygulanacaktır.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText2Revize: "Bu rezervasyonun iptali durumunda depozito ve kalan ödemenin iadesi bulunmamaktadır.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText3: "#VALUE1# tarihinden sonra iptal durumunda #VALUE2# ceza ücreti uygulanacaktır.",
        hotelCheckout_lblPartialPaymentDeposit: "Teminat olarak",
        hotelCheckout_lblPartialPaymentFirstDeposit: "Seçilen konaklama için #VALUE1# depozito tutarı #VALUE2#ye kadar ödenmelidir.",
        hotelCheckout_lblPartialPaymentFirstPayment: "Depozito Ödeme",
        hotelCheckout_lblPartialPaymentInfo: "Parçalı Ödeme Bilgisi",
        hotelCheckout_lblPartialPaymentPaymentInfo: "Bu işlem için Parçalı Ödeme yöntemini kullanabilirsiniz. Parçalı ödemede ilk önce depozito tutarını ödersiniz.",
        hotelCheckout_lblPartialPaymentReady: "Bu işlem parçalı ödeme yapmaya uygundur",
        hotelCheckout_lblPartialPaymentRefundDisabled: "Depozito ödemesinin iadesi yoktur.",
        hotelCheckout_lblPartialPaymentRemainingPayment: "Toplam ödemenin geri kalanı",
        hotelCheckout_lblPartialPaymentRemainingPaymentInfo: "Seçilen konaklama için kalan tutar olan #VALUE1#, #VALUE2# tarihine kadar ödenmelidir.",
        hotelCheckout_lblPartialPaymentSecondPayment: "Kalan Ödeme",
        hotelCheckout_lblPartialPaymentUsePartialPayment: "Parçalı Ödeme Aktif",
        hotelCheckout_lblPartialPaymentUsePartialPaymentAvailableInfo: "Bu işlem için parçalı ödeme yöntemini kullanabilirsiniz. Parçalı ödeme yapmak için onayınıza ihtiyacımız vardır.",
        hotelCheckout_lblPartialPaymentUsePartialPaymentDeactive: "Parçalı ödeme kullanın ve depozito tutarını ödeyin",
        hotelCheckout_lblproviderTotal: "Ara Toplam",
        hotelCheckout_lblRoomInfo: "Oda Bilgileri",
        hotelCheckout_lblRoomName: "Oda Tipi",
        hotelCheckout_lblRoomTotal: "Toplam",
        hotelCheckout_lblTax: "Vergiler",
        hotelCheckout_lblTaxUS: "Satış Vergisi",
        hotelCheckout_lblTotalPrice: "Toplam Ücret",
        hotelCheckout_lblTotalTax: "Vergiler",
        hotelCheckout_PartialCancellationContentText: "Bu rezervasyonun iptali durumunda depozito ve kalan ödemenin iadesi bulunmamaktadır.",
        hotelCheckoutBasket_partialPaymentControl: "#VALUE# euro depozito ödeyerek parçalı ödeme yapmak istiyorum.",
        hotelCheckoutBasket_txtBoardType: "Pansiyon Tipi",
        hotelCheckoutBasket_txtNight: "Gece",
        hotelCheckoutBasket_txtRoom: "#VALUE#.Oda",
        hotelCheckoutPage_contactInfo_title: "İletişim Bilgileri",
        hotelCheckoutPage_descriptions_alert_text: "Aynı gün ve hafta sonu girişli rezervasyonlar için lütfen destek ekibimizden müsaitlik alınız ve re-konfirmasyon isteyiniz.",
        hotelCheckoutPage_descriptions_hotelNote_text: "Otele giriş saati 14:00 çıkış saati 12:00dir. Geç giriş yapacak misafirler için tarafımıza bilgi verilmesi gerekmektedir. Aynı gün ve hafta sonu girişli rezervasyonlar için lütfen destek ekibimizden müsaitlik alınız ve re-konfirmasyon isteyiniz.",
        hotelCheckoutPage_descriptions_title: "Otel Açıklamaları",
        hotelCheckoutPage_roomInfo_agencyCommission_label: "Acente Komisyon",
        hotelCheckoutPage_roomInfo_agencyExtraCommission_label: "Acente Ekstra Komisyon",
        hotelCheckoutPage_roomInfo_title: "Oda Bilgileri",
        hotelCheckoutPage_roomInfoCard_lastBookingDate_alertDescriptionText: "Yukarıda belirtilen tarihe kadar satın alma işlemi gerçekleştirilmeyen rezervasyonlar otomatik olarak iptal olacaktır.",
        hotelCheckoutPage_roomInfoCard_lastBookingDate_alertText: "Son Satın Alma Tarihi",
        hotelCheckoutPage_roomInformation_roomBoardLabel: "Pansiyon Tipi",
        hotelCheckoutPage_roomInformation_roomNameLabel: "Oda Tipi",
        hotelCheckoutPage_roomRestrictionInfo: "Belirtilen oda fiyatı {adultCount}{adultFmt}{adultPluralFmt}{and}{childCount}{childFmt}{childPluralFmt} konaklaması için hesaplanmıştır.",
        hotelCheckoutPageRH_children: "Çocuk Ücreti",
        hotelCheckoutPageRH_childrenMeal: "Çocuk Yemeği",
        hotelCheckoutPageRH_cot: "Bebek Yatağı",
        hotelCheckoutPageRH_deposit: "Depozito",
        hotelCheckoutPageRH_extraBed: "Ekstra Yatak",
        hotelCheckoutPageRH_internet: "Internet",
        hotelCheckoutPageRH_meal: "Yemek",
        hotelCheckoutPageRH_noShow: "No Show",
        hotelCheckoutPageRH_parking: "Otopark",
        hotelCheckoutPageRH_pets: "Evcil Hayvan",
        hotelCheckoutPageRH_shuttle: "Shuttle",
        hotelCheckoutPageRH_visa: "Visa",
        hotelFacilitiesModal_lblTitle: "İmkanlar",
        hotelFilter_btnApplyAndClose: "Uygula ve Kapat",
        hotelFilter_btnClearAll: "Temizle",
        hotelFilter_btnSelectAll: "Tümünü Seç",
        hotelFilter_btnShowAll: "Tümünü Göster",
        hotelFilter_btnShowLess: "Daha Az Göster",
        hotelFilter_btnShowMore: "Daha Fazla Göster",
        hotelFilter_lblAnd: "ve",
        hotelFilter_lblBoardType: "Pansiyon Tipi",
        hotelFilter_lblBoutiqueHotel: "Butik Otel",
        hotelFilter_lblClassTitle: "Yıldız",
        hotelFilter_lblDiscountedHotel: "İndirimli",
        hotelFilter_lblFacilityModalCancel: "İptal",
        hotelFilter_lblFacilityModalClose: "KAPAT",
        hotelFilter_lblFacilityModalSave: "Kaydet",
        hotelFilter_lblFacilityTitle: "İmkanlar",
        hotelFilter_lblFilterByDefinitions: "Tanımlara göre filtrele",
        hotelFilter_lblFilterByStars: "Yıldıza göre filtrele",
        hotelFilter_lblFilterPartialPayment: "Parçalı Ödeme",
        hotelFilter_lblFilterText: "Oteller #VALUE1# #VALUE2# #VALUE3# #VALUE4# #VALUE5# #VALUE6# olarak filtrelendi.",
        hotelFilter_lblhassAddOn: "Add-On",
        hotelFilter_lblInfoTitle: "Arama Daraltma",
        hotelFilter_lblPartialPayment: "Parçalı Ödeme",
        hotelFilter_lblPriceTitle: "Fiyat Aralığı (Toplam Tutar)",
        hotelFilter_lblRecommendedHotel: "Önerilen",
        hotelFilter_lblSearchHotel: "Otel Ara",
        hotelFilter_lblSelectedClassCount: "Yıldız seçildi",
        hotelFilter_lblSpecialCategory: "Özel Kategori",
        hotelFilter_lblStars: "yıldız",
        hotelOrderDetail_gtsDirect: "Operatör GTS",
        hotelOrderDetail_lblAccomodationInfo: "Konaklama Tutarı",
        hotelOrderDetail_lblAgencyCommission: "Acente Komisyon",
        hotelOrderDetail_lblBaseTotal: "Net Fiyat",
        hotelOrderDetail_lblGrandTotal: "Genel Toplam",
        hotelOrderDetail_lblPackageGrandTotal: "Paket Genel Toplamı",
        hotelOrderDetail_lblproviderTotal: "Ara Toplam",
        hotelOrderDetail_lblReverseTotal: "İade Tutarı",
        hotelOrderDetail_lblSalesTotal: "Satış Toplamı",
        hotelOrderDetail_lblTax: "Vergiler",
        hotelOrderDetail_lblTaxUS: "Satış Vergisi",
        hotelOrderDetail_lblTotalPrice: "Toplam Ücret",
        hotelRoomCard_lblDetailHighlightedFeatures: "Öne Çıkarılan Oda Özellikleri",
        hotelRoomCard_lblDetailRoomFeatures: "Oda Özellikleri",
        hotelRoomCard_lblDetailRoomInformations: "Oda Bilgileri",
        hotelRoomCard_lblDetailRoomOptions: "Oda Seçenekleri",
        hotelRoomCard_lblDetailSeeDetails: "Oda detayını göster...",
        hotelRoomCard_lblKontenjan: "Müsaitlik bulunmamaktadır.",
        hotelRoomCard_lblLoadMore: "Daha Fazla Göster",
        "hotelRoomCard_lblMinimum Konaklama": "Minimum #VALUE# gece.",
        "hotelRoomCard_lblMinimum..": "minimum #VALUE# gece",
        hotelRoomCard_lblMinimumKonaklama: "Minimum #VALUE# gece.",
        hotelRoomCard_lblMinimumStay: "minimum #VALUE# gece",
        hotelRoomCard_lblQuota: "Müsaitlik bulunmamaktadır.",
        hotelRoomCard_lblRelease: "Müsaitlik bulunmamaktadır.",
        hotelRoomCard_lblRoomCountTxt: "Oda",
        hotelRoomCard_lblStop: "Müsaitlik bulunmamaktadır.",
        hotelRoomDetail_lblAddOnInfoDisabled: "#AGE_RANGE# yaş aralığı katılamaz",
        hotelRoomDetail_lblAddOnInfoFree: "#AGE_RANGE# yaş aralığı ücretsizdir",
        hotelSalesReport_agencyCommission: "Acente Komisyon",
        hotelSalesReport_filter_btnList: "Listele",
        hotelSalesReport_filter_lblFromDate: "Başlangıç Tarihi",
        hotelSalesReport_filter_lblReportType: "Rapor Tipi",
        hotelSalesReport_filter_lblStatus: "Durum",
        hotelSalesReport_filter_lblToDate: "Bitiş Tarihi",
        hotelSalesReport_fromDate: "Başlangıç Tarihi",
        hotelSalesReport_grandTotal: "Toplam",
        hotelSalesReport_guestCount: "Misafir Adedi",
        hotelSalesReport_reportType: "Rapor Tipi",
        hotelSalesReport_roomCount: "Oda Adedi",
        hotelSalesReport_saleAmount: "Satış Tutarı",
        hotelSalesReport_tblMain_tdChannelGts: "Operatör GTS",
        hotelSalesReport_tblMain_tdChannelProvider: "Tedarikçi",
        hotelSalesReport_tblMain_thAgencyCommission: "Acente Komisyon",
        hotelSalesReport_tblMain_thAgencyName: "Acente",
        hotelSalesReport_tblMain_thCheckinDate: "Giriş Tarihi",
        hotelSalesReport_tblMain_thCheckoutDate: "Çıkış Tarihi",
        hotelSalesReport_tblMain_thContactName: "Ad",
        hotelSalesReport_tblMain_thContactSurname: "Soyad",
        hotelSalesReport_tblMain_thGuestCount: "Misafir",
        hotelSalesReport_tblMain_thHotelLocation: "Lokasyon",
        hotelSalesReport_tblMain_thHotelName: "Otel İsmi",
        hotelSalesReport_tblMain_thOnUsChannel: "Kanal",
        hotelSalesReport_tblMain_thOptionDaysLeft: "Kalan Opsiyon Günü",
        hotelSalesReport_tblMain_thOrderId: "Order ID",
        hotelSalesReport_tblMain_thRoomCount: "Oda",
        hotelSalesReport_tblMain_thSaleDateTime: "Satış Tarihi",
        hotelSalesReport_tblMain_thTotal: "Toplam",
        hotelSalesReport_tblMain_thVocuherStatus: "Durum",
        hotelSalesReport_tblMain_thVoucher: "Voucher",
        hotelSalesReport_tblMain_thVoucherStatus: "Durum",
        hotelSalesReport_title: "Otel Satış Raporu",
        hotelSalesReport_toDate: "Bitiş Tarihi",
        hotelSalesReport_totalAgencyCommission: "Acente Kom. Toplamı",
        hotelSalesReport_totalGuestCount: "Misafir Toplamı",
        hotelSalesReport_totalRoomCount: "Oda Toplamı",
        hotelSalesReport_totalVoucherCount: "Voucher Toplamı",
        hotelSalesReport_voucherCount: "Voucher Adedi",
        HotelSalesSummaryReportList_thAgencyId: "Acente ID",
        hotelSearch_btnSearch: "OTEL ARA",
        hotelSearch_btnShowFilter: "Filtreler",
        hotelSearch_lblDate: "Giriş - Çıkış Tarihi",
        hotelSearch_lblDestination: "Otel Ara",
        hotelSearch_lblEmptyFilterResult: "Lütfen filtrelerinizi kontrol ediniz, seçim kriterlerinize göre sonuç bulunamamıştır.",
        hotelSearch_lblEmptyHotelList: "Aradığınız kriterlere uygun otel bulunamamıştır. Lütfen arama kriterlerinizi değiştirerek tekrar deneyiniz.",
        hotelSearch_lblFilterClearAll: "Tüm filtreleri temizle !",
        hotelSearch_lblFilterStatus: "Filtreler :",
        hotelSearch_lblFilterStatusOff: ".",
        hotelSearch_lblFilterStatusOn: ".",
        hotelSearch_lblGuest: "Oda ve Kişi Sayısı",
        hotelSearch_lblNationality: "Çıkış Ülkesi",
        hotelSearch_lblNoFoundedHotels: "Bu arama kriterlerine göre otel bulunamadı.",
        hotelSearch_lblNonRecommendedHotels: "Toplam #VALUE1# otel listelendi.",
        hotelSearch_lblOnlyGtsDirect: "GTS Kontratlı Otelleri Filtrele!",
        hotelSearch_lblTotalHotelNumber: "#VALUE1# önerilen olmak üzere toplam #VALUE2# otel listelendi.",
        hotelSearchForm_lblArea: "Kod",
        hotelSearchForm_lblCode: "Ülke",
        hotelSearchForm_lblMail: "E-mail",
        hotelSearchGuest_btnRemoveRoom: "Odayı Kaldır",
        hotelSearchGuest_lblAdult: "Yetişkin",
        hotelSearchGuest_lblChild: "Çocuk",
        hotelSearchGuest_lblChildAge: "Çocuk Yaşı",
        hotelSearchGuest_lblRoomCount: "Oda",
        hotelSearchGuest_phAdultCount: "Yetişkin",
        hotelSearchGuest_phChildCouunt: "Çocuk",
        hotelSearchGuest_phRoomCount: "Oda",
        hotelSearchInformation_adult: "Yetişkin",
        hotelSearchInformation_child: ", #COUNT# Çocuk",
        hotelSearchInformation_data: "Odalar",
        hotelSearchInformation_date1: "Giriş Tarihi",
        hotelSearchInformation_date2: "Çıkış Tarihi",
        hotelSearchInformation_footer: "#DAY# gün #NIGHT# gece",
        hotelSearchInformation_location: "Lokasyon",
        hotelSearchInformation_rooms: "Odalar",
        hotelSearchInformation_text: "En iyi fiyatlar aranıyor, lütfen bekleyiniz!",
        hotelSearchResultPage_hotelCard_informationTab_hotelDescription_title: "Otel Açıklaması",
        hotelSearchResultPage_hotelCard_informationTab_hotelMap_title: "Lokasyon & Adres Bilgileri",
        invoiceList_filter_btnList: "Listele",
        invoiceList_filter_lblFromDate: "Başlangıç Tarihi",
        invoiceList_filter_lblOrderId: "Order ID",
        invoiceList_filter_lblPnr: "Pnr",
        invoiceList_filter_lblToDate: "Bitiş Tarihi",
        invoiceList_filter_phOrderId: "Order ID",
        invoiceList_filter_phPnr: "Pnr",
        invoiceList_info_FCADOM: "Uçak Yurtiçi / Fatura / Cari Hesap",
        invoiceList_info_FCAINT: "Uçak Yurtdışı / Fatura / Cari Hesap",
        invoiceList_info_FCCDOM: "Uçak Yurtiçi / Fatura / Kredi Kartı",
        invoiceList_info_FCCINT: "Uçak Yurtdışı / Fatura / Kredi Kartı",
        invoiceList_info_FCODOM: "Uçak Yurtiçi /Fatura / Kredi Kartı Komisyon",
        invoiceList_info_FCOINT: "Uçak Yurtdışı / Fatura / Kredi Kartı Komisyon",
        invoiceList_info_HCADOM: "Otel Yurtiçi / Fatura / Cari Hesap",
        invoiceList_info_HCAINT: "Otel Yurtdışı / Fatura / Cari Hesap",
        invoiceList_info_HCCDOM: "Otel Yurtiçi / Fatura / Kredi Kartı",
        invoiceList_info_HCCINT: "Otel Yurtdışı / Fatura / Kredi Kartı",
        invoiceList_info_HCODOM: "Otel Yurtiçi /Fatura / Kredi Kartı Komisyon",
        invoiceList_info_HCOINT: "Otel Yurtdışı / Fatura / Kredi Kartı Komisyon",
        invoiceList_info_RCAINT: "Rent a Car Yurtdışı / Fatura / Cari Hesap",
        invoiceList_info_RCCINT: "Rent a Car  Yurtdışı / Fatura / Kredi Kartı",
        invoiceList_info_RCOINT: "Rent a Car Yurtdışı / Fatura / Kredi Kartı Komisyon",
        invoiceList_info_TCAINT: "Transfer Yurtdışı / Fatura / Cari Hesap",
        invoiceList_info_TCCINT: "Transfer Yurtdışı / Fatura / Kredi Kartı",
        invoiceList_info_TCOINT: "Transfer Yurtdışı / Fatura / Kredi Kartı Komisyon",
        invoiceList_tblMain_thGrandTotal: "Toplam Tutar",
        invoiceList_tblMain_thInvoiceDate: "Fatura Tarihi",
        invoiceList_tblMain_thInvoiceId: "ID",
        invoiceList_tblMain_thInvoiceInfo: "Açıklama",
        invoiceList_tblMain_thInvoiceNote: "Not",
        invoiceList_tblMain_thInvoiceNumber: "Fatura Numarası",
        invoiceList_tblMain_thInvoiceTotal: "Toplam Tutar",
        invoiceList_title: "Fatura Listesi",
        invoiceListPage_invoiceListDataTable_invoiceDateLabel: "Fatura Tarihi",
        invoiceListPage_invoiceListDataTable_invoiceNumberLabel: "Fatura Numarası",
        invoiceListPage_pageTitle: "Fatura Listesi",
        invoiceListPage_searchForm_endDateLabel: "Bitiş Tarihi",
        invoiceListPage_searchForm_orderIdLabel: "Order ID",
        invoiceListPage_searchForm_pnrLabel: "Pnr",
        invoiceListPage_searchForm_startDateLabel: "Başlangıç Tarihi",
        languageFullName_de: "Almanca",
        languageFullName_en: "İngilizce",
        languageFullName_ru: "Rusça",
        languageFullName_tr: "Türkçe",
        legInformation_lblEdit: "Bilgileri Düzenle",
        legInformation_titleLegPriceInfos: "Fiyat Bilgileri",
        legInformation_titleLegs: "Rota Bilgileri",
        loginPage_resetPasswordSuccessPopupText: "Bu işlem sonucunda size bir sıfırlama e-postası göndereceğiz  Onaylıyor musunuz ?",
        MasterAgency_returnTobeGsa: "Toplam Ücret'e Eklenir.",
        multiSelectComponent_itemSelected: "öğe seçildi",
        navbarComponent_switchToDarkMode: "Gece Modu",
        navbarComponent_switchToLightMode: "Gündüz Modu",
        notesComp_notTime: "Not Alma Zamanı",
        notesComponent_infoAlert_text: "Bu alana girilen notlar sadece acenteniz tarafından görüntülenir. Bir talep göndermek için lütfen Service Center Talep Ekranını kullanın.",
        NotificationComponent_btnAllowNotification: "Bildirimleri Aç",
        NotificationComponent_deleteAllNotificationConfirm: "Tüm bildirimler silinecek. Onaylıyor musunuz?",
        NotificationComponent_deleteNotificationConfirm: "Bildirim silinecek. Onaylıyor musunuz?",
        NotificationComponent_emptyOlder: "Daha eski bildiriminiz yok.",
        NotificationComponent_emptyToday: "Bugüne ait bildiriminiz yok.",
        NotificationComponent_emptyWeek: "Bu haftaya ait bildiriminiz yok.",
        NotificationComponent_emptyYesterday: "Düne ait bildiriminiz yok.",
        NotificationComponent_markAsRead: "Tümünü Okundu",
        NotificationComponent_notificationAllowed: "Bildirimler Açık",
        NotificationComponent_notificationNotAllowed: "Bildirimler Kapalı",
        NotificationComponent_notificationPage: "Tümünü Gör",
        NotificationComponent_notificationSettings: "Bildirim Ayarları",
        NotificationComponent_notificationsHeader: "Bildirimler",
        NotificationComponent_notificationStatus: "Bildirim Durumu",
        NotificationComponent_older: "Geçmiş",
        NotificationComponent_readAllConfirm: "Tüm bildirimler okundu olarak işaretlenecek. Onaylıyor musunuz?",
        NotificationComponent_removeAll: "Tümünü Sil",
        NotificationComponent_today: "Bugün",
        NotificationComponent_week: "Hafta",
        NotificationComponent_yesterday: "Dün",
        onBehalfOfComponent_agency: "Acente",
        onBehalfOfComponent_gsa: "Gsa",
        onBehalfOfComponent_lblAgency: "Acente",
        onBehalfOfComponent_lblGsa: "Gsa",
        onBehalfOfComponent_phAgency: "Ara",
        onBehalfOfComponent_phSelectAgency: "Lütfen acente seçiniz.",
        onBehalfOfComponent_placeholder: "Acente Ara",
        orderDetail_addOnReadLess: "Daralt",
        orderDetail_addOnReadMore: "Devamını Oku",
        orderDetail_btnBookingConfirmation: "Booking Confirmation",
        orderDetail_btnEticket: "E-Bilet",
        OrderDetail_btnGetReverseInfo: "İade Tutarını Hesapla",
        orderDetail_btnReReserve: "Yeniden Opsiyonla",
        orderDetail_btnReReserve_lblDefault: "Yeniden Opsiyonla",
        orderDetail_btnReReserve_lblRezerve: "Güncel Fiyat Sorgula",
        orderDetail_btnReserved: "Rezerve",
        orderDetail_btnRnr: "RNR",
        orderDetail_btnRnrProvider: "Tedarikçi RNR",
        orderDetail_btnRnrProviderBookingDoc: "Aracı Mukavelesi",
        orderDetail_btnTnr: "TNR",
        orderDetail_btnVoucher: "Voucher",
        orderDetail_exOrderId: "Eski Order ID",
        orderDetail_gtsDirect: "Operatör GTS",
        orderDetail_lblAccommodationDate: "Konaklama Tarihi",
        orderDetail_lblAccommodationInfoTitle: "Konaklama Bilgileri",
        orderDetail_lblAddon: "Add-On",
        orderDetail_lblAddonInfoTitle: "Add-On Bilgileri",
        orderDetail_lblAddonPrice: "Add-On Fiyatı",
        orderDetail_lblAdress: "Adres",
        orderDetail_lblAdultCount: "Yetişkin Sayısı",
        orderDetail_lblAgencyCommission: "Acente Komisyon",
        orderDetail_lblAgencyId: "Acente ID",
        orderDetail_lblAgencyName: "Acente Adı",
        orderDetail_lblAgencyPrice: "Acente Net Tutar",
        orderDetail_lblarrivalFlightDateTime: "İniş Saati",
        orderDetail_lblArrivalFlightNumber: "İniş Uçuş No",
        orderDetail_lblBaseAmount: "Net Fiyat",
        orderDetail_lblBirthdate: "Doğum Tarihi",
        orderDetail_lblBoardType: "Konaklama Tipi",
        orderDetail_lblBookingDate: "Satın Alındığı Zaman",
        orderDetail_lblCheckinDate: "Giriş Tarihi",
        orderDetail_lblCheckoutDate: "Çıkış Tarihi",
        orderDetail_lblChildAges: "Çocuk Yaşı",
        orderDetail_lblChildCount: "Çocuk Sayısı",
        orderDetail_lbldepartureFlightDateTime: "Kalkış Saati",
        orderDetail_lblDepartureFlightNumber: "Kalkış Uçuş No",
        orderDetail_lblDriverInfoTitle: "Sürücü Bilgisi",
        orderDetail_lblEmail: "E-mail",
        orderDetail_lblEventDate: "Etkinlik Tarihi",
        orderDetail_lblFlightNumber: "Uçuş No",
        orderDetail_lblFromDestination: "Çıkış Noktası (A)",
        orderDetail_lblGender: "Cinsiyet",
        orderDetail_lblGrandTotal: "Genel Toplam",
        orderDetail_lblGuestType: "Tip",
        orderDetail_lblHotelName: "Otel Adı",
        orderDetail_lblLastBookingDate: "Son Satın Alma Tarihi",
        orderDetail_lblLastTicketDate: "Son Satın Alma Zamanı",
        orderDetail_lblLastVoidDate: "Son Ücretsiz İptal Tarihi",
        orderDetail_lblLegIndex: "Sıra No",
        orderDetail_lblLegStatus: "Durum",
        orderDetail_lblName: "Ad Soyad",
        orderDetail_lblNameSurname: "İsim-Soyisim",
        orderDetail_lblNationality: "Uyruk",
        orderDetail_lblOrderId: "Order ID",
        orderDetail_lblOrderStatus: "Durum",
        orderDetail_lblPackageProviderSchimetterling: "SCHMETTERLING",
        orderDetail_lblPartialPaymentLastBookingDate: "Depozito Ödeme Son Tarihi",
        orderDetail_lblPartialPaymentLastCancelDate: "Son İptal Zamanı",
        orderDetail_lblPartialPaymentLastVoidDate: "Kalan Ödeme Son Tarihi",
        orderDetail_lblPartialPaymentVoidDate: "İptal İade Zamanı",
        orderDetail_lblPassengerEmail: "Yolcu E-mail",
        orderDetail_lblPassengerInfoRoomType: "Oda Tipi",
        orderDetail_lblPassengerInfoTitle: "Misafir Bilgileri",
        orderDetail_lblPassengerPhone: "Yolcu Telefonu",
        orderDetail_lblPhoneNumber: "Telefon",
        orderDetail_lblPlannedPickupDateTime: "Alınış Tarihi ve Saati",
        orderDetail_lblPriceInfoTitle: "Fiyat Bilgileri",
        orderDetail_lblProvider: "Tedarikçi",
        orderDetail_lblProviderRnr: "Tedarikçi RNR",
        orderDetail_lblRentACarInfoTitle: "Rent A Car Bilgileri",
        orderDetail_lblReservationWarnings: "Havalimanı buluşma bilgisi veya otelden alınış saati bir gün önce Whatsapp mesajı ile yolcu telefonuna bildirilecektir.",
        orderDetail_lblReserveDate: "Rezerve Tarihi",
        orderDetail_lblReverseDate: "İptal Tarihi",
        orderDetail_lblRnr: "RNR",
        orderDetail_lblRnrStatus: "Durum",
        orderDetail_lblRoomInformations: "Oda Bilgileri",
        orderDetail_lblRoomType: "Oda Tipi",
        orderDetail_lblTicketDate: "Oluşturulma Zamanı",
        orderDetail_lblTnr: "TNR",
        orderDetail_lblTnrStatus: "Durum",
        orderDetail_lblToDestination: "Varış Noktası (B)",
        orderDetail_lblTotal: "Toplam",
        orderDetail_lblTotalPrice: "Toplam Ücret",
        orderDetail_lblTransferInfoTitle: "Transfer Bilgileri",
        orderDetail_lblTravelerInfoTitle: "Yolcu Bilgileri",
        orderDetail_lblUserEmail: "Kullanıcı E-Mail",
        orderDetail_lblUserPhone: "Kullanıcı Telefonu",
        orderDetail_lblVehicle: "Araç Tipi",
        orderDetail_lblVehicleTypeInfo: "Marka / Model",
        orderDetail_lblViewAddon: "Add-on Görüntüle",
        orderDetail_lblVoucher: "Voucher",
        orderDetail_lblVoucherId: "GTS Voucher Id",
        orderDetail_lblVoucherStatus: "Durum",
        orderDetail_PartialCancellationContentText1: "Rezervasyonun iptali durumunda aşağıdaki cezalar uygulanacaktır.",
        orderDetail_PartialCancellationContentText2: "Bu rezervasyonun iptali durumunda depozito ve kalan ödemenin iadesi bulunmamaktadır.",
        orderDetail_PartialCancellationContentText3: "#VALUE1# tarihine kadar #VALUE# cezası uygulanacaktır.",
        orderDetail_PartialCancellationContentText4: "#VALUE1# tarihine kadar bakiyesi ödenen rezervasyon tutarının iadesi yoktur.",
        orderDetail_PartialCancellationPolicy: "İptal Politikaları",
        orderDetail_PartialPaymentDeadLineBody: "Yukarıdaki tarihe kadar #VALUE# euroluk depozito ödemesi yapılmadığı durumda rezervasyon iptal olur",
        orderDetail_PartialPaymentDeadLineTitle: "Depozito Son Ödeme Tarihi:",
        orderDetail_partialPaymentRemainingPayment: "Kalan Ödeme",
        orderDetail_partialPaymentRemainingText: "#VALUE# tutarındaki kalan ödeme #VALUE1# tarihine kadar yapılmalıdır.",
        orderDetail_partialPaymentRemainingTitle: "Bu order için depozito ödemesi yapıldı.",
        orderDetail_passengerInformations_ancillaryTotal: "Ek Hizmetler Toplam",
        orderDetail_passengerInformations_grandTotal: "Genel Toplam",
        orderDetail_passengerInformations_paymentFreeTotal: "Kredi Kartı İşlem Ücreti",
        orderDetail_passengerInformations_reissueTotal: "Değişiklik Toplamı",
        orderDetail_passengerInformations_reverseTotal: "İade Toplamı",
        orderDetail_passengerInformations_salesTotal: "Satış Toplamı",
        orderDetail_provider: "Tedarikçi",
        "OrderDetail_tblPass.thDate": "Tarih",
        "OrderDetail_tblTnrLegs.thArrivalFlightNumber": "İniş Uçuş No",
        "OrderDetail_tblTnrLegs.thLegIndex": "Sıra No",
        orderDetail_thAgencyId: "Acente ID",
        orderDetail_thAgencyName: "Acente Adı",
        orderDetail_thAirlinePnr: "Havayolu Pnr",
        orderDetail_thLastPurchaseDateTime: "Son Satın Alma Zamanı",
        orderDetail_thOrderId: "Order ID",
        orderDetail_thPassengerEmail: "Yolcu E-mail",
        orderDetail_thPassengerPhone: "Yolcu Telefonu",
        orderDetail_thPnr: "Pnr",
        orderDetail_thProvider: "Tedarikçi",
        orderDetail_thReservationDateTime: "Rezerve Zamanı",
        orderDetail_thStatus: "Durum",
        orderDetail_thStatusLabel: "Durum",
        orderDetail_thTicketDateTime: "Biletleme Zamanı",
        orderDetail_thUserEmail: "Kullanıcı E-Mail",
        orderDetail_thUserPhone: "Kullanıcı Telefonu",
        orderDetail_titleReservationInfo: "Rezervasyon Bilgileri",
        orderDetail_transferDate: "Transfer Tarihi",
        orderDetailFlight_txtTaskMessage: "#PNRID# nolu pnr #ORDERID# nolu order cancel edilemedi! #TASKID# üzerinden task açıldı.",
        orderDetailPage_baggage: "Bagaj",
        orderDetailPage_date: "Tarih",
        orderDetailPage_eticket: "E-Bilet",
        orderDetailPage_fare: "Fare",
        orderDetailPage_flightInformation: "Uçuş Bilgileri",
        orderDetailPage_grandTotal: "Genel Toplam",
        orderDetailPage_lblBirthDate: "Doğum Tarihi",
        orderDetailPage_nameSurname: "İsim-Soyisim",
        orderDetailPage_orderSummaryHeader_agencyIdLabel: "Acente ID",
        orderDetailPage_orderSummaryHeader_agencyNameLabel: "Acente Adı",
        orderDetailPage_orderSummaryHeader_agencyPhoneLabel: "Acente Telefonu",
        orderDetailPage_orderSummaryHeader_contactEmailLabel: "Yolcu E-mail",
        orderDetailPage_orderSummaryHeader_contactPhoneLabel: "Yolcu Telefonu",
        orderDetailPage_orderSummaryHeader_orderIdLabel: "Order ID",
        orderDetailPage_orderSummaryHeader_passengerEmailLabel: "Yolcu E-mail",
        orderDetailPage_orderSummaryHeader_passengerPhoneLabel: "Yolcu Telefonu",
        orderDetailPage_orderSummaryHeader_statusLabel: "Durum",
        orderDetailPage_orderSummaryHeader_userEmailLabel: "Kullanıcı E-Mail",
        orderDetailPage_orderSummaryHeader_userPhoneLabel: "Kullanıcı Telefonu",
        orderDetailPage_passengerInformation: "Yolcu Bilgileri",
        orderDetailPage_passengerInformation_sectionTitle: "Misafir Bilgileri",
        orderDetailPage_passengerSummaryHeader_roomTypeLabel: "Oda Tipi",
        orderDetailPage_pnrSummaryHeader_airlinePnr: "Havayolu Pnr",
        orderDetailPage_pnrSummaryHeader_lastPurchaseTime: "Son Satın Alma Zamanı",
        orderDetailPage_pnrSummaryHeader_provider: "Tedarikçi",
        orderDetailPage_pnrSummaryHeader_reservationInformations: "Rezervasyon Bilgileri",
        orderDetailPage_pnrSummaryHeader_reservationTime: "Rezerve Zamanı",
        orderDetailPage_pnrSummaryHeader_status: "Durum",
        orderDetailPage_pnrSummaryHeader_ticketDateTime: "Biletleme Zamanı",
        orderDetailPage_priceInformation_sectionTitle: "Fiyat Bilgileri",
        orderDetailPage_priceSummaryHeader_accommodationDateLabel: "Konaklama Tarihi",
        orderDetailPage_priceSummaryHeader_agencyCommissionLabel: "Acente Komisyon",
        orderDetailPage_priceSummaryHeader_agencyNetPriceLabel: "Acente Net Tutar",
        orderDetailPage_priceSummaryHeader_roomTypeLabel: "Oda Tipi",
        orderDetailPage_priceSummaryHeader_totalPriceLabel: "Toplam",
        orderDetailPage_serviceFee: "T.Hizmet Bedeli",
        orderDetailPage_status: "Durum",
        orderDetailPage_tax: "Vergi",
        orderDetailPage_voucherInformation_sectionTitle: "Konaklama Bilgileri",
        orderDetailPage_voucherRoomTypeHeader_adultNumberLabel: "Yetişkin Sayısı",
        orderDetailPage_voucherRoomTypeHeader_boardTypeLabel: "Konaklama Tipi",
        orderDetailPage_voucherRoomTypeHeader_childrenAgeLabel: "Çocuk Yaşı",
        orderDetailPage_voucherRoomTypeHeader_childrenNumberLabel: "Çocuk Sayısı",
        orderDetailPage_voucherRoomTypeHeader_roomTypeLabel: "Oda Tipi",
        orderDetailPage_voucherSummaryHeader_checkInLabel: "Giriş Tarihi",
        orderDetailPage_voucherSummaryHeader_checkOutLabel: "Çıkış Tarihi",
        orderDetailPage_voucherSummaryHeader_hotelNameLabel: "Otel Adı",
        orderDetailPage_voucherSummaryHeader_lastBookingDateTimeLabel: "Son Satın Alma Tarihi",
        orderDetailPage_voucherSummaryHeader_lastVoidDateTimeLabel: "Son Ücretsiz İptal Tarihi",
        orderDetailPage_voucherSummaryHeader_providerLabel: "Tedarikçi",
        orderDetailPage_voucherSummaryHeader_reserveDateTimeLabel: "Rezerve Tarihi",
        orderDetailPage_voucherSummaryHeader_reverseDateTimeLabel: "İptal Tarihi",
        orderDetailPage_voucherSummaryHeader_statusLabel: "Durum",
        orderDetailPage_voucherSummaryHeader_totalPriceLabel: "Toplam",
        orderDetailPage_voucherSummaryHeader_voucherLabel: "Voucher",
        OrderDetailPayment_btnReverse: "Cezasız İptal",
        OrderDetailPayment_NOT_SALES_OFFICE: "Satış ofisi değildir. Lütfen kullanıcınızı veya acentenizi kontrol ediniz.",
        OrderDetailPayment_popup_EXCEPTION: "Bilinmeyen bir hata ile karşılaştık.",
        OrderDetailPayment_popup_FAILURE: "Başarısız!",
        OrderDetailPayment_popup_FAILURE_VOUCHER_NOT_REVERSABLE: "Rezervasyonunuz iptal iade edilmeyen rezervasyondur.",
        OrderDetailPayment_popup_REJECTED: "Bir Hata Oluştu.",
        OrderDetailPayment_popup_REJECTED_INSUFFICIENT_PAYMENT: "İşleminiz sırasında bir hata aldınız.  Ödemeniz tahsil edilememiştir.",
        OrderDetailPayment_popup_SUCCESS: "Başarılı",
        OrderDetailPayment_progressGif_RESERVE_HOTEL: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        OrderDetailPayment_progressGif_TICKET_HOTEL: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        OrderDetailPayment_progressGif_TicketReservedFlight: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        orderDetailTab_btnPartialPaid: "Parçalı Alındı",
        orderDetailtransfer__lblProvider: "Tedarikçi",
        orderSearch_creationFromDate: "Başlangıç Tarihi",
        orderSearch_creationToDate: "Bitiş Tarihi",
        orderSearch_filter_btnSearch: "Listele",
        orderSearch_filter_lblCreationFromDate: "Başlangıç Tarihi",
        orderSearch_filter_lblCreationToDate: "Bitiş Tarihi",
        orderSearch_filter_lblDateType: "Tarih Tipi",
        orderSearch_filter_lblEticket: "E-Bilet",
        orderSearch_filter_lblHotel: "Otel",
        orderSearch_filter_lblName: "Ad",
        orderSearch_filter_lblOrderId: "Order ID",
        orderSearch_filter_lblPnr: "Pnr",
        orderSearch_filter_lblPnrStatus: "Pnr Durumu",
        orderSearch_filter_lblProviderList: "Tedarikçi Listesi",
        orderSearch_filter_lblRentACar: "Rent A Car",
        orderSearch_filter_lblRnr: "Rent A Car",
        orderSearch_filter_lblRnrStatus: "Rnr Durumu",
        orderSearch_filter_lblSurname: "Soyad",
        orderSearch_filter_lblVoucher: "Voucher",
        orderSearch_filter_lblVoucherId: "Voucher ID",
        orderSearch_filter_lblVoucherStatus: "Durum",
        orderSearch_filter_plhEticket: "E-Bilet",
        orderSearch_filter_plhName: "Ad",
        orderSearch_filter_plhOrderId: "Order ID",
        orderSearch_filter_plhPnr: "Pnr",
        orderSearch_filter_plhRnr: "Rnr",
        orderSearch_filter_plhSurname: "Soyad",
        orderSearch_filter_plhVoucher: "Voucher",
        orderSearch_filter_plhVoucherId: "Voucher ID",
        orderSearch_orderSearch: "Tarih Tipi",
        orderSearch_pageTitle: "Order Arama",
        orderSearch_pnrStatus: "Pnr Durumu",
        orderSearch_providerList: "Tedarikçi Listesi",
        orderSearch_tblMain_agencyName: "Acente İsmi",
        orderSearch_tblMain_dropOffLocation: "Teslim Ediliş Noktası",
        orderSearch_tblMain_etickets: "E-Bilet",
        orderSearch_tblMain_gsaName: "Gsa Adı",
        orderSearch_tblMain_insertedDate: "Satın Alındığı Zaman",
        orderSearch_tblMain_insertedUser: "Oluşturan Kullanıcı",
        orderSearch_tblMain_nameSurname: "Ad Soyad",
        orderSearch_tblMain_orderId: "Order ID",
        orderSearch_tblMain_pickUpDateTime: "Alınış Zamanı",
        orderSearch_tblMain_pickUpLocation: "Teslim Alış Noktası",
        orderSearch_tblMain_pnrs: "Pnr",
        orderSearch_tblMain_pnrStatuses: "Pnr Durumu",
        orderSearch_tblMain_provider: "Tedarikçi",
        orderSearch_tblMain_rnr: "RNR No",
        orderSearch_tblMain_rnrStatus: "Rnr Durumu",
        orderSearch_tblMain_rooms: "Odalar",
        orderSearch_tblMain_tnrs: "Tnr",
        orderSearch_tblMain_tnrStatuses: "Durum",
        orderSearch_tblMain_vehicle: "Araç",
        orderSearch_tblMain_voucherId: "Voucher ID",
        orderSearch_tblMain_vouchers: "Voucher",
        orderSearch_tblMain_voucherStatuses: "Durum",
        orderSearch_voucherStatus: "Durum",
        orderSearch__filter_lblVoucherStatus: "Durum",
        orderSearch_filter_lblFlight: "Uçak",
        orderSearch_filter_lblFromLocation: "Nereden",
        orderSearch_filter_lblTnr: "Tnr",
        orderSearch_filter_lblTnrStatus: "Durum",
        orderSearch_filter_lblToLocation: "Nereye",
        orderSearch_filter_lblTransfer: "Transfer",
        orderSearch_filter_plhFromLocation: "Nereden",
        orderSearch_filter_plhTnr: "Tnr",
        orderSearch_filter_plhToLocation: "Nereye",
        otelSalesReport_tblMain_thContactSurname: "Soyad",
        passengerCard_country: "Ülke:",
        passengerCard_dateofBirth: "Doğum Tarihi",
        passengerCard_expiryDate: "Bitiş Tarihi",
        passengerCard_hesCode: "HES Kodu",
        passengerCard_identityNo: "T.C. Kimlik No",
        passengerCard_milesCard: "Mil No",
        passengerCard_milNo: "Mil No",
        passengerCard_nationality: "Vatandaşlık",
        passengerCard_passportNumber: "Pasaport No",
        passengerCard_serialNumber: "Seri No",
        paymentChoice_partialPaymentPaidText: "#VALUE# tarihinde #VALUE1# tutarındaki depozito ödemesi yapıldı.",
        paymentChoice_partialPaymentRemainingText: "#VALUE# tarihine kadar  #VALUE1# tutarında ödeme yapılması bekleniyor",
        PaymentChoiceItem_btnLEAVE: "REZERVASYONA GİT",
        PaymentChoiceItem_btnRESERVE: "REZERVE ET",
        PaymentChoiceItem_btnREVERSE: "CEZASIZ İPTAL",
        PaymentChoiceItem_btnTICKET: "SATIN AL",
        PaymentChoiceItem_FLIGHT_UPCOMING: "Havayolu rezervasyona müsaade etmemektedir.",
        PaymentChoiceItem_INSUFFICIENT_AGENCY_BALANCE: "Kullanılabilir bakiyeniz yetersizdir.  Lütfen ekibimiz ile iletişime geçiniz.",
        PaymentChoiceItem_INSUFFICIENT_GSA_BALANCE: "Gsa bakiyesini lütfen kontrol ediniz.",
        PaymentChoiceItem_lblCommission: "Komisyon",
        PaymentChoiceItem_lblGrandTotal: "Ödenecek Tutar",
        PaymentChoiceItem_lblPartialPaymentDepositAmount: "Teminat Tutarı",
        PaymentChoiceItem_lblRemainingBalance: "Kalan Bakiyeniz",
        PaymentChoiceItem_lblTotal: "Toplam",
        PaymentChoiceItem_NOT_SALES_OFFICE: "Satış ofisi değildir. Lütfen kullanıcınızı veya acentenizi kontrol ediniz.",
        PaymentChoiceItem_SSRS_CANT_BE_RESERVED: "SSR eklediğiniz için hava yolu rezervasyon izni vermemektedir. Direkt satın alma işlemi gerçekleştirmeniz gerekmektedir.",
        paymentChoiceItem_stepBack: "Önceki",
        PaymentChoiceItem_txtCommissionInfo_CREDITCARD: "Kredi kartı VISA , MASTER ile satın alım ve yüklemelerde sabit %2 banka komisyon oranı eklenecektir.  Kredi kartı AMEX ile satın alım ve yüklemelerde sabit %4,5 banka komisyon oranı eklenecektir.",
        PaymentChoiceItem_txtCommissionInfo_IDEAL: "Komisyon alınmamaktadır.",
        PaymentChoiceItem_txtCommissionInfo_SOFORT: "Sofort ile yüklemelerde 1-500€ arasında sabit 3€, 501€ dan itibaren sabit 5€ banka komisyonu eklenecektir.",
        paymentChoices_lblCARI: "CARİ",
        paymentComponent_commission: "Komisyon",
        paymentComponent_paymentInformations: "Ödeme Bilgileri",
        paymentComponent_remainingBalance: "Kalan Bakiyeniz",
        PaymentPurchaseWidget_btnReverse: "Cezasız İptal",
        PaymentPurchaseWidget_btnReverseWithPenalty: "Ceza ile İade et",
        PaymentPurchaseWidget_FLIGHT_RESERVATION_LEGAL_NOTICE: "Opsiyonlu kayıtlarda rezervasyon aşamasında gösterilen fiyat ile biletleme aşamasında gösterilen fiyat arasında farklılıklar oluşabilmektedir. Hatırlatmak isteriz ki; havayolları opsiyonlu kayıtlarda opsiyon süresi boyunca koltuk rezervasyonunu garanti etmekte olup fiyat opsiyonu ile ilgili bir garanti sunmamaktadır. Bu kurallar IATA ve havayolları tarafından belirlenmektedir.Havayolu kaynaklı bu tarz fiyat değişikliği olması halinde biletleme öncesi tarafınıza onaya sunulacaktır.",
        PaymentPurchaseWidget_NO_AUTHORIZATED_ACTION: "Kullanıcınızın bu işlemi yapmaya yetkisi yoktur. Lütfen acentenizi kontrol ediniz.",
        PaymentPurchaseWidget_NOT_SALES_OFFICE: "Satış ofisi değildir. Lütfen kullanıcınızı veya acentenizi kontrol ediniz.",
        paymentPurchaseWidget_partialPay: "Parçalı Öde",
        PaymentPurchaseWidget_popup_REVERSE_FLIGHT: "Biletiniz iptal edilecek. Onaylıyor musunuz ?",
        PaymentPurchaseWidget_popup_REVERSE_FLIGHT_W_PENALTY: "Toplam  #VALUE1# #VALUE2# ceza ile iade edilebilir. Onaylıyor musunuz?",
        PaymentPurchaseWidget_popup_REVERSE_HOTEL: "Rezervasyonunuz iptal edilecek. Onaylıyor musunuz ?",
        PaymentPurchaseWidget_popup_REVERSE_RENTACAR: "Rezervasyonunuz iptal edilecek. Onaylıyor musunuz ?",
        PaymentPurchaseWidget_popup_REVERSE_TRANSFER: "Rezervasyonunuz iptal edilecek. Onaylıyor musunuz ?",
        PaymentPurchaseWidget_popup_TICKET_FLIGHT: "Rezervasyonunuzu satın almak istiyor musunuz ?",
        PaymentPurchaseWidget_popup_TICKET_HOTEL: "Rezervasyonunuzu satın almak istiyor musunuz ?",
        PaymentPurchaseWidget_popup_title: "Bilgi",
        PaymentPurchaseWidget_progressGif_RESERVE_HOTEL: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_RESERVE_TRANSFER: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_ReverseFlight: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_ReverseHotel: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_ReverseTransfer: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_TICKET_FINANCE: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_TICKET_HOTEL: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_TICKET_TRANSFER: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_TicketReservedFlight: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        PaymentPurchaseWidget_progressGif_TicketReservedHotel: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        pending_pending: "Beklmede",
        PickupInfo_txtAirToAir: "* Yolcularımız #VALUE3# öncesinde #VALUE1# lokasyonundan #VALUE2# lokasyonuna bırakılacaklardır.",
        PickupInfo_txtAirToLoc: "* Yolcularımız #VALUE1# lokasyonundan #VALUE2# lokasyonuna bırakılacaklardır.",
        PickupInfo_txtLocToAir: "* Yolcularımız #VALUE3# öncesinde #VALUE1# lokasyonundan #VALUE2# lokasyonuna bırakılacaktır.",
        PickupInfo_txtLocToLoc: "* Yolcularımız #VALUE2# lokasyonundan #VALUE3# lokasyonuna bırakılmak için saat #VALUE1# civarında alınacaklardır.",
        PnrConfirm_bookingConfirmation: "Booking<br> Confirmation/<br> Rechnung",
        PnrConfirm_fare: "Fare",
        PnrConfirm_grandTotal: "Genel Toplam",
        PnrDoc_Email: "E-posta",
        pnrDoc_PopupCopiedToClipboard: "PDF linki kopyalandı!",
        PnrOffer_flight: "Uçuş",
        popup_ancillaryDivideContent: "PNR Ayırma işlemi yapıldıktan sonra PNR lar kombine edilemez. <br> Onaylıyor musunuz?",
        popup_confirmationTitle: "Onaylama",
        Popup_ERROR: "Bir Hata Oluştu.",
        Popup_exclamationTitle: "Bir Hata Oluştu.",
        Popup_HOTEL_REVERSE_FAILURE_VOUCHER_NOT_REVERSABLE: "Rezervasyonunuzun iptali için lütfen Service Center ile iletişime geçiniz.",
        popup_informationTitle: "Bilgi",
        popup_lblCheckout: "Checkout a Devam Et",
        Popup_lblChildrenAgesWarn: "Hata",
        Popup_lblChildrenAgesWarnText: "Lütfen çocuk yaşını kontrol ediniz.",
        Popup_lblEmptySearch: "Hata",
        Popup_lblFlightSalesReportDateRange: "Tarih aralığı maksimum 1 yıl olmalıdır. Lütfen giriş yaptığınız tarihleri kontrol ediniz.",
        Popup_lblLessThanTwoCharacter: "Bilgi",
        Popup_lblPackagesContent: "Paket satışları çok yakında burada.",
        Popup_lblPackagesTitle: "Hazır mısınız?",
        Popup_lblPlsWaitForContact: "Ekibimiz talebinizle ilgili mümkün olan en kısa sürede size yardımcı olacaktır.",
        Popup_lblRequestReceived: "Talebiniz alınmıştır.",
        popup_lblReserve: "Aynı Bilgilerle Yeniden Rezerve Et",
        Popup_lblSuccess: "Başarılı",
        Popup_lblTransferMaintenance: "Bu servis için çalışmamız devam etmektedir.",
        Popup_lblWarning: "Hata",
        Popup_ORDER_DETAIL_AUTHORIZATION_NOT_OWNER: "Bu orderı görüntüleme yetkiniz bulunmamaktadır.",
        Popup_RESERVE_HOTEL_FAILURE_RESERVATION: "Lütfen yeniden arama yapınız.",
        Popup_RESERVE_HOTEL_SUCCESS_OK: "Rezervasyonunuzu başarılı bir şekilde rezerve ettiniz. Lütfen işleminizi kontrol ediniz.",
        Popup_SUCCESS: "Başarılı",
        popup_successTitle: "Başarılı",
        "Popup_TICKET_HOTEL_EXCEPTION_error.no.enough.room.quota": "İşleminiz yürütülürken oda kotası tükenmiştir. Lütfen sayfayı kapatıp yeniden arama yapınız ya da Service Center ekibimizle iletişime geçiniz.",
        Popup_TICKET_HOTEL_SUCCESS_OK: "Rezervasyonunuzu başarılı bir şekilde satın aldınız. Lütfen işleminizi kontrol ediniz.",
        Popup_TICKETRESERVED_FLIGHT_SUCCESS_OK: "Biletleme işleminiz başarılı.",
        Popup_TICKETRESERVED_HOTEL_SUCCESS_OK: "Rezervasyonunuzu başarılı bir şekilde rezerve ettiniz. Lütfen işleminizi kontrol ediniz.",
        Popup_txtDuplicatedPassenger: "Aynı bilgilerle birden fazla yolcu kaydedilemez.",
        Popup_txtEmptySearch: "Lütfen arama kutucuğunu doldurarak gelen listeden bir seçim yapınız.",
        Popup_txtFlightChangeError: "Onaylama sırasında hata aldınız. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        Popup_txtFlightChangeErrorApprove: "Onaylama sırasında hata aldınız. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        Popup_txtFlightChangeErrorPNR_Reissue: "Onaylama sırasında hata aldınız. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        Popup_txtFlightChangeSuccess: "Uçuş değişikliğinizi başarılı bir şekilde onayladınız. Service Center üzerinden takip edebilirsiniz.",
        Popup_txtFlightChangeSuccessApprove: "Uçuş değişikliğinizi başarılı bir şekilde onayladınız. Service Center üzerinden takip edebilirsiniz.",
        Popup_txtFlightChangeSuccessPNR_Reissue: "Talebiniz başarılı bir şekilde ekibimize iletilmiştir. Talebinizi Service Center üzerinden takip edebilirsiniz.",
        Popup_txtFlightChangeSuccessPNR_Reverse: "İptal Talebiniz başarılı bir şekilde ekibimize iletilmiştir. Talebinizi Service Center üzerinden takip edebilirsiniz.",
        Popup_txtLessThanTwoCharacter: "Minimum 2 karakter girilmelidir.",
        Popup_txtMealNotAvailable: "Rezervasyonunuz için yemek talebi oluşturulamaz. Lütfen Service Center ile iletişime geçiniz.",
        Popup_txtSsrsNotAvailable: "Rezervasyonunuz için ssr talebi oluşturulamaz. Lütfen Service Center ile iletişime geçiniz.",
        Popup_txtWheelChairNotAvailable: "Rezervasyonunuz için tekerlekli sandalye talebi oluşturulamaz. Lütfen Service Center ile iletişime geçiniz.",
        Popup_txtWheelChairNowAvailable: "Rezervasyonunuz için tekerlekli sandalye talebi oluşturulamaz. Lütfen Service Center ile iletişime geçiniz.",
        popup_warningTitle: "Hatalı",
        pricechange_btnCancel: "İptal",
        priceChange_lblnewPrice: "Yeni Fiyat",
        priceChange_lblOldPrice: "Eski Fiyat",
        priceChange_lblpriceDiff: "Fiyat Farkı",
        profileSettings_lblAgencyAddress: "Adres",
        profileSettings_lblAgencyCountryPhoneCode: "Ülke Telefon Kodu",
        profileSettings_lblAgencyCurrency: "Para birimi",
        profileSettings_lblAgencyDescription: "Acentenize ait bilgiler.",
        profileSettings_lblAgencyEmail: "E-mail",
        profileSettings_lblAgencyId: "Acente ID",
        profileSettings_lblAgencyInfo: "Acente Bilgileri",
        profileSettings_lblAgencyName: "İsim",
        profileSettings_lblAgencyOfficialName: "Resmi İsim",
        profileSettings_lblAgencyPhone: "Telefon",
        profileSettings_lblAgencyShortName: "Kısa İsim",
        profileSettings_lblAgencyTimezone: "Saat dilimi",
        profileSettings_lblBrowseProfilePhoto: "Galeriden Seç",
        profileSettings_lblDragDropFile: "Dosyayı Sürükle Bırak veya",
        profileSettings_lblGsa: "Gsa",
        profileSettings_lblPasswordChangeContent: "Hesaplarınızın güvenliği için şifrelerinizi daha güçlü hale getirme zorunluluğu doğmuştur. Verdiğimiz zahmet sizlerin hesap güvenliği içindir. Şimdiden anlayışınız için teşekkür ederiz.",
        profileSettings_lblPasswordChangeTitle: "Şifre Değişikliği için Tıklayınız",
        profileSettings_lblPrivacyAndSecurity: "Güvenlik Tercihleri",
        profileSettings_lblPrivacyAndSecurityDescription: "Kullanıcınıza ait bilgiler.",
        profileSettings_lblProfilePhoto: "Profil Fotoğrafı",
        profileSettings_lblProfilePhotoDescription: "Yükleme yapmak istediğiniz fotoğraf boyutu aşağıdaki gibi olmalıdır.",
        profileSettings_lblProfileSettings: "Profili Düzenle",
        profileSettings_lblRegionLanguagePreferences: "Bölge ve Telefon Tercihleri",
        profileSettings_lblRegionLanguagePreferencesDescription: "Acentenize ait bilgiler.",
        profileSettings_lblReset: "RESETLE",
        profileSettings_lblResetPassword: "Yeni Şifre Oluştur",
        profileSettings_lblUpdate: "GÜNCELLE",
        profileSettings_lblUserDescription: "Kullanıcınıza ait bilgiler.",
        profileSettings_lblUserEmail: "E-mail",
        profileSettings_lblUserInfo: "Kullanıcı Bilgileri",
        profileSettings_lblUserLanguage: "Dil Tercihi",
        profileSettings_lblUserName: "İsim",
        profileSettings_lblUserPhone: "Telefon",
        profileSettings_lblUserSurname: "Soy İsim",
        profileSettings_lblUserTimezone: "Saat dilimi",
        renatacar_tooltipINSURANCE_ECO: "Rent a car kaskoları lastik, cam ve farda oluşan hasarları karşılamamaktadır. Bunun için ekstra bir sigorta satın alınması gerekmektedir.",
        rentacar_airConditioning: "Klima",
        rentacar_Ancillaries: "Ek Hizmetler",
        rentacar_btnCancel: "İptal",
        rentacar_btnSearch: "#VALUE# GÜN KİRALA",
        rentacar_btnSearchDaily: "Araç Ara",
        rentacar_btnSubmit: "Seç",
        rentacar_doorCount: "Kapı Sayısı",
        rentacar_driverInformations: "Sürücü Bilgisi",
        rentacar_dropoffDate: "İade Tarihi & Saati",
        rentacar_extraFeatures: "Ek Özellikler",
        rentacar_filtersFuelType: "Yakıt Tipi",
        rentacar_filtersTransmissionType: "Vites Tipi",
        rentacar_filtersVehicleType: "Araç Sınıfı",
        rentacar_gearType: "Vites Tipi",
        rentacar_largeBaggage: "Büyük Bagaj",
        rentacar_lblAddDropoffLocation: "Farklı yere iade edilecek",
        rentacar_lblAncillaries: "Ek Hizmetler",
        rentacar_lblDropoffLocation: "İade Yeri",
        rentacar_lblPerDay: "Günlük",
        rentacar_lblPickupLocaton: "Alış Yeri",
        rentacar_lblRentACarDescriptionsInfoFives: "Araç teslim zamanından 24 saat öncesine kadar ücretsiz iptal sağlayabilirsiniz.",
        rentacar_lblRentACarDescriptionsInfoFour: "Aracı mukavele formu acente ile sürücü arasında mutlaka imzalanmalıdır.",
        rentacar_lblRentACarDescriptionsInfoOne: "Sürücü kimliğinizin/ehliyetinizin fiziksel olarak yanınızda olması gerekmektedir.",
        rentacar_lblRentACarDescriptionsInfoThree: "Rent a car kaskoları lastik, cam ve farda oluşan hasarları karşılamamaktadır. Bunun için ekstra bir sigorta satın alınması gerekmektedir.",
        rentacar_lblRentACarDescriptionsInfoTwo: "Araç kiralama esnasında kredi kartı ve depozito talep edilmemektedir.",
        rentacar_lblRentACarDescriptionsTitle: "Rent a Car Açıklamaları",
        rentacar_lblShowAncillaries: "Ek Hizmetleri Göster",
        rentacar_lblTermsOfUse: "Kullanım Koşulları",
        rentacar_locationSearchlbl: "Şehir veya Havalimanı",
        rentacar_orSmiliar: "veya Benzer",
        rentacar_passengerCapacity: "Yolcu Kapasitesi",
        rentacar_pickupDate: "Alış Tarihi & Saati",
        rentacar_ReservationDate: "Rezervasyon Tarihleri",
        rentacar_smallBaggage: "Küçük Bagaj",
        rentacar_suitCases: "Bagaj",
        rentacarCard_minDriverAge: "Minimum Sürücü Yaşı : #VALUE#",
        rentacarCard_minLicenseAge: "Minimum Lisans Yılı : #VALUE#",
        rentacarCheckout_lblAgencyCommission: "Acente Komisyon",
        rentacarCheckout_lblAgencyExtraCommission: "Acente Ekstra Komisyon",
        rentacarCheckout_lblAncillaries: "Ek Hizmetler",
        rentacarCheckout_lblAncillaryTotal: "Ek Hizmetler Toplam",
        rentacarCheckout_lblDropOffLocation: "İade Yeri",
        rentacarCheckout_lblGrandTotal: "Genel Toplam",
        rentacarCheckout_lblLastVoidDateTime: "Rent A Car iptalinde araç teslimatına 24 saat kala ücretsiz iptal iade yapabilirsiniz.",
        rentacarCheckout_lblPaymentFeeTotal: "Kredi Kartı İşlem Ücreti",
        rentacarCheckout_lblPickUpLocation: "Alış Yeri",
        rentacarCheckout_lblPriceSummary: "Toplam",
        rentacarCheckout_lblReverseTotal: "İade Toplamı",
        rentacarCheckout_lblSaleTotal: "Satış Toplamı",
        rentacarCheckout_lblVehicleTotal: "Araç Fiyatı",
        rentACarOrderDetail_lblRentACarAgencyNote: "Acente Notu",
        rentacarOrderDetail_phOr: "veya",
        rentacarOrderDetail_phSimilar: "benzeri",
        rentACarSalesReport_currency: "Para birimi",
        rentACarSalesReport_filter_btnList: "Ara",
        rentACarSalesReport_filter_lblFromDate: "Başlangıç Tarihi",
        rentACarSalesReport_filter_lblReportType: "Rapor Tipi",
        rentACarSalesReport_filter_lblStatus: "Durum",
        rentACarSalesReport_filter_lblToDate: "Bitiş Tarihi",
        rentACarSalesReport_lblReportType: "Rapor Tipi",
        rentACarSalesReport_refundTotal: "İade Toplamı",
        rentACarSalesReport_reverseAmount: "İade Toplamı",
        rentACarSalesReport_rnrCount: "Order Adedi",
        rentACarSalesReport_tblMain_thAgencyCommission: "Acente Komisyon",
        rentACarSalesReport_tblMain_thAgencyName: "Acente İsmi",
        rentACarSalesReport_tblMain_thDriverName: "Sürücü Adı",
        rentACarSalesReport_tblMain_thDriverSurname: "Sürücü Soyadı",
        rentACarSalesReport_tblMain_thDropOffDateTime: "Bırakma Zamanı",
        rentACarSalesReport_tblMain_thDropOffLocation: "İade Yeri",
        rentACarSalesReport_tblMain_thGrandTotal: "Genel Toplam",
        rentACarSalesReport_tblMain_thMarkModel: "Araç",
        rentACarSalesReport_tblMain_thOrderId: "Order ID",
        rentACarSalesReport_tblMain_thPickUpDateTime: "Alınış Zamanı",
        rentACarSalesReport_tblMain_thPickUpLocation: "Alış Yeri",
        rentACarSalesReport_tblMain_thRnr: "Rnr",
        rentACarSalesReport_tblMain_thSaleDate: "Satış Tarihi",
        rentACarSalesReport_tblMain_thStatus: "Durum",
        rentACarSalesReport_tblMain_thTotal: "Toplam",
        rentACarSalesReport_title: "Araç Kiralama Satış Raporu",
        rentACarSalesReport_totalAmount: "Satış Toplamı",
        rentACarSalesReport_vehicleCount: "Yolcu Adedi",
        rentacarSearchForm_lblCreditCardDepositInfo: "Araç teslimi esnasında Kredi Kartı ya da Depozito istenmemektedir.",
        rentacarSearchInformation_date1: "Alış Zamanı",
        rentacarSearchInformation_date2: "Bırakma Zamanı",
        rentacarSearchInformation_locationFrom: "Alış Yeri",
        rentacarSearchInformation_locationTo: "İade Yeri",
        rentacarSearchInformation_text: "En iyi fiyatlar aranıyor, lütfen bekleyiniz!",
        requestForm_activeTab: "Task açılacak Pnr.",
        requestForm_packageTitle: "Task açılacak paket",
        requestForm_pnrTitle: "Task açılacak Pnr.",
        requestForm_rnrTitle: "Task açılacak Rnr.",
        requestForm_tnrTitle: "Task açılacak Tnr.",
        requestForm_unknownTitle: "Bu servisimizde çalışmamız devam etmektedir.",
        requestForm_voucherTitle: "Task açılacak Voucher",
        requestFormComponent_infoAlert_text: "Lütfen yapmak istediğiniz işlemi işaretleyiniz. Yanıt süresi seçiminize bağlı olarak değişecektir.",
        requestFormComponent_textareaPlaceholder_label: "Lütfen talebinizi yazınız...",
        reservationInfo_lblPopupPriceChangeTitle: "Rezervasyon Fiyat Değişiklik Bilgisi",
        ReservationInformation_progressGif_ReReserveFlight: "Sizin için aynı uçuşları yeniden arıyoruz.",
        resetPassword_emailEmpty: "Lütfen geçerli bir mail giriniz.",
        resetPassword_emailHasBeenSent: "Mail başarılı bir şekilde gönderilmiştir.",
        resetPassword_lowerCase: "Küçük Harf",
        resetPassword_minChar: "En az #VALUE# karakter.",
        resetPassword_numberRequired: "Sayı",
        resetPassword_passwordResetFaile: "Şifre yenileme linkinizi lütfen kontrol ediniz.",
        resetPassword_passwordResetFailed: "Hata aldınız. Lütfen şifre değiştirme linkini yeniden gönderiniz.",
        resetPassword_passwordResetMessage: "Lütfen yeni şifre belirleyiniz.",
        resetPassword_passwordResetSuccess: "Şifreniz değiştirildi.",
        resetPassword_passwordResetTitle: "Şifre Değişikliği",
        resetPassword_passwordsAreDifferent: "Girdiğiniz şifreler uyuşmamaktadır.",
        resetPassword_passwordsAreDifferentOrShort: "Girdiğiniz şifreler uyuşmamaktadır.",
        resetPassword_passwordsAreShort: "Şifreniz minimum 4 karakter olmalıdır.",
        resetPassword_resetPassword: "Yeni Şifreyi Kaydet",
        resetPassword_specialChar: "Özel Karakter",
        resetPassword_upperCase: "Büyük Harf",
        resetPassword_userPassword: "Yeni Şifre",
        resetPassword_userPasswordConfirm: "Yeni Şifreyi Tekrar Giriniz",
        RESPONSE___EXCEPTION_QUOTA: "İşleminiz yürütülürken oda kotası tükenmiştir. Lütfen sayfayı kapatıp yeniden arama yapınız ya da Service Center ekibimizle iletişime geçiniz.",
        RESPONSE___EXCEPTION_STOP: "İşleminiz yürütülürken oda kotası tükenmiştir. Lütfen sayfayı kapatıp yeniden arama yapınız ya da Service Center ekibimizle iletişime geçiniz.",
        RESPONSE___EXPIRED_OK: "Fiyat bilgisi değişmiş olabilir.  Lütfen yeniden arama yaparak işleminize devam ediniz.",
        RESPONSE__RESERVE_HOTEL_SUCCESS_OK: "İşleminiz başarılı bir şekilde gerçekleştirilmiştir.",
        RESPONSE__TICKET_HOTEL_SUCCESS_OK: "Rezervasyonunuzu başarılı bir şekilde satın aldınız. Lütfen işleminizi kontrol ediniz.",
        RESPONSE__TICKET_TRANSFER_SUCCESS_OK: "Transferinizi başarılı bir şekilde satın aldınız. Lütfen işleminizi kontrol ediniz.",
        RESPONSE__TICKETRESERVED_HOTEL_SUCCESS_OK: "İşleminiz başarılı bir şekilde gerçekleştirilmiştir.",
        "RESPONSE__TRANSFER_API_TICKET_TRANSFER_ISSUED_::TchnGtsDatabaseService.Reservation successful": "Transferinizi başarılı bir şekilde satın aldınız. Lütfen işleminizi kontrol ediniz.",
        "RESPONSE__TRANSFER_API_TICKET_TRANSFER_ISSUED_::TchnGtsDatabaseService.Reservation successful orderId:": "Transferinizi başarılı bir şekilde satın aldınız. Lütfen işleminizi kontrol ediniz. Order ID:",
        RESPONSE_429_TooManyRequests_OK: "Otel detayı görüntülenemiyor. Lütfen sayfayı yenileyerek tekrar deneyiniz ya da Service Center ekibimizden destek talep ediniz.",
        "RESPONSE_AGENT_API.HOTEL_TICKET_RESERVED.REJECTED.INSUFFICIENT_PAYMENT": "İşleminiz sırasında bir hata aldınız.  Ödemeniz tahsil edilememiştir.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.FAILURE.NO_VEHICLE_FOUND": "Kriterlerinize uygun araç bulunamamıştır. Lütfen arama kriterlerinizi değiştirip yeniden deneyiniz.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.NOT_EXISTS.LOCATION_NOT_MAPPED_FOR_PANDORA": "Aradığınız lokasyon için tedarikçi tarafında uygun araç bulunamamıştır. Lütfen talepleriniz için Service Center ile iletişime geçiniz.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.REJECTED.PARAMETER.PERIOD_DAYS": "#VALUE# gün ve üzeri için araç kiralama yapılmaktadır.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.REJECTED.PARAMETER.PICKUP_HOURS": "#VALUE# saat ve üzeri için araç kiralama yapılmaktadır.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_PARAMETER_OK: "Yanlış parametre girdiniz.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_REJECTED_EXPIRED: "Bakiye güncelleme sırasında hata aldınız.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_REJECTED_INSUFFICIENT_PAYMENT: "Ödeme sırasında bir hata aldınız.",
        RESPONSE_AGENT_API_BalanceUpdate_AUTHORIZATION_NOT_SALES_OFFICE: "Satış ofisi değildir. Lütfen kullanıcınızı veya acentenizi kontrol ediniz.",
        RESPONSE_AGENT_API_CHECKOUT_EXPIRED: "Uzun süredir işlem yapmadığınız için fiyatlar güncellenmiş olabilir.   Aramanızı yenileyerek işleminize devam edebilirsiniz.",
        "RESPONSE_AGENT_API_Detail_EXCEPTION_One or more errors occurred_": "Bilinmeyen bir hata aldınız.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TAKEN_OFF: "Lütfen uçuş tarihinizi kontrol ediniz.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TAKEN_OFF_TITLE: "Bilgi",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TIME_NOT_AVAILABLE: "Bagaj satın almak için lütfen Service Center ile görüşünüz.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TIME_NOT_AVAILABLE_TITLE: "Bilgi",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_MANUAL_DIVIDED: "Bagaj satın alım sırasında hata aldınız. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_MANUAL_DIVIDED_TITLE: "Bilgi",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_ANCILLARY: "Bu rezervasyon için daha önce bagaj alınmış. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_ANCILLARY_TITLE: "Bagaj Bilgi",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_TASK: "Bagaj satın alımı için lütfen Service Center ile görüşünüz.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_TASK_TITLE: "Bilgi",
        "RESPONSE_AGENT_API_FLIGHT_CHECKOUT_ PARAMETER_NOT_RESERVABLE": "Rezervasyon yapılamaz. Lütfen aramanızı yenileyerek işleme devam ediniz.",
        "RESPONSE_AGENT_API_FLIGHT_CHECKOUT_ PARAMETER_NOT_TICKETABLE": "Biletleme yapılamaz. Lütfen aramanızı yenileyerek işleme devam ediniz.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "Kullanıcınızın bu işlemi yapmaya yetkisi yoktur. Lütfen acentenizi kontrol ediniz.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXCEPTION_CHECKOUT: "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_BASKET: "Fiyat bilgisi değişmiş olabilir.  Lütfen yeniden arama yaparak işleminize devam ediniz.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_CHECKOUT: "Fiyat bilgisi değişmiş olabilir.  Lütfen yeniden arama yaparak işleminize devam ediniz.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_SEARCH: "Uzun süredir işlem yapmadığınız için fiyatlar güncellenmiş olabilir.   Aramanızı yenileyerek işleminize devam edebilirsiniz.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_FAILURE_NO_PRICE: "Seçtiğiniz uçuşta fiyat bulunamamıştır. Lütfen yeniden arama yapınız.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Rezervasyon yapılamaz. Lütfen aramanızı yenileyerek işleme devam ediniz.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_RETRY_NO_RESPONSE: "Lütfen istenilen bilgileri tam ve doğru şekilde giriniz.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_SESSION_INVALID_AGENCY: "Lütfen acente seçiniz.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_EXPIRED_CHECKOUT_NOT_FOUND: "İptal işlemi sırasında zaman aşımı alındı. Lütfen daha sonra tekrar deneyiniz.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_FAILURE_RESERVATION_FAILED: "Rezervasyon sırasında hata aldınız. Yeni rezervasyonunuz yapılmamıştır.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_BRAND_OR_SSR_NOT_FOUND: "Brand veya Ssr bulunamadı. Lütfen manuel devam ediniz.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_CHECKOUT_FAILED: "İşleminiz sırasında hata oluşmuştur. Yeniden arama yaparak işleminize devam edebilirsiniz.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_FLIGHT_NOT_FOUND: "Geçmiş rezervasyonunuza ait uçuşları havayolunda bulamadık. Lütfen uçuşları kontrol ediniz.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_SESSION_INVALID_GSA_OR_AGENCY: "Kullanıcınızın bu işlemi yapmaya yetkisi yoktur. Lütfen acentenizi kontrol ediniz.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_EXPIRED_INQUIRY: "İşleminiz sırasında zaman aşımı aldınız. Lütfen sayfanızı yenileyerek işleme devam ediniz.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_FAILURE_ALREADY_REVERSED: "Pnrınız iptal lütfen sayfanızı yenileyiniz. Sorun olduğunu düşünüyorsanız Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_FAILURE_UNREVERSABLE: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_INQUIRY_REJECTED_PNR_EXPIRED: "Son satın alma zamanı geçti. Service Center ile iletişime geçerek manuel iptal gerçekleştirebilirsiniz.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_SUCCESS_OK: "İşleminiz başarılı bir şekilde gerçekleştirilmiştir.",
        RESPONSE_AGENT_API_FLIGHT_SEARCH_SESSION_INVALID_AGENCY: "Lütfen acente seçiniz.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_AUTHORIZATION_DIFFERENT_USER: "Uçuş seçimi sırasında hata aldınız. Lütfen yeniden arama yapınız.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Uzun süredir işlem yapmadığınız için fiyatlar güncellenmiş olabilir.   Aramanızı yenileyerek işleminize devam edebilirsiniz.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_NOT_EXISTS_NO_PACKAGE_AVAILABLE: "Seçmiş olduğunuz uçuş için paket ücret mevcut değildir.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Lütfen 'Eşlenik' uçuşları seçiniz, ya da 'Tek Yön Fiyatı' olmayan bir seçiminiz varsa kaldırınız.",
        "RESPONSE_AGENT_API_FLIGHT_TICKET_ FAILURE_PRICE_DIFFERENCE": "İşleminiz sırasında fiyat farkı oluşmuştur. Yapmak istediğiniz işlemi lütfen seçiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_EXCEPTION_PNR_RETRIEVE: "Rezervasyon bilgileriniz değişmiştir. Otomatik işlem yapılamamaktadır. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_EXCEPTION_TICKET: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "Biletleme işleminiz sırasında fiyat farkı oluşmuştur.  Fiyat Farkı :",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE_TITLE: "Fiyat Değişti!",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_REJECTED: "Uçuş detaylarınız değişmiş.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_REJECTED_ISSUED: "Rezervasyonunuz satın alındı.  Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_PARAMETER_EXISTED: "İşlem yapmak istediğiniz detaylarda daha önce biletiniz veya rezervasyonunuz olabilir. Lütfen Service Center ekibimizden kontrol sağlayınız.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_PRICE_DIFFERENCE_AFTER_RESERVATION: "İşleminiz sırasında fiyat farkı oluşmuştur.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_CHANGE_DETECTED: "Uçuş detaylarınızda değişiklik vardır.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_IMPORT_TYPE_MANUAL: "Biletinize manuel işlem yapılması sebebi ile biletleme işleminizi şuan için gerçekleştiremiyoruz.  Lütfen Service Center ekibimize task açınız.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_FUNDS: "Bakiyeniz yok. Lütfen satış ekibimiz ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "İşleminiz sırasında bir hata aldınız.   Ödemeniz tahsil edilememiştir.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_UNSUFFICIENT_FUNDS: "Kullanılabilir bakiyeniz yetersizdir.  Lütfen ekibimiz ile iletişime geçiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_RETRY_RESERVATION_PRICE_CHECK: "İşleminiz sırasında hata oluşmuştur.  Yeniden arama yaparak işleminize devam edebilirsiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_SESSION_INVALID_AGENCY: "Biletleme yapabilmek için lütfen acente seçiniz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_SUCCESS_PAYMENT_POST: "İşleminiz başarılı bir şekilde gerçekleştirilmiştir.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_TIMEOUT_EXPIRED: "Ödeme işleminiz zaman aşımına uğradı.  Lütfen tekrar deneyiniz.",
        RESPONSE_AGENT_API_HOTEL_CHECKOUT_EXPIRED_BASKET: "Fiyat bilgisi değişmiş olabilir.  Lütfen yeniden arama yaparak işleminize devam ediniz.",
        RESPONSE_AGENT_API_HOTEL_CHECKOUT_REJECTED_CHECKOUT_NOT_AVAILABLE: "Uzun süredir işlem yapmadığınız için fiyatlar güncellenmiş olabilir.Aramanızı yenileyerek işleminize devam edebilirsiniz.",
        RESPONSE_AGENT_API_HOTEL_DETAIL_EXPIRED_HOTEL_SEARCH: "Otel detayı görüntülenemiyor. Lütfen sayfayı yenileyerek tekrar deneyiniz ya da Service Center ekibimizden destek talep ediniz.",
        RESPONSE_AGENT_API_HOTEL_REVERSE_FAILURE_VOUCHER_CANT_RETRIEVED: "Rezervasyonunuzun iptali için lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_HOTEL_SEARCH_PARAMETER_INVALID_DESTINATION: "Lütfen arama kutucuğunu doldurarak gelen listeden bir seçim yapınız.",
        RESPONSE_AGENT_API_HOTEL_SEARCH_SESSION_INVALID_AGENCY: "Lütfen acente seçiniz.",
        RESPONSE_AGENT_API_HOTEL_SELECT_EXPIRED_HOTEL_DETAIL: "Fiyat bilgisi değişmiş olabilir.  Lütfen yeniden arama yaparak işleminize devam ediniz.",
        "RESPONSE_AGENT_API_IAgtBllSchmetterlingService.ShowOrder_FAILURE_NULL_LIST_RESPONSE": "Bu tarihlerde paket satışı bulunmamaktadır. Tarihleri kontrol edip, tekrar deneyiniz.",
        RESPONSE_AGENT_API_LOGIN_AUTHORIZATION_INVALID_CREDENTIALS: "Lütfen girdiğiniz bilgilerin doğruluğundan emin olunuz.",
        RESPONSE_AGENT_API_LOGIN_AUTHORIZATION_USER_BLOCKED: "Hatalı giriş bilgileri sebebi ile kullanıcınız bloke edilmiştir. Şifrenizi değiştirerek yeniden giriş yapabilirsiniz.",
        RESPONSE_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "Lütfen girdiğiniz bilgilerin doğruluğundan emin olunuz.",
        RESPONSE_AGENT_API_LOGIN_PARAMETER_INVALID_DOMAIN_NAME: "Giriş yapmaya çalıştığınız kullanıcı bilgileri bu web sitesine ait değildir. Lütfen sayfanızın linkini kontrol ediniz.",
        RESPONSE_AGENT_API_ORDER_DETAIL_AUTHORIZATION_NOT_OWNER: "Bu orderı görüntüleme yetkiniz bulunmamaktadır.",
        RESPONSE_AGENT_API_PWD_RESET_REJECTED_INVALID_DOMAIN_NAME: "Bu link üzerinden şifre sıfırlama yapamazsınız. Lütfen login bilgilerinizi ve URL'inizi kontrol ediniz.",
        RESPONSE_AGENT_API_PWD_RESET_REJECTED_INVALID_USER: "Lütfen geçerli bir mail giriniz.",
        RESPONSE_AGENT_API_RENT_A_CAR_BOOK_REJECTED_INSUFFICIENT_PAYMENT: "İşleminiz sırasında bir hata aldınız.Ödemeniz tahsil edilememiştir.",
        RESPONSE_AGENT_API_RENT_A_CAR_BOOK_REJECTED_PAYMENT_POST: "İşleminiz sırasında bir hata aldınız.Ödemeniz tahsil edilememiştir.",
        RESPONSE_AGENT_API_RENT_A_CAR_REVERSE_FAILURE_FAILURE: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_RENT_A_CAR_SEARCH_PARAMETER_INVALID_DATES: "Lütfen tarihleri kontrol ediniz.",
        RESPONSE_AGENT_API_TAKE_NOTE_PARAMETER_INVALID_NOTE: "Bu alan boş bırakılamaz.",
        RESPONSE_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE: "Talebiniz daha önce Service Center ekibimize iletilmiştir.  Lütfen talebinizi Service Center üzerinden takip ediniz.",
        RESPONSE_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE_TITLE: "Bilgi",
        RESPONSE_AGENT_API_TASK_CREATE_SUCCESS_OK: "Talebiniz başarılı bir şekilde ekibimize iletilmiştir. Talebinizi Service Center üzerinden takip edebilirsiniz.",
        RESPONSE_AGENT_API_TICKET_EXCEPTION: "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_AGENT_API_TICKET_EXPIRED: "Fiyat bilgisi değişmiş olabilir.  Lütfen yeniden arama yaparak işleminize devam ediniz.",
        RESPONSE_AGENT_API_TICKET_FAILURE: "İşleminiz sırasında fiyat farkı oluşmuştur.  Fiyat Farkını Kabul Ediyor Musunuz ?",
        RESPONSE_AGENT_API_TICKET_FAILURE_PRICE_DIFFERENCE: "Biletleme işleminiz sırasında fiyat farkı oluşmuştur.  Fiyat Farkı :",
        RESPONSE_AGENT_API_TICKET_FAILURE_PRICE_DIFFERENCE_TITLE: "Fiyat Farkı",
        RESPONSE_AGENT_API_TICKET_HOTEL_REJECTED_INSUFFICIENT_PAYMENT: "İşleminiz sırasında bir hata aldınız. Ödemeniz tahsil edilememiştir.",
        RESPONSE_AGENT_API_TICKET_PARAMETER: "Aramanız geçerli değil.  Lütfen arama kriterlerinizi kontrol ederek yeniden deneyiniz.",
        RESPONSE_AGENT_API_TICKET_PRICE_DIFFERENCE: "Fiyat Farkını Kabul Ediyor Musunuz ?",
        RESPONSE_AGENT_API_TICKET_PRICE_DIFFERENCE_TITLE: "Fiyat Farkı",
        RESPONSE_AGENT_API_TICKET_REJECTED_PNR_STATUS: "Pnr durumunuz değişmiştir. Ekranınızı yenileyerek işleme lütfen devam ediniz.",
        RESPONSE_AGENT_API_TICKET_RETRY: "İşleminiz sırasında hata oluşmuştur.  Yeniden arama yaparak işleminize devam edebilirsiniz.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_FAILURE_PRICE_DIFFERENCE: "İşleminiz sırasında fiyat farkı oluşmuştur.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_FAILURE_REJECTED: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_CHANGE_DETECTED: "Uçuş detaylarınızda değişiklik vardır.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_INSUFFICIENT_FUNDS: "Bakiyeniz yok. Lütfen satış ekibimiz ile iletişime geçiniz.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_INSUFFICIENT_PAYMENT: "İşleminiz sırasında bir hata aldınız.  Ödemeniz tahsil edilememiştir.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_PNR_EXPIRED: "Son satın alma zamanı geçti. Service Center ile iletişime geçerek manuel kontrol sağlayabilirsiniz.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_SUCCESS_OK: "Biletleme işleminiz başarılı.",
        RESPONSE_AGENT_API_TICKETRESERVED_HOTEL_REJECTED_RETRIEVE_: "Tedarikçi tarafından yanıt alınamamıştır. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_AGENT_API_UNKNOWN_REJECTED_HOTEL_CHECK_AVAILABILITY: "Bilinmeyen bir hata ile karşılaştık. Lütfen Service Center ekibimizle iletişime geçiniz.",
        RESPONSE_AGENT_PI_FLIGHT_TICKET_REJECTED_IMPORT_TYPE_MANUAL: "Biletinize manuel işlem yapılması sebebi ile biletleme işleminizi şuan için gerçekleştiremiyoruz.  Lütfen Service Center ekibimize task açınız.",
        RESPONSE_AGENT_WEB_FLIGHT_CHECKOUT_TIMEOUT_SERVICE_TIMEOUT: "Fiyat bilgisi değişmiş olabilir.  Lütfen yeniden arama yaparak işleminize devam ediniz.",
        "RESPONSE_FLIGHT.API.FLIGHT.RESERVATION.FAILURE.INVALID.PARENT.AGE": "Yetişkin yaşı, bebek ile seyahat için uygun değildir.",
        "RESPONSE_FLIGHT.API.FLIGHT.TICKET.FAILURE.INVALID.PARENT.AGE": "Yetişkin yaşı, bebek ile seyahat için uygun değildir.",
        "RESPONSE_FLIGHT_API _FLIGHT_SSR_TICKET_FAILURE_REQ_SSR_UNAVAILABLE": "Bu uçakta seçmiş olduğunuz tekerlekli sandalye için müsaitlik bulunmamaktadır.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.INVALID_AGE_FOR_PAX_TYPE": "Lütfen girmiş olduğunuz doğum tarihlerini kontrol ediniz.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.INVALID_IDENTITY_INFO": "Lütfen girmiş olduğunuz yolcu bilgilerini kontrol ediniz.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.UNMAPPED_ERROR": "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        "RESPONSE_FLIGHT_API.FLIGHT_REVERSE.FAILURE.UNMAPPED_ERROR": "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_EMPTY_SERVICE_RESPONSE: "Havayolu tarafından yanıt alınamamıştır. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_EMPTY_SERVICE_RESPONSE_TITLE: "Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_REJECTED: "Havayolu tarafından yanıt alınamamıştır. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_REJECTED_TITLE: "Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_UNMAPPED_ERROR: "Havayolu tarafından yanıt alınamamıştır. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_UNMAPPED_ERROR_TITLE: "Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_REJECTED_NOT_IMPLEMANTED_SERVICE: "Bagaj satın almak için lütfen Service Center ile görüşünüz.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_REJECTED_NOT_IMPLEMANTED_SERVICE_TITLE: "Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_COMMON_ERROR: "Uçuş bilgileri sayfasına geçerken bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_REQUESTED_CLASS_NOT_AVAILABLE: "Seçtiğiniz sınıf için fiyat bilgisi geçerli değildir. Lütfen yeniden arama yaparak işleminize devam ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNKNOWN_ERROR: "Uçuş bilgileri sayfasına geçerken bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "Uçuş bilgileri sayfasına geçerken bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_NOT_EXISTS_PACKAGE_NOT_EXIST: "Seçtiğiniz uçuş için kabin bilgisi bulunamamıştır.",
        RESPONSE_FLIGHT_API_FLIGHT_DIVIDE_PNR_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Rezervasyon bilgileriniz değişmiştir. Otomatik işlem yapılamamaktadır. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_COMMON_ERROR: "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR: "Bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR_TITLE: "Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNMAPPED_ERROR: "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_COMMON_ERROR: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_UNKNOWN_ERROR: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_UNMAPPED_ERROR: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_COMMON_ERROR: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_UNKNOWN_ERROR: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_UNMAPPED_ERROR: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_COMMON_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_CONTINUE_WITH_INSTANT_TICKETING: "Havayolu rezervasyon yapmamaktadır. Lütfen direk satın almayı deneyiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_DUBLICATE_PASSENGER: "İşlem yapmak istediğiniz detaylarda daha önce biletiniz veya rezervasyonunuz olabilir. Lütfen Service Center ekibimizden kontrol sağlayınız.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_EMPTY_SERVICE_RESPONSE: "Havayolu tarafından yanıt alınamamıştır. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_EXCEEDED_MAX_PAX: "Havayolu girilen yolcu sayısını kabul etmemektedir. Lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_HES_CODE_DECLINED: "Hes kod geçersiz. Lütfen hes kodunuzu kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_IDENTITY_OR_PASSPORT_NUMBER_MANDATORY: "T.C  Kimlik Bilgileri veya Pasaport bilgisi zorunludur. Lütfen istenilen bilgileri giriniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_AGE_FOR_PAX_TYPE: "Lütfen girmiş olduğunuz doğum tarihlerini kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_CONTACT_INFO: "Lütfen girmiş olduğunuz iletişim bilgilerini kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_IDENTITY_INFO: "Lütfen girmiş olduğunuz yolcu bilgilerini kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_MINIMUM_CONNECTION_TIME: "Minimum bağlantı süresi aşıldı. Lütfen uçuşlarınızı kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_NOT_ABLE_TO_VERIFY_LOYALTY_CARD: "Mil Numaranızı ve Kimlik Bilgilerinizi lütfen doğru giriniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_PRICE_CHANGED: "Fiyat bilgisi değişmiş olabilir.  Lütfen yeniden arama yaparak işleminize devam ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_REQUESTED_CLASS_NOT_AVAILABLE: "Talep edilen uçuş ve klas için yer kalmadı.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SELECTED_FLIGHT_NOT_AVAILABLE_FOR_BOOKING: "Rezervasyon yapılamaz.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SERVICE_ERROR: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık.<br> Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SSR_LIMIT_EXCEEDED: "Havayolu tarafında eklenen ssr limiti aşıldı.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_UNKNOWN_ERROR: "Rezervasyon sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_UNMAPPED_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_WAIT_LIST_CLOSED: "Talep edilen uçuş ve sınıf için yer kalmadı.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_COMMON_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_INVALID_PASSENGER_INFO: "Lütfen girmiş olduğunuz yolcu bilgilerini kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_UNKNOWN_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_UNMAPPED_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_REJECTED_CONTINUE_WITH_INSTANT_TICKETING: "Havayolu rezervasyon yapmamaktadır. Lütfen direk satın almayı deneyiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_REJECTED_MINIMUM_CONNECTION_TIME: "Lütfen uçuş saatlerinizi kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_COMMON_ERROR: "Bilinmeyen bir hata ile karşılaştık. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_EMPTY_SERVICE_RESPONSE: "Havayolu tarafından yanıt alınamamıştır. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_INVALID_PASSENGER_INFO: "Yolcu bilgilerinizde değişiklik vardır. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_SERVICE_ERROR: "Bağlantı hatası aldınız.Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNKNOWN_ERROR: "Bilinmeyen bir hata ile karşılaştık. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNKNOWN_ERROR_TITLE: "Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNMAPPED_ERROR: "Bilinmeyen bir hata ile karşılaştık. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_EXPIRED_REVERSE_INQUIRY_EXPIRED: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_EMPTY_SERVICE_RESPONSE: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_REJECTED: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_REVERSE_TYPE_CHANGED: "Biletinize manuel işlem yapılması sebebi ile işleminizi şuan için gerçekleştiremiyoruz.  Lütfen Service Center ekibimize task açınız.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_UNKNOWN_ERROR: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_UNMAPPED_ERROR: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_CONNECTION_UNKNOWN_ERROR: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_EMPTY_SERVICE_RESPONSE: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Rezervasyon bilgileriniz değişmiştir. Otomatik işlem yapılamamaktadır. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_SERVICE_ERROR: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_UNMAPPED_ERROR: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_REJECTED_UNKNOWN_ERROR: "Bilinmeyen bir hata ile karşılaştık. Biletinizin statüsü için lütfen Service Center ekibimiz ile görüşünüz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_REJECTED_NOT_IMPLEMANTED_SERVICE: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_REJECTED_UNKNOWN_ERROR: "İptal işlemi sırasında hata aldınız.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Rezervasyon bilgileriniz değişmiştir. Otomatik işlem yapılamamaktadır. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_SEARCH_CONNECTION_UNKNOWN_ERROR: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_CONNECTION_UNKNOWN_ERROR: "Bilinmeyen bir hata ile karşılaştık. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_COMMON_ERROR: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INSUFFICIENT_STOCK: "Tedarikçiden kaynaklı bir hata aldınız.  Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INVALID_CONTACT_INFO: "Lütfen girmiş olduğunuz iletişim bilgilerini kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INVALID_PASSENGER_INFO: "Lütfen girmiş olduğunuz yolcu bilgilerini kontrol ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_PNR_HAS_BEEN_MODIFIED: "Pnr durumunuz değişmiş. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATED_FLIGHT_CHANGED: "Uçuş detaylarınızda değişiklik vardır.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATED_PASSENGER_INFO_CHANGED: "Yolcu bilgilerinizde değişiklik vardır. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Rezervasyon bilgileriniz değişmiştir. Otomatik işlem yapılamamaktadır. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNKNOWN_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNMAPPED_ERROR: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_REJECTED_REQUESTED_CLASS_NOT_AVAILABLE: "Seçtiğiniz sınıf için fiyat bilgisi geçerli değildir. Lütfen yeniden arama yaparak işleminize devam ediniz.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_TIMEOUT_SERVICE_TIMEOUT: "Ödeme işleminiz zaman aşımına uğradı.  Lütfen tekrar deneyiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_FLIGHT_SCHEDULE_CHANGED: "Uçuş detaylarınızda değişiklik vardır.  Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_FLIGHT_SCHEDULE_CHANGED_TITLE: "Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_REISSUE_NOT_AVAIL: "Uçuşunuza online değişiklik yapılamaz. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_REISSUE_NOT_AVAIL_TITLE: "Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNKNOWN_ERROR: "Uçuş bilgileriniz veya yolcu bilgileriniz havayolu ile eşleşmemektedir. Lütfen Service Center ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNKNOWN_ERROR_TITLE: "Önemli Bilgi",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNMAPPED_ERROR: "Değişiklik sorgusunda bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNMAPPED_ERROR_TITLE: "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_REJECTED_NOT_IMPLEMANTED_SERVICE: "Değişiklik yapabilmek için lütfen Service Center ile görüşünüz.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_REJECTED_NOT_IMPLEMANTED_SERVICE_TITLE: "Bilgi",
        "RESPONSE_FLIGHT_API_TICKET_EXCEPTION,": "Bilinmeyen bir hata ile karşılaştık.",
        "RESPONSE_FLIGHT_API_TICKET_FAILURE,_COMMON_ERROR,": "Biletleme sırasında bilinmeyen bir hata ile karşılaştık.",
        "RESPONSE_FLIGHT_API_TICKET_FAILURE,_UNKNOWN_ERROR,": "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_TICKET_FAILURE_DUBLICATE_PASSENGER: "İşlem yapmak istediğiniz detaylarda daha önce biletiniz veya rezervasyonunuz olabilir. Lütfen Service Center ekibimizden kontrol sağlayınız.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_COMMON_ERROR: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık.<br> Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_INSUFFICIENT_STOCK: "Tedarikçiden kaynaklı bir hata aldınız.  Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_UNKNOWN_ERROR: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık.<br> Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_UNMAPPED_ERROR: "Biletleme sırasında bilinmeyen bir hata ile karşılaştık.<br> Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_COMMON_ERROR: "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_UNKNOWN_ERROR: "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_UNMAPPED_ERROR: "Bilinmeyen bir hata ile karşılaştık.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_COMMON_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_UNKNOWN_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_UNMAPPED_ERROR: "Biletleme sırasında bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        "RESPONSE_HOTEL_API.TICKET.EXCEPTION.MrCannotStay": "Bay konaklamalar bu otel tarafından kabul edilmiyor. Lütfen sayfayı kapatıp yeniden arama yapınız.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_[holder.phone] allows digit only": "Lütfen iletişim bilgileri kısmını rakamları kullanarak tekrar doldurunuz.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_error.hotel.not.accepts.only.man": "Bay konaklamalar bu otel tarafından kabul edilmiyor. Lütfen sayfayı kapatıp yeniden arama yapınız.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_error.no.enough.room.quota": "İşleminiz yürütülürken oda kotası tükenmiştir. Lütfen sayfayı kapatıp yeniden arama yapınız ya da Service Center ekibimizle iletişime geçiniz.",
        RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_ExistingOfferId: "Lütfen sayfayı kapatıp aramanızı yenileyiniz ve tekrar oluşturunuz.",
        RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_MrCannotStay: "Bay konaklamalar bu otel tarafından kabul edilmiyor. Lütfen sayfayı kapatıp yeniden arama yapınız.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_REJECTED_MR.STAY": "Bay konaklamalar bu otel tarafından kabul edilmiyor.",
        "RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_ExistingOfferId_This offerid already requested for booking": "Lütfen sayfayı kapatıp aramanızı yenileyiniz ve tekrar oluşturunuz.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_GTS_BOOKING: "İşleminiz yürütülürken oda kotası tükenmiştir. Lütfen sayfayı kapatıp yeniden arama yapınız ya da Service Center ekibimizle iletişime geçiniz.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_NO_ROOM_AVAILABLE: "İşleminiz yürütülürken oda kotası tükenmiştir. Lütfen sayfayı kapatıp yeniden arama yapınız ya da Service Center ekibimizle iletişime geçiniz.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_ROOM_HAS_STOP: "İşleminiz yürütülürken oda kotası tükenmiştir. Lütfen sayfayı kapatıp yeniden arama yapınız ya da Service Center ekibimizle iletişime geçiniz.",
        "RESPONSE_HOTEL_API_TICKET_HOTEL_REJECTED_MR.STAY": "Talep ettiğiniz <strong>konaklama tipi</strong>  bu otel için uygun değildir. Lütfen, Service Center ile iletişime geçiniz.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_SUCCESS_OK: "İşleminiz başarılı bir şekilde gerçekleştirilmiştir.",
        RESPONSE_PRICE_CHANGE: "İşleminiz sırasında fiyat farkı oluşmuştur.",
        RESPONSE_REJECTED_NOT_AVAILABLE: "İlgili uçuş için havayolu değişiklik yapmaya izin vermemektedir.",
        RESPONSE_SUCCESS_OK: "Mail başarılı bir şekilde gönderilmiştir.",
        RESPONSE_TICKET_HOTEL_EXCEPTION_JP_BOOK_OCCUPANCY_NAMES_ERROR: "Misafir isimleri aynı olamaz. Lütfen kontrol edip tekrar deneyiniz.",
        RESPONSE_TICKET_TRANSFER_SUCCESS_OK: "Transferinizi başarılı bir şekilde satın aldınız. Lütfen işleminizi kontrol ediniz.",
        RESPONSE_TRANSFER_API_TICKET_TRANSFER_ISSUED_OK: "Transferinizi başarılı bir şekilde satın aldınız. Lütfen işleminizi kontrol ediniz.",
        RESULT_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR: "Bilinmeyen bir hata ile karşılaştık. Lütfen Service Center ekibimiz ile iletişime geçiniz.",
        RESULT_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_REJECTED: "Rezervasyon sırasında bilinmeyen bir hata ile karşılaştık. Hatanın sebebi için lütfen Service Center Ekibimiz ile iletişime geçiniz.",
        roomCard_lblRoomPrice: "Oda Fiyatı",
        roomCardComponent_roomDetail_buttonLabel: "Oda Detayı",
        roomCardComponent_roomPriceLabel: "Oda Fiyatı",
        router_agencyRequired: "Bu sayfaya yalnızca acente erişebilir.",
        router_chooseAgency: "Lütfen acente seçiniz.",
        router_contactForAuth: "Bir hata olduğunu düşünüyorsanız, lütfen yetkililerle iletişime geçiniz!",
        router_noAuth: "Bu sayfaya erişmek için yetkiniz yok!",
        router_noInvalidOtp: "Bilgi",
        router_noInvalidOtpNote: "Lütfen tokenınızı kontrol ediniz.",
        router_titleAccTransactionList: "Hesap Hareketleri",
        router_titleBalanceUpdate: "Bakiye Güncelleme",
        router_titleDashboard: "Pano",
        router_titleDashboard2: "Pano",
        router_titleFlightCheckout: "Uçak Satın Alma",
        router_titleFlightSale: "AGENT",
        router_titleFlightSalesReport: "Uçuş Satış Raporu",
        router_titleHotelCache: "Hotel Cache Search",
        router_titleHotelCheckout: "Satın Alma",
        router_titleHotelSale: "AGENT",
        router_titleHotelSalesReport: "Otel Satış Raporu",
        router_titleInvoiceList: "Fatura Listesi",
        router_titleLogin: "Giriş Yap",
        router_titleNotification: "Bildirim",
        router_titleOrderDetail: "Order Detay",
        router_titleOrderSearch: "Order Arama",
        router_titlePackageSearch: "Paketler",
        router_titlePageNotFound: "Sayfa Bulunamadı",
        router_titlePnrDoc: "PNR Döküman",
        router_titleRegister: "Kayıt Ol",
        router_titleRentACar: "Araç Kiralama",
        router_titleRentACarCheckut: "Rent A Car Checkout",
        router_titleRentACarSalesReport: "Araç Kiralama Satış Raporu",
        router_titleSchmetterlingSalesReport: "Paket Satış Raporu",
        router_titleSettings: "Ayarlar",
        router_titleTaskList: "Task Listesi",
        router_titleTestDev: "TestDev",
        router_titleTransferCheckout: "Transfer Checkout",
        router_titleTransferSale: "Transfer Alma",
        router_titleTransferSalesReport: "Transfer Satış Raporu",
        router_titleVersion: "Versiyon",
        saleReport_airlineList: "Havayolu",
        saleReport_creationFromDate: "Başlangıç Tarihi",
        saleReport_creationToDate: "Bitiş Tarihi",
        saleReport_optEticket: "E-Bilet",
        saleReport_optPNR: "Pnr",
        saleReport_pnrStatus: "Pnr Durumu",
        saleReport_providerList: "Tedarikçi Listesi",
        saleReport_reportType: "Rapor Tipi",
        saleReport_showReissues: "Değişiklikleri Göster",
        saleReport_userList: "Kullanıcı Listesi",
        schmetterlingSalesReport_filter_btnList: "Listele",
        schmetterlingSalesReport_filter_lblCustomerName: "Müşteri Adı",
        schmetterlingSalesReport_filter_lblCustomerSurname: "Müşteri Soyadı",
        schmetterlingSalesReport_filter_lblFromDate: "Başlangıç Tarihi",
        schmetterlingSalesReport_filter_lblReservationNo: "Schmetterling ID",
        schmetterlingSalesReport_filter_lblStatus: "Durum",
        schmetterlingSalesReport_filter_lblToDate: "Bitiş Tarihi",
        schmetterlingSalesReport_filter_plhCustomerName: "Müşteri Adı",
        schmetterlingSalesReport_filter_plhCustomerSurname: "Müşteri Soyadı",
        schmetterlingSalesReport_filter_plhReservationNo: "Schmetterling ID",
        schmetterlingSalesReport_tblMain_thCheckInDateTime: "Giriş Tarihi",
        schmetterlingSalesReport_tblMain_thCheckOutDateTime: "Çıkış Tarihi",
        schmetterlingSalesReport_tblMain_thCustomerNameSurname: "Müşteri Adı Soyadı",
        schmetterlingSalesReport_tblMain_thOrderId: "Order ID",
        schmetterlingSalesReport_tblMain_thReservationNo: "Schmetterling ID",
        schmetterlingSalesReport_tblMain_thStatus: "Durum",
        schmetterlingSalesReport_tblMain_thTotalPrice: "Toplam Ücret",
        schmetterlingSalesReport_tblMain_thTourOperator: "Tur Operatörü",
        schmetterlingSalesReport_tblMain_thTravelType: "Seyahat Türü",
        schmetterlingSalesReport_tblMain_thUser: "Rezervasyonu Oluşturan Kullanıcı ID",
        schmetterlingSalesReport_title: "Paket Satış Raporu",
        Search_Response_AGENT_API_FLIGHT_SEARCH_SESSION_INVALID_AGENCY: "Lütfen acente seçiniz.",
        Search_Response_AGENT_WEB_FLIGHT_SEARCH_TIMEOUT_SERVICE_TIMEOUT: "İşleminiz sırasında hata oluşmuştur.  Yeniden arama yaparak işleminize devam edebilirsiniz.",
        Search_Response_FLIGHT_API_FLIGHT_SEARCH_CONNECTION_UNKNOWN_ERROR: "Uçuş arama sırasında bağlantı hatası aldınız.",
        searchComponentFlight_addLeg: "Uçuş Ekle",
        searchComponentFlight_airlines: "Havayolu",
        searchComponentFlight_airlinesSelected: "Havayolu Seçili",
        searchComponentFlight_autocompleteArrival: "Nereye",
        searchComponentFlight_autocompleteDeparture: "Nereden",
        searchComponentFlight_cabinTypes: "Kabin Tipi",
        searchComponentFlight_cheapestPrice: "En Düşük Paket Fiyatı",
        searchComponentFlight_departureDate: "Gidiş Tarihi",
        searchComponentFlight_filterAirlines: "Havayollarını Filtrele",
        searchComponentFlight_flightRules: "Uçuş Kuralları",
        searchComponentFlight_lastSeat: "Son Koltuk",
        searchComponentFlight_paxes: "Yolcular",
        searchComponentFlight_removeLeg: "Uçuş Çıkar",
        searchComponentFlight_returnDate: "Dönüş Tarihi",
        searchComponentFlight_searchFlight: "UÇUŞ ARA",
        searchComponentFlight_stops: "Aktarma",
        searchComponentFlight_travelDuration: "Seyahat Süresi",
        searchComponentFlight_waitingTime: "Bekleme Süresi",
        searchComponentHotel_autocompleteLocationPlaceholder: "Otel, Bölge veya Şehir Adı",
        searchComponentHotel_dateLabel: "Giriş - Çıkış Tarihi",
        searchComponentHotel_destinationLabel: "Otel, Bölge veya Şehir Adı",
        searchComponentHotel_guestLabel: "Misafir Sayısı",
        searchComponentHotel_nationalityLabel: "Çıkış Ülkesi",
        searchFiltersFlight_airlines: "Havayolu",
        searchFiltersFlight_airlineTransfer: "Havalimanı Aktarma",
        searchFiltersFlight_arrivalHour: "Gidiş Varış",
        searchFiltersFlight_baggageAllowance: "Bagaj Hakkı",
        searchFiltersFlight_business: "Business",
        searchFiltersFlight_cabinTypes: "Kabin Tipi",
        searchFiltersFlight_connections: "Aktarmalar",
        searchFiltersFlight_departureHour: "Gidiş Kalkış",
        searchFiltersFlight_deselectAll: "Temizle",
        searchFiltersFlight_economy: "Ekonomi",
        searchFiltersFlight_EFltConnectionType_airlineTransfer: "Havalimanı Aktarma",
        searchFiltersFlight_EFltConnectionType_noneStop: "Aktarmasız",
        searchFiltersFlight_EFltConnectionType_stop: "Aktarma",
        searchFiltersFlight_EFltConnectionType_UNKNOWN: "Tanımsız",
        searchFiltersFlight_noBaggage: "Bagajsız",
        searchFiltersFlight_noneStop: "Aktarmasız",
        searchFiltersFlight_selectAll: "Hepsini Seç",
        searchFiltersFlight_stop: "Aktarma",
        searchFiltersFlight_time: "Kalkış / Varış Saatleri",
        searchFiltersFlight_withBaggage: "Bagajlı",
        searchFiltersHotel_deselectAll: "Temizle",
        searchFiltersHotel_lblCustomPriceRange: "Fiyat Aralığı (Toplam Tutar)",
        SearchInformationPopupFlight_allAirports: "Tüm Havalimanları",
        SearchInformationPopupFlight_text: "Uçuşlar hazırlanıyor, lütfen bekleyiniz.",
        SearchOrderList_lblBrandPnr: "Havayolu Pnr",
        seatMapModal_lblYanyana: "Yan Yana",
        SeatMapModal_progressGif: "İşleminiz devam ediyor. Lütfen bekleyiniz.",
        serviceCenter_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE: "Talebiniz daha önce Service Center ekibimize iletilmiştir.Lütfen talebinizi Service Center üzerinden takip ediniz.",
        serviceCenter_AGENT_API_TASK_CREATE_SUCCESS_OK: "Talebiniz başarılı bir şekilde ekibimize iletilmiştir. Talebinizi Service Center üzerinden takip edebilirsiniz.",
        serviceCenter_ancillary: "Ek Hizmetler",
        serviceCenter_cancellationRefund: "İptal - İade",
        serviceCenter_openTicket: "Açık Bilet",
        serviceCenter_reissue: "Değişiklik",
        serviceCenterComponent_tabChangeDetails_label: "Uçuş Değişiklik",
        serviceCenterComponent_tabMyNotes_label: "Kendi Notlarım",
        serviceCenterComponent_tabServiceCenterRequestForm_label: "Service Center Talep Formu",
        serviceCenterComponent_tabTaskChat_label: "Task Detayları",
        serviceCenterRequestForm_btnSend: "Gönder",
        serviceCenterRequestForm_popupTaskCreate: "Talebiniz başarılı bir şekilde ekibimize iletilmiştir. Talebinizi Service Center üzerinden takip edebilirsiniz.",
        serviceCenterRequestForm_taskOpeningInfos: "TASKTYPE talebiniz SELECTION için işleme alınacaktır",
        Set_Customer_SUCCESS_OK: "Başarılı",
        setting_profileSettings: "Profil Ayarları",
        settings_customerManagement: "Müşteri İşlemleri",
        settings_customerSettings: "Müşteri İşlemleri",
        settings_profileSettings: "Profil Ayarları",
        settings_userAuthorization: "Kullanıcı Yetkileri",
        settings_userAuths: "Kullanıcı Yetkileri",
        settings_userSettings: "Kullanıcı Ayarları",
        settingsPage_customerBookSubPage_newCustomerAddButtonText: "Yeni Müşteri Ekle",
        settingsPage_customerBookSubPage_pageTitle: "Müşteri İşlemleri",
        settingsPage_lblRegionLanguagePreferencesDescription: "Acentenize ait bilgiler.",
        settingsPage_navMenu_customerSettings: "Müşteri İşlemleri",
        settingsPage_navMenu_profileSettings: "Profil Ayarları",
        settingsPage_navMenu_userRoles: "Kullanıcı Yetkileri",
        settingsPage_navMenu_userSettings: "Kullanıcı Ayarları",
        settingsPage_profileSettingsSubPage_agencyInformationDescription: "Acentenize ait bilgiler.",
        settingsPage_profileSettingsSubPage_agencyInformationLabel: "Acente Bilgileri",
        settingsPage_profileSettingsSubPage_browseProfilePhotoButtonText: "Galeriden Seç",
        settingsPage_profileSettingsSubPage_dragDropFileText: "Dosyayı Sürükle Bırak veya",
        settingsPage_profileSettingsSubPage_pageTitle: "Profili Düzenle",
        settingsPage_profileSettingsSubPage_profilePhotoDescription: "Yükleme yapmak istediğiniz fotoğraf boyutu aşağıdaki gibi olmalıdır.",
        settingsPage_profileSettingsSubPage_profilePhotoLabel: "Profil Fotoğrafı",
        settingsPage_profileSettingsSubPage_regionAndLanguagePreferencesDescription: "Acentenize ait bilgiler.",
        settingsPage_profileSettingsSubPage_regionAndLanguagePreferencesLabel: "Bölge ve Telefon Tercihleri",
        settingsPage_profileSettingsSubPage_userInformationDescription: "Kullanıcınıza ait bilgiler.",
        settingsPage_profileSettingsSubPage_userInformationLabel: "Kullanıcı Bilgileri",
        settingsPage_userAuthorizationSubPage_pageTitle: "Kullanıcı Yetkileri",
        settingsPage_userSettingsSubPage_pageTitle: "Kullanıcı Ayarları",
        SignIn_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "Lütfen girdiğiniz bilgilerin doğruluğundan emin olunuz.",
        SignIn_CookieText: "Size web sitemizde mümkün olan en iyi deneyimi sunduğumuzdan emin olmak için, çerezleri kişisel veri politikamıza uygun olarak kullanıyoruz.",
        SignIn_emailIsValidating: "Kontrol Ediliyor",
        SignIn_forgotPassword: "Şifremi Unuttum!",
        SignIn_legalText: "Kaydolarak şunları kabul etmiş olursunuz",
        SignIn_legalText2: ",",
        SignIn_legalText3: "ve",
        SignIn_legalTextCookieUse: "Çerez Kullanımı.",
        SignIn_legalTextPrivacyPolicy: "Gizlilik Politikası",
        SignIn_legalTextTermsOfService: "Kullanım Şartları",
        SignIn_loginHeader1: "Hoşgeldiniz",
        SignIn_loginHeader2: "Lütfen sisteme giriş yapınız.",
        SignIn_signInBtn: "Giriş Yap",
        SignIn_TITLE_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "Bir Hata Oluştu.",
        SignIn_userEmail: "Kullanıcı E-mail",
        SignIn_userPassword: "Kullanıcı Şifresi",
        ssrSelection_lblSsrTitle: "Seçili Brand:",
        ssrSelectionBox_lblBAGGAGEDescription: "Mevcut seçiminize ekstra bagaj eklenir.",
        ssrSelectionBox_lblbaggageTitle: "Extra Bagaj",
        ssrSelectionBox_lblCABIN_BAGDescription: "1 Parça Kabin Bagajı",
        ssrSelectionBox_lblcabin_bagTitle: "Kabin Bagajı",
        ssrSelectionBox_lblcheckin_at_airportTitle: "Havalimanı Check-in",
        ssrSelectionBox_lblreissue_allowanceTitle: "Sun Flex 3",
        ssrSelectionBox_lblwheelchairTitle: "Tekerlekli Sandalye",
        targetAchievement_lblConfetti: "Tebrikler!",
        targetAchievement_lblInformaton: ".",
        targetAchievement_lblTarget: "Hedef",
        targetAchievement_lblTitle: "Teşvik",
        taskDetails_openingNote: "Açılış Notu",
        taskDetails_subStatus: "Alt Durum",
        taskDetails_taskOpenTime: "Task Açılış Zamanı",
        taskDetails_type: "Tip",
        taskList_filter_btnList: "Listele",
        taskList_filter_lblFromDate: "Başlangıç Tarihi",
        taskList_filter_lblStatus: "Durum",
        taskList_filter_lblToDate: "Bitiş Tarihi",
        taskList_filter_lblType: "Task Türü",
        taskList_tblMain_thAgency: "Acente İsmi",
        taskList_tblMain_thClosingType: "Kapanış Tipi",
        taskList_tblMain_thCreatedUser: "Task Açan Kullanıcı",
        taskList_tblMain_thEntity: "Ürün",
        taskList_tblMain_thEntityType: "Ürün Tipi",
        taskList_tblMain_thGsa: "Gsa",
        taskList_tblMain_thId: "Task Id",
        taskList_tblMain_thNote: "Açılış Notu",
        taskList_tblMain_thOrderId: "Order ID",
        taskList_tblMain_thOrderStatus: "Order Durumu",
        taskList_tblMain_thStatus: "Task Durumu",
        taskList_tblMain_thSubStatus: "Alt Durum",
        taskList_tblMain_thTaskStatus: "Task Durumu",
        taskList_tblMain_thType: "Task Türü",
        taskList_tblMain_thUpdatedDateTime: "Son Güncelleme Zamanı",
        "TaskList_tblTaskMng.thETaskClosingType": "Kapanış Tipi",
        "TaskList_tblTaskMng.thETaskSubStatus": "Alt Durum",
        "TaskList_tblTaskMng.thETaskType": "Task Türü",
        taskList_title: "Service Center",
        taskListPage_pageTitle: "Service Center",
        taskListPage_searchForm_endDateLabel: "Bitiş Tarihi:",
        taskListPage_searchForm_startDateLabel: "Başlangıç Tarihi",
        taskListPage_searchForm_statusLabel: "Durum",
        taskListPage_searchForm_taskTypeLabel: "Task Türü",
        taskListPage_taskListDataTable_taskAgencyNameLabel: "Acente İsmi",
        taskListPage_taskListDataTable_taskClosingTypeLabel: "Kapanış Tipi",
        taskListPage_taskListDataTable_taskGsaLabel: "Gsa",
        taskListPage_taskListDataTable_taskIdLabel: "Task Id",
        taskListPage_taskListDataTable_taskLastUpdateTimeLabel: "Son Güncelleme Zamanı",
        taskListPage_taskListDataTable_taskOpenedUserEmailLabel: "Task Açan Kullanıcı",
        taskListPage_taskListDataTable_taskOpeningDescriptionLabel: "Açılış Notu",
        taskListPage_taskListDataTable_taskOrderIdLabel: "Order ID",
        taskListPage_taskListDataTable_taskPnrLabel: "Pnr",
        taskListPage_taskListDataTable_taskStatusLabel: "Task Durumu",
        taskListPage_taskListDataTable_taskSubStatusLabel: "Alt Durum",
        taskListPage_taskListDataTable_taskTypeLabel: "Task Türü",
        taskNotes_placeholder: "Notunuzu buraya yazabilirsiniz.",
        taxInformation_city_tax: "Şehir Vergisi",
        taxInformation_electricity_fee: "Elektrik Ücreti",
        taxInformation_excludedTaxes: "Dahil Olmayan Vergiler",
        taxInformation_includedTaxes: "Dahil Olan Vergiler",
        taxInformation_service_fee: "Servis Ücreti",
        taxInformation_vat: "Vergi",
        tblOrderSearch_thAirlinePnr: "Havayolu Pnr",
        test_arrayParamAdult: "{0} adult var",
        test_childAge1: "çocuk odası {0} ile {1} arasında ok",
        test_childAge2: "çocuk odası {0} ile {1} arasında ok ve diğer çocuk {0} ile {1} arası ok",
        test_dynamic_text: "ali {value} veli",
        test_test: "test",
        tokenDirection_lblAppInstallation: "Uygulama Kurulumu",
        tokenDirection_lblAppInstallationDownloadAppStore: "Şuradan Edin",
        tokenDirection_lblAppInstallationDownloadPlayStore: "Şuradan Edin",
        tokenDirection_lblAppInstallationGuideText: "Aşağıdaki QR kodunu taratarak Google Authenticator uygulamasını akıllı telefonunuza indirin.",
        tokenDirection_lblAppInstallationGuideTextOrVisit: "ya da bu linki ziyaret et #VALUE#",
        tokenDirection_lblAppInstallationOpenToken: "Token Kullanımını Aç",
        tokenDirection_lblAppInstallationQrBelowInfo: "QR kodunu tarayarak açılan sayfa sizi otomatik olarak cihazınız için uyumlu mağazaya yönlendirecektir. Yönlendirme çalışmazsa, bağlantılara manuel olarak tıklamayı deneyin.",
        tokenDirection_lblAppInstallationValidateToken: "Token Doğrula",
        tokenDirection_lblExtraSecurityLayer: "Hesap Güvenliğiniz Artırın",
        tokenDirection_lblExtraSecurityLayerInfo1: "Hesaplarınızın çalınmasına karşı kesin çözüm token kullanımıdır. Sonraki adımları takip ederek token aktif edebilirsiniz.",
        tokenDirection_lblExtraSecurityLayerInfo2: "Hazırsan,  #VALUE#",
        tokenDirection_lblExtraSecurityLayerLastPageInfo: "İkinci doğrulama adımını açarken bir hatayla mı karşılaştınız? Destek ekibimizle iletişime geçmek için #VALUE# numaramızı arayabilirsiniz.",
        tokenDirection_lblExtraSecurityLayerLastPageInfo2: "ya da #VALUE# arayınız.",
        tokenDirection_lblGeneratingVerificationCode: "Doğrulama Kodu Oluşturma",
        tokenDirection_lblGeneratingVerificationCodeText1: "Google hesabınızı açarak Authenticator uygulamasını kullanın.",
        tokenDirection_lblGeneratingVerificationCodeText2: "Bir kod eklemek için uygulamanın sağ alt köşesinde bulunan #VALUE# düğmesine basın.",
        tokenDirection_lblGeneratingVerificationCodeText3: "QR Kodunu Tara alanına dokunun ve uygulama için kamera erişimine izin verin ve aşağıdaki QR kodunu tarayın.",
        tokenDirection_lblLetsGo: "Haydi Başlayalım!",
        tokenDirection_lblLoginWithToken: "Token İle Giriş",
        tokenDirection_lblLoginWithTokenText1: "6 haneli doğrulama kodunuz Authenticator Uygulamasına gönderilecektir.",
        tokenDirection_lblLoginWithTokenText2: "Her oturum açma işleminde #VALUE# adresinin oturum açma ekranındaki Token alanı için bu doğrulama kodunu kullanın",
        tokenDirection_lblSkip: "Kapat",
        tokenDirection_lblSupportService: "Destek Servisi",
        tokenValidate_lblInfoText1: "Authenticator uygulamanızda hesabınızla eşleşen 6 haneli kodu giriniz.",
        tokenValidate_lblInfoText2: "Eğer Authenticator uygulamanızda doğrulama kodunu göremiyorsanız, bir önceki adıma geri dönüp işlemleri tekrarlayınız.",
        tokenValidate_lblTitle: "Token Doğrulama",
        TransactionList_FLIGHT_ANCILLARY: "Uçak Bileti Ancillary",
        TransactionList_PNR_COMMISSION_REVERSE: "Uçak Komisyon İade",
        transactionListPage_AgencyBalance: "Bakiye",
        transactionListPage_pageTitle: "Hesap Hareketleri",
        transactionListPage_searchForm_endDateLabel: "Bitiş Tarihi",
        transactionListPage_searchForm_startDateLabel: "Başlangıç Tarihi",
        transactionListPage_transactionListDataTable_transactionBalanceLabel: "Bakiye",
        transactionListPage_transactionListDataTable_transactionCreditLabel: "Alacak",
        transactionListPage_transactionListDataTable_transactionDateLabel: "Tarih",
        transactionListPage_transactionListDataTable_transactionDebtLabel: "Borç",
        transactionListPage_transactionListDataTable_transactionEntityLabel: "Ürün",
        transactionListPage_transactionListDataTable_transactionInfoLabel: "Açıklama",
        transactionListPage_transactionListDataTable_transactionOrderIdLabel: "Order ID",
        transactionListPage_transactionListDataTable_transactionProviderLabel: "Tedarikçi",
        transactionListPage_transactionListDataTable_transactionTrxTypeLabel: "İşlem Tipi",
        transactionListPage_transactionListDataTable_transactionUserEmailLabel: "Kullanıcı E-mail",
        transactionPage_transactionDataTable_balanceBeginLabel: "Devir Bakiyesi",
        transactionPage_transactionDataTable_balanceEndLabel: "Son Bakiye",
        transactionPage_transactionDataTable_balanceTotalLabel: "İşlemler Toplamı",
        transfer_fillTravelerRows: "Doldur",
        transfer_fillTravelerRowsCancelBtn: "İptal",
        transfer_fillTravelers: "Yolcu Bilgileri",
        transfer_fillTravelersContent: "Yolcu bilgilerini otomatik doldurmak ister misiniz?",
        transfer_lblAdult: "Yetişkin",
        transfer_lblChild: "Çocuk",
        transfer_lblDepartureDate: "Alınış / Dönüş Tarihi",
        transfer_lblExtraFeature: "Ek Özellikler",
        transfer_lblFromName: "A Noktası",
        transfer_lblInfant: "Bebek",
        transfer_lblManuelRequest: "Aradığınız rotada ön tanımlı fiyat bilgisi bulunamamıştır. Manuel Talep butonuna tıklayarak talebinizi bize iletebilirsiniz.",
        transfer_lblPickupReturnDate: "Gidiş Tarihi / Dönüş Tarihi",
        transfer_lblProvider: "Tedarikçi",
        transfer_lblReturnDate: "Dönüş Tarihi",
        transfer_lblToName: "B Noktası",
        transfer_lblTravelerCount: "Pax Sayısı",
        transfer_lblTravellers: "Yolcu Sayısı",
        transfer_manuelRequest: "Manuel Talep",
        transfer_manuelRequestCancel: "İptal",
        transfer_manuelRequestConfirm: "Talebi Onayla",
        transfer_manuelRequestModalTitle: "Manuel Talep Oluştur",
        transfer_phOrSimilar: "VEYA BENZERİ",
        transfer_RepresentativeVehicle: "Temsilidir",
        transfer_searchTransfer: "TRANSFER ARA",
        transfer_travelerBirthDate: "Doğum Tarihi",
        transfer_travelerNameSurname: "İsim-Soyisim",
        transfer_travelerType: "Kişi Tipi",
        transferAutocomplete_lblDistrict: "Bölge",
        transferAutocomplete_lblHotel: "Otel",
        transferAutocomplete_phFromDestination: "Nereden: Havalimanı / Otel",
        transferAutocomplete_phToDestination: "Nereye: Havalimanı / Otel",
        transferBasket_btnClear: "Temizle",
        transferBasket_lblTitle: "Sepet",
        transferBasket_lblTotalFare: "Toplam",
        TransferCard_btnRemoveCar: "Aracı Değiştir",
        TransferCard_btnSelectCar: "Araç Seç",
        TransferCard_lblBaggage: "Bagaj",
        TransferCard_lblCapacity: "Araç Kapasitesi",
        TransferCard_lblCardInformationText: "Araç görseli temsilidir.",
        TransferCard_lblInformation: "",
        TransferCard_lblMax: "Max Yolcu",
        transferCheckout_agencyExtraCCommission: "Acente Ekstra Komisyon Ekleniyor.",
        transferCheckout_lblAgencyCommission: "Acente Komisyon",
        transferCheckout_lblAgencyExtraCommission: "Acente Extra Komisyon",
        TransferCheckout_lblAirCondition: "Klima",
        TransferCheckout_lblArrival: "İniş Saati",
        TransferCheckout_lblBaggage: "Bagaj",
        TransferCheckout_lblDeparture: "Kalkış Saati",
        transferCheckout_lblGrandTotal: "Genel Toplam",
        TransferCheckout_lblImportantNote1: "Lütfen bu voucher üzerindeki transfer bilgilerinizi kontrol ediniz.",
        TransferCheckout_lblImportantNote2: "İptal ve değişiklik işlemleri için acenteniz ile görüşebilirsiniz.",
        TransferCheckout_lblImportantNote3: "Havalimanı veya otel buluşma onayı, transferinizden bir gün önce Whatsapp mesajı ile tarafınıza gönderilecektir. Eğer onay bilgisi tarafınıza ulaşmaz ise transferinizden en az 12 saat önce +90 531 720 21 28 numaralı acil durum çağrı iletişim telefonumuzdan otel alınış saatinizi teyit edebilirsiniz.",
        TransferCheckout_lblImportantNote4: "Havalimanı karşılamalarında kaptanınızın bekleme süresi uçak inişinden itibaren 60 dakikadır. Eğer terminal içerisinde bagaj ve pasaport işlemleriniz daha uzun sürerse mutlaka bu durumu çağrı iletişim numaramıza bildiriniz.",
        TransferCheckout_lblImportantNote5: "Havalimanı veya otel buluşma onayı, transferinizden bir gün önce Whatsapp mesajı ile tarafınıza gönderilecektir. Eğer onay bilgisi tarafınıza ulaşmaz ise transferinizden en az 12 saat önce #VALUE# ve #VALUE1# numaralı acil durum çağrı iletişim telefonumuzdan otel alınış saatinizi teyit edebilirsiniz.",
        TransferCheckout_lblLastVoidDate: "Ücretsiz İptal Tarihi",
        TransferCheckout_lblLightning: "Aydınlatma",
        TransferCheckout_lblMax: "Max Yolcu",
        TransferCheckout_lblPickUp: "Alınış Zamanı",
        TransferCheckout_lblPickupTime: "Alınış Zamanı",
        TransferCheckout_lblPnr: "PNR",
        transferCheckout_lblTransferDescriptionsTitle: "Transfer Açıklamaları",
        transferCheckout_titleGrandTotal: "Genel Toplam",
        TransferCheckout_txtPickupInfo: "Havalimanı buluşma bilgisi veya otelden alınış saati bir gün önce Whatsapp mesajı ile yolcu telefonuna bildirilecektir.",
        TransferFilters_deselectAll: "Tüm Seçimi Kaldır",
        TransferFilters_lblFilters: "Filtreler",
        TransferFilters_lblTransferTypes: "Transfer Tipi",
        TransferFilters_lblVehicles: "Araçlar",
        TransferFilters_selectAll: "Tümünü Seç",
        TransferInformations_lblAgencyCommissionExtra: "Acente Extra Komisyon",
        TransferInformations_lblAgencyCommissionRatio: "Acente Komisyon",
        TransferInformations_lblCar: "Araç",
        TransferInformations_lblGrandTotal: "Genel Toplam",
        TransferInformations_lblTotalPrice: "Toplam Ücret (Net)",
        TransferInformations_transferInformations: "Transfer Bilgileri",
        transferOrderDetail_lblAgencyCommission: "Acente Komisyon",
        transferOrderDetail_lblBaseTotal: "Net Fiyat",
        transferOrderDetail_lblGrandTotal: "Genel Toplam",
        transferOrderDetail_lblReverseTotal: "İade Tutarı",
        transferOrderDetail_lblSalesTotal: "Satış Toplamı",
        transferOrderDetail_lblTransferAgencyNote: "Acente Notu",
        transferReissue_popupReiusseSuccessSubtitle: "Değişiklik işleminiz başarılı bir şekilde kaydedilmiştir.",
        transferSalesReport_currency: "Para birimi",
        transferSalesReport_filter_btnList: "Listele",
        transferSalesReport_filter_lblFromDate: "Başlangıç",
        transferSalesReport_filter_lblReportType: "Rapor Tipi",
        transferSalesReport_filter_lblStatus: "Durum",
        transferSalesReport_filter_lblToDate: "Bitiş",
        transferSalesReport_legCount: "Transfer Adedi",
        transferSalesReport_refundTotal: "İade Toplamı",
        transferSalesReport_tblMain_thAgencyCommission: "Acente Komisyon",
        transferSalesReport_tblMain_thAgencyName: "Acente Adı",
        transferSalesReport_tblMain_thFrom: "Nereden",
        transferSalesReport_tblMain_thFromDate: "Başlangıç Tarihi",
        transferSalesReport_tblMain_thOrderId: "Order ID",
        transferSalesReport_tblMain_thProvider: "Tedarikçi",
        transferSalesReport_tblMain_thSaleDate: "Satış Tarihi",
        transferSalesReport_tblMain_thStatus: "Durum",
        transferSalesReport_tblMain_thTnr: "Tnr",
        transferSalesReport_tblMain_thTo: "Nereye",
        transferSalesReport_tblMain_thTotal: "Toplam Ücret",
        transferSalesReport_tblMain_thTransferDate: "Transfer Tarihi",
        transferSalesReport_tblMain_thTransferType: "Transfer Tipi",
        transferSalesReport_tblMain_thTravelerCount: "Pax Sayısı",
        transferSalesReport_tblMain_thTravelerName: "Yolcu Adı",
        transferSalesReport_tblMain_thTravelerSurname: "Yolcu Soyadı",
        transferSalesReport_tblMain_thVehicleType: "Araç Tipi",
        transferSalesReport_title: "Transfer Satış Raporu",
        transferSalesReport_tnrCount: "Order Adedi",
        transferSalesReport_totalAmount: "Satış Toplamı",
        transferSalesReport_travelerCount: "Yolcu Adedi",
        TransferSearch_lblCarFound: "araç bulundu.",
        transferSearch_lblEmptyTransferList: "Aradığınız kriterlere uygun transfer bulunamamıştır. Lütfen arama kriterlerinizi değiştirerek tekrar deneyiniz.",
        transferSearch_lblFromDestination: "Nereden",
        transferSearch_lblToDestination: "Nereye",
        TransferSearch_lblTransferRoute: "Nereden #VALUE# nereye #VALUE1#",
        transferSearchInformation_adult: "Yetişkin",
        transferSearchInformation_child: "#COUNT# Çocuk",
        transferSearchInformation_data: "Kişi Sayısı",
        transferSearchInformation_date1: "Alınış Zamanı",
        transferSearchInformation_date2: "Dönüş Tarihi",
        transferSearchInformation_infant: "#COUNT# Bebek",
        transferSearchInformation_locationFrom: "Nereden",
        transferSearchInformation_locationTo: "Nereye",
        transferSearchInformation_text: "En iyi fiyatlar aranıyor, lütfen bekleyiniz!",
        transferTravelerInformationsComponent_travelerInformations: "Yolcu Bilgileri",
        userAuths_authChanged: "Yetki Değişti.",
        UserAuths_authError: "Yetki Verilemedi.",
        UserAuths_BalanceUpdate: "Bakiye Yükleme",
        UserAuths_CustomerSettings: "Müşteri İşlemleri",
        UserAuths_FlightCheckout: "Uçuş Satın Alma",
        UserAuths_FlightSale: "Uçuş Ara",
        UserAuths_FlightSalesReport: "Uçuş Satış Raporu",
        UserAuths_HotelCheckout: "Otel Satın Alma",
        UserAuths_HotelSale: "Otel Ara",
        UserAuths_HotelSalesReport: "Otel Satış Raporu",
        UserAuths_InvoiceList: "Fatura Listesi",
        userAuths_lblAccTransactionList: "Hesap Hareketleri",
        userAuths_lblBalanceUpdate: "Bakiye Yükleme",
        userAuths_lblCustomerSettings: "Müşteri İşlemleri",
        userAuths_lblDashboardV2: "Dashboard V2",
        userAuths_lblFlightCheckout: "Uçuş Satın Alma",
        userAuths_lblFlightSale: "Uçuş Ara",
        userAuths_lblFlightSalesReport: "Uçuş Satış Raporu",
        userAuths_lblHotelCheckout: "Otel Satın Alma",
        userAuths_lblHotelSale: "Otel Ara",
        userAuths_lblHotelSalesReport: "Otel Satış Raporu",
        userAuths_lblInvoiceList: "Fatura Listesi",
        userAuths_lblNotification: "Bildirimler",
        userAuths_lblOrderDetail: "Order Görüntüleme",
        userAuths_lblOrderSearch: "Order Arama",
        userAuths_lblPersonalAuth: "Bireysel Kullanıcı",
        userAuths_lblProfileSettings: "Profil Ayarları",
        userAuths_lblRentACar: "Rent a Car",
        userAuths_lblRentACarCheckout: "Rent A Car Satın Alma",
        userAuths_lblRentACarSale: "Rent A Car Ara",
        userAuths_lblRentACarSalesReport: "Rent A Car Satış Raporu",
        userAuths_lblSchimetterling: "Paket Ara",
        userAuths_lblSchmetterlingSalesReport: "Paket Satış Raporu",
        userAuths_lblTaskList: "Service Center",
        userAuths_lblTransactionList: "Hesap Hareketleri",
        userAuths_lblTransferCheckout: "Transfer Satın Alma",
        userAuths_lblTransferSale: "Transfer Ara",
        userAuths_lblTransferSalesReport: "Transfer Satış Raporu",
        userAuths_lblUserAuths: "Kullanıcı Yetkileri (Admin)",
        userAuths_lblUserSettings: "Kullanıcı Ayarları",
        UserAuths_Notification: "Bildirimler",
        UserAuths_OrderDetail: "Order Görüntüleme",
        UserAuths_OrderSearch: "Order Arama",
        UserAuths_ProfileSettings: "Profil Ayarları",
        UserAuths_TaskList: "Service Center",
        userAuths_thAuth: "Yetki",
        userAuths_thId: "ID",
        userAuths_titleUserAuths: "Kullanıcı Yetkileri",
        UserAuths_TransactionList: "Hesap Hareketleri",
        UserAuths_TransferCheckout: "Transfer Satın Alma",
        UserAuths_TransferSale: "Transfer Ara",
        userAuths_userActive: "Aktif",
        UserAuths_UserAuths: "Kullanıcı Yetkileri",
        userAuths_userPassive: "Pasif",
        UserAuths_UserSettings: "Kullanıcı Ayarları",
        userControlBox_lnkDashboard: "Dashboard",
        userControlBox_lnkLogout: "Çıkış",
        userControlBox_lnkSettings: "Ayarlar",
        userSettings_lblUserEmail: "E-mail",
        userSettings_lblUserSettings: "Kullanıcı Ayarları",
        userSettings_mailSet: "Mail ayarlarınız değişti!",
        widgetHeaderComponent_addNew: "Yeni Ekle",
        widgetHeaderComponent_backtoFirst: "İlk Versiyona Dön",
        widgetHeaderComponent_highlighter: "Vurgula",
        widgetHeaderComponent_removeWidget: "Widget'ı Kaldır",
        widgetHeaderComponent_resetToDefault: "İlk Versiyona Dön",
        widgetHeaderComponent_toggleExtras: "Ekstraları Aç / Kapat",
        widgetHeaderComponent_turnOnArrangement: "Düzenlemeyi Aç"
    },
    en: {
        "25 gtsGrid_item": "25",
        accountTransactionList_filter_btnList: "List",
        accountTransactionList_filter_lblFromDate: "Creation From Date",
        accountTransactionList_filter_lblToDate: "Creation To Date",
        accountTransactionList_tblMain_thBalance: "Balance",
        accountTransactionList_tblMain_thBalanceBegin: "Turnover Balance",
        accountTransactionList_tblMain_thBalanceEnd: "Current Balance",
        accountTransactionList_tblMain_thCredit: "Credit",
        accountTransactionList_tblMain_thCurrency: "Currency",
        accountTransactionList_tblMain_thDate: "Date",
        accountTransactionList_tblMain_thDebt: "Debit",
        accountTransactionList_tblMain_thEntity: "Product",
        accountTransactionList_tblMain_thInfo: "Description",
        accountTransactionList_tblMain_thOrderId: "Order ID",
        accountTransactionList_tblMain_thProvider: "Provider Name",
        accountTransactionList_tblMain_thTrxSum: "Total of Transactions",
        accountTransactionList_tblMain_thTrxType: "Transaction Type",
        accountTransactionList_tblMain_thUserEmail: "User E-mail",
        accountTransactionList_tblMain_title: "Account Activities",
        accountTransactionList_tblMain_trProvider: "Hotel Provider",
        accountTransactionList_tblSumm_thBalance: "Balance",
        accountTransactionList_tblSumm_thCredit: "Credit",
        accountTransactionList_tblSumm_thDebt: "Debit",
        accountTransactionList_tblSumm_thTrxType: "Transaction Type",
        accountTransactionList_tblSumm_title: "Account Transactions Summary",
        accountTransactionList_title: "Account Activities",
        addTask_success: "Successful",
        addWidget_btnSave: "SAVE",
        addWidget_lblAddWidget: "Add Widgets",
        addWidget_lblFlightForthComingTitle: "ForthComing Flights",
        addWidget_lblFlightGirosTitle: "Flight - Giros",
        addWidget_lblFlightPerformanceTitle: "Flight Performance",
        addWidget_lblFlightReservationsTitle: "Upcoming Reservations  - Flight",
        addWidget_lblHotelCheckInTitle: "Upcoming Hotel Check-Ins",
        addWidget_lblHotelPerformanceTitle: "Hotel Performance",
        addWidget_lblHotelReservationsTitle: "Upcoming Reservations  - Hotel",
        addWidget_lblRentACarWidgetTitle: "ForthComing Rent a Car",
        addWidget_lblTranferWidgetTitle: "Upcoming Transfers",
        agencyAccountComponent_availableBalance: "Available Balance",
        agencyAccountComponent_balance: "Balance",
        agencyAccountComponent_balanceUpdate: "Balance Deposit",
        agencyAccountComponent_definedCredit: "Defined Credit",
        AgencyExtraCommission_checkOutInformation: "Added to Total Fee.",
        AgencyExtraCommission_title: "Extra Commission",
        AGENT_API_FLIGHT_CHECKOUT_EXPIRED_CHECKOUT: "Prices may have been updated because you haven't traded for a long time.  You can continue your process by renewing your search.",
        airlineEnum_6Y: "SmartLynx Airlines",
        airlineEnum_QS: "Smartwings Airline",
        alert_emptyAmount: "Please enter amount.",
        alert_emptyHotelList: "There is no hotel found matching with your search criteria. Please try again by changing your search criteria.",
        alert_warningPopupTitle: "An Error Occurred",
        ancillariesServices_baggage: "Extra Baggage",
        ancillariesServices_checkIn: "Online Check-in",
        ancillariesServices_descriptionsunflex3: "SunFlex 3, Rebooking free of charge 3 days prior to departure (+ price difference)",
        ancillariesServices_divide: "Divide",
        ancillariesServices_food: "Food Service",
        ancillariesServices_lblMealsFooterInfo: "You can contact our Service Center team for all your questions and suggestions.",
        ancillariesServices_lblWheelChairFooterInfo: "To change the wheelchair service you previously requested, please contact our Service Center team. Modifications cannot be made through this page.",
        ancillariesServices_meal: "Meal Selection",
        ancillariesServices_meals: "Meal",
        ancillariesServices_onlineCheckin: "Online Check-in",
        ancillariesServices_reissue: "Reissue",
        ancillariesServices_relatedPNRInfos: "Additional services are PNR and Passenger based. The above additional services are created for PNR {PNR}.",
        ancillariesServices_relatedPPaymentMethods: "You can pay for the additional services you receive with Mco, Credit Card, Sofort and Ideal payment methods.",
        ancillariesServices_seat: "Select Seat",
        ancillariesServices_selectfood: "Select Food",
        ancillariesServices_selectssr: "Sun Flex 3 Select",
        ancillariesServices_selectwheelchair: "Select WheelChair",
        ancillariesServices_SunFlex3: "Sun Flex 3",
        ancillariesServices_title: "Ancillary Services",
        ancillariesServices_wheelChair: "Wheelchair",
        ancillariesServicesModal_baggageContentSuccess: "Your baggage buy has been successful.",
        ancillariesServicesModal_baggageFailedTitle: "Error",
        ancillariesServicesModal_baggageSuccessTitle: "Success",
        ancillariesServicesModal_baggeFailedMessage: "You received an error during baggage claim. Please contact the Service Center and try again.",
        ancillariesServicesModal_btnClose: "CLOSE",
        ancillariesServicesModal_btnConfirm: "CONFIRM",
        ancillariesServicesModal_btnTryAgain: "Search Again",
        ancillariesServicesModal_BuyWithCurrentPaymentMethod: "Current Buy",
        ancillariesServicesModal_contentFailed: "You received an error when buying baggage. Please contact the Service Center.",
        ancillariesServicesModal_contentSuccess: "We have processed your additional baggage to your order number <strong>{ORDER}</strong>",
        ancillariesServicesModal_divideAfterDividePNR: "New PNR",
        ancillariesServicesModal_divideCurrentPNR: "Current PNR",
        ancillariesServicesModal_divideFailedMessage: "You received an error during the divide process. Please contact the Service Center.",
        ancillariesServicesModal_divideFailedTitle: "Error",
        ancillariesServicesModal_divideSuccessMessage: "Your divide transaction is successful. Your new Pnr {PNR} number has been created.",
        ancillariesServicesModal_divideSuccessTitle: "Success",
        ancillariesServicesModal_infos: "Bank commission is added to the payments you make with Credit card and Sofort while purchasing Additional Services.",
        ancillariesServicesModal_infoTextReissueStep2: "Please select flight you want to reissue.",
        ancillariesServicesModal_infoTxtPlsSelectFlights: "Please select the flights, via {ICON} button, you want to reissue.",
        ancillariesServicesModal_lblAgencyExtraComm: "Agency Extra Commission",
        ancillariesServicesModal_lblArrivalAirport: "Arrival",
        ancillariesServicesModal_lblArrivalAirportPlaceholder: "Arrival",
        ancillariesServicesModal_lblArrivalDate: "Arrival Date",
        ancillariesServicesModal_lblCancelSelection: "Cancel Selection",
        ancillariesServicesModal_lblDepartureAirport: "Departure",
        ancillariesServicesModal_lblDepartureAirportPlaceholder: "Departure",
        ancillariesServicesModal_lblDepartureDate: "Departure Date",
        ancillariesServicesModal_lblExtraComm: "Extra Commission",
        ancillariesServicesModal_lblFlight: "Flight",
        ancillariesServicesModal_lblGrandTotal: "Grand Total",
        ancillariesServicesModal_lblPayment: "Payment Step",
        ancillariesServicesModal_lblReserve: "RESERVE",
        ancillariesServicesModal_lblSearch: "Search",
        ancillariesServicesModal_lblSearchResultCount: "Displaying result.",
        ancillariesServicesModal_lblSSR: "Sun Flex 3",
        ancillariesServicesModal_lblSSRDesc: "SunFlex 3, Rebooking free of charge 3 days prior to departure (+ price difference)",
        ancillariesServicesModal_lblTotalSalesAmount: "Total",
        ancillariesServicesModal_mealsContentSuccess: "Your meal buy has been successful.",
        ancillariesServicesModal_mealsFailedMessage: "You received an error during meal purchase. Please contact the Service Center and try again.",
        ancillariesServicesModal_mealsFailedTitle: "Error",
        ancillariesServicesModal_mealsSuccessTitle: "Successful",
        ancillariesServicesModal_NO_BRAND_FOUND: "The brand of your reservation was not found on the airline. Please contact the Service Center.",
        ancillariesServicesModal_NO_FLIGHT_FOUND: "No flights matching your search criteria were found.",
        ancillariesServicesModal_otherPaymentMethods: "Other Methods",
        ancillariesServicesModal_paymentStep: "Payment Step",
        ancillariesServicesModal_reissueErrorMessage: "We encountered an unknown error in the reissue query. Please contact our Service Centre Team for the cause of the error.",
        ancillariesServicesModal_reissueErrorNoSearchResultText: "There was no response from the airline. Please contact our Service Center Team for the cause of the error.",
        ancillariesServicesModal_reissueErrorNoSearchResultTitle: "An Error Occurred",
        ancillariesServicesModal_reissueErrorText: "Error",
        ancillariesServicesModal_reissueErrorTitle: "You received an error during the change process. Please contact the Service Centre team.",
        ancillariesServicesModal_reissueSuccessText: "Your change has been made successfully. You can check your new ticket.",
        ancillariesServicesModal_reissueSuccessTitle: "Success",
        ancillariesServicesModal_resetDivide: "Reset",
        ancillariesServicesModal_stepDivide: "PNR Divide",
        ancillariesServicesModal_stepFlightSearchResult: "Search Result",
        ancillariesServicesModal_stepFlightSelection: "Reissue Selection",
        ancillariesServicesModal_stepMealSelection: "Meal Selection",
        ancillariesServicesModal_stepPayment: "Payment Page",
        ancillariesServicesModal_stepReissuePayment: "Payment Page",
        ancillariesServicesModal_stepReissueResult: "Result",
        ancillariesServicesModal_stepResult: "Result",
        ancillariesServicesModal_stepSeatSelection: "Seat Selection",
        ancillariesServicesModal_stepSelectBaggage: "Baggage Selection",
        ancillariesServicesModal_stepSssr: "Sun Flex Select",
        ancillariesServicesModal_stepSunFlex3: "Sun Flex",
        ancillariesServicesModal_stepWheelChairSelection: "WheelChair Selection",
        ancillariesServicesModal_sunFlexErrorMessage: "You received an error during Sun Flex purchase. Please contact the Service Center and try again.",
        ancillariesServicesModal_sunFlexSuccessMessage: "Your SunFlex 3 reservation has been made successfully.",
        ancillariesServicesModal_titleFailed: "Error",
        ancillariesServicesModal_titleSuccess: "Success",
        ancillariesServicesModal_wheelchairErrorMessage: "You received an error during the wheelchair purchase. Please contact the Service Center and try again.",
        ancillariesServicesModal_wheelchairErrorTitle: "Error",
        ancillariesServicesModal_wheelchairSuccessMessage: "Your wheelchair purchase process has been realized successfully.",
        ancillariesServicesModal_wheelchairSuccessTitle: "Successful",
        ancillarReissue_btnCalculateReissue: "Calculate Reissue",
        ancillarReissue_lblCalculateDescription: "You can calculate Reissue Amount via reissue calculator.",
        ancillarReissue_lblCalculateTitle: "Reissue Calculation",
        ancillarReissue_lblCalculatingFailedDescription: "An error occurred during your operation. You can continue your process by searching again.",
        ancillarReissue_lblCalculatingFailedDescriptionFLIGHT_SAME_DAY: "Please check your selected flights.",
        ancillarReissue_lblCalculatingFailedTitle: "Error",
        ancillarReissue_lblFlightResultTitleAirline: "Airlines",
        ancillarReissue_lblFlightResultTitleArrival: "Arrival",
        ancillarReissue_lblFlightResultTitleDeparture: "Departure",
        ancillarReissue_lblFlightResultTitleFlightDate: "Flight Date",
        ancillarReissue_lblFlightResultTitleFlightNu: "Flight Number",
        ancillarReissue_lblFlightResultTitleFrom: "Departure",
        ancillarReissue_lblFlightResultTitleStop: "Connection",
        ancillarReissue_lblFlightResultTitleTo: "To",
        ancillarReissue_lblPreviousStep: "Previous Step",
        ancillaryReissueSelection_lblPnrHasAncillaries: "SSRs purchased for your old reservation will not be transferred to your reservation after the reissue.",
        ancillarySeatMap_btnCancel: "Cancel",
        ancillarySeatMap_btnNextStep: "Next",
        ancillarySeatMap_btnPayment: "Payment",
        ancillarySeatMap_btnPreviousStep: "Previous Step",
        ancillarySeatMap_lblAvailable: "Selectable",
        ancillarySeatMap_lblCancel: "Cancel",
        ancillarySeatMap_lblErrorBtn: "Error",
        ancillarySeatMap_lblExtraLegRoom: "XL Seat",
        ancillarySeatMap_lblGrandTotal: "Grand Total",
        ancillarySeatMap_lblNotAvailable: "Unselectable",
        ancillarySeatMap_lblPassenger: "Passenger",
        ancillarySeatMap_lblPaxType: "Pax Type",
        ancillarySeatMap_lblPayment: "Payment",
        ancillarySeatMap_lblPrice: "Price",
        ancillarySeatMap_lblResult: "Result",
        ancillarySeatMap_lblResultEXCEPTION: "We encountered an unknown error. Please contact our Service Center Team for the cause of the error.",
        ancillarySeatMap_lblResultEXPIRED: "Prices may have been updated because you haven't traded for a long time. You can continue your process by renewing your search.",
        ancillarySeatMap_lblResultFAILURE: "We encountered an unknown error. Please contact our Service Center team.",
        ancillarySeatMap_lblResultREJECTED: "We encountered an unknown error while reservation. Please contact our Service Center Team for the cause of the error.",
        ancillarySeatMap_lblResultSUCCESS: "Your seat purchase has been successful.",
        ancillarySeatMap_lblResultTIMEOUT: "Your transaction timed out.  Please try again.",
        ancillarySeatMap_lblSeat: "Seat",
        ancillarySeatMap_lblSelected: "Selected",
        ancillarySeatMap_lblSuccessBtn: "Success",
        announcement_homePage: "Main Page",
        announcement_showOriginalImage: "Show Original",
        announcement_updateTime: "Latest Update Time",
        announcement_urlCopied: "Copied",
        AnnouncementManagementList_thThumbnailImage: "Thumbnail",
        aside_accTransactionList: "Account Activities",
        aside_balanceUpdate: "Balance Deposit",
        aside_dashBoard: "Dashboard Home Page",
        aside_flightSalesReport: "Flight Sales Report",
        aside_flightSearch: "Flight Search",
        aside_hotelSalesReport: "Hotel Sales Report",
        aside_hotelSearch: "Hotel Search",
        aside_invoiceList: "Invoice List",
        aside_notifications: "Notifications",
        aside_orderSearch: "Search Order",
        aside_packageSearch: "Package Search",
        aside_rentACarSalesReport: "Rent a Car Sales Report",
        aside_rentACarSearch: "Rent A Car Search",
        aside_schmetterlingSalesReport: "Package Sales Report",
        aside_serviceCenter: "Service Center",
        aside_targetAchievements: "Incentive Target",
        aside_transferSalesReport: "Transfer Sales Report",
        aside_transferSearch: "Transfer Search",
        BALANCE_UPDATE_REJECTED: "An Error Occurred",
        BALANCE_UPDATE_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during payment.",
        BALANCE_UPDATE_SUCCESS: "Successful",
        BALANCE_UPDATE_SUCCESS_BALANCE_UPDATED: "Your balance has been successfully loaded.",
        BALANCE_UPDATE_VALIDATION: "An Error Occurred",
        BALANCE_UPDATE_VALIDATION_AMOUNT: "Please enter amount.",
        balanceUpdate_btnDeposit: "LOAD BALANCE",
        balanceUpdate_lblAmount: "Balance to be Loaded",
        balanceUpdate_lblAvailableBalance: "Available Balance",
        balanceUpdate_lblBalance: "Balance",
        balanceUpdate_lblCommission: "Commission",
        balanceUpdate_lblInfo: "Description",
        balanceUpdate_lblLoan: "Defined Credit",
        balanceUpdate_lblNote: "Description",
        balanceUpdate_lblTotal: "Total",
        BalanceUpdate_popup_REJECTED: "An Error Occurred",
        BalanceUpdate_popup_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during your transaction.  Your payment could not be collected.",
        BalanceUpdate_popup_SUCCESS: "Successful",
        BalanceUpdate_popup_SUCCESS_BALANCE_UPDATED: "Your balance has been successfully loaded.",
        BalanceUpdate_popup_Validation: "An Error Occurred.",
        BalanceUpdate_popup_Validation_AMOUNT: "Please enter amount.",
        BalanceUpdate_popup_Validation_ERROR: "Error",
        BalanceUpdate_progressGifStep1: "Your transaction is in progress. Please wait.",
        BalanceUpdate_progressGifStep2: "Your transaction is in progress. Please wait.",
        balanceUpdate_title: "Balance Deposit",
        balanceUpdateComponent_balanceUpdateForm_commissionLabel: "Commission",
        balanceUpdateComponent_balanceUpdateForm_descriptionLabel: "Description",
        balanceUpdateComponent_balanceUpdateForm_loadedBalanceLabel: "Balance to be Loaded",
        balanceUpdateComponent_balanceUpdateForm_totalLabel: "Total",
        balanceUpdateComponent_paymentChoicesTab_creditCardAlertText: "A fixed 2% bank commission rate will be added to the Credit card deposit.",
        balanceUpdateComponent_paymentChoicesTab_sofortAlertText: "In case of making Sofort payment; sum between 1€ and 500€ fixed 3€ and sum above 501€ fixed 5€ will be added to the total amount.",
        balanceUpdatePage_buttonText: "LOAD BALANCE",
        balanceUpdatePage_pageTitle: "Balance Deposit",
        balanceUpdateSummaryComponent_availableBalanceLabel: "Available Balance",
        balanceUpdateSummaryComponent_balanceLabel: "Balance",
        balanceUpdateSummaryComponent_loanLabel: "Defined Credit",
        basketComponentFlight_baggageInformationChanges: "Your baggage information has changed. Please check.",
        basketComponentFlight_basket: "Basket",
        basketComponentFlight_btnBaggageInformationConfirmation: "APPROVE",
        basketComponentFlight_closeBasket: "CLOSE",
        basketComponentFlight_goToCheckout: "CONTINUE",
        basketComponentFlight_newItemAddedorUpdated: "Product Added to Basket or Basket Updated.",
        basketComponentFlight_package: "Package",
        basketComponentFlight_sendOffer: "Send Offer",
        basketComponentFlight_single: "Single",
        basketComponentFlight_singleOfferIsCheaper: "Single Price is Cheaper!",
        basketComponentFlight_total: "Total",
        basketComponentHotel_sendOffer: "Send Offer",
        cancellationPoliciesComponent_cancellationDateLabel: "Free Cancellation Date",
        cancellationPolicy_lblFreeCancellationDate: "Free Cancellation Date",
        cancellationPolicy_lblNoCancellationPolicy: "Cancellation Policy",
        cancellationPolicy_lblNoLastBookingDateDescription: "There is no refund in case of cancellation of this reservation.",
        cancellationPolicy_lblPackageSchmetterlingCancellationPolicy: "To see the cancellation conditions and all rules regarding flights or accommodation, please refer to the details of the relevant reservation in the sales report section of the Smart Booking system.",
        changeDetails_lblarrivalAirportCode: "Arrival",
        changeDetails_lblarrivalDateTime: "Arrival Time",
        changeDetails_lblbrandCode: "Brand Code",
        changeDetails_lblbrandName: "Brand Name",
        changeDetails_lblcabinType: "Cabin Type",
        changeDetails_lbldepartureAirportCode: "Departure",
        changeDetails_lbldepartureDateTime: "Departure Time",
        changeDetails_lblflightClass: "Class",
        changeDetails_lblflightIndex: "Flight Index",
        changeDetails_lblflightNumber: "Flight Number",
        changeDetails_lblflightRoute: "Flight Route",
        changeDetails_lblmarketingAirlineCode: "Marketing Airline",
        changeDetails_lbloperatingAirlineCode: "Operating Airline",
        changeDetails_lblsegmentIndex: "Segment Index",
        changeDetails_lblsegmentRoute: "Segment Route",
        changeHistory_lblHistory: "Old Flight Information",
        changeHistory_lblLatestEntry: "New Flight Information",
        changeHistoryTable_lblTaskOpeningTime: "Task Open Time",
        changeHistoryTable_lblType: "Type",
        changeHistoryTable_thArrival: "Arrival Time",
        changeHistoryTable_thBrandCode: "Brand Code",
        changeHistoryTable_thBrandName: "Brand Name",
        changeHistoryTable_thCabinType: "Cabin Type",
        changeHistoryTable_thClass: "Class",
        changeHistoryTable_thDeparture: "Departure Time",
        changeHistoryTable_thFlightNo: "Flight Number",
        changeHistoryTable_thFrom: "Departure",
        changeHistoryTable_thMarketingOperating: "Marketing/ Operating",
        changeHistoryTable_thRoute: "Flight",
        changeHistoryTable_thTo: "Arrival",
        changeItemDetails_lblBody: "Attention! Your flight details has been changed.  There has been a flight/time change for the reservation mentioned below. Please contact your passenger and provide a written confirmation regarding the change",
        changeItemDetails_lblClosing: "Best Regards",
        changeItemDetails_lblGreeting: "Dear Agency,",
        chatBubbleComponent_sent: "Sent",
        chatTabComponent_placeholder: "Write a message...",
        checkout_coPayCancelBtn: "RESERVATION CANCELLATION",
        checkout_lblMetaPolicy: "Fees and Other Information",
        checkout_lblTaxInformation: "Tax Information",
        checkoutBasket_basket_addExtraCommission: "Add Extra Commission",
        checkoutBasket_basket_infos: "Informations",
        checkoutBasket_basket_pnrInfos: "As a result of this process #VALUE# PNR(S) will be created",
        checkoutBasket_titleBasket: "Basket",
        checkoutContact_bntFillWithAgentInfo: "Use Agent User Information",
        checkoutContact_bntFillWithFirstPaxInfo: "Use First Passenger Information",
        checkoutContact_contactInformations: "Contact Info",
        checkoutContact_informationText: "When the purchase is completed, your transaction information will be sent to your e-mail and/or mobile phone.",
        checkoutContact_lblFilledWithAgentInfos: "Ticketing is done with agency user information.",
        checkoutContact_lblFilledWithFirstPaxInfos: "Ticketing is done with the first passenger information.",
        checkoutContact_vldMin4: "You can enter a maximum of 4 digits for the city code.",
        checkoutFlightPayment_alertNoReservationAndNoBookingBtns: "The airline does not give a price for the brand you choose. You can try again by changing your selection.",
        checkoutFlightPayment_CHECKOUT_FAILED: "An error occurred during your operation. You can continue your process by searching again.",
        checkoutFlightPayment_INSUFFICIENT_AGENCY_BALANCE: "Your available balance is insufficient.  Please contact our team.",
        checkoutFlightPayment_lblBooking: "BUY",
        checkoutFlightPayment_lblReservationCancel: "RESERVATION CANCELLATION",
        checkoutFlightPayment_lblReservationRedirect: "GO TO THE RESERVATION",
        checkoutFlightPayment_lblReserve: "RESERVE",
        checkoutFlightPayment_NOT_SALES_OFFICE: "It is not a sales office. Please check your user or agent.",
        checkoutFlightPayment_SELECTED_BRANDS_NOT_VALID_NOW: "The airline does not give a price for the brand you choose. You can try again by changing your selection.",
        checkoutNotes_lblInformations: "Informations",
        common_add: "ADD",
        common_address: "Address",
        common_adult: "Adult",
        common_adultPluralExt: "(s)",
        common_agencyCommission: "Agency Commission",
        common_agencyExtraCommission: "Agency Extra Commission",
        common_agencyId: "Agency ID",
        common_agencyName: "Agency Name",
        common_agencyNote: "Agency Note",
        common_airlines: "Airlines",
        common_all: "Show All",
        common_allow: "Allow",
        common_and: "and",
        common_apr: "Apr",
        common_april: "April",
        common_arrivalFlightNumber: "Flight Number",
        common_arrivaltime_decrease: "Arrival Time, Decreasing",
        common_arrivaltime_increase: "Arrival Time, Increasing",
        common_aug: "Aug",
        common_august: "August",
        common_basket: "Basket",
        common_birthday: "Birth Date",
        common_booking: "BUY",
        common_cancel: "Cancelled",
        common_cancellationTime: "Cancellation Time",
        common_cannotBeEmpty: "Please fill it out.",
        common_capacity_decrease: "Capacity, Decrease",
        common_capacity_increase: "Capacity, Increase",
        common_checkIn: "Check In Date",
        common_checkOut: "Check Out Date",
        common_child: "Child",
        common_childPluralExt: "(s)",
        common_chooseChildAge: "Year",
        common_city: "City Code",
        common_clear: "Clear",
        common_clearAll: "Clear",
        common_close: "Closed",
        common_code: "Code",
        common_confirmationTitle: "Approval Phase",
        common_countryCode: "Country Code",
        common_countryPhoneCode: "Country Area Code",
        common_countryPhoneCodeShort: "Country Code",
        common_currency: "Currency",
        common_day: "Day",
        common_dec: "Dec",
        common_december: "December",
        common_decline: "Decline",
        common_departure_decrease: "Departure, Decreasing",
        common_departure_increase: "Departure, Increasing",
        common_departureFlightNumber: "Flight Number",
        common_departureTime: "Departure Time",
        common_description: "Description",
        common_deselectAll: "Deselect",
        common_disabled: "Disabled",
        common_disabledCompanion: "Disabled Companion",
        common_discount: "Discounted",
        common_district: "District",
        common_email: "E-mail",
        common_ethnic: "Ethnic (VFR)",
        common_eticket: "E-Ticket",
        common_etickets: "E-Ticket",
        common_eticketStatus: "E-Ticket Status",
        common_extraSeat: "Extra Seat",
        common_feb: "Feb",
        common_february: "February",
        common_female: "Female",
        common_flight: "Flight",
        common_flightNumber: "Flight Number",
        common_flightNumberOptional: "Flight Number",
        common_fri: "Fri",
        common_friday: "Friday",
        common_fullName: "Name Surname",
        common_gender: "Gender",
        common_generalGrandTotal: "General Grand Total",
        common_generalTotalPrice: "Total Fee",
        common_gotodashboard: "Go To Dashboard",
        common_grandTotal: "Total",
        common_gsa: "Gsa",
        common_gsaName: "Gsa Name",
        common_guest: "Guest",
        common_hide: "Hide",
        common_hotel: "Hotel",
        common_hotelName: "Hotel Name",
        common_hour: "Hour",
        common_id: "ID",
        common_infant: "Infant",
        common_info: "Info",
        common_information: "Information",
        common_insertedDate: "Inserted Time",
        common_insertedUser: "Inserted User",
        common_itemsSelected: "item selected",
        common_jan: "Jan",
        common_january: "January",
        common_jul: "Jul",
        common_july: "July",
        common_jun: "Jun",
        common_june: "June",
        common_languagePreference: "Language Preference",
        common_lblGrandTotal: "Grand Total",
        common_lblMediaOrientationMsg: "Please continue the process by placing your device in an upright position.",
        common_list: "List",
        common_male: "Male",
        common_mar: "Mar",
        common_march: "March",
        common_marketingAirlineCodes: "Airlines",
        common_may: "May",
        common_minute: "Minute",
        common_mon: "Mon",
        common_monday: "Monday",
        common_my: "May",
        common_name: "Name",
        common_nameSurname: "Name Surname",
        common_nationalIdentity: "National Identity",
        common_nationality: "Nationality",
        common_nodata: "No data available in table.",
        common_nothingSelected: "Nothing Selected",
        common_nov: "Nov",
        common_november: "November",
        common_oct: "Oct",
        common_october: "October",
        common_officialName: "Official Name",
        common_ok: "OK",
        common_okbtn: "OK",
        common_open: "Open",
        common_optionExpiryTime: "Option Expiry Time",
        common_order: "Order",
        common_orderId: "Order ID",
        common_passenger: "Passenger",
        common_paxCount: "Pax Count",
        common_person: "Adult",
        common_phone: "Phone",
        common_photoGallery: "Photos",
        common_pnrs: "Pnr",
        common_pnrStatus: "Pnr Status",
        common_pnrStatuses: "Pnr Status",
        common_price: "Price",
        common_price_decrease: "Price, Decreasing",
        common_price_fpdecrease: "Package Price, Decreasing",
        common_price_fpincrease: "Package Price, Increasing",
        common_price_increase: "Price, Increasing",
        common_pritax: "Tax",
        common_provider: "Provider Name",
        common_providerName: "Provider Name",
        common_purchase: "BUY",
        common_readLess: "Read Less",
        common_readMore: "Read More",
        common_recommended: "Recommended",
        common_removeRoom: "Remove Room",
        common_reservation: "RESERVE",
        common_reset: "Reset",
        common_resetbtn: "Reset",
        common_reversable: "CANCEL",
        common_room: "Room",
        common_rooms: "Rooms",
        common_roomSelect: "Room Select",
        common_salesTotal: "Sales Total",
        common_saleTime: "Sale Time",
        common_sat: "Sat",
        common_saturday: "Saturday",
        common_save: "Save",
        common_search: "Search",
        common_searchFlight: "Flight",
        common_searchHotel: "Hotel",
        common_searchPackages: "Packages",
        common_searchRentACar: "Rent A Car",
        common_searchRoom: "Choose Room",
        common_searchTransfer: "Transfer",
        common_segment: "Segment",
        common_selectAll: "Select All",
        common_sep: "Sep",
        common_september: "September",
        common_serviceFee: "Service Fee",
        common_shortName: "Short Name",
        common_show: "Show",
        common_showAllOption: "Show All Option",
        common_showLess: "Hide All Features",
        common_showLessOption: "Show Less Option",
        common_showMore: "Show All Features",
        common_showMoreOption: "Show More Option",
        common_star_decrease: "Star, Decreasing",
        common_star_increase: "Star, Increasing",
        common_status: "Status",
        common_success: "Successful",
        common_sun: "Sun",
        common_sunday: "Sunday",
        common_surname: "Surname",
        common_tax: "Pri Tax",
        common_thu: "Thu",
        common_thursday: "Thursday",
        common_timezone: "Time Zone",
        common_toggle: "Show / Hide",
        common_total: "Total",
        common_total_penalty: "Total Penalty",
        common_total_refund: "Total Refund Amount",
        common_totalPrice: "Total Fee",
        common_totalRefund: "Total Refund",
        common_totaltraveltime_decrease: "Total Travel Time, Decreasing",
        common_totaltraveltime_increase: "Total Travel Time, Increasing",
        common_totalwaitingtime_decrease: "Total Waiting Time, Decreasing",
        common_totalwaitingtime_increase: "Total Waiting Time, Increasing",
        common_transfers_crease: "Number of Transfers, Decreasing",
        common_transfers_increase: "Number of Transfers, Increasing",
        common_tue: "Tue",
        common_tuesday: "Tuesday",
        common_type: "Type",
        common_unknown: "Unknown",
        common_unknownChange: "Unknown change detail.",
        common_update: "Update",
        common_userEmail: "User E-mail",
        common_voucher: "Voucher",
        common_wed: "Wed",
        common_wednesday: "Wednesday",
        common_writeANote: "Note",
        common_zwischenstopps: "Connections",
        creditCardComponent_cvvLabel: "Security Code",
        creditCardComponent_holderLabel: "Name Surname on the Credit Card",
        creditCardComponent_monthLabel: "Month",
        creditCardComponent_panLabel: "Card No",
        creditCardComponent_yearLabel: "Year",
        customcancel_lblcustomCancel: "Cancel",
        customerList_customerList: "Customer List",
        customerManagement_btnAddNewCustomer: "Add New Customer",
        customerManagement_deleteCustomer: "Delete Customer",
        customerManagement_editCustomer: "Edit Customer",
        customerManagement_popupCustomerAddedSubtitle: "Customer added.",
        customerManagement_popupCustomerDeleteSubtitle: "Customer Deleted.",
        customerManagement_popupCustomerEditSubtitle: "Customer updated.",
        customerManagement_popupNewCustomerWillBeAdded: "The customer will be added. Do you approve?",
        customerManagement_thBirthdate: "Birth Date",
        customerManagement_thEmail: "E-mail",
        customerManagement_thNameSurname: "Name-Surname",
        customerManagement_thNationalIdentity: "National Identity",
        customerManagement_thNationality: "Nationality",
        customerManagement_thPassportNumber: "Passport Number",
        customerManagement_thPhone: "Phone",
        customerSet_btnAdd: "Add",
        customerSet_btnClose: "CLOSE",
        customerSet_lblAgencyName: "Agency Name",
        customerSet_lblBirthdate: "Birth Date",
        customerSet_lblCityCode: "City Code",
        customerSet_lblCountryCode: "Country Code",
        customerSet_lblDateofExpiry: "Date Of Expiry",
        customerSet_lblEmail: "E-mail",
        customerSet_lblGender: "Gender",
        customerSet_lblHesCode: "HES Code",
        customerSet_lblMilesCard: "Miles Card",
        customerSet_lblName: "Name",
        customerSet_lblNationalIdentity: "National Identity",
        customerSet_lblNationality: "Nationality",
        customerSet_lblPassportNumber: "Passport Number",
        customerSet_lblPhoneNumber: "Phone",
        customerSet_lblSerialNumber: "Serial Number",
        customerSet_lblSurname: "Last Name",
        customerSettings_lblAction: "Edit",
        customerSettings_lblAddNewCustomer: "Add New Customer",
        customerSettings_lblBirthday: "Birth Date",
        customerSettings_lblCustomerSettings: "Customer Transactions",
        customerSettings_lblEmail: "E-mail",
        customerSettings_lblFullName: "Name-Surname",
        customerSettings_lblNationalIdentity: "National Identity",
        customerSettings_lblNationality: "Nationality",
        customerSettings_lblPhone: "Phone",
        dashboard_flightForthComings: "ForthComing Flights",
        dashboard_flightGiros: "Ciro Report - Flight",
        dashboard_flightPerformance: "Flight Performance",
        dashboard_flightReservations: "Upcoming Reservations  - Flight",
        dashboard_hotelPerformance: "Hotel Performance",
        dashboard_hotelReservations: "Upcoming Reservations  - Hotel",
        dashboard_hotelUpcomingCheckins: "Upcoming Hotel Check-Ins",
        dashboard_rentACarUpcoming: "ForthComing Rent a Car",
        dashboard_reportsGiro: "Sales Reports",
        dashboard_reservations: "Reservations",
        dashboard_transferUpcoming: "Upcoming Reservations  - Transfer",
        dashboard_upcomings: "Bookings",
        dashboardAnnouncements_noContent: "There are no announcements here.",
        dashboardCampaigns_noContent: "There is no campaign here.",
        dashboardGiro_allProductsTotal: "Total Gross Sales",
        dashboardGiro_apexAjet: "Ajet",
        dashboardGiro_apexCorendon: "XC",
        dashboardGiro_apexFlight: "Flight",
        dashboardGiro_apexHotel: "Hotel",
        dashboardGiro_apexOther: "Others",
        dashboardGiro_apexPegasus: "PC",
        dashboardGiro_apexRentACar: "Rent a Car",
        dashboardGiro_apexSunexpress: "XQ",
        dashboardGiro_apexTransfer: "Transfer",
        dashboardGiro_apexTurkishAirlines: "TK",
        dashboardGiro_giroInfoText: "Turnovers realized in the Sales Report; It shows gross turnover not excluding cancellation, refund and change transactions.",
        dashboardGiro_gross: "Gross",
        dashboardGiro_issuedEntityCount2: "PNR",
        dashboardGiro_issuedEntityCount3: "Voucher",
        dashboardGiro_issuedEntityCount4: "TNR",
        dashboardGiro_issuedEntityCount5: "RNR",
        dashboardGiro_issuedEntityCountDesc2: "(Issued)",
        dashboardGiro_issuedEntityCountDesc3: "(Issued)",
        dashboardGiro_issuedEntityCountDesc4: "(Issued)",
        dashboardGiro_issuedEntityCountDesc5: "(Issued)",
        dashboardGiro_issuedEtickets: "Pax",
        dashboardGiro_issuedPaxCount2: "Pax",
        dashboardGiro_issuedPaxCount3: "Pax",
        dashboardGiro_issuedPaxCountDesc2: "(Issued)",
        dashboardGiro_issuedPaxCountDesc3: "(Issued)",
        dashboardGiro_issuedPNR: "Pnr",
        dashboardGiro_issuedRoomCount3: "Room",
        dashboardGiro_issuedRoomCountDesc3: "(Booked)",
        dashboardGiro_net: "Net",
        dashboardGiro_reservedEntityCount2: "PNR",
        dashboardGiro_reservedEntityCount3: "Voucher",
        dashboardGiro_reservedEntityCountDesc2: "(Reserved)",
        dashboardGiro_reservedEntityCountDesc3: "(Reserved)",
        dashboardGiro_reservedEtickets: "Pax",
        dashboardGiro_reservedPaxCount2: "Pax",
        dashboardGiro_reservedPaxCountDesc2: "(Reserved)",
        dashboardGiro_reservedPNR: "Pnr",
        dashboardGiro_total: "Total",
        dashboardPage_announcements: "Announcements",
        dashboardPage_arrange_off: "Edit Page - OFF",
        dashboardPage_arrange_on: "Edit Page - ON",
        dashboardPage_campaigns: "Top Deals",
        dashboardPage_carModelRoute: "Vehicle  / Pick-up Location",
        dashboardPage_checkInDate: "Check In Date",
        dashboardPage_driverName: "Name-Surname",
        dashboardPage_flight_Flights: "ForthComing Flights",
        dashboardPage_flight_Performance: "Flight Performance",
        dashboardPage_flight_Reservations: "Upcoming Reservations  - Flight",
        dashboardPage_flightMyReservations: "My Reservations - Flight",
        dashboardPage_guestName: "Name Surname",
        dashboardPage_hotel_Checkins: "Upcoming Hotel Check-Ins",
        dashboardPage_hotel_Performance: "Hotel Performance",
        dashboardPage_hotel_Reservations: "Upcoming Reservations  - Hotel",
        dashboardPage_hotelMyCheckIns: "My Check-Ins",
        dashboardPage_hotelMyReservations: "My Reservations - Hotel",
        dashboardPage_hotelName: "Hotel Name",
        dashboardPage_incompleteOrder: "Daily Transactions",
        dashboardPage_lastBookingDateTime: "Last Booking Date",
        dashboardPage_lblAnnouncementDetail: "Announcement Detail",
        dashboardPage_lblAnnouncementList: "Announcement List",
        dashboardPage_lblCampaignList: "Campaign List",
        dashboardPage_lblReadMore: "Read More",
        dashboardPage_lblShowDetails: "Show Details",
        dashboardPage_lblValidDayCount: "Last #VALUE# Day(s)",
        dashboardPage_provideRoute: "Provider / Route",
        dashboardPage_providerRoute: "Route",
        dashboardPage_rentDate: "Pick-up Time",
        dashboardPage_reservationStatus: "Reservation Status",
        dashboardPage_RNR: "RNR",
        dashboardPage_showOldPosts: "Show Old Posts",
        dashboardPage_Time: "Time",
        dashboardPage_TNR: "TNR",
        dashboardPage_totalGiro: "Daily Giro",
        dashboardPage_transferDate: "Transfer Date",
        dashboardPage_travelerName: "Name-Surname",
        dashboardPage_welcomeBack: "Welcome Back",
        dashboardPage_widgetBookingDetails: "Booking Details",
        dashboardPage_widgetBookingNumber: "Booking No.",
        dashboardPage_widgetFlightMyPastReservations: "Renew my past reservations",
        dashboardPage_widgetFlightMyReservations: "My Reservations - Flight",
        dashboardPage_widgetForthComingFlights: "ForthComing Flights",
        dashboardPage_widgetGiroAll: "All",
        dashboardPage_widgetGiroFlight: "Flight",
        dashboardPage_widgetHotelName: "Hotel Name",
        dashboardPage_widgetMyReservations: "ForthComing Flights",
        dashboardPage_widgetNameSurname: "Name Surname",
        dashboardPage_widgetPastReservations: "Past Reservations- Flights",
        dashboardPage_widgetPnr: "PNR",
        dashboardPage_widgetProviderRoute: "Provider / Route",
        dashboardPage_widgetReservationDetails: "Reservation Details",
        dashboardPage_widgetReservationNumber: "Reservation No.",
        dashboardPage_widgetReservationsAll: "All",
        dashboardPage_widgetReservationsFlight: "Flight",
        dashboardPage_widgetReservationsHotel: "Hotel",
        dashboardPage_widgetRnr: "RNR",
        dashboardPage_widgetRoute: "Vehicle / Route",
        dashboardPage_widgetTime: "Time",
        dashboardPage_widgetTnr: "TNR",
        dashboardPage_widgetUpcomingCharterFlights: "Upcoming Charter Flights",
        dashboardPage_widgetUpcomingsAll: "All",
        dashboardPage_widgetUpcomingsFlight: "Flight",
        dashboardPage_widgetUpcomingsHotel: "Hotel",
        dashboardPage_widgetUpcomingsRentACar: "Rent a Car",
        dashboardPage_widgetUpcomingsTransfer: "Transfer",
        dashboardPage_widgetVoucher: "Voucher",
        dashboardTable_last72Hours: "Last 72 hours",
        dashboardTable_onlyFlightChanges: "Flight Change",
        dashboardTable_onlyOnlineCheckIns: "Online Check-in",
        dashboardTable_onlyPartialPaids: "Only Partial Paid",
        dashboardTable_onlyPastReservations: "Past Reservations",
        dashboardTable_onlyWKSC: "Only Schedule Change",
        dashboardTable_print: "Print",
        DocumentModal_lblEmail: "E-mail",
        documentViewer_copyToClipboard: "Copy",
        documentViewer_couldntSendMail: "The mail could not be sent.",
        documentViewer_deselectAll: "Deselect All",
        documentViewer_documentCannotGet: "There was an error loading the document!",
        documentViewer_generatePDF: "Refresh",
        documentViewer_mailSentSuccessful: "The mail has been sent successfully.",
        documentViewer_mailSentSuccessfully: "The mail has been sent successfully.",
        documentViewer_openPdf: "View Pdf",
        documentViewer_paxBasedInfoTitle: "Pax-based print.",
        documentViewer_phEmail: "E-Mails",
        documentViewer_popupCopiedToClipboard: "Copied",
        documentViewer_printDocument: "Print",
        documentViewer_selectAll: "Select All",
        documentViewer_sendEmail: "Send an e-mail.",
        documentViewer_sendToWhatsapp: "Send it via Whatsapp.",
        documentViewer_showCancelPolicies: "Policy Info Show / Hide",
        documentViewer_showOptionDate: "Hide / Show Option Date",
        documentViewer_showPrice: "With Price-No Price",
        documentViewer_documentCannotGet_documentCannotGet: "Document not found.",
        EAccTransactionTypeGroup_BONUS: "Bonus",
        EAccTransactionTypeGroup_CC_COMMISSION: "CC Commission",
        EAccTransactionTypeGroup_CC_PAYMENT: "CC Payment",
        EAccTransactionTypeGroup_F_COM_VPOS: "CC Commission Flight",
        EAccTransactionTypeGroup_F_PAY_VPOS: "CC Payment Flight",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY: "Flight Ticket Ancillary",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY_REV: "Flight Ticket Ancillary Refund",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY_REVERSE: "Flight Ticket Ancillary Refund",
        EAccTransactionTypeGroup_FLIGHT_COMMISSION: "Flight Commission",
        EAccTransactionTypeGroup_FLIGHT_COMMISSION_REVERSE: "Flight Commission Refund",
        EAccTransactionTypeGroup_FLIGHT_FARE: "Flight Ticket Sales",
        EAccTransactionTypeGroup_FLIGHT_FARE_REVERSE: "Flight Ticket Refund",
        EAccTransactionTypeGroup_H_COM_VPOS: "CC Commission Hotel",
        EAccTransactionTypeGroup_H_PAY_VPOS: "CC Payment Hotel",
        EAccTransactionTypeGroup_HOTEL_COMMISSION: "Hotel Commission",
        EAccTransactionTypeGroup_HOTEL_COMMISSION_REVERSE: "Hotel Commission Refund",
        EAccTransactionTypeGroup_HOTEL_FARE: "Hotel Sales",
        EAccTransactionTypeGroup_HOTEL_FARE_REVERSE: "Hotel Refund",
        EAccTransactionTypeGroup_PAYMENT: "Payment",
        EAccTransactionTypeGroup_R_COM_VPOS: "CC Commission Rent A Car",
        EAccTransactionTypeGroup_R_PAY_VPOS: "CC Payment Rent A Car",
        EAccTransactionTypeGroup_RENTACAR_ANCILLARY: "Rent A Car Ancillary",
        EAccTransactionTypeGroup_RENTACAR_ANCILLARY_REVERSE: "Rent A Car Ancillary Refund",
        EAccTransactionTypeGroup_RENTACAR_COMMISSION: "Rent A Car Commission",
        EAccTransactionTypeGroup_RENTACAR_COMMISSION_REVERSE: "Rent A Car Commission Refund",
        EAccTransactionTypeGroup_RENTACAR_EXTRA: "Rent A Car Extras Sale",
        EAccTransactionTypeGroup_RENTACAR_EXTRA_REVERSE: "Rent A Car Extras Refund",
        EAccTransactionTypeGroup_RENTACAR_FARE: "Rent A Car Sale",
        EAccTransactionTypeGroup_RENTACAR_FARE_REVERSE: "Rent A Car Refund",
        EAccTransactionTypeGroup_REV_CC_PAYMENT: "CC Payment Refund",
        EAccTransactionTypeGroup_REV_F_PAY_VPOS: "CC Payment Flight Refund",
        EAccTransactionTypeGroup_REV_H_COM_VPOS: "CC Commission Hotel Refund",
        EAccTransactionTypeGroup_REV_H_PAY_VPOS: "CC Payment Hotel Refund",
        EAccTransactionTypeGroup_REV_R_PAY_VPOS: "CC Commission Rent A Car Refund",
        EAccTransactionTypeGroup_REV_T_COM_VPOS: "CC Commission Transfer Refund",
        EAccTransactionTypeGroup_REV_T_PAY_VPOS: "CC Payment Transfer Refund",
        EAccTransactionTypeGroup_SYSTEM_ERROR_CORRECTION: "System Error Correction",
        EAccTransactionTypeGroup_T_COM_VPOS: "CC Commission Transfer",
        EAccTransactionTypeGroup_T_PAY_VPOS: "CC Payment Transfer",
        EAccTransactionTypeGroup_TRANSFER: "Transfer",
        EAccTransactionTypeGroup_TRANSFER_COMMISSION: "Transfer Commission",
        EAccTransactionTypeGroup_TRANSFER_COMMISSION_REVERSE: "Transfer Commission Refund",
        EAccTransactionTypeGroup_TRANSFER_FARE: "Transfer Sales",
        EAccTransactionTypeGroup_TRANSFER_FARE_REVERSE: "Transfer Refund",
        EAccTransactionTypeGroup_UNKNOWN: "Unknown",
        EAccTransactionTypeGroup_VIRTUAL_CREDIT: "Virtual Credit",
        EComApplication_AGENT_SPA: "AGENT VUE",
        EComGender_FEMALE: "Female",
        EComGender_MALE: "Male",
        EFltCabinType_BUSINESS: "Business",
        EFltCabinType_ECONOMY: "Economy",
        EFltCabinType_UNKNOWN: "Unknown",
        EFltEticketStatus_11: "Reserve - Pending",
        EFltEticketStatus_ALL: "All",
        EFltEticketStatus_BAGGAGE: "Baggage",
        EFltEticketStatus_CANCELED: "Cancelled",
        EFltEticketStatus_ERROR: "Error",
        EFltEticketStatus_FOOD: "Food",
        EFltEticketStatus_ISSUED: "Issued",
        EFltEticketStatus_MEAL: "Food",
        EFltEticketStatus_NEW: "Error - New",
        EFltEticketStatus_OPEN: "Open",
        EFltEticketStatus_OTHER: "Other",
        EFltEticketStatus_PENDING: "Receiving - Pending",
        EFltEticketStatus_PENDING_ISSUED: "Receiving - Pending",
        EFltEticketStatus_PENDING_REFUNDED: "Pending - Refunded",
        EFltEticketStatus_PENDING_RESERVED: "Pending - Reserved",
        EFltEticketStatus_PENDING_VOIDED: "Pending - Void",
        EFltEticketStatus_REFUNDED: "Refund",
        EFltEticketStatus_REISSUED: "Reissued",
        EFltEticketStatus_RESERVED: "Reserved",
        EFltEticketStatus_SEAT: "Seat",
        EFltEticketStatus_UNKNOWN: "Unknown",
        EFltEticketStatus_VOIDED: "Void",
        EFltEticketStatus_WHEELCHAIR: "Wheelchair",
        EFltPaxType_ADULT: "Adult",
        EFltPaxType_ADULT_WITH_INFANT: "Adult With Infant",
        EFltPaxType_ALL: "All",
        EFltPaxType_CHILD: "Child",
        EFltPaxType_DISABLED_COMPANION: "Disabled Companion",
        EFltPaxType_EXTRA_SEAT: "Extra Seat",
        EFltPaxType_INFANT: "Infant",
        EFltPaxType_UNKNOWN: "Unknown",
        EFltPnrStatus_9: "Open",
        EFltPnrStatus_ALL: "All",
        EFltPnrStatus_CANCELED: "Cancelled",
        EFltPnrStatus_ERROR: "Error",
        EFltPnrStatus_ISSUED: "Issued",
        EFltPnrStatus_NEW: "Error - New",
        EFltPnrStatus_OPEN: "Open Ticket",
        EFltPnrStatus_PENDING: "Receiving - Pending",
        EFltPnrStatus_PENDING_CANCELED: "Pending - Cancelled",
        EFltPnrStatus_PENDING_CANCELLED: "Pending - Cancelled",
        EFltPnrStatus_PENDING_ISSUED: "Pending - Issued",
        EFltPnrStatus_PENDING_REFUNDED: "Pending - Refunded",
        EFltPnrStatus_PENDING_RESERVED: "Pending - Reserved",
        EFltPnrStatus_PENDING_VOIDED: "Pending - Void",
        EFltPnrStatus_REFUNDED: "Refunded",
        EFltPnrStatus_REISSUED: "Reissued",
        EFltPnrStatus_RESERVED: "Reserved",
        EFltPnrStatus_UNKNOWN: "Unknown",
        EFltPnrStatus_VOIDED: "Void",
        EFltSeatTypeEnum_AISLE: "Aisle",
        EFltSeatTypeEnum_EXIT: "Exit",
        EFltSeatTypeEnum_EXTRA_LEGROOM: "XL Seat",
        EFltSeatTypeEnum_STANDARD: "Standard",
        EFltSeatTypeEnum_UNKNOWN: "Unknown",
        EFltSeatTypeEnum_WINDOW: "Window",
        EFltSsrFoodType_AVML: "Vegetarian Hindu meal",
        EFltSsrFoodType_BBML: "Baby Meal",
        EFltSsrFoodType_CHML: "Child Meal",
        EFltSsrFoodType_CNML: "Chicken Meal",
        EFltSsrFoodType_DBML: "Diabetic Meal",
        EFltSsrFoodType_FPML: "Fruit Platter Meal",
        EFltSsrFoodType_FSML: "Fish Meal",
        EFltSsrFoodType_GFML: "Gluten Intolerant Meal",
        EFltSsrFoodType_GPML: "Group SSR Snack & 1 Bev.",
        EFltSsrFoodType_HNML: "Hindu Meal",
        EFltSsrFoodType_KSML: "Kosher Meal",
        EFltSsrFoodType_LCML: "Low Calorie Meal",
        EFltSsrFoodType_LFML: "Low Fat Meal",
        EFltSsrFoodType_LPML: "Low Protein Meal",
        EFltSsrFoodType_LSML: "Meal without Salt",
        EFltSsrFoodType_MOML: "Moslem Meal",
        EFltSsrFoodType_NLML: "Meal without Lactose",
        EFltSsrFoodType_NOML: "No Meal",
        EFltSsrFoodType_PMIB: "Breakfast Platter",
        EFltSsrFoodType_PMIC: "Chicken Shish Kebab",
        EFltSsrFoodType_PMID: "Fried Chicken",
        EFltSsrFoodType_PMIE: "Beef Goulasch",
        EFltSsrFoodType_SFML: "Sea Food Meal",
        EFltSsrFoodType_SPML: "Special Meal",
        EFltSsrFoodType_TBML: "T/O 2 Snacks & 1 Water",
        EFltSsrFoodType_VGML: "Vegetarian Vegan Meal",
        EFltSsrFoodType_VJML: "Vegetarain Jain Meal",
        EFltSsrFoodType_VLML: "Vegetarian Meal Lacto-Ovo",
        EFltSsrFoodType_VOML: "Vegetarian Oriental Meal",
        EFltSsrMealType_AVML: "Vegetarian Hindu meal",
        EFltSsrMealType_BBML: "Baby Meal",
        EFltSsrMealType_BLML: "Bland Meal",
        EFltSsrMealType_CHML: "Child Meal",
        EFltSsrMealType_CNML: "Chicken Meal",
        EFltSsrMealType_DBML: "Diabetic Meal",
        EFltSsrMealType_EFltSsrMealType: "Child Meal",
        EFltSsrMealType_FPML: "Fruit Platter Meal",
        EFltSsrMealType_FSML: "Fish Meal",
        EFltSsrMealType_GFML: "Gluten Intolerant Meal",
        EFltSsrMealType_GPML: "Group SSR Snack & 1 Bev.",
        EFltSsrMealType_HNML: "Hindu Meal",
        EFltSsrMealType_KSML: "Kosher Meal",
        EFltSsrMealType_LCML: "Low Calorie Meal",
        EFltSsrMealType_LFML: "Low Fat Meal",
        EFltSsrMealType_LPML: "Low Protein Meal",
        EFltSsrMealType_LSML: "Meal without Salt",
        EFltSsrMealType_MOML: "Moslem Meal",
        EFltSsrMealType_NLML: "Meal without Lactose",
        EFltSsrMealType_NOML: "No Meal",
        EFltSsrMealType_PMIB: "Breakfast Platter",
        EFltSsrMealType_PMIC: "Chicken Shish Kebab",
        EFltSsrMealType_PMID: "Fried Chicken",
        EFltSsrMealType_PMIE: "Beef Goulasch",
        EFltSsrMealType_PRIBAS_SUN_CHML: "Child Meal",
        EFltSsrMealType_PRIBAS_SUN_PMDB: "Breakfast Platter",
        EFltSsrMealType_PRIBAS_SUN_PMDC: "Chicken Shish Kebab",
        EFltSsrMealType_PRIBAS_SUN_PMDD: "Fried Chicken",
        EFltSsrMealType_PRIBAS_SUN_PMDE: "Beef Goulasch",
        EFltSsrMealType_PRIBAS_SUN_PMIB: "Breakfast Platter",
        EFltSsrMealType_PRIBAS_SUN_PMIC: "Chicken Shish Kebab",
        EFltSsrMealType_PRIBAS_SUN_PMID: "Fried Chicken",
        EFltSsrMealType_PRIBAS_SUN_PMIE: "Beef Goulasch",
        EFltSsrMealType_PRIBAS_SUN_SCSM: "Snack Box & 1 water",
        EFltSsrMealType_PRIBAS_SUN_SPDB: "SunPremium Omelette & 2 Bev.",
        EFltSsrMealType_PRIBAS_SUN_SPDC: "SunPremium Meatball & 2 Bev.",
        EFltSsrMealType_PRIBAS_SUN_SPDD: "SunPremium Aubergi. & 2 Bev.",
        EFltSsrMealType_PRIBAS_SUN_SPDE: "SunPremium Chicken & 2 Bev.",
        EFltSsrMealType_PRIBAS_SUN_SPIB: "SunPremium Meatball & 2 Bev.",
        EFltSsrMealType_PRIBAS_SUN_SPIC: "SunPremium Chicken & 2 Bev.",
        EFltSsrMealType_PRIBAS_SUN_SPID: "SunPremium Ravioli & 2 Bev.",
        EFltSsrMealType_PRIBAS_SUN_SPIE: "SunPremium Salad & 2 Bev.",
        EFltSsrMealType_PRIBAS_SUN_SPVG: "Falafel (vegan) & 1 non-alcoholic beverage",
        EFltSsrMealType_PRIBAS_SUN_SPVL: "Mezze Platter (vegetarian) & 1 non-alcoholic beverage",
        EFltSsrMealType_PRIBAS_SUN_VGML: "Falafel (vegan)",
        EFltSsrMealType_PRIBAS_SUN_VLML: "Mezze Platter (vegetarian)",
        EFltSsrMealType_SFML: "Sea Food Meal",
        EFltSsrMealType_SPML: "Special Meal",
        EFltSsrMealType_TAML: "T/O 1 Sweet Snack & 1 Water",
        EFltSsrMealType_TBML: "T/O 2 Snacks & 1 Water",
        EFltSsrMealType_VGML: "Vegan Meal",
        EFltSsrMealType_VJML: "Vegetarain Jain Meal",
        EFltSsrMealType_VOML: "Vegetarian Oriental Meal",
        EFltSsrType_BAGGAGE: "Baggage",
        EFltSsrType_CABIN_BAG: "Cabin Bag",
        EFltSsrType_CHECKIN_AT_AIRPORT: "Check-in",
        EFltSsrType_FOOD: "Food",
        EFltSsrType_MEAL: "At Meal",
        EFltSsrType_OTHER: "Other",
        EFltSsrType_REISSUE_ALLOWANCE: "Sun Flex 3",
        EFltSsrType_SEAT: "Seat",
        EFltSsrType_WHEELCHAIR: "Wheelchair",
        EFltSsrWheelchairType_WCBD: "Dry Cell battery operated wheelchair",
        EFltSsrWheelchairType_WCHC: "Wheelchair (Pax completely immobile and cannot climb stairs)",
        EFltSsrWheelchairType_WCHR: "Wheelchair for the Ramp.(Can climb stairs.)",
        EFltSsrWheelchairType_WCHS: "Wheelchair (Can't climb stairs.)",
        EFltSsrWheelchairType_WCLB: "Passenger who has own Lithium Battery powered wheelchair",
        EFltSsrWheelchairType_WCMP: "Wheelchair Manuel (Power Driven)",
        EHtlAmountType_FIXED: "Fixed",
        EHtlAmountType_RATIO: "Ratio",
        EHtlGuestType_ADULT: "Adult",
        EHtlGuestType_CHILD: "Child",
        EHtlPolicyType_CANCELLATION: "Cancellation",
        EHtlPolicyType_NO_SHOW: "No Show",
        EHtlPriceRestrictionType_ALL: "All",
        EHtlPriceRestrictionType_MIN_STAY: "MinimumStay",
        EHtlPriceRestrictionType_NO_RESTRICTION: "No Restriction",
        EHtlPriceRestrictionType_QUOTA: "Quota",
        EHtlPriceRestrictionType_RELEASE: "Release",
        EHtlPriceRestrictionType_STOP: "Stop",
        EHtlPriceRestrictionType_UNKNOWN: "Unknown",
        EHtlRefundType_NONREFUNDABLE: "Non Refundable",
        EHtlRefundType_REFUNDABLE: "Refundable",
        EHtlVoucherStatus_ALL: "All",
        EHtlVoucherStatus_BOOKED: "Booked",
        EHtlVoucherStatus_CANCELED: "Canceled from Reserve",
        EHtlVoucherStatus_ERROR: "Error",
        EHtlVoucherStatus_NEW: "New",
        EHtlVoucherStatus_PARTIAL_PAID: "Partial Paid",
        EHtlVoucherStatus_REBOOKED: "Rebooked",
        EHtlVoucherStatus_REFUNDED: "Cancellation with Penalty",
        EHtlVoucherStatus_RESERVED: "Reserved",
        EHtlVoucherStatus_UNKNOWN: "Unknown",
        EHtlVoucherStatus_VOIDED: "Canceled from Booked",
        "enum_airport-checkin": "Airport Check-in",
        enum_all: "All",
        enum_AVML: "Vegetarian Hindu meal",
        enum_baggage: "Baggage",
        enum_BBML: "Baby Meal",
        enum_BLML: "Bland Meal",
        enum_booked: "Booked",
        enum_business: "Business",
        "enum_cabin-bag": "1 Piece Of Cabin Baggage",
        enum_canceled: "Cancelled",
        enum_cancellationPolicies_fixed: "For cancellations made between the dates {0} - {1} corresponding penalty is {2}{3}.",
        enum_cancellationPolicies_fixed_no_show: "{1}{2} for cancellations made after {0} No-Show penalty will be applied.",
        enum_cancellationPolicies_ratio: "For cancellations made between the dates {0} - {1} corresponding penalty is {2}{3}.",
        enum_cancellationPolicies_ratio_no_show: "{1}{2} for cancellations made after {0} No-Show penalty will be applied.",
        enum_cancelled: "Cancelled",
        enum_cc_commission: "CC Commission",
        enum_cc_payment: "CC Payment",
        enum_checkin_at_airport: "Airport Check-in",
        enum_CHML: "Child Meal",
        enum_close: "Closed",
        enum_closed: "Closed",
        enum_CNML: "Chicken Meal",
        enum_completed: "Completed",
        enum_date1: "Creation From Date",
        enum_DBML: "Diabetic Meal",
        enum_duplicate: "Duplicate",
        enum_economy: "Economy",
        enum_eHtlAmountType_ALL: "All",
        enum_eHtlAmountType_FIXED: "Fixed",
        enum_eHtlAmountType_RATIO: "For cancellations made between the dates {0} - {1} corresponding penalty is {2}{3}.",
        enum_eHtlAmountType_UNKNOWN: "Unknown",
        enum_EHtlRoomPriceStatus_NEW: "New",
        enum_EHtlRoomPriceStatus_RESERVED: "Reserved",
        enum_EHtlVoucherStatus_ALL: "All",
        enum_EHtlVoucherStatus_BOOKED: "Booked",
        enum_EHtlVoucherStatus_CANCELLED: "Canceled from Reserve",
        enum_EHtlVoucherStatus_ERROR: "Error",
        enum_EHtlVoucherStatus_NEW: "New",
        enum_EHtlVoucherStatus_REBOOKED: "Rebooked",
        enum_EHtlVoucherStatus_REFUNDED: "Cancellation with Penalty",
        enum_EHtlVoucherStatus_RESERVED: "Reserved",
        enum_EHtlVoucherStatus_UNKNOWN: "Unknown",
        enum_EHtlVoucherStatus_VOIDED: "Cancelled From Booked",
        enum_enum_replied_by_agency: "Response Received From Agency",
        enum_error: "Error",
        enum_f_com_vpos: "CC Commission Flight",
        enum_f_pay_vpos: "CC Payment Flight",
        enum_fcadom: "Flight Dom. / Invoice / Current",
        enum_fcaint: "Flight Int. / Invoice / Current",
        enum_fccdom: "Flight Dom. / Invoice / Credit Card",
        enum_fccint: "Flight Int. / Invoice / Credit Card",
        enum_fcodom: "Flight Dom. / Invoice / Credit Card Commission",
        enum_fcoint: "Flight Int. / Invoice / Credit Card Commission",
        enum_FPML: "Fruit Platter Meal",
        enum_FSML: "Fish Meal",
        enum_GFML: "Gluten Intolerant Meal",
        "enum_hand-wallet": "1 Piece of Underseat Bag Only (Must be with a maximum size of 40x30x15 cm)",
        enum_HNML: "Hindu Meal",
        enum_hotel: "Hotel",
        enum_insurance: "Insurance",
        enum_invoice: "Invoice",
        enum_issued: "Issued",
        enum_KSML: "Kosher Meal",
        enum_LCML: "Low Calorie Meal",
        enum_LFML: "Low Fat Meal",
        enum_LPML: "Low Protein Meal",
        enum_LSML: "Meal without Salt",
        enum_meal: "Meal",
        enum_MOML: "Moslem Meal",
        enum_new: "New",
        enum_NLML: "Meal without Lactose",
        enum_open: "Open",
        enum_other: "other",
        enum_package_info: "Package Info Task",
        enum_partial_paid: "Partial Paid",
        enum_payment: "Payment",
        enum_payment_reverse: "Payment Reverse",
        enum_pending_canceled: "Pending - Cancelled",
        enum_pending_issued: "Pending - Issued",
        enum_pending_refunded: "Pending - Refunded",
        enum_pending_reserved: "Pending - Reserved",
        enum_pending_voided: "Pending - Void",
        enum_pnr: "Pnr",
        enum_pnr_add: "Manual Pnr Upload",
        enum_pnr_ancillary: "Ancillary",
        enum_pnr_ancillary_rev: "Flight Ticket Ancillary Refund",
        enum_pnr_commission: "Flight Commission",
        enum_pnr_commission_reverse: "Flight Commission Refund",
        enum_pnr_correction: "Pnr Correction",
        enum_pnr_divide: "Pnr - Divide",
        enum_pnr_fare: "Flight Ticket Sales",
        enum_pnr_fare_reverse: "Flight Ticket Refund",
        enum_pnr_open: "Open Ticket",
        enum_pnr_reissue: "Reissue",
        enum_pnr_reverse: "Cancellation - Refund",
        enum_pnr_update: "Pnr Update",
        enum_pnr_update_pax: "Passenger Information Update",
        enum_processing: "Processing",
        enum_receiving_pending: "Receiving - Pending",
        enum_refunded: "Refunded",
        "enum_reissue-allowance": "Sun Flex 3",
        enum_reissued: "Reissued",
        enum_reopen: "Reopen",
        enum_replied_by_agency: "Response Received From Agency",
        enum_replied_by_provider: "Replied by Provider.",
        enum_reserved: "Reserved",
        enum_rev_f_com_vpos: "CC Commission Flight Refund",
        enum_rev_f_pay_vpos: "CC Payment Flight Refund",
        Enum_rnr_ancillary: "Rnr Ancillary",
        enum_rnr_reissue: "Rent A Car Reissue",
        enum_rnr_reverse: "Rent A Car Cancelation",
        enum_seat: "Seat",
        enum_SFML: "Sea Food Meal",
        enum_sunflex: "Rebooking without fee up to 3 days before departure (+ price difference).",
        enum_tnr_add: "Transfer Add",
        enum_tnr_reissue: "Transfer Reissue",
        enum_tnr_reverse: "Transfer Cancelation",
        enum_transfer: "Transfer",
        enum_unknown: "Unknown",
        enum_VGML: "Vegetarian Vegan Meal",
        enum_VJML: "Vegetarain Jain Meal",
        enum_VLML: "Vegetarian Meal Lacto-Ovo",
        enum_void: "Void",
        enum_voided: "Void",
        enum_VOML: "Vegetarian Oriental Meal",
        enum_voucher_add: "Voucher Add",
        enum_voucher_reissue: "Hotel Reissued",
        enum_voucher_reverse: "Cancellation - Refund",
        enum_voucher_roomdateprice_update: "Voucher Update",
        enum_voucher_update: "Voucher Update",
        Enum_waiting: "Waiting",
        enum_waiting_on_agency: "Waiting on Agency",
        enum_waiting_on_operator: "Waiting on Operatör",
        Enum_waiting_on_provider: "Waiting on Operatör",
        enum_WCBD: "Dry battery chair.",
        enum_WCHC: "Wheelchair (Pax completely immobile and cannot climb stairs)",
        enum_WCHR: "Wheelchair for the Ramp.(Can climb stairs.)",
        enum_WCHS: "Wheelchair (Can't climb stairs.)",
        enum_WCLB: "Passenger who has own Lithium Battery powered wheelchair",
        enum_WCMP: "Wheelchair Manuel (Power Driven)",
        enum_wheelchair: "Wheelchair",
        enum_wont_fix: "Won't Fix",
        EOdrEntityType_HOTEL: "VOUCHER",
        EOdrEntityType_INSURANCE: "Insurance",
        EOdrEntityType_PNR: "PNR",
        EOdrEntityType_TRANSFER: "TRANSFER",
        EOdrEntityType_UNKNOWN: "Unknown",
        EOdrSearchHotelDateType_CHECKIN: "Check In Date",
        EOdrSearchHotelDateType_PROCESS: "Sale Date",
        EOdrSearchRentACarDateType_PICKUP: "Pick-up",
        EOdrSearchRentACarDateType_PROCESS: "Sales",
        EOdrType_FLIGHT: "Flight",
        EPayCardBrand_UNKNOWN: "Unknown",
        EProcessType_FLIGHT_ONLINE_ANCILLARY_SALE: "Online Ancillary Sales",
        ERacAncillaryType_ALL: "All",
        ERacAncillaryType_BABY_SEAT: "Baby/Child Seat",
        ERacAncillaryType_EXTRA_DAMAGE: "Extra Damage",
        ERacAncillaryType_EXTRA_PICKUP_DROPOFF: "Pick up or Drop off from different point",
        ERacAncillaryType_EXTRA_TRAFFIC_PENALTY: "Extra Traffic Penalty",
        ERacAncillaryType_INSURANCE_ECO: "Tire - Glass - Headlight Insurance",
        ERacAncillaryType_INSURANCE_PREMIUM: "Insurance",
        ERacAncillaryType_INTERNET: "Internet",
        ERacAncillaryType_MANDAT_ONEWAY_TRANSFER: "One way transfer",
        ERacAncillaryType_NAVIGATION: "Navigation",
        ERacAncillaryType_SNOW_CHAIN: "Snow Chain",
        ERacAncillaryType_SNOW_TIRE: "Snow Tire",
        ERacAncillaryType_UNKNOWN: "Unknown",
        ERacBodyType_ALL: "All",
        ERacBodyType_HATCHBACK: "Hatchback",
        ERacBodyType_MINIVAN: "Minivan",
        ERacBodyType_SEDAN: "Sedan",
        ERacBodyType_SUV: "Suv",
        ERacBodyType_UNKNOWN: "Unknown",
        ERacBodyType_VAN: "Van",
        ERacFacilityType_AIRPORT_SURCHARGE: "Airport Surcharge",
        ERacFacilityType_ALL: "All",
        ERacFacilityType_COLLISION_DAMAGE_WAIVER: "Kasko",
        ERacFacilityType_FREE_CANCELLATION: "Free Cancellation (last 24 hours)",
        ERacFacilityType_THEFT_WAIVER: "Theft Waiver",
        ERacFacilityType_THIRD_PARTY_LIABILITY_INSURANCE: "Third Party Liability Insurance",
        ERacFacilityType_UNKNOWN: "Unknown",
        ERacFacilityType_UNLIMITED_MILEAGE: "Unlimited Mileage",
        ERacFuelType_ALL: "All",
        ERacFuelType_DIESEL: "Diesel",
        ERacFuelType_ELECTRIC: "Electric",
        ERacFuelType_GASOLINE: "Gasoline",
        ERacFuelType_UNKNOWN: "Unknown",
        ERacGearType_ALL: "All",
        ERacGearType_AUTOMATIC: "Automatic",
        ERacGearType_MANUEL: "Manuel",
        ERacGearType_UNKNOWN: "Unknown",
        ERacPassengerCapacity_3: "3 Passenger",
        ERacPassengerCapacity_5: "5 Passengers",
        ERacPassengerCapacity_7: "7 Passengers",
        ERacPassengerCapacity_9: "9 Passengers",
        ERacRnrStatus_ALL: "All",
        ERacRnrStatus_CANCELED: "Canceled",
        ERacRnrStatus_ERROR: "Error",
        ERacRnrStatus_ISSUED: "Issued",
        ERacRnrStatus_NEW: "New",
        ERacRnrStatus_OPEN: "Open",
        ERacRnrStatus_PENDING: "Receiving - Pending",
        ERacRnrStatus_REFUNDED: "Refunded",
        ERacRnrStatus_REISSUED: "Reissued",
        ERacRnrStatus_RESERVED: "Reserved",
        ERacRnrStatus_VOIDED: "Void",
        EReportHotelDateType_CHECK_IN: "Check In",
        EReportHotelDateType_CHECK_OUT: "Check Out",
        EReportHotelDateType_SALE: "Sales",
        EReportRentACarDateType_PICKUP: "Pick-up Date and Time",
        EReportRentACarDateType_PROCESS: "Sale Date",
        EReportRentACarReportType_ANCILLARY: "Ancillary Services",
        EReportRentACarReportType_VEHICLE: "Car",
        EReportSalesType_REISSUE: "Reissue",
        EReportSalesType_SALE: "Sale",
        EReportTransferDateType_ALL: "All",
        EReportTransferDateType_SALE: "Sale",
        EReportTransferDateType_TRANSFER: "Transfer Date",
        EReportTransferDateType_UNKNOWN: "Unknown",
        "ERROR_CODES_AGENT_API.FLIGHT_TICKET.SUCCESS.PAYMENT_POST": "Your transaction has been completed successfully.",
        "ERROR_CODES_AGENT_API.HOTEL_TICKET_RESERVED.REJECTED.INSUFFICIENT_PAYMENT": "You received an error during payment.",
        "ERROR_CODES_FLIGHT_API.FLIGHT_RESERVATION.PARAMETER.INVALID_PASSENGER_INFO": "Please check the passenger information you have entered.",
        ErrorReportList_thEntityType: "Product Type",
        ESchmetterlingOrderStatus_ALL: "All",
        ESchmetterlingOrderStatus_BOOKED: "Booked",
        ESchmetterlingOrderStatus_CANCELED: "Canceled",
        ESchmetterlingOrderStatus_DISPLAYED: "Successful displayed",
        ESchmetterlingOrderStatus_OPTIONAL_BOOKED: "Optional",
        ESchmetterlingOrderStatus_RESERVED: "Reserved",
        ESchmetterlingOrderStatus_UNKNOWN: "Unknown",
        ETaskClosingType_ALL: "All",
        ETaskClosingType_CANCELED: "Canceled",
        ETaskClosingType_COMPLETED: "Completed",
        ETaskClosingType_DUBLICATE: "Duplicate",
        ETaskClosingType_UNKNOWN: "Unknown",
        ETaskClosingType_WONT_FIX: "Won't Fix",
        ETaskStatus_ALL: "All",
        ETaskStatus_CLOSED: "Closed",
        ETaskStatus_OPEN: "Open",
        ETaskStatus_UNKNOWN: "Unknown",
        ETaskStatus_waiting_on_agency: "Waiting on Agency",
        ETaskSubStatus_8: "Answered",
        ETaskSubStatus_ALL: "All",
        ETaskSubStatus_CLOSED: "Closed",
        ETaskSubStatus_NEW: "New",
        ETaskSubStatus_PROCESSING: "Processing",
        ETaskSubStatus_REPLIED_BY_AGENCY: "Agency Response",
        ETaskSubStatus_REPLIED_BY_PROVIDER: "Replied by Provider",
        ETaskSubStatus_UNKNOWN: "Unknown",
        ETaskSubStatus_WAITING: "Waiting",
        ETaskSubStatus_WAITING_ON_AGENCY: "Waiting on Agency",
        ETaskSubStatus_WAITING_ON_OPERATOR: "Waiting on Operatör",
        ETaskSubStatus_WAITING_ON_PROVIDER: "Waiting on Provider",
        ETaskSubStatusETaskSubStatus_REPLIED_BY_PROVIDER: "Response From Provider",
        ETaskSubType_BASIC: "Basıc",
        ETaskSubType_FLIGHT_CHANGE_CONFIRM_MAJOR: "Planned Change",
        ETaskSubType_FLIGHT_CHANGE_CONFIRM_MINOR: "Mandatory Change",
        ETaskType_7: "Manual Sales",
        ETaskType_ALL: "All",
        ETaskType_PAYMENT_REVERSE: "Payment Reverse",
        ETaskType_PNR_ADD: "Manual Pnr Upload",
        ETaskType_PNR_ANCILLARY: "Pnr  Ancillary",
        ETaskType_PNR_CORRECTION: "Pnr Correction",
        ETaskType_PNR_DIVIDE: "Pnr - Divide",
        ETaskType_PNR_FLIGHT_CHANGE_CONFIRMATION: "Auto - Change Notification",
        ETaskType_PNR_OPEN: "Open Ticket",
        ETaskType_PNR_REISSUE: "Pnr Reissue",
        ETaskType_PNR_RESERVE: "Pnr Cancellation - Refund",
        ETaskType_PNR_REVERSE: "Pnr Cancellation - Refund",
        ETaskType_PNR_UPDATE: "Pnr Update",
        ETaskType_PNR_UPDATE_PAX: "Passenger Information Update",
        ETaskType_TNR_ADD: "Manuel TNR Add",
        ETaskType_TNR_REISSUE: "Transfer Reissue",
        ETaskType_TNR_REVERSE: "TNR Reverse",
        ETaskType_UNKNOWN: "Unknown",
        ETaskType_VOUCHER_ADD: "Manual Voucher Upload",
        ETaskType_VOUCHER_NOTE: "Agency Note",
        ETaskType_VOUCHER_RECONFIRMATION: "Reconfirmation Task",
        ETaskType_VOUCHER_REISSUE: "Hotel Reissued",
        ETaskType_VOUCHER_REVERSE: "Hotel Cancellation - Refund",
        ETaskType_VOUCHER_ROOMGUEST_UPDATE: "Hotel Guest Update",
        ETaskType_VOUCHER_UPDATE: "Update Status",
        ETasLogStatus_FLIGHT: "Flight",
        ETrfFacilityType_UNKNOWN: "Free cancellation up to 48 hours",
        ETrfFacilityType_VOIDABLE_BEFORE_24H: "Free cancellation up to 24 hours",
        ETrfFacilityType_VOIDABLE_BEFORE_36H: "Free cancellation up to 36 hours",
        ETrfLegStatus_ALL: "All",
        ETrfLegStatus_CANCELED: "Canceled",
        ETrfLegStatus_ERROR: "Error",
        ETrfLegStatus_ISSUED: "Issued",
        ETrfLegStatus_NEW: "New",
        ETrfLegStatus_OPEN: "Open",
        ETrfLegStatus_PENDING: "Pending",
        ETrfLegStatus_REFUNDED: "Refunded",
        ETrfLegStatus_REISSUED: "Reissued",
        ETrfLegStatus_RESERVED: "Reserved",
        ETrfLegStatus_UNKNOWN: "Unknown",
        ETrfLegStatus_VOIDED: "Void",
        ETrfTnrStatus_ALL: "All",
        ETrfTnrStatus_CANCELED: "Canceled",
        ETrfTnrStatus_ERROR: "Error",
        ETrfTnrStatus_ISSUED: "Issued",
        ETrfTnrStatus_NEW: "New",
        ETrfTnrStatus_OPEN: "Open",
        ETrfTnrStatus_PENDING: "Pending",
        ETrfTnrStatus_REFUNDED: "Refunded",
        ETrfTnrStatus_REISSUED: "Reissued",
        ETrfTnrStatus_RESERVED: "Reserved",
        ETrfTnrStatus_UNKNOWN: "Unknown",
        ETrfTnrStatus_VOIDED: "Void",
        ETrfTransferType_ALL: "All",
        ETrfTransferType_GROUP: "Group",
        ETrfTransferType_INDIVIDUAL: "Individual",
        ETrfTransferType_UNKNOWN: "Unknown",
        ETrfTravelerType_ADULT: "Adult",
        ETrfTravelerType_CHILD: "Child",
        ETrfTravelerType_INFANT: "Infant",
        ETrfVehicleCategory_ALL: "All",
        ETrfVehicleCategory_CAR: "Car",
        ETrfVehicleCategory_MIDIBUS: "Midibus",
        ETrfVehicleCategory_MINIBUS: "Minibus",
        ETrfVehicleCategory_MINIVAN: "Minivan",
        ETrfVehicleCategory_MINIVAN_VIP: "VIP Minivan",
        ETrfVehicleCategory_OTOBUS: "Bus",
        ETrfVehicleCategory_UNKNOWN: "Unknown",
        ETrfVehicleCategory_VAN: "Van",
        filterComponent_boardType_titleLabel: "Board Type",
        filterComponent_class_selectedClassCount_label: "Stars selected",
        filterComponent_class_titleLabel: "Stars",
        filterComponent_facilities_titleLabel: "Facilities",
        filterComponent_information_titleLabel: "Search Collapse",
        filterComponent_prices_titleLabel: "Price Range",
        flight_fillPaxRows: "Fill",
        flight_fillPaxRowsCancelBtn: "Cancel",
        flight_fillPaxRowsContent: "Would you like to auto-fill passenger information?",
        flight_fillPaxRowsTooltip: "Autofill Passengers",
        flight_fillPaxRowsTooltipHighlighter: "Use passenger communication.",
        flight_fillPaxRowsTtitle: "Passenger Information",
        flight_flightCabinType: "Cabin Type",
        flight_flightClass: "Class",
        flight_operatingAirline: "Airlines",
        flight_paxBirthDate: "Birth Date",
        flight_paxInfo: "Passenger Information",
        flight_paxNameSurname: "Name-Surname",
        flight_paxType: "Pax Type",
        FLIGHT_PRIBAS_KEY57: "1 Small piece of hand baggage (up to 4 kg & 40x30x20 cm)",
        flightBrands_lblBaggageInfoUnknown: "Baggage Unknown",
        flightBrands_lblBrandCalculate: "Calculate",
        flightCard_directFlight: "None Stop",
        flightCard_operatedBy: "Operated By",
        flightCard_showDetails: "Details",
        flightCardComponent_oneWay: "One Way",
        flightcardcomponent_operatedBy: "Operated By",
        flightChange_lblApprove: "Confirm Change",
        flightChange_lblPnrReissue: "Request for Change",
        flightChange_lblPnrReverse: "Cancellation Request",
        flightChangeAlert_lblMsg: "Your flight has been changed or canceled. Please check.",
        flightChangeAlert_lblTitle: "Info",
        flightCheckout_agencyExtraCCommission: "Agency Adds Extra Commission.",
        FlightCheckout_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "Your user is not authorized to do this operation. Please check your agent.",
        FlightCheckout_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Reservation cannot be made. Please continue the process by renewing your search.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "There was a price difference during your transaction.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_PRICE_DIFFERENCE_AFTER_RESERVATION: "There was a price difference during your transaction.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during payment.",
        flightCheckout_BrandPriceUnavailable: "The price for this package could not be obtained from the airline.",
        flightCheckout_brandSelection: "The brand is being changed.",
        FlightCheckout_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "We encountered an unknown error.",
        FlightCheckout_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNMAPPED_ERROR: "We encountered an unknown error during ticketing. Please contact our Service Center Team for the cause of the error.",
        flightCheckout_lblAgencyExtraCommission: "Agency Extra Commission",
        flightCheckout_lblBaseTotal: "Net Price",
        flightCheckout_lblGrandTotal: "Grand Total",
        flightCheckout_SELECTED_BRANDS_NOT_VALID_NOW: "The airline does not give a price for the brand you choose. You can try again by changing your selection.",
        flightCheckout_SSR_rezervationDisabled: "The airline does not allow reserving because you have added ssr. You need to make a direct ticketing.",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "An Error Occurred",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Error",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "Price Difference",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "An Error Occurred",
        FlightCheckout_TITLE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "Error",
        flightCheckoutPayment_priceDifference: "Do you accept the AMOUNTPLACEHOLDER price difference?",
        flightCheckoutPayment_transectionProgress: "Your transaction is in progress. Please wait.",
        flightInformationsComponent_baggageAllowance: "Baggage Allowance",
        flightInformationsComponent_cabinType: "Cabin Type",
        flightInformationsComponent_class: "Class",
        flightInformationsComponent_flight: "Flight",
        flightInformationsComponent_flightInformation: "Flight information",
        flightInformationsComponent_timeChanged: "There is a change in your flight information.",
        flightInformationsComponent_travelDuration: "Travel Duration",
        "flightLegHeader_show/hideResults": "Show / Hide Results",
        flightMatrix_showHideAll: "All Airlines",
        FlightOffer_flight: "Flight",
        flightOrderDetail_btnCalculatePenalty: "Calculate Penalty",
        flightOrderDetail_lblAgencyExtraCommission: "Agency Extra Commission",
        flightOrderDetail_lblAgencyServiceFee: "Agency Service Fee",
        flightOrderDetail_lblAncillaryTotal: "Ancillary Total",
        flightOrderDetail_lblBaseTotal: "Net Price",
        flightOrderDetail_lblCalculatingPenalty: "Calculating the Penalty Amount",
        flightOrderDetail_lblCalculatingPenaltyTxt: "Your amount is being calculated. Please wait.",
        flightOrderDetail_lblCancellationWithPenalty: "Refund with Penalty",
        flightOrderDetail_lblCancellationWithPenaltyTxt: "You can see the penalty fee and the refund amount by calculating.",
        flightOrderDetail_lblGrandTotal: "Grand Total",
        flightOrderDetail_lblPackageGrandTotal: "Package Grand Total",
        flightOrderDetail_lblPaymentFeeTotal: "Credit Card Transaction Fee",
        flightOrderDetail_lblRefundWithPenalty: "Penalty Amount",
        flightOrderDetail_lblReissueTotal: "Change Total",
        flightOrderDetail_lblReverseTotal: "Total Refund",
        flightOrderDetail_lblReverseTotalAncillary: "Ancillary Refund",
        flightOrderDetail_lblSupplierReturn: "Provider Refund",
        flightOrderDetail_msgRESERVATED_FLIGHT_CHANGED: "There is a schedule change for this flight. Please contact the service center.",
        flightOrderDetail_ticketIssuedNotificaiton: "You have successfully created it, your details are below.",
        flightOrderDetail_ticketReservedNotificaiton: "You have successfully reserved your ticket.",
        flightOrderDetail_txtFlight: "Flight",
        flightPassengerInformationsComponent_passengerInformations: "Passenger Information",
        flightPayment_infoCreditCard: "A fixed 2% bank commission rate will be added to the Credit card deposit.",
        flightPayment_infoSofort: "In case of making Sofort payment; sum between 1€ and 500€ fixed 3€ and sum above 501€ fixed 5€ will be added to the total amount.",
        FlightPayment_INSUFFICIENT_AGENCY_BALANCE: "Your available balance is insufficient.  Please contact our team.",
        FlightPaymentWidget_btnReverse: "Cancellation Without Penalty",
        FlightPaymentWidget_popup_REVERSE_FLIGHT: "Your ticket will be cancelled. Do you confirm ?",
        FlightPaymentWidget_popup_title: "Information",
        flightPriceInformationsComponent_priceInformations: "Price Info",
        flightSaleReport_currency: "Currency",
        flightSaleReport_flightDate: "Flight Date",
        flightSaleReport_paxCount: "Pax Count",
        flightSaleReport_pnrCount: "Pnr Count",
        flightSaleReport_refundTotal: "Refund Amount",
        flightSaleReport_totalAmount: "Sales Amount",
        flightSaleReport_transactionDate: "Transaction Date",
        flightSalesReport_airlineList: "Airlines",
        flightSalesReport_creationFromDate: "From Date",
        flightSalesReport_creationToDate: "Creation To Date",
        flightSalesReport_currency: "Currency",
        flightSalesReport_flightDate: "Flight Date",
        flightSalesReport_optEticket: "E-Ticket",
        flightSalesReport_optPNR: "Pnr",
        flightSalesReport_paxCount: "Pax Count",
        flightSalesReport_pnrCount: "Pnr Count",
        flightSalesReport_pnrStatus: "Pnr Status",
        flightSalesReport_providerList: "Provider List",
        flightSalesReport_refundTotal: "Refund Amount",
        flightSalesReport_reportType: "Report Type",
        flightSalesReport_showReissues: "Show Reissue",
        flightSalesReport_title: "Flight Sales Report",
        flightSalesReport_totalAmount: "Sales Amount",
        flightSalesReport_transactionDate: "Transaction Date",
        flightSalesReport_userList: "User List",
        flightSearchForm_lblMultiLeg: "Multi Leg",
        flightSearchResult_lblFamilyPricesToggler: "Show Lowest Package Prices",
        flightSearchResult_lblOkay: "I understand",
        flightSearchResult_tourFamilyPricesToggler: "You can use it to see the lowest bundled rate.",
        FlightSelect_AGENT_API_FLIGHT_CHECKOUT_EXCEPTION_CHECKOUT: "We encountered an unknown error.",
        FlightSelect_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Prices may have been updated because you haven't traded for a long time. You can continue your process by renewing your search.",
        FlightSelect_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Please select 'Conjugate' flights, or deselect if you have a 'One Way Fare' selection.",
        FlightSelect_TITLE_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Price information may have changed.  Please continue your search by searching again.",
        FlightSelect_TITLE_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Please select 'Conjugate' flights, or deselect if you have a 'One Way Fare' selection.",
        flightsResults_noFlightWarning: "No Flight Found.",
        girosWidget_gross: "Gross",
        girosWidget_net: "Net",
        girosWidget_others: "OTHER",
        gtsDataTableComponent_checkIn: "Online Check-in",
        gtsDataTableComponent_daily: "Daily",
        gtsDataTableComponent_emptyEntity: "empty entity",
        gtsDataTableComponent_firstLabel: "First",
        gtsDataTableComponent_flightChange: "Flight Change",
        gtsDataTableComponent_lastLabel: "End",
        gtsDataTableComponent_lblWKSC: "Schedule Change",
        gtsDataTableComponent_monthly: "Monthly",
        gtsDataTableComponent_nextLabel: "Next",
        gtsDataTableComponent_partialPayment: "Remaining Payment",
        gtsDataTableComponent_performanceBarChartdaily: "Daily Pax",
        gtsDataTableComponent_performanceBarChartmonthly: "Monthly Pax",
        gtsDataTableComponent_performanceBarChartweekly: "Weekly Pax",
        gtsDataTableComponent_prevLabel: "Prev",
        gtsDataTableComponent_rowsPerPageLabel: "Show on Page",
        gtsDataTableComponent_totalGiro: "Total Giro",
        gtsDataTableComponent_totalPax: "Total Pax",
        gtsDataTableComponent_totalPnr: "Total Pnr",
        gtsDataTableComponent_totalSearch: "Total Search",
        gtsDataTableComponent_totalVoucher: "Total Voucher",
        gtsDataTableComponent_weekly: "Weekly",
        gtsGrid_btnNextPage: "Next",
        gtsGrid_btnPrevPage: "Previous",
        gtsGrid_item: "items",
        gtsGrid_showAll: "All items",
        gtsGrid_totalItem: "items showed",
        guestItem_txtGuest: "Guest",
        hamburgerMenu_accTransactionList: "Account Activities",
        hamburgerMenu_balanceUpdate: "Balance Deposit",
        hamburgerMenu_customerManagement: "Customer Transactions",
        hamburgerMenu_darkMode: "Dark Mode",
        hamburgerMenu_dashBoard: "Dashboard",
        hamburgerMenu_financeWithSubs: "Finance",
        hamburgerMenu_flightSaleReport: "Flight Sales Report",
        hamburgerMenu_flightSalesReport: "Flight Sales Report",
        hamburgerMenu_flightSearch: "Flight Search",
        hamburgerMenu_hotelSaleReport: "Hotel Sales Report",
        hamburgerMenu_hotelSalesReport: "Hotel Sales Report",
        hamburgerMenu_hotelSearch: "Hotel Search",
        hamburgerMenu_invoiceList: "Invoice List",
        hamburgerMenu_lightMode: "Light Mode",
        hamburgerMenu_logOut: "Logout",
        hamburgerMenu_orderSearch: "Search Order",
        hamburgerMenu_profileSettings: "Profile Settings",
        hamburgerMenu_rentACarSalesReport: "Rent A Car Sales Report",
        hamburgerMenu_rentACarSearch: "Rent A Car Search",
        hamburgerMenu_reportsWithSubs: "Reports",
        hamburgerMenu_serviceCenter: "Service Center",
        hamburgerMenu_settings: "Settings",
        hamburgerMenu_transferSalesReport: "Transfer Sales Report",
        hamburgerMenu_transferSearch: "Transfer Search",
        hamburgerMenu_userAuthorization: "User Role",
        hamburgerMenu_userSettings: "User Settings",
        "HOTEL_API.TICKET.EXCEPTION.error.hotel.not.accepts.only.man": "Mr. stays are not accepted by this hotel. Please close the page and search again.",
        hotel_lblAdult: "Adult",
        hotel_lblChild: "Child",
        hotel_lblCustomerNationality: "Guest Nationality",
        hotel_lblDateInfo: "Accommodation Date:",
        hotel_lblDestinationName: "Destination Name:",
        hotel_lblInfant: "Infant",
        hotel_lblManuelRequest: "We apologize, but at this time we are unable to locate the requested hotel or location. The Find For Me button will submit your inquiry to us.",
        hotel_lblRoom: "Room",
        hotel_lblRoomInfos: "Room Information:",
        hotel_manuelFormTitle: "How can we reach you?",
        hotel_manuelRequestCancel: "Cancelled",
        hotel_manuelRequestConfirm: "Find For Me",
        hotel_manuelRequestModalTitle: "You could not find what you wanted? Let us help you find it.",
        HOTEL_API_TICKET_HOTEL_EXCEPTION_GTS_BOOKING: "While executing your transaction, the room quota has been exhausted. Please close the page and search again or contact our Service Center Team.",
        hotelAutocomplete_lblCity: "City",
        hotelAutocomplete_lblDisctrict: "District",
        hotelAutocomplete_lblHotel: "Hotel",
        hotelAutocomplete_lblLastSearches: "Recent Searches",
        hotelAutocomplete_phDestination: "Destination, Zone, Hotel",
        hotelBasket_btnClear: "Clear",
        hotelBasket_lblAddOnPrice: "Add-On Price",
        hotelBasket_lblGrandTotal: "Grand Total",
        hotelBasket_lblRoomPrice: "Room Price",
        hotelBasket_lblTitle: "Basket",
        hotelBasketComponent_roomPriceLabel: "Room Price",
        hotelCacheCheckout_lblCancellationPolicy: "Cancellation Policies",
        hotelCacheCheckout_lblPassengerInformations: "Guest Information",
        HotelCacheCheckoutBook_WarningEmptyAreaContent: "Please check the information and try again.",
        HotelCacheCheckoutBook_WarningEmptyAreaHead: "Error",
        hotelCacheOrderDetail_lblInfoDontForgetSave: "Do not forget to save the information.",
        hotelCacheOrderDetail_lblPaymentInformations: "Payment information",
        hotelCacheOrderDetail_lblSummary: "Accommodation Information",
        hotelCacheSearch_addOn: "Add-On",
        hotelCacheSearch_lblHotelNotSelectedWarning: "You havent chosen any hotel...",
        hotelCacheSearch_lblMultiHotelSelect: "Multiple Hotel Selection",
        hotelCacheSearch_lblSearchInfo: "On this page, the hotels of suppliers that are open for sale are listed on the wholesale hotel search rules screen.",
        hotelCacheSearch_lblSelectedHotels: "Selected Hotels",
        HotelCacheSearch_MultiSelectWarningPopup: "One or more hotels you selected were not found in the Cache List.",
        HotelCacheSearch_MultiSelectWarningPopupInfo: "Select to add multiple hotels...",
        HotelCacheSearch_WarningMultiSelectInfo: "Your hotel search list is reset because you have switched from multiple to single selection.",
        HotelCacheSearch_WarningMultiSelectPopup: "İnformation",
        HotelCacheSearch_WarningPopup: "Information",
        HotelCacheSearch_WarningPopupEmpty: "Error",
        HotelCacheSearch_WarningPopupEmptyInfo: "Please fill in the search box and make a selection from the incoming list.",
        HotelCacheSearch_WarningPopupInfo: "Please make sure that the hotel you are searching for is on the Cache List!",
        HotelCacheSearch_WarningPopupToCheckout: "Error",
        HotelCacheSearch_WarningPopupTopInfo: "Please check your search criteria and try again.",
        hotelCard_btnFacilityShowLess: "Hide All Features",
        hotelCard_btnFacilityShowMore: "Show All Features",
        hotelCard_btnReadLess: "Read Less",
        hotelCard_btnReadMore: "Read More",
        hotelCard_btnSearchRoom: "Choose Room",
        hotelCard_lblBoutiqueHotel: "Boutique Hotel",
        hotelCard_lblCalendarBetween: "There is no availability between the selected dates.",
        hotelCard_lblCalendarInfo: "Please select your Check-In and Check-Out date...",
        hotelCard_lblCalendarMinStay: "There is a minimum #VALUE# night accommodation requirement on the dates you have selected.",
        hotelCard_lblCalendarRelease: "Reservations cannot be made on the selected dates #VALUE# days or less before check-in.",
        hotelCard_lblCalendarSelectNights: "Select date...",
        hotelCard_lblCalendarStopType: "Please change the date range.",
        hotelCard_lblCalendarSuccess: "The date selection is successful...",
        hotelCard_lblChangeDate: "Change the Dates",
        hotelCard_lblDescription: "Hotel Description",
        hotelCard_lblDiscounted: "Discounted",
        hotelCard_lblhasAddon: "Add-On",
        hotelCard_lblhasAddonTitle: "Add-On",
        hotelCard_lblhasMandatoryAddonTitle: "Mandatory Add-On",
        hotelCard_lblKontenjan: "There is no availability on the dates you have selected.",
        hotelCard_lblLocationTitle: "Location  &  Address Information",
        hotelCard_lblMinimum: "There is a minimum #VALUE# night accommodation requirement on the dates you have selected.",
        hotelCard_lblMinimumStay: "Minimum #VALUE# night(s).",
        hotelCard_lblNights: "Night",
        hotelCard_lblPartialPayment: "Partial Payment",
        hotelCard_lblQuota: "There is no availability on the dates you have selected.",
        hotelCard_lblRecommended: "Recommended",
        hotelCard_lblRelease: "Reservations cannot be made on the selected dates #VALUE# days or less before check-in.",
        hotelCard_lblSearchResultInspectFirst: "Search result #VALUE1# in seconds",
        hotelCard_lblSearchResultInspectSecond: "#VALUE1# hotels are listed from providers.",
        hotelCard_lblSpecialCategory: "Special Category",
        hotelCard_lblStop: "There is no availability on the dates you have selected.",
        hotelCard_noMatchingBoardType: "There is no board type price for this guests, please change your boardtype in basket.",
        hotelCard_room: "Room",
        hotelCard_tabInfo: "Information",
        hotelCard_tabPhotoGallery: "Gallery",
        hotelCard_tabRooms: "Choosing A Room",
        hotelcheckout_addOnCardfree: "#AGE_RANGE# Age range is free",
        hotelcheckout_addOnCardWarning: "Children aged #VALUE# are not permitted to attend.",
        hotelCheckout_addOnInsert: "Insert Add-On",
        hotelCheckout_addOnMandatory: "Mandatory Add-On",
        hotelCheckout_addOnNonMandatory: "Non-Mandatory Add-On",
        hotelCheckout_addOnReadLess: "Read Less",
        hotelCheckout_addOnReadMore: "Read More",
        hotelCheckout_addOnRemove: "Remove Add-On",
        hotelCheckout_agencyExtraCCommission: "Agency Adds Extra Commission.",
        hotelCheckout_cmbAgencyCommission: "Agency Commission",
        hotelCheckout_lblAccomodationInfo: "Accommodation Fee",
        hotelCheckout_lblAddOnTotalPrice: "Add-On Fee",
        hotelCheckout_lblAgencyCommission: "Agency Commission",
        hotelCheckout_lblAgencyExtraCommission: "Agency Extra Commission",
        hotelCheckout_lblBoardType: "Board Type",
        hotelCheckout_lblCheckin: "Check In Date",
        hotelCheckout_lblCheckout: "Check Out Date",
        hotelCheckout_lblCityTax: "#VALUE1#€ City Tax may be charged at the hotel",
        hotelCheckout_lblContactInfo: "Contact Info",
        hotelCheckout_lblEmail: "E-mail",
        hotelCheckout_lblGrandTotal: "Grand Total",
        hotelCheckout_lblHotelAgentTerms: "Agency Terms & Conditions",
        hotelCheckout_lblHotelAnd: "and",
        hotelCheckout_lblHotelDescriptions: "Check-in time to the hotel is 14:00 check-out time is 12:00. Information should be provided to us for guests who will check in late. For same-day and weekend reservations, please get availability from our support team and ask for re-confirmation.",
        hotelCheckout_lblHotelDescriptionsAlert: "According to the provision of Article 52(1)(b) of Turkish Law No. 7194; A lodging tax will be levied on all hotel guests from January 1st, 2023. Some hotels already include the tax in the price, while others require the tax directly from the customer upon check-in. PS: as the situation is new, please inform your guests about the new regulations. In the event of discrepancies, GTS Global Travel Services GmbH assumes no responsibility or liability.",
        hotelCheckout_lblHotelDescriptionsRateHawkMetaPolicy: "Fees and Other Information",
        hotelCheckout_lblHotelDescriptionsTitle: "Hotel Descriptions",
        hotelCheckout_lblHotelProviderInstructionsDescriptions: "Please check the supplier descriptions of your purchase before confirming the booking.",
        hotelCheckout_lblHotelProviderInstructionsTitle: "Provider Instructions",
        hotelCheckout_lblHotelSupplierTerms: "Provider Terms & Conditions",
        hotelCheckout_lblHotelTerms: "I have read and accept the",
        hotelCheckout_lblLastBookingDate: "Last Booking Date",
        hotelCheckout_lblLastBookingDateDescription: "Reservations that have not been purchased by the above-mentioned date will be automatically canceled.",
        hotelCheckout_lblPartialPaymentAvailable: "Partial Payment Terms",
        hotelCheckout_lblPartialPaymentCancelInfo: "Reservations whose payments are not completed by the dates specified above will be automatically cancelled.",
        hotelCheckout_lblPartialPaymentCancellationTerms: "Cancellation Terms",
        hotelCheckout_lblPartialPaymentCancellationTermsInfo: "In case of reservation cancellation, the following penalty terms apply.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText: "Until #VALUE#, there will be #VALUE1#no penalty #VALUE2#fee for cancellation",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText1: "Until #VALUE#, there will be #VALUE1#no penalty #VALUE2#fee for cancellation",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText2: "Until #VALUE1#, a penalty fee of #VALUE2# will be applied for cancellation.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText2Revize: "In case of cancellation of this reservation, the deposit and remaining payment are not refundable.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText3: "Until #VALUE1#, a penalty fee of #VALUE2# will be applied for cancellation.",
        hotelCheckout_lblPartialPaymentDeposit: "As a deposit",
        hotelCheckout_lblPartialPaymentFirstDeposit: "The deposit of #VALUE1# for the selected trip must be paid until #VALUE2#.",
        hotelCheckout_lblPartialPaymentFirstPayment: "Deposit Payment",
        hotelCheckout_lblPartialPaymentInfo: "Partial Payment İnformation",
        hotelCheckout_lblPartialPaymentPaymentInfo: "For this transaction, you may use the Partial Payment method. With partial payment you will pay the deposit amount first.",
        hotelCheckout_lblPartialPaymentReady: "This process is suitable for partial payment",
        hotelCheckout_lblPartialPaymentRefundDisabled: "There is no refund of the deposit payment.",
        hotelCheckout_lblPartialPaymentRemainingPayment: "Rest of the total payment",
        hotelCheckout_lblPartialPaymentRemainingPaymentInfo: "The remaining amount for the selected accommodation #VALUE1#, must be paid until #VALUE2#.",
        hotelCheckout_lblPartialPaymentSecondPayment: "Remaining Payment",
        hotelCheckout_lblPartialPaymentUsePartialPayment: "Partial Payment Active",
        hotelCheckout_lblPartialPaymentUsePartialPaymentAvailableInfo: "You can use the partial payment method for this transaction. We need your confirmation to make a partial payment.",
        hotelCheckout_lblPartialPaymentUsePartialPaymentDeactive: "Use partial payment and pay the deposit amount",
        hotelCheckout_lblproviderTotal: "Subtotal",
        hotelCheckout_lblRoomInfo: "Room Information",
        hotelCheckout_lblRoomName: "Room Type",
        hotelCheckout_lblRoomTotal: "Total",
        hotelCheckout_lblTax: "Tax",
        hotelCheckout_lblTaxUS: "Sales Tax",
        hotelCheckout_lblTotalPrice: "Total Fee",
        hotelCheckout_lblTotalTax: "Tax",
        hotelCheckout_PartialCancellationContentText: "In case of cancellation of this reservation, the deposit and remaining payment are not refundable.",
        hotelCheckoutBasket_partialPaymentControl: "I want to make a partial payment by paying a #VALUE# euro deposit.",
        hotelCheckoutBasket_txtBoardType: "Board Type",
        hotelCheckoutBasket_txtNight: "Night",
        hotelCheckoutBasket_txtRoom: "Room #VALUE#",
        hotelCheckoutPage_contactInfo_title: "Contact Info",
        hotelCheckoutPage_descriptions_alert_text: "For same-day and weekend reservations, please get availability from our support team and ask for re-confirmation.",
        hotelCheckoutPage_descriptions_hotelNote_text: "Check-in time to the hotel is 14:00 check-out time is 12:00. Information should be provided to us for guests who will check in late. For same-day and weekend reservations, please get availability from our support team and ask for re-confirmation.",
        hotelCheckoutPage_descriptions_title: "Hotel Descriptions",
        hotelCheckoutPage_roomInfo_agencyCommission_label: "Agency Commission",
        hotelCheckoutPage_roomInfo_agencyExtraCommission_label: "Agency Extra Commission",
        hotelCheckoutPage_roomInfo_title: "Room Information",
        hotelCheckoutPage_roomInfoCard_lastBookingDate_alertDescriptionText: "Reservations that have not been purchased by the above-mentioned date will be automatically canceled.",
        hotelCheckoutPage_roomInfoCard_lastBookingDate_alertText: "Last Booking Date",
        hotelCheckoutPage_roomInformation_roomBoardLabel: "Board Type",
        hotelCheckoutPage_roomInformation_roomNameLabel: "Room Type",
        hotelCheckoutPage_roomRestrictionInfo: "This room price is calculated for {adultCount}{adultFmt}{adultPluralFmt}{and}{childCount}{childFmt}{childPluralFmt}.",
        hotelCheckoutPageRH_children: "Child Fee",
        hotelCheckoutPageRH_childrenMeal: "Children Meal",
        hotelCheckoutPageRH_cot: "Baby Cot",
        hotelCheckoutPageRH_deposit: "Deposit",
        hotelCheckoutPageRH_extraBed: "Extra Bed",
        hotelCheckoutPageRH_internet: "Internet",
        hotelCheckoutPageRH_meal: "Meal",
        hotelCheckoutPageRH_noShow: "No Show",
        hotelCheckoutPageRH_parking: "Otopark",
        hotelCheckoutPageRH_pets: "Pets",
        hotelCheckoutPageRH_shuttle: "Shuttle",
        hotelCheckoutPageRH_visa: "Visa",
        hotelFacilitiesModal_lblTitle: "Facilities",
        hotelFilter_btnApplyAndClose: "Apply and Close",
        hotelFilter_btnClearAll: "Clear",
        hotelFilter_btnSelectAll: "Select All",
        hotelFilter_btnShowAll: "Show All Option",
        hotelFilter_btnShowLess: "Show Less Option",
        hotelFilter_btnShowMore: "Show More Option",
        hotelFilter_lblAnd: "and",
        hotelFilter_lblBoardType: "Board Type",
        hotelFilter_lblBoutiqueHotel: "Boutique Hotel",
        hotelFilter_lblClassTitle: "Stars",
        hotelFilter_lblDiscountedHotel: "Discounted",
        hotelFilter_lblFacilityModalCancel: "Cancelled",
        hotelFilter_lblFacilityModalClose: "CLOSE",
        hotelFilter_lblFacilityModalSave: "Save",
        hotelFilter_lblFacilityTitle: "Facilities",
        hotelFilter_lblFilterByDefinitions: "Filter by definitions",
        hotelFilter_lblFilterByStars: "Filter by Star",
        hotelFilter_lblFilterPartialPayment: "Partial Payment",
        hotelFilter_lblFilterText: "Hotels with #VALUE1# #VALUE2# #VALUE3# #VALUE4# #VALUE5# #VALUE6# are filtered.",
        hotelFilter_lblhassAddOn: "Add-On",
        hotelFilter_lblInfoTitle: "Search Collapse",
        hotelFilter_lblPartialPayment: "Partial Payment",
        hotelFilter_lblPriceTitle: "Price Range",
        hotelFilter_lblRecommendedHotel: "Recommended",
        hotelFilter_lblSearchHotel: "Hotel Search",
        hotelFilter_lblSelectedClassCount: "Stars selected",
        hotelFilter_lblSpecialCategory: "Special Category",
        hotelFilter_lblStars: "stars",
        hotelOrderDetail_gtsDirect: "Operator GTS",
        hotelOrderDetail_lblAccomodationInfo: "Accommodation Fee",
        hotelOrderDetail_lblAgencyCommission: "Agency Commission",
        hotelOrderDetail_lblBaseTotal: "Net Price",
        hotelOrderDetail_lblGrandTotal: "Grand Total",
        hotelOrderDetail_lblPackageGrandTotal: "Package Grand Total",
        hotelOrderDetail_lblproviderTotal: "Subtotal",
        hotelOrderDetail_lblReverseTotal: "Refund Amount",
        hotelOrderDetail_lblSalesTotal: "Sales Total",
        hotelOrderDetail_lblTax: "Tax",
        hotelOrderDetail_lblTaxUS: "Sales Tax",
        hotelOrderDetail_lblTotalPrice: "Total Fee",
        hotelRoomCard_lblDetailHighlightedFeatures: "Room Highlights",
        hotelRoomCard_lblDetailRoomFeatures: "RoomFeatures",
        hotelRoomCard_lblDetailRoomInformations: "Room Info",
        hotelRoomCard_lblDetailRoomOptions: "Room Options",
        hotelRoomCard_lblDetailSeeDetails: "See room details...",
        hotelRoomCard_lblKontenjan: "There is no availability.",
        hotelRoomCard_lblLoadMore: "Show More Option",
        "hotelRoomCard_lblMinimum Konaklama": "Minimum #VALUE# night(s).",
        "hotelRoomCard_lblMinimum..": "minimum #VALUE# night",
        hotelRoomCard_lblMinimumKonaklama: "Minimum #VALUE# night(s).",
        hotelRoomCard_lblMinimumStay: "minimum #VALUE# night",
        hotelRoomCard_lblQuota: "There is no availability.",
        hotelRoomCard_lblRelease: "There is no availability.",
        hotelRoomCard_lblRoomCountTxt: "Room",
        hotelRoomCard_lblStop: "There is no availability.",
        hotelRoomDetail_lblAddOnInfoDisabled: "#AGE_RANGE# age range cannot participate",
        hotelRoomDetail_lblAddOnInfoFree: "#AGE_RANGE# Age range is free",
        hotelSalesReport_agencyCommission: "Agency Commission",
        hotelSalesReport_filter_btnList: "List",
        hotelSalesReport_filter_lblFromDate: "From Date",
        hotelSalesReport_filter_lblReportType: "Report Type",
        hotelSalesReport_filter_lblStatus: "Status",
        hotelSalesReport_filter_lblToDate: "End Date",
        hotelSalesReport_fromDate: "Creation From Date",
        hotelSalesReport_grandTotal: "Total",
        hotelSalesReport_guestCount: "Guest Count",
        hotelSalesReport_reportType: "Report Type",
        hotelSalesReport_roomCount: "Room Count",
        hotelSalesReport_saleAmount: "Sales Amount",
        hotelSalesReport_tblMain_tdChannelGts: "Operator GTS",
        hotelSalesReport_tblMain_tdChannelProvider: "Provider",
        hotelSalesReport_tblMain_thAgencyCommission: "Agency Commission",
        hotelSalesReport_tblMain_thAgencyName: "Agency",
        hotelSalesReport_tblMain_thCheckinDate: "Check In Date",
        hotelSalesReport_tblMain_thCheckoutDate: "Check Out Date",
        hotelSalesReport_tblMain_thContactName: "Name",
        hotelSalesReport_tblMain_thContactSurname: "Surname",
        hotelSalesReport_tblMain_thGuestCount: "Guests",
        hotelSalesReport_tblMain_thHotelLocation: "Location",
        hotelSalesReport_tblMain_thHotelName: "Hotel Name",
        hotelSalesReport_tblMain_thOnUsChannel: "Channel",
        hotelSalesReport_tblMain_thOptionDaysLeft: "Option Days Left",
        hotelSalesReport_tblMain_thOrderId: "Order ID",
        hotelSalesReport_tblMain_thRoomCount: "Room",
        hotelSalesReport_tblMain_thSaleDateTime: "Sale Date",
        hotelSalesReport_tblMain_thTotal: "Total",
        hotelSalesReport_tblMain_thVocuherStatus: "Status",
        hotelSalesReport_tblMain_thVoucher: "Voucher",
        hotelSalesReport_tblMain_thVoucherStatus: "Status",
        hotelSalesReport_title: "Hotel Sales Report",
        hotelSalesReport_toDate: "End Date",
        hotelSalesReport_totalAgencyCommission: "Agency Com. Total",
        hotelSalesReport_totalGuestCount: "Guests Total",
        hotelSalesReport_totalRoomCount: "Room Total",
        hotelSalesReport_totalVoucherCount: "Voucher Total",
        hotelSalesReport_voucherCount: "Voucher Count",
        HotelSalesSummaryReportList_thAgencyId: "Agency ID",
        hotelSearch_btnSearch: "HOTEL SEARCH",
        hotelSearch_btnShowFilter: "Filters",
        hotelSearch_lblDate: "Check-in - Check-out Date",
        hotelSearch_lblDestination: "Destination, Zone, Hotel",
        hotelSearch_lblEmptyFilterResult: "Please check your filters, no results were found according to your selection criteria.",
        hotelSearch_lblEmptyHotelList: "There is no hotel found matching with your search criteria. Please try again by changing your search criteria.",
        hotelSearch_lblFilterClearAll: "Clean all filters !",
        hotelSearch_lblFilterStatus: "Filters :",
        hotelSearch_lblFilterStatusOff: ".",
        hotelSearch_lblFilterStatusOn: ".",
        hotelSearch_lblGuest: "Guests",
        hotelSearch_lblNationality: "Guest Nationality",
        hotelSearch_lblNoFoundedHotels: "No hotel found according to this search criteria.",
        hotelSearch_lblNonRecommendedHotels: "A total of #VALUE1# hotels were listed.",
        hotelSearch_lblOnlyGtsDirect: "Filter GTS Contract Hotels!",
        hotelSearch_lblTotalHotelNumber: "A total of #VALUE2# hotels were listed, #VALUE1# of which were recommended.",
        hotelSearchForm_lblArea: "Area",
        hotelSearchForm_lblCode: "Country",
        hotelSearchForm_lblMail: "E-mail",
        hotelSearchGuest_btnRemoveRoom: "Remove Room",
        hotelSearchGuest_lblAdult: "Adult",
        hotelSearchGuest_lblChild: "Child",
        hotelSearchGuest_lblChildAge: "Child Age",
        hotelSearchGuest_lblRoomCount: "Room",
        hotelSearchGuest_phAdultCount: "Adult",
        hotelSearchGuest_phChildCouunt: "Child",
        hotelSearchGuest_phRoomCount: "Room",
        hotelSearchInformation_adult: "Adult",
        hotelSearchInformation_child: ", #COUNT# Child",
        hotelSearchInformation_data: "Rooms",
        hotelSearchInformation_date1: "Check In Date",
        hotelSearchInformation_date2: "Check Out Date",
        hotelSearchInformation_footer: "#DAY# day(s) and #NIGHT# night(s)",
        hotelSearchInformation_location: "Location",
        hotelSearchInformation_rooms: "Rooms",
        hotelSearchInformation_text: "Looking for the best prices, please wait!",
        hotelSearchResultPage_hotelCard_informationTab_hotelDescription_title: "Hotel Description",
        hotelSearchResultPage_hotelCard_informationTab_hotelMap_title: "Location  &  Address Information",
        invoiceList_filter_btnList: "List",
        invoiceList_filter_lblFromDate: "Creation From Date",
        invoiceList_filter_lblOrderId: "Order ID",
        invoiceList_filter_lblPnr: "Pnr",
        invoiceList_filter_lblToDate: "Creation To Date",
        invoiceList_filter_phOrderId: "Order ID",
        invoiceList_filter_phPnr: "Pnr",
        invoiceList_info_FCADOM: "Flight Dom. / Invoice / Current",
        invoiceList_info_FCAINT: "Flight Int. / Invoice / Current",
        invoiceList_info_FCCDOM: "Flight Dom. / Invoice / Credit Card",
        invoiceList_info_FCCINT: "Flight Int. / Invoice / Credit Card",
        invoiceList_info_FCODOM: "Flight Dom. / Invoice / Credit Card Commission",
        invoiceList_info_FCOINT: "Flight Int. / Invoice / Credit Card Commission",
        invoiceList_info_HCADOM: "Hotel Dom. / Invoice / Current",
        invoiceList_info_HCAINT: "Hotel Int. / Invoice / Current",
        invoiceList_info_HCCDOM: "Hotel Dom. / Invoice / Credit Card",
        invoiceList_info_HCCINT: "Hotel Int. / Invoice / Credit Card",
        invoiceList_info_HCODOM: "Hotel Dom. / Invoice / Credit Card Commission",
        invoiceList_info_HCOINT: "Hotel Int. / Invoice / Credit Card Commission",
        invoiceList_info_RCAINT: "Rent a Car Int. / Invoice / Current",
        invoiceList_info_RCCINT: "Rent a Car Int. / Invoice / Credit Card",
        invoiceList_info_RCOINT: "Rent a Car Int. / Invoice / Credit Card Commission",
        invoiceList_info_TCAINT: "Transfer Int. / Invoice / Current",
        invoiceList_info_TCCINT: "Transfer Int. / Invoice / Credit Card",
        invoiceList_info_TCOINT: "Transfer Int. / Invoice / Credit Card Commission",
        invoiceList_tblMain_thGrandTotal: "Total Amount",
        invoiceList_tblMain_thInvoiceDate: "Invoice Date",
        invoiceList_tblMain_thInvoiceId: "ID",
        invoiceList_tblMain_thInvoiceInfo: "Description",
        invoiceList_tblMain_thInvoiceNote: "Note",
        invoiceList_tblMain_thInvoiceNumber: "Invoice Number",
        invoiceList_tblMain_thInvoiceTotal: "Total Amount",
        invoiceList_title: "Invoice List",
        invoiceListPage_invoiceListDataTable_invoiceDateLabel: "Invoice Date",
        invoiceListPage_invoiceListDataTable_invoiceNumberLabel: "Invoice Number",
        invoiceListPage_pageTitle: "Invoice List",
        invoiceListPage_searchForm_endDateLabel: "Creation To Date",
        invoiceListPage_searchForm_orderIdLabel: "Order ID",
        invoiceListPage_searchForm_pnrLabel: "Pnr",
        invoiceListPage_searchForm_startDateLabel: "Creation From Date",
        languageFullName_de: "German",
        languageFullName_en: "English",
        languageFullName_ru: "Russian",
        languageFullName_tr: "Turkish",
        legInformation_lblEdit: "İnformation Edit",
        legInformation_titleLegPriceInfos: "Price Information",
        legInformation_titleLegs: "Route İnformation",
        loginPage_resetPasswordSuccessPopupText: "We will send you a reset link as a result of this action . Do you confirm ?",
        MasterAgency_returnTobeGsa: "It will be added to the total fee.",
        multiSelectComponent_itemSelected: "items selected",
        navbarComponent_switchToDarkMode: "Dark Mode",
        navbarComponent_switchToLightMode: "Light Mode",
        notesComp_notTime: "Note Taking Time",
        notesComponent_infoAlert_text: "The notes entered in this field are displayed only by your agency. Please use the Service Center Request Screen to submit a request.",
        NotificationComponent_btnAllowNotification: "Open Notifications",
        NotificationComponent_deleteAllNotificationConfirm: "All notifications will be deleted. Do you confirm?",
        NotificationComponent_deleteNotificationConfirm: "The notification will be deleted. Do you confirm?",
        NotificationComponent_emptyOlder: "You do not have any notifications for older than week.",
        NotificationComponent_emptyToday: "You do not have any notifications for today",
        NotificationComponent_emptyWeek: "You do not have any notifications for this week.",
        NotificationComponent_emptyYesterday: "You do not have any notifications for yesterday.",
        NotificationComponent_markAsRead: "All Read",
        NotificationComponent_notificationAllowed: "Notifications Allowed",
        NotificationComponent_notificationNotAllowed: "Notifications Not Allowed",
        NotificationComponent_notificationPage: "See All",
        NotificationComponent_notificationSettings: "Notification Settings",
        NotificationComponent_notificationsHeader: "Notifications",
        NotificationComponent_notificationStatus: "Notfications Statu",
        NotificationComponent_older: "Older",
        NotificationComponent_readAllConfirm: "All notifications will be marked as read. Do you confirm?",
        NotificationComponent_removeAll: "Delete All",
        NotificationComponent_today: "Today",
        NotificationComponent_week: "Week",
        NotificationComponent_yesterday: "Yesterday",
        onBehalfOfComponent_agency: "Agency",
        onBehalfOfComponent_gsa: "Gsa",
        onBehalfOfComponent_lblAgency: "Agency",
        onBehalfOfComponent_lblGsa: "Gsa",
        onBehalfOfComponent_phAgency: "Search",
        onBehalfOfComponent_phSelectAgency: "Please select agency.",
        onBehalfOfComponent_placeholder: "Search Agent",
        orderDetail_addOnReadLess: "Read Less",
        orderDetail_addOnReadMore: "Read More",
        orderDetail_btnBookingConfirmation: "Booking Confirmation",
        orderDetail_btnEticket: "E-Ticket",
        OrderDetail_btnGetReverseInfo: "Calculate Penalty",
        orderDetail_btnReReserve: "Re-Option",
        orderDetail_btnReReserve_lblDefault: "Re-Option",
        orderDetail_btnReReserve_lblRezerve: "Check Current Price",
        orderDetail_btnReserved: "Reserved",
        orderDetail_btnRnr: "RNR",
        orderDetail_btnRnrProvider: "Provider RNR",
        orderDetail_btnRnrProviderBookingDoc: "Aracı Mukavelesi",
        orderDetail_btnTnr: "TNR",
        orderDetail_btnVoucher: "Voucher",
        orderDetail_exOrderId: "Old Order ID",
        orderDetail_gtsDirect: "Operator GTS",
        orderDetail_lblAccommodationDate: "Accommodation Date",
        orderDetail_lblAccommodationInfoTitle: "Accommodation Information",
        orderDetail_lblAddon: "Add-On",
        orderDetail_lblAddonInfoTitle: "Add-On Informations",
        orderDetail_lblAddonPrice: "Add-On Price",
        orderDetail_lblAdress: "Address",
        orderDetail_lblAdultCount: "Number of Adult",
        orderDetail_lblAgencyCommission: "Agency Commission",
        orderDetail_lblAgencyId: "Agency ID",
        orderDetail_lblAgencyName: "Agency Name",
        orderDetail_lblAgencyPrice: "Agency Net Price",
        orderDetail_lblarrivalFlightDateTime: "Arrival Time",
        orderDetail_lblArrivalFlightNumber: "Arrival Flight Number",
        orderDetail_lblBaseAmount: "Net Price",
        orderDetail_lblBirthdate: "Birth Date",
        orderDetail_lblBoardType: "Board Type",
        orderDetail_lblBookingDate: "Booking Date",
        orderDetail_lblCheckinDate: "Check In Date",
        orderDetail_lblCheckoutDate: "Check Out Date",
        orderDetail_lblChildAges: "Age of Children",
        orderDetail_lblChildCount: "Number of Children",
        orderDetail_lbldepartureFlightDateTime: "Departure Time",
        orderDetail_lblDepartureFlightNumber: "Departure Flight Number",
        orderDetail_lblDriverInfoTitle: "Driver Information",
        orderDetail_lblEmail: "E-mail",
        orderDetail_lblEventDate: "Event Date",
        orderDetail_lblFlightNumber: "Flight Number",
        orderDetail_lblFromDestination: "Departure (A)",
        orderDetail_lblGender: "Gender",
        orderDetail_lblGrandTotal: "Grand Total",
        orderDetail_lblGuestType: "Type",
        orderDetail_lblHotelName: "Hotel Name",
        orderDetail_lblLastBookingDate: "Last Booking Date",
        orderDetail_lblLastTicketDate: "Last Purchase Time",
        orderDetail_lblLastVoidDate: "Last Free Cancellation Date",
        orderDetail_lblLegIndex: "Index Nr.",
        orderDetail_lblLegStatus: "Status",
        orderDetail_lblName: "Name Surname",
        orderDetail_lblNameSurname: "Name-Surname",
        orderDetail_lblNationality: "Nationality",
        orderDetail_lblOrderId: "Order ID",
        orderDetail_lblOrderStatus: "Status",
        orderDetail_lblPackageProviderSchimetterling: "SCHMETTERLING",
        orderDetail_lblPartialPaymentLastBookingDate: "Deposit Payment Deadline",
        orderDetail_lblPartialPaymentLastCancelDate: "Last Void Date",
        orderDetail_lblPartialPaymentLastVoidDate: "Remaining Payment Due Date",
        orderDetail_lblPartialPaymentVoidDate: "Cancellation Refund Time",
        orderDetail_lblPassengerEmail: "Passenger E-mail",
        orderDetail_lblPassengerInfoRoomType: "Room Type",
        orderDetail_lblPassengerInfoTitle: "Guest Information",
        orderDetail_lblPassengerPhone: "Passenger Phone",
        orderDetail_lblPhoneNumber: "Phone",
        orderDetail_lblPlannedPickupDateTime: "Pick-up Date and Time",
        orderDetail_lblPriceInfoTitle: "Price Information",
        orderDetail_lblProvider: "Provider Name",
        orderDetail_lblProviderRnr: "Provider RNR",
        orderDetail_lblRentACarInfoTitle: "Rent A Car İnformation",
        orderDetail_lblReservationWarnings: "Information about the meeting point at the airport or the pick-up time at the hotel will sent to the passengers mobile phone one day in advance via Whatsapp message.",
        orderDetail_lblReserveDate: "Reserve Date",
        orderDetail_lblReverseDate: "Cancel Date",
        orderDetail_lblRnr: "RNR",
        orderDetail_lblRnrStatus: "Status",
        orderDetail_lblRoomInformations: "Room Informations",
        orderDetail_lblRoomType: "Room Type",
        orderDetail_lblTicketDate: "Inserted Time",
        orderDetail_lblTnr: "TNR",
        orderDetail_lblTnrStatus: "Status",
        orderDetail_lblToDestination: "Arrival (B)",
        orderDetail_lblTotal: "Total",
        orderDetail_lblTotalPrice: "Total Fee",
        orderDetail_lblTransferInfoTitle: "Transfer Information",
        orderDetail_lblTravelerInfoTitle: "Traveller Information",
        orderDetail_lblUserEmail: "User E-mail",
        orderDetail_lblUserPhone: "User Phone",
        orderDetail_lblVehicle: "Vehicle Type",
        orderDetail_lblVehicleTypeInfo: "Brand / Model",
        orderDetail_lblViewAddon: "View Add-on",
        orderDetail_lblVoucher: "Voucher",
        orderDetail_lblVoucherId: "GTS Voucher Id",
        orderDetail_lblVoucherStatus: "Status",
        orderDetail_PartialCancellationContentText1: "In case of cancellation of the reservation, the following penalties will apply.",
        orderDetail_PartialCancellationContentText2: "In case of cancellation of this reservation, the deposit and remaining payment are not refundable.",
        orderDetail_PartialCancellationContentText3: "A #VALUE# penalty will be applied until #VALUE1#",
        orderDetail_PartialCancellationContentText4: "There is no refund for the reservation amount paid until #VALUE1#",
        orderDetail_PartialCancellationPolicy: "Cancellation Policies",
        orderDetail_PartialPaymentDeadLineBody: "If the #VALUE# euro deposit is not paid by the above date, the reservation will be cancelled.",
        orderDetail_PartialPaymentDeadLineTitle: "Deposit Due Date:",
        orderDetail_partialPaymentRemainingPayment: "Remaining Payment",
        orderDetail_partialPaymentRemainingText: "The remaining payment of #VALUE# must be made until #VALUE1#.",
        orderDetail_partialPaymentRemainingTitle: "Deposit has been paid for this order.",
        orderDetail_passengerInformations_ancillaryTotal: "Ancillary Total",
        orderDetail_passengerInformations_grandTotal: "Grand Total",
        orderDetail_passengerInformations_paymentFreeTotal: "Credit Card Transaction Fee",
        orderDetail_passengerInformations_reissueTotal: "Change Total",
        orderDetail_passengerInformations_reverseTotal: "Total Refund",
        orderDetail_passengerInformations_salesTotal: "Sales Total",
        orderDetail_provider: "Provider",
        "OrderDetail_tblPass.thDate": "Date",
        "OrderDetail_tblTnrLegs.thArrivalFlightNumber": "Arrival Flight Number",
        "OrderDetail_tblTnrLegs.thLegIndex": "Index Nr.",
        orderDetail_thAgencyId: "Agency ID",
        orderDetail_thAgencyName: "Agency Name",
        orderDetail_thAirlinePnr: "Airline Pnr",
        orderDetail_thLastPurchaseDateTime: "Last Purchase Time",
        orderDetail_thOrderId: "Order ID",
        orderDetail_thPassengerEmail: "Passenger E-mail",
        orderDetail_thPassengerPhone: "Passenger Phone",
        orderDetail_thPnr: "Pnr",
        orderDetail_thProvider: "Provider Name",
        orderDetail_thReservationDateTime: "Reserve Time",
        orderDetail_thStatus: "Status",
        orderDetail_thStatusLabel: "Status",
        orderDetail_thTicketDateTime: "Ticket  Date Time",
        orderDetail_thUserEmail: "User E-mail",
        orderDetail_thUserPhone: "User Phone",
        orderDetail_titleReservationInfo: "Reservation Information",
        orderDetail_transferDate: "Transfer Date",
        orderDetailFlight_txtTaskMessage: "#PNRID# nolu pnr #ORDERID# nolu order cancel edilemedi! #TASKID# üzerinden task açıldı.",
        orderDetailPage_baggage: "Baggage",
        orderDetailPage_date: "Date",
        orderDetailPage_eticket: "E-Ticket",
        orderDetailPage_fare: "Fare",
        orderDetailPage_flightInformation: "Flight information",
        orderDetailPage_grandTotal: "Grand Total",
        orderDetailPage_lblBirthDate: "Birth Date",
        orderDetailPage_nameSurname: "Name-Surname",
        orderDetailPage_orderSummaryHeader_agencyIdLabel: "Agency ID",
        orderDetailPage_orderSummaryHeader_agencyNameLabel: "Agency Name",
        orderDetailPage_orderSummaryHeader_agencyPhoneLabel: "Agency Phone",
        orderDetailPage_orderSummaryHeader_contactEmailLabel: "Passenger E-mail",
        orderDetailPage_orderSummaryHeader_contactPhoneLabel: "Passenger Phone",
        orderDetailPage_orderSummaryHeader_orderIdLabel: "Order ID",
        orderDetailPage_orderSummaryHeader_passengerEmailLabel: "Passenger E-mail",
        orderDetailPage_orderSummaryHeader_passengerPhoneLabel: "Passenger Phone",
        orderDetailPage_orderSummaryHeader_statusLabel: "Status",
        orderDetailPage_orderSummaryHeader_userEmailLabel: "User E-mail",
        orderDetailPage_orderSummaryHeader_userPhoneLabel: "User Phone",
        orderDetailPage_passengerInformation: "Passenger Information",
        orderDetailPage_passengerInformation_sectionTitle: "Guest Information",
        orderDetailPage_passengerSummaryHeader_roomTypeLabel: "Room Type",
        orderDetailPage_pnrSummaryHeader_airlinePnr: "Airline Pnr",
        orderDetailPage_pnrSummaryHeader_lastPurchaseTime: "Last Purchase Time",
        orderDetailPage_pnrSummaryHeader_provider: "Provider Name",
        orderDetailPage_pnrSummaryHeader_reservationInformations: "Reservation Information",
        orderDetailPage_pnrSummaryHeader_reservationTime: "Reserve Time",
        orderDetailPage_pnrSummaryHeader_status: "Status",
        orderDetailPage_pnrSummaryHeader_ticketDateTime: "Ticket  Date Time",
        orderDetailPage_priceInformation_sectionTitle: "Price Information",
        orderDetailPage_priceSummaryHeader_accommodationDateLabel: "Accommodation Date",
        orderDetailPage_priceSummaryHeader_agencyCommissionLabel: "Agency Commission",
        orderDetailPage_priceSummaryHeader_agencyNetPriceLabel: "Agency Net Price",
        orderDetailPage_priceSummaryHeader_roomTypeLabel: "Room Type",
        orderDetailPage_priceSummaryHeader_totalPriceLabel: "Total",
        orderDetailPage_serviceFee: "T.Service Fee",
        orderDetailPage_status: "Status",
        orderDetailPage_tax: "Tax",
        orderDetailPage_voucherInformation_sectionTitle: "Accommodation Information",
        orderDetailPage_voucherRoomTypeHeader_adultNumberLabel: "Number of Adult",
        orderDetailPage_voucherRoomTypeHeader_boardTypeLabel: "Board Type",
        orderDetailPage_voucherRoomTypeHeader_childrenAgeLabel: "Age of Children",
        orderDetailPage_voucherRoomTypeHeader_childrenNumberLabel: "Number of Children",
        orderDetailPage_voucherRoomTypeHeader_roomTypeLabel: "Room Type",
        orderDetailPage_voucherSummaryHeader_checkInLabel: "Check In Date",
        orderDetailPage_voucherSummaryHeader_checkOutLabel: "Check Out Date",
        orderDetailPage_voucherSummaryHeader_hotelNameLabel: "Hotel Name",
        orderDetailPage_voucherSummaryHeader_lastBookingDateTimeLabel: "Last Booking Date",
        orderDetailPage_voucherSummaryHeader_lastVoidDateTimeLabel: "Last Free Cancellation Date",
        orderDetailPage_voucherSummaryHeader_providerLabel: "Provider Name",
        orderDetailPage_voucherSummaryHeader_reserveDateTimeLabel: "Reserve Date",
        orderDetailPage_voucherSummaryHeader_reverseDateTimeLabel: "Cancellation Date",
        orderDetailPage_voucherSummaryHeader_statusLabel: "Status",
        orderDetailPage_voucherSummaryHeader_totalPriceLabel: "Total",
        orderDetailPage_voucherSummaryHeader_voucherLabel: "Voucher",
        OrderDetailPayment_btnReverse: "Cancellation Without Penalty",
        OrderDetailPayment_NOT_SALES_OFFICE: "It is not a sales office. Please check your user or agent.",
        OrderDetailPayment_popup_EXCEPTION: "We encountered an unknown error.",
        OrderDetailPayment_popup_FAILURE: "Failure!",
        OrderDetailPayment_popup_FAILURE_VOUCHER_NOT_REVERSABLE: "This reservation is a non-refundable reservation.",
        OrderDetailPayment_popup_REJECTED: "An Error Occurred.",
        OrderDetailPayment_popup_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during your transaction. Your payment could not be collected.",
        OrderDetailPayment_popup_SUCCESS: "Successful",
        OrderDetailPayment_progressGif_RESERVE_HOTEL: "Your transaction is in progress. Please wait.",
        OrderDetailPayment_progressGif_TICKET_HOTEL: "Your transaction is in progress. Please wait.",
        OrderDetailPayment_progressGif_TicketReservedFlight: "Your transaction is in progress. Please wait.",
        orderDetailTab_btnPartialPaid: "Partial Paid",
        orderDetailtransfer__lblProvider: "Provider",
        orderSearch_creationFromDate: "Creation From Date",
        orderSearch_creationToDate: "Creation To Date",
        orderSearch_filter_btnSearch: "List",
        orderSearch_filter_lblCreationFromDate: "From Date",
        orderSearch_filter_lblCreationToDate: "End Date",
        orderSearch_filter_lblDateType: "Date Types",
        orderSearch_filter_lblEticket: "E-Ticket",
        orderSearch_filter_lblHotel: "Hotel",
        orderSearch_filter_lblName: "Name",
        orderSearch_filter_lblOrderId: "Order ID",
        orderSearch_filter_lblPnr: "Pnr",
        orderSearch_filter_lblPnrStatus: "Pnr Status",
        orderSearch_filter_lblProviderList: "Provider List",
        orderSearch_filter_lblRentACar: "Rent A Car",
        orderSearch_filter_lblRnr: "Rent A Car",
        orderSearch_filter_lblRnrStatus: "Rnr Status",
        orderSearch_filter_lblSurname: "Last Name",
        orderSearch_filter_lblVoucher: "Voucher",
        orderSearch_filter_lblVoucherId: "Voucher ID",
        orderSearch_filter_lblVoucherStatus: "Status",
        orderSearch_filter_plhEticket: "E-Ticket",
        orderSearch_filter_plhName: "Name",
        orderSearch_filter_plhOrderId: "Order ID",
        orderSearch_filter_plhPnr: "Pnr",
        orderSearch_filter_plhRnr: "Rnr",
        orderSearch_filter_plhSurname: "Last Name",
        orderSearch_filter_plhVoucher: "Voucher",
        orderSearch_filter_plhVoucherId: "Voucher ID",
        orderSearch_orderSearch: "Date Types",
        orderSearch_pageTitle: "Search Order",
        orderSearch_pnrStatus: "Pnr Status",
        orderSearch_providerList: "Provider List",
        orderSearch_tblMain_agencyName: "Agency Name",
        orderSearch_tblMain_dropOffLocation: "Car Drop Off Point",
        orderSearch_tblMain_etickets: "E-Ticket",
        orderSearch_tblMain_gsaName: "Gsa Name",
        orderSearch_tblMain_insertedDate: "Booking Date",
        orderSearch_tblMain_insertedUser: "Inserted User",
        orderSearch_tblMain_nameSurname: "Name Surname",
        orderSearch_tblMain_orderId: "Order ID",
        orderSearch_tblMain_pickUpDateTime: "Pick-up Time",
        orderSearch_tblMain_pickUpLocation: "Car Pick-up Point",
        orderSearch_tblMain_pnrs: "Pnr",
        orderSearch_tblMain_pnrStatuses: "Pnr Status",
        orderSearch_tblMain_provider: "Provider Name",
        orderSearch_tblMain_rnr: "RNR No",
        orderSearch_tblMain_rnrStatus: "Rnr Status",
        orderSearch_tblMain_rooms: "Rooms",
        orderSearch_tblMain_tnrs: "Tnr",
        orderSearch_tblMain_tnrStatuses: "Status",
        orderSearch_tblMain_vehicle: "Car",
        orderSearch_tblMain_voucherId: "Voucher ID",
        orderSearch_tblMain_vouchers: "Voucher",
        orderSearch_tblMain_voucherStatuses: "Status",
        orderSearch_voucherStatus: "Status",
        orderSearch__filter_lblVoucherStatus: "Status",
        orderSearch_filter_lblFlight: "Flight",
        orderSearch_filter_lblFromLocation: "Departure",
        orderSearch_filter_lblTnr: "Tnr",
        orderSearch_filter_lblTnrStatus: "Status",
        orderSearch_filter_lblToLocation: "Arrival",
        orderSearch_filter_lblTransfer: "Transfer",
        orderSearch_filter_plhFromLocation: "Departure",
        orderSearch_filter_plhTnr: "Tnr",
        orderSearch_filter_plhToLocation: "Arrival",
        otelSalesReport_tblMain_thContactSurname: "Surname",
        passengerCard_country: "Country:",
        passengerCard_dateofBirth: "Birth Date",
        passengerCard_expiryDate: "Expiry Date",
        passengerCard_hesCode: "HES Code",
        passengerCard_identityNo: "T.R. Identity No",
        passengerCard_milesCard: "Miles Card",
        passengerCard_milNo: "Miles Card",
        passengerCard_nationality: "Nationality",
        passengerCard_passportNumber: "Passport Number",
        passengerCard_serialNumber: "Serial Number",
        paymentChoice_partialPaymentPaidText: "A deposit payment of #VALUE1# was made on #VALUE#.",
        paymentChoice_partialPaymentRemainingText: "Payment of #VALUE1# is expected until #VALUE#",
        PaymentChoiceItem_btnLEAVE: "GO TO THE RESERVATION",
        PaymentChoiceItem_btnRESERVE: "RESERVE",
        PaymentChoiceItem_btnREVERSE: "CANCELLATION WITHOUT PENALTY",
        PaymentChoiceItem_btnTICKET: "BUY",
        PaymentChoiceItem_FLIGHT_UPCOMING: "The airline does not allow reservations.",
        PaymentChoiceItem_INSUFFICIENT_AGENCY_BALANCE: "Your available balance is insufficient.  Please contact our team.",
        PaymentChoiceItem_INSUFFICIENT_GSA_BALANCE: "Please check the Gsa balance.",
        PaymentChoiceItem_lblCommission: "Commission",
        PaymentChoiceItem_lblGrandTotal: "Amount To Be Paid",
        PaymentChoiceItem_lblPartialPaymentDepositAmount: "Deposit Amount",
        PaymentChoiceItem_lblRemainingBalance: "Your Remaining Balance",
        PaymentChoiceItem_lblTotal: "Total",
        PaymentChoiceItem_NOT_SALES_OFFICE: "It is not a sales office. Please check your user or agent.",
        PaymentChoiceItem_SSRS_CANT_BE_RESERVED: "The airline does not allow reserving because you have added ssr. You need to make a direct ticketing.",
        paymentChoiceItem_stepBack: "Previous",
        PaymentChoiceItem_txtCommissionInfo_CREDITCARD: "A fixed 2% bank commission rate will be added for purchases and top-ups with credit card VISA, MASTER.  For purchases and top-ups with credit card AMEX, a fixed 4,5 % bank commission rate will be added.",
        PaymentChoiceItem_txtCommissionInfo_IDEAL: "No commission is charged.",
        PaymentChoiceItem_txtCommissionInfo_SOFORT: "In case of making Sofort payment; sum between 1€ and 500€ fixed 3€ and sum above 501€ fixed 5€ will be added to the total amount.",
        paymentChoices_lblCARI: "CURRENT",
        paymentComponent_commission: "Commission",
        paymentComponent_paymentInformations: "Payment information",
        paymentComponent_remainingBalance: "Your Remaining Balance",
        PaymentPurchaseWidget_btnReverse: "Cancellation Without Penalty",
        PaymentPurchaseWidget_btnReverseWithPenalty: "Refund with Penalty",
        PaymentPurchaseWidget_FLIGHT_RESERVATION_LEGAL_NOTICE: "Opsiyonlu kayıtlarda rezervasyon aşamasında gösterilen fiyat ile biletleme aşamasında gösterilen fiyat arasında farklılıklar oluşabilmektedir. Hatırlatmak isteriz ki; havayolları opsiyonlu kayıtlarda opsiyon süresi boyunca koltuk rezervasyonunu garanti etmekte olup fiyat opsiyonu ile ilgili bir garanti sunmamaktadır. Bu kurallar IATA ve havayolları tarafından belirlenmektedir.Havayolu kaynaklı bu tarz fiyat değişikliği olması halinde biletleme öncesi tarafınıza onaya sunulacaktır.",
        PaymentPurchaseWidget_NO_AUTHORIZATED_ACTION: "Your user is not authorized to do this operation. Please check your agent.",
        PaymentPurchaseWidget_NOT_SALES_OFFICE: "It is not a sales office. Please check your user or agent.",
        paymentPurchaseWidget_partialPay: "Partial Pay",
        PaymentPurchaseWidget_popup_REVERSE_FLIGHT: "Your ticket will be cancelled. Do you confirm ?",
        PaymentPurchaseWidget_popup_REVERSE_FLIGHT_W_PENALTY: "It can be refunded with a total penalty of #VALUE1# #VALUE2# .  Do you confirm?",
        PaymentPurchaseWidget_popup_REVERSE_HOTEL: "Your reservation will be cancelled. Do you confirm ?",
        PaymentPurchaseWidget_popup_REVERSE_RENTACAR: "Your reservation will be cancelled. Do you confirm ?",
        PaymentPurchaseWidget_popup_REVERSE_TRANSFER: "Your reservation will be cancelled. Do you confirm ?",
        PaymentPurchaseWidget_popup_TICKET_FLIGHT: "Do you want to buy your reservation?",
        PaymentPurchaseWidget_popup_TICKET_HOTEL: "Do you want to buy your reservation?",
        PaymentPurchaseWidget_popup_title: "Information",
        PaymentPurchaseWidget_progressGif_RESERVE_HOTEL: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_RESERVE_TRANSFER: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_ReverseFlight: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_ReverseHotel: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_ReverseTransfer: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_TICKET_FINANCE: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_TICKET_HOTEL: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_TICKET_TRANSFER: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_TicketReservedFlight: "Your transaction is in progress. Please wait.",
        PaymentPurchaseWidget_progressGif_TicketReservedHotel: "Your transaction is in progress. Please wait.",
        pending_pending: "Pending",
        PickupInfo_txtAirToAir: "* Transfer car will pick you up from #VALUE1# drop you off to #VALUE2# before #VALUE3#.",
        PickupInfo_txtAirToLoc: "* Transfer car will pick you up from #VALUE1# drop you off to #VALUE2#.",
        PickupInfo_txtLocToAir: "* Transfer car will pick you up from #VALUE1# drop you off to #VALUE2# before #VALUE3#.",
        PickupInfo_txtLocToLoc: "* Transfer car will pick you up around #VALUE1# from #VALUE3# drop you off to #VALUE2#.",
        PnrConfirm_bookingConfirmation: "Booking<br> Confirmation/<br> Rechnung",
        PnrConfirm_fare: "Fare",
        PnrConfirm_grandTotal: "Grand Total",
        PnrDoc_Email: "E-mail",
        pnrDoc_PopupCopiedToClipboard: "PDF link copied!",
        PnrOffer_flight: "Flight",
        popup_ancillaryDivideContent: "PNRs cannot be combined after divided. <br>Do you confirm?",
        popup_confirmationTitle: "Confirmation",
        Popup_ERROR: "An Error Occurred",
        Popup_exclamationTitle: "An Error Occurred",
        Popup_HOTEL_REVERSE_FAILURE_VOUCHER_NOT_REVERSABLE: "Please contact the Service Center to cancel your reservation.",
        popup_informationTitle: "Information",
        popup_lblCheckout: "Continue to checkout",
        Popup_lblChildrenAgesWarn: "Error",
        Popup_lblChildrenAgesWarnText: "Please check the child age.",
        Popup_lblEmptySearch: "Error",
        Popup_lblFlightSalesReportDateRange: "The date range should be a maximum of 1 year. Please check the dates you selected.",
        Popup_lblLessThanTwoCharacter: "Information",
        Popup_lblPackagesContent: "Package sales are coming soon here!",
        Popup_lblPackagesTitle: "Ready?",
        Popup_lblPlsWaitForContact: "Our team will assist you with your request as soon as possible.",
        Popup_lblRequestReceived: "Your request has been recieved.",
        popup_lblReserve: "Book Again with Same Details",
        Popup_lblSuccess: "Success",
        Popup_lblTransferMaintenance: "We are still working on this service.",
        Popup_lblWarning: "Error",
        Popup_ORDER_DETAIL_AUTHORIZATION_NOT_OWNER: "You are not authorized to view this order. Please continue the process by refreshing your page.",
        Popup_RESERVE_HOTEL_FAILURE_RESERVATION: "Please search your request again.",
        Popup_RESERVE_HOTEL_SUCCESS_OK: "You have successfully reserved your reservation. Please check your transaction.",
        Popup_SUCCESS: "Successful",
        popup_successTitle: "Successful",
        "Popup_TICKET_HOTEL_EXCEPTION_error.no.enough.room.quota": "While executing your transaction, the room quota has been exhausted. Please close the page and search again or contact our Service Center Team.",
        Popup_TICKET_HOTEL_SUCCESS_OK: "You have successfully purchased your reservation. Please check your transaction.",
        Popup_TICKETRESERVED_FLIGHT_SUCCESS_OK: "Your ticketing is successful.",
        Popup_TICKETRESERVED_HOTEL_SUCCESS_OK: "You have successfully reserved your reservation. Please check your transaction.",
        Popup_txtDuplicatedPassenger: "Multiple passengers cannot be registered with the same information.",
        Popup_txtEmptySearch: "Please fill in the search box and make a selection from the incoming list.",
        Popup_txtFlightChangeError: "You received an error during confirmation. Please contact our Service Center team.",
        Popup_txtFlightChangeErrorApprove: "You received an error during confirmation. Please contact our Service Center team.",
        Popup_txtFlightChangeErrorPNR_Reissue: "You received an error during confirmation. Please contact our Service Center team.",
        Popup_txtFlightChangeSuccess: "You have successfully confirmed your flight change. You can follow up via Service Center.",
        Popup_txtFlightChangeSuccessApprove: "You have successfully confirmed your flight change. You can follow up via Service Center.",
        Popup_txtFlightChangeSuccessPNR_Reissue: "Your request has been successfully forwarded to our team. You can follow up your request via the Service Center.",
        Popup_txtFlightChangeSuccessPNR_Reverse: "Your Cancellation Request has been successfully forwarded to our team. You can follow your request through the Service Center.",
        Popup_txtLessThanTwoCharacter: "Minimum 2 characters must be entered.",
        Popup_txtMealNotAvailable: "A meal request cannot be created for your reservation. Please contact the Service Center.",
        Popup_txtSsrsNotAvailable: "An ssr request cannot be created for your reservation. Please contact the Service Center.",
        Popup_txtWheelChairNotAvailable: "A wheelchair request cannot be created for your reservation. Please contact the Service Center.",
        Popup_txtWheelChairNowAvailable: "A wheelchair request cannot be created for your reservation. Please contact the Service Center.",
        popup_warningTitle: "Error",
        pricechange_btnCancel: "Cancel",
        priceChange_lblnewPrice: "New Price",
        priceChange_lblOldPrice: "Old Price",
        priceChange_lblpriceDiff: "Price Diff",
        profileSettings_lblAgencyAddress: "Address",
        profileSettings_lblAgencyCountryPhoneCode: "Country Area Code",
        profileSettings_lblAgencyCurrency: "Currency",
        profileSettings_lblAgencyDescription: "Information about your agency.",
        profileSettings_lblAgencyEmail: "E-mail",
        profileSettings_lblAgencyId: "Agency ID",
        profileSettings_lblAgencyInfo: "Agency Information",
        profileSettings_lblAgencyName: "Name",
        profileSettings_lblAgencyOfficialName: "Official Name",
        profileSettings_lblAgencyPhone: "Phone",
        profileSettings_lblAgencyShortName: "Short Name",
        profileSettings_lblAgencyTimezone: "Time Zone",
        profileSettings_lblBrowseProfilePhoto: "Select From Gallery",
        profileSettings_lblDragDropFile: "Drag and Drop File or",
        profileSettings_lblGsa: "Gsa",
        profileSettings_lblPasswordChangeContent: "For the security of your accounts, it has become necessary to make your passwords stronger. The effort we request from you is for your own accounts security. Thank you for your understanding in advance.",
        profileSettings_lblPasswordChangeTitle: "Please Click for Password Change",
        profileSettings_lblPrivacyAndSecurity: "Security Preferences",
        profileSettings_lblPrivacyAndSecurityDescription: "Information about your user.",
        profileSettings_lblProfilePhoto: "Profile Photo",
        profileSettings_lblProfilePhotoDescription: "The photo size you want to upload should be as below.",
        profileSettings_lblProfileSettings: "Edit Profile",
        profileSettings_lblRegionLanguagePreferences: "Region and Phone Preferences",
        profileSettings_lblRegionLanguagePreferencesDescription: "Information about your agency.",
        profileSettings_lblReset: "RESET",
        profileSettings_lblResetPassword: "Create New Password",
        profileSettings_lblUpdate: "UPDATE",
        profileSettings_lblUserDescription: "Information about your user.",
        profileSettings_lblUserEmail: "E-mail",
        profileSettings_lblUserInfo: "User Information",
        profileSettings_lblUserLanguage: "Language Preference",
        profileSettings_lblUserName: "Name",
        profileSettings_lblUserPhone: "Phone",
        profileSettings_lblUserSurname: "Last Name",
        profileSettings_lblUserTimezone: "Time Zone",
        renatacar_tooltipINSURANCE_ECO: "Rent a car insurance does not cover damage to tires, glass and headlights. An extra insurance must be purchased for this.",
        rentacar_airConditioning: "Air Conditioning",
        rentacar_Ancillaries: "Ancillary Services",
        rentacar_btnCancel: "Cancelled",
        rentacar_btnSearch: "RENT FOR #VALUE# DAY (S)",
        rentacar_btnSearchDaily: "Rent a Car Search",
        rentacar_btnSubmit: "Select",
        rentacar_doorCount: "Door Count",
        rentacar_driverInformations: "Driver Information",
        rentacar_dropoffDate: "Drop-Off Date",
        rentacar_extraFeatures: "Extra Features",
        rentacar_filtersFuelType: "Fuel Type",
        rentacar_filtersTransmissionType: "Transmission Type",
        rentacar_filtersVehicleType: "Car Type",
        rentacar_gearType: "Gear Type",
        rentacar_largeBaggage: "Large Baggage",
        rentacar_lblAddDropoffLocation: "Add Different Drop Off Location",
        rentacar_lblAncillaries: "Ancillary Services",
        rentacar_lblDropoffLocation: "Dropoff Location",
        rentacar_lblPerDay: "Per Day",
        rentacar_lblPickupLocaton: "Pickup Location",
        rentacar_lblRentACarDescriptionsInfoFives: "You may cancel free of charge up to 24 hours before the vehicle delivery time.",
        rentacar_lblRentACarDescriptionsInfoFour: "The vehicle agreement form must be signed between the agent and the driver.",
        rentacar_lblRentACarDescriptionsInfoOne: "You may physically have your drivers ID/drivers license with you.",
        rentacar_lblRentACarDescriptionsInfoThree: "Rent a car insurance does not cover damage to tires, glass and headlights. An extra insurance must be purchased for this.",
        rentacar_lblRentACarDescriptionsInfoTwo: "Credit card and deposit are not requested during car rental.",
        rentacar_lblRentACarDescriptionsTitle: "Rent a Car Description",
        rentacar_lblShowAncillaries: "Show Ancillaries",
        rentacar_lblTermsOfUse: "Terms Of Use",
        rentacar_locationSearchlbl: "City or Airport",
        rentacar_orSmiliar: "or Smiliar",
        rentacar_passengerCapacity: "Passenger Capacity",
        rentacar_pickupDate: "Pick-up Date",
        rentacar_ReservationDate: "Reservation Dates",
        rentacar_smallBaggage: "Small Baggage",
        rentacar_suitCases: "Baggage",
        rentacarCard_minDriverAge: "Minimum Driver Age  : #VALUE#",
        rentacarCard_minLicenseAge: "Minimum License Age : #VALUE#",
        rentacarCheckout_lblAgencyCommission: "Agency Commission",
        rentacarCheckout_lblAgencyExtraCommission: "Agency Extra Commission",
        rentacarCheckout_lblAncillaries: "Ancillary Services",
        rentacarCheckout_lblAncillaryTotal: "Ancillary Total",
        rentacarCheckout_lblDropOffLocation: "Dropoff Location",
        rentacarCheckout_lblGrandTotal: "Grand Total",
        rentacarCheckout_lblLastVoidDateTime: "In Rent A Car, you can cancel your vehicle delivery free of charge up to 24 hours before the rental.",
        rentacarCheckout_lblPaymentFeeTotal: "Credit Card Transaction Fee",
        rentacarCheckout_lblPickUpLocation: "Pickup Location",
        rentacarCheckout_lblPriceSummary: "Total",
        rentacarCheckout_lblReverseTotal: "Refund Total",
        rentacarCheckout_lblSaleTotal: "Sales Total",
        rentacarCheckout_lblVehicleTotal: "Vehicle Price",
        rentACarOrderDetail_lblRentACarAgencyNote: "Agency Note",
        rentacarOrderDetail_phOr: "or",
        rentacarOrderDetail_phSimilar: "similar",
        rentACarSalesReport_currency: "Currency",
        rentACarSalesReport_filter_btnList: "Search",
        rentACarSalesReport_filter_lblFromDate: "From Date",
        rentACarSalesReport_filter_lblReportType: "Report Type",
        rentACarSalesReport_filter_lblStatus: "Status",
        rentACarSalesReport_filter_lblToDate: "Creation To Date",
        rentACarSalesReport_lblReportType: "Report Type",
        rentACarSalesReport_refundTotal: "Total Refund",
        rentACarSalesReport_reverseAmount: "Total Refund",
        rentACarSalesReport_rnrCount: "Order Count",
        rentACarSalesReport_tblMain_thAgencyCommission: "Agency Commission",
        rentACarSalesReport_tblMain_thAgencyName: "Agency Name",
        rentACarSalesReport_tblMain_thDriverName: "Driver Name",
        rentACarSalesReport_tblMain_thDriverSurname: "Driver Surname",
        rentACarSalesReport_tblMain_thDropOffDateTime: "Drop-Off Date",
        rentACarSalesReport_tblMain_thDropOffLocation: "Dropoff Location",
        rentACarSalesReport_tblMain_thGrandTotal: "Grand Total",
        rentACarSalesReport_tblMain_thMarkModel: "Vehicle",
        rentACarSalesReport_tblMain_thOrderId: "Order ID",
        rentACarSalesReport_tblMain_thPickUpDateTime: "Pick-up Time",
        rentACarSalesReport_tblMain_thPickUpLocation: "Pickup Location",
        rentACarSalesReport_tblMain_thRnr: "Rnr",
        rentACarSalesReport_tblMain_thSaleDate: "Sale Date",
        rentACarSalesReport_tblMain_thStatus: "Status",
        rentACarSalesReport_tblMain_thTotal: "Total",
        rentACarSalesReport_title: "Rent a Car Sales Report",
        rentACarSalesReport_totalAmount: "Sale Total",
        rentACarSalesReport_vehicleCount: "Passanger Count",
        rentacarSearchForm_lblCreditCardDepositInfo: "Credit Card or Deposit is not requested during vehicle delivery.",
        rentacarSearchInformation_date1: "Pick-up Date and Time",
        rentacarSearchInformation_date2: "Drop-Off Date",
        rentacarSearchInformation_locationFrom: "Pickup Location",
        rentacarSearchInformation_locationTo: "Dropoff Location",
        rentacarSearchInformation_text: "Looking for the best prices, please wait!",
        requestForm_activeTab: "Task will open Pnr.",
        requestForm_packageTitle: "Task will open package",
        requestForm_pnrTitle: "Task will open Pnr.",
        requestForm_rnrTitle: "Task will open Rnr.",
        requestForm_tnrTitle: "Task will open Tnr.",
        requestForm_unknownTitle: "Under Construction",
        requestForm_voucherTitle: "Task will open Voucher",
        requestFormComponent_infoAlert_text: "Please mark the operation you want to do. The response time will vary depending on your choice.",
        requestFormComponent_textareaPlaceholder_label: "Please write your request...",
        reservationInfo_lblPopupPriceChangeTitle: "Reservation Price Change Information",
        ReservationInformation_progressGif_ReReserveFlight: "We are searching for same flights again for you.",
        resetPassword_emailEmpty: "Please enter a valid e-mail.",
        resetPassword_emailHasBeenSent: "The mail has been sent successfully.",
        resetPassword_lowerCase: "Lower Case",
        resetPassword_minChar: "Minimum #VALUE# characters.",
        resetPassword_numberRequired: "Number",
        resetPassword_passwordResetFaile: "Please check your password reset link.",
        resetPassword_passwordResetFailed: "You received an error. Please resend the password change link.",
        resetPassword_passwordResetMessage: "Please set a new password.",
        resetPassword_passwordResetSuccess: "Your password has been changed.",
        resetPassword_passwordResetTitle: "Password Change",
        resetPassword_passwordsAreDifferent: "The passwords you entered do not match.",
        resetPassword_passwordsAreDifferentOrShort: "The passwords you entered do not match.",
        resetPassword_passwordsAreShort: "Your password must be a minimum of 4 characters.",
        resetPassword_resetPassword: "Save New Password",
        resetPassword_specialChar: "Special Character",
        resetPassword_upperCase: "Upper Case",
        resetPassword_userPassword: "New Password",
        resetPassword_userPasswordConfirm: "ReEnter New Password",
        RESPONSE___EXCEPTION_QUOTA: "While executing your transaction, the room quota has been exhausted. Please close the page and search again or contact our Service Center Team.",
        RESPONSE___EXCEPTION_STOP: "While executing your transaction, the room quota has been exhausted. Please close the page and search again or contact our Service Center Team.",
        RESPONSE___EXPIRED_OK: "Price information may have changed.  Please continue your search by searching again.",
        RESPONSE__RESERVE_HOTEL_SUCCESS_OK: "Your transaction has been completed successfully.",
        RESPONSE__TICKET_HOTEL_SUCCESS_OK: "You have successfully purchased your reservation. Please check your transaction.",
        RESPONSE__TICKET_TRANSFER_SUCCESS_OK: "You have successfully purchased your transfer. Please check your transaction.",
        RESPONSE__TICKETRESERVED_HOTEL_SUCCESS_OK: "Your transaction has been completed successfully.",
        "RESPONSE__TRANSFER_API_TICKET_TRANSFER_ISSUED_::TchnGtsDatabaseService.Reservation successful": "You have successfully purchased your transfer. Please check your transaction.",
        "RESPONSE__TRANSFER_API_TICKET_TRANSFER_ISSUED_::TchnGtsDatabaseService.Reservation successful orderId:": "You have successfully purchased your transfer. Please check your transaction. Order ID:",
        RESPONSE_429_TooManyRequests_OK: "Hotel details cannot be displayed. Please refresh the page and try again or request support from our Service Centre team.",
        "RESPONSE_AGENT_API.HOTEL_TICKET_RESERVED.REJECTED.INSUFFICIENT_PAYMENT": "You received an error during your transaction.  Your payment could not be collected.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.FAILURE.NO_VEHICLE_FOUND": "No vehicles matching your criteria were found. Please change your search criteria and try again.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.NOT_EXISTS.LOCATION_NOT_MAPPED_FOR_PANDORA": "No suitable vehicle was found by the supplier for the location you are looking for. Please contact the Service Centre for your requests.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.REJECTED.PARAMETER.PERIOD_DAYS": "Rent a car is available for #VALUE# days and above.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.REJECTED.PARAMETER.PICKUP_HOURS": "Rent a car is available for #VALUE# hour(s) and above.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_PARAMETER_OK: "You entered the wrong parameter.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_REJECTED_EXPIRED: "You received an error while updating the balance.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during payment.",
        RESPONSE_AGENT_API_BalanceUpdate_AUTHORIZATION_NOT_SALES_OFFICE: "It is not a sales office. Please check your user or agent.",
        RESPONSE_AGENT_API_CHECKOUT_EXPIRED: "Prices may have been updated because you haven't traded for a long time.    You can continue your process by renewing your search.",
        "RESPONSE_AGENT_API_Detail_EXCEPTION_One or more errors occurred_": "You have received an unknown error.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TAKEN_OFF: "Please check your flight date.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TAKEN_OFF_TITLE: "Information",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TIME_NOT_AVAILABLE: "Please contact the Service Center to purchase baggage.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TIME_NOT_AVAILABLE_TITLE: "Information",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_MANUAL_DIVIDED: "You received an error when buying baggage. Please contact the Service Center.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_MANUAL_DIVIDED_TITLE: "Information",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_ANCILLARY: "Baggage has already been taken for this booking. Please contact the Service Centre team.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_ANCILLARY_TITLE: "Baggage Information",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_TASK: "Please contact the Service Center for the purchase of baggage.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_TASK_TITLE: "Information",
        "RESPONSE_AGENT_API_FLIGHT_CHECKOUT_ PARAMETER_NOT_RESERVABLE": "Reservation cannot be made. Please continue the process by renewing your search.",
        "RESPONSE_AGENT_API_FLIGHT_CHECKOUT_ PARAMETER_NOT_TICKETABLE": "Ticketing cannot be made. Please continue the process by renewing your search.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "Your user is not authorized to do this operation. Please check your agent.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXCEPTION_CHECKOUT: "We encountered an unknown error.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_BASKET: "Price information may have changed.  Please continue your search by searching again.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_CHECKOUT: "Price information may have changed.  Please continue your search by searching again.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_SEARCH: "Prices may have been updated because you haven't traded for a long time.    You can continue your process by renewing your search.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_FAILURE_NO_PRICE: "No price was found for the flight you selected. Please search again.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Reservation cannot be made. Please continue the process by renewing your search.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_RETRY_NO_RESPONSE: "Please enter the requested information completely and accurately.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_SESSION_INVALID_AGENCY: "Please select agency.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_EXPIRED_CHECKOUT_NOT_FOUND: "A timeout was received during the cancel process. Please try again later.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_FAILURE_RESERVATION_FAILED: "You received an error during booking. Your new reservation has not been made.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_BRAND_OR_SSR_NOT_FOUND: "Brand or Ssr not found. Please continue manually.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_CHECKOUT_FAILED: "An error occurred during your operation. You can continue your process by searching again.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_FLIGHT_NOT_FOUND: "We could not find the flights for your previous booking with the airline. Please check the dates.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_SESSION_INVALID_GSA_OR_AGENCY: "Your user is not authorized to do this operation. Please check your agent.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_EXPIRED_INQUIRY: "You received a timeout during your transaction. Please continue the process by refreshing your page.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_FAILURE_ALREADY_REVERSED: "Your PNR is canceled, please refresh your page. If you think there is a problem, please contact the Service Center.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_FAILURE_UNREVERSABLE: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_INQUIRY_REJECTED_PNR_EXPIRED: "The last purchase time has passed. You can perform manual cancellation by contacting the Service Center.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_SUCCESS_OK: "Your transaction has been completed successfully.",
        RESPONSE_AGENT_API_FLIGHT_SEARCH_SESSION_INVALID_AGENCY: "Please select agency.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_AUTHORIZATION_DIFFERENT_USER: "You received an error during flight selection. Please search again.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Prices may have been updated because you haven't traded for a long time.    You can continue your process by renewing your search.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_NOT_EXISTS_NO_PACKAGE_AVAILABLE: "There is no package fee for the flight you have selected.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Please select 'Conjugate' flights, or deselect if you have a 'One Way Fare' selection.",
        "RESPONSE_AGENT_API_FLIGHT_TICKET_ FAILURE_PRICE_DIFFERENCE": "There was a price difference during your transaction. Please select the transaction you want to do.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_EXCEPTION_PNR_RETRIEVE: "Your reservation information has changed. Automatic operation is not possible. Please contact the Service Center.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_EXCEPTION_TICKET: "We encountered an unknown error during ticketing.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "There was a price difference during your ticketing process.  Price Difference :",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE_TITLE: "Price Changed!",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_REJECTED: "Your flight details have changed.  Please contact the Service Center.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_REJECTED_ISSUED: "Your reservation has been issued.  Please contact our Service Centre team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_PARAMETER_EXISTED: "You may have a previous ticket or reservation for the details you want to process. Please check with our Service Center team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_PRICE_DIFFERENCE_AFTER_RESERVATION: "There was a price difference during your transaction.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_CHANGE_DETECTED: "Your flight details have changed. Please contact the Service Center.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_IMPORT_TYPE_MANUAL: "Due to the manual processing of your ticket, we are unable to process your ticketing at the moment.  Please open a task to our Service Center team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_FUNDS: "You have no balance. Please contact our Sales Team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during your transaction.  Your payment could not be collected.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_UNSUFFICIENT_FUNDS: "Your available balance is insufficient.  Please contact our team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_RETRY_RESERVATION_PRICE_CHECK: "An error occurred during your operation.  You can continue your process by searching again.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_SESSION_INVALID_AGENCY: "Please select an agency to make ticketing.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_SUCCESS_PAYMENT_POST: "Your transaction has been completed successfully.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_TIMEOUT_EXPIRED: "Your pay transaction has expired.  Please try again.",
        RESPONSE_AGENT_API_HOTEL_CHECKOUT_EXPIRED_BASKET: "Price information may have changed.  Please continue your search by searching again.",
        RESPONSE_AGENT_API_HOTEL_CHECKOUT_REJECTED_CHECKOUT_NOT_AVAILABLE: "Prices may have been updated because you haven't traded for a long time. You can continue your process by renewing your search.",
        RESPONSE_AGENT_API_HOTEL_DETAIL_EXPIRED_HOTEL_SEARCH: "Hotel details cannot be displayed. Please refresh the page and try again or request support from our Service Centre team.",
        RESPONSE_AGENT_API_HOTEL_REVERSE_FAILURE_VOUCHER_CANT_RETRIEVED: "Please contact the Service Center to cancel your reservation.",
        RESPONSE_AGENT_API_HOTEL_SEARCH_PARAMETER_INVALID_DESTINATION: "Please fill in the search box and make a selection from the incoming list",
        RESPONSE_AGENT_API_HOTEL_SEARCH_SESSION_INVALID_AGENCY: "Please select agency.",
        RESPONSE_AGENT_API_HOTEL_SELECT_EXPIRED_HOTEL_DETAIL: "Price information may have changed.  Please continue your search by searching again.",
        "RESPONSE_AGENT_API_IAgtBllSchmetterlingService.ShowOrder_FAILURE_NULL_LIST_RESPONSE": "There is no package sales on these dates. Check the dates and try again.",
        RESPONSE_AGENT_API_LOGIN_AUTHORIZATION_INVALID_CREDENTIALS: "Please make sure that the information you entered is correct.",
        RESPONSE_AGENT_API_LOGIN_AUTHORIZATION_USER_BLOCKED: "Your user has been blocked due to incorrect login information. You can log in again by changing your password.",
        RESPONSE_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "Please make sure that the information you entered is correct.",
        RESPONSE_AGENT_API_LOGIN_PARAMETER_INVALID_DOMAIN_NAME: "The user information you are trying to log in does not belong to this website. Please check your login URL.",
        RESPONSE_AGENT_API_ORDER_DETAIL_AUTHORIZATION_NOT_OWNER: "You are not authorized to view this order.",
        RESPONSE_AGENT_API_PWD_RESET_REJECTED_INVALID_DOMAIN_NAME: "You cannot reset your password via this link. Please check your login information and url.",
        RESPONSE_AGENT_API_PWD_RESET_REJECTED_INVALID_USER: "Please enter a valid e-mail.",
        RESPONSE_AGENT_API_RENT_A_CAR_BOOK_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during your transaction.  Your payment could not be collected.",
        RESPONSE_AGENT_API_RENT_A_CAR_BOOK_REJECTED_PAYMENT_POST: "You received an error during your transaction.  Your payment could not be collected.",
        RESPONSE_AGENT_API_RENT_A_CAR_REVERSE_FAILURE_FAILURE: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_AGENT_API_RENT_A_CAR_SEARCH_PARAMETER_INVALID_DATES: "Please check the dates.",
        RESPONSE_AGENT_API_TAKE_NOTE_PARAMETER_INVALID_NOTE: "This field cannot be left blank.",
        RESPONSE_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE: "Your request has already been forwarded to our Service Center team.  Please follow your request on the Service Center.",
        RESPONSE_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE_TITLE: "Information",
        RESPONSE_AGENT_API_TASK_CREATE_SUCCESS_OK: "Your request has been successfully forwarded to our team. You can follow up your request via the Service Center.",
        RESPONSE_AGENT_API_TICKET_EXCEPTION: "We encountered an unknown error.",
        RESPONSE_AGENT_API_TICKET_EXPIRED: "Price information may have changed.  Please continue your search by searching again.",
        RESPONSE_AGENT_API_TICKET_FAILURE: "There was a price difference during your transaction.  Do you accept the price difference?",
        RESPONSE_AGENT_API_TICKET_FAILURE_PRICE_DIFFERENCE: "There was a price difference during your ticketing process.  Price Difference :",
        RESPONSE_AGENT_API_TICKET_FAILURE_PRICE_DIFFERENCE_TITLE: "Price Difference",
        RESPONSE_AGENT_API_TICKET_HOTEL_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during your transaction. Your payment could not be collected.",
        RESPONSE_AGENT_API_TICKET_PARAMETER: "Your search is not valid.  Please check your search criteria and try again.",
        RESPONSE_AGENT_API_TICKET_PRICE_DIFFERENCE: "Price difference occured! Do you accept the price difference?",
        RESPONSE_AGENT_API_TICKET_PRICE_DIFFERENCE_TITLE: "Price Difference",
        RESPONSE_AGENT_API_TICKET_REJECTED_PNR_STATUS: "Your Pnr status has changed. Please continue the process by refreshing your screen.",
        RESPONSE_AGENT_API_TICKET_RETRY: "An error occurred during your operation.  You can continue your process by searching again.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_FAILURE_PRICE_DIFFERENCE: "There was a price difference during your transaction.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_FAILURE_REJECTED: "We encountered an unknown error during ticketing.  Please contact our Service Center Team for the cause of the error.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_CHANGE_DETECTED: "Your flight details have changed. Please contact the Service Center.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_INSUFFICIENT_FUNDS: "You have no balance. Please contact our Sales Team.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_INSUFFICIENT_PAYMENT: "You received an error during your transaction.  Your payment could not be collected.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_PNR_EXPIRED: "The last purchase time has passed. You can provide a manual check by contacting the Service Center.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_SUCCESS_OK: "Your ticketing is successful.",
        RESPONSE_AGENT_API_TICKETRESERVED_HOTEL_REJECTED_RETRIEVE_: "There was no response from the provider. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_AGENT_API_UNKNOWN_REJECTED_HOTEL_CHECK_AVAILABILITY: "We encountered an unknown error. Please contact with our Service Center team.",
        RESPONSE_AGENT_PI_FLIGHT_TICKET_REJECTED_IMPORT_TYPE_MANUAL: "Due to the manual processing of your ticket, we are unable to process your ticketing at the moment.  Please open a task to our Service Center team.",
        RESPONSE_AGENT_WEB_FLIGHT_CHECKOUT_TIMEOUT_SERVICE_TIMEOUT: "Price information may have changed.  Please continue your search by searching again.",
        "RESPONSE_FLIGHT.API.FLIGHT.RESERVATION.FAILURE.INVALID.PARENT.AGE": "Adult age is not suitable for traveling with a baby.",
        "RESPONSE_FLIGHT.API.FLIGHT.TICKET.FAILURE.INVALID.PARENT.AGE": "Adult age is not suitable for traveling with a baby.",
        "RESPONSE_FLIGHT_API _FLIGHT_SSR_TICKET_FAILURE_REQ_SSR_UNAVAILABLE": "There is no availability for the wheelchair you have selected on this flight.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.INVALID_AGE_FOR_PAX_TYPE": "Please check the date of birth you entered.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.INVALID_IDENTITY_INFO": "Please check the passenger information you have entered.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.UNMAPPED_ERROR": "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        "RESPONSE_FLIGHT_API.FLIGHT_REVERSE.FAILURE.UNMAPPED_ERROR": "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_EMPTY_SERVICE_RESPONSE: "There was no response from the airline. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_EMPTY_SERVICE_RESPONSE_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_REJECTED: "There was no response from the airline. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_REJECTED_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_UNMAPPED_ERROR: "There was no response from the airline. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_UNMAPPED_ERROR_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_REJECTED_NOT_IMPLEMANTED_SERVICE: "Please contact the Service Center to purchase baggage.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_REJECTED_NOT_IMPLEMANTED_SERVICE_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_COMMON_ERROR: "We encountered an unknown error when switching to the flight information page.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_REQUESTED_CLASS_NOT_AVAILABLE: "Price information is not valid for the class you have selected.Please continue your search by searching again.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNKNOWN_ERROR: "We encountered an unknown error when switching to the flight information page.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "We encountered an unknown error when switching to the flight information page.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_NOT_EXISTS_PACKAGE_NOT_EXIST: "No cabin information was found for the flight you selected.",
        RESPONSE_FLIGHT_API_FLIGHT_DIVIDE_PNR_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Your reservation information has changed. Automatic operation is not possible. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_COMMON_ERROR: "We encountered an unknown error.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR: "We encountered an unknown error. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNMAPPED_ERROR: "We encountered an unknown error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_COMMON_ERROR: "We encountered an unknown error while reservation. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_UNKNOWN_ERROR: "We encountered an unknown error while reservation. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_UNMAPPED_ERROR: "We encountered an unknown error while reservation. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_COMMON_ERROR: "We encountered an unknown error while reservation. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_UNKNOWN_ERROR: "We encountered an unknown error while reservation. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_UNMAPPED_ERROR: "We encountered an unknown error while reservation. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_COMMON_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_CONTINUE_WITH_INSTANT_TICKETING: "The airline does not make reservations. Please try to buy directly.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_DUBLICATE_PASSENGER: "You may have a previous ticket or reservation for the details you want to process. Please check with our Service Center team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_EMPTY_SERVICE_RESPONSE: "There was no response from the airline. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_EXCEEDED_MAX_PAX: "The airline does not accept the number of passengers entered. Please contact our Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_HES_CODE_DECLINED: "Hes code is invalid. Please check your hes code.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_IDENTITY_OR_PASSPORT_NUMBER_MANDATORY: "T.R. Identity Information or Passport information is mandatory. Please enter the requested information.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_AGE_FOR_PAX_TYPE: "Please check the date of birth you entered.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_CONTACT_INFO: "Please check the contact information you have entered.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_IDENTITY_INFO: "Please check the passenger information you have entered.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_MINIMUM_CONNECTION_TIME: "Minimum connection time exceeded. Please check your flights.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_NOT_ABLE_TO_VERIFY_LOYALTY_CARD: "Please enter your Mile Number and Identity Information correctly.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_PRICE_CHANGED: "Price information may have changed.  Please continue your search by searching again.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_REQUESTED_CLASS_NOT_AVAILABLE: "No seat left for the requested flight and class",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SELECTED_FLIGHT_NOT_AVAILABLE_FOR_BOOKING: "Reservation cannot be made.  Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SERVICE_ERROR: "We encountered an unknown error while reservation.<br> Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SSR_LIMIT_EXCEEDED: "The ssr limit added on the airline side has been exceeded.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_UNKNOWN_ERROR: "We encountered an error while booking. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_UNMAPPED_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_WAIT_LIST_CLOSED: "No seat left for the requested flight and class.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_COMMON_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_INVALID_PASSENGER_INFO: "Please check the passenger information you have entered.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_UNKNOWN_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_UNMAPPED_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_REJECTED_CONTINUE_WITH_INSTANT_TICKETING: "The airline does not make reservations. Please try to buy directly.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_REJECTED_MINIMUM_CONNECTION_TIME: "Please check flight times.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_COMMON_ERROR: "We encountered an unknown error. Please contact our Service Center team.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_EMPTY_SERVICE_RESPONSE: "There was no response from the airline. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_INVALID_PASSENGER_INFO: "There is a change in your passenger information.Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_SERVICE_ERROR: "You have received a connection error. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNKNOWN_ERROR: "We encountered an unknown error. Please contact our Service Center team.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNKNOWN_ERROR_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNMAPPED_ERROR: "We encountered an unknown error. Please contact our Service Center team.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_EXPIRED_REVERSE_INQUIRY_EXPIRED: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_EMPTY_SERVICE_RESPONSE: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_REJECTED: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_REVERSE_TYPE_CHANGED: "Due to manual processing of your ticket, we are unable to process your transaction at the moment.  Please open a task to our Service Center team.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_UNKNOWN_ERROR: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_UNMAPPED_ERROR: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_CONNECTION_UNKNOWN_ERROR: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_EMPTY_SERVICE_RESPONSE: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Your reservation information has changed. Automatic operation is not possible. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_SERVICE_ERROR: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_UNMAPPED_ERROR: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_REJECTED_UNKNOWN_ERROR: "We encountered an unknown error during ticketing.Please contact our Service Centre team for the status of your ticket.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_REJECTED_NOT_IMPLEMANTED_SERVICE: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_REJECTED_UNKNOWN_ERROR: "You received an error during the cancellation process. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Your reservation information has changed. Automatic operation is not possible. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_SEARCH_CONNECTION_UNKNOWN_ERROR: "We encountered an unknown error during ticketing.  Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_CONNECTION_UNKNOWN_ERROR: "We encountered an unknown error. Please contact our Service Center team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_COMMON_ERROR: "We encountered an unknown error during ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INSUFFICIENT_STOCK: "You have received an error from the provider.  Please contact our Service Center team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INVALID_CONTACT_INFO: "Please check the contact information you have entered.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INVALID_PASSENGER_INFO: "Please check the passenger information you have entered.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_PNR_HAS_BEEN_MODIFIED: "Your Pnr status has changed. Please contact our Service Center team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATED_FLIGHT_CHANGED: "Your flight details have changed. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATED_PASSENGER_INFO_CHANGED: "There is a change in your passenger information.Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Your reservation information has changed. Automatic operation is not possible. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNKNOWN_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNMAPPED_ERROR: "We encountered an unknown error during ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_REJECTED_REQUESTED_CLASS_NOT_AVAILABLE: "Price information is not valid for the class you have selected.Please continue your search by searching again.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_TIMEOUT_SERVICE_TIMEOUT: "Your pay transaction has expired.  Please try again.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_FLIGHT_SCHEDULE_CHANGED: "Your flight details have changed. Please contact the Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_FLIGHT_SCHEDULE_CHANGED_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_REISSUE_NOT_AVAIL: "Reissue to your flight cannot be made online. Please contact the Service Centre.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_REISSUE_NOT_AVAIL_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNKNOWN_ERROR: "Your flight details or passenger details do not match the airline. Please contact the Service Centre.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNKNOWN_ERROR_TITLE: "Important Information",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNMAPPED_ERROR: "We encountered an unknown error in the reissue query. Please contact our Service Centre Team for the cause of the error.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNMAPPED_ERROR_TITLE: "We encountered an unknown error.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_REJECTED_NOT_IMPLEMANTED_SERVICE: "Please contact the Service Centre to make reissue.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_REJECTED_NOT_IMPLEMANTED_SERVICE_TITLE: "Information",
        "RESPONSE_FLIGHT_API_TICKET_EXCEPTION,": "We encountered an unknown error.",
        "RESPONSE_FLIGHT_API_TICKET_FAILURE,_COMMON_ERROR,": "We encountered an unknown error during ticketing.",
        "RESPONSE_FLIGHT_API_TICKET_FAILURE,_UNKNOWN_ERROR,": "We encountered an unknown error.",
        RESPONSE_FLIGHT_API_TICKET_FAILURE_DUBLICATE_PASSENGER: "You may have a previous ticket or reservation for the details you want to process. Please check with our Service Center team.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_COMMON_ERROR: "We encountered an unknown error during ticketing.<br> Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_INSUFFICIENT_STOCK: "You have received an error from the provider.  Please contact our Service Center team.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_UNKNOWN_ERROR: "We encountered an unknown error during ticketing.<br> Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_UNMAPPED_ERROR: "We encountered an unknown error during ticketing.<br> Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_COMMON_ERROR: "We encountered an unknown error.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_UNKNOWN_ERROR: "We encountered an unknown error.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_UNMAPPED_ERROR: "We encountered an unknown error.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_COMMON_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_UNKNOWN_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_UNMAPPED_ERROR: "We encountered an error while ticketing. Please contact our Service Center Team for the cause of the error.",
        "RESPONSE_HOTEL_API.TICKET.EXCEPTION.MrCannotStay": "Mr. stays are not accepted by this hotel. Please close the page and search again.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_[holder.phone] allows digit only": "Please fill in the contact information section again using numbers.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_error.hotel.not.accepts.only.man": "Mr. stays are not accepted by this hotel. Please close the page and search again.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_error.no.enough.room.quota": "While executing your transaction, the room quota has been exhausted. Please close the page and search again or contact our Service Center Team.",
        RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_ExistingOfferId: "Please close this page and research your offer again.",
        RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_MrCannotStay: "Mr. stays are not accepted by this hotel. Please close the page and search again.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_REJECTED_MR.STAY": "Mr. stays are not accepted by this hotel.",
        "RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_ExistingOfferId_This offerid already requested for booking": "Please close this page and research your offer again.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_GTS_BOOKING: "While executing your transaction, the room quota has been exhausted. Please close the page and search again or contact our Service Center Team.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_NO_ROOM_AVAILABLE: "While executing your transaction, the room quota has been exhausted. Please close the page and search again or contact our Service Center Team.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_ROOM_HAS_STOP: "While executing your transaction, the room quota has been exhausted. Please close the page and search again or contact our Service Center Team.",
        "RESPONSE_HOTEL_API_TICKET_HOTEL_REJECTED_MR.STAY": "The <strong>accommodation type</strong> you have requested is not suitable for this hotel. Please contact the Service Center.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_SUCCESS_OK: "Your transaction has been completed successfully.",
        RESPONSE_PRICE_CHANGE: "There was a price difference during your transaction.",
        RESPONSE_REJECTED_NOT_AVAILABLE: "The airline does not allow changes for the relevant flight.",
        RESPONSE_SUCCESS_OK: "The mail has been sent successfully.",
        RESPONSE_TICKET_HOTEL_EXCEPTION_JP_BOOK_OCCUPANCY_NAMES_ERROR: "Guest names cannot be the same. Please check and try again.",
        RESPONSE_TICKET_TRANSFER_SUCCESS_OK: "You have successfully purchased your transfer. Please check your transaction.",
        RESPONSE_TRANSFER_API_TICKET_TRANSFER_ISSUED_OK: "You have successfully purchased your transfer. Please check your transaction.",
        RESULT_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR: "We encountered an unknown error. Please contact our Service Center team.",
        RESULT_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_REJECTED: "We encountered an unknown error while reservation. Please contact our Service Center Team for the cause of the error.",
        roomCard_lblRoomPrice: "Room Price",
        roomCardComponent_roomDetail_buttonLabel: "Room Information",
        roomCardComponent_roomPriceLabel: "Room Price",
        router_agencyRequired: "Only agents can access this page.",
        router_chooseAgency: "Please select agency.",
        router_contactForAuth: "If you think there is a mistake, please contact the authorities!",
        router_noAuth: "You are not authorized to access this page!",
        router_noInvalidOtp: "Information",
        router_noInvalidOtpNote: "Please check your token.",
        router_titleAccTransactionList: "Account Transaction List",
        router_titleBalanceUpdate: "Balance Update",
        router_titleDashboard: "Dashboard",
        router_titleDashboard2: "Dashboard",
        router_titleFlightCheckout: "Flight Sale",
        router_titleFlightSale: "AGENT",
        router_titleFlightSalesReport: "Flight Sales Report",
        router_titleHotelCache: "Hotel Cache Search",
        router_titleHotelCheckout: "Hotel Sale",
        router_titleHotelSale: "AGENT",
        router_titleHotelSalesReport: "Hotel Sales Report",
        router_titleInvoiceList: "Invoice List",
        router_titleLogin: "Login",
        router_titleNotification: "Notification",
        router_titleOrderDetail: "Order Detail",
        router_titleOrderSearch: "Order Search",
        router_titlePackageSearch: "Packages",
        router_titlePageNotFound: "Page Not Found",
        router_titlePnrDoc: "PNR Document",
        router_titleRegister: "Register",
        router_titleRentACar: "Rent A Car",
        router_titleRentACarCheckut: "Rent A Car Checkout",
        router_titleRentACarSalesReport: "Rent a Car Sales Report",
        router_titleSchmetterlingSalesReport: "Package Sales Report",
        router_titleSettings: "Settings",
        router_titleTaskList: "Task List",
        router_titleTestDev: "TestDev",
        router_titleTransferCheckout: "Transfer Checkout",
        router_titleTransferSale: "Transfer Sale",
        router_titleTransferSalesReport: "Transfer Sales Report",
        router_titleVersion: "Version",
        saleReport_airlineList: "Airlines",
        saleReport_creationFromDate: "From Date",
        saleReport_creationToDate: "Creation To Date",
        saleReport_optEticket: "E-Ticket",
        saleReport_optPNR: "Pnr",
        saleReport_pnrStatus: "Pnr Status",
        saleReport_providerList: "Provider List",
        saleReport_reportType: "Report Type",
        saleReport_showReissues: "Show Reissue",
        saleReport_userList: "User List",
        schmetterlingSalesReport_filter_btnList: "List",
        schmetterlingSalesReport_filter_lblCustomerName: "Customer Name",
        schmetterlingSalesReport_filter_lblCustomerSurname: "Customer Surname",
        schmetterlingSalesReport_filter_lblFromDate: "Creation From Date",
        schmetterlingSalesReport_filter_lblReservationNo: "Schmetterling ID",
        schmetterlingSalesReport_filter_lblStatus: "Status",
        schmetterlingSalesReport_filter_lblToDate: "Creation To Date",
        schmetterlingSalesReport_filter_plhCustomerName: "Customer Name",
        schmetterlingSalesReport_filter_plhCustomerSurname: "Customer Surname",
        schmetterlingSalesReport_filter_plhReservationNo: "Schmetterling ID",
        schmetterlingSalesReport_tblMain_thCheckInDateTime: "Check In Date",
        schmetterlingSalesReport_tblMain_thCheckOutDateTime: "Check Out Date",
        schmetterlingSalesReport_tblMain_thCustomerNameSurname: "Customer Name And Surname",
        schmetterlingSalesReport_tblMain_thOrderId: "Order ID",
        schmetterlingSalesReport_tblMain_thReservationNo: "Schmetterling ID",
        schmetterlingSalesReport_tblMain_thStatus: "Status",
        schmetterlingSalesReport_tblMain_thTotalPrice: "Total Fee",
        schmetterlingSalesReport_tblMain_thTourOperator: "Tour Operator",
        schmetterlingSalesReport_tblMain_thTravelType: "Travel Type",
        schmetterlingSalesReport_tblMain_thUser: "ID of the User That Created the Booking",
        schmetterlingSalesReport_title: "Package Sales Report",
        Search_Response_AGENT_API_FLIGHT_SEARCH_SESSION_INVALID_AGENCY: "Please select agency.",
        Search_Response_AGENT_WEB_FLIGHT_SEARCH_TIMEOUT_SERVICE_TIMEOUT: "An error occurred during your operation.  You can continue your process by searching again.",
        Search_Response_FLIGHT_API_FLIGHT_SEARCH_CONNECTION_UNKNOWN_ERROR: "You received a connection error while searching for a flight.",
        searchComponentFlight_addLeg: "Add Flight",
        searchComponentFlight_airlines: "Airlines",
        searchComponentFlight_airlinesSelected: "Airline Selected",
        searchComponentFlight_autocompleteArrival: "Arrival",
        searchComponentFlight_autocompleteDeparture: "Departure",
        searchComponentFlight_cabinTypes: "Cabin Type",
        searchComponentFlight_cheapestPrice: "Lowest package price",
        searchComponentFlight_departureDate: "Departure Date",
        searchComponentFlight_filterAirlines: "Filter Airlines",
        searchComponentFlight_flightRules: "Flight Rules",
        searchComponentFlight_lastSeat: "Last Seat",
        searchComponentFlight_paxes: "Passengers",
        searchComponentFlight_removeLeg: "Remove Flight",
        searchComponentFlight_returnDate: "Return Date",
        searchComponentFlight_searchFlight: "SEARCH FLIGHTS",
        searchComponentFlight_stops: "Stop",
        searchComponentFlight_travelDuration: "Travel Duration",
        searchComponentFlight_waitingTime: "Waiting Time",
        searchComponentHotel_autocompleteLocationPlaceholder: "Destination, Zone, Hotel",
        searchComponentHotel_dateLabel: "Check-in - Check-out Date",
        searchComponentHotel_destinationLabel: "Destination, Zone, Hotel",
        searchComponentHotel_guestLabel: "Number of Guests",
        searchComponentHotel_nationalityLabel: "Guest Nationality",
        searchFiltersFlight_airlines: "Airlines",
        searchFiltersFlight_airlineTransfer: "Airport Transfer",
        searchFiltersFlight_arrivalHour: "Departure-arrival",
        searchFiltersFlight_baggageAllowance: "Baggage Allowance",
        searchFiltersFlight_business: "Business",
        searchFiltersFlight_cabinTypes: "Cabin Types",
        searchFiltersFlight_connections: "Connections",
        searchFiltersFlight_departureHour: "Departure-takeoff",
        searchFiltersFlight_deselectAll: "Clear",
        searchFiltersFlight_economy: "Economy",
        searchFiltersFlight_EFltConnectionType_airlineTransfer: "Airport Transfer",
        searchFiltersFlight_EFltConnectionType_noneStop: "None Stop",
        searchFiltersFlight_EFltConnectionType_stop: "Stop",
        searchFiltersFlight_EFltConnectionType_UNKNOWN: "Unknown",
        searchFiltersFlight_noBaggage: "No Baggage",
        searchFiltersFlight_noneStop: "None Stop",
        searchFiltersFlight_selectAll: "Select All",
        searchFiltersFlight_stop: "Connection",
        searchFiltersFlight_time: "Departure / Arrival Hours",
        searchFiltersFlight_withBaggage: "With Baggage",
        searchFiltersHotel_deselectAll: "Clear All",
        searchFiltersHotel_lblCustomPriceRange: "Price Range",
        SearchInformationPopupFlight_allAirports: "All Airports",
        SearchInformationPopupFlight_text: "We are searching for flights, please wait.",
        SearchOrderList_lblBrandPnr: "Airline Pnr",
        seatMapModal_lblYanyana: "Side by Side",
        SeatMapModal_progressGif: "Your transaction is in progress. Please wait.",
        serviceCenter_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE: "Your request has already been forwarded to our Service Center team.  Please follow your request on the Service Center.",
        serviceCenter_AGENT_API_TASK_CREATE_SUCCESS_OK: "Your request has been successfully forwarded to our team. You can follow up your request via the Service Center.",
        serviceCenter_ancillary: "Ancillary",
        serviceCenter_cancellationRefund: "Cancellation - Refund",
        serviceCenter_openTicket: "Open Ticket",
        serviceCenter_reissue: "Reissued",
        serviceCenterComponent_tabChangeDetails_label: "Flight Change",
        serviceCenterComponent_tabMyNotes_label: "My Own Notes",
        serviceCenterComponent_tabServiceCenterRequestForm_label: "Service Center Request Form",
        serviceCenterComponent_tabTaskChat_label: "Task Details",
        serviceCenterRequestForm_btnSend: "Send",
        serviceCenterRequestForm_popupTaskCreate: "Your request has been successfully forwarded to our team. You can follow up your request via the Service Center.",
        serviceCenterRequestForm_taskOpeningInfos: "Your TASKTYPE request will be processed for this SELECTION",
        Set_Customer_SUCCESS_OK: "Successful",
        setting_profileSettings: "Profile Settings",
        settings_customerManagement: "Customer Transactions",
        settings_customerSettings: "Customer Transactions",
        settings_profileSettings: "Profile Settings",
        settings_userAuthorization: "User Role",
        settings_userAuths: "User Authorizations",
        settings_userSettings: "User Settings",
        settingsPage_customerBookSubPage_newCustomerAddButtonText: "Add New Customer",
        settingsPage_customerBookSubPage_pageTitle: "Customer Transactions",
        settingsPage_lblRegionLanguagePreferencesDescription: "Information about your agency.",
        settingsPage_navMenu_customerSettings: "Customer Transactions",
        settingsPage_navMenu_profileSettings: "Profile Settings",
        settingsPage_navMenu_userRoles: "User Role",
        settingsPage_navMenu_userSettings: "User Settings",
        settingsPage_profileSettingsSubPage_agencyInformationDescription: "Information about your agency.",
        settingsPage_profileSettingsSubPage_agencyInformationLabel: "Agency Information",
        settingsPage_profileSettingsSubPage_browseProfilePhotoButtonText: "Select From Gallery",
        settingsPage_profileSettingsSubPage_dragDropFileText: "Drag and Drop File or",
        settingsPage_profileSettingsSubPage_pageTitle: "Edit Profile",
        settingsPage_profileSettingsSubPage_profilePhotoDescription: "The photo size you want to upload should be as below.",
        settingsPage_profileSettingsSubPage_profilePhotoLabel: "Profile Photo",
        settingsPage_profileSettingsSubPage_regionAndLanguagePreferencesDescription: "Information about your agency.",
        settingsPage_profileSettingsSubPage_regionAndLanguagePreferencesLabel: "Region and Phone Preferences",
        settingsPage_profileSettingsSubPage_userInformationDescription: "Information about your user.",
        settingsPage_profileSettingsSubPage_userInformationLabel: "User Information",
        settingsPage_userAuthorizationSubPage_pageTitle: "User Role",
        settingsPage_userSettingsSubPage_pageTitle: "User Settings",
        SignIn_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "Please make sure that the information you entered is correct.",
        SignIn_CookieText: "We use cookies in line with our personal data policy to ensure that we give you the best experience on our website.",
        SignIn_emailIsValidating: "It is checked.",
        SignIn_forgotPassword: "Forgotten Password!",
        SignIn_legalText: "By signing up, you agree to the",
        SignIn_legalText2: "and",
        SignIn_legalText3: "including",
        SignIn_legalTextCookieUse: "Cookie Use.",
        SignIn_legalTextPrivacyPolicy: "Privacy Policy",
        SignIn_legalTextTermsOfService: "Terms of Service",
        SignIn_loginHeader1: "Welcome",
        SignIn_loginHeader2: "Please login to the system.",
        SignIn_signInBtn: "Log in",
        SignIn_TITLE_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "An Error Occurred",
        SignIn_userEmail: "User E-mail",
        SignIn_userPassword: "User Password",
        ssrSelection_lblSsrTitle: "Selected Brand:",
        ssrSelectionBox_lblBAGGAGEDescription: "Extra baggage is added to your existing selection.",
        ssrSelectionBox_lblbaggageTitle: "Extra Baggage",
        ssrSelectionBox_lblCABIN_BAGDescription: "1 Piece Of Cabin Baggage",
        ssrSelectionBox_lblcabin_bagTitle: "Cabin Baggage",
        ssrSelectionBox_lblcheckin_at_airportTitle: "Airport Check-in",
        ssrSelectionBox_lblreissue_allowanceTitle: "Sun Flex 3",
        ssrSelectionBox_lblwheelchairTitle: "Wheelchair",
        targetAchievement_lblConfetti: "Congratulations!",
        targetAchievement_lblInformaton: ".",
        targetAchievement_lblTarget: "Target",
        targetAchievement_lblTitle: "Incentive",
        taskDetails_openingNote: "Opening Note",
        taskDetails_subStatus: "Sub Status",
        taskDetails_taskOpenTime: "Task Open Time",
        taskDetails_type: "Type",
        taskList_filter_btnList: "List",
        taskList_filter_lblFromDate: "Creation From Date",
        taskList_filter_lblStatus: "Status",
        taskList_filter_lblToDate: "Creation To Date",
        taskList_filter_lblType: "Task Type",
        taskList_tblMain_thAgency: "Agency Name",
        taskList_tblMain_thClosingType: "Closing Type",
        taskList_tblMain_thCreatedUser: "User Opened Task",
        taskList_tblMain_thEntity: "Product",
        taskList_tblMain_thEntityType: "Product Type",
        taskList_tblMain_thGsa: "Gsa",
        taskList_tblMain_thId: "Task Id",
        taskList_tblMain_thNote: "Opening Note",
        taskList_tblMain_thOrderId: "Order ID",
        taskList_tblMain_thOrderStatus: "Order Status",
        taskList_tblMain_thStatus: "Task Status",
        taskList_tblMain_thSubStatus: "Sub Status",
        taskList_tblMain_thTaskStatus: "Task Status",
        taskList_tblMain_thType: "Task Type",
        taskList_tblMain_thUpdatedDateTime: "Last Update",
        "TaskList_tblTaskMng.thETaskClosingType": "Closing Type",
        "TaskList_tblTaskMng.thETaskSubStatus": "Sub Status",
        "TaskList_tblTaskMng.thETaskType": "Task Type",
        taskList_title: "Service Center",
        taskListPage_pageTitle: "Service Center",
        taskListPage_searchForm_endDateLabel: "Expiry Date:",
        taskListPage_searchForm_startDateLabel: "Creation From Date",
        taskListPage_searchForm_statusLabel: "Status",
        taskListPage_searchForm_taskTypeLabel: "Task Type",
        taskListPage_taskListDataTable_taskAgencyNameLabel: "Agency Name",
        taskListPage_taskListDataTable_taskClosingTypeLabel: "Closing Type",
        taskListPage_taskListDataTable_taskGsaLabel: "Gsa",
        taskListPage_taskListDataTable_taskIdLabel: "Task Id",
        taskListPage_taskListDataTable_taskLastUpdateTimeLabel: "Last Update",
        taskListPage_taskListDataTable_taskOpenedUserEmailLabel: "User Opened Task",
        taskListPage_taskListDataTable_taskOpeningDescriptionLabel: "Opening Note",
        taskListPage_taskListDataTable_taskOrderIdLabel: "Order ID",
        taskListPage_taskListDataTable_taskPnrLabel: "Pnr",
        taskListPage_taskListDataTable_taskStatusLabel: "Task Status",
        taskListPage_taskListDataTable_taskSubStatusLabel: "Sub Status",
        taskListPage_taskListDataTable_taskTypeLabel: "Task Type",
        taskNotes_placeholder: "You can write your note here.",
        taxInformation_city_tax: "City Tax",
        taxInformation_electricity_fee: "Electricity Fee",
        taxInformation_excludedTaxes: "Taxes Not Included",
        taxInformation_includedTaxes: "Taxes Included",
        taxInformation_service_fee: "Service Fee",
        taxInformation_vat: "Tax",
        tblOrderSearch_thAirlinePnr: "Airline Pnr",
        test_arrayParamAdult: "{0} adult var",
        test_childAge1: "çocuk odası {0} ile {1} arasında ok",
        test_childAge2: "çocuk odası {0} ile {1} arasında ok ve diğer çocuk {0} ile {1} arası ok",
        test_dynamic_text: "mike jenny {value} dffsfsdfsdf",
        test_test: "test",
        tokenDirection_lblAppInstallation: "App Installation",
        tokenDirection_lblAppInstallationDownloadAppStore: "Download On The",
        tokenDirection_lblAppInstallationDownloadPlayStore: "Get It On",
        tokenDirection_lblAppInstallationGuideText: "Download the Google Authenticator application to your smartphone by scanning the QR code below.",
        tokenDirection_lblAppInstallationGuideTextOrVisit: "or visit #VALUE#",
        tokenDirection_lblAppInstallationOpenToken: "Open Token Usage",
        tokenDirection_lblAppInstallationQrBelowInfo: "The page opened by scanning the QR code will automatically redirect you to compatible store for your device. If the redirection doesnt work, try clicking the links manually.",
        tokenDirection_lblAppInstallationValidateToken: "Validate Token",
        tokenDirection_lblExtraSecurityLayer: "Increase Your Account Security",
        tokenDirection_lblExtraSecurityLayerInfo1: "The definitive solution against the theft of your accounts is the use of tokens. You can activate the token by following the next steps.",
        tokenDirection_lblExtraSecurityLayerInfo2: "Activate is by saying,  #VALUE#",
        tokenDirection_lblExtraSecurityLayerLastPageInfo: "Did you encounter an error when opening the second verification step? You may call #VALUE# to contact our Service Center Team.",
        tokenDirection_lblExtraSecurityLayerLastPageInfo2: "or call #VALUE#",
        tokenDirection_lblGeneratingVerificationCode: "Generating Verification Code",
        tokenDirection_lblGeneratingVerificationCodeText1: "Start using the Authenticator app by opening your Google Account.",
        tokenDirection_lblGeneratingVerificationCodeText2: "Press the #VALUE# button located in the buttom right corner of the app to add a code.",
        tokenDirection_lblGeneratingVerificationCodeText3: "Tap on the Scan QR Code area and allow camera access for the application and scan the QR code below.",
        tokenDirection_lblLetsGo: "Lets GO!",
        tokenDirection_lblLoginWithToken: "Login With Token",
        tokenDirection_lblLoginWithTokenText1: "Your 6-digit verification code will be sent to the Authenticator App.",
        tokenDirection_lblLoginWithTokenText2: "Use this verification code for the Token field on the login screen of #VALUE# for each login",
        tokenDirection_lblSkip: "Close",
        tokenDirection_lblSupportService: "Support Service",
        tokenValidate_lblInfoText1: "Enter the 6-digit code that matches your account in your Authenticator app.",
        tokenValidate_lblInfoText2: "If you do not see the verification code in your Authenticator application, go back to the previous step and repeat the process.",
        tokenValidate_lblTitle: "Validate Your Token",
        TransactionList_FLIGHT_ANCILLARY: "Flight Ticket Ancillary",
        TransactionList_PNR_COMMISSION_REVERSE: "Flight Commission Refund",
        transactionListPage_AgencyBalance: "Balance",
        transactionListPage_pageTitle: "Account Activities",
        transactionListPage_searchForm_endDateLabel: "Creation To Date",
        transactionListPage_searchForm_startDateLabel: "Creation From Date",
        transactionListPage_transactionListDataTable_transactionBalanceLabel: "Balance",
        transactionListPage_transactionListDataTable_transactionCreditLabel: "Credit",
        transactionListPage_transactionListDataTable_transactionDateLabel: "Date",
        transactionListPage_transactionListDataTable_transactionDebtLabel: "Debit",
        transactionListPage_transactionListDataTable_transactionEntityLabel: "Product",
        transactionListPage_transactionListDataTable_transactionInfoLabel: "Description",
        transactionListPage_transactionListDataTable_transactionOrderIdLabel: "Order ID",
        transactionListPage_transactionListDataTable_transactionProviderLabel: "Provider Name",
        transactionListPage_transactionListDataTable_transactionTrxTypeLabel: "Transaction Type",
        transactionListPage_transactionListDataTable_transactionUserEmailLabel: "User E-mail",
        transactionPage_transactionDataTable_balanceBeginLabel: "Turnover Balance",
        transactionPage_transactionDataTable_balanceEndLabel: "Current Balance",
        transactionPage_transactionDataTable_balanceTotalLabel: "Total of Transactions",
        transfer_fillTravelerRows: "Fill",
        transfer_fillTravelerRowsCancelBtn: "Cancel",
        transfer_fillTravelers: "Passenger Information",
        transfer_fillTravelersContent: "Would you like to auto-fill passenger information?",
        transfer_lblAdult: "Adult",
        transfer_lblChild: "Child",
        transfer_lblDepartureDate: "Pickup / Return Date",
        transfer_lblExtraFeature: "Extra Features",
        transfer_lblFromName: "Destination A",
        transfer_lblInfant: "Infant",
        transfer_lblManuelRequest: "There is no predefined price information on your requested the route. You may send us your request by clicking the Manual Request button.",
        transfer_lblPickupReturnDate: "Pickup Date / Return Date",
        transfer_lblProvider: "Provider",
        transfer_lblReturnDate: "Return Date",
        transfer_lblToName: "Destination B",
        transfer_lblTravelerCount: "Pax Count",
        transfer_lblTravellers: "Number Of Travellers",
        transfer_manuelRequest: "Manuel Request",
        transfer_manuelRequestCancel: "Cancelled",
        transfer_manuelRequestConfirm: "Confirm Request",
        transfer_manuelRequestModalTitle: "Create Manuel Request",
        transfer_phOrSimilar: "OR SIMILAR",
        transfer_RepresentativeVehicle: "Representative",
        transfer_searchTransfer: "SEARCH TRANSFER",
        transfer_travelerBirthDate: "Birth Date",
        transfer_travelerNameSurname: "Name-Surname",
        transfer_travelerType: "Pax Type",
        transferAutocomplete_lblDistrict: "District",
        transferAutocomplete_lblHotel: "Hotel",
        transferAutocomplete_phFromDestination: "From: Airport / Hotel",
        transferAutocomplete_phToDestination: "To: Airport / Hotel",
        transferBasket_btnClear: "Clear All",
        transferBasket_lblTitle: "Basket",
        transferBasket_lblTotalFare: "Total",
        TransferCard_btnRemoveCar: "Change Car",
        TransferCard_btnSelectCar: "Select Car",
        TransferCard_lblBaggage: "Baggage",
        TransferCard_lblCapacity: "Vehicle Capacity",
        TransferCard_lblCardInformationText: "Vehicle image is representative.",
        TransferCard_lblInformation: "",
        TransferCard_lblMax: "Max Traveller",
        transferCheckout_agencyExtraCCommission: "Agency Adds Extra Commission.",
        transferCheckout_lblAgencyCommission: "Agency Commission",
        transferCheckout_lblAgencyExtraCommission: "Agency Extra Commission",
        TransferCheckout_lblAirCondition: "Air Conditioning",
        TransferCheckout_lblArrival: "Arrival Time",
        TransferCheckout_lblBaggage: "Baggage",
        TransferCheckout_lblDeparture: "Departure Time",
        transferCheckout_lblGrandTotal: "Grand Total",
        TransferCheckout_lblImportantNote1: "Please check your transfer information on this voucher.",
        TransferCheckout_lblImportantNote2: "You can contact your agency for cancellation and change procedures.",
        TransferCheckout_lblImportantNote3: "Airport or hotel meeting confirmation will be sent to you by Whatsapp message the day before your transfer. If you do not receive the confirmation information, you can confirm your hotel pick-up time from our emergency call contact number +90 531 720 21 28 at least 12 hours before your transfer.",
        TransferCheckout_lblImportantNote4: "Your captains waiting time for airport pick-ups is 60 minutes from the plane landing. If your baggage and passport procedures take longer in the terminal, please inform our call contact number.",
        TransferCheckout_lblImportantNote5: "Airport or hotel meeting confirmation will be sent to you by Whatsapp message the day before your transfer. If you do not receive the confirmation information, you can confirm your hotel pick-up time from our emergency call contact number #VALUE# and #VALUE1# at least 12 hours before your transfer.",
        TransferCheckout_lblLastVoidDate: "Free Cancellation Date",
        TransferCheckout_lblLightning: "Lightning",
        TransferCheckout_lblMax: "Max Traveller",
        TransferCheckout_lblPickUp: "Pick-up Time",
        TransferCheckout_lblPickupTime: "Pick-up Date and Time",
        TransferCheckout_lblPnr: "PNR",
        transferCheckout_lblTransferDescriptionsTitle: "Transfer Information",
        transferCheckout_titleGrandTotal: "Grand Total",
        TransferCheckout_txtPickupInfo: "Information about the meeting point at the airport or the pick-up time at the hotel will sent to the passengers mobile phone one day in advance via Whatsapp message.",
        TransferFilters_deselectAll: "Deselect All",
        TransferFilters_lblFilters: "Filters",
        TransferFilters_lblTransferTypes: "Transfer Type",
        TransferFilters_lblVehicles: "Vehicles",
        TransferFilters_selectAll: "Select All",
        TransferInformations_lblAgencyCommissionExtra: "Agency Extra Commission",
        TransferInformations_lblAgencyCommissionRatio: "Agency Commission",
        TransferInformations_lblCar: "Car",
        TransferInformations_lblGrandTotal: "Grand Total",
        TransferInformations_lblTotalPrice: "Total Fee (Net)",
        TransferInformations_transferInformations: "Transfer Information",
        transferOrderDetail_lblAgencyCommission: "Agency Commission",
        transferOrderDetail_lblBaseTotal: "Net Price",
        transferOrderDetail_lblGrandTotal: "Grand Total",
        transferOrderDetail_lblReverseTotal: "Refund Amount",
        transferOrderDetail_lblSalesTotal: "Sales Total",
        transferOrderDetail_lblTransferAgencyNote: "Agency Note",
        transferReissue_popupReiusseSuccessSubtitle: "Your change has been successfully saved.",
        transferSalesReport_currency: "Currency",
        transferSalesReport_filter_btnList: "List",
        transferSalesReport_filter_lblFromDate: "From",
        transferSalesReport_filter_lblReportType: "Report Type",
        transferSalesReport_filter_lblStatus: "Status",
        transferSalesReport_filter_lblToDate: "To",
        transferSalesReport_legCount: "Leg Count",
        transferSalesReport_refundTotal: "Total Refund",
        transferSalesReport_tblMain_thAgencyCommission: "Agency Commission",
        transferSalesReport_tblMain_thAgencyName: "Agency Name",
        transferSalesReport_tblMain_thFrom: "From",
        transferSalesReport_tblMain_thFromDate: "From Date",
        transferSalesReport_tblMain_thOrderId: "Order ID",
        transferSalesReport_tblMain_thProvider: "Provider Name",
        transferSalesReport_tblMain_thSaleDate: "Sale Date",
        transferSalesReport_tblMain_thStatus: "Status",
        transferSalesReport_tblMain_thTnr: "Tnr",
        transferSalesReport_tblMain_thTo: "To",
        transferSalesReport_tblMain_thTotal: "Total Fee",
        transferSalesReport_tblMain_thTransferDate: "Transfer Date",
        transferSalesReport_tblMain_thTransferType: "Transfer Type",
        transferSalesReport_tblMain_thTravelerCount: "Pax Count",
        transferSalesReport_tblMain_thTravelerName: "Passenger Name",
        transferSalesReport_tblMain_thTravelerSurname: "Passenger Surname",
        transferSalesReport_tblMain_thVehicleType: "Vehicle Type",
        transferSalesReport_title: "Transfer Sales Report",
        transferSalesReport_tnrCount: "Order Count",
        transferSalesReport_totalAmount: "Sale Total",
        transferSalesReport_travelerCount: "Passanger Count",
        TransferSearch_lblCarFound: "car(s) found.",
        transferSearch_lblEmptyTransferList: "There is no transfer found matching with your search criteria. Please try again by changing your search criteria.",
        transferSearch_lblFromDestination: "From",
        transferSearch_lblToDestination: "To",
        TransferSearch_lblTransferRoute: "From #VALUE# to #VALUE1#",
        transferSearchInformation_adult: "Adult",
        transferSearchInformation_child: "#COUNT# Child",
        transferSearchInformation_data: "Pax Count",
        transferSearchInformation_date1: "Pick-up Time",
        transferSearchInformation_date2: "Return Date",
        transferSearchInformation_infant: "#COUNT# Infant",
        transferSearchInformation_locationFrom: "Departure",
        transferSearchInformation_locationTo: "To",
        transferSearchInformation_text: "Looking for the best prices, please wait!",
        transferTravelerInformationsComponent_travelerInformations: "Traveller Information",
        userAuths_authChanged: "Authority Changed.",
        UserAuths_authError: "No Auth was Given.",
        UserAuths_BalanceUpdate: "Balance Deposit",
        UserAuths_CustomerSettings: "Customer Transactions",
        UserAuths_FlightCheckout: "Flight Buy",
        UserAuths_FlightSale: "Flight Search",
        UserAuths_FlightSalesReport: "Flight Sales Report",
        UserAuths_HotelCheckout: "Hotel Buy",
        UserAuths_HotelSale: "Hotel Search",
        UserAuths_HotelSalesReport: "Hotel Sales Report",
        UserAuths_InvoiceList: "Invoice List",
        userAuths_lblAccTransactionList: "Account Activities",
        userAuths_lblBalanceUpdate: "Balance Deposit",
        userAuths_lblCustomerSettings: "Customer Transactions",
        userAuths_lblDashboardV2: "Dashboard V2",
        userAuths_lblFlightCheckout: "Flight Buy",
        userAuths_lblFlightSale: "Flight Search",
        userAuths_lblFlightSalesReport: "Flight Sales Report",
        userAuths_lblHotelCheckout: "Hotel Buy",
        userAuths_lblHotelSale: "Hotel Search",
        userAuths_lblHotelSalesReport: "Hotel Sales Report",
        userAuths_lblInvoiceList: "Invoice List",
        userAuths_lblNotification: "Notifications",
        userAuths_lblOrderDetail: "Order View",
        userAuths_lblOrderSearch: "Search Order",
        userAuths_lblPersonalAuth: "Individual User",
        userAuths_lblProfileSettings: "Profile Settings",
        userAuths_lblRentACar: "Rent a Car",
        userAuths_lblRentACarCheckout: "Rent A Car Buy",
        userAuths_lblRentACarSale: "Rent A Car Search",
        userAuths_lblRentACarSalesReport: "Rent A Car Sales Report",
        userAuths_lblSchimetterling: "Package Search",
        userAuths_lblSchmetterlingSalesReport: "Package Sales Report",
        userAuths_lblTaskList: "Service Center",
        userAuths_lblTransactionList: "Account Activities",
        userAuths_lblTransferCheckout: "Transfer Buy",
        userAuths_lblTransferSale: "Transfer Search",
        userAuths_lblTransferSalesReport: "Transfer Sales Report",
        userAuths_lblUserAuths: "User Authorizations (Admin)",
        userAuths_lblUserSettings: "User Settings",
        UserAuths_Notification: "Notifications",
        UserAuths_OrderDetail: "Order View",
        UserAuths_OrderSearch: "Search Order",
        UserAuths_ProfileSettings: "Profile Settings",
        UserAuths_TaskList: "Service Center",
        userAuths_thAuth: "Authorization",
        userAuths_thId: "ID",
        userAuths_titleUserAuths: "User Authorizations",
        UserAuths_TransactionList: "Account Activities",
        UserAuths_TransferCheckout: "Transfer Buy",
        UserAuths_TransferSale: "Transfer Search",
        userAuths_userActive: "Active",
        UserAuths_UserAuths: "User Authorizations",
        userAuths_userPassive: "Passive",
        UserAuths_UserSettings: "User Settings",
        userControlBox_lnkDashboard: "Dashboard",
        userControlBox_lnkLogout: "Logout",
        userControlBox_lnkSettings: "Settings",
        userSettings_lblUserEmail: "E-mail",
        userSettings_lblUserSettings: "User Settings",
        userSettings_mailSet: "Your mail settings have changed!",
        widgetHeaderComponent_addNew: "Add New",
        widgetHeaderComponent_backtoFirst: "Back to First Version",
        widgetHeaderComponent_highlighter: "Highlighter",
        widgetHeaderComponent_removeWidget: "Remove Widget",
        widgetHeaderComponent_resetToDefault: "Back to First Version",
        widgetHeaderComponent_toggleExtras: "Toggle Extras",
        widgetHeaderComponent_turnOnArrangement: "Open Edit"
    },
    de: {
        "25 gtsGrid_item": "25",
        accountTransactionList_filter_btnList: "Auflisten",
        accountTransactionList_filter_lblFromDate: "Startdatum",
        accountTransactionList_filter_lblToDate: "Enddatum",
        accountTransactionList_tblMain_thBalance: "Saldo",
        accountTransactionList_tblMain_thBalanceBegin: "Saldo",
        accountTransactionList_tblMain_thBalanceEnd: "Aktueller Saldo",
        accountTransactionList_tblMain_thCredit: "Haben",
        accountTransactionList_tblMain_thCurrency: "Währung",
        accountTransactionList_tblMain_thDate: "Datum",
        accountTransactionList_tblMain_thDebt: "Soll",
        accountTransactionList_tblMain_thEntity: "Produkt",
        accountTransactionList_tblMain_thInfo: "Beschreibung:",
        accountTransactionList_tblMain_thOrderId: "Order ID",
        accountTransactionList_tblMain_thProvider: "Anbietername",
        accountTransactionList_tblMain_thTrxSum: "Summe der Transaktionen",
        accountTransactionList_tblMain_thTrxType: "Art der Transaktion",
        accountTransactionList_tblMain_thUserEmail: "Benutzer-E-mail",
        accountTransactionList_tblMain_title: "Kontenverlauf",
        accountTransactionList_tblMain_trProvider: "Hotel Provider",
        accountTransactionList_tblSumm_thBalance: "Saldo",
        accountTransactionList_tblSumm_thCredit: "Haben",
        accountTransactionList_tblSumm_thDebt: "Soll",
        accountTransactionList_tblSumm_thTrxType: "Art der Transaktion",
        accountTransactionList_tblSumm_title: "Zusammenfassung des Kontenverlaufs",
        accountTransactionList_title: "Kontenverlauf",
        addTask_success: "Erfolgreich",
        addWidget_btnSave: "SPEICHERN",
        addWidget_lblAddWidget: "Widgets Hinzufügen",
        addWidget_lblFlightForthComingTitle: "Weiter Kommende Flüge",
        addWidget_lblFlightGirosTitle: "Flug - Umsatz",
        addWidget_lblFlightPerformanceTitle: "Flugleistung",
        addWidget_lblFlightReservationsTitle: "Bevorstehende Reservierungen - Flug",
        addWidget_lblHotelCheckInTitle: "Bevorstehende Hotel-Check-Ins",
        addWidget_lblHotelPerformanceTitle: "Hotel Performance",
        addWidget_lblHotelReservationsTitle: "Anstehende Reservierungen - Hotel",
        addWidget_lblRentACarWidgetTitle: "Anstehende Reservierungen - Mietwagen",
        addWidget_lblTranferWidgetTitle: "Anstehende Transfers",
        agencyAccountComponent_availableBalance: "Verfügbares Guthaben",
        agencyAccountComponent_balance: "Saldo",
        agencyAccountComponent_balanceUpdate: "Guthaben Laden",
        agencyAccountComponent_definedCredit: "Kreditrahmen",
        AgencyExtraCommission_checkOutInformation: "Zur Gesamtgebühr Hinzugefügt.",
        AgencyExtraCommission_title: "Extra-Kommission",
        AGENT_API_FLIGHT_CHECKOUT_EXPIRED_CHECKOUT: "Die Preise wurden möglicherweise aktualisiert, weil Sie lange nicht gehandelt haben.  Sie können Ihren Vorgang fortsetzen, indem Sie Ihre Suche erneuern.",
        airlineEnum_6Y: "SmartLynx Airlines",
        airlineEnum_QS: "Smartwings Airline",
        alert_emptyAmount: "Bitte Betrag eingeben.",
        alert_emptyHotelList: "Es wurde kein Hotel gefunden, dass Ihren Suchkriterien entspricht. Bitte versuchen Sie es erneut, indem Sie Ihre Suchkriterien ändern.",
        alert_warningPopupTitle: "Ein Fehler ist aufgetreten.",
        ancillariesServices_baggage: "Zusätzliches Gepäck",
        ancillariesServices_checkIn: "Online Check-In",
        ancillariesServices_descriptionsunflex3: "SunFlex 3, Umbuchung ohne Gebühr bis zu 3 Tage vor Abflug (+ Preisdifferenz)",
        ancillariesServices_divide: "Passagier Trennen",
        ancillariesServices_food: "Verpflegungsservice",
        ancillariesServices_lblMealsFooterInfo: "Für alle Fragen und Anregungen steht Ihnen unser Service-Center-Team zur Verfügung.",
        ancillariesServices_lblWheelChairFooterInfo: "Um den Rollstuhlservice zu ändern, wenden Sie sich bitte an unser Service-Center-Team. Änderungen können auf dieser Seite nicht vorgenommen werden.",
        ancillariesServices_meal: "Auswahl an Speisen",
        ancillariesServices_meals: "Mahlzeit",
        ancillariesServices_onlineCheckin: "Online Check-in",
        ancillariesServices_reissue: "Umbuchung",
        ancillariesServices_relatedPNRInfos: "Die zusätzlichen Dienste sind PNR- und Passagier bezogen. Die oben genannten zusätzlichen Dienste werden für PNR {PNR} erstellt.",
        ancillariesServices_relatedPPaymentMethods: "Sie können die erhaltenen Zusatzleistungen mit den Zahlungsarten Mco, Kreditkarte, Sofort und Ideal bezahlen.",
        ancillariesServices_seat: "Sitz Auswählen",
        ancillariesServices_selectfood: "Auswahl an Speisen",
        ancillariesServices_selectssr: "Sun Flex 3 Auswählen",
        ancillariesServices_selectwheelchair: "Rollstuhl Auswahl",
        ancillariesServices_SunFlex3: "Sun Flex 3",
        ancillariesServices_title: "Zusatzleistungen",
        ancillariesServices_wheelChair: "Rollstuhl",
        ancillariesServicesModal_baggageContentSuccess: "Ihr Gepäckkauf wurde erfolgreich abgeschlossen.",
        ancillariesServicesModal_baggageFailedTitle: "Fehler",
        ancillariesServicesModal_baggageSuccessTitle: "Erfolgreich",
        ancillariesServicesModal_baggeFailedMessage: "Beim Kauf von Gepäck ist eine Fehlermeldung aufgetreten. Bitte kontaktieren Sie das Service Center und versuchen Sie es erneut.",
        ancillariesServicesModal_btnClose: "SCHLIESSEN",
        ancillariesServicesModal_btnConfirm: "BESTÄTIGEN",
        ancillariesServicesModal_btnTryAgain: "Erneut suchen",
        ancillariesServicesModal_BuyWithCurrentPaymentMethod: "Aktueller Kauf",
        ancillariesServicesModal_contentFailed: "Sie haben einen Fehler beim Kauf von Zusatzgepäck erhalten. Bitte kontaktieren Sie unser Service Center.",
        ancillariesServicesModal_contentSuccess: "Wir haben Ihr zusätzliches Gepäck zu Ihrer Bestellnummer <strong>{ORDER}</strong> verarbeitet.",
        ancillariesServicesModal_divideAfterDividePNR: "Neue PNR",
        ancillariesServicesModal_divideCurrentPNR: "Haupt PNR",
        ancillariesServicesModal_divideFailedMessage: "Sie haben während des PNR Teilungsvorgangs einen Fehler erhalten. Bitte wenden Sie sich an das Service Center.",
        ancillariesServicesModal_divideFailedTitle: "Fehlermeldung",
        ancillariesServicesModal_divideSuccessMessage: "Ihr PNR Teilungsvorgang war erfolgreich. Ihre neue Pnr {PNR}-Nummer wurde erstellt.",
        ancillariesServicesModal_divideSuccessTitle: "Erfolg",
        ancillariesServicesModal_infos: "Beim Erhalt zusätzlicher Dienste wird eine Bankprovision für Zahlungen per Kreditkarte und Sofort hinzugefügt.",
        ancillariesServicesModal_infoTextReissueStep2: "Bitte wählen Sie den Flug aus, den Sie umbuchen möchten.",
        ancillariesServicesModal_infoTxtPlsSelectFlights: "Bitte wählen Sie über die Schaltfläche {ICON} die Flüge aus, die Sie umbuchen möchten.",
        ancillariesServicesModal_lblAgencyExtraComm: "Agentur-Extraprovision",
        ancillariesServicesModal_lblArrivalAirport: "Ankunft",
        ancillariesServicesModal_lblArrivalAirportPlaceholder: "Ankunftsflughafen",
        ancillariesServicesModal_lblArrivalDate: "Ankunftsdatum",
        ancillariesServicesModal_lblCancelSelection: "Auswahl abbrechen",
        ancillariesServicesModal_lblDepartureAirport: "Abflug",
        ancillariesServicesModal_lblDepartureAirportPlaceholder: "Von",
        ancillariesServicesModal_lblDepartureDate: "Hinflugdatum",
        ancillariesServicesModal_lblExtraComm: "Extra-Kommission",
        ancillariesServicesModal_lblFlight: "Flug",
        ancillariesServicesModal_lblGrandTotal: "Gesamtsumme",
        ancillariesServicesModal_lblPayment: "Zahlungsschritt",
        ancillariesServicesModal_lblReserve: "RESERVIEREN",
        ancillariesServicesModal_lblSearch: "Suche",
        ancillariesServicesModal_lblSearchResultCount: "Das Ergebnis wird angezeigt.",
        ancillariesServicesModal_lblSSR: "Sun Flex 3",
        ancillariesServicesModal_lblSSRDesc: "SunFlex 3, Umbuchung ohne Gebühr bis zu 3 Tage vor Abflug (+ Preisdifferenz)",
        ancillariesServicesModal_lblTotalSalesAmount: "Gesamt",
        ancillariesServicesModal_mealsContentSuccess: "Ihre Mahlzeit Buchung ist erfolgreich.",
        ancillariesServicesModal_mealsFailedMessage: "Bei der Mahlzeit Buchung  ist eine Fehlermeldung aufgetreten. Bitte kontaktieren Sie das Service Center und versuchen Sie es erneut.",
        ancillariesServicesModal_mealsFailedTitle: "Fehler",
        ancillariesServicesModal_mealsSuccessTitle: "Erfolgreich",
        ancillariesServicesModal_NO_BRAND_FOUND: "Die Tarife Ihrer Buchung konnte bei der Fluggesellschaft nicht gefunden werden. Bitte kontaktieren Sie das Service Center.",
        ancillariesServicesModal_NO_FLIGHT_FOUND: "Es wurden keine Flüge gefunden, die Ihren Suchkriterien entsprechen.",
        ancillariesServicesModal_otherPaymentMethods: "Andere Methoden",
        ancillariesServicesModal_paymentStep: "Zahlungsseite",
        ancillariesServicesModal_reissueErrorMessage: "Bei der Abfrage der Umbuchung ist ein unbekannter Fehler aufgetreten. Bitte kontaktieren Sie unser Service Center Team, um die Ursache des Fehlers zu klären.",
        ancillariesServicesModal_reissueErrorNoSearchResultText: "Es gab keine Bestätigung seitens der Airline. Bitte wenden Sie sich an unser Service Center Team.",
        ancillariesServicesModal_reissueErrorNoSearchResultTitle: "Ein Fehler ist  Aufgetreten",
        ancillariesServicesModal_reissueErrorText: "Fehlermeldung",
        ancillariesServicesModal_reissueErrorTitle: "Sie haben während der Umbuchungsprozess einen Fehler erhalten. Bitte kontaktieren Sie das Service Center Team.",
        ancillariesServicesModal_reissueSuccessText: "Ihre Umbuchung wurde erfolgreich durchgeführt. Sie können Ihr neues Ticket überprüfen.",
        ancillariesServicesModal_reissueSuccessTitle: "Erfolgreich",
        ancillariesServicesModal_resetDivide: "Zurücksetzen",
        ancillariesServicesModal_stepDivide: "PNR Splitten",
        ancillariesServicesModal_stepFlightSearchResult: "Suchergebnis",
        ancillariesServicesModal_stepFlightSelection: "Umbuchung Auswahl",
        ancillariesServicesModal_stepMealSelection: "Auswahl an Speisen",
        ancillariesServicesModal_stepPayment: "Zahlungsseite",
        ancillariesServicesModal_stepReissuePayment: "Zahlungsseite",
        ancillariesServicesModal_stepReissueResult: "Ergebnis",
        ancillariesServicesModal_stepResult: "Ergebnis",
        ancillariesServicesModal_stepSeatSelection: "Sitzplatzauswahl",
        ancillariesServicesModal_stepSelectBaggage: "Auswahl des Gepäcks",
        ancillariesServicesModal_stepSssr: "Sun Flex Auswahl",
        ancillariesServicesModal_stepSunFlex3: "Sun Flex",
        ancillariesServicesModal_stepWheelChairSelection: "Rollstuhl Auswahl",
        ancillariesServicesModal_sunFlexErrorMessage: "Beim Kauf von Sun Flex ist ein Fehler aufgetreten. Bitte kontaktieren Sie das Service Center und versuchen Sie es erneut.",
        ancillariesServicesModal_sunFlexSuccessMessage: "Ihre SunFlex 3 Reservierung wurde erfolgreich durchgeführt.",
        ancillariesServicesModal_titleFailed: "Fehlermeldung",
        ancillariesServicesModal_titleSuccess: "Erfolgreich",
        ancillariesServicesModal_wheelchairErrorMessage: "Sie haben beim Kauf eines Rollstuhls eine Fehlermeldung erhalten. Bitte kontaktieren Sie das Service Center und versuchen Sie es erneut.",
        ancillariesServicesModal_wheelchairErrorTitle: "Fehlermeldung",
        ancillariesServicesModal_wheelchairSuccessMessage: "Ihr Rollstuhl Buchungsprozesse wurde erfolgreich durchgeführt.",
        ancillariesServicesModal_wheelchairSuccessTitle: "Erfolgreich",
        ancillarReissue_btnCalculateReissue: "Umbuchung Berechnung",
        ancillarReissue_lblCalculateDescription: "Sie können den Umbuchungsbetrag berechnen.",
        ancillarReissue_lblCalculateTitle: "Berechnung der Umbuchung",
        ancillarReissue_lblCalculatingFailedDescription: "Ein Fehler ist während Ihrer Transaktion aufgetreten. Sie können Ihren Vorgang fortsetzen, indem sie erneut suchen.",
        ancillarReissue_lblCalculatingFailedDescriptionFLIGHT_SAME_DAY: "Bitte überprüfen Sie die von Ihnen ausgewählten Flüge.",
        ancillarReissue_lblCalculatingFailedTitle: "Fehlermeldung",
        ancillarReissue_lblFlightResultTitleAirline: "Fluggesellschaften",
        ancillarReissue_lblFlightResultTitleArrival: "Ankunft",
        ancillarReissue_lblFlightResultTitleDeparture: "Abflug",
        ancillarReissue_lblFlightResultTitleFlightDate: "Flugdatum",
        ancillarReissue_lblFlightResultTitleFlightNu: "Flugnummer",
        ancillarReissue_lblFlightResultTitleFrom: "Abflug",
        ancillarReissue_lblFlightResultTitleStop: "Verbindung",
        ancillarReissue_lblFlightResultTitleTo: "Ankunft",
        ancillarReissue_lblPreviousStep: "Vorheriger Schritt",
        ancillaryReissueSelection_lblPnrHasAncillaries: "Die SSRs, die Sie für Ihre alte Buchung gekauft haben, werden nach der Neuausstellung nicht auf Ihre neue Buchung übertragen.",
        ancillarySeatMap_btnCancel: "Storniert",
        ancillarySeatMap_btnNextStep: "Nächste",
        ancillarySeatMap_btnPayment: "Zahlung",
        ancillarySeatMap_btnPreviousStep: "Vorheriger Schritt",
        ancillarySeatMap_lblAvailable: "Auswählbar",
        ancillarySeatMap_lblCancel: "Stornieren",
        ancillarySeatMap_lblErrorBtn: "Fehler",
        ancillarySeatMap_lblExtraLegRoom: "XL-Sitz",
        ancillarySeatMap_lblGrandTotal: "Gesamtsumme",
        ancillarySeatMap_lblNotAvailable: "Nicht auswählbar",
        ancillarySeatMap_lblPassenger: "Passagier",
        ancillarySeatMap_lblPaxType: "Pax-Typ",
        ancillarySeatMap_lblPayment: "Zahlung",
        ancillarySeatMap_lblPrice: "Preis",
        ancillarySeatMap_lblResult: "Ergebnis",
        ancillarySeatMap_lblResultEXCEPTION: "Es ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich bezüglich der Fehlerursache an unser Service-Center-Team.",
        ancillarySeatMap_lblResultEXPIRED: "Die Preise wurden möglicherweise aktualisiert, weil Sie längere Zeit nicht gehandelt haben. Sie können Ihren Vorgang fortsetzen, indem Sie Ihre Suche erneuern.",
        ancillarySeatMap_lblResultFAILURE: "Es ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service-Center-Team.",
        ancillarySeatMap_lblResultREJECTED: "Bei der Reservierung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich bezüglich der Fehlerursache an unser Service-Center-Team.",
        ancillarySeatMap_lblResultSUCCESS: "Ihre Sitzplatzreservierung war erfolgreich.",
        ancillarySeatMap_lblResultTIMEOUT: "Bei Ihrer Transaktion ist eine Zeitüberschreitung aufgetreten. Bitte versuche es erneut.",
        ancillarySeatMap_lblSeat: "Sitzplatz",
        ancillarySeatMap_lblSelected: "Ausgewählte",
        ancillarySeatMap_lblSuccessBtn: "Erfolgreich",
        announcement_homePage: "Startseite",
        announcement_showOriginalImage: "Original Zeigen",
        announcement_updateTime: "Letzte Aktualisierungszeit",
        announcement_urlCopied: "Kopiert",
        AnnouncementManagementList_thThumbnailImage: "Voransicht",
        aside_accTransactionList: "Kontenverlauf",
        aside_balanceUpdate: "Guthaben aufladen",
        aside_dashBoard: "Dashboard Startseite",
        aside_flightSalesReport: "Flugticket-Verkaufsbericht",
        aside_flightSearch: "Flugsuche",
        aside_hotelSalesReport: "Hotelverkaufsbericht",
        aside_hotelSearch: "Hotelsuche",
        aside_invoiceList: "Rechnungsliste",
        aside_notifications: "Benachrichtigungen",
        aside_orderSearch: "Order Suche",
        aside_packageSearch: "Paketsuche",
        aside_rentACarSalesReport: "Mietwagen-Verkaufsbericht",
        aside_rentACarSearch: "Mietwagensuche",
        aside_schmetterlingSalesReport: "Pauschalreisen-Verkaufsbericht",
        aside_serviceCenter: "Service Center",
        aside_targetAchievements: "Ziel der Incentive",
        aside_transferSalesReport: "Transferverkaufsbericht",
        aside_transferSearch: "Transfersuche",
        BALANCE_UPDATE_REJECTED: "Ein Fehler ist aufgetreten",
        BALANCE_UPDATE_REJECTED_INSUFFICIENT_PAYMENT: "Sie haben während der Zahlung einen Fehler erhalten.",
        BALANCE_UPDATE_SUCCESS: "Erfolgreich",
        BALANCE_UPDATE_SUCCESS_BALANCE_UPDATED: "Ihr Guthaben wurde erfolgreich geladen.",
        BALANCE_UPDATE_VALIDATION: "Ein Fehler ist aufgetreten",
        BALANCE_UPDATE_VALIDATION_AMOUNT: "Bitte Betrag eingeben.",
        balanceUpdate_btnDeposit: "GUTHABEN AUFLADEN",
        balanceUpdate_lblAmount: "Einzahlung",
        balanceUpdate_lblAvailableBalance: "Noch verfügbares Guthaben",
        balanceUpdate_lblBalance: "Saldo",
        balanceUpdate_lblCommission: "Kommission",
        balanceUpdate_lblInfo: "Beschreibung:",
        balanceUpdate_lblLoan: "Kreditrahmen",
        balanceUpdate_lblNote: "Beschreibung",
        balanceUpdate_lblTotal: "Gesamt",
        BalanceUpdate_popup_REJECTED: "Ein Fehler ist  aufgetreten",
        BalanceUpdate_popup_REJECTED_INSUFFICIENT_PAYMENT: "Sie haben während Ihrer Transaktion einen Fehler erhalten. Ihre Zahlung konnte nicht eingezogen werden.",
        BalanceUpdate_popup_SUCCESS: "Erfolgreich",
        BalanceUpdate_popup_SUCCESS_BALANCE_UPDATED: "Ihr Guthaben wurde erfolgreich geladen.",
        BalanceUpdate_popup_Validation: "Ein Fehler ist aufgetreten.",
        BalanceUpdate_popup_Validation_AMOUNT: "Bitte Betrag eingeben.",
        BalanceUpdate_popup_Validation_ERROR: "Error",
        BalanceUpdate_progressGifStep1: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        BalanceUpdate_progressGifStep2: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        balanceUpdate_title: "Guthaben Aufladen",
        balanceUpdateComponent_balanceUpdateForm_commissionLabel: "Kommission",
        balanceUpdateComponent_balanceUpdateForm_descriptionLabel: "Beschreibung:",
        balanceUpdateComponent_balanceUpdateForm_loadedBalanceLabel: "Einzahlung",
        balanceUpdateComponent_balanceUpdateForm_totalLabel: "Gesamt",
        balanceUpdateComponent_paymentChoicesTab_creditCardAlertText: "Den Kreditkartenzahlungen wird eine feste Bankprovision von 2% hinzugefügt.",
        balanceUpdateComponent_paymentChoicesTab_sofortAlertText: "Im Falle einer Sofortüberweisung; bei Summen zwischen 1,- € und 500,- € werden pauschal 3,- € und bei Summen über 501,- € pauschal 5,- € zum Gesamtbetrag hinzugerechnet.",
        balanceUpdatePage_buttonText: "GUTHABEN AUFLADEN",
        balanceUpdatePage_pageTitle: "Guthaben Aufladen",
        balanceUpdateSummaryComponent_availableBalanceLabel: "Noch verfügbares Guthaben",
        balanceUpdateSummaryComponent_balanceLabel: "Balance",
        balanceUpdateSummaryComponent_loanLabel: "Kreditrahmen",
        basketComponentFlight_baggageInformationChanges: "Die Daten Ihres Gepäcks haben sich geändert. Bitte überprüfen Sie diese.",
        basketComponentFlight_basket: "Warenkorb",
        basketComponentFlight_btnBaggageInformationConfirmation: "APPROVE",
        basketComponentFlight_closeBasket: "SCHLIEßEN",
        basketComponentFlight_goToCheckout: "FORTSETZEN",
        basketComponentFlight_newItemAddedorUpdated: "Produkt zum Warenkorb hinzugefügt oder Warenkorb aktualisiert.",
        basketComponentFlight_package: "Paket",
        basketComponentFlight_sendOffer: "Angebot senden",
        basketComponentFlight_single: "Single",
        basketComponentFlight_singleOfferIsCheaper: "Einzelstreckenbuchung ist günstiger!",
        basketComponentFlight_total: "Gesamt",
        basketComponentHotel_sendOffer: "Angebot senden",
        cancellationPoliciesComponent_cancellationDateLabel: "kostenlos Stonieren bis",
        cancellationPolicy_lblFreeCancellationDate: "Kostenlos stornieren bis zum",
        cancellationPolicy_lblNoCancellationPolicy: "Stornierungsbedingungen",
        cancellationPolicy_lblNoLastBookingDateDescription: "Kein Geld zurück! Im Falle einer Stornierung dieser Buchung gibt es keine Rückerstattung.",
        cancellationPolicy_lblPackageSchmetterlingCancellationPolicy: "Um die Stornobedingungen und alle Regeln bezüglich des Fluges oder der Unterkunft zu sehen, bitte schauen Sie im Verkaufsbericht-Bereich des Smart Booking-Systems in die Details der entsprechenden Reservierung.",
        changeDetails_lblarrivalAirportCode: "Ankunft",
        changeDetails_lblarrivalDateTime: "Ankunftszeit",
        changeDetails_lblbrandCode: "Brand Code",
        changeDetails_lblbrandName: "Brand Name",
        changeDetails_lblcabinType: "Kabinentyp",
        changeDetails_lbldepartureAirportCode: "Abflug",
        changeDetails_lbldepartureDateTime: "Abflugszeit",
        changeDetails_lblflightClass: "Klasse",
        changeDetails_lblflightIndex: "Flight Index",
        changeDetails_lblflightNumber: "Flugnummer",
        changeDetails_lblflightRoute: "Flugroute",
        changeDetails_lblmarketingAirlineCode: "Marketing Airline",
        changeDetails_lbloperatingAirlineCode: "Operating Airline",
        changeDetails_lblsegmentIndex: "Segment Index",
        changeDetails_lblsegmentRoute: "Segment Route",
        changeHistory_lblHistory: "Alte Fluginformation",
        changeHistory_lblLatestEntry: "Neue Fluginformation",
        changeHistoryTable_lblTaskOpeningTime: "Zeit der Anfrage",
        changeHistoryTable_lblType: "Typ",
        changeHistoryTable_thArrival: "Ankunftszeit",
        changeHistoryTable_thBrandCode: "Brand Code",
        changeHistoryTable_thBrandName: "Brand Name",
        changeHistoryTable_thCabinType: "Kabinentyp",
        changeHistoryTable_thClass: "Klasse",
        changeHistoryTable_thDeparture: "Abflugszeit",
        changeHistoryTable_thFlightNo: "Flugnummer",
        changeHistoryTable_thFrom: "Abflug",
        changeHistoryTable_thMarketingOperating: "Marketing/ Operating",
        changeHistoryTable_thRoute: "Flug",
        changeHistoryTable_thTo: "Ankunft",
        changeItemDetails_lblBody: "Achtung! Ihre Flugdaten haben sich geändert. Für die unten angegebene Reservierung gab es eine Änderung des Flugplans bzw. der Uhrzeit. Bitte setzen Sie sich mit dem Passagier in Verbindung und geben Sie eine schriftliche Rückmeldung bezüglich der Änderung.",
        changeItemDetails_lblClosing: "Mit freundlichen Grüßen",
        changeItemDetails_lblGreeting: "Liebe Vertriebspartner,",
        chatBubbleComponent_sent: "Gesendet",
        chatTabComponent_placeholder: "Eine nachricht schreiben...",
        checkout_coPayCancelBtn: "RESERVIERUNG STORNIEREN",
        checkout_lblMetaPolicy: "Gebühren und sonstige Informationen",
        checkout_lblTaxInformation: "Steuerinformationen",
        checkoutBasket_basket_addExtraCommission: "Zusätzliche Provision Hinzufügen",
        checkoutBasket_basket_infos: "Informationen",
        checkoutBasket_basket_pnrInfos: "Als Ergebnis dieses Prozesses wird (werden) #VALUE# PNR`(s) erstellt",
        checkoutBasket_titleBasket: "Korb",
        checkoutContact_bntFillWithAgentInfo: "Verwenden Sie die Benutzerinformationen des Agenten",
        checkoutContact_bntFillWithFirstPaxInfo: "Verwenden Sie die Informationen des ersten Passagiers",
        checkoutContact_contactInformations: "Kontaktinformationen:",
        checkoutContact_informationText: "Wenn der Kauf abgeschlossen ist, werden Ihre Transaktionsinformationen an Ihre E-Mail-Adresse und/oder Ihr Mobiltelefon gesendet.",
        checkoutContact_lblFilledWithAgentInfos: "Die Ticketerstellung erfolgt anhand der Benutzerinformationen der Agentur.",
        checkoutContact_lblFilledWithFirstPaxInfos: "Die Ticketausstellung erfolgt mit der ersten Fahrgastinformation.",
        checkoutContact_vldMin4: "Sie können maximal 4 Ziffern für den Stadtcode eingeben.",
        checkoutFlightPayment_alertNoReservationAndNoBookingBtns: "Die Fluggesellschaft gibt keinen Preis für die von Ihnen gewählte Marke an. Sie können es erneut versuchen, indem Sie Ihre Auswahl ändern.",
        checkoutFlightPayment_CHECKOUT_FAILED: "Ein Fehler ist während Ihrer Operation aufgetreten. Sie können Ihren Vorgang fortsetzen, indem sie erneut suchen.",
        checkoutFlightPayment_INSUFFICIENT_AGENCY_BALANCE: "Ihr verfügbares Guthaben reicht nicht aus.  Bitte kontaktieren Sie unser Team.",
        checkoutFlightPayment_lblBooking: "BUCHEN",
        checkoutFlightPayment_lblReservationCancel: "RESERVIERUNG STORNIEREN",
        checkoutFlightPayment_lblReservationRedirect: "ZUR RESERVIERUNG",
        checkoutFlightPayment_lblReserve: "RESERVIEREN",
        checkoutFlightPayment_NOT_SALES_OFFICE: "Es ist kein Verkaufsbüro ausgewählt. Bitte überprüfen Sie Ihren Benutzer oder Agenten.",
        checkoutFlightPayment_SELECTED_BRANDS_NOT_VALID_NOW: "Die Fluggesellschaft gibt keinen Preis für die von Ihnen gewählte Marke an. Sie können es erneut versuchen, indem Sie Ihre Auswahl ändern.",
        checkoutNotes_lblInformations: "Informationen",
        common_add: "HINZUFÜGEN",
        common_address: "Anschrift",
        common_adult: "Erwachsene",
        common_adultPluralExt: "(r)",
        common_agencyCommission: "Agenturkommission",
        common_agencyExtraCommission: "Agentur-Extraprovision",
        common_agencyId: "Agentur-ID",
        common_agencyName: "Agenturname",
        common_agencyNote: "Agentur Hinweis:",
        common_airlines: "Fluggesellschaften",
        common_all: "Alle anzeigen",
        common_allow: "Erlauben",
        common_and: "und",
        common_apr: "Apr",
        common_april: "April",
        common_arrivalFlightNumber: "Flugnummer",
        common_arrivaltime_decrease: "Ankunftszeit absteigend",
        common_arrivaltime_increase: "Ankunftszeit aufsteigend",
        common_aug: "Aug",
        common_august: "August",
        common_basket: "Warenkorb",
        common_birthday: "Geburtsdatum",
        common_booking: "BUCHEN",
        common_cancel: "Storniert",
        common_cancellationTime: "Stornierungsdatum",
        common_cannotBeEmpty: "Bitte ausfüllen.",
        common_capacity_decrease: "Kapazitätsabnahme",
        common_capacity_increase: "Kapazitätserhöhung",
        common_checkIn: "Anreise",
        common_checkOut: "Abreise",
        common_child: "Kinder",
        common_childPluralExt: "(er)",
        common_chooseChildAge: "Alter",
        common_city: "Vorwahl",
        common_clear: "Löschen",
        common_clearAll: "Löschen",
        common_close: "Beenden",
        common_code: "Code",
        common_confirmationTitle: "Genehmigungsphase",
        common_countryCode: "Vorwahl",
        common_countryPhoneCode: "Vorwahl",
        common_countryPhoneCodeShort: "Vorwahl",
        common_currency: "Währung",
        common_day: "Tag",
        common_dec: "Dez",
        common_december: "Dezember",
        common_decline: "Ablehnen",
        common_departure_decrease: "Abflug absteigend",
        common_departure_increase: "Abflug aufsteigend",
        common_departureFlightNumber: "Flugnummer",
        common_departureTime: "Abflugzeit",
        common_description: "Beschreibung:",
        common_deselectAll: "Abwählen",
        common_disabled: "Behindert",
        common_disabledCompanion: "Begleitperson",
        common_discount: "Rabatt",
        common_district: "Kreis",
        common_email: "E-mail",
        common_ethnic: "Ethnisch (VFR)",
        common_eticket: "E-Ticket",
        common_etickets: "E-Ticket",
        common_eticketStatus: "E-Ticket-Status",
        common_extraSeat: "Extra Seat",
        common_feb: "Feb",
        common_february: "Februar",
        common_female: "Frau",
        common_flight: "Flug",
        common_flightNumber: "Flugnummer",
        common_flightNumberOptional: "Flugnummer",
        common_fri: "Fre",
        common_friday: "Freitag",
        common_fullName: "Vor- und Nachname",
        common_gender: "Geschlecht",
        common_generalGrandTotal: "Gesamtbetrag",
        common_generalTotalPrice: "Gesamtgebühr",
        common_gotodashboard: "Go To Dashboard",
        common_grandTotal: "Gesamtbetrag",
        common_gsa: "Gsa",
        common_gsaName: "Gsa Name",
        common_guest: "Gast",
        common_hide: "Ausblenden",
        common_hotel: "Hotel",
        common_hotelName: "Hotelname",
        common_hour: "Stunden",
        common_id: "ID",
        common_infant: "Babys",
        common_info: "Info",
        common_information: "Information",
        common_insertedDate: "Erstellungszeitpunkt",
        common_insertedUser: "Benutzer",
        common_itemsSelected: "Artikel ausgewählt",
        common_jan: "Jan",
        common_january: "Januar",
        common_jul: "Jul",
        common_july: "Juli",
        common_jun: "Jun",
        common_june: "Juni",
        common_languagePreference: "Spracheinstellung",
        common_lblGrandTotal: "Gesamtsumme",
        common_lblMediaOrientationMsg: "Bitte bringen Sie Ihr Gerät in eine aufrechte Position und fahren Sie mit dem Vorgang fort.",
        common_list: "Auflisten",
        common_male: "Herr",
        common_mar: "Mär",
        common_march: "März",
        common_marketingAirlineCodes: "Fluggesellschaften",
        common_may: "Mai",
        common_minute: "Minuten",
        common_mon: "Mon",
        common_monday: "Montag",
        common_my: "Mai",
        common_name: "Vorname",
        common_nameSurname: "Vor- und Nachname",
        common_nationalIdentity: "Nationale Identität",
        common_nationality: "Staatsangehörigkeit",
        common_nodata: "Keine Daten in der Liste vorhanden.",
        common_nothingSelected: "Nichts ausgewählt",
        common_nov: "Nov",
        common_november: "November",
        common_oct: "Okt",
        common_october: "Oktober",
        common_officialName: "Offizieller Name",
        common_ok: "OK",
        common_okbtn: "OK",
        common_open: "Offen",
        common_optionExpiryTime: "Ablaufdatum der Option",
        common_order: "Bestellung",
        common_orderId: "Order ID",
        common_passenger: "Passagier",
        common_paxCount: "Personenanzahl",
        common_person: "Erwachsene",
        common_phone: "Nummer",
        common_photoGallery: "Fotos",
        common_pnrs: "Pnr",
        common_pnrStatus: "Pnr-Status",
        common_pnrStatuses: "Pnr-Status",
        common_price: "Preis",
        common_price_decrease: "Preis absteigend",
        common_price_fpdecrease: "Paketpreis, Absteigend",
        common_price_fpincrease: "Paketpreis, Aufsteigend",
        common_price_increase: "Preis aufsteigend",
        common_pritax: "Steuern",
        common_provider: "Anbietername",
        common_providerName: "Anbietername",
        common_purchase: "BUCHEN",
        common_readLess: "Weniger",
        common_readMore: "Weiterlesen",
        common_recommended: "Empfehlung",
        common_removeRoom: "Zimmer Entfernen",
        common_reservation: "RESERVIEREN",
        common_reset: "Zurücksetzen",
        common_resetbtn: "Zurücksetzen",
        common_reversable: "STORNIEREN",
        common_room: "Zimmer",
        common_rooms: "Zimmer",
        common_roomSelect: "Zimmerauswahl",
        common_salesTotal: "Gesamtumsatz",
        common_saleTime: "Verkaufsdatum",
        common_sat: "Sa.",
        common_saturday: "Samstag",
        common_save: "Speichern",
        common_search: "Suche",
        common_searchFlight: "Flug",
        common_searchHotel: "Hotel",
        common_searchPackages: "Pauschal",
        common_searchRentACar: "Mietwagen",
        common_searchRoom: "Zimmerauswahl",
        common_searchTransfer: "Transfer",
        common_segment: "Segment",
        common_selectAll: "Alle Auswählen",
        common_sep: "Sep",
        common_september: "September",
        common_serviceFee: "Gebühr",
        common_shortName: "Marketingname",
        common_show: "Anzeigen",
        common_showAllOption: "Alle Optionen Anzeigen",
        common_showLess: "Alle Funktionen ausblenden",
        common_showLessOption: "Weniger Optionen Anzeigen",
        common_showMore: "Alle Funktionen Anzeigen",
        common_showMoreOption: "Weitere Optionen anzeigen",
        common_star_decrease: "Kategorie absteigend",
        common_star_increase: "Kategorie aufsteigend",
        common_status: "Status",
        common_success: "Erfolgreich",
        common_sun: "So.",
        common_sunday: "Sonntag",
        common_surname: "Nachname",
        common_tax: "Steuer",
        common_thu: "Do.",
        common_thursday: "Donnerstag",
        common_timezone: "Zeitzone",
        common_toggle: "Anzeigen / Ausblenden",
        common_total: "Gesamt",
        common_total_penalty: "Stornobetrag",
        common_total_refund: "Gesamterstattungsbetrag",
        common_totalPrice: "Gesamtgebühr",
        common_totalRefund: "Gesamterstattung",
        common_totaltraveltime_decrease: "Gesamtreisezeit absteigend",
        common_totaltraveltime_increase: "Gesamtreisezeit aufsteigend",
        common_totalwaitingtime_decrease: "Wartezeit insgesamt absteigend",
        common_totalwaitingtime_increase: "Gesamtwartezeit aufsteigend",
        common_transfers_crease: "Anzahl der Stopps absteigend",
        common_transfers_increase: "Anzahl der Stopps aufsteigend",
        common_tue: "Die.",
        common_tuesday: "Dienstag",
        common_type: "Art",
        common_unknown: "Unbekannt",
        common_unknownChange: "Unbekannte Änderungsdetails",
        common_update: "Aktualisieren",
        common_userEmail: "Benutzer-E-mail",
        common_voucher: "Voucher",
        common_wed: "Mi.",
        common_wednesday: "Mittwoch",
        common_writeANote: "Notiz",
        common_zwischenstopps: "Zwischenstopps",
        creditCardComponent_cvvLabel: "Sicherheitscode",
        creditCardComponent_holderLabel: "Vor- und Nachname auf der Kreditkarte",
        creditCardComponent_monthLabel: "Monat",
        creditCardComponent_panLabel: "Karten-Nr.",
        creditCardComponent_yearLabel: "Jahr",
        customcancel_lblcustomCancel: "Abbrechen",
        customerList_customerList: "Kundenliste",
        customerManagement_btnAddNewCustomer: "Neuen Kunden hinzufügen",
        customerManagement_deleteCustomer: "Kunden Löschen",
        customerManagement_editCustomer: "Kunde Bearbeiten",
        customerManagement_popupCustomerAddedSubtitle: "Kunde hinzugefügt.",
        customerManagement_popupCustomerDeleteSubtitle: "Kunde Gelöscht.",
        customerManagement_popupCustomerEditSubtitle: "Kunde aktualisiert.",
        customerManagement_popupNewCustomerWillBeAdded: "Der Kunde wird hinzugefügt. Sind Sie damit einverstanden?",
        customerManagement_thBirthdate: "Geburtsdatum",
        customerManagement_thEmail: "E-mail",
        customerManagement_thNameSurname: "Vor- und Nachname",
        customerManagement_thNationalIdentity: "Nationale Identität",
        customerManagement_thNationality: "Staatsangehörigkeit",
        customerManagement_thPassportNumber: "Ausweisnummer",
        customerManagement_thPhone: "Nummer",
        customerSet_btnAdd: "Hinzufügen",
        customerSet_btnClose: "SCHLIEßEN",
        customerSet_lblAgencyName: "Agenturname",
        customerSet_lblBirthdate: "Geburtsdatum",
        customerSet_lblCityCode: "Vorwahl",
        customerSet_lblCountryCode: "Land",
        customerSet_lblDateofExpiry: "Ablaufdatum",
        customerSet_lblEmail: "E-mail",
        customerSet_lblGender: "Geschlecht",
        customerSet_lblHesCode: "HES Code",
        customerSet_lblMilesCard: "Meilennummer",
        customerSet_lblName: "Vorname",
        customerSet_lblNationalIdentity: "Nationale Identität",
        customerSet_lblNationality: "Nationalitaet",
        customerSet_lblPassportNumber: "Ausweisnummer",
        customerSet_lblPhoneNumber: "Nummer",
        customerSet_lblSerialNumber: "Seriennummer",
        customerSet_lblSurname: "Nachname",
        customerSettings_lblAction: "Bearbeiten",
        customerSettings_lblAddNewCustomer: "Neuen Kunden hinzufügen",
        customerSettings_lblBirthday: "Geburtsdatum",
        customerSettings_lblCustomerSettings: "Kundentransaktionen",
        customerSettings_lblEmail: "E-mail",
        customerSettings_lblFullName: "Vor- und Nachname",
        customerSettings_lblNationalIdentity: "Nationale Identität",
        customerSettings_lblNationality: "Nationalitaet",
        customerSettings_lblPhone: "Nummer",
        dashboard_flightForthComings: "Anstehende Flüge",
        dashboard_flightGiros: "Umsatzbericht- Flug",
        dashboard_flightPerformance: "Flug Performance",
        dashboard_flightReservations: "Anstehende Reservierungen - Flug",
        dashboard_hotelPerformance: "Hotel Performance",
        dashboard_hotelReservations: "Anstehende Reservierungen - Hotel",
        dashboard_hotelUpcomingCheckins: "Anstehende Hotel-Check-Ins",
        dashboard_rentACarUpcoming: "Anstehende Reservierungen - Mietwagen",
        dashboard_reportsGiro: "Verkaufsberichte",
        dashboard_reservations: "Reservierungen",
        dashboard_transferUpcoming: "Bevorstehende Reservierungen - Transfer",
        dashboard_upcomings: "Buchungen",
        dashboardAnnouncements_noContent: "Es gibt keine Ankündigung.",
        dashboardCampaigns_noContent: "Es gibt keine Kampagne.",
        dashboardGiro_allProductsTotal: "Gesamt Bruttoumsatz",
        dashboardGiro_apexAjet: "Ajet",
        dashboardGiro_apexCorendon: "XC",
        dashboardGiro_apexFlight: "Flug",
        dashboardGiro_apexHotel: "Hotel",
        dashboardGiro_apexOther: "Andere",
        dashboardGiro_apexPegasus: "PC",
        dashboardGiro_apexRentACar: "Mietwagen",
        dashboardGiro_apexSunexpress: "XQ",
        dashboardGiro_apexTransfer: "Transfer",
        dashboardGiro_apexTurkishAirlines: "TK",
        dashboardGiro_giroInfoText: "Der Verkaufsbericht zeigt die realisierten Umsätze, die nicht von Stornierungen, Rückerstattungen oder Änderungen beeinflusst wurden und die Bruttoumsätze darstellen.",
        dashboardGiro_gross: "Brutto",
        dashboardGiro_issuedEntityCount2: "PNR",
        dashboardGiro_issuedEntityCount3: "Voucher",
        dashboardGiro_issuedEntityCount4: "TNR",
        dashboardGiro_issuedEntityCount5: "RNR",
        dashboardGiro_issuedEntityCountDesc2: "(Gebucht)",
        dashboardGiro_issuedEntityCountDesc3: "(Gebucht)",
        dashboardGiro_issuedEntityCountDesc4: "(Gebucht)",
        dashboardGiro_issuedEntityCountDesc5: "(Gebucht)",
        dashboardGiro_issuedEtickets: "Pax",
        dashboardGiro_issuedPaxCount2: "Pax",
        dashboardGiro_issuedPaxCount3: "Pax",
        dashboardGiro_issuedPaxCountDesc2: "(Gebucht)",
        dashboardGiro_issuedPaxCountDesc3: "(Gebucht)",
        dashboardGiro_issuedPNR: "Pnr",
        dashboardGiro_issuedRoomCount3: "Zimmer",
        dashboardGiro_issuedRoomCountDesc3: "(Gebucht)",
        dashboardGiro_net: "Netto",
        dashboardGiro_reservedEntityCount2: "PNR",
        dashboardGiro_reservedEntityCount3: "Voucher",
        dashboardGiro_reservedEntityCountDesc2: "(Reserviert)",
        dashboardGiro_reservedEntityCountDesc3: "(Reserviert)",
        dashboardGiro_reservedEtickets: "Pax",
        dashboardGiro_reservedPaxCount2: "Pax",
        dashboardGiro_reservedPaxCountDesc2: "(Reserviert)",
        dashboardGiro_reservedPNR: "Pnr",
        dashboardGiro_total: "Gesamt",
        dashboardPage_announcements: "Neuigkeiten",
        dashboardPage_arrange_off: "Seite bearbeiten - AUS",
        dashboardPage_arrange_on: "Seite bearbeiten - EIN",
        dashboardPage_campaigns: "Top Deals",
        dashboardPage_carModelRoute: "Fahrzeug  / Abholort",
        dashboardPage_checkInDate: "Check In Datum",
        dashboardPage_driverName: "Name-Nachname",
        dashboardPage_flight_Flights: "Anstehende Flüge",
        dashboardPage_flight_Performance: "Flug Performance",
        dashboardPage_flight_Reservations: "Anstehende Reservierungen - Flug",
        dashboardPage_flightMyReservations: "Meine Reservierungen - Flug",
        dashboardPage_guestName: "Vor- und Nachname",
        dashboardPage_hotel_Checkins: "Bevorstehende Hotel-Check-In`s",
        dashboardPage_hotel_Performance: "Hotel Performance",
        dashboardPage_hotel_Reservations: "Bevorstehende Reservierungen - Hotel",
        dashboardPage_hotelMyCheckIns: "Meine Check-Ins",
        dashboardPage_hotelMyReservations: "Meine Reservierungen - Hotel",
        dashboardPage_hotelName: "Hotelname",
        dashboardPage_incompleteOrder: "Tägliche Transaktionen",
        dashboardPage_lastBookingDateTime: "Gültig bis",
        dashboardPage_lblAnnouncementDetail: "Ankündigung Detail",
        dashboardPage_lblAnnouncementList: "Ankündigungsliste",
        dashboardPage_lblCampaignList: "Kampagnenliste",
        dashboardPage_lblReadMore: "Weiterlesen",
        dashboardPage_lblShowDetails: "Details anzeigen",
        dashboardPage_lblValidDayCount: "Letzte #VALUE# Tage",
        dashboardPage_provideRoute: "Anbieter / Route",
        dashboardPage_providerRoute: "Route",
        dashboardPage_rentDate: "Uhrzeit der Abholung",
        dashboardPage_reservationStatus: "Reservierungsstatus",
        dashboardPage_RNR: "RNR",
        dashboardPage_showOldPosts: "Alte Beiträge Anzeigen",
        dashboardPage_Time: "Zeit",
        dashboardPage_TNR: "TNR",
        dashboardPage_totalGiro: "Täglicher Umsatz",
        dashboardPage_transferDate: "Transfer Datum",
        dashboardPage_travelerName: "Name-Surname",
        dashboardPage_welcomeBack: "Willkommen Zurück",
        dashboardPage_widgetBookingDetails: "Buchungsdetails",
        dashboardPage_widgetBookingNumber: "Buchungsnr.",
        dashboardPage_widgetFlightMyPastReservations: "Meine alten Optionen erneuern",
        dashboardPage_widgetFlightMyReservations: "Meine Reservierungen - Flug",
        dashboardPage_widgetForthComingFlights: "Anstehende Flüge",
        dashboardPage_widgetGiroAll: "Alle",
        dashboardPage_widgetGiroFlight: "Flug",
        dashboardPage_widgetHotelName: "Hotelname",
        dashboardPage_widgetMyReservations: "Anstehende Flüge",
        dashboardPage_widgetNameSurname: "Vor- und Nachname",
        dashboardPage_widgetPastReservations: "verfallene Optionen",
        dashboardPage_widgetPnr: "PNR",
        dashboardPage_widgetProviderRoute: "Anbieter / Route",
        dashboardPage_widgetReservationDetails: "Reservierungsdetails",
        dashboardPage_widgetReservationNumber: "Reservierungsnr.",
        dashboardPage_widgetReservationsAll: "Alle",
        dashboardPage_widgetReservationsFlight: "Flug",
        dashboardPage_widgetReservationsHotel: "Hotel",
        dashboardPage_widgetRnr: "RNR",
        dashboardPage_widgetRoute: "Fahrzeug / Route",
        dashboardPage_widgetTime: "Zeit",
        dashboardPage_widgetTnr: "TNR",
        dashboardPage_widgetUpcomingCharterFlights: "Anstehende Charterflüge",
        dashboardPage_widgetUpcomingsAll: "Alle",
        dashboardPage_widgetUpcomingsFlight: "Flug",
        dashboardPage_widgetUpcomingsHotel: "Hotel",
        dashboardPage_widgetUpcomingsRentACar: "Mietwagen",
        dashboardPage_widgetUpcomingsTransfer: "Transfer",
        dashboardPage_widgetVoucher: "Voucher",
        dashboardTable_last72Hours: "Letzte 72 Stunden",
        dashboardTable_onlyFlightChanges: "Uhrzeitänderung",
        dashboardTable_onlyOnlineCheckIns: "Online Check-In",
        dashboardTable_onlyPartialPaids: "Nur teilweise bezahlt",
        dashboardTable_onlyPastReservations: "Vergangene Reservierungen",
        dashboardTable_onlyWKSC: "Nur Flugzeitänderung",
        dashboardTable_print: "Drucken",
        DocumentModal_lblEmail: "E-mail",
        documentViewer_copyToClipboard: "Kopieren",
        documentViewer_couldntSendMail: "Die Mail konnte nicht versendet werden.",
        documentViewer_deselectAll: "Alle Abwählen",
        documentViewer_documentCannotGet: "Es ist ein Fehler beim Laden des Dokuments aufgetreten!",
        documentViewer_generatePDF: "Erneuern Sie",
        documentViewer_mailSentSuccessful: "Die Mail wurde erfolgreich versendet.",
        documentViewer_mailSentSuccessfully: "Die Mail wurde erfolgreich versendet.",
        documentViewer_openPdf: "Ansicht PDF",
        documentViewer_paxBasedInfoTitle: "Pax Basiertes Drucken",
        documentViewer_phEmail: "E-Mails",
        documentViewer_popupCopiedToClipboard: "Kopiert",
        documentViewer_printDocument: "Drucken",
        documentViewer_selectAll: "Alle Auswählen",
        documentViewer_sendEmail: "E-Mail senden",
        documentViewer_sendToWhatsapp: "Per WhatsApp senden",
        documentViewer_showCancelPolicies: "Richtlinieninfo anzeigen/ausblenden",
        documentViewer_showOptionDate: "Option Datum aus-/einblenden",
        documentViewer_showPrice: "Mit Preis / Ohne Preis",
        documentViewer_documentCannotGet_documentCannotGet: "Kein Dokument gefunden.",
        EAccTransactionTypeGroup_BONUS: "Bonus",
        EAccTransactionTypeGroup_CC_COMMISSION: "CC Commission",
        EAccTransactionTypeGroup_CC_PAYMENT: "KK Zahlung",
        EAccTransactionTypeGroup_F_COM_VPOS: "KK Kommission Flug",
        EAccTransactionTypeGroup_F_PAY_VPOS: "Kreditkartenzahlung Flug",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY: "Flugticket-Zusatzleistung",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY_REV: "Rückerstattung von Flugticket-Zusatzleistungen",
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY_REVERSE: "Zusätzliche Rückerstattung von Flugtickets",
        EAccTransactionTypeGroup_FLIGHT_COMMISSION: "Flugkommission",
        EAccTransactionTypeGroup_FLIGHT_COMMISSION_REVERSE: "Rückerstattung der Flugprovision",
        EAccTransactionTypeGroup_FLIGHT_FARE: "Verkauf von Flugtickets",
        EAccTransactionTypeGroup_FLIGHT_FARE_REVERSE: "Rückerstattung des Flugtickets",
        EAccTransactionTypeGroup_H_COM_VPOS: "KK Kommission Hotel",
        EAccTransactionTypeGroup_H_PAY_VPOS: "KK Zahlung Hotel",
        EAccTransactionTypeGroup_HOTEL_COMMISSION: "Hotelkommission",
        EAccTransactionTypeGroup_HOTEL_COMMISSION_REVERSE: "Rückerstattung der Hotelprovision",
        EAccTransactionTypeGroup_HOTEL_FARE: "Hotelverkauf",
        EAccTransactionTypeGroup_HOTEL_FARE_REVERSE: "Hotelrückerstattung",
        EAccTransactionTypeGroup_PAYMENT: "Zahlung",
        EAccTransactionTypeGroup_R_COM_VPOS: "CC Commission Rent A Car",
        EAccTransactionTypeGroup_R_PAY_VPOS: "CC Payment Rent A Car",
        EAccTransactionTypeGroup_RENTACAR_ANCILLARY: "Autovermietung Zusatzleistung",
        EAccTransactionTypeGroup_RENTACAR_ANCILLARY_REVERSE: "Autovermietung Zusatzkostenerstattung",
        EAccTransactionTypeGroup_RENTACAR_COMMISSION: "Rent A Car kommission",
        EAccTransactionTypeGroup_RENTACAR_COMMISSION_REVERSE: "Rent A Car Provisionsrückerstattung",
        EAccTransactionTypeGroup_RENTACAR_EXTRA: "Autovermietung Extras Verkauf",
        EAccTransactionTypeGroup_RENTACAR_EXTRA_REVERSE: "Autovermietung Extras Rückerstattung",
        EAccTransactionTypeGroup_RENTACAR_FARE: "Automietenverkauf",
        EAccTransactionTypeGroup_RENTACAR_FARE_REVERSE: "Autovermietung Rückerstattung",
        EAccTransactionTypeGroup_REV_CC_PAYMENT: "KK Zahlungsrückerstattung",
        EAccTransactionTypeGroup_REV_F_PAY_VPOS: "CC Payment Flight Refund",
        EAccTransactionTypeGroup_REV_H_COM_VPOS: "KK-Hotel Kommissionsrückerstattung",
        EAccTransactionTypeGroup_REV_H_PAY_VPOS: "CC Payment Hotel Refund",
        EAccTransactionTypeGroup_REV_R_PAY_VPOS: "CC Commission Rent A Car Refund",
        EAccTransactionTypeGroup_REV_T_COM_VPOS: "KK- Provision Transfer Rücerstattung",
        EAccTransactionTypeGroup_REV_T_PAY_VPOS: "CC-Zahlung Transfer Rückerstattung",
        EAccTransactionTypeGroup_SYSTEM_ERROR_CORRECTION: "System-Fehlerkorrektur",
        EAccTransactionTypeGroup_T_COM_VPOS: "CC-Provision Transfer",
        EAccTransactionTypeGroup_T_PAY_VPOS: "CC Payment Transfer",
        EAccTransactionTypeGroup_TRANSFER: "Transfer",
        EAccTransactionTypeGroup_TRANSFER_COMMISSION: "Transferkommission",
        EAccTransactionTypeGroup_TRANSFER_COMMISSION_REVERSE: "Rückerstattung der Transferprovision",
        EAccTransactionTypeGroup_TRANSFER_FARE: "Verkauf von Transfer",
        EAccTransactionTypeGroup_TRANSFER_FARE_REVERSE: "Rückerstattung des Transfer",
        EAccTransactionTypeGroup_UNKNOWN: "Unbekannt",
        EAccTransactionTypeGroup_VIRTUAL_CREDIT: "Virtual Credit",
        EComApplication_AGENT_SPA: "AGENT VUE",
        EComGender_FEMALE: "Frau",
        EComGender_MALE: "Herr",
        EFltCabinType_BUSINESS: "Business",
        EFltCabinType_ECONOMY: "Economy",
        EFltCabinType_UNKNOWN: "Unbekannt",
        EFltEticketStatus_11: "Reserviert - Pending",
        EFltEticketStatus_ALL: "Alle",
        EFltEticketStatus_BAGGAGE: "Gepäck",
        EFltEticketStatus_CANCELED: "Storniert",
        EFltEticketStatus_ERROR: "Fehler",
        EFltEticketStatus_FOOD: "Essen",
        EFltEticketStatus_ISSUED: "Gebucht",
        EFltEticketStatus_MEAL: "Essen",
        EFltEticketStatus_NEW: "Fehler - Neu",
        EFltEticketStatus_OPEN: "Offen",
        EFltEticketStatus_OTHER: "Andere",
        EFltEticketStatus_PENDING: "Empfangen - Ausstehend",
        EFltEticketStatus_PENDING_ISSUED: "Receiving - Pending",
        EFltEticketStatus_PENDING_REFUNDED: "Pending - Rückerstattet",
        EFltEticketStatus_PENDING_RESERVED: "Pending - Reserviert",
        EFltEticketStatus_PENDING_VOIDED: "Pending - Void",
        EFltEticketStatus_REFUNDED: "Erstattet",
        EFltEticketStatus_REISSUED: "Umbuchung",
        EFltEticketStatus_RESERVED: "Reserviert",
        EFltEticketStatus_SEAT: "Sitz",
        EFltEticketStatus_UNKNOWN: "Unbekannt",
        EFltEticketStatus_VOIDED: "Void",
        EFltEticketStatus_WHEELCHAIR: "Rollstuhl",
        EFltPaxType_ADULT: "Erwachsen",
        EFltPaxType_ADULT_WITH_INFANT: "Erwachsene und Babys",
        EFltPaxType_ALL: "Alle",
        EFltPaxType_CHILD: "Kinder",
        EFltPaxType_DISABLED_COMPANION: "Begleitperson",
        EFltPaxType_EXTRA_SEAT: "Extra Seat",
        EFltPaxType_INFANT: "Babys",
        EFltPaxType_UNKNOWN: "Unbekannt",
        EFltPnrStatus_9: "Offen",
        EFltPnrStatus_ALL: "Alle",
        EFltPnrStatus_CANCELED: "Storniert",
        EFltPnrStatus_ERROR: "Fehler",
        EFltPnrStatus_ISSUED: "Gebucht",
        EFltPnrStatus_NEW: "Fehler - Neu",
        EFltPnrStatus_OPEN: "Open Ticket",
        EFltPnrStatus_PENDING: "Empfangen - Ausstehend",
        EFltPnrStatus_PENDING_CANCELED: "Ausstehend – Abgebrochen",
        EFltPnrStatus_PENDING_CANCELLED: "Pending - Storniert",
        EFltPnrStatus_PENDING_ISSUED: "Ausstehend – Ausgestellt",
        EFltPnrStatus_PENDING_REFUNDED: "Ausstehend – Rückerstattet",
        EFltPnrStatus_PENDING_RESERVED: "Ausstehend – Reserviert",
        EFltPnrStatus_PENDING_VOIDED: "Ausstehend – Void",
        EFltPnrStatus_REFUNDED: "Rückerstattet",
        EFltPnrStatus_REISSUED: "Umbuchung",
        EFltPnrStatus_RESERVED: "Reserviert",
        EFltPnrStatus_UNKNOWN: "Unbekannt",
        EFltPnrStatus_VOIDED: "Void",
        EFltSeatTypeEnum_AISLE: "Gang",
        EFltSeatTypeEnum_EXIT: "Notausgang",
        EFltSeatTypeEnum_EXTRA_LEGROOM: "XL-Sitz",
        EFltSeatTypeEnum_STANDARD: "Standard",
        EFltSeatTypeEnum_UNKNOWN: "nicht definiert",
        EFltSeatTypeEnum_WINDOW: "Fensterseite",
        EFltSsrFoodType_AVML: "Asiatischer Vegetarier",
        EFltSsrFoodType_BBML: "Babymahlzeit",
        EFltSsrFoodType_CHML: "Kindermahlzeit",
        EFltSsrFoodType_CNML: "Hühnermahlzeit",
        EFltSsrFoodType_DBML: "Diabetische Mahlzeit",
        EFltSsrFoodType_FPML: "Mahlzeit am Obstteller",
        EFltSsrFoodType_FSML: "Fischmahlzeit",
        EFltSsrFoodType_GFML: "Glutenintolerante Mahlzeit",
        EFltSsrFoodType_GPML: "Gruppen-SSR Snack & 1 Getr.",
        EFltSsrFoodType_HNML: "Hinduistisches Essen",
        EFltSsrFoodType_KSML: "Koschere Mahlzeit",
        EFltSsrFoodType_LCML: "Kalorienarme Mahlzeit",
        EFltSsrFoodType_LFML: "Fettarme Mahlzeit",
        EFltSsrFoodType_LPML: "Proteinarme Mahlzeit",
        EFltSsrFoodType_LSML: "Mahlzeit ohne Salz",
        EFltSsrFoodType_MOML: "Moslemisches Essen",
        EFltSsrFoodType_NLML: "Mahlzeit ohne Laktose",
        EFltSsrFoodType_NOML: "Keine Mahlzeit",
        EFltSsrFoodType_PMIB: "Frühstücksplatte",
        EFltSsrFoodType_PMIC: "Hähnchenspieß",
        EFltSsrFoodType_PMID: "Gebratenes Hähnchen",
        EFltSsrFoodType_PMIE: "Rindergulasch",
        EFltSsrFoodType_SFML: "Meeresfrüchte-Mahlzeit",
        EFltSsrFoodType_SPML: "Besondere Mahlzeit",
        EFltSsrFoodType_TBML: "T/O 2 Snacks & 1 Wasser",
        EFltSsrFoodType_VGML: "Vegetarische Vegane Mahlzeit",
        EFltSsrFoodType_VJML: "Vegetarische Jain-Mahlzeit",
        EFltSsrFoodType_VLML: "Vegetarische Mahlzeit Lacto-Ovo",
        EFltSsrFoodType_VOML: "Vegetarische orientalische Mahlzeit",
        EFltSsrMealType_AVML: "Vegetarische Hindu-Mahlzeit",
        EFltSsrMealType_BBML: "Babymahlzeit",
        EFltSsrMealType_BLML: "leicht verdauliches Essen",
        EFltSsrMealType_CHML: "Kindermahlzeit",
        EFltSsrMealType_CNML: "Hühnchen Mahlzeit",
        EFltSsrMealType_DBML: "Diabetische Mahlzeit",
        EFltSsrMealType_EFltSsrMealType: "Kindermahlzeit",
        EFltSsrMealType_FPML: "Obstteller",
        EFltSsrMealType_FSML: "Fischmahlzeit",
        EFltSsrMealType_GFML: "Glutenfreie Mahlzeit",
        EFltSsrMealType_GPML: "Group SSR Snack & 1 Getr.",
        EFltSsrMealType_HNML: "Hinduistisches Essen",
        EFltSsrMealType_KSML: "Koschere Mahlzeit",
        EFltSsrMealType_LCML: "Kalorienarme Mahlzeit",
        EFltSsrMealType_LFML: "Fettarme Mahlzeit",
        EFltSsrMealType_LPML: "Proteinarme Mahlzeit",
        EFltSsrMealType_LSML: "Mahlzeit ohne Salz",
        EFltSsrMealType_MOML: "Muslimisches Essen",
        EFltSsrMealType_NLML: "Laktosefreie Mahlzeit",
        EFltSsrMealType_NOML: "Keine Mahlzeit",
        EFltSsrMealType_PMIB: "Frühstücksplatte",
        EFltSsrMealType_PMIC: "Hähnchenspieß",
        EFltSsrMealType_PMID: "Gebratenes Hähnchen",
        EFltSsrMealType_PMIE: "Rindergulasch",
        EFltSsrMealType_PRIBAS_SUN_CHML: "Kindermahlzeit",
        EFltSsrMealType_PRIBAS_SUN_PMDB: "Frühstücksplatte",
        EFltSsrMealType_PRIBAS_SUN_PMDC: "Hähnchenspieß",
        EFltSsrMealType_PRIBAS_SUN_PMDD: "Gebratenes Hähnchen",
        EFltSsrMealType_PRIBAS_SUN_PMDE: "Rindergulasch",
        EFltSsrMealType_PRIBAS_SUN_PMIB: "Frühstücksplatte",
        EFltSsrMealType_PRIBAS_SUN_PMIC: "Hähnchenspieß",
        EFltSsrMealType_PRIBAS_SUN_PMID: "Gebratenes Hähnchen",
        EFltSsrMealType_PRIBAS_SUN_PMIE: "Rindergulasch",
        EFltSsrMealType_PRIBAS_SUN_SCSM: "Snack Box & 1 Wasser",
        EFltSsrMealType_PRIBAS_SUN_SPDB: "SunPremium Omelett & 2 Getr.",
        EFltSsrMealType_PRIBAS_SUN_SPDC: "SunPremium Frikadel. & 2 Getr.",
        EFltSsrMealType_PRIBAS_SUN_SPDD: "SunPremium Aubergi. & 2 Getr.",
        EFltSsrMealType_PRIBAS_SUN_SPDE: "SunPremium Hähnchen & 2 Getr.",
        EFltSsrMealType_PRIBAS_SUN_SPIB: "SunPremium Frikadel. & 2 Getr.",
        EFltSsrMealType_PRIBAS_SUN_SPIC: "SunPremium Hähnchen & 2 Getr.",
        EFltSsrMealType_PRIBAS_SUN_SPID: "SunPremium Ravioli & 2 Getr.",
        EFltSsrMealType_PRIBAS_SUN_SPIE: "SunPremium Salat & 2 Getr.",
        EFltSsrMealType_PRIBAS_SUN_SPVG: "Falafel (vegan) & 1 alkoholfreies Getränk",
        EFltSsrMealType_PRIBAS_SUN_SPVL: "Vorspeisenteller (vegetarisch) & 1 alkoholfreies Getränk",
        EFltSsrMealType_PRIBAS_SUN_VGML: "Falafel (vegan)",
        EFltSsrMealType_PRIBAS_SUN_VLML: "Vorspeisenteller (vegetarisch)",
        EFltSsrMealType_SFML: "Meeresfrüchte-Mahlzeit",
        EFltSsrMealType_SPML: "Spezielle Mahlzeit",
        EFltSsrMealType_TAML: "T/O 1 Süß. Snack & 1 Wasser",
        EFltSsrMealType_TBML: "T/O 2 Snacks & 1 Wasser",
        EFltSsrMealType_VGML: "Vegane Mahlzeit",
        EFltSsrMealType_VJML: "Vegetarische Jain-Mahlzeit",
        EFltSsrMealType_VOML: "Orientalische Mahlzeit",
        EFltSsrType_BAGGAGE: "Gepäck",
        EFltSsrType_CABIN_BAG: "Handgepäck",
        EFltSsrType_CHECKIN_AT_AIRPORT: "Check-In",
        EFltSsrType_FOOD: "Essen",
        EFltSsrType_MEAL: "Beim Mahlzeit",
        EFltSsrType_OTHER: "Sonstiges",
        EFltSsrType_REISSUE_ALLOWANCE: "Sun Flex 3",
        EFltSsrType_SEAT: "Sitz",
        EFltSsrType_WHEELCHAIR: "Rollstuhl",
        EFltSsrWheelchairType_WCBD: "Batteriebetriebener Rollstuhl mit Trockenzellen",
        EFltSsrWheelchairType_WCHC: "Rollstuhl (Pax völlig immobil und kann keine Treppen steigen)",
        EFltSsrWheelchairType_WCHR: "Rollstuhl für die Rampe. (Kann Treppen steigen.)",
        EFltSsrWheelchairType_WCHS: "Rollstuhl (Kann keine Treppen steigen.)",
        EFltSsrWheelchairType_WCLB: "Passagier, der einen eigenen Lithium Batteriebetriebenen Rollstuhl hat",
        EFltSsrWheelchairType_WCMP: "Rollstuhl manuell (elektrisch angetrieben)",
        EHtlAmountType_FIXED: "Feststehend/Fix",
        EHtlAmountType_RATIO: "Verhältnis",
        EHtlGuestType_ADULT: "Erwachsene",
        EHtlGuestType_CHILD: "Kinder",
        EHtlPolicyType_CANCELLATION: "Stornierung",
        EHtlPolicyType_NO_SHOW: "No-Show",
        EHtlPriceRestrictionType_ALL: "Alle",
        EHtlPriceRestrictionType_MIN_STAY: "MinimumStay",
        EHtlPriceRestrictionType_NO_RESTRICTION: "Keine Einschränkung",
        EHtlPriceRestrictionType_QUOTA: "Quota",
        EHtlPriceRestrictionType_RELEASE: "Release",
        EHtlPriceRestrictionType_STOP: "Stop",
        EHtlPriceRestrictionType_UNKNOWN: "Unbekannt",
        EHtlRefundType_NONREFUNDABLE: "Nicht erstattbar",
        EHtlRefundType_REFUNDABLE: "Erstattbar",
        EHtlVoucherStatus_ALL: "Alle",
        EHtlVoucherStatus_BOOKED: "Gebucht",
        EHtlVoucherStatus_CANCELED: "Storniert aus Reserve",
        EHtlVoucherStatus_ERROR: "Fehler",
        EHtlVoucherStatus_NEW: "Neu",
        EHtlVoucherStatus_PARTIAL_PAID: "zum Teil bezahlt",
        EHtlVoucherStatus_REBOOKED: "Umbuchen",
        EHtlVoucherStatus_REFUNDED: "Stornierung mit Strafe",
        EHtlVoucherStatus_RESERVED: "Reserviert",
        EHtlVoucherStatus_UNKNOWN: "Unbekannt",
        EHtlVoucherStatus_VOIDED: "Storniert von Gebucht",
        "enum_airport-checkin": "Flughafen Check-In",
        enum_all: "Alle",
        enum_AVML: "Asiatischer Vegetarier",
        enum_baggage: "Gepäck",
        enum_BBML: "Babymahlzeit",
        enum_BLML: "Leichte Mahlzeit",
        enum_booked: "Gebucht",
        enum_business: "Business",
        "enum_cabin-bag": "1 Stück Handgepäck",
        enum_canceled: "Storniert",
        enum_cancellationPolicies_fixed: "Bei Stornierungen zwischen dem {0} und {1} werden {2}{3} Abzüge auf den Gesamtpreis der Unterkunft angewendet.",
        enum_cancellationPolicies_fixed_no_show: "Stornierungen die nach {0} erfolgen, wird eine No-Show Gebühr in Höhe von {1}{2} berechnet.",
        enum_cancellationPolicies_ratio: "Bei Stornierungen zwischen dem {0} - {1} beträgt die entsprechende Gebühr {2}{3}.",
        enum_cancellationPolicies_ratio_no_show: "Stornierungen die nach {0} erfolgen, wird eine No-Show Gebühr in Höhe von {1}{2} berechnet.",
        enum_cancelled: "Storniert",
        enum_cc_commission: "KK Kommission",
        enum_cc_payment: "KK Zahlung",
        enum_checkin_at_airport: "Flughafen Check-In",
        enum_CHML: "Kindermahlzeit",
        enum_close: "Geschlossen",
        enum_closed: "Geschlossen",
        enum_CNML: "Hähnchenmenü",
        enum_completed: "Abgeschlossen",
        enum_date1: "Startdatum",
        enum_DBML: "Diabetisches Menü",
        enum_duplicate: "Duplikat",
        enum_economy: "Economy",
        enum_eHtlAmountType_ALL: "Alle",
        enum_eHtlAmountType_FIXED: "Fest",
        enum_eHtlAmountType_RATIO: "Bei Stornierungen zwischen dem {0} - {1} beträgt die entsprechende Gebühr {2}{3}.",
        enum_eHtlAmountType_UNKNOWN: "Unbekannt",
        enum_EHtlRoomPriceStatus_NEW: "Neu",
        enum_EHtlRoomPriceStatus_RESERVED: "Reservieren",
        enum_EHtlVoucherStatus_ALL: "Alle",
        enum_EHtlVoucherStatus_BOOKED: "Gebucht",
        enum_EHtlVoucherStatus_CANCELLED: "Storniert aus Reserve",
        enum_EHtlVoucherStatus_ERROR: "Fehler",
        enum_EHtlVoucherStatus_NEW: "Neu",
        enum_EHtlVoucherStatus_REBOOKED: "Umbuchen",
        enum_EHtlVoucherStatus_REFUNDED: "Stornierung mit Strafe",
        enum_EHtlVoucherStatus_RESERVED: "Reserviert",
        enum_EHtlVoucherStatus_UNKNOWN: "Unbekannt",
        enum_EHtlVoucherStatus_VOIDED: "Storniert von gebucht",
        enum_enum_replied_by_agency: "Antwort von der Agentur erhalten",
        enum_error: "Fehler",
        enum_f_com_vpos: "KK Kommission Flug",
        enum_f_pay_vpos: "KK Zahlung Flug",
        enum_fcadom: "Flug Inl. / Rechnung / MCO",
        enum_fcaint: "Flug Ausl. / Rechnung / MCO",
        enum_fccdom: "Flug Inl. / Rechnung / Kreditkarte",
        enum_fccint: "Flug Ausl. / Rechnung / Kreditkarte",
        enum_fcodom: "Flug Inl. / Rechnung / Kreditkartenprovision",
        enum_fcoint: "Flug Int. / Rechnung / Kreditkartenprovision",
        enum_FPML: "Obstteller",
        enum_FSML: "Fischmenü",
        enum_GFML: "Glutenfreies Menü",
        "enum_hand-wallet": "Nur 1 Handtasche, die unter den Sitz passt, maximal 40x30x15 cm groß",
        enum_HNML: "Indisches Essen",
        enum_hotel: "Hotel",
        enum_insurance: "Versicherung",
        enum_invoice: "Rechnung",
        enum_issued: "Gebucht",
        enum_KSML: "Koscheres Essen",
        enum_LCML: "Kalorienarme Mahlzeit",
        enum_LFML: "Fettarme Mahlzeit",
        enum_LPML: "Proteinarme Mahlzeit",
        enum_LSML: "Mahlzeit ohne Salz",
        enum_meal: "Essen",
        enum_MOML: "Halal Essen",
        enum_new: "Neu",
        enum_NLML: "Laktosefreies Essen",
        enum_open: "Offen",
        enum_other: "Andere",
        enum_package_info: "Anfrage zu Pauschalreise-Informationen",
        enum_partial_paid: "zum Teil bezahlt",
        enum_payment: "Zahlung",
        enum_payment_reverse: "Zahlungsstorno",
        enum_pending_canceled: "Ausstehend – Abgebrochen",
        enum_pending_issued: "Ausstehend – Ausgestellt",
        enum_pending_refunded: "Ausstehend – Rückerstattet",
        enum_pending_reserved: "Ausstehend – Reserviert",
        enum_pending_voided: "Ausstehend – Void",
        enum_pnr: "Pnr",
        enum_pnr_add: "Manuell Pnr Upload",
        enum_pnr_ancillary: "Zusatzleistung",
        enum_pnr_ancillary_rev: "Rückerstattung von Flugticketzusatzleistungen",
        enum_pnr_commission: "Flugkommission",
        enum_pnr_commission_reverse: "Rückerstattung der Flugprovision",
        enum_pnr_correction: "Pnr-Korrektur",
        enum_pnr_divide: "Pnr - Passagier Trennen",
        enum_pnr_fare: "Verkauf von Flugtickets",
        enum_pnr_fare_reverse: "Rückerstattung des Flugtickets",
        enum_pnr_open: "Open Ticket",
        enum_pnr_reissue: "Umbuchung",
        enum_pnr_reverse: "Stornierung - Erstattung",
        enum_pnr_update: "Pnr Update",
        enum_pnr_update_pax: "Aktualisierung der Passagierinformationen",
        enum_processing: "Wird Bearbeitet",
        enum_receiving_pending: "Empfangen - Ausstehend",
        enum_refunded: "Zurückerstattet",
        "enum_reissue-allowance": "Sun Flex 3",
        enum_reissued: "Umbuchung",
        enum_reopen: "Wieder öffnen",
        enum_replied_by_agency: "Antwort von der Agentur erhalten",
        enum_replied_by_provider: "Antwort vom Anbieter erhalten.",
        enum_reserved: "Reservieren",
        enum_rev_f_com_vpos: "KK-Flug Kommissionsrückerstattung",
        enum_rev_f_pay_vpos: "KK Zahlung Flugticketerstattung",
        Enum_rnr_ancillary: "Mietwagen Zusatzleistungen",
        enum_rnr_reissue: "Autovermietung Umbuchung",
        enum_rnr_reverse: "Autovermietung Stornierung",
        enum_seat: "Sitz",
        enum_SFML: "Meeresfrüchte-Menü",
        enum_sunflex: "Kostenfreie Umbuchung bis 3 Tage vor Abflug (+ Preisdifferenz).",
        enum_tnr_add: "Transfer Hinzufügen",
        enum_tnr_reissue: "Transfer Umbuchung",
        enum_tnr_reverse: "Transfer Stornierung",
        enum_transfer: "Transfer",
        enum_unknown: "Unbekannt",
        enum_VGML: "Vegetarisches Essen",
        enum_VJML: "Vegetarische Jain-Mahlzeit",
        enum_VLML: "Vegetarische Mahlzeit Lacto-Ovo",
        enum_void: "Void",
        enum_voided: "Void",
        enum_VOML: "Orientalisches Essen",
        enum_voucher_add: "Voucher Hinzufügen",
        enum_voucher_reissue: "Hotel Umbuchung",
        enum_voucher_reverse: "Stornierung - Erstattung",
        enum_voucher_roomdateprice_update: "Voucher - Änderung",
        enum_voucher_update: "Voucher - Änderung",
        Enum_waiting: "Warten",
        enum_waiting_on_agency: "Warten auf Antwort von der Agentur",
        enum_waiting_on_operator: "Warten auf Antwort vom Operator",
        Enum_waiting_on_provider: "Warten auf Operatör",
        enum_WCBD: "Rollstühle mit auslaufsicherer Gel- und Trockenbatterie",
        enum_WCHC: "Rollstuhl (Passagier nicht mobil und kann keine Treppen steigen)",
        enum_WCHR: "Rollstuhl für die Rampe. (Kann Treppen steigen.)",
        enum_WCHS: "Rollstuhl (Kann keine Treppen steigen.)",
        enum_WCLB: "Passagier, der einen eigenen Lithiumbatterie-betriebenen Rollstuhl hat",
        enum_WCMP: "Rollstuhl manuell (elektrisch angetrieben)",
        enum_wheelchair: "Rollstuhl",
        enum_wont_fix: "Wird nicht behoben",
        EOdrEntityType_HOTEL: "VOUCHER",
        EOdrEntityType_INSURANCE: "Versicherung",
        EOdrEntityType_PNR: "PNR",
        EOdrEntityType_TRANSFER: "TRANSFER",
        EOdrEntityType_UNKNOWN: "Unbekannt",
        EOdrSearchHotelDateType_CHECKIN: "Check-In Datum",
        EOdrSearchHotelDateType_PROCESS: "Verkaufsdatum",
        EOdrSearchRentACarDateType_PICKUP: "Abholung",
        EOdrSearchRentACarDateType_PROCESS: "Verkauf",
        EOdrType_FLIGHT: "Flug",
        EPayCardBrand_UNKNOWN: "Unbekannt",
        EProcessType_FLIGHT_ONLINE_ANCILLARY_SALE: "Online-Nebenverkauf",
        ERacAncillaryType_ALL: "Alle",
        ERacAncillaryType_BABY_SEAT: "Babysitz / Kindersitz",
        ERacAncillaryType_EXTRA_DAMAGE: "Extra-Schaden",
        ERacAncillaryType_EXTRA_PICKUP_DROPOFF: "Abholung oder Rückgabe vom anderen Ort",
        ERacAncillaryType_EXTRA_TRAFFIC_PENALTY: "Zusätzliches Verkehrsstrafmandat",
        ERacAncillaryType_INSURANCE_ECO: "Glas, Reifen und Scheinwerfer Versicherung",
        ERacAncillaryType_INSURANCE_PREMIUM: "Versicherung",
        ERacAncillaryType_INTERNET: "Internet",
        ERacAncillaryType_MANDAT_ONEWAY_TRANSFER: "Einseitiger Transfer",
        ERacAncillaryType_NAVIGATION: "Navigation",
        ERacAncillaryType_SNOW_CHAIN: "Schneekette",
        ERacAncillaryType_SNOW_TIRE: "Winterreifen",
        ERacAncillaryType_UNKNOWN: "Unbekannt",
        ERacBodyType_ALL: "Alle",
        ERacBodyType_HATCHBACK: "Hatchback",
        ERacBodyType_MINIVAN: "Minivan",
        ERacBodyType_SEDAN: "Sedan",
        ERacBodyType_SUV: "SUV",
        ERacBodyType_UNKNOWN: "Unbekannt",
        ERacBodyType_VAN: "Van",
        ERacFacilityType_AIRPORT_SURCHARGE: "Flughafenzuschlag",
        ERacFacilityType_ALL: "Alle",
        ERacFacilityType_COLLISION_DAMAGE_WAIVER: "Kfz-Versicherung",
        ERacFacilityType_FREE_CANCELLATION: "Kostenlose Stornierung (bis 24 Stunden)",
        ERacFacilityType_THEFT_WAIVER: "Diebstahlschutz",
        ERacFacilityType_THIRD_PARTY_LIABILITY_INSURANCE: "Haftpflichtdeckung",
        ERacFacilityType_UNKNOWN: "Unbekannt",
        ERacFacilityType_UNLIMITED_MILEAGE: "Unbegrenzte Kilometer",
        ERacFuelType_ALL: "Alle",
        ERacFuelType_DIESEL: "Diesel",
        ERacFuelType_ELECTRIC: "Elektrisch",
        ERacFuelType_GASOLINE: "Benzin",
        ERacFuelType_UNKNOWN: "Unbekannt",
        ERacGearType_ALL: "Alle",
        ERacGearType_AUTOMATIC: "Automatik",
        ERacGearType_MANUEL: "Manuell",
        ERacGearType_UNKNOWN: "Unbekannt",
        ERacPassengerCapacity_3: "3 Passagier",
        ERacPassengerCapacity_5: "5 Passagiere",
        ERacPassengerCapacity_7: "7 Passagiere",
        ERacPassengerCapacity_9: "9 Passagiere",
        ERacRnrStatus_ALL: "Alle",
        ERacRnrStatus_CANCELED: "Storniert",
        ERacRnrStatus_ERROR: "Fehler",
        ERacRnrStatus_ISSUED: "Gebucht",
        ERacRnrStatus_NEW: "Neu",
        ERacRnrStatus_OPEN: "Offen",
        ERacRnrStatus_PENDING: "Receiving - Pending",
        ERacRnrStatus_REFUNDED: "Refunded",
        ERacRnrStatus_REISSUED: "Umgebucht",
        ERacRnrStatus_RESERVED: "Reserviert",
        ERacRnrStatus_VOIDED: "Void",
        EReportHotelDateType_CHECK_IN: "Anreise",
        EReportHotelDateType_CHECK_OUT: "Abreisedatum",
        EReportHotelDateType_SALE: "Verkauf",
        EReportRentACarDateType_PICKUP: "Datum und Uhrzeit der Abholung",
        EReportRentACarDateType_PROCESS: "Verkaufsdatum",
        EReportRentACarReportType_ANCILLARY: "Zusatzleistungen",
        EReportRentACarReportType_VEHICLE: "Fahrzeug",
        EReportSalesType_REISSUE: "Umbuchung",
        EReportSalesType_SALE: "Verkauf",
        EReportTransferDateType_ALL: "Alle",
        EReportTransferDateType_SALE: "Verkauf",
        EReportTransferDateType_TRANSFER: "Transfer Datum",
        EReportTransferDateType_UNKNOWN: "Unbekannt",
        "ERROR_CODES_AGENT_API.FLIGHT_TICKET.SUCCESS.PAYMENT_POST": "Ihre Transaktion wurde erfolgreich abgeschlossen.",
        "ERROR_CODES_AGENT_API.HOTEL_TICKET_RESERVED.REJECTED.INSUFFICIENT_PAYMENT": "Sie haben während der Bezahlung einen Fehler erhalten.",
        "ERROR_CODES_FLIGHT_API.FLIGHT_RESERVATION.PARAMETER.INVALID_PASSENGER_INFO": "Bitte überprüfen Sie die eingegebenen Passagierdaten.",
        ErrorReportList_thEntityType: "Produktart",
        ESchmetterlingOrderStatus_ALL: "Alle",
        ESchmetterlingOrderStatus_BOOKED: "Gebucht",
        ESchmetterlingOrderStatus_CANCELED: "Storniert",
        ESchmetterlingOrderStatus_DISPLAYED: "Erfolgreich angezeigt",
        ESchmetterlingOrderStatus_OPTIONAL_BOOKED: "Optional",
        ESchmetterlingOrderStatus_RESERVED: "Reserviert",
        ESchmetterlingOrderStatus_UNKNOWN: "Unbekannt",
        ETaskClosingType_ALL: "Alle",
        ETaskClosingType_CANCELED: "Storniert",
        ETaskClosingType_COMPLETED: "Abgeschlossen",
        ETaskClosingType_DUBLICATE: "Doppelt",
        ETaskClosingType_UNKNOWN: "Unbekannt",
        ETaskClosingType_WONT_FIX: "Wird nicht behoben",
        ETaskStatus_ALL: "Alle",
        ETaskStatus_CLOSED: "Geschlossen",
        ETaskStatus_OPEN: "Offen",
        ETaskStatus_UNKNOWN: "Unbekannt",
        ETaskStatus_waiting_on_agency: "Warten auf Agentur",
        ETaskSubStatus_8: "Geantwortet",
        ETaskSubStatus_ALL: "Alle",
        ETaskSubStatus_CLOSED: "Geschlossen",
        ETaskSubStatus_NEW: "Neu",
        ETaskSubStatus_PROCESSING: "Wird bearbeitet",
        ETaskSubStatus_REPLIED_BY_AGENCY: "Antwort der Agentur erhalten",
        ETaskSubStatus_REPLIED_BY_PROVIDER: "Antwort vom Anbieter erhalten.",
        ETaskSubStatus_UNKNOWN: "Unbekannt",
        ETaskSubStatus_WAITING: "Warten",
        ETaskSubStatus_WAITING_ON_AGENCY: "Warten auf Antwort von der Agentur",
        ETaskSubStatus_WAITING_ON_OPERATOR: "Warten auf Antwort vom Operator",
        ETaskSubStatus_WAITING_ON_PROVIDER: "Warten auf Antwort vom Anbieter",
        ETaskSubStatusETaskSubStatus_REPLIED_BY_PROVIDER: "Antwort vom Lieferanten erhalten",
        ETaskSubType_BASIC: "Basic",
        ETaskSubType_FLIGHT_CHANGE_CONFIRM_MAJOR: "Geplante Flugplanänderung",
        ETaskSubType_FLIGHT_CHANGE_CONFIRM_MINOR: "Verpflichtende Flugplanänderung",
        ETaskType_7: "Manueller Verkauf",
        ETaskType_ALL: "Alle",
        ETaskType_PAYMENT_REVERSE: "Zahlungsstorno",
        ETaskType_PNR_ADD: "Manuell Pnr Upload",
        ETaskType_PNR_ANCILLARY: "Pnr-Zusatzleistung",
        ETaskType_PNR_CORRECTION: "Pnr-Korrektur",
        ETaskType_PNR_DIVIDE: "Pnr - Passagier Trennen",
        ETaskType_PNR_FLIGHT_CHANGE_CONFIRMATION: "Auto - Flugplanänderungsbenachrichtigung",
        ETaskType_PNR_OPEN: "Open Ticket",
        ETaskType_PNR_REISSUE: "Pnr Umbuchung",
        ETaskType_PNR_RESERVE: "Pnr Stornierung - Erstattung",
        ETaskType_PNR_REVERSE: "Pnr Stornierung - Rückerstattung",
        ETaskType_PNR_UPDATE: "Pnr Update",
        ETaskType_PNR_UPDATE_PAX: "Aktualisierung der Passagierinformationen",
        ETaskType_TNR_ADD: "Manuell TNR Hinzufügen",
        ETaskType_TNR_REISSUE: "Transfer Umbuchung",
        ETaskType_TNR_REVERSE: "TNR Reverse",
        ETaskType_UNKNOWN: "Unbekannt",
        ETaskType_VOUCHER_ADD: "Manueller Gutschein-Upload",
        ETaskType_VOUCHER_NOTE: "Hinweis der Agentur",
        ETaskType_VOUCHER_RECONFIRMATION: "Reconfirmation Task",
        ETaskType_VOUCHER_REISSUE: "Hotel Umbuchung",
        ETaskType_VOUCHER_REVERSE: "Hotel Stornierung - Rückerstattung",
        ETaskType_VOUCHER_ROOMGUEST_UPDATE: "Hotelgast-Update",
        ETaskType_VOUCHER_UPDATE: "Aktualisierungsstatus",
        ETasLogStatus_FLIGHT: "Flugzeug",
        ETrfFacilityType_UNKNOWN: "Bis zu 48 Stunden kostenlos stornieren",
        ETrfFacilityType_VOIDABLE_BEFORE_24H: "Bis zu 24 Stunden kostenlos stornieren",
        ETrfFacilityType_VOIDABLE_BEFORE_36H: "Bis zu 36 Stunden kostenlos stornieren",
        ETrfLegStatus_ALL: "Alle",
        ETrfLegStatus_CANCELED: "Storniert",
        ETrfLegStatus_ERROR: "Error",
        ETrfLegStatus_ISSUED: "Gebucht",
        ETrfLegStatus_NEW: "Neu",
        ETrfLegStatus_OPEN: "Offen",
        ETrfLegStatus_PENDING: "Ausstehend",
        ETrfLegStatus_REFUNDED: "Rückerstattet",
        ETrfLegStatus_REISSUED: "Umbuchung",
        ETrfLegStatus_RESERVED: "Reserviert",
        ETrfLegStatus_UNKNOWN: "Unbekannt",
        ETrfLegStatus_VOIDED: "Void",
        ETrfTnrStatus_ALL: "Alle",
        ETrfTnrStatus_CANCELED: "Storniert",
        ETrfTnrStatus_ERROR: "Fehler",
        ETrfTnrStatus_ISSUED: "Gebucht",
        ETrfTnrStatus_NEW: "Neu",
        ETrfTnrStatus_OPEN: "Offen",
        ETrfTnrStatus_PENDING: "Ausstehend",
        ETrfTnrStatus_REFUNDED: "Rückerstattet",
        ETrfTnrStatus_REISSUED: "Umbuchung",
        ETrfTnrStatus_RESERVED: "Reserviert",
        ETrfTnrStatus_UNKNOWN: "Unbekannt",
        ETrfTnrStatus_VOIDED: "Void",
        ETrfTransferType_ALL: "Alle",
        ETrfTransferType_GROUP: "Gruppe",
        ETrfTransferType_INDIVIDUAL: "Individuell",
        ETrfTransferType_UNKNOWN: "Unbekannt",
        ETrfTravelerType_ADULT: "Erwachsene",
        ETrfTravelerType_CHILD: "Kinder",
        ETrfTravelerType_INFANT: "Babys",
        ETrfVehicleCategory_ALL: "Alle",
        ETrfVehicleCategory_CAR: "Auto",
        ETrfVehicleCategory_MIDIBUS: "Midibus",
        ETrfVehicleCategory_MINIBUS: "Minibus",
        ETrfVehicleCategory_MINIVAN: "Minivan",
        ETrfVehicleCategory_MINIVAN_VIP: "VIP Minivan",
        ETrfVehicleCategory_OTOBUS: "Bus",
        ETrfVehicleCategory_UNKNOWN: "Unbekannt",
        ETrfVehicleCategory_VAN: "Van",
        filterComponent_boardType_titleLabel: "Verpflegung",
        filterComponent_class_selectedClassCount_label: "Sterne",
        filterComponent_class_titleLabel: "Stern",
        filterComponent_facilities_titleLabel: "Einrichtungen",
        filterComponent_information_titleLabel: "Suche verfeinern",
        filterComponent_prices_titleLabel: "Budget (Gesamtpreis)",
        flight_fillPaxRows: "Ausfüllen",
        flight_fillPaxRowsCancelBtn: "Abbrechen",
        flight_fillPaxRowsContent: "Möchten Sie die Passagierdaten automatisch ausfüllen lassen?",
        flight_fillPaxRowsTooltip: "Autofill Passagiere",
        flight_fillPaxRowsTooltipHighlighter: "Nutzen Sie die Passenger Kommunikation.",
        flight_fillPaxRowsTtitle: "Passagierinformationen",
        flight_flightCabinType: "Kabinenklasse",
        flight_flightClass: "Klasse",
        flight_operatingAirline: "Fluggesellschaften",
        flight_paxBirthDate: "Geburtstag",
        flight_paxInfo: "Passagierinformationen",
        flight_paxNameSurname: "Vor- und Nachname",
        flight_paxType: "Passagiertyp",
        FLIGHT_PRIBAS_KEY57: "1 kleines Handgepäckstück (max. 4kg ve 40x30x20cm)",
        flightBrands_lblBaggageInfoUnknown: "Gepäck Unbekannt",
        flightBrands_lblBrandCalculate: "Berechnen",
        flightCard_directFlight: "Nonstop",
        flightCard_operatedBy: "Gesteuert von",
        flightCard_showDetails: "Details",
        flightCardComponent_oneWay: "One Way",
        flightcardcomponent_operatedBy: "Operated By",
        flightChange_lblApprove: "Bestätigen Sie Änderung",
        flightChange_lblPnrReissue: "Umbuchungsanfrage",
        flightChange_lblPnrReverse: "Stornierungsanfrage",
        flightChangeAlert_lblMsg: "Es gibt eine Flugplanänderung oder eine Annullierung. Bitte prüfen Sie dies.",
        flightChangeAlert_lblTitle: "Info",
        flightCheckout_agencyExtraCCommission: "Agentur Extra-Kommission wird hinzugefügt.",
        FlightCheckout_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "Ihr Benutzer ist nicht berechtigt, diesen Vorgang auszuführen. Bitte überprüfen Sie Ihren Agenten.",
        FlightCheckout_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Eine Reservierung ist nicht möglich. Bitte setzen Sie den Vorgang fort, indem Sie Ihre Suche erneuern.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "Während Ihrer Transaktion gab es eine Preisdifferenz.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_PRICE_DIFFERENCE_AFTER_RESERVATION: "Während Ihrer Transaktion gab es eine Preisdifferenz.",
        FlightCheckout_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "Sie haben während der Zahlung einen Fehler erhalten.",
        flightCheckout_BrandPriceUnavailable: "Der Preis für diese Pauschalreise konnte von der Fluggesellschaft nicht abgerufen werden.",
        flightCheckout_brandSelection: "Die Marke wird geändert.",
        FlightCheckout_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "Ein unbekannter Fehler ist aufgetreten.",
        FlightCheckout_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNMAPPED_ERROR: "Beim Ticketing ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        flightCheckout_lblAgencyExtraCommission: "Agenturprovision Extra",
        flightCheckout_lblBaseTotal: "Nettopreis",
        flightCheckout_lblGrandTotal: "Gesamtsumme",
        flightCheckout_SELECTED_BRANDS_NOT_VALID_NOW: "Die Fluggesellschaft gibt keinen Preis für die von Ihnen gewählte Option an. Sie können es erneut versuchen, indem Sie Ihre Auswahl ändern.",
        flightCheckout_SSR_rezervationDisabled: "Da Sie SSR hinzugefügt haben, lässt die Fluggesellschaft die Reservierung nicht zu.  Sie müssen das Ticket sofort ausstellen.",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "Ein Fehler ist aufgetreten",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Fehler",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "Preisdifferenz",
        FlightCheckout_TITLE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "Ein Fehler ist aufgetreten",
        FlightCheckout_TITLE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "Fehlermeldung",
        flightCheckoutPayment_priceDifference: "Akzeptieren Sie AMOUNTPLACEHOLDER  Preisunterschied?",
        flightCheckoutPayment_transectionProgress: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        flightInformationsComponent_baggageAllowance: "Freigepäck",
        flightInformationsComponent_cabinType: "Kabinenklasse",
        flightInformationsComponent_class: "Klasse",
        flightInformationsComponent_flight: "Flug",
        flightInformationsComponent_flightInformation: "Fluginformationen",
        flightInformationsComponent_timeChanged: "Ihre Flugdaten haben sich geändert.",
        flightInformationsComponent_travelDuration: "Flugdauer",
        "flightLegHeader_show/hideResults": "Ergebnisse Anzeigen / Ausblenden",
        flightMatrix_showHideAll: "Alle Fluggesellschaften",
        FlightOffer_flight: "Flug",
        flightOrderDetail_btnCalculatePenalty: "Erstattungsbetrag berechnen",
        flightOrderDetail_lblAgencyExtraCommission: "Agenturprovision Extra",
        flightOrderDetail_lblAgencyServiceFee: "Erstattung Agenturprovision",
        flightOrderDetail_lblAncillaryTotal: "Zusatzleistungen Gesamt",
        flightOrderDetail_lblBaseTotal: "Nettopreis",
        flightOrderDetail_lblCalculatingPenalty: "Berechnung des Erstattungsbetrags",
        flightOrderDetail_lblCalculatingPenaltyTxt: "Ihr Erstattungsbetrag wird berechnet. Bitte warten Sie.",
        flightOrderDetail_lblCancellationWithPenalty: "Kostenpflichtige Erstattung",
        flightOrderDetail_lblCancellationWithPenaltyTxt: "Sie können die Stornogebühr und den Erstattungsbetrag durch Berechnung ermitteln.",
        flightOrderDetail_lblGrandTotal: "Gesamtsumme",
        flightOrderDetail_lblPackageGrandTotal: "Pauschalreise-Gesamtsumme",
        flightOrderDetail_lblPaymentFeeTotal: "Transaktionsgebühr für Kreditkarten",
        flightOrderDetail_lblRefundWithPenalty: "Erstattungsbetrags",
        flightOrderDetail_lblReissueTotal: "Summe ändern",
        flightOrderDetail_lblReverseTotal: "Gesamterstattung",
        flightOrderDetail_lblReverseTotalAncillary: "Zusatzkostenerstattung",
        flightOrderDetail_lblSupplierReturn: "Erstattung Provider",
        flightOrderDetail_msgRESERVATED_FLIGHT_CHANGED: "Es gibt eine Flugplanänderung für diesen Flug. Bitte wenden Sie sich an das Service Center.",
        flightOrderDetail_ticketIssuedNotificaiton: "Sie haben es erfolgreich erstellt, Ihre Details sind unten.",
        flightOrderDetail_ticketReservedNotificaiton: "Sie haben Ihr Ticket erfolgreich reserviert.",
        flightOrderDetail_txtFlight: "Flug",
        flightPassengerInformationsComponent_passengerInformations: "Passagierinformationen",
        flightPayment_infoCreditCard: "Den Kreditkartenzahlungen wird eine feste Bankprovision von 2% hinzugefügt.",
        flightPayment_infoSofort: "Im Falle einer Sofortzahlung; Summen zwischen 1€ und 500€ pauschal 3€ und Summen über 501€ pauschal 5€ werden zum Gesamtbetrag hinzugerechnet.",
        FlightPayment_INSUFFICIENT_AGENCY_BALANCE: "Ihr verfügbares Guthaben reicht nicht aus.  Bitte kontaktieren Sie unser Team.",
        FlightPaymentWidget_btnReverse: "Kostenfrei Stornieren",
        FlightPaymentWidget_popup_REVERSE_FLIGHT: "Ihr Ticket wird storniert. Bestätigen Sie?",
        FlightPaymentWidget_popup_title: "Information",
        flightPriceInformationsComponent_priceInformations: "Preisinformation",
        flightSaleReport_currency: "Währung",
        flightSaleReport_flightDate: "Hinflugdatum",
        flightSaleReport_paxCount: "Personenanzahl",
        flightSaleReport_pnrCount: "Pnr-Anzahl",
        flightSaleReport_refundTotal: "Rückerstattungsbetrag",
        flightSaleReport_totalAmount: "Verkaufspreis",
        flightSaleReport_transactionDate: "Transaktionsdatum",
        flightSalesReport_airlineList: "Fluggesellschaften",
        flightSalesReport_creationFromDate: "Startdatum",
        flightSalesReport_creationToDate: "Enddatum",
        flightSalesReport_currency: "Währung",
        flightSalesReport_flightDate: "Flugdatum",
        flightSalesReport_optEticket: "E-Ticket",
        flightSalesReport_optPNR: "Pnr",
        flightSalesReport_paxCount: "Personenanzahl",
        flightSalesReport_pnrCount: "Pnr-Anzahl",
        flightSalesReport_pnrStatus: "Pnr-Status",
        flightSalesReport_providerList: "Anbieterliste",
        flightSalesReport_refundTotal: "Rückerstattungsbetrag",
        flightSalesReport_reportType: "Berichtsart",
        flightSalesReport_showReissues: "Umbuchungen anzeigen",
        flightSalesReport_title: "Flugticket-Verkaufsbericht",
        flightSalesReport_totalAmount: "Verkaufspreis",
        flightSalesReport_transactionDate: "Transaktionsdatum",
        flightSalesReport_userList: "Benutzerliste",
        flightSearchForm_lblMultiLeg: "Gabelflug",
        flightSearchResult_lblFamilyPricesToggler: "Zeige die niedrigsten Paketpreise",
        flightSearchResult_lblOkay: "Verstanden",
        flightSearchResult_tourFamilyPricesToggler: "Sie können es verwenden, um den niedrigsten Paketpreis zu sehen.",
        FlightSelect_AGENT_API_FLIGHT_CHECKOUT_EXCEPTION_CHECKOUT: "Es ist ein unbekannter Fehler aufgetreten.",
        FlightSelect_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Die Preise wurden möglicherweise aktualisiert, weil Sie lange nicht gehandelt haben.  Sie können Ihren Vorgang fortsetzen, indem Sie Ihre Suche erneuern.",
        FlightSelect_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Bitte wählen Sie 'Conjugate'-Flüge aus oder deaktivieren Sie die Option, wenn Sie einen 'One Way Tarif'-Tarif haben.",
        FlightSelect_TITLE_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Die Preisinformationen können sich geändert haben.  Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        FlightSelect_TITLE_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Bitte wählen Sie 'Conjugate'-Flüge aus oder deaktivieren Sie die Option, wenn Sie einen 'One Way Tarif'-Tarif haben.",
        flightsResults_noFlightWarning: "Kein Flug gefunden.",
        girosWidget_gross: "Brutto",
        girosWidget_net: "Netto",
        girosWidget_others: "ANDERE",
        gtsDataTableComponent_checkIn: "Online Check-In",
        gtsDataTableComponent_daily: "Täglich",
        gtsDataTableComponent_emptyEntity: "empty entity",
        gtsDataTableComponent_firstLabel: "Zuerst",
        gtsDataTableComponent_flightChange: "Uhrzeitänderung",
        gtsDataTableComponent_lastLabel: "Ende",
        gtsDataTableComponent_lblWKSC: "Flugzeitänderung",
        gtsDataTableComponent_monthly: "Monatlich",
        gtsDataTableComponent_nextLabel: "Nächste",
        gtsDataTableComponent_partialPayment: "Restzahlung",
        gtsDataTableComponent_performanceBarChartdaily: "Anzahl der täglichen Paxe",
        gtsDataTableComponent_performanceBarChartmonthly: "Monatliche Paxanzahl",
        gtsDataTableComponent_performanceBarChartweekly: "Anzahl der wöchentlichen Paxe",
        gtsDataTableComponent_prevLabel: "Zurück",
        gtsDataTableComponent_rowsPerPageLabel: "Auf Seite Anzeigen",
        gtsDataTableComponent_totalGiro: "Gesamtumsatz",
        gtsDataTableComponent_totalPax: "Gesamt Pax",
        gtsDataTableComponent_totalPnr: "Gesamt Pnr",
        gtsDataTableComponent_totalSearch: "Gesamtsuche",
        gtsDataTableComponent_totalVoucher: "Total Voucher",
        gtsDataTableComponent_weekly: "Wöchentlich",
        gtsGrid_btnNextPage: "Nächste",
        gtsGrid_btnPrevPage: "Zurück",
        gtsGrid_item: "Artikel",
        gtsGrid_showAll: "Alle Artikel",
        gtsGrid_totalItem: "Artikel angezeigt",
        guestItem_txtGuest: "Gast",
        hamburgerMenu_accTransactionList: "Kontenverlauf",
        hamburgerMenu_balanceUpdate: "Guthaben Aufladen",
        hamburgerMenu_customerManagement: "Kundentransaktionen",
        hamburgerMenu_darkMode: "Dunkler Modus",
        hamburgerMenu_dashBoard: "Dashboard",
        hamburgerMenu_financeWithSubs: "Finanzen",
        hamburgerMenu_flightSaleReport: "Flugverkaufsbericht",
        hamburgerMenu_flightSalesReport: "Flugverkaufsbericht",
        hamburgerMenu_flightSearch: "Flugsuche",
        hamburgerMenu_hotelSaleReport: "Hotelverkaufsbericht",
        hamburgerMenu_hotelSalesReport: "Hotelverkaufsbericht",
        hamburgerMenu_hotelSearch: "Hotel suchen",
        hamburgerMenu_invoiceList: "Rechnungsliste",
        hamburgerMenu_lightMode: "Heller Modus",
        hamburgerMenu_logOut: "Ausloggen",
        hamburgerMenu_orderSearch: "Order Suche",
        hamburgerMenu_profileSettings: "Profileinstellungen",
        hamburgerMenu_rentACarSalesReport: "Rent A Car Verkaufsbericht",
        hamburgerMenu_rentACarSearch: "Rent A Car Suche",
        hamburgerMenu_reportsWithSubs: "Berichte",
        hamburgerMenu_serviceCenter: "Service Center",
        hamburgerMenu_settings: "Einstellungen",
        hamburgerMenu_transferSalesReport: "Transferverkaufsbericht",
        hamburgerMenu_transferSearch: "Transfersuche",
        hamburgerMenu_userAuthorization: "Benutzerberechtigungen",
        hamburgerMenu_userSettings: "Benutzereinstellungen",
        "HOTEL_API.TICKET.EXCEPTION.error.hotel.not.accepts.only.man": "Mr. Stays werden von diesem Hotel nicht akzeptiert. Bitte schließen Sie die Seite und suchen Sie erneut.",
        hotel_lblAdult: "Erwachsene",
        hotel_lblChild: "Kinder",
        hotel_lblCustomerNationality: "Abreiseland",
        hotel_lblDateInfo: "Aufenthaltsdatum:",
        hotel_lblDestinationName: "Reiseziel",
        hotel_lblInfant: "Babys",
        hotel_lblManuelRequest: "Für Ihre Suchkriterien gibt es leider keine Verfügbarkeit. Sie können uns Ihre Anfrage manuell senden, indem Sie auf die Schaltfläche „Anfrage bestätigen“ klicken.",
        hotel_lblRoom: "Zimmer",
        hotel_lblRoomInfos: "Zimmer:",
        hotel_manuelFormTitle: "Wie können wir Sie erreichen?",
        hotel_manuelRequestCancel: "Abbrechen",
        hotel_manuelRequestConfirm: "Anfrage bestätigen",
        hotel_manuelRequestModalTitle: "Nicht gefunden wonach Sie suchen? Wir machen es für Sie!",
        HOTEL_API_TICKET_HOTEL_EXCEPTION_GTS_BOOKING: "Beim Ausführen des Vorgangs wurde das Zimmerkontingent bereits ausgebucht. Bitte schließen Sie die Seite und führen Sie die Suche erneut durch oder kontaktieren Sie unser Service-Center.",
        hotelAutocomplete_lblCity: "Stadt",
        hotelAutocomplete_lblDisctrict: "Kreis",
        hotelAutocomplete_lblHotel: "Hotel",
        hotelAutocomplete_lblLastSearches: "Zuletzt gesucht",
        hotelAutocomplete_phDestination: "Suche nach: Stadt, Region oder Unterkunft",
        hotelBasket_btnClear: "Löschen",
        hotelBasket_lblAddOnPrice: "Add-On Preis",
        hotelBasket_lblGrandTotal: "Gesamtsumme",
        hotelBasket_lblRoomPrice: "Zimmerpreis",
        hotelBasket_lblTitle: "Warenkorb",
        hotelBasketComponent_roomPriceLabel: "Zimmerpreis",
        hotelCacheCheckout_lblCancellationPolicy: "Stornierungsbedingungen",
        hotelCacheCheckout_lblPassengerInformations: "Gästeinformationen",
        HotelCacheCheckoutBook_WarningEmptyAreaContent: "Bitte überprüfen Sie die Angaben und versuchen Sie es erneut.",
        HotelCacheCheckoutBook_WarningEmptyAreaHead: "Fehler",
        hotelCacheOrderDetail_lblInfoDontForgetSave: "Vergessen Sie nicht, die Angaben zu speichern.",
        hotelCacheOrderDetail_lblPaymentInformations: "Zahlungsinformationen",
        hotelCacheOrderDetail_lblSummary: "Informationen zur Unterkunft",
        hotelCacheSearch_addOn: "Add-On",
        hotelCacheSearch_lblHotelNotSelectedWarning: "Sie haben kein Hotel ausgewählt…",
        hotelCacheSearch_lblMultiHotelSelect: "Mehrere Hotelauswahl",
        hotelCacheSearch_lblSearchInfo: "Auf dieser Seite werden die Hotels der Anbieter angezeigt, die zum Verkauf stehen, im Bereich der Suchregeln für Großhandels-Hotels.",
        hotelCacheSearch_lblSelectedHotels: "Ausgewählte Hotels",
        HotelCacheSearch_MultiSelectWarningPopup: "Ein oder mehrere Hotels, die Sie ausgewählt haben, wurden nicht in der Cache-Liste gefunden.",
        HotelCacheSearch_MultiSelectWarningPopupInfo: "Wählen Sie aus, um mehrere Hotels hinzuzufügen...",
        HotelCacheSearch_WarningMultiSelectInfo: "Ihre Hotelsuchliste wird zurückgesetzt, weil Sie von der Mehrfach- auf Einzelauswahl umgestellt haben.",
        HotelCacheSearch_WarningMultiSelectPopup: "İnformation",
        HotelCacheSearch_WarningPopup: "Information",
        HotelCacheSearch_WarningPopupEmpty: "Fehler",
        HotelCacheSearch_WarningPopupEmptyInfo: "Bitte treffen Sie eine Auswahl aus der Liste, indem Sie das Suchfeld ausfüllen.",
        HotelCacheSearch_WarningPopupInfo: "Bitte stellen Sie sicher, dass das gesuchte Hotel in der Cache-Liste enthalten ist!",
        HotelCacheSearch_WarningPopupToCheckout: "Fehler",
        HotelCacheSearch_WarningPopupTopInfo: "Bitte überprüfen Sie Ihre Suchkriterien und versuchen Sie es erneut.",
        hotelCard_btnFacilityShowLess: "Alle Funktionen ausblenden",
        hotelCard_btnFacilityShowMore: "Alle Funktionen Anzeigen",
        hotelCard_btnReadLess: "Weniger",
        hotelCard_btnReadMore: "Weiterlesen",
        hotelCard_btnSearchRoom: "Zimmerauswahl",
        hotelCard_lblBoutiqueHotel: "Boutique Hotel",
        hotelCard_lblCalendarBetween: "Zwischen den ausgewählten Daten besteht keine Verfügbarkeit.",
        hotelCard_lblCalendarInfo: "Bitte wählen Sie Ihr Check-in- und Check-out-Datum aus…",
        hotelCard_lblCalendarMinStay: "Für die von Ihnen ausgewählten Datum gilt eine Mindestaufenthaltsdauer von #VALUE#.",
        hotelCard_lblCalendarRelease: "Reservierungen können nicht an ausgewählten Datum, #VALUE# Tagen oder weniger vor dem Check-in vorgenommen werden.",
        hotelCard_lblCalendarSelectNights: "Datum auswählen...",
        hotelCard_lblCalendarStopType: "Bitte ändern Sie den Datumsbereich.",
        hotelCard_lblCalendarSuccess: "Datumsauswahl ist erfolgreich...",
        hotelCard_lblChangeDate: "Datum ändern",
        hotelCard_lblDescription: "Hotelbeschreibung",
        hotelCard_lblDiscounted: "Rabatt",
        hotelCard_lblhasAddon: "Add-On",
        hotelCard_lblhasAddonTitle: "Add-On",
        hotelCard_lblhasMandatoryAddonTitle: "Obligatorisches Add-On",
        hotelCard_lblKontenjan: "Für die ausgewählten Reisedaten gibt es leider keine Verfügbarkeit.",
        hotelCard_lblLocationTitle: "Standort & Adressinformationen",
        hotelCard_lblMinimum: "Für die von Ihnen ausgewählten Datum ist eine Mindestanzahl von #WERT# Übernachtungen erforderlich.",
        hotelCard_lblMinimumStay: "Minimum #VALUE# Nächte.",
        hotelCard_lblNights: "Nacht",
        hotelCard_lblPartialPayment: "Anzahlung",
        hotelCard_lblQuota: "Für die ausgewählten Reisedaten gibt es leider keine Verfügbarkeit.",
        hotelCard_lblRecommended: "Empfehlung",
        hotelCard_lblRelease: "Für die ausgewählten Datum können keine Reservierungen vor dem Check-In #VALUE# Tage oder weniger vorgenommen werden.",
        hotelCard_lblSearchResultInspectFirst: "Suchergebnis #VALUE1# in Sekunden",
        hotelCard_lblSearchResultInspectSecond: "Es werden #VALUE1# Hotels von Anbietern aufgelistet.",
        hotelCard_lblSpecialCategory: "Sonderkategorie",
        hotelCard_lblStop: "Für die ausgewählten Reisedaten gibt es leider keine Verfügbarkeit.",
        hotelCard_noMatchingBoardType: "Für diese Auswahl konnte kein Preis ermittelt werden, bitte ändern Sie im Warenkorb die Verpflegungsart im.",
        hotelCard_room: "Zimmer",
        hotelCard_tabInfo: "Ausstattung & Lage",
        hotelCard_tabPhotoGallery: "Galerie",
        hotelCard_tabRooms: "Zimmerauswahl",
        hotelcheckout_addOnCardfree: "#AGE_RANGE# Altersspanne ist kostenlos",
        hotelcheckout_addOnCardWarning: "Kinder im Alter von #VALUE# dürfen nicht teilnehmen.",
        hotelCheckout_addOnInsert: "Add-On hinzufügen",
        hotelCheckout_addOnMandatory: "Erforderliches Add-On",
        hotelCheckout_addOnNonMandatory: "Nicht erforderliches Add-On",
        hotelCheckout_addOnReadLess: "weniger lesen",
        hotelCheckout_addOnReadMore: "Weiterlesen",
        hotelCheckout_addOnRemove: "Add-On entfernen",
        hotelCheckout_agencyExtraCCommission: "Zusätzliche Provision für Agenten hinzugefügt",
        hotelCheckout_cmbAgencyCommission: "Agenturprovision",
        hotelCheckout_lblAccomodationInfo: "Unterkunftsgebühr",
        hotelCheckout_lblAddOnTotalPrice: "Gebühr für Add-On",
        hotelCheckout_lblAgencyCommission: "Agenturprovision",
        hotelCheckout_lblAgencyExtraCommission: "Agentur-Extraprovision",
        hotelCheckout_lblBoardType: "Verpflegungsart",
        hotelCheckout_lblCheckin: "Anreise",
        hotelCheckout_lblCheckout: "Abreise",
        hotelCheckout_lblCityTax: "#VALUE1#€ City Tax kann im Hotel erhoben werden",
        hotelCheckout_lblContactInfo: "Kontaktinformationen:",
        hotelCheckout_lblEmail: "E-mail",
        hotelCheckout_lblGrandTotal: "Gesamtsumme",
        hotelCheckout_lblHotelAgentTerms: "Geschäftsbedingungen der Agentur",
        hotelCheckout_lblHotelAnd: "und",
        hotelCheckout_lblHotelDescriptions: "Der Check-In erfolgt um 14:00 Uhr und der Check-Out um 12:00 Uhr. Bitte informieren Sie uns, wenn Sie spät anreisen. Bei Buchungen, die am selben Tag und am Wochenende getätigt werden, fragen Sie bitte unser Support-Team nach Verfügbarkeit und bitten um eine erneute Bestätigung.",
        hotelCheckout_lblHotelDescriptionsAlert: "Gemäß § 7194, Artikel 52 Abs. 1 b) des türkischen StG, wird ab dem 01.01.2023 eine Bettensteuer für Hotelgäste eingeführt. Bei einigen Hotels ist diese Steuer bereits im Preis mitenthalten, bei anderen wiederum wird es bei der Anreise im Hotel vom Gast kassiert.\nWichtiger Hinweis: Da die Einführung erst neulich in Kraft getreten ist, ist es wichtig Ihre Gäste über die neue Verordnung zu informieren. Bei etwaigen Unstimmigkeiten übernimmt die GTS Global Travel Services GmbH keinerlei Haftung und behält sich alle Rechte vor.",
        hotelCheckout_lblHotelDescriptionsRateHawkMetaPolicy: "Gebühren und sonstige Informationen",
        hotelCheckout_lblHotelDescriptionsTitle: "Beschreibung:",
        hotelCheckout_lblHotelProviderInstructionsDescriptions: "Bitte überprüfen Sie die Beschreibungen der Anbieter, bevor Sie die Buchung bestätigen.",
        hotelCheckout_lblHotelProviderInstructionsTitle: "Anleitungen für Anbieter",
        hotelCheckout_lblHotelSupplierTerms: "Allgemeine Geschäftsbedingungen der Anbieter",
        hotelCheckout_lblHotelTerms: "Ich habe gelesen und akzeptiere die",
        hotelCheckout_lblLastBookingDate: "Gültig bis",
        hotelCheckout_lblLastBookingDateDescription: "Reservierungen die bis zum o. a.  Datum nicht gebucht werden, unterliegen automatischer Stornierung.",
        hotelCheckout_lblPartialPaymentAvailable: "Teilzahlungsbedingungen",
        hotelCheckout_lblPartialPaymentCancelInfo: "Reservierungen, deren Zahlung nicht bis zu den oben angegebenen Terminen erfolgt, werden automatisch storniert.",
        hotelCheckout_lblPartialPaymentCancellationTerms: "Stornierungsbedingungen",
        hotelCheckout_lblPartialPaymentCancellationTermsInfo: "Im Falle einer Stornierung der Reservierung gelten die folgenden Strafbestimmungen.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText: "Bis zum #VALUE# wird keine Strafgebühr #VALUE2# für die Stornierung erhoben.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText1: "Bis zum #VALUE# wird keine #VALUE1#Strafgebühr#VALUE2# für die Stornierung erhoben.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText2: "Bis #VALUE1# wird für die Stornierung eine Strafgebühr von #VALUE2# erhoben.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText2Revize: "Im Falle einer Stornierung dieser Reservierung werden die Anzahlung und die Restzahlung nicht zurückerstattet.",
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText3: "Bis #VALUE1# wird für die Stornierung eine Strafgebühr von #VALUE2# erhoben.",
        hotelCheckout_lblPartialPaymentDeposit: "Als Anzahlung",
        hotelCheckout_lblPartialPaymentFirstDeposit: "Die Anzahlung von #VALUE1# für die gewählte Reise muss bis #VALUE2# bezahlt werden.",
        hotelCheckout_lblPartialPaymentFirstPayment: "Anzahlung",
        hotelCheckout_lblPartialPaymentInfo: "Teilzahlung İnformation",
        hotelCheckout_lblPartialPaymentPaymentInfo: "Für diese Transaktion können Sie die Anzahlungsmethode verwenden. Hierbei zahlen Sie zunächst nur den Anzahlungsbetrag.",
        hotelCheckout_lblPartialPaymentReady: "Diese Transaktion ist zur Teilzahlung geeignet.",
        hotelCheckout_lblPartialPaymentRefundDisabled: "Die Anzahlung ist nicht erstattungsfähig.",
        hotelCheckout_lblPartialPaymentRemainingPayment: "Restbetrag der Gesamtzahlung",
        hotelCheckout_lblPartialPaymentRemainingPaymentInfo: "Der Restbetrag für die ausgewählte Unterkunft #VALUE1#, muss bis #VALUE2# bezahlt werden.",
        hotelCheckout_lblPartialPaymentSecondPayment: "Restzahlung",
        hotelCheckout_lblPartialPaymentUsePartialPayment: "Teilzahlung aktiv",
        hotelCheckout_lblPartialPaymentUsePartialPaymentAvailableInfo: "Für diese Transaktion können Sie die Teilzahlungsart nutzen. Für Teilzahlungen benötigen wir Ihre Bestätigung.",
        hotelCheckout_lblPartialPaymentUsePartialPaymentDeactive: "Nutzen Sie die Teilzahlung und zahlen Sie den Anzahlungsbetrag",
        hotelCheckout_lblproviderTotal: "Teilsumme",
        hotelCheckout_lblRoomInfo: "Zimmerinformation",
        hotelCheckout_lblRoomName: "Zimmertyp",
        hotelCheckout_lblRoomTotal: "Gesamt",
        hotelCheckout_lblTax: "Tax",
        hotelCheckout_lblTaxUS: "Sales Tax",
        hotelCheckout_lblTotalPrice: "Gesamtgebühr",
        hotelCheckout_lblTotalTax: "Steuern",
        hotelCheckout_PartialCancellationContentText: "Im Falle einer Stornierung dieser Reservierung werden die Anzahlung und der Restbetrag nicht zurückerstattet.",
        hotelCheckoutBasket_partialPaymentControl: "Ich möchte eine Teilzahlung leisten, indem ich eine Anzahlung in Höhe von #VALUE# Euro leiste.",
        hotelCheckoutBasket_txtBoardType: "Verpflegungsart",
        hotelCheckoutBasket_txtNight: "Nächte",
        hotelCheckoutBasket_txtRoom: "#VALUE# Zimmer",
        hotelCheckoutPage_contactInfo_title: "Kontaktinformationen:",
        hotelCheckoutPage_descriptions_alert_text: "Für Buchungen, die am selben Tag und am Wochenende gebucht werden, fragen Sie bitte unser Support-Team nach Verfügbarkeit und bitten um eine erneute Bestätigung.",
        hotelCheckoutPage_descriptions_hotelNote_text: "Der Check-In erfolgt um 14:00 Uhr und der Check-Out um 12:00 Uhr. Bitte informieren Sie uns, wenn Sie spät anreisen. Bei Buchungen, die am selben Tag und am Wochenende getätigt werden, fragen Sie bitte unser Support-Team nach Verfügbarkeit und bitten um eine erneute Bestätigung.",
        hotelCheckoutPage_descriptions_title: "Beschreibung",
        hotelCheckoutPage_roomInfo_agencyCommission_label: "Agenturprovision",
        hotelCheckoutPage_roomInfo_agencyExtraCommission_label: "Agentur-Extraprovision",
        hotelCheckoutPage_roomInfo_title: "Zimmerinformation",
        hotelCheckoutPage_roomInfoCard_lastBookingDate_alertDescriptionText: "Reservierungen die bis zum o. a. Datum nicht gebucht werden, unterliegen automatischer Stornierung.",
        hotelCheckoutPage_roomInfoCard_lastBookingDate_alertText: "Gültig bis",
        hotelCheckoutPage_roomInformation_roomBoardLabel: "Verpflegungsart",
        hotelCheckoutPage_roomInformation_roomNameLabel: "Zimmertyp",
        hotelCheckoutPage_roomRestrictionInfo: "Dieser Zimmerpreis gilt für {adultCount}{adultFmt}{adultPluralFmt}{and}{childCount}{childFmt}{childPluralFmt}.",
        hotelCheckoutPageRH_children: "Gebühr für Kinder",
        hotelCheckoutPageRH_childrenMeal: "Kinder Mahlzeit",
        hotelCheckoutPageRH_cot: "Babybett",
        hotelCheckoutPageRH_deposit: "Kaution",
        hotelCheckoutPageRH_extraBed: "Zusatzbett",
        hotelCheckoutPageRH_internet: "Internet",
        hotelCheckoutPageRH_meal: "Mahlzeit",
        hotelCheckoutPageRH_noShow: "No-Show",
        hotelCheckoutPageRH_parking: "Parkplatz",
        hotelCheckoutPageRH_pets: "Haustier",
        hotelCheckoutPageRH_shuttle: "Shuttle",
        hotelCheckoutPageRH_visa: "Visa",
        hotelFacilitiesModal_lblTitle: "Einrichtungen",
        hotelFilter_btnApplyAndClose: "Anwenden und Abschließen",
        hotelFilter_btnClearAll: "Löschen",
        hotelFilter_btnSelectAll: "Alle Auswählen",
        hotelFilter_btnShowAll: "Alle Optionen Anzeigen",
        hotelFilter_btnShowLess: "Weniger Optionen Anzeigen",
        hotelFilter_btnShowMore: "Weitere Optionen anzeigen",
        hotelFilter_lblAnd: "und",
        hotelFilter_lblBoardType: "Verpflegung",
        hotelFilter_lblBoutiqueHotel: "Boutique Hotel",
        hotelFilter_lblClassTitle: "Sterne",
        hotelFilter_lblDiscountedHotel: "Rabatt",
        hotelFilter_lblFacilityModalCancel: "Storniert",
        hotelFilter_lblFacilityModalClose: "SCHLIEßEN",
        hotelFilter_lblFacilityModalSave: "Speichern",
        hotelFilter_lblFacilityTitle: "Einrichtungen",
        hotelFilter_lblFilterByDefinitions: "Filter nach Definitionen",
        hotelFilter_lblFilterByStars: "Filter nach Stern",
        hotelFilter_lblFilterPartialPayment: "Anzahlung",
        hotelFilter_lblFilterText: "Hotels mit #VALUE1# #VALUE2# #VALUE3# #VALUE4# #VALUE5# #VALUE6# werden gefiltert.",
        hotelFilter_lblhassAddOn: "Add-On",
        hotelFilter_lblInfoTitle: "Filtern nach",
        hotelFilter_lblPartialPayment: "Anzahlung",
        hotelFilter_lblPriceTitle: "Budget (Gesamtpreis)",
        hotelFilter_lblRecommendedHotel: "Empfehlung",
        hotelFilter_lblSearchHotel: "Hotel suchen",
        hotelFilter_lblSelectedClassCount: "Sterne ausgewählt",
        hotelFilter_lblSpecialCategory: "Sonderkategorie",
        hotelFilter_lblStars: "stern",
        hotelOrderDetail_gtsDirect: "Veranstalter GTS",
        hotelOrderDetail_lblAccomodationInfo: "Unterkunftsgebühr",
        hotelOrderDetail_lblAgencyCommission: "Agentur-Provision",
        hotelOrderDetail_lblBaseTotal: "Nettopreis",
        hotelOrderDetail_lblGrandTotal: "Gesamtsumme",
        hotelOrderDetail_lblPackageGrandTotal: "Pauschalreise-Gesamtsumme",
        hotelOrderDetail_lblproviderTotal: "Teilsumme",
        hotelOrderDetail_lblReverseTotal: "Rückerstattungsbetrag",
        hotelOrderDetail_lblSalesTotal: "Gesamtumsatz",
        hotelOrderDetail_lblTax: "Tax",
        hotelOrderDetail_lblTaxUS: "Sales Tax",
        hotelOrderDetail_lblTotalPrice: "Gesamtgebühr",
        hotelRoomCard_lblDetailHighlightedFeatures: "Besondere Zimmermerkmale",
        hotelRoomCard_lblDetailRoomFeatures: "Zimmerausstattung",
        hotelRoomCard_lblDetailRoomInformations: "Zimmerinfo",
        hotelRoomCard_lblDetailRoomOptions: "Zimmeroptionen",
        hotelRoomCard_lblDetailSeeDetails: "Zimmerdetail anzeigen…",
        hotelRoomCard_lblKontenjan: "Keine Verfügbarkeit!",
        hotelRoomCard_lblLoadMore: "Mehr anzeigen",
        "hotelRoomCard_lblMinimum Konaklama": "Minimum #VALUE# Nächte.",
        "hotelRoomCard_lblMinimum..": "minimum #VALUE# Nächte",
        hotelRoomCard_lblMinimumKonaklama: "Minimum #VALUE# Nächte.",
        hotelRoomCard_lblMinimumStay: "minimum #VALUE# Nächte",
        hotelRoomCard_lblQuota: "Keine Verfügbarkeit!",
        hotelRoomCard_lblRelease: "Keine Verfügbarkeit!",
        hotelRoomCard_lblRoomCountTxt: "Zimmer",
        hotelRoomCard_lblStop: "Keine Verfügbarkeit!",
        hotelRoomDetail_lblAddOnInfoDisabled: "#AGE_RANGE# Altersspanne kann nicht teilnehmen",
        hotelRoomDetail_lblAddOnInfoFree: "#AGE_RANGE# Altersspanne ist kostenlos",
        hotelSalesReport_agencyCommission: "Agenturprovision",
        hotelSalesReport_filter_btnList: "Auflisten",
        hotelSalesReport_filter_lblFromDate: "Startdatum",
        hotelSalesReport_filter_lblReportType: "Berichtsart",
        hotelSalesReport_filter_lblStatus: "Status",
        hotelSalesReport_filter_lblToDate: "Enddatum",
        hotelSalesReport_fromDate: "Startdatum",
        hotelSalesReport_grandTotal: "Gesamt",
        hotelSalesReport_guestCount: "Anzahl der Gäste",
        hotelSalesReport_reportType: "Berichtsart",
        hotelSalesReport_roomCount: "Anzahl der Zimmer",
        hotelSalesReport_saleAmount: "Verkaufsbetrag",
        hotelSalesReport_tblMain_tdChannelGts: "Veranstalter GTS",
        hotelSalesReport_tblMain_tdChannelProvider: "Provider",
        hotelSalesReport_tblMain_thAgencyCommission: "Agenturprovision",
        hotelSalesReport_tblMain_thAgencyName: "Agentur",
        hotelSalesReport_tblMain_thCheckinDate: "Anreisedatum",
        hotelSalesReport_tblMain_thCheckoutDate: "Abreisedatum",
        hotelSalesReport_tblMain_thContactName: "Vorname",
        hotelSalesReport_tblMain_thContactSurname: "Nachname",
        hotelSalesReport_tblMain_thGuestCount: "Gäste",
        hotelSalesReport_tblMain_thHotelLocation: "Lage",
        hotelSalesReport_tblMain_thHotelName: "Hotelname",
        hotelSalesReport_tblMain_thOnUsChannel: "Kanal",
        hotelSalesReport_tblMain_thOptionDaysLeft: "Verbleibende Optionsfrist",
        hotelSalesReport_tblMain_thOrderId: "Order ID",
        hotelSalesReport_tblMain_thRoomCount: "Zimmer",
        hotelSalesReport_tblMain_thSaleDateTime: "Buchungsdatum",
        hotelSalesReport_tblMain_thTotal: "Gesamt",
        hotelSalesReport_tblMain_thVocuherStatus: "Status",
        hotelSalesReport_tblMain_thVoucher: "Voucher",
        hotelSalesReport_tblMain_thVoucherStatus: "Status",
        hotelSalesReport_title: "Hotelverkaufsbericht",
        hotelSalesReport_toDate: "Enddatum",
        hotelSalesReport_totalAgencyCommission: "Agenturprovision Gesamt",
        hotelSalesReport_totalGuestCount: "Gäste Gesamt",
        hotelSalesReport_totalRoomCount: "Zimmer Gesamt",
        hotelSalesReport_totalVoucherCount: "Voucher Gesamt",
        hotelSalesReport_voucherCount: "Anzahl Voucher",
        HotelSalesSummaryReportList_thAgencyId: "Agentur-ID",
        hotelSearch_btnSearch: "HOTEL SUCHEN",
        hotelSearch_btnShowFilter: "Filter",
        hotelSearch_lblDate: "Anreise - Abreise",
        hotelSearch_lblDestination: "Reiseziel / Hotel",
        hotelSearch_lblEmptyFilterResult: "Bitte überprüfen Sie Ihre Filter, es wurden keine Ergebnisse entsprechend Ihrer Auswahlkriterien gefunden.",
        hotelSearch_lblEmptyHotelList: "Es wurde kein Hotel gefunden, dass Ihren Suchkriterien entspricht. Bitte versuchen Sie es erneut, indem Sie Ihre Suchkriterien ändern.",
        hotelSearch_lblFilterClearAll: "Alle filter löschen !",
        hotelSearch_lblFilterStatus: "Filter :",
        hotelSearch_lblFilterStatusOff: ".",
        hotelSearch_lblFilterStatusOn: ".",
        hotelSearch_lblGuest: "Zimmer und Gäste",
        hotelSearch_lblNationality: "Abreiseland",
        hotelSearch_lblNoFoundedHotels: "Kein Hotel mit diesen Suchkriterien gefunden.",
        hotelSearch_lblNonRecommendedHotels: "Insgesamt wurden #VALUE1# Hotels gelistet.",
        hotelSearch_lblOnlyGtsDirect: "Filtern nach GTS-Vertrags-Hotels!",
        hotelSearch_lblTotalHotelNumber: "Insgesamt wurden #VALUE2# Hotels gelistet, von denen #VALUE1# empfohlen wurden.",
        hotelSearchForm_lblArea: "Vorwahl",
        hotelSearchForm_lblCode: "Land",
        hotelSearchForm_lblMail: "E-mail",
        hotelSearchGuest_btnRemoveRoom: "Zimmer Entfernen",
        hotelSearchGuest_lblAdult: "Erwachsene",
        hotelSearchGuest_lblChild: "Kinder",
        hotelSearchGuest_lblChildAge: "Alter des Kindes",
        hotelSearchGuest_lblRoomCount: "Zimmer",
        hotelSearchGuest_phAdultCount: "Erwachsene",
        hotelSearchGuest_phChildCouunt: "Kinder",
        hotelSearchGuest_phRoomCount: "Zimmer",
        hotelSearchInformation_adult: "Erwachsene",
        hotelSearchInformation_child: ", #COUNT# Kind",
        hotelSearchInformation_data: "Zimmer",
        hotelSearchInformation_date1: "Check-In",
        hotelSearchInformation_date2: "Check-Out",
        hotelSearchInformation_footer: "#DAY#  Tage und #NIGHT# Nächte",
        hotelSearchInformation_location: "Lage",
        hotelSearchInformation_rooms: "Zimmer",
        hotelSearchInformation_text: "Auf der Suche nach den besten Preisen, bitte warten!",
        hotelSearchResultPage_hotelCard_informationTab_hotelDescription_title: "Hotelbeschreibung",
        hotelSearchResultPage_hotelCard_informationTab_hotelMap_title: "Standort & Adressinformationen",
        invoiceList_filter_btnList: "Auflisten",
        invoiceList_filter_lblFromDate: "Startdatum",
        invoiceList_filter_lblOrderId: "Order ID",
        invoiceList_filter_lblPnr: "Pnr",
        invoiceList_filter_lblToDate: "Enddatum",
        invoiceList_filter_phOrderId: "Order ID",
        invoiceList_filter_phPnr: "Pnr",
        invoiceList_info_FCADOM: "Flug Inl. / Rechnung / MCO",
        invoiceList_info_FCAINT: "Flug Ausl. / Rechnung / MCO",
        invoiceList_info_FCCDOM: "Flug Inl. / Rechnung / Kreditkarte",
        invoiceList_info_FCCINT: "Flug Ausl. / Rechnung / Kreditkarte",
        invoiceList_info_FCODOM: "Flug Inl. / Rechnung / Kreditkartenprovision",
        invoiceList_info_FCOINT: "Flug Int. / Rechnung / Kreditkartenprovision",
        invoiceList_info_HCADOM: "Hotel  Inl. / Rechnung / MCO",
        invoiceList_info_HCAINT: "Hotel Ausl. / Rechnung / MCO",
        invoiceList_info_HCCDOM: "Hotel Inl. / Rechnung / Kreditkarte",
        invoiceList_info_HCCINT: "Hotel Ausl. / Rechnung / Kreditkarte",
        invoiceList_info_HCODOM: "Hotel Inl. / Rechnung / Kreditkartenprovision",
        invoiceList_info_HCOINT: "Hotel Int. / Rechnung / Kreditkartenprovision",
        invoiceList_info_RCAINT: "Rent a Car Ausl. / Rechnung / MCO",
        invoiceList_info_RCCINT: "Rent a Car Ausl. / Rechnung / Kreditkarte",
        invoiceList_info_RCOINT: "Rent a Car Ausl. / Rechnung / Kreditkartenprovision",
        invoiceList_info_TCAINT: "Transfer Ausl. / Rechnung / MCO",
        invoiceList_info_TCCINT: "Transfer Ausl. / Rechnung / Kreditkarte",
        invoiceList_info_TCOINT: "Transfer Int. / Rechnung / Kreditkartenprovision",
        invoiceList_tblMain_thGrandTotal: "Gesamtsumme",
        invoiceList_tblMain_thInvoiceDate: "Rechnungsdatum",
        invoiceList_tblMain_thInvoiceId: "ID",
        invoiceList_tblMain_thInvoiceInfo: "Beschreibung",
        invoiceList_tblMain_thInvoiceNote: "Notiz",
        invoiceList_tblMain_thInvoiceNumber: "Rechnungsnummer",
        invoiceList_tblMain_thInvoiceTotal: "Gesamtsumme",
        invoiceList_title: "Rechnungsliste",
        invoiceListPage_invoiceListDataTable_invoiceDateLabel: "Rechnungsdatum",
        invoiceListPage_invoiceListDataTable_invoiceNumberLabel: "Rechnungsnummer",
        invoiceListPage_pageTitle: "Rechnungsliste",
        invoiceListPage_searchForm_endDateLabel: "Enddatum",
        invoiceListPage_searchForm_orderIdLabel: "Order ID",
        invoiceListPage_searchForm_pnrLabel: "Pnr",
        invoiceListPage_searchForm_startDateLabel: "Startdatum",
        languageFullName_de: "Deutsch",
        languageFullName_en: "Englisch",
        languageFullName_ru: "Russisch",
        languageFullName_tr: "Türkisch",
        legInformation_lblEdit: "Informationen Bearbeiten",
        legInformation_titleLegPriceInfos: "Preisinformationen",
        legInformation_titleLegs: "Routeninformationen",
        loginPage_resetPasswordSuccessPopupText: "Als Ergebnis dieser Aktion senden wir Ihnen eine E-Mail zum Zurücksetzen. Bestätigen ?",
        MasterAgency_returnTobeGsa: "Sie wird auf die Gesamtgebühr hinzugerechnet.",
        multiSelectComponent_itemSelected: "Elemente ausgewählt",
        navbarComponent_switchToDarkMode: "Dunkler Modus",
        navbarComponent_switchToLightMode: "Heller Modus",
        notesComp_notTime: "Zeit, sich Notizen zu machen",
        notesComponent_infoAlert_text: "Die in diesem Feld eingegebenen Notizen können nur von Ihnen eingesehen werden. Bitte verwenden Sie den Service-Center-Anfragebildschirm, um eine Anfrage zu stellen.",
        NotificationComponent_btnAllowNotification: "Öffnen Sie die Benachrichtigungen",
        NotificationComponent_deleteAllNotificationConfirm: "Alle Benachrichtigungen werden gelöscht. Bestätigen Sie?",
        NotificationComponent_deleteNotificationConfirm: "Die Benachrichtigung wird gelöscht. Bestätigen Sie?",
        NotificationComponent_emptyOlder: "Sie haben keine Benachrichtigungen Älter als eine Woche.",
        NotificationComponent_emptyToday: "Sie haben für heute keine Benachrichtigungen",
        NotificationComponent_emptyWeek: "Sie haben keine Benachrichtigungen seit einer Woche.",
        NotificationComponent_emptyYesterday: "Sie haben keine Benachrichtigungen für gestern.",
        NotificationComponent_markAsRead: "Alle gelesen",
        NotificationComponent_notificationAllowed: "Benachrichtigungen erlaubt",
        NotificationComponent_notificationNotAllowed: "Benachrichtigungen nicht Erlaubt",
        NotificationComponent_notificationPage: "Alles Sehen",
        NotificationComponent_notificationSettings: "Benachrichtigungseinstellungen",
        NotificationComponent_notificationsHeader: "Benachrichtigungen",
        NotificationComponent_notificationStatus: "Benachrichtigungsstatus",
        NotificationComponent_older: "Älter",
        NotificationComponent_readAllConfirm: "Alle Benachrichtigungen werden als gelesen markiert. Bestätigen Sie?",
        NotificationComponent_removeAll: "Alles löschen",
        NotificationComponent_today: "Heute",
        NotificationComponent_week: "Woche",
        NotificationComponent_yesterday: "Gestern",
        onBehalfOfComponent_agency: "Agentur",
        onBehalfOfComponent_gsa: "Gsa",
        onBehalfOfComponent_lblAgency: "Agentur",
        onBehalfOfComponent_lblGsa: "Gsa",
        onBehalfOfComponent_phAgency: "Suche",
        onBehalfOfComponent_phSelectAgency: "Bitte Agentur auswählen.",
        onBehalfOfComponent_placeholder: "Suche Agentur",
        orderDetail_addOnReadLess: "Weniger anzeigen",
        orderDetail_addOnReadMore: "Weiterlesen",
        orderDetail_btnBookingConfirmation: "Buchungsbestätigung",
        orderDetail_btnEticket: "E-Ticket",
        OrderDetail_btnGetReverseInfo: "Erstattungsbetrag berechnen",
        orderDetail_btnReReserve: "Aktuellen Preis prüfen und erneut reservieren",
        orderDetail_btnReReserve_lblDefault: "Neue Reservierung durchführen",
        orderDetail_btnReReserve_lblRezerve: "Aktuellen Preis abfragen",
        orderDetail_btnReserved: "Reserviert",
        orderDetail_btnRnr: "RNR",
        orderDetail_btnRnrProvider: "Anbieter RNR",
        orderDetail_btnRnrProviderBookingDoc: "Vermittlervereinbarung",
        orderDetail_btnTnr: "TNR",
        orderDetail_btnVoucher: "Voucher",
        orderDetail_exOrderId: "Alte Order ID",
        orderDetail_gtsDirect: "Veranstalter GTS",
        orderDetail_lblAccommodationDate: "Reisedatum",
        orderDetail_lblAccommodationInfoTitle: "Reiseinformationen",
        orderDetail_lblAddon: "Add-On",
        orderDetail_lblAddonInfoTitle: "Add-On Informationen",
        orderDetail_lblAddonPrice: "Add-On Preis",
        orderDetail_lblAdress: "Address",
        orderDetail_lblAdultCount: "Anzahl Erwachsener",
        orderDetail_lblAgencyCommission: "Agenturprovision",
        orderDetail_lblAgencyId: "Agentur-ID",
        orderDetail_lblAgencyName: "Agenturname",
        orderDetail_lblAgencyPrice: "Nettopreis der Agentur",
        orderDetail_lblarrivalFlightDateTime: "Ankunftszeit",
        orderDetail_lblArrivalFlightNumber: "Abreise Flugnumme",
        orderDetail_lblBaseAmount: "Nettopreis",
        orderDetail_lblBirthdate: "Geburtsdatum",
        orderDetail_lblBoardType: "Verpflegungsart",
        orderDetail_lblBookingDate: "Buchungsdatum",
        orderDetail_lblCheckinDate: "Check In Datum",
        orderDetail_lblCheckoutDate: "Check Out Datum",
        orderDetail_lblChildAges: "Alter der Kinder",
        orderDetail_lblChildCount: "Anzahl der Kinder",
        orderDetail_lbldepartureFlightDateTime: "Abflugzeit",
        orderDetail_lblDepartureFlightNumber: "Abfahrt Flugnumme",
        orderDetail_lblDriverInfoTitle: "Fahrerinformationen",
        orderDetail_lblEmail: "E-mail",
        orderDetail_lblEventDate: "Veranstaltungsdatum",
        orderDetail_lblFlightNumber: "Flugnummer",
        orderDetail_lblFromDestination: "Abreise (A)",
        orderDetail_lblGender: "Geschlecht",
        orderDetail_lblGrandTotal: "Gesamtbetrag",
        orderDetail_lblGuestType: "Art",
        orderDetail_lblHotelName: "Hotelname",
        orderDetail_lblLastBookingDate: "Gültig bis",
        orderDetail_lblLastTicketDate: "Gültig bis",
        orderDetail_lblLastVoidDate: "Letztes Kostenloses Stornierungsdatum",
        orderDetail_lblLegIndex: "Sequenz Nr.",
        orderDetail_lblLegStatus: "Status",
        orderDetail_lblName: "Vor- und Nachname",
        orderDetail_lblNameSurname: "Vor- und Nachname",
        orderDetail_lblNationality: "Staatsangehörigkeit",
        orderDetail_lblOrderId: "Order ID",
        orderDetail_lblOrderStatus: "Status",
        orderDetail_lblPackageProviderSchimetterling: "SCHMETTERLING",
        orderDetail_lblPartialPaymentLastBookingDate: "Frist für die Anzahlung",
        orderDetail_lblPartialPaymentLastCancelDate: "Letzte Stornierungsdatum",
        orderDetail_lblPartialPaymentLastVoidDate: "Fälligkeitsdatum der Restzahlung",
        orderDetail_lblPartialPaymentVoidDate: "Stornierungs-Rückerstattungszeit",
        orderDetail_lblPassengerEmail: "Passagier-E-Mail",
        orderDetail_lblPassengerInfoRoomType: "Zimmertyp",
        orderDetail_lblPassengerInfoTitle: "Gästeinformation",
        orderDetail_lblPassengerPhone: "Passagier-Telefon",
        orderDetail_lblPhoneNumber: "Nummer",
        orderDetail_lblPlannedPickupDateTime: "Datum und Uhrzeit der Abholung",
        orderDetail_lblPriceInfoTitle: "Preisinformationen",
        orderDetail_lblProvider: "Anbietername",
        orderDetail_lblProviderRnr: "Anbieter RNR",
        orderDetail_lblRentACarInfoTitle: "Rent A Car İnformation",
        orderDetail_lblReservationWarnings: "Informationen über den Treffpunkt am Flughafen oder die Abholzeit im Hotel werden dem Passagier einen Tag vorher per Whatsapp-Nachricht auf sein Handy geschickt.",
        orderDetail_lblReserveDate: "Reservierungsdatum",
        orderDetail_lblReverseDate: "Stornierungsdatum",
        orderDetail_lblRnr: "RNR",
        orderDetail_lblRnrStatus: "Status",
        orderDetail_lblRoomInformations: "Zimmerinformationen",
        orderDetail_lblRoomType: "Zimmertyp",
        orderDetail_lblTicketDate: "Erstellungszeitpunkt",
        orderDetail_lblTnr: "TNR",
        orderDetail_lblTnrStatus: "Status",
        orderDetail_lblToDestination: "Abreise (A)",
        orderDetail_lblTotal: "Gesamt",
        orderDetail_lblTotalPrice: "Gesamtgebühr",
        orderDetail_lblTransferInfoTitle: "Transferinformationen",
        orderDetail_lblTravelerInfoTitle: "Informationen für Reisende",
        orderDetail_lblUserEmail: "Benutzer-E-mail",
        orderDetail_lblUserPhone: "Benutzer-Telefon",
        orderDetail_lblVehicle: "Autotyp",
        orderDetail_lblVehicleTypeInfo: "Markenmodell",
        orderDetail_lblViewAddon: "Add-on anzeigen",
        orderDetail_lblVoucher: "Voucher",
        orderDetail_lblVoucherId: "GTS Voucher Id",
        orderDetail_lblVoucherStatus: "Status",
        orderDetail_PartialCancellationContentText1: "Im Falle einer Stornierung der Reservierung werden die folgenden Strafen erhoben.",
        orderDetail_PartialCancellationContentText2: "Im Falle einer Stornierung dieser Reservierung werden die Anzahlung und der Restbetrag nicht zurückerstattet.",
        orderDetail_PartialCancellationContentText3: "Eine #VALUE# Strafe wird angewendet, bis #VALUE1#",
        orderDetail_PartialCancellationContentText4: "Es gibt keine Rückerstattung für den bis #VALUE1# gezahlten Reservierungsbetrag.",
        orderDetail_PartialCancellationPolicy: "Stornierungsbedingungen",
        orderDetail_PartialPaymentDeadLineBody: "Wird die Anzahlung in Höhe von #VALUE# Euro nicht bis zum oben genannten Datum geleistet, wird die Reservierung storniert.",
        orderDetail_PartialPaymentDeadLineTitle: "Fälligkeitsdatum der Anzahlung:",
        orderDetail_partialPaymentRemainingPayment: "Restzahlung",
        orderDetail_partialPaymentRemainingText: "Die Restzahlung in Höhe von #VALUE# muss bis #VALUE1# erfolgen.",
        orderDetail_partialPaymentRemainingTitle: "Für diese Bestellung wurde eine Anzahlung geleistet.",
        orderDetail_passengerInformations_ancillaryTotal: "Zusatzleistungen Gesamt",
        orderDetail_passengerInformations_grandTotal: "Gesamtsumme",
        orderDetail_passengerInformations_paymentFreeTotal: "Transaktionsgebühr für Kreditkarten",
        orderDetail_passengerInformations_reissueTotal: "Umbuchungsgebühr",
        orderDetail_passengerInformations_reverseTotal: "Gesamterstattung",
        orderDetail_passengerInformations_salesTotal: "Gesamtumsatz",
        orderDetail_provider: "Provider",
        "OrderDetail_tblPass.thDate": "Datum",
        "OrderDetail_tblTnrLegs.thArrivalFlightNumber": "Abreise Flugnumme",
        "OrderDetail_tblTnrLegs.thLegIndex": "Sequenz Nr.",
        orderDetail_thAgencyId: "Agentur-ID",
        orderDetail_thAgencyName: "Agenturname",
        orderDetail_thAirlinePnr: "Fluggesellschaft Pnr",
        orderDetail_thLastPurchaseDateTime: "Gültig bis",
        orderDetail_thOrderId: "Order ID",
        orderDetail_thPassengerEmail: "Passagier-E-Mail",
        orderDetail_thPassengerPhone: "Passagier-Telefon",
        orderDetail_thPnr: "Pnr",
        orderDetail_thProvider: "Anbietername",
        orderDetail_thReservationDateTime: "Reservierungsdatum",
        orderDetail_thStatus: "Status",
        orderDetail_thStatusLabel: "Status",
        orderDetail_thTicketDateTime: "Ticketerstellungsdatum",
        orderDetail_thUserEmail: "Benutzer-E-mail",
        orderDetail_thUserPhone: "Benutzer-Telefon",
        orderDetail_titleReservationInfo: "Reservierungsinformationen",
        orderDetail_transferDate: "Transfer Datum",
        orderDetailFlight_txtTaskMessage: "#PNRID# nolu pnr #ORDERID# nolu order cancel edilemedi! #TASKID# üzerinden task açıldı.",
        orderDetailPage_baggage: "Gepäck",
        orderDetailPage_date: "Datum",
        orderDetailPage_eticket: "E-Ticket",
        orderDetailPage_fare: "Fare",
        orderDetailPage_flightInformation: "Fluginformationen",
        orderDetailPage_grandTotal: "Gesamtbetrag",
        orderDetailPage_lblBirthDate: "Geburtstag",
        orderDetailPage_nameSurname: "Vor- und Nachname",
        orderDetailPage_orderSummaryHeader_agencyIdLabel: "Agentur-ID",
        orderDetailPage_orderSummaryHeader_agencyNameLabel: "Agenturname",
        orderDetailPage_orderSummaryHeader_agencyPhoneLabel: "Agentur Telefon",
        orderDetailPage_orderSummaryHeader_contactEmailLabel: "Passagier-E-Mail",
        orderDetailPage_orderSummaryHeader_contactPhoneLabel: "Passagier-Telefon",
        orderDetailPage_orderSummaryHeader_orderIdLabel: "Order ID",
        orderDetailPage_orderSummaryHeader_passengerEmailLabel: "Passagier-E-Mail",
        orderDetailPage_orderSummaryHeader_passengerPhoneLabel: "Passagier-Telefon",
        orderDetailPage_orderSummaryHeader_statusLabel: "Status",
        orderDetailPage_orderSummaryHeader_userEmailLabel: "Benutzer-E-mail",
        orderDetailPage_orderSummaryHeader_userPhoneLabel: "Benutzer-Telefon",
        orderDetailPage_passengerInformation: "Passagierinformationen",
        orderDetailPage_passengerInformation_sectionTitle: "Gästeinformationen",
        orderDetailPage_passengerSummaryHeader_roomTypeLabel: "Zimmertyp",
        orderDetailPage_pnrSummaryHeader_airlinePnr: "Fluggesellschaft Pnr",
        orderDetailPage_pnrSummaryHeader_lastPurchaseTime: "Gültig bis",
        orderDetailPage_pnrSummaryHeader_provider: "Anbietername",
        orderDetailPage_pnrSummaryHeader_reservationInformations: "Reservierungsinformationen",
        orderDetailPage_pnrSummaryHeader_reservationTime: "Reservierungsdatum",
        orderDetailPage_pnrSummaryHeader_status: "Status",
        orderDetailPage_pnrSummaryHeader_ticketDateTime: "Ticketerstellungsdatum",
        orderDetailPage_priceInformation_sectionTitle: "Preisinformationen",
        orderDetailPage_priceSummaryHeader_accommodationDateLabel: "Reisedatum",
        orderDetailPage_priceSummaryHeader_agencyCommissionLabel: "Agenturprovision",
        orderDetailPage_priceSummaryHeader_agencyNetPriceLabel: "Nettopreis der Agentur",
        orderDetailPage_priceSummaryHeader_roomTypeLabel: "Zimmertyp",
        orderDetailPage_priceSummaryHeader_totalPriceLabel: "Gesamt",
        orderDetailPage_serviceFee: "T.Service  Gebühr",
        orderDetailPage_status: "Status",
        orderDetailPage_tax: "Steuern",
        orderDetailPage_voucherInformation_sectionTitle: "Unterkunftsinformationen",
        orderDetailPage_voucherRoomTypeHeader_adultNumberLabel: "Anzahl Erwachsener",
        orderDetailPage_voucherRoomTypeHeader_boardTypeLabel: "Verpflegungsart",
        orderDetailPage_voucherRoomTypeHeader_childrenAgeLabel: "Alter der Kinder",
        orderDetailPage_voucherRoomTypeHeader_childrenNumberLabel: "Anzahl der Kinder",
        orderDetailPage_voucherRoomTypeHeader_roomTypeLabel: "Zimmertyp",
        orderDetailPage_voucherSummaryHeader_checkInLabel: "Check In Datum",
        orderDetailPage_voucherSummaryHeader_checkOutLabel: "Check Out Datum",
        orderDetailPage_voucherSummaryHeader_hotelNameLabel: "Hotelname",
        orderDetailPage_voucherSummaryHeader_lastBookingDateTimeLabel: "Gültig bis",
        orderDetailPage_voucherSummaryHeader_lastVoidDateTimeLabel: "Letztes Kostenloses Stornierungsdatum",
        orderDetailPage_voucherSummaryHeader_providerLabel: "Anbietername",
        orderDetailPage_voucherSummaryHeader_reserveDateTimeLabel: "Reservierungsdatum",
        orderDetailPage_voucherSummaryHeader_reverseDateTimeLabel: "Stornierungsdatum",
        orderDetailPage_voucherSummaryHeader_statusLabel: "Status",
        orderDetailPage_voucherSummaryHeader_totalPriceLabel: "Gesamt",
        orderDetailPage_voucherSummaryHeader_voucherLabel: "Voucher",
        OrderDetailPayment_btnReverse: "Kostenfrei Stornieren",
        OrderDetailPayment_NOT_SALES_OFFICE: "Es ist kein Verkaufsbüro ausgewählt. Bitte überprüfen Sie Ihren Benutzer oder Agenten.",
        OrderDetailPayment_popup_EXCEPTION: "Ein unbekannter Fehler ist aufgetreten.",
        OrderDetailPayment_popup_FAILURE: "Nicht Erfolgreich!",
        OrderDetailPayment_popup_FAILURE_VOUCHER_NOT_REVERSABLE: "Diese Reservierung ist nicht erstattungsfähig.",
        OrderDetailPayment_popup_REJECTED: "Ein Fehler ist aufgetreten.",
        OrderDetailPayment_popup_REJECTED_INSUFFICIENT_PAYMENT: "Sie haben während Ihrer Transaktion einen Fehler erhalten.  Ihre Zahlung konnte nicht eingezogen werden.",
        OrderDetailPayment_popup_SUCCESS: "Erfolgreich",
        OrderDetailPayment_progressGif_RESERVE_HOTEL: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        OrderDetailPayment_progressGif_TICKET_HOTEL: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        OrderDetailPayment_progressGif_TicketReservedFlight: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        orderDetailTab_btnPartialPaid: "Teilweise Bezahlt",
        orderDetailtransfer__lblProvider: "Anbieter",
        orderSearch_creationFromDate: "Erstellungsdatum",
        orderSearch_creationToDate: "Enddatum",
        orderSearch_filter_btnSearch: "Auflisten",
        orderSearch_filter_lblCreationFromDate: "Erstellungsdatum",
        orderSearch_filter_lblCreationToDate: "Enddatum",
        orderSearch_filter_lblDateType: "Art des Datum",
        orderSearch_filter_lblEticket: "E-Ticket",
        orderSearch_filter_lblHotel: "Hotel",
        orderSearch_filter_lblName: "Name",
        orderSearch_filter_lblOrderId: "Order ID",
        orderSearch_filter_lblPnr: "Pnr",
        orderSearch_filter_lblPnrStatus: "Pnr-Status",
        orderSearch_filter_lblProviderList: "Anbieterliste",
        orderSearch_filter_lblRentACar: "Mietwagen",
        orderSearch_filter_lblRnr: "Mietwagen",
        orderSearch_filter_lblRnrStatus: "Rnr-Status",
        orderSearch_filter_lblSurname: "Nachname",
        orderSearch_filter_lblVoucher: "Voucher",
        orderSearch_filter_lblVoucherId: "Voucher-ID",
        orderSearch_filter_lblVoucherStatus: "Status",
        orderSearch_filter_plhEticket: "E-Ticket",
        orderSearch_filter_plhName: "Name",
        orderSearch_filter_plhOrderId: "Order ID",
        orderSearch_filter_plhPnr: "Pnr",
        orderSearch_filter_plhRnr: "Rnr",
        orderSearch_filter_plhSurname: "Nachname",
        orderSearch_filter_plhVoucher: "Voucher",
        orderSearch_filter_plhVoucherId: "Voucher-ID",
        orderSearch_orderSearch: "Art des Datums",
        orderSearch_pageTitle: "Order Suche",
        orderSearch_pnrStatus: "Pnr-Status",
        orderSearch_providerList: "Anbieterliste",
        orderSearch_tblMain_agencyName: "Agenturname",
        orderSearch_tblMain_dropOffLocation: "Auto-Abgabepunkt",
        orderSearch_tblMain_etickets: "E-Ticket",
        orderSearch_tblMain_gsaName: "Gsa Name",
        orderSearch_tblMain_insertedDate: "Buchungsdatum",
        orderSearch_tblMain_insertedUser: "Erstellt vom Benutzer",
        orderSearch_tblMain_nameSurname: "Vor- und Nachname",
        orderSearch_tblMain_orderId: "Order ID",
        orderSearch_tblMain_pickUpDateTime: "Uhrzeit der Abholung",
        orderSearch_tblMain_pickUpLocation: "Auto-Abholpunkt",
        orderSearch_tblMain_pnrs: "Pnr",
        orderSearch_tblMain_pnrStatuses: "Pnr-Status",
        orderSearch_tblMain_provider: "Anbietername",
        orderSearch_tblMain_rnr: "RNR No",
        orderSearch_tblMain_rnrStatus: "Rnr-Status",
        orderSearch_tblMain_rooms: "Zimmer",
        orderSearch_tblMain_tnrs: "Tnr",
        orderSearch_tblMain_tnrStatuses: "Status",
        orderSearch_tblMain_vehicle: "Fahrzeug",
        orderSearch_tblMain_voucherId: "Voucher-ID",
        orderSearch_tblMain_vouchers: "Voucher",
        orderSearch_tblMain_voucherStatuses: "Status",
        orderSearch_voucherStatus: "Status",
        orderSearch__filter_lblVoucherStatus: "Status",
        orderSearch_filter_lblFlight: "Flug",
        orderSearch_filter_lblFromLocation: "Abflug",
        orderSearch_filter_lblTnr: "Tnr",
        orderSearch_filter_lblTnrStatus: "Status",
        orderSearch_filter_lblToLocation: "Ankunft",
        orderSearch_filter_lblTransfer: "Transfer",
        orderSearch_filter_plhFromLocation: "Abflug",
        orderSearch_filter_plhTnr: "Tnr",
        orderSearch_filter_plhToLocation: "Ankunft",
        otelSalesReport_tblMain_thContactSurname: "Nachname",
        passengerCard_country: "Land:",
        passengerCard_dateofBirth: "Geburtsdatum",
        passengerCard_expiryDate: "Verfallsdatum",
        passengerCard_hesCode: "HES Code",
        passengerCard_identityNo: "T.R. Identität Nr.",
        passengerCard_milesCard: "Meilennummer",
        passengerCard_milNo: "Meilennummer",
        passengerCard_nationality: "Staatsangehörigkeit",
        passengerCard_passportNumber: "Ausweisnummer",
        passengerCard_serialNumber: "Seriennummer",
        paymentChoice_partialPaymentPaidText: "Am #VALUE# wurde eine Anzahlung in Höhe von #VALUE1# geleistet.",
        paymentChoice_partialPaymentRemainingText: "Die Zahlung von #VALUE1# wird bis #VALUE# erwartet",
        PaymentChoiceItem_btnLEAVE: "ZUR RESERVIERUNG",
        PaymentChoiceItem_btnRESERVE: "RESERVIEREN",
        PaymentChoiceItem_btnREVERSE: "KOSTENFREI STORNIEREN",
        PaymentChoiceItem_btnTICKET: "BUCHEN",
        PaymentChoiceItem_FLIGHT_UPCOMING: "Die Fluggesellschaft erlaubt keine Buchungen.",
        PaymentChoiceItem_INSUFFICIENT_AGENCY_BALANCE: "Ihr verfügbares Guthaben reicht nicht aus.  Bitte kontaktieren Sie unser Team.",
        PaymentChoiceItem_INSUFFICIENT_GSA_BALANCE: "Bitte prüfen Sie die Gsa-Guthaben.",
        PaymentChoiceItem_lblCommission: "Kommission",
        PaymentChoiceItem_lblGrandTotal: "Zu zahlender Betrag",
        PaymentChoiceItem_lblPartialPaymentDepositAmount: "Anzahlungsbetrag",
        PaymentChoiceItem_lblRemainingBalance: "Ihr Restguthaben",
        PaymentChoiceItem_lblTotal: "Gesamt",
        PaymentChoiceItem_NOT_SALES_OFFICE: "Es ist kein Verkaufsbüro ausgewählt. Bitte überprüfen Sie Ihren Benutzer oder Agenten.",
        PaymentChoiceItem_SSRS_CANT_BE_RESERVED: "Da Sie SSR hinzugefügt haben, lässt die Fluggesellschaft die Reservierung nicht zu. Sie müssen das Ticket sofort ausstellen.",
        paymentChoiceItem_stepBack: "Zurück",
        PaymentChoiceItem_txtCommissionInfo_CREDITCARD: "Bei Zahlungen und Guthabenaufladungen mit der Kreditkarte VISA oder MASTER wird eine feste Bankprovision von 2 % hinzugefügt.  Bei Zahlungen und Guthabenaufladungen mit der Kreditkarte AMEX wird eine feste Bankprovision von 4,5 % hinzugefügt.",
        PaymentChoiceItem_txtCommissionInfo_IDEAL: "Es wird keine Provision erhoben.",
        PaymentChoiceItem_txtCommissionInfo_SOFORT: "Im Falle einer Sofortüberweisung; bei Summen zwischen 1,- € und 500,- € werden pauschal 3,- € und bei Summen über 501,- € pauschal 5,- € zum Gesamtbetrag hinzugerechnet.",
        paymentChoices_lblCARI: "MCO",
        paymentComponent_commission: "Kommission",
        paymentComponent_paymentInformations: "Zahlungsart",
        paymentComponent_remainingBalance: "Ihr Restguthaben",
        PaymentPurchaseWidget_btnReverse: "Kostenfrei Stornieren",
        PaymentPurchaseWidget_btnReverseWithPenalty: "kostenpflichtige Erstattung",
        PaymentPurchaseWidget_FLIGHT_RESERVATION_LEGAL_NOTICE: "Opsiyonlu kayıtlarda rezervasyon aşamasında gösterilen fiyat ile biletleme aşamasında gösterilen fiyat arasında farklılıklar oluşabilmektedir. Hatırlatmak isteriz ki; havayolları opsiyonlu kayıtlarda opsiyon süresi boyunca koltuk rezervasyonunu garanti etmekte olup fiyat opsiyonu ile ilgili bir garanti sunmamaktadır. Bu kurallar IATA ve havayolları tarafından belirlenmektedir.Havayolu kaynaklı bu tarz fiyat değişikliği olması halinde biletleme öncesi tarafınıza onaya sunulacaktır.",
        PaymentPurchaseWidget_NO_AUTHORIZATED_ACTION: "Ihr Benutzer ist nicht berechtigt, diesen Vorgang auszuführen. Bitte überprüfen Sie Ihren Agenten.",
        PaymentPurchaseWidget_NOT_SALES_OFFICE: "Es ist kein Verkaufsbüro ausgewählt. Bitte überprüfen Sie Ihren Benutzer oder Agenten.",
        paymentPurchaseWidget_partialPay: "Teilzahlung",
        PaymentPurchaseWidget_popup_REVERSE_FLIGHT: "Ihr Ticket wird storniert. Bestätigen Sie?",
        PaymentPurchaseWidget_popup_REVERSE_FLIGHT_W_PENALTY: "Es kann mit einer Gesamtstrafe von #VALUE1# #VALUE2# erstattet werden. Bestätigen Sie?",
        PaymentPurchaseWidget_popup_REVERSE_HOTEL: "Ihre Reservierung wird storniert. Bestätigst du?",
        PaymentPurchaseWidget_popup_REVERSE_RENTACAR: "Ihr Reservierung wird storniert. Bestätigen Sie?",
        PaymentPurchaseWidget_popup_REVERSE_TRANSFER: "Ihr Reservierung wird storniert. Bestätigen Sie?",
        PaymentPurchaseWidget_popup_TICKET_FLIGHT: "Möchten Sie Ihre Reservierung kaufen ?",
        PaymentPurchaseWidget_popup_TICKET_HOTEL: "Möchten Sie Ihre Reservierung kaufen ?",
        PaymentPurchaseWidget_popup_title: "Information",
        PaymentPurchaseWidget_progressGif_RESERVE_HOTEL: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_RESERVE_TRANSFER: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_ReverseFlight: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_ReverseHotel: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_ReverseTransfer: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_TICKET_FINANCE: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_TICKET_HOTEL: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_TICKET_TRANSFER: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_TicketReservedFlight: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        PaymentPurchaseWidget_progressGif_TicketReservedHotel: "Ihre Transaktion ist in Bearbeitung. Bitte warten Sie.",
        pending_pending: "Wartend",
        PickupInfo_txtAirToAir: "* Die Fahrgäste werden vor #VALUE3# Uhr von #VALUE1# zum „#VALUE2#“ transferiert.",
        PickupInfo_txtAirToLoc: "* Die Fahrgäste werden von #VALUE1# nach #VALUE2# transferiert.",
        PickupInfo_txtLocToAir: "* Die Fahrgäste werden vor #VALUE3# Uhr von #VALUE1# zum #VALUE2# transferiert.",
        PickupInfo_txtLocToLoc: "* Die Abholzeit für unsere Fahrgäste von #VALUE1#“ nach #VALUE2# ist um #VALUE3# Uhr.",
        PnrConfirm_bookingConfirmation: "Booking<br> Confirmation/<br> Rechnung",
        PnrConfirm_fare: "Fare",
        PnrConfirm_grandTotal: "Gesamtbetrag",
        PnrDoc_Email: "E-mail",
        pnrDoc_PopupCopiedToClipboard: "PDF-Link kopiert!",
        PnrOffer_flight: "Flug",
        popup_ancillaryDivideContent: "PNRs können nach dem PNR-Teilungsvorgang nicht mehr kombiniert werden. <br> Können Sie das bestätigen?",
        popup_confirmationTitle: "Bestätigung",
        Popup_ERROR: "Ein Fehler ist aufgetreten",
        Popup_exclamationTitle: "Ein Fehler ist aufgetreten",
        Popup_HOTEL_REVERSE_FAILURE_VOUCHER_NOT_REVERSABLE: "Bitte kontaktieren Sie das Service Center, um Ihre Reservierung zu stornieren.",
        popup_informationTitle: "Information",
        popup_lblCheckout: "Weiter zum Checkout",
        Popup_lblChildrenAgesWarn: "Error",
        Popup_lblChildrenAgesWarnText: "Bitte überprüfen Sie das Alter des Kindes.",
        Popup_lblEmptySearch: "Error",
        Popup_lblFlightSalesReportDateRange: "Der Zeitraum sollte maximal 1 Jahr betragen. Bitte überprüfen Sie die ausgewählten Daten.",
        Popup_lblLessThanTwoCharacter: "Information",
        Popup_lblPackagesContent: "Pauschalreisen bald hier verfügbar!",
        Popup_lblPackagesTitle: "Bereit?",
        Popup_lblPlsWaitForContact: "Unser Team wird sich bei Ihnen schnellstmöglich melden und Ihnen behilflich sein.",
        Popup_lblRequestReceived: "Ihre Anfrage wurde gesendet.",
        popup_lblReserve: "Erneut mit denselben Informationen buchen",
        Popup_lblSuccess: "Erfolgreich",
        Popup_lblTransferMaintenance: "Wir arbeiten noch an diesem Service.",
        Popup_lblWarning: "Fehler",
        Popup_ORDER_DETAIL_AUTHORIZATION_NOT_OWNER: "Sie sind nicht berechtigt, diese Buchung einzusehen. Bitte setzen Sie den Vorgang fort, indem Sie Ihre Seite aktualisieren.",
        Popup_RESERVE_HOTEL_FAILURE_RESERVATION: "Bitte starten Sie Ihre Anfrage erneut.",
        Popup_RESERVE_HOTEL_SUCCESS_OK: "Sie haben Ihre Reservierung erfolgreich gebucht. Bitte überprüfen Sie ihre Reservierung.",
        Popup_SUCCESS: "Erfolgreich",
        popup_successTitle: "Erfolgreich",
        "Popup_TICKET_HOTEL_EXCEPTION_error.no.enough.room.quota": "Beim Ausführen Ihrer Transaktion ist das Zimmerkontingent erschöpft. Bitte schließen Sie die Seite und suchen Sie erneut oder wenden Sie sich an unser Service-Center Team.",
        Popup_TICKET_HOTEL_SUCCESS_OK: "Sie haben Ihre Reservierung erfolgreich abgeschlossen. Bitte überprüfen Sie Ihre Transaktion.",
        Popup_TICKETRESERVED_FLIGHT_SUCCESS_OK: "Ihr Buchung ist erfolgreich.",
        Popup_TICKETRESERVED_HOTEL_SUCCESS_OK: "Sie haben Ihre Reservierung erfolgreich gebucht. Bitte überprüfen Sie ihre Reservierung.",
        Popup_txtDuplicatedPassenger: "Es können nicht mehrere Passagiere mit denselben Daten registriert werden.",
        Popup_txtEmptySearch: "Bitte füllen Sie das Suchfeld aus und treffen Sie eine Auswahl aus der Eingangsliste.",
        Popup_txtFlightChangeError: "Bei der Bestätigung ist ein Fehler aufgetreten. Bitte kontaktieren Sie unser Service-Center-Team.",
        Popup_txtFlightChangeErrorApprove: "Bei der Bestätigung ist ein Fehler aufgetreten. Bitte kontaktieren Sie unser Service-Center-Team.",
        Popup_txtFlightChangeErrorPNR_Reissue: "Bei der Bestätigung ist ein Fehler aufgetreten. Bitte kontaktieren Sie unser Service-Center-Team.",
        Popup_txtFlightChangeSuccess: "Sie haben Ihre Flugplanänderung erfolgreich bestätigt. Sie können den Status über das Service Center verfolgen.",
        Popup_txtFlightChangeSuccessApprove: "Sie haben Ihre Flugplanänderung erfolgreich bestätigt. Sie können den Status über das Service Center verfolgen.",
        Popup_txtFlightChangeSuccessPNR_Reissue: "Ihre Anfrage wurde erfolgreich an unser Team weitergeleitet. Sie können Ihre Anfrage über das Service Center verfolgen.",
        Popup_txtFlightChangeSuccessPNR_Reverse: "Ihre Stornierungsanfrage wurde erfolgreich an unser Team weitergeleitet. Sie können Ihre Anfrage über das Service Center verfolgen.",
        Popup_txtLessThanTwoCharacter: "Es sind mindestens 2 Zeichen einzugeben.",
        Popup_txtMealNotAvailable: "Für Ihre Reservierung kann kein Mahlzeitwunsch erstellt werden. Bitte wenden Sie sich an das Service Center.",
        Popup_txtSsrsNotAvailable: "Für Ihre Reservierung kann keine SSR-Anforderung erstellt werden. Bitte wenden Sie sich an das Service Center.",
        Popup_txtWheelChairNotAvailable: "Für Ihre Reservierung kann keine Rollstuhlanfrage gestellt werden. Bitte kontaktieren Sie das Service Center.",
        Popup_txtWheelChairNowAvailable: "Für Ihre Reservierung kann keine Rollstuhlanfrage gestellt werden. Bitte kontaktieren Sie das Service Center.",
        popup_warningTitle: "Fehler",
        pricechange_btnCancel: "Abbrechen",
        priceChange_lblnewPrice: "Neuer Preis",
        priceChange_lblOldPrice: "Alter Preis",
        priceChange_lblpriceDiff: "Preisunterschied",
        profileSettings_lblAgencyAddress: "Anschrift",
        profileSettings_lblAgencyCountryPhoneCode: "Vorwahl",
        profileSettings_lblAgencyCurrency: "Währung",
        profileSettings_lblAgencyDescription: "Informationen über Ihre Agentur.",
        profileSettings_lblAgencyEmail: "E-mail",
        profileSettings_lblAgencyId: "Agentur-ID",
        profileSettings_lblAgencyInfo: "Agenturinformationen",
        profileSettings_lblAgencyName: "Vorname",
        profileSettings_lblAgencyOfficialName: "Offizieller Name",
        profileSettings_lblAgencyPhone: "Telefon",
        profileSettings_lblAgencyShortName: "Marketingname",
        profileSettings_lblAgencyTimezone: "Zeitzone",
        profileSettings_lblBrowseProfilePhoto: "Aus der Galerie wählen",
        profileSettings_lblDragDropFile: "Datei ziehen und ablegen oder",
        profileSettings_lblGsa: "Gsa",
        profileSettings_lblPasswordChangeContent: "Für die Sicherheit Ihrer Konten besteht die Notwendigkeit Ihre Passwörter stärker zu erstellen. Die Umstände dienen ausschließlich der Sicherheit Ihres Kundenkontos. Vielen Dank für Ihr Verständnis im Voraus.",
        profileSettings_lblPasswordChangeTitle: "Bitte Klicken Um Ihr Passwort Zurückzusetzen",
        profileSettings_lblPrivacyAndSecurity: "Sicherheitspräferenzen",
        profileSettings_lblPrivacyAndSecurityDescription: "Informationen zu Ihrem Benutzer.",
        profileSettings_lblProfilePhoto: "Profilfoto",
        profileSettings_lblProfilePhotoDescription: "Die Fotogröße, die Sie hochladen möchten, sollte wie folgt sein.",
        profileSettings_lblProfileSettings: "Profil Bearbeiten",
        profileSettings_lblRegionLanguagePreferences: "Regions- und Telefoneinstellungen",
        profileSettings_lblRegionLanguagePreferencesDescription: "Informationen über Ihre Agentur.",
        profileSettings_lblReset: "ZURÜCKSETZEN",
        profileSettings_lblResetPassword: "Neues Passwort erstellen",
        profileSettings_lblUpdate: "AKTUALISIEREN",
        profileSettings_lblUserDescription: "Informationen zu Ihrem Benutzer.",
        profileSettings_lblUserEmail: "E-mail",
        profileSettings_lblUserInfo: "Nutzerinformation",
        profileSettings_lblUserLanguage: "Spracheinstellung",
        profileSettings_lblUserName: "Vorname",
        profileSettings_lblUserPhone: "Telefon",
        profileSettings_lblUserSurname: "Nachname",
        profileSettings_lblUserTimezone: "Zeitzone",
        renatacar_tooltipINSURANCE_ECO: "Die Mietwagen-Versicherung deckt keine Schäden an Reifen, Glas und Scheinwerfern ab. Hierfür muss eine Zusatzversicherung abgeschlossen werden.",
        rentacar_airConditioning: "Klima",
        rentacar_Ancillaries: "Zusatzleistungen",
        rentacar_btnCancel: "Storniert",
        rentacar_btnSearch: "MIETE FÜR #VALUE# TAG(E)",
        rentacar_btnSearchDaily: "Mietwagen Suchen",
        rentacar_btnSubmit: "Auswählen",
        rentacar_doorCount: "Türen",
        rentacar_driverInformations: "Fahrerinformationen",
        rentacar_dropoffDate: "Rückgabedatum & Uhrzeit",
        rentacar_extraFeatures: "Inklusivleistungen",
        rentacar_filtersFuelType: "Kraftstoffart",
        rentacar_filtersTransmissionType: "Getriebe",
        rentacar_filtersVehicleType: "Fahrzeugtyp",
        rentacar_gearType: "Getriebetyp",
        rentacar_largeBaggage: "Großgepäck",
        rentacar_lblAddDropoffLocation: "Anderer Rückgabeort",
        rentacar_lblAncillaries: "Zusatzleistungen",
        rentacar_lblDropoffLocation: "Anderer Rückgabeort",
        rentacar_lblPerDay: "pro Tag",
        rentacar_lblPickupLocaton: "Abhol- & Rückgabeort",
        rentacar_lblRentACarDescriptionsInfoFives: "Sie können bis zu 24 Stunden vor der Fahrzeugübergabe kostenlos stornieren.",
        rentacar_lblRentACarDescriptionsInfoFour: "Das Formular für die Vermittlungsvereinbarung muss von der Agentur und dem Fahrer unterzeichnet werden.",
        rentacar_lblRentACarDescriptionsInfoOne: "Der Fahrer muss bei Übergabe des Fahrzeugs seinen Personalausweis und seinen Führerschein vorlegen.",
        rentacar_lblRentACarDescriptionsInfoThree: "Die Mietwagenversicherung deckt keine Schäden an Reifen, Glas und Scheinwerfern. Hierfür muss eine zusätzliche Versicherung abgeschlossen werden.",
        rentacar_lblRentACarDescriptionsInfoTwo: "Bei der Fahrzeugübergabe wird keine Kreditkarte oder Kaution verlangt.",
        rentacar_lblRentACarDescriptionsTitle: "Wichtige Informationen",
        rentacar_lblShowAncillaries: "Zusatzleistungen",
        rentacar_lblTermsOfUse: "Nutzungsbedingungen",
        rentacar_locationSearchlbl: "Stadt oder Flughafen",
        rentacar_orSmiliar: "oder ähnlich",
        rentacar_passengerCapacity: "Sitze",
        rentacar_pickupDate: "Abholdatum & Uhrzeit",
        rentacar_ReservationDate: "Reservierungsdatum",
        rentacar_smallBaggage: "Kleingepäck",
        rentacar_suitCases: "Gepäck",
        rentacarCard_minDriverAge: "Mindestalter des Fahrers : #VALUE#",
        rentacarCard_minLicenseAge: "Mindestalter des Führerscheins : #VALUE#",
        rentacarCheckout_lblAgencyCommission: "Agenturprovision",
        rentacarCheckout_lblAgencyExtraCommission: "Agentur-Extraprovision",
        rentacarCheckout_lblAncillaries: "Zusatzleistungen",
        rentacarCheckout_lblAncillaryTotal: "Zusatzleistungen",
        rentacarCheckout_lblDropOffLocation: "Rückgabeort",
        rentacarCheckout_lblGrandTotal: "Gesamtsumme",
        rentacarCheckout_lblLastVoidDateTime: "Kostenlos stornierbar bis 24 Stunden vor Abholung!",
        rentacarCheckout_lblPaymentFeeTotal: "Bearbeitungsgebühr für Kreditkarten",
        rentacarCheckout_lblPickUpLocation: "Abhol- & Rückgabeort",
        rentacarCheckout_lblPriceSummary: "Gesamt",
        rentacarCheckout_lblReverseTotal: "Gesamtbetrag der Rückerstattung",
        rentacarCheckout_lblSaleTotal: "Gesamtumsatz",
        rentacarCheckout_lblVehicleTotal: "Fahrzeugpreis",
        rentACarOrderDetail_lblRentACarAgencyNote: "Hinweis der Agentur",
        rentacarOrderDetail_phOr: "Oder",
        rentacarOrderDetail_phSimilar: "ähnlich",
        rentACarSalesReport_currency: "Währung",
        rentACarSalesReport_filter_btnList: "Auflisten",
        rentACarSalesReport_filter_lblFromDate: "Startdatum",
        rentACarSalesReport_filter_lblReportType: "Berichtsart",
        rentACarSalesReport_filter_lblStatus: "Status",
        rentACarSalesReport_filter_lblToDate: "Enddatum",
        rentACarSalesReport_lblReportType: "Berichtsart",
        rentACarSalesReport_refundTotal: "Gesamterstattung",
        rentACarSalesReport_reverseAmount: "Gesamterstattung",
        rentACarSalesReport_rnrCount: "Anzahl der Order",
        rentACarSalesReport_tblMain_thAgencyCommission: "Agenturprovision",
        rentACarSalesReport_tblMain_thAgencyName: "Agenturname",
        rentACarSalesReport_tblMain_thDriverName: "Vorname des Fahrers",
        rentACarSalesReport_tblMain_thDriverSurname: "Nachname des Fahrers",
        rentACarSalesReport_tblMain_thDropOffDateTime: "Rückgabedatum",
        rentACarSalesReport_tblMain_thDropOffLocation: "Rückgabeort",
        rentACarSalesReport_tblMain_thGrandTotal: "Gesamtsumme",
        rentACarSalesReport_tblMain_thMarkModel: "Fahrzeug",
        rentACarSalesReport_tblMain_thOrderId: "Order ID",
        rentACarSalesReport_tblMain_thPickUpDateTime: "Uhrzeit der Abholung",
        rentACarSalesReport_tblMain_thPickUpLocation: "Abhol- & Rückgabeort",
        rentACarSalesReport_tblMain_thRnr: "Rnr",
        rentACarSalesReport_tblMain_thSaleDate: "Verkaufsdatum",
        rentACarSalesReport_tblMain_thStatus: "Status",
        rentACarSalesReport_tblMain_thTotal: "Gesamt",
        rentACarSalesReport_title: "Mietwagen-Verkaufsbericht",
        rentACarSalesReport_totalAmount: "Gesamtverkauf",
        rentACarSalesReport_vehicleCount: "Anzahl der Passagiere",
        rentacarSearchForm_lblCreditCardDepositInfo: "Bei der Fahrzeugübergabe wird keine Kreditkarte oder Kaution verlangt.",
        rentacarSearchInformation_date1: "Datum und Uhrzeit der Abholung",
        rentacarSearchInformation_date2: "Abgabedatum",
        rentacarSearchInformation_locationFrom: "Abhol- & Rückgabeort",
        rentacarSearchInformation_locationTo: "Rückgabeort",
        rentacarSearchInformation_text: "Auf der Suche nach den besten Preisen, bitte warten!",
        requestForm_activeTab: "Anfrage für folgende PNR..",
        requestForm_packageTitle: "Angefragte Pauschalreise",
        requestForm_pnrTitle: "Anfrage für folgende PNR..",
        requestForm_rnrTitle: "zu öffnende Task Rnr.",
        requestForm_tnrTitle: "zu öffnende Task Tnr.",
        requestForm_unknownTitle: "Im Bau befindlich",
        requestForm_voucherTitle: "Anfrage für folgende Voucher..",
        requestFormComponent_infoAlert_text: "Bitte wählen Sie die Aktion aus, die Sie ausführen möchten. Die Antwortdauer hängt von Ihrer Wahl ab.",
        requestFormComponent_textareaPlaceholder_label: "Bitte schreiben Sie Ihre Anfrage...",
        reservationInfo_lblPopupPriceChangeTitle: "Informationen zur Reservierungspreisänderung",
        ReservationInformation_progressGif_ReReserveFlight: "Wir suchen wieder nach den gleichen Flügen für Sie.",
        resetPassword_emailEmpty: "Bitte geben Sie eine gültige E-Mail ein.",
        resetPassword_emailHasBeenSent: "Die Mail wurde erfolgreich versendet.",
        resetPassword_lowerCase: "Kleinbuchstabe",
        resetPassword_minChar: "Mindestens #VALUE# Zeichen.",
        resetPassword_numberRequired: "Nummer",
        resetPassword_passwordResetFaile: "Bitte überprüfen Sie Ihren Link zur Erneuerung des Passworts.",
        resetPassword_passwordResetFailed: "Sie haben einen Fehler erhalten. Bitte senden Sie den Link zur Passwortänderung erneut.",
        resetPassword_passwordResetMessage: "Bitte legen Sie ein neues Passwort fest.",
        resetPassword_passwordResetSuccess: "Ihr Passwort wurde geändert.",
        resetPassword_passwordResetTitle: "Passwort ändern",
        resetPassword_passwordsAreDifferent: "Die Passwörter die du eingegeben hast, passen nicht.",
        resetPassword_passwordsAreDifferentOrShort: "Die Passwörter die du eingegeben hast, passen nicht.",
        resetPassword_passwordsAreShort: "Ihr Passwort muss mindestens 4 Zeichen lang sein.",
        resetPassword_resetPassword: "Neues Passwort Speichern",
        resetPassword_specialChar: "Sonderzeichen",
        resetPassword_upperCase: "Großbuchstabe",
        resetPassword_userPassword: "Neues Kennwort",
        resetPassword_userPasswordConfirm: "Neues Passwort erneut eingeben",
        RESPONSE___EXCEPTION_QUOTA: "Beim Ausführen Ihrer Transaktion ist das Zimmerkontingent ausgebucht. Bitte schließen Sie die Seite und suchen Sie erneut oder wenden Sie sich an unser Service-Center Team.",
        RESPONSE___EXCEPTION_STOP: "Beim Ausführen Ihrer Transaktion ist das Zimmerkontingent ausgebucht. Bitte schließen Sie die Seite und suchen Sie erneut oder wenden Sie sich an unser Service-Center Team.",
        RESPONSE___EXPIRED_OK: "Die Preisinformationen können sich geändert haben.  Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        RESPONSE__RESERVE_HOTEL_SUCCESS_OK: "Ihre Transaktion wurde erfolgreich abgeschlossen.",
        RESPONSE__TICKET_HOTEL_SUCCESS_OK: "Sie haben Ihre Reservierung erfolgreich abgeschlossen. Bitte überprüfen Sie Ihre Transaktion.",
        RESPONSE__TICKET_TRANSFER_SUCCESS_OK: "Sie haben Ihren Transfer erfolgreich gebucht. Bitte überprüfen Sie Ihre Transaktion.",
        RESPONSE__TICKETRESERVED_HOTEL_SUCCESS_OK: "Ihre Transaktion wurde erfolgreich abgeschlossen.",
        "RESPONSE__TRANSFER_API_TICKET_TRANSFER_ISSUED_::TchnGtsDatabaseService.Reservation successful": "Sie haben Ihren Transfer erfolgreich gebucht. Bitte überprüfen Sie Ihre Transaktion.",
        "RESPONSE__TRANSFER_API_TICKET_TRANSFER_ISSUED_::TchnGtsDatabaseService.Reservation successful orderId:": "Sie haben Ihren Transfer erfolgreich gebucht. Bitte überprüfen Sie Ihre Transaktion. Order ID:",
        RESPONSE_429_TooManyRequests_OK: "Hoteldetails können nicht angezeigt werden. Bitte versuchen Sie es erneut, indem Sie die Seite aktualisieren oder Unterstützung von unserem Service-Center-Team anfordern.",
        "RESPONSE_AGENT_API.HOTEL_TICKET_RESERVED.REJECTED.INSUFFICIENT_PAYMENT": "Sie haben während Ihrer Transaktion einen Fehler erhalten. Ihre Zahlung konnte nicht eingezogen werden.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.FAILURE.NO_VEHICLE_FOUND": "Es wurden keine Fahrzeuge gefunden, die Ihren Kriterien entsprechen. Bitte ändern Sie Ihre Suchkriterien und versuchen Sie es erneut.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.NOT_EXISTS.LOCATION_NOT_MAPPED_FOR_PANDORA": "Für den von Ihnen gesuchten Standort wurde vom Anbieter kein geeignetes Fahrzeug gefunden. Bitte kontaktieren Sie Ihren Servicecenter.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.REJECTED.PARAMETER.PERIOD_DAYS": "Autovermietung ist für #VALUE# tag und mehr verfügbar.",
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.REJECTED.PARAMETER.PICKUP_HOURS": "Autovermietung ist für #VALUE# Stunde(n) und mehr verfügbar.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_PARAMETER_OK: "Sie haben den falschen Parameter eingegeben.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_REJECTED_EXPIRED: "Beim Aufladen Ihres Agenturguthabens ist ein Fehler aufgetreten.",
        RESPONSE_AGENT_API_BALANCE_UPDATE_REJECTED_INSUFFICIENT_PAYMENT: "Sie haben während der Bezahlung einen Fehler erhalten.",
        RESPONSE_AGENT_API_BalanceUpdate_AUTHORIZATION_NOT_SALES_OFFICE: "Es ist kein Verkaufsbüro. Bitte überprüfen Sie Ihren Benutzer oder Agenten.",
        RESPONSE_AGENT_API_CHECKOUT_EXPIRED: "Da sie lange nicht gehandelt haben, wurden die Preise möglicherweise aktualisiert.    Sie können Ihre Suche fortsetzen, indem Sie Ihre Suche erneuern.",
        "RESPONSE_AGENT_API_Detail_EXCEPTION_One or more errors occurred_": "Sie haben einen unbekannten Fehler erhalten.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TAKEN_OFF: "Bitte überprüfen Sie Ihr Flugdatum.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TAKEN_OFF_TITLE: "Information",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TIME_NOT_AVAILABLE: "Bitte wenden Sie sich an das Service Center, um Gepäck zu kaufen,",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TIME_NOT_AVAILABLE_TITLE: "Information",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_MANUAL_DIVIDED: "Sie haben einen Fehler beim Buchen von Zusatzgepäck erhalten. Bitte kontaktieren Sie unser Service Center.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_MANUAL_DIVIDED_TITLE: "Information",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_ANCILLARY: "Das Gepäck für diese Buchung wurde bereits angenommen. Bitte wenden Sie sich an das Service-Center-Team.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_ANCILLARY_TITLE: "Informationen zum Reisegepäck",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_TASK: "Für den Kauf von Gepäck wenden Sie sich bitte an das Service Center.",
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_TASK_TITLE: "Information",
        "RESPONSE_AGENT_API_FLIGHT_CHECKOUT_ PARAMETER_NOT_RESERVABLE": "Eine Reservierung ist nicht möglich. Bitte setzen Sie den Vorgang fort, indem Sie Ihre Suche erneuern.",
        "RESPONSE_AGENT_API_FLIGHT_CHECKOUT_ PARAMETER_NOT_TICKETABLE": "Die Buchung ist nicht möglich. Bitte setzen Sie den Vorgang fort, indem Sie Ihre Suche erneuern.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: "Ihr Benutzer ist nicht berechtigt, diesen Vorgang auszuführen. Bitte überprüfen Sie Ihren Agenten.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXCEPTION_CHECKOUT: "Es ist ein unbekannter Fehler aufgetreten.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_BASKET: "Die Preisinformationen können sich geändert haben.  Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_CHECKOUT: "Die Preisinformationen können sich geändert haben.  Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_SEARCH: "Da sie lange nicht gehandelt haben, wurden die Preise möglicherweise aktualisiert.    Sie können Ihre Suche fortsetzen, indem Sie Ihre Suche erneuern.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_FAILURE_NO_PRICE: "Für den ausgewählten Flug wurde kein Preis gefunden. Bitte suchen Sie erneut.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: "Eine Reservierung ist nicht möglich. Bitte setzen Sie den Vorgang fort, indem Sie Ihre Suche erneuern.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_RETRY_NO_RESPONSE: "Bitte geben Sie die angeforderten Informationen vollständig und genau ein.",
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_SESSION_INVALID_AGENCY: "Bitte Agent auswählen",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_EXPIRED_CHECKOUT_NOT_FOUND: "Beim Stornierungsvorgang ist ein Zeitüberschreitungsfehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_FAILURE_RESERVATION_FAILED: "Sie haben bei der Reservierung eine Fehlermeldung erhalten. Ihre neue Reservierung wurde noch nicht vorgenommen.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_BRAND_OR_SSR_NOT_FOUND: "Marke oder Ssr nicht gefunden. Bitte fahren Sie manuell fort.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_CHECKOUT_FAILED: "Ein Fehler ist während Ihrer Operation aufgetreten. Sie können Ihren Vorgang fortsetzen, indem sie erneut suchen.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_FLIGHT_NOT_FOUND: "Wir konnten die Flüge für Ihre frühere Buchung bei der Fluggesellschaft nicht finden. Bitte überprüfen Sie die Daten.",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_SESSION_INVALID_GSA_OR_AGENCY: "Ihr Benutzer ist nicht berechtigt, diesen Vorgang auszuführen. Bitte überprüfen Sie Ihren Agenten.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_EXPIRED_INQUIRY: "Sie haben während Ihrer Transaktion eine Zeitüberschreitung erhalten. Bitte setzen Sie den Vorgang fort, indem Sie Ihre Seite aktualisieren.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_FAILURE_ALREADY_REVERSED: "Ihr PNR wurde gelöscht, bitte aktualisieren Sie Ihre Seite. Wenn Sie glauben, dass es ein Problem gibt, wenden Sie sich bitte an das Service-Center.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_FAILURE_UNREVERSABLE: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_INQUIRY_REJECTED_PNR_EXPIRED: "Das letzte Kaufdatum ist abgelaufen. Sie können die manuelle Stornierung vornehmen, indem Sie sich mit dem Service Center in Verbindung setzen.",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_SUCCESS_OK: "Ihre Transaktion wurde erfolgreich abgeschlossen.",
        RESPONSE_AGENT_API_FLIGHT_SEARCH_SESSION_INVALID_AGENCY: "Bitte Agent auswählen",
        RESPONSE_AGENT_API_FLIGHT_SELECT_AUTHORIZATION_DIFFERENT_USER: "Sie haben während der Flugauswahl einen Fehler erhalten. Bitte suchen Sie erneut.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: "Da sie lange nicht gehandelt haben, wurden die Preise möglicherweise aktualisiert.    Sie können Ihre Suche fortsetzen, indem Sie Ihre Suche erneuern.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_NOT_EXISTS_NO_PACKAGE_AVAILABLE: "Für den von Ihnen gewählten Flug fällt keine Paketgebühr an.",
        RESPONSE_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: "Bitte wählen Sie „Roundtrip“ Flüge aus oder heben Sie die Auswahl auf, wenn Sie eine „One-Way-Tarif“-Auswahl haben.",
        "RESPONSE_AGENT_API_FLIGHT_TICKET_ FAILURE_PRICE_DIFFERENCE": "Während Ihrer Transaktion gab es eine Preisdifferenz Bitte wählen Sie die gewünschte Aktion aus.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_EXCEPTION_PNR_RETRIEVE: "Ihre Reservierungsinformationen haben sich geändert. Ein Automatikbetrieb ist nicht möglich. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_EXCEPTION_TICKET: "Beim Ticketing ist ein unbekannter Fehler aufgetreten.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: "Während Ihres Ticketvorgangs gab es einen Preisunterschied.  Preisunterschied :",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE_TITLE: "Preis wurde geändert",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_REJECTED: "Ihre Flugdaten haben sich geändert.  Bitte wenden Sie sich an das Service Center.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_REJECTED_ISSUED: "Ihre Reservierung wurde gekauft.  Bitte kontaktieren Sie unser Service Center Team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_PARAMETER_EXISTED: "Möglicherweise haben Sie ein früheres Ticket oder eine Reservierung für die Details, die Sie bearbeiten möchten. Bitte erkundigen Sie sich bei unserem Service-Center-Team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_PRICE_DIFFERENCE_AFTER_RESERVATION: "Während Ihrer Transaktion gab es eine Preisdifferenz.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_CHANGE_DETECTED: "Ihre Flugdaten haben sich geändert. Bitte wenden Sie sich an das Service Center.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_IMPORT_TYPE_MANUAL: "Aufgrund der manuellen Bearbeitung Ihres Tickets können wir Ihren Ticketing-Prozess derzeit nicht bearbeiten.  Bitte öffnen Sie eine Aufgabe für unser Service-Center-Team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_FUNDS: "Sie haben kein Guthaben, Bitte wenden Sie sich an ınser Verkaufsteam ",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: "Sie haben während Ihrer Transaktion einen Fehler erhalten.  Ihre Zahlung konnte nicht eingezogen werden.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_UNSUFFICIENT_FUNDS: "Ihr verfügbares Guthaben reicht nicht aus.  Bitte kontaktieren Sie unser Team.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_RETRY_RESERVATION_PRICE_CHECK: "Ein Fehler ist während Ihrer Operation aufgetreten.  Sie können Ihren Vorgang fortsetzen, indem sie erneut suchen.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_SESSION_INVALID_AGENCY: "Bitte wählen Sie eine Agentur für die Ticketausstellung aus.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_SUCCESS_PAYMENT_POST: "Ihre Transaktion wurde erfolgreich abgeschlossen.",
        RESPONSE_AGENT_API_FLIGHT_TICKET_TIMEOUT_EXPIRED: "Ihre Sitzung ist abgelaufen. Bitte versuchen Sie es erneut. ",
        RESPONSE_AGENT_API_HOTEL_CHECKOUT_EXPIRED_BASKET: "Die Preisinformationen können sich geändert haben. Bitte setzen Sie Ihre Transaktion fort, indem Sie erneut suchen.",
        RESPONSE_AGENT_API_HOTEL_CHECKOUT_REJECTED_CHECKOUT_NOT_AVAILABLE: "Da sie lange nicht gehandelt haben, wurden die Preise möglicherweise aktualisiert. Sie können Ihre Suche fortsetzen, indem Sie Ihre Suche erneuern.",
        RESPONSE_AGENT_API_HOTEL_DETAIL_EXPIRED_HOTEL_SEARCH: "Hoteldetails können nicht angezeigt werden. Bitte versuchen Sie es erneut, indem Sie die Seite aktualisieren oder Unterstützung von unserem Service-Center-Team anfordern.",
        RESPONSE_AGENT_API_HOTEL_REVERSE_FAILURE_VOUCHER_CANT_RETRIEVED: "Bitte kontaktieren Sie das Service Center, um Ihre Reservierung zu stornieren.",
        RESPONSE_AGENT_API_HOTEL_SEARCH_PARAMETER_INVALID_DESTINATION: "Bitte füllen Sie das Suchfeld aus und treffen Sie eine Auswahl aus der Eingangsliste.",
        RESPONSE_AGENT_API_HOTEL_SEARCH_SESSION_INVALID_AGENCY: "Bitte wählen Sie eine Agentur aus.",
        RESPONSE_AGENT_API_HOTEL_SELECT_EXPIRED_HOTEL_DETAIL: "Die Preisinformationen können sich geändert haben.  Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        "RESPONSE_AGENT_API_IAgtBllSchmetterlingService.ShowOrder_FAILURE_NULL_LIST_RESPONSE": "Für diese Daten liegen keine Verkäufe von Pauschalreisen vor. Bitte überprüfen Sie die Angaben und versuchen Sie es erneut.",
        RESPONSE_AGENT_API_LOGIN_AUTHORIZATION_INVALID_CREDENTIALS: "Bitte stellen Sie sicher, dass die von Ihnen eingegebenen Informationen korrekt sind.",
        RESPONSE_AGENT_API_LOGIN_AUTHORIZATION_USER_BLOCKED: "Ihr Benutzer wurde aufgrund falscher Anmeldeinformationen gesperrt. Sie können sich erneut anmelden, indem Sie Ihr Passwort ändern.",
        RESPONSE_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "Bitte stellen Sie sicher, dass die von Ihnen eingegebenen Informationen korrekt sind.",
        RESPONSE_AGENT_API_LOGIN_PARAMETER_INVALID_DOMAIN_NAME: "Die Benutzerdaten, mit denen Sie sich anzumelden versuchen, gehören nicht zu dieser Website. Bitte überprüfen Sie Ihre Anmelde-URL.",
        RESPONSE_AGENT_API_ORDER_DETAIL_AUTHORIZATION_NOT_OWNER: "Sie sind nicht berechtigt, diese Bestellung anzuzeigen.",
        RESPONSE_AGENT_API_PWD_RESET_REJECTED_INVALID_DOMAIN_NAME: "Sie können Ihr Passwort über diesen Link nicht zurücksetzen. Bitte überprüfen Sie Ihre Anmeldedaten und Ihre URL.",
        RESPONSE_AGENT_API_PWD_RESET_REJECTED_INVALID_USER: "Bitte geben Sie eine gültige E-Mail ein.",
        RESPONSE_AGENT_API_RENT_A_CAR_BOOK_REJECTED_INSUFFICIENT_PAYMENT: "Bei Ihrer Transaktion ist eine Fehlermeldung aufgetreten. Ihre Zahlung konnte nicht eingezogen werden.",
        RESPONSE_AGENT_API_RENT_A_CAR_BOOK_REJECTED_PAYMENT_POST: "Bei Ihrer Transaktion ist eine Fehlermeldung aufgetreten. Ihre Zahlung konnte nicht eingezogen werden.",
        RESPONSE_AGENT_API_RENT_A_CAR_REVERSE_FAILURE_FAILURE: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_AGENT_API_RENT_A_CAR_SEARCH_PARAMETER_INVALID_DATES: "Bitte überprüfen Sie die Daten.",
        RESPONSE_AGENT_API_TAKE_NOTE_PARAMETER_INVALID_NOTE: "Dieses Feld darf nicht leer gelassen werden.",
        RESPONSE_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE: "Ihre Anfrage wurde bereits an unser Service Center Team weitergeleitet.  Bitte folgen Sie Ihrer Anfrage im Service Center.",
        RESPONSE_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE_TITLE: "Information",
        RESPONSE_AGENT_API_TASK_CREATE_SUCCESS_OK: "Ihre Anfrage wurde erfolgreich an unser Team weitergeleitet. Sie können Ihre Anfrage über das Service Center verfolgen.",
        RESPONSE_AGENT_API_TICKET_EXCEPTION: "Es ist ein unbekannter Fehler aufgetreten.",
        RESPONSE_AGENT_API_TICKET_EXPIRED: "Die Preisinformationen können sich geändert haben.  Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        RESPONSE_AGENT_API_TICKET_FAILURE: "Während Ihrer Buchung gab es einen Preisunterschied.  Akzeptieren Sie den Preisunterschied?",
        RESPONSE_AGENT_API_TICKET_FAILURE_PRICE_DIFFERENCE: "Während Ihres Ticketvorgangs gab es einen Preisunterschied.  Preisunterschied :",
        RESPONSE_AGENT_API_TICKET_FAILURE_PRICE_DIFFERENCE_TITLE: "Preisunterschied",
        RESPONSE_AGENT_API_TICKET_HOTEL_REJECTED_INSUFFICIENT_PAYMENT: "Sie haben während Ihrer Transaktion einen Fehler erhalten. Ihre Zahlung konnte nicht eingezogen werden.",
        RESPONSE_AGENT_API_TICKET_PARAMETER: "Ihre Suche ist ungültig.  Bitte überprüfen Sie Ihre Suchkriterien und versuchen Sie es erneut.",
        RESPONSE_AGENT_API_TICKET_PRICE_DIFFERENCE: "Preisunterschied aufgetreten! Akzeptieren Sie den Preisunterschied?",
        RESPONSE_AGENT_API_TICKET_PRICE_DIFFERENCE_TITLE: "Preisunterschied",
        RESPONSE_AGENT_API_TICKET_REJECTED_PNR_STATUS: "Ihr Pnr-Status hat sich geändert. Bitte setzen Sie den Vorgang fort, indem Sie Ihren Bildschirm aktualisieren.",
        RESPONSE_AGENT_API_TICKET_RETRY: "Während Ihrer Buchung ist ein Fehler aufgetreten.  Sie können Ihre Suche fortsetzen, indem Sie erneut suchen.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_FAILURE_PRICE_DIFFERENCE: "Während Ihrer Transaktion ist eine Preisdifferenz aufgetreten.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_FAILURE_REJECTED: "Beim Ticketing ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_CHANGE_DETECTED: "Es gibt eine Änderung in Ihren Flugdaten.  Bitte kontaktieren Sie das Service Center.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_INSUFFICIENT_FUNDS: "Sie haben kein Guthaben. Bitte wenden Sie sich an unser Verkaufsteam.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_INSUFFICIENT_PAYMENT: "Sie haben während Ihrer Transaktion einen Fehler erhalten. Ihre Zahlung konnte nicht eingezogen werden.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_PNR_EXPIRED: "Das letzte Kaufdatum ist abgelaufen. Sie können eine manuelle Überprüfung vornehmen, indem Sie sich mit dem Service Center in Verbindung setzen.",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_SUCCESS_OK: "Ihr Ticketing ist erfolgreich.",
        RESPONSE_AGENT_API_TICKETRESERVED_HOTEL_REJECTED_RETRIEVE_: "Es gab keine Bestätigung seitens der Provider. Bitte wenden Sie sich an unser Service Center Team.",
        RESPONSE_AGENT_API_UNKNOWN_REJECTED_HOTEL_CHECK_AVAILABILITY: "Es ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service Center Team.",
        RESPONSE_AGENT_PI_FLIGHT_TICKET_REJECTED_IMPORT_TYPE_MANUAL: "Aufgrund der manuellen Bearbeitung Ihres Tickets können wir Ihren Ticketing-Prozess derzeit nicht bearbeiten.  Bitte öffnen Sie eine Aufgabe für unser Service-Center-Team.",
        RESPONSE_AGENT_WEB_FLIGHT_CHECKOUT_TIMEOUT_SERVICE_TIMEOUT: "Die Preisinformationen können sich geändert haben. Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        "RESPONSE_FLIGHT.API.FLIGHT.RESERVATION.FAILURE.INVALID.PARENT.AGE": "Das Erwachsenenalter ist für Reisen mit einem Baby nicht geeignet.",
        "RESPONSE_FLIGHT.API.FLIGHT.TICKET.FAILURE.INVALID.PARENT.AGE": "Das Erwachsenenalter ist für Reisen mit einem Baby nicht geeignet.",
        "RESPONSE_FLIGHT_API _FLIGHT_SSR_TICKET_FAILURE_REQ_SSR_UNAVAILABLE": "Der von Ihnen ausgewählte Rollstuhl ist für diesen Flug nicht verfügbar.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.INVALID_AGE_FOR_PAX_TYPE": "Bitte überprüfen Sie das eingegebene Geburtsdatum.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.INVALID_IDENTITY_INFO": "Bitte überprüfen Sie die eingegebenen Passagierdaten.",
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.UNMAPPED_ERROR": "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        "RESPONSE_FLIGHT_API.FLIGHT_REVERSE.FAILURE.UNMAPPED_ERROR": "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_EMPTY_SERVICE_RESPONSE: "Es gab keine Bestätigung seitens der Airline. Bitte wenden Sie sich an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_EMPTY_SERVICE_RESPONSE_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_REJECTED: "Es gab keine Bestätigung seitens der Airline. Bitte wenden Sie sich an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_REJECTED_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_UNMAPPED_ERROR: "Von der Airline kam keine Antwort. Bitte wenden Sie sich wegen der Fehlerursache an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_UNMAPPED_ERROR_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_REJECTED_NOT_IMPLEMANTED_SERVICE: "Bitte wenden Sie sich an das Service Center, um Gepäck zu kaufen,",
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_REJECTED_NOT_IMPLEMANTED_SERVICE_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_COMMON_ERROR: "Beim Wechseln zur Fluginformationsseite ist ein unbekannter Fehler aufgetreten.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_REQUESTED_CLASS_NOT_AVAILABLE: "Preisinformationen gelten nicht für die von Ihnen gewählte Klasse. Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNKNOWN_ERROR: "Beim Wechseln zur Fluginformationsseite ist ein unbekannter Fehler aufgetreten.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: "Beim Wechseln zur Fluginformationsseite ist ein unbekannter Fehler aufgetreten.",
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_NOT_EXISTS_PACKAGE_NOT_EXIST: "Für den ausgewählten Flug wurden keine Kabineninformationen gefunden.",
        RESPONSE_FLIGHT_API_FLIGHT_DIVIDE_PNR_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Ihre Reservierungsinformationen haben sich geändert. Ein Automatikbetrieb ist nicht möglich. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_COMMON_ERROR: "Wir haben einen unbekannten Fehler festgestellt.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR: "Wir haben einen unbekannten Fehler festgestellt. Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNMAPPED_ERROR: "Wir haben einen unbekannten Fehler festgestellt.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_COMMON_ERROR: "Bei der Buchung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_UNKNOWN_ERROR: "Bei der Buchung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_UNMAPPED_ERROR: "Bei der Buchung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_COMMON_ERROR: "Bei der Buchung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_UNKNOWN_ERROR: "Bei der Buchung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_UNMAPPED_ERROR: "Bei der Buchung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_COMMON_ERROR: "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_CONTINUE_WITH_INSTANT_TICKETING: "Die Fluggesellschaft nimmt keine Reservierungen vor. Bitte versuchen Sie direkt zu kaufen.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_DUBLICATE_PASSENGER: "Möglicherweise haben Sie ein früheres Ticket oder eine Reservierung für die Details, die Sie bearbeiten möchten. Bitte erkundigen Sie sich bei unserem Service-Center-Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_EMPTY_SERVICE_RESPONSE: "Von der Airline kam keine Antwort. Bitte wenden Sie sich wegen der Fehlerursache an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_EXCEEDED_MAX_PAX: "Die Fluggesellschaft akzeptiert die angegebene Anzahl von Passagieren nicht. Bitte kontaktieren Sie unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_HES_CODE_DECLINED: "Ihr HES - Code ist ungültig. Bitte überprüfen Sie Ihr HES - Code ",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_IDENTITY_OR_PASSPORT_NUMBER_MANDATORY: "Ihr T. C .  Identitätsinformationen oder Passinformationen sin ungüldig. Bitte geben Sie die angeforderten Informationen ein.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_AGE_FOR_PAX_TYPE: "Bitte überprüfen Sie das eingegebene Geburtsdatum.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_CONTACT_INFO: "Bitte überprüfen Sie die eingegebenen Kontaktdaten.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_IDENTITY_INFO: "Bitte überprüfen Sie die eingegebenen Passagierdaten.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_MINIMUM_CONNECTION_TIME: "Mindestverbindungszeit überschritten. Bitte überprüfen Sie Ihre Flüge.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_NOT_ABLE_TO_VERIFY_LOYALTY_CARD: "Bitte geben Sie Ihre Meilennummer und Identitätsinformationen korrekt ein.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_PRICE_CHANGED: "Die Preisinformationen können sich geändert haben.  Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_REQUESTED_CLASS_NOT_AVAILABLE: "Für den gewünschten Flug und die Klasse ist kein Platz mehr verfügbar",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SELECTED_FLIGHT_NOT_AVAILABLE_FOR_BOOKING: "Eine Reservierung ist nicht möglich.  Bitte wenden Sie sich an das Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SERVICE_ERROR: "Bei der Buchung ist ein unbekannter Fehler aufgetreten.<br> Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SSR_LIMIT_EXCEEDED: "Das von der Fluggesellschaft hinzugefügte SSR-Limit wurde überschritten.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_UNKNOWN_ERROR: "Bei der Buchung ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_UNMAPPED_ERROR: "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_WAIT_LIST_CLOSED: "Für den gewünschten Flug und die Klasse ist kein Platz mehr verfügbar.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_COMMON_ERROR: "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_INVALID_PASSENGER_INFO: "Bitte überprüfen Sie die eingegebenen Passagierdaten.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_UNKNOWN_ERROR: "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_UNMAPPED_ERROR: "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_REJECTED_CONTINUE_WITH_INSTANT_TICKETING: "Die Fluggesellschaft nimmt keine Reservierungen vor. Bitte versuchen Sie direkt zu kaufen.",
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_REJECTED_MINIMUM_CONNECTION_TIME: "Bitte überprüfen Sie die Flugzeiten.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_COMMON_ERROR: "Wir haben einen unbekannten Fehler festgestellt. Bitte wenden Sie sich an unser Service-Center-Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_EMPTY_SERVICE_RESPONSE: "Von der Airline kam keine Antwort. Bitte wenden Sie sich wegen der Fehlerursache an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_INVALID_PASSENGER_INFO: "Ihre Fahrgastdaten haben sich geändert.Bitte wenden Sie sich an das Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_SERVICE_ERROR: "Sie haben einen Verbindungsfehler erhalten.\t Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNKNOWN_ERROR: "Wir haben einen unbekannten Fehler festgestellt. Bitte wenden Sie sich an unser Service-Center-Team.",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNKNOWN_ERROR_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNMAPPED_ERROR: "Wir haben einen unbekannten Fehler festgestellt. Bitte wenden Sie sich an unser Service-Center-Team.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_EXPIRED_REVERSE_INQUIRY_EXPIRED: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_EMPTY_SERVICE_RESPONSE: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_REJECTED: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_REVERSE_TYPE_CHANGED: "Aufgrund der manuellen Bearbeitung Ihres Tickets können wir Ihre Transaktion derzeit nicht bearbeiten.  Bitte öffnen Sie eine Aufgabe für unser Service-Center-Team.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_UNKNOWN_ERROR: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_UNMAPPED_ERROR: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_CONNECTION_UNKNOWN_ERROR: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_EMPTY_SERVICE_RESPONSE: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Ihre Reservierungsinformationen haben sich geändert. Ein Automatikbetrieb ist nicht möglich. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_SERVICE_ERROR: "Während des Stornierungsvorgangs haben Sie eine Fehlermeldung erhalten. Bitte wenden Sie sich an das Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_UNMAPPED_ERROR: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_REJECTED_UNKNOWN_ERROR: "Bei der Ticketausstellung ist ein unbekannter Fehler aufgetreten. Bitte kontaktieren Sie unser Service Center Team, um den Status Ihres Tickets zu erfahren.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_REJECTED_NOT_IMPLEMANTED_SERVICE: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_REJECTED_UNKNOWN_ERROR: "Sie haben während des Stornierungsprozesses einen Fehler erhalten. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Ihre Reservierungsinformationen haben sich geändert. Ein Automatikbetrieb ist nicht möglich. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_SEARCH_CONNECTION_UNKNOWN_ERROR: "Beim Ticketing ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_CONNECTION_UNKNOWN_ERROR: "Wir haben einen unbekannten Fehler festgestellt. Bitte wenden Sie sich an unser Service-Center-Team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_COMMON_ERROR: "Beim Ticketing ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INSUFFICIENT_STOCK: "Sie haben eine Fehlermeldung vom Anbieter erhalten. Bitte wenden Sie sich an unser Service-Center-Team",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INVALID_CONTACT_INFO: "Bitte überprüfen Sie die von Ihnen eingegebenen Kontaktinformationen.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INVALID_PASSENGER_INFO: "Bitte überprüfen Sie die von Ihnen eingegebenen Passagierinformationen.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_PNR_HAS_BEEN_MODIFIED: "Ihr Pnr-Status hat sich geändert. Bitte wenden Sie sich an unser Service-Center-Team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATED_FLIGHT_CHANGED: "Ihre Flugdaten haben sich geändert. Bitte wenden Sie sich an das Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATED_PASSENGER_INFO_CHANGED: "Ihre Fahrgastdaten haben sich geändert.Bitte wenden Sie sich an das Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: "Ihre Reservierungsinformationen haben sich geändert. Ein Automatikbetrieb ist nicht möglich. Bitte wenden Sie sich an das Service-Center.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNKNOWN_ERROR: "Während der Buchung ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNMAPPED_ERROR: "Beim Ticketing ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_REJECTED_REQUESTED_CLASS_NOT_AVAILABLE: "Preisinformationen gelten nicht für die von Ihnen gewählte Klasse. Bitte setzen Sie Ihre Suche fort, indem Sie erneut suchen.",
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_TIMEOUT_SERVICE_TIMEOUT: "Ihre Sitzung ist abgelaufen. Bitte versuchen Sie es erneut.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_FLIGHT_SCHEDULE_CHANGED: "Ihre Flugdaten haben sich geändert. Bitte wenden Sie sich an das Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_FLIGHT_SCHEDULE_CHANGED_TITLE: "Information",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_REISSUE_NOT_AVAIL: "Eine Umbuchung für Ihren Flug kann nicht online vorgenommen werden. Bitte kontaktieren Sie das Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_REISSUE_NOT_AVAIL_TITLE: "Informationen",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNKNOWN_ERROR: "Ihre Flug- oder Passagierinformationen stimmen nicht mit der Fluggesellschaft überein. Bitte wenden Sie sich an das Service Center.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNKNOWN_ERROR_TITLE: "Wichtige Informationen",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNMAPPED_ERROR: "Bei der Abfrage der Umbuchung ist ein unbekannter Fehler aufgetreten. Bitte kontaktieren Sie unser Service Center Team, um die Ursache des Fehlers zu klären.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNMAPPED_ERROR_TITLE: "Wir haben einen unbekannten Fehler festgestellt.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_REJECTED_NOT_IMPLEMANTED_SERVICE: "Bitte wenden Sie sich an das Service Center, um eine Umbuchung vorzunehmen.",
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_REJECTED_NOT_IMPLEMANTED_SERVICE_TITLE: "Information",
        "RESPONSE_FLIGHT_API_TICKET_EXCEPTION,": "Ein unbekannter Fehler ist aufgetreten.",
        "RESPONSE_FLIGHT_API_TICKET_FAILURE,_COMMON_ERROR,": "Beim Ticketing ist ein unbekannter Fehler aufgetreten.",
        "RESPONSE_FLIGHT_API_TICKET_FAILURE,_UNKNOWN_ERROR,": "Ein unbekannter Fehler ist aufgetreten.",
        RESPONSE_FLIGHT_API_TICKET_FAILURE_DUBLICATE_PASSENGER: "Möglicherweise haben Sie ein früheres Ticket oder eine Reservierung für die Details, die Sie bearbeiten möchten. Bitte erkundigen Sie sich bei unserem Service-Center-Team.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_COMMON_ERROR: "Beim der Ticketerstellung ist ein unbekannter Fehler aufgetreten.<br> Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_INSUFFICIENT_STOCK: "Sie haben eine Fehlermeldung vom Anbieter erhalten. Bitte wenden Sie sich an unser Service-Center-Team",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_UNKNOWN_ERROR: "Beim Ticketing ist ein unbekannter Fehler aufgetreten.<br> Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_UNMAPPED_ERROR: "Beim Ticketing ist ein unbekannter Fehler aufgetreten.<br> Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_COMMON_ERROR: "Wir haben einen unbekannten Fehler festgestellt.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_UNKNOWN_ERROR: "Wir haben einen unbekannten Fehler festgestellt.",
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_UNMAPPED_ERROR: "Wir haben einen unbekannten Fehler festgestellt.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_COMMON_ERROR: "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_UNKNOWN_ERROR: "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_UNMAPPED_ERROR: "Beim Ticketing ist ein Fehler aufgetreten. Bitte wenden Sie sich wegen des Fehlers an unser Service Center Team.",
        "RESPONSE_HOTEL_API.TICKET.EXCEPTION.MrCannotStay": "Mr. Stays werden von diesem Hotel nicht akzeptiert. Bitte schließen Sie die Seite und suchen Sie erneut.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_[holder.phone] allows digit only": "Bitte füllen Sie den Abschnitt mit den Kontaktinformationen erneut mit Zahlen aus.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_error.hotel.not.accepts.only.man": "Mr. Stays werden von diesem Hotel nicht akzeptiert. Bitte schließen Sie die Seite und suchen Sie erneut.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_error.no.enough.room.quota": "Beim Ausführen Ihrer Transaktion ist das Zimmerkontingent ausgebucht. Bitte schließen Sie die Seite und suchen Sie erneut oder wenden Sie sich an unser Service-Center Team.",
        RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_ExistingOfferId: "Bitte schließen Sie diese Seite und aktualisieren Sie Ihre Suche.",
        RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_MrCannotStay: "Mr. Stays werden von diesem Hotel nicht akzeptiert. Bitte schließen Sie die Seite und suchen Sie erneut.",
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_REJECTED_MR.STAY": "Buchungen nur für Herren werden von diesem Hotel nicht akzeptiert.",
        "RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_ExistingOfferId_This offerid already requested for booking": "Bitte schließen Sie diese Seite und recherchieren Sie Ihr Angebot erneut.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_GTS_BOOKING: "Beim Ausführen Ihrer Transaktion ist das Zimmerkontingent ausgebucht. Bitte schließen Sie die Seite und suchen Sie erneut oder wenden Sie sich an unser Service-Center Team.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_NO_ROOM_AVAILABLE: "Beim Ausführen Ihrer Transaktion ist das Zimmerkontingent ausgebucht. Bitte schließen Sie die Seite und suchen Sie erneut oder wenden Sie sich an unser Service-Center Team.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_ROOM_HAS_STOP: "Beim Ausführen Ihrer Transaktion ist das Zimmerkontingent ausgebucht. Bitte schließen Sie die Seite und suchen Sie erneut oder wenden Sie sich an unser Service-Center Team.",
        "RESPONSE_HOTEL_API_TICKET_HOTEL_REJECTED_MR.STAY": "Der von Ihnen gewünschte <strong>Unterkunftstyp</strong> ist für dieses Hotel nicht geeignet. Bitte wenden Sie sich an das Service Center.",
        RESPONSE_HOTEL_API_TICKET_HOTEL_SUCCESS_OK: "Ihre Transaktion wurde erfolgreich abgeschlossen.",
        RESPONSE_PRICE_CHANGE: "Während Ihrer Transaktion gab es eine Preisdifferenz.",
        RESPONSE_REJECTED_NOT_AVAILABLE: "Die Fluggesellschaft erlaubt keine Umbuchungen für den betreffenden Flug.",
        RESPONSE_SUCCESS_OK: "Die Mail wurde erfolgreich versendet.",
        RESPONSE_TICKET_HOTEL_EXCEPTION_JP_BOOK_OCCUPANCY_NAMES_ERROR: "Die Namen der Gäste dürfen nicht identisch sein. Bitte überprüfen Sie es und versuchen Sie es erneut.",
        RESPONSE_TICKET_TRANSFER_SUCCESS_OK: "Sie haben Ihren Transfer erfolgreich gebucht. Bitte überprüfen Sie Ihre Transaktion.",
        RESPONSE_TRANSFER_API_TICKET_TRANSFER_ISSUED_OK: "Sie haben Ihren Transfer erfolgreich gebucht. Bitte überprüfen Sie Ihre Transaktion.",
        RESULT_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR: "Wir haben einen unbekannten Fehler festgestellt. Bitte wenden Sie sich an unser Service-Center-Team.",
        RESULT_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_REJECTED: "Bei der Buchung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an unser Service Center Team, um die Ursache des Fehlers zu erfahren.",
        roomCard_lblRoomPrice: "Zimmerpreis",
        roomCardComponent_roomDetail_buttonLabel: "Zimmerinformation",
        roomCardComponent_roomPriceLabel: "Zimmerpreis",
        router_agencyRequired: "Nur Agenten können auf diese Seite zugreifen.",
        router_chooseAgency: "Bitte Agentur auswählen.",
        router_contactForAuth: "Wenn Sie der Meinung sind, dass ein Fehler vorliegt, wenden Sie sich bitte an uns!",
        router_noAuth: "Sie sind nicht autorisiert auf diese Seite zuzugreifen!",
        router_noInvalidOtp: "Information",
        router_noInvalidOtpNote: "Bitte überprüfen Sie Ihr Token.",
        router_titleAccTransactionList: "Kontenverlauf",
        router_titleBalanceUpdate: "Kontostand Aktualisieren",
        router_titleDashboard: "Dashboard",
        router_titleDashboard2: "Dashboard",
        router_titleFlightCheckout: "Flug Kaufen",
        router_titleFlightSale: "AGENT",
        router_titleFlightSalesReport: "Flugverkaufsbericht",
        router_titleHotelCache: "Hotel-Cache-Suche",
        router_titleHotelCheckout: "Hotel Kaufen",
        router_titleHotelSale: "AGENT",
        router_titleHotelSalesReport: "Hotelverkaufsbericht",
        router_titleInvoiceList: "Rechnungsliste",
        router_titleLogin: "Login",
        router_titleNotification: "Benachrichtigung",
        router_titleOrderDetail: "Order Detail",
        router_titleOrderSearch: "Order Suche",
        router_titlePackageSearch: "Pauschalreisen",
        router_titlePageNotFound: "Seite Nicht Gefunden",
        router_titlePnrDoc: "PNR Dokument",
        router_titleRegister: "Registrieren",
        router_titleRentACar: "Mietwagen",
        router_titleRentACarCheckut: "Rent A Car Checkout",
        router_titleRentACarSalesReport: "Mietwagen-Verkaufsbericht",
        router_titleSchmetterlingSalesReport: "Pauschalreisen-Verkaufsbericht",
        router_titleSettings: "Einstellungen",
        router_titleTaskList: "Aufgabenliste",
        router_titleTestDev: "TestDev",
        router_titleTransferCheckout: "Transfer Checkout",
        router_titleTransferSale: "Transfer  Kaufen",
        router_titleTransferSalesReport: "Transferverkaufsbericht",
        router_titleVersion: "Version",
        saleReport_airlineList: "Fluggesellschaften",
        saleReport_creationFromDate: "Startdatum",
        saleReport_creationToDate: "Enddatum",
        saleReport_optEticket: "E-Ticket",
        saleReport_optPNR: "Pnr",
        saleReport_pnrStatus: "Pnr-Status",
        saleReport_providerList: "Anbieterliste",
        saleReport_reportType: "Berichtsart",
        saleReport_showReissues: "Umbuchungen anzeigen",
        saleReport_userList: "Benutzerliste",
        schmetterlingSalesReport_filter_btnList: "Auflistung",
        schmetterlingSalesReport_filter_lblCustomerName: "Kundenname",
        schmetterlingSalesReport_filter_lblCustomerSurname: "Kunden Nachname",
        schmetterlingSalesReport_filter_lblFromDate: "Erstellung ab Datum",
        schmetterlingSalesReport_filter_lblReservationNo: "Schmetterling-ID",
        schmetterlingSalesReport_filter_lblStatus: "Status",
        schmetterlingSalesReport_filter_lblToDate: "Erstellung Bis Heute",
        schmetterlingSalesReport_filter_plhCustomerName: "Kundenname",
        schmetterlingSalesReport_filter_plhCustomerSurname: "Kunden Nachname",
        schmetterlingSalesReport_filter_plhReservationNo: "Schmetterling-ID",
        schmetterlingSalesReport_tblMain_thCheckInDateTime: "Check-in-Datum",
        schmetterlingSalesReport_tblMain_thCheckOutDateTime: "Check-out-Datum",
        schmetterlingSalesReport_tblMain_thCustomerNameSurname: "Kundenname und Nachname",
        schmetterlingSalesReport_tblMain_thOrderId: "Order-ID",
        schmetterlingSalesReport_tblMain_thReservationNo: "Schmetterling-ID",
        schmetterlingSalesReport_tblMain_thStatus: "Status",
        schmetterlingSalesReport_tblMain_thTotalPrice: "Gesamtgebühr",
        schmetterlingSalesReport_tblMain_thTourOperator: "Reiseveranstalter",
        schmetterlingSalesReport_tblMain_thTravelType: "Reiseart",
        schmetterlingSalesReport_tblMain_thUser: "Benutzer-ID, die die Reservierung erstellt hat",
        schmetterlingSalesReport_title: "Pauschalreisen-Verkaufsbericht",
        Search_Response_AGENT_API_FLIGHT_SEARCH_SESSION_INVALID_AGENCY: "Bitte Agentur auswählen.",
        Search_Response_AGENT_WEB_FLIGHT_SEARCH_TIMEOUT_SERVICE_TIMEOUT: "Während Ihrer Buchung ist ein Fehler aufgetreten.  Sie können Ihre Suche fortsetzen, indem Sie erneut suchen.",
        Search_Response_FLIGHT_API_FLIGHT_SEARCH_CONNECTION_UNKNOWN_ERROR: "Sie haben bei der Suche nach einem Flug einen Verbindungsfehler erhalten.",
        searchComponentFlight_addLeg: "Flug Hinzufügen",
        searchComponentFlight_airlines: "Fluggesellschaften",
        searchComponentFlight_airlinesSelected: "Fluggesellschaft Ausgewählt",
        searchComponentFlight_autocompleteArrival: "Ankunft",
        searchComponentFlight_autocompleteDeparture: "Abflug",
        searchComponentFlight_cabinTypes: "Kabinenklasse",
        searchComponentFlight_cheapestPrice: "Niedrigster Paketpreis",
        searchComponentFlight_departureDate: "Hinflugdatum",
        searchComponentFlight_filterAirlines: "Fluggesellschaften filtern",
        searchComponentFlight_flightRules: "Flugregeln",
        searchComponentFlight_lastSeat: "Letzter Sitz",
        searchComponentFlight_paxes: "Passagiere",
        searchComponentFlight_removeLeg: "Flug Entfernen",
        searchComponentFlight_returnDate: "Rückflugdatum",
        searchComponentFlight_searchFlight: "FLÜGE SUCHEN",
        searchComponentFlight_stops: "Zwischenstopps",
        searchComponentFlight_travelDuration: "Reisedauer",
        searchComponentFlight_waitingTime: "Wartezeit",
        searchComponentHotel_autocompleteLocationPlaceholder: "Suche nach: Stadt, Region oder Unterkunft",
        searchComponentHotel_dateLabel: "Check-In - Check-Out Datum",
        searchComponentHotel_destinationLabel: "Suche nach: Stadt, Region oder Unterkunft",
        searchComponentHotel_guestLabel: "Anzahl der Gäste",
        searchComponentHotel_nationalityLabel: "Abreiseland",
        searchFiltersFlight_airlines: "Fluggesellschaften",
        searchFiltersFlight_airlineTransfer: "Flughafentransfer",
        searchFiltersFlight_arrivalHour: "Abflug - Ankunftzeit",
        searchFiltersFlight_baggageAllowance: "Freigepäck",
        searchFiltersFlight_business: "Business",
        searchFiltersFlight_cabinTypes: "Kabinenklasse",
        searchFiltersFlight_connections: "Zwischenstopps",
        searchFiltersFlight_departureHour: "Abflug - Startzeit",
        searchFiltersFlight_deselectAll: "Löschen",
        searchFiltersFlight_economy: "Economy",
        searchFiltersFlight_EFltConnectionType_airlineTransfer: "Flughafentransfer",
        searchFiltersFlight_EFltConnectionType_noneStop: "Direktflug",
        searchFiltersFlight_EFltConnectionType_stop: "Zwischenstopp",
        searchFiltersFlight_EFltConnectionType_UNKNOWN: "Unbekannt",
        searchFiltersFlight_noBaggage: "Kein Gepäck",
        searchFiltersFlight_noneStop: "Direktflug",
        searchFiltersFlight_selectAll: "Alle auswählen",
        searchFiltersFlight_stop: "Zwischenstopp",
        searchFiltersFlight_time: "Abflug- / Ankunftszeiten",
        searchFiltersFlight_withBaggage: "Mit Gepäck",
        searchFiltersHotel_deselectAll: "Alles löschen",
        searchFiltersHotel_lblCustomPriceRange: "Eigenes Budget festlegen",
        SearchInformationPopupFlight_allAirports: "Alle Flughäfen",
        SearchInformationPopupFlight_text: "Suche läuft, bitte warten.",
        SearchOrderList_lblBrandPnr: "Fluggesellschaft Pnr",
        seatMapModal_lblYanyana: "Nebeneinander",
        SeatMapModal_progressGif: "Ihre Transaktion wird fortgesetzt. Bitte warten.",
        serviceCenter_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE: "Ihre Anfrage wurde bereits an unser Service Center Team weitergeleitet.  Bitte folgen Sie Ihrer Anfrage im Menüpunkt Service Center.",
        serviceCenter_AGENT_API_TASK_CREATE_SUCCESS_OK: "Ihre Anfrage wurde erfolgreich an unser Team weitergeleitet. Sie können Ihre Anfrage über das Service Center verfolgen.",
        serviceCenter_ancillary: "Zusatzleistung",
        serviceCenter_cancellationRefund: "Stornierung - Erstattung",
        serviceCenter_openTicket: "Open Ticket",
        serviceCenter_reissue: "Umbuchung",
        serviceCenterComponent_tabChangeDetails_label: "Flugplanänderung",
        serviceCenterComponent_tabMyNotes_label: "Meine Notizen",
        serviceCenterComponent_tabServiceCenterRequestForm_label: "Service Center Anfrageformular",
        serviceCenterComponent_tabTaskChat_label: "Aufgabendetails",
        serviceCenterRequestForm_btnSend: "Senden",
        serviceCenterRequestForm_popupTaskCreate: "Ihre Anfrage wurde erfolgreich an unser Team weitergeleitet. Sie können Ihre Anfrage über das Service Center verfolgen.",
        serviceCenterRequestForm_taskOpeningInfos: "TASKTYPE wird für Ihre Anfrage SELECTION verarbeitet.",
        Set_Customer_SUCCESS_OK: "Erfolgreich",
        setting_profileSettings: "Profileinstellungen",
        settings_customerManagement: "Kundentransaktionen",
        settings_customerSettings: "Kundentransaktionen",
        settings_profileSettings: "Profileinstellungen",
        settings_userAuthorization: "Benutzerberechtigungen",
        settings_userAuths: "Benutzerberechtigungen",
        settings_userSettings: "Benutzereinstellungen",
        settingsPage_customerBookSubPage_newCustomerAddButtonText: "Neuen Kunden hinzufügen",
        settingsPage_customerBookSubPage_pageTitle: "Kundentransaktionen",
        settingsPage_lblRegionLanguagePreferencesDescription: "Informationen über Ihre Agentur.",
        settingsPage_navMenu_customerSettings: "Kundentransaktionen",
        settingsPage_navMenu_profileSettings: "Profileinstellungen",
        settingsPage_navMenu_userRoles: "Benutzerberechtigungen",
        settingsPage_navMenu_userSettings: "Benutzereinstellungen",
        settingsPage_profileSettingsSubPage_agencyInformationDescription: "Informationen über Ihre Agentur.",
        settingsPage_profileSettingsSubPage_agencyInformationLabel: "Agenturinformationen",
        settingsPage_profileSettingsSubPage_browseProfilePhotoButtonText: "Aus der Galerie wählen",
        settingsPage_profileSettingsSubPage_dragDropFileText: "Datei ziehen und ablegen oder",
        settingsPage_profileSettingsSubPage_pageTitle: "Profil Bearbeiten",
        settingsPage_profileSettingsSubPage_profilePhotoDescription: "Die Fotogröße, die Sie hochladen möchten, sollte wie folgt sein.",
        settingsPage_profileSettingsSubPage_profilePhotoLabel: "Profilfoto",
        settingsPage_profileSettingsSubPage_regionAndLanguagePreferencesDescription: "Informationen zu Ihrer Agentur.",
        settingsPage_profileSettingsSubPage_regionAndLanguagePreferencesLabel: "Regions- und Telefoneinstellungen",
        settingsPage_profileSettingsSubPage_userInformationDescription: "Informationen zu Ihrem Benutzer.",
        settingsPage_profileSettingsSubPage_userInformationLabel: "Nutzerinformation",
        settingsPage_userAuthorizationSubPage_pageTitle: "Benutzerberechtigungen",
        settingsPage_userSettingsSubPage_pageTitle: "Benutzereinstellungen",
        SignIn_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "Bitte stellen Sie sicher, dass die von Ihnen eingegebenen Informationen korrekt sind.",
        SignIn_CookieText: "Wir verwenden Cookies in Übereinstimmung mit unserer Richtlinie zu personenbezogenen Daten, um sicherzustellen, dass wir Ihnen das beste Ergebnis auf unserer Website bieten.",
        SignIn_emailIsValidating: "Sie wird gerade überprüft.",
        SignIn_forgotPassword: "Passwort vergessen!",
        SignIn_legalText: "Mit der Anmeldung stimmen Sie den",
        SignIn_legalText2: "und",
        SignIn_legalText3: "einschließlich",
        SignIn_legalTextCookieUse: "Cookie-Verwendung.",
        SignIn_legalTextPrivacyPolicy: "Datenschutzbestimmungen zu",
        SignIn_legalTextTermsOfService: "Nutzungsbedingungen",
        SignIn_loginHeader1: "Willkommen",
        SignIn_loginHeader2: "Bitte loggen Sie sich in das System ein.",
        SignIn_signInBtn: "Einloggen",
        SignIn_TITLE_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: "Ein Fehler ist aufgetreten",
        SignIn_userEmail: "Benutzer-E-mail",
        SignIn_userPassword: "Benutzer Passwort",
        ssrSelection_lblSsrTitle: "Ausgewählte Marke:",
        ssrSelectionBox_lblBAGGAGEDescription: "Zusätzliches Gepäck wird zu Ihrer aktuellen Auswahl hinzugefügt.",
        ssrSelectionBox_lblbaggageTitle: "Zusätzliches Gepäck",
        ssrSelectionBox_lblCABIN_BAGDescription: "1 Stück Handgepäck",
        ssrSelectionBox_lblcabin_bagTitle: "Handgepäck",
        ssrSelectionBox_lblcheckin_at_airportTitle: "Check-in am Flughafen",
        ssrSelectionBox_lblreissue_allowanceTitle: "Sun Flex 3",
        ssrSelectionBox_lblwheelchairTitle: "Rollstuhl",
        targetAchievement_lblConfetti: "Herzlichen Glückwunsch!",
        targetAchievement_lblInformaton: ".",
        targetAchievement_lblTarget: "Ziel",
        targetAchievement_lblTitle: "Anreiz",
        taskDetails_openingNote: "Eröffnungsnotiz",
        taskDetails_subStatus: "Substatus",
        taskDetails_taskOpenTime: "Task Öffnungszeit",
        taskDetails_type: "Art",
        taskList_filter_btnList: "Auflisten",
        taskList_filter_lblFromDate: "Startdatum",
        taskList_filter_lblStatus: "Status",
        taskList_filter_lblToDate: "Enddatum",
        taskList_filter_lblType: "Aufgabentyp",
        taskList_tblMain_thAgency: "Agenturname",
        taskList_tblMain_thClosingType: "Beendigungsart",
        taskList_tblMain_thCreatedUser: "Vom Benutzer geöffnete Aufgabe",
        taskList_tblMain_thEntity: "Produkt",
        taskList_tblMain_thEntityType: "Produktart",
        taskList_tblMain_thGsa: "Gsa",
        taskList_tblMain_thId: "Task Id",
        taskList_tblMain_thNote: "Eröffnungsnotiz",
        taskList_tblMain_thOrderId: "Order ID",
        taskList_tblMain_thOrderStatus: "Order Status",
        taskList_tblMain_thStatus: "Aufgabenstatus",
        taskList_tblMain_thSubStatus: "Substatus",
        taskList_tblMain_thTaskStatus: "Task Status",
        taskList_tblMain_thType: "Aufgabentyp",
        taskList_tblMain_thUpdatedDateTime: "Letztes Update",
        "TaskList_tblTaskMng.thETaskClosingType": "Beendigungsart",
        "TaskList_tblTaskMng.thETaskSubStatus": "Substatus",
        "TaskList_tblTaskMng.thETaskType": "Aufgabentyp",
        taskList_title: "Service Center",
        taskListPage_pageTitle: "Service Center",
        taskListPage_searchForm_endDateLabel: "Enddatum:",
        taskListPage_searchForm_startDateLabel: "Startdatum",
        taskListPage_searchForm_statusLabel: "Status",
        taskListPage_searchForm_taskTypeLabel: "Aufgabentyp",
        taskListPage_taskListDataTable_taskAgencyNameLabel: "Agenturname",
        taskListPage_taskListDataTable_taskClosingTypeLabel: "Beendigungsart",
        taskListPage_taskListDataTable_taskGsaLabel: "Gsa",
        taskListPage_taskListDataTable_taskIdLabel: "Task Id",
        taskListPage_taskListDataTable_taskLastUpdateTimeLabel: "Letztes Update",
        taskListPage_taskListDataTable_taskOpenedUserEmailLabel: "Vom Benutzer geöffnete Aufgabe",
        taskListPage_taskListDataTable_taskOpeningDescriptionLabel: "Eröffnungsnotiz",
        taskListPage_taskListDataTable_taskOrderIdLabel: "Order ID",
        taskListPage_taskListDataTable_taskPnrLabel: "Pnr",
        taskListPage_taskListDataTable_taskStatusLabel: "Aufgabenstatus",
        taskListPage_taskListDataTable_taskSubStatusLabel: "Substatus",
        taskListPage_taskListDataTable_taskTypeLabel: "Aufgabentyp",
        taskNotes_placeholder: "Hier können Sie Ihre Notiz schreiben.",
        taxInformation_city_tax: "Stadtsteuer",
        taxInformation_electricity_fee: "Stromgebühr",
        taxInformation_excludedTaxes: "Nicht inbegriffene Steuern",
        taxInformation_includedTaxes: "Inklusive Steuern",
        taxInformation_service_fee: "Servicegebühr",
        taxInformation_vat: "Steuer",
        tblOrderSearch_thAirlinePnr: "Fluggesellschaft Pnr",
        test_arrayParamAdult: "{0} adult var",
        test_childAge1: "çocuk odası {0} ile {1} arasında ok",
        test_childAge2: "çocuk odası {0} ile {1} arasında ok ve diğer çocuk {0} ile {1} arası ok",
        test_dynamic_text: "hans hanna {value} fsdfsdfsdfsdfs",
        test_test: "test",
        tokenDirection_lblAppInstallation: "App-Installation",
        tokenDirection_lblAppInstallationDownloadAppStore: "Herunterladen Auf der",
        tokenDirection_lblAppInstallationDownloadPlayStore: "Einschalten",
        tokenDirection_lblAppInstallationGuideText: "Laden Sie die Google Authenticator-App auf Ihr Smartphone herunter, indem Sie den unten stehenden QR-Code scannen.",
        tokenDirection_lblAppInstallationGuideTextOrVisit: "oder besuchen Sie deisen Link #VALUE#",
        tokenDirection_lblAppInstallationOpenToken: "Öffnen Sie die Token-Anwendung",
        tokenDirection_lblAppInstallationQrBelowInfo: "Die Seite, die sich durch scannen des QR-Codes öffnet, leitet Sie automatisch zum kompatiblen Store für Ihr Gerät weiter. Wenn die Weiterleitung nicht funktioniert, versuchen Sie manuell auf die Links zu klicken.",
        tokenDirection_lblAppInstallationValidateToken: "Token validieren",
        tokenDirection_lblExtraSecurityLayer: "Zusätzliche Sicherheitsebene",
        tokenDirection_lblExtraSecurityLayerInfo1: "Die endgültige Lösung gegen den Diebstahl Ihrer Konten ist die Verwendung von Token. Sie können den Token aktivieren, indem Sie die nächsten Schritte befolgen.",
        tokenDirection_lblExtraSecurityLayerInfo2: "Aktivieren Sie, indem Sie sagen: #VALUE#",
        tokenDirection_lblExtraSecurityLayerLastPageInfo: "Beim Öffnen des zweiten Verifizierungsschritts ist ein Fehler aufgetreten? Sie können #VALUE# anrufen, um unser Service Center Team zu kontaktieren.",
        tokenDirection_lblExtraSecurityLayerLastPageInfo2: "oder rufen Sie #VALUE# an.",
        tokenDirection_lblGeneratingVerificationCode: "Generierung des Verifizierungscodes",
        tokenDirection_lblGeneratingVerificationCodeText1: "Verwenden Sie die Authenticator-App, indem Sie Ihr Google-Konto öffnen.",
        tokenDirection_lblGeneratingVerificationCodeText2: "Klicken Sie auf die Schaltfläche #VALUE#  in der unteren rechten Ecke der App, um einen Code hinzuzufügen.",
        tokenDirection_lblGeneratingVerificationCodeText3: "Tippen Sie auf das Feld QR-Code scannen, erlauben Sie der App den Kamerazugriff und scannen Sie den unten stehenden QR-Code.",
        tokenDirection_lblLetsGo: "Lass uns anfangen!",
        tokenDirection_lblLoginWithToken: "Anmeldung mit Token",
        tokenDirection_lblLoginWithTokenText1: "Ihr 6-stelliger Verifizierungscode wird an die Authenticator-App gesendet.",
        tokenDirection_lblLoginWithTokenText2: "Verwenden Sie diesen Verifizierungscode für das Token-Feld auf dem Anmeldebildschirm von #VALUE# bei jeder Anmeldung",
        tokenDirection_lblSkip: "Ausschalten",
        tokenDirection_lblSupportService: "Support-Service",
        tokenValidate_lblInfoText1: "Geben Sie den 6-stelligen Code, der zu Ihrem Konto passt, in Ihre Authenticator-App ein.",
        tokenValidate_lblInfoText2: "Wenn Sie den Verifizierungscode in Ihrer Authenticator-Anwendung nicht sehen, gehen Sie zurück zum vorherigen Schritt und wiederholen Sie den Vorgang.",
        tokenValidate_lblTitle: "Validieren Sie Ihren Token",
        TransactionList_FLIGHT_ANCILLARY: "Flugticket Zusatzleistung",
        TransactionList_PNR_COMMISSION_REVERSE: "Rückerstattung der Flugprovision",
        transactionListPage_AgencyBalance: "Saldo",
        transactionListPage_pageTitle: "Kontenverlauf",
        transactionListPage_searchForm_endDateLabel: "Enddatum",
        transactionListPage_searchForm_startDateLabel: "Startdatum",
        transactionListPage_transactionListDataTable_transactionBalanceLabel: "Saldo",
        transactionListPage_transactionListDataTable_transactionCreditLabel: "Haben",
        transactionListPage_transactionListDataTable_transactionDateLabel: "Datum",
        transactionListPage_transactionListDataTable_transactionDebtLabel: "Soll",
        transactionListPage_transactionListDataTable_transactionEntityLabel: "Produkt",
        transactionListPage_transactionListDataTable_transactionInfoLabel: "Beschreibung",
        transactionListPage_transactionListDataTable_transactionOrderIdLabel: "Order ID",
        transactionListPage_transactionListDataTable_transactionProviderLabel: "Anbietername",
        transactionListPage_transactionListDataTable_transactionTrxTypeLabel: "Art der Transaktion",
        transactionListPage_transactionListDataTable_transactionUserEmailLabel: "Benutzer-E-mail",
        transactionPage_transactionDataTable_balanceBeginLabel: "Saldo",
        transactionPage_transactionDataTable_balanceEndLabel: "Aktueller Saldo",
        transactionPage_transactionDataTable_balanceTotalLabel: "Summe der Transaktionen",
        transfer_fillTravelerRows: "Füllen Sie",
        transfer_fillTravelerRowsCancelBtn: "Abbrechen",
        transfer_fillTravelers: "Passagierinformationen",
        transfer_fillTravelersContent: "Möchten Sie die Passagierdaten automatisch ausfüllen lassen?",
        transfer_lblAdult: "Erwachsene",
        transfer_lblChild: "Kinder",
        transfer_lblDepartureDate: "Abholdatum / Rücktransfer",
        transfer_lblExtraFeature: "Zusätzliche Funktionen",
        transfer_lblFromName: "Von (A)",
        transfer_lblInfant: "Babys",
        transfer_lblManuelRequest: "Es gibt keine vordefinierten Preisinformationen für die von Ihnen gewünschte Strecke. Sie können uns Ihre Anfrage senden, indem Sie auf die Schaltfläche Manuelle Anfrage klicken.",
        transfer_lblPickupReturnDate: "Pickup Date / Return Date",
        transfer_lblProvider: "Anbieter",
        transfer_lblReturnDate: "Rückfahrt",
        transfer_lblToName: "Nach (B)",
        transfer_lblTravelerCount: "Anzahl der Pax",
        transfer_lblTravellers: "Anzahl der Reisenden",
        transfer_manuelRequest: "Manuell Anfrage",
        transfer_manuelRequestCancel: "Abbrechen",
        transfer_manuelRequestConfirm: "Anfrage bestätigen",
        transfer_manuelRequestModalTitle: "Manuelle Anfrage erstellen",
        transfer_phOrSimilar: "ODER ÄHNLICH",
        transfer_RepresentativeVehicle: "Beispielbilder",
        transfer_searchTransfer: "TRANSFER SUCHEN",
        transfer_travelerBirthDate: "Geburtstag",
        transfer_travelerNameSurname: "Vor- und Nachname",
        transfer_travelerType: "Passagiertyp",
        transferAutocomplete_lblDistrict: "Kreis",
        transferAutocomplete_lblHotel: "Hotel",
        transferAutocomplete_phFromDestination: "Von: Flughafen / Hotel",
        transferAutocomplete_phToDestination: "Nach: Flughafen / Hotel",
        transferBasket_btnClear: "Alles löschen",
        transferBasket_lblTitle: "Warenkorb",
        transferBasket_lblTotalFare: "Gesamt",
        TransferCard_btnRemoveCar: "Transfer ändern",
        TransferCard_btnSelectCar: "Transfer Auswählen",
        TransferCard_lblBaggage: "Gepäck",
        TransferCard_lblCapacity: "Fahrzeugkapazität",
        TransferCard_lblCardInformationText: "Beispielbilder",
        TransferCard_lblInformation: "",
        TransferCard_lblMax: "Max Gäste",
        transferCheckout_agencyExtraCCommission: "Zusätzliche Provision für Agenten hinzugefügt",
        transferCheckout_lblAgencyCommission: "Agenturkommission",
        transferCheckout_lblAgencyExtraCommission: "Agenturprovision Extra",
        TransferCheckout_lblAirCondition: "Klimaanlage",
        TransferCheckout_lblArrival: "Ankunftszeit",
        TransferCheckout_lblBaggage: "Gepäck",
        TransferCheckout_lblDeparture: "Abflugzeit",
        transferCheckout_lblGrandTotal: "Gesamtbetrag",
        TransferCheckout_lblImportantNote1: "Bitte überprüfen Sie die Transferinformationen auf diesem Voucher.",
        TransferCheckout_lblImportantNote2: "Sie können sich für Stornierungen und Änderungen an Ihre Agentur wenden.",
        TransferCheckout_lblImportantNote3: "Die Bestätigung des Flughafen- oder Hoteltreffens wird Ihnen am Tag vor Ihrem Transfer per WhatsApp-Nachricht zugesandt. Wenn Sie die Bestätigungsinformationen nicht erhalten, können Sie Ihre Abholzeit mindestens 12 Stunden vor Ihrem Transfer über unsere Notruf-Kontaktnummer +90 531 720 21 28 bestätigen.",
        TransferCheckout_lblImportantNote4: "Die Wartezeit Ihres Transfers für die Abholung vom Flughafen beträgt 60 Minuten ab der Landung des Flugzeugs. Wenn Ihre Gepäck- und Passkontrollen im Terminal länger dauern sollten, teilen Sie uns dies bitte telefonisch mit.",
        TransferCheckout_lblImportantNote5: "Die Bestätigung Ihres Flughafen- oder Hoteltreffens wird Ihnen einen Tag vor Ihrem Transfer per WhatsApp-Nachricht zugesandt. Wenn Sie keine Bestätigungsinformationen erhalten, können Sie Ihre Abholzeit vom Hotel mindestens 12 Stunden vor Ihrem Transfer bestätigen, indem Sie unsere Notrufnummern #VALUE# und #VALUE1# anrufen.",
        TransferCheckout_lblLastVoidDate: "Kostenlos stornieren bis zum",
        TransferCheckout_lblLightning: "Beleuchtung",
        TransferCheckout_lblMax: "Max Gäste",
        TransferCheckout_lblPickUp: "Uhrzeit der Abholung",
        TransferCheckout_lblPickupTime: "Datum und Uhrzeit der Abholung",
        TransferCheckout_lblPnr: "PNR",
        transferCheckout_lblTransferDescriptionsTitle: "Transfer Information",
        transferCheckout_titleGrandTotal: "Gesamtsumme",
        TransferCheckout_txtPickupInfo: "Informationen über den Treffpunkt am Flughafen oder die Abholzeit im Hotel werden dem Passagier einen Tag vorher per Whatsapp-Nachricht auf sein Handy geschickt.",
        TransferFilters_deselectAll: "Alle Abwählen",
        TransferFilters_lblFilters: "Filter",
        TransferFilters_lblTransferTypes: "Transfertyp",
        TransferFilters_lblVehicles: "Fahrzeuge",
        TransferFilters_selectAll: "Alle Auswählen",
        TransferInformations_lblAgencyCommissionExtra: "Agenturprovision Extra",
        TransferInformations_lblAgencyCommissionRatio: "Agenturkommission",
        TransferInformations_lblCar: "Transfer",
        TransferInformations_lblGrandTotal: "Gesamtsumme",
        TransferInformations_lblTotalPrice: "Gesamtgebühr (Netto)",
        TransferInformations_transferInformations: "Transferinformationen",
        transferOrderDetail_lblAgencyCommission: "Agenturkommission",
        transferOrderDetail_lblBaseTotal: "Nettopreis",
        transferOrderDetail_lblGrandTotal: "Gesamtbetrag",
        transferOrderDetail_lblReverseTotal: "Rückerstattungsbetrag",
        transferOrderDetail_lblSalesTotal: "Gesamtumsatz",
        transferOrderDetail_lblTransferAgencyNote: "Hinweis der Agentur",
        transferReissue_popupReiusseSuccessSubtitle: "Ihre Änderung wurde erfolgreich durchgeführt.",
        transferSalesReport_currency: "Währung",
        transferSalesReport_filter_btnList: "Auflisten",
        transferSalesReport_filter_lblFromDate: "Startdatum",
        transferSalesReport_filter_lblReportType: "Berichtsart",
        transferSalesReport_filter_lblStatus: "Status",
        transferSalesReport_filter_lblToDate: "Enddatum",
        transferSalesReport_legCount: "Anzahl der Strecken",
        transferSalesReport_refundTotal: "Gesamterstattung",
        transferSalesReport_tblMain_thAgencyCommission: "Agenturprovision",
        transferSalesReport_tblMain_thAgencyName: "Agenturname",
        transferSalesReport_tblMain_thFrom: "Abholung",
        transferSalesReport_tblMain_thFromDate: "Erstellungsdatum",
        transferSalesReport_tblMain_thOrderId: "Order ID",
        transferSalesReport_tblMain_thProvider: "Anbietername",
        transferSalesReport_tblMain_thSaleDate: "Verkaufsdatum",
        transferSalesReport_tblMain_thStatus: "Status",
        transferSalesReport_tblMain_thTnr: "Tnr",
        transferSalesReport_tblMain_thTo: "Reiseziel",
        transferSalesReport_tblMain_thTotal: "Gesamtgebühr",
        transferSalesReport_tblMain_thTransferDate: "Transfer Datum",
        transferSalesReport_tblMain_thTransferType: "Transfertyp",
        transferSalesReport_tblMain_thTravelerCount: "Anzahl der Pax",
        transferSalesReport_tblMain_thTravelerName: "Vorname des Passagiers",
        transferSalesReport_tblMain_thTravelerSurname: "Nachname des Passagiers",
        transferSalesReport_tblMain_thVehicleType: "Fahrzeugtyp",
        transferSalesReport_title: "Transferverkaufsbericht",
        transferSalesReport_tnrCount: "Anzahl der Order",
        transferSalesReport_totalAmount: "Gesamtverkauf",
        transferSalesReport_travelerCount: "Anzahl der Passagiere",
        TransferSearch_lblCarFound: "Transfer(s) gefunden.",
        transferSearch_lblEmptyTransferList: "Es wurde kein Transfer gefunden, dass Ihren Suchkriterien entspricht. Bitte versuchen Sie es erneut, indem Sie Ihre Suchkriterien ändern.",
        transferSearch_lblFromDestination: "Abholung",
        transferSearch_lblToDestination: "Reiseziel",
        TransferSearch_lblTransferRoute: "Von #VALUE# bis #VALUE1#",
        transferSearchInformation_adult: "Erwachsen",
        transferSearchInformation_child: "#COUNT# Kind",
        transferSearchInformation_data: "Personenzahl",
        transferSearchInformation_date1: "Abholzeit",
        transferSearchInformation_date2: "Datum der Rückfahrt",
        transferSearchInformation_infant: "#COUNT# Babys",
        transferSearchInformation_locationFrom: "Abholung",
        transferSearchInformation_locationTo: "Reiseziel",
        transferSearchInformation_text: "Auf der Suche nach den besten Preisen, bitte warten!",
        transferTravelerInformationsComponent_travelerInformations: "Informationen für Reisende",
        userAuths_authChanged: "Autorität Geändert.",
        UserAuths_authError: "Nicht Berechtigt.",
        UserAuths_BalanceUpdate: "Guthaben Aufladen",
        UserAuths_CustomerSettings: "Kundentransaktionen",
        UserAuths_FlightCheckout: "Flugkauf",
        UserAuths_FlightSale: "Flugsuche",
        UserAuths_FlightSalesReport: "Flugverkaufsbericht",
        UserAuths_HotelCheckout: "Hotelkauf",
        UserAuths_HotelSale: "Hotel suchen",
        UserAuths_HotelSalesReport: "Hotelverkaufsbericht",
        UserAuths_InvoiceList: "Rechnungsliste",
        userAuths_lblAccTransactionList: "Kontenverlauf",
        userAuths_lblBalanceUpdate: "Guthaben Aufladen",
        userAuths_lblCustomerSettings: "Kundentransaktionen",
        userAuths_lblDashboardV2: "Dashboard V2",
        userAuths_lblFlightCheckout: "Flugkauf",
        userAuths_lblFlightSale: "Flugsuche",
        userAuths_lblFlightSalesReport: "Flugverkaufsbericht",
        userAuths_lblHotelCheckout: "Hotelkauf",
        userAuths_lblHotelSale: "Hotel suchen",
        userAuths_lblHotelSalesReport: "Hotelverkaufsbericht",
        userAuths_lblInvoiceList: "Rechnungsliste",
        userAuths_lblNotification: "Benachrichtigungen",
        userAuths_lblOrderDetail: "Order Einsehen",
        userAuths_lblOrderSearch: "Order Suche",
        userAuths_lblPersonalAuth: "Einzelner Benutzer",
        userAuths_lblProfileSettings: "Profileinstellungen",
        userAuths_lblRentACar: "Mietwagen",
        userAuths_lblRentACarCheckout: "Mietwagen buchen",
        userAuths_lblRentACarSale: "Rent A Car Suche",
        userAuths_lblRentACarSalesReport: "Mietwagen-Verkaufsbericht",
        userAuths_lblSchimetterling: "Paketsuche",
        userAuths_lblSchmetterlingSalesReport: "Pauschalreisen-Verkaufsbericht",
        userAuths_lblTaskList: "Service Center",
        userAuths_lblTransactionList: "Kontenverlauf",
        userAuths_lblTransferCheckout: "Transfer Buchen",
        userAuths_lblTransferSale: "Transfersuche",
        userAuths_lblTransferSalesReport: "Transferverkaufsbericht",
        userAuths_lblUserAuths: "Benutzerberechtigungen (Admin)",
        userAuths_lblUserSettings: "Benutzereinstellungen",
        UserAuths_Notification: "Benachrichtigungen",
        UserAuths_OrderDetail: "Order Einsehen",
        UserAuths_OrderSearch: "Order Suche",
        UserAuths_ProfileSettings: "Profileinstellungen",
        UserAuths_TaskList: "Service Center",
        userAuths_thAuth: "Berechtigung",
        userAuths_thId: "ID",
        userAuths_titleUserAuths: "Benutzerberechtigungen",
        UserAuths_TransactionList: "Kontenverlauf",
        UserAuths_TransferCheckout: "Transfer Buchen",
        UserAuths_TransferSale: "Transfersuche",
        userAuths_userActive: "Aktiv",
        UserAuths_UserAuths: "Benutzerberechtigungen",
        userAuths_userPassive: "Passiv",
        UserAuths_UserSettings: "Benutzereinstellungen",
        userControlBox_lnkDashboard: "Dashboard",
        userControlBox_lnkLogout: "Ausloggen",
        userControlBox_lnkSettings: "Einstellungen",
        userSettings_lblUserEmail: "E-mail",
        userSettings_lblUserSettings: "Benutzereinstellungen",
        userSettings_mailSet: "Ihre Mail-Einstellungen haben sich geändert!",
        widgetHeaderComponent_addNew: "Neue Hinzufügen",
        widgetHeaderComponent_backtoFirst: "Zurück zur ersten Version",
        widgetHeaderComponent_highlighter: "Highlight",
        widgetHeaderComponent_removeWidget: "Widget Entfernen",
        widgetHeaderComponent_resetToDefault: "Zurück zur Ersten Version",
        widgetHeaderComponent_toggleExtras: "Extras öffnen/schließen",
        widgetHeaderComponent_turnOnArrangement: "Konfiguration öffnen"
    },
    ru: {
        "25 gtsGrid_item": null,
        accountTransactionList_filter_btnList: null,
        accountTransactionList_filter_lblFromDate: null,
        accountTransactionList_filter_lblToDate: null,
        accountTransactionList_tblMain_thBalance: null,
        accountTransactionList_tblMain_thBalanceBegin: null,
        accountTransactionList_tblMain_thBalanceEnd: null,
        accountTransactionList_tblMain_thCredit: null,
        accountTransactionList_tblMain_thCurrency: "Валюта                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        accountTransactionList_tblMain_thDate: null,
        accountTransactionList_tblMain_thDebt: null,
        accountTransactionList_tblMain_thEntity: null,
        accountTransactionList_tblMain_thInfo: null,
        accountTransactionList_tblMain_thOrderId: null,
        accountTransactionList_tblMain_thProvider: null,
        accountTransactionList_tblMain_thTrxSum: null,
        accountTransactionList_tblMain_thTrxType: null,
        accountTransactionList_tblMain_thUserEmail: null,
        accountTransactionList_tblMain_title: null,
        accountTransactionList_tblMain_trProvider: null,
        accountTransactionList_tblSumm_thBalance: null,
        accountTransactionList_tblSumm_thCredit: null,
        accountTransactionList_tblSumm_thDebt: null,
        accountTransactionList_tblSumm_thTrxType: null,
        accountTransactionList_tblSumm_title: null,
        accountTransactionList_title: null,
        addTask_success: null,
        addWidget_btnSave: null,
        addWidget_lblAddWidget: null,
        addWidget_lblFlightForthComingTitle: null,
        addWidget_lblFlightGirosTitle: null,
        addWidget_lblFlightPerformanceTitle: null,
        addWidget_lblFlightReservationsTitle: null,
        addWidget_lblHotelCheckInTitle: null,
        addWidget_lblHotelPerformanceTitle: null,
        addWidget_lblHotelReservationsTitle: null,
        addWidget_lblRentACarWidgetTitle: null,
        addWidget_lblTranferWidgetTitle: null,
        agencyAccountComponent_availableBalance: null,
        agencyAccountComponent_balance: null,
        agencyAccountComponent_balanceUpdate: null,
        agencyAccountComponent_definedCredit: null,
        AgencyExtraCommission_checkOutInformation: null,
        AgencyExtraCommission_title: null,
        AGENT_API_FLIGHT_CHECKOUT_EXPIRED_CHECKOUT: null,
        airlineEnum_6Y: null,
        airlineEnum_QS: null,
        alert_emptyAmount: null,
        alert_emptyHotelList: null,
        alert_warningPopupTitle: null,
        ancillariesServices_baggage: null,
        ancillariesServices_checkIn: null,
        ancillariesServices_descriptionsunflex3: "SunFlex 3, Rebooking free of charge 3 days prior to departure (+ price difference)                                                                                                                                                                                                                                                                                                                                                                                                                                  ",
        ancillariesServices_divide: null,
        ancillariesServices_food: null,
        ancillariesServices_lblMealsFooterInfo: null,
        ancillariesServices_lblWheelChairFooterInfo: null,
        ancillariesServices_meal: "Meal Selection                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ",
        ancillariesServices_meals: null,
        ancillariesServices_onlineCheckin: "Online Check-in                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        ancillariesServices_reissue: null,
        ancillariesServices_relatedPNRInfos: null,
        ancillariesServices_relatedPPaymentMethods: null,
        ancillariesServices_seat: null,
        ancillariesServices_selectfood: null,
        ancillariesServices_selectssr: "Sun Flex 3 Select                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        ancillariesServices_selectwheelchair: null,
        ancillariesServices_SunFlex3: "Sun Flex 3                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        ancillariesServices_title: null,
        ancillariesServices_wheelChair: null,
        ancillariesServicesModal_baggageContentSuccess: null,
        ancillariesServicesModal_baggageFailedTitle: null,
        ancillariesServicesModal_baggageSuccessTitle: null,
        ancillariesServicesModal_baggeFailedMessage: null,
        ancillariesServicesModal_btnClose: null,
        ancillariesServicesModal_btnConfirm: null,
        ancillariesServicesModal_btnTryAgain: "Искать снова                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ",
        ancillariesServicesModal_BuyWithCurrentPaymentMethod: null,
        ancillariesServicesModal_contentFailed: null,
        ancillariesServicesModal_contentSuccess: null,
        ancillariesServicesModal_divideAfterDividePNR: null,
        ancillariesServicesModal_divideCurrentPNR: null,
        ancillariesServicesModal_divideFailedMessage: null,
        ancillariesServicesModal_divideFailedTitle: null,
        ancillariesServicesModal_divideSuccessMessage: null,
        ancillariesServicesModal_divideSuccessTitle: null,
        ancillariesServicesModal_infos: null,
        ancillariesServicesModal_infoTextReissueStep2: null,
        ancillariesServicesModal_infoTxtPlsSelectFlights: null,
        ancillariesServicesModal_lblAgencyExtraComm: null,
        ancillariesServicesModal_lblArrivalAirport: null,
        ancillariesServicesModal_lblArrivalAirportPlaceholder: null,
        ancillariesServicesModal_lblArrivalDate: null,
        ancillariesServicesModal_lblCancelSelection: null,
        ancillariesServicesModal_lblDepartureAirport: null,
        ancillariesServicesModal_lblDepartureAirportPlaceholder: null,
        ancillariesServicesModal_lblDepartureDate: null,
        ancillariesServicesModal_lblExtraComm: null,
        ancillariesServicesModal_lblFlight: null,
        ancillariesServicesModal_lblGrandTotal: null,
        ancillariesServicesModal_lblPayment: null,
        ancillariesServicesModal_lblReserve: "РЕЗЕРВ                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        ancillariesServicesModal_lblSearch: null,
        ancillariesServicesModal_lblSearchResultCount: null,
        ancillariesServicesModal_lblSSR: "Sun Flex 3                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        ancillariesServicesModal_lblSSRDesc: "SunFlex 3, Rebooking free of charge 3 days prior to departure (+ price difference)                                                                                                                                                                                                                                                                                                                                                                                                                                  ",
        ancillariesServicesModal_lblTotalSalesAmount: null,
        ancillariesServicesModal_mealsContentSuccess: null,
        ancillariesServicesModal_mealsFailedMessage: null,
        ancillariesServicesModal_mealsFailedTitle: null,
        ancillariesServicesModal_mealsSuccessTitle: null,
        ancillariesServicesModal_NO_BRAND_FOUND: "Марка вашего бронирования не была найдена авиакомпанией. Пожалуйста, обратитесь в Центр обслуживания.                                                                                                                                                                                                                                                                                                                                                                                                               ",
        ancillariesServicesModal_NO_FLIGHT_FOUND: "Рейсов, соответствующих вашим критериям поиска, не найдено.                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        ancillariesServicesModal_otherPaymentMethods: null,
        ancillariesServicesModal_paymentStep: null,
        ancillariesServicesModal_reissueErrorMessage: null,
        ancillariesServicesModal_reissueErrorNoSearchResultText: null,
        ancillariesServicesModal_reissueErrorNoSearchResultTitle: null,
        ancillariesServicesModal_reissueErrorText: null,
        ancillariesServicesModal_reissueErrorTitle: null,
        ancillariesServicesModal_reissueSuccessText: null,
        ancillariesServicesModal_reissueSuccessTitle: null,
        ancillariesServicesModal_resetDivide: null,
        ancillariesServicesModal_stepDivide: null,
        ancillariesServicesModal_stepFlightSearchResult: null,
        ancillariesServicesModal_stepFlightSelection: null,
        ancillariesServicesModal_stepMealSelection: null,
        ancillariesServicesModal_stepPayment: null,
        ancillariesServicesModal_stepReissuePayment: null,
        ancillariesServicesModal_stepReissueResult: null,
        ancillariesServicesModal_stepResult: null,
        ancillariesServicesModal_stepSeatSelection: null,
        ancillariesServicesModal_stepSelectBaggage: null,
        ancillariesServicesModal_stepSssr: "Sun Flex Select                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        ancillariesServicesModal_stepSunFlex3: "Sun Flex 3                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        ancillariesServicesModal_stepWheelChairSelection: null,
        ancillariesServicesModal_sunFlexErrorMessage: "You received an error during Sun Flex purchase. Please contact the Service Center and try again.                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        ancillariesServicesModal_sunFlexSuccessMessage: "Your SunFlex 3 reservation has been made successfully.                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        ancillariesServicesModal_titleFailed: null,
        ancillariesServicesModal_titleSuccess: null,
        ancillariesServicesModal_wheelchairErrorMessage: null,
        ancillariesServicesModal_wheelchairErrorTitle: null,
        ancillariesServicesModal_wheelchairSuccessMessage: null,
        ancillariesServicesModal_wheelchairSuccessTitle: null,
        ancillarReissue_btnCalculateReissue: null,
        ancillarReissue_lblCalculateDescription: null,
        ancillarReissue_lblCalculateTitle: null,
        ancillarReissue_lblCalculatingFailedDescription: null,
        ancillarReissue_lblCalculatingFailedDescriptionFLIGHT_SAME_DAY: null,
        ancillarReissue_lblCalculatingFailedTitle: null,
        ancillarReissue_lblFlightResultTitleAirline: null,
        ancillarReissue_lblFlightResultTitleArrival: null,
        ancillarReissue_lblFlightResultTitleDeparture: null,
        ancillarReissue_lblFlightResultTitleFlightDate: null,
        ancillarReissue_lblFlightResultTitleFlightNu: null,
        ancillarReissue_lblFlightResultTitleFrom: null,
        ancillarReissue_lblFlightResultTitleStop: null,
        ancillarReissue_lblFlightResultTitleTo: null,
        ancillarReissue_lblPreviousStep: null,
        ancillaryReissueSelection_lblPnrHasAncillaries: "SSR, приобретенные для вашей старой брони, не будут перенесены на вашу бронь после переоформления.                                                                                                                                                                                                                                                                                                                                                                                                                  ",
        ancillarySeatMap_btnCancel: null,
        ancillarySeatMap_btnNextStep: null,
        ancillarySeatMap_btnPayment: null,
        ancillarySeatMap_btnPreviousStep: null,
        ancillarySeatMap_lblAvailable: null,
        ancillarySeatMap_lblCancel: null,
        ancillarySeatMap_lblErrorBtn: null,
        ancillarySeatMap_lblExtraLegRoom: "XL Сиденье                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        ancillarySeatMap_lblGrandTotal: null,
        ancillarySeatMap_lblNotAvailable: null,
        ancillarySeatMap_lblPassenger: null,
        ancillarySeatMap_lblPaxType: null,
        ancillarySeatMap_lblPayment: null,
        ancillarySeatMap_lblPrice: null,
        ancillarySeatMap_lblResult: null,
        ancillarySeatMap_lblResultEXCEPTION: null,
        ancillarySeatMap_lblResultEXPIRED: null,
        ancillarySeatMap_lblResultFAILURE: null,
        ancillarySeatMap_lblResultREJECTED: null,
        ancillarySeatMap_lblResultSUCCESS: null,
        ancillarySeatMap_lblResultTIMEOUT: null,
        ancillarySeatMap_lblSeat: null,
        ancillarySeatMap_lblSelected: null,
        ancillarySeatMap_lblSuccessBtn: null,
        announcement_homePage: null,
        announcement_showOriginalImage: null,
        announcement_updateTime: null,
        announcement_urlCopied: null,
        AnnouncementManagementList_thThumbnailImage: null,
        aside_accTransactionList: null,
        aside_balanceUpdate: null,
        aside_dashBoard: null,
        aside_flightSalesReport: null,
        aside_flightSearch: null,
        aside_hotelSalesReport: null,
        aside_hotelSearch: null,
        aside_invoiceList: null,
        aside_notifications: null,
        aside_orderSearch: null,
        aside_packageSearch: null,
        aside_rentACarSalesReport: null,
        aside_rentACarSearch: null,
        aside_schmetterlingSalesReport: null,
        aside_serviceCenter: null,
        aside_targetAchievements: null,
        aside_transferSalesReport: null,
        aside_transferSearch: null,
        BALANCE_UPDATE_REJECTED: null,
        BALANCE_UPDATE_REJECTED_INSUFFICIENT_PAYMENT: null,
        BALANCE_UPDATE_SUCCESS: null,
        BALANCE_UPDATE_SUCCESS_BALANCE_UPDATED: null,
        BALANCE_UPDATE_VALIDATION: null,
        BALANCE_UPDATE_VALIDATION_AMOUNT: null,
        balanceUpdate_btnDeposit: null,
        balanceUpdate_lblAmount: null,
        balanceUpdate_lblAvailableBalance: null,
        balanceUpdate_lblBalance: null,
        balanceUpdate_lblCommission: null,
        balanceUpdate_lblInfo: null,
        balanceUpdate_lblLoan: null,
        balanceUpdate_lblNote: null,
        balanceUpdate_lblTotal: null,
        BalanceUpdate_popup_REJECTED: null,
        BalanceUpdate_popup_REJECTED_INSUFFICIENT_PAYMENT: null,
        BalanceUpdate_popup_SUCCESS: null,
        BalanceUpdate_popup_SUCCESS_BALANCE_UPDATED: null,
        BalanceUpdate_popup_Validation: null,
        BalanceUpdate_popup_Validation_AMOUNT: null,
        BalanceUpdate_popup_Validation_ERROR: null,
        BalanceUpdate_progressGifStep1: null,
        BalanceUpdate_progressGifStep2: null,
        balanceUpdate_title: null,
        balanceUpdateComponent_balanceUpdateForm_commissionLabel: null,
        balanceUpdateComponent_balanceUpdateForm_descriptionLabel: null,
        balanceUpdateComponent_balanceUpdateForm_loadedBalanceLabel: null,
        balanceUpdateComponent_balanceUpdateForm_totalLabel: null,
        balanceUpdateComponent_paymentChoicesTab_creditCardAlertText: null,
        balanceUpdateComponent_paymentChoicesTab_sofortAlertText: null,
        balanceUpdatePage_buttonText: null,
        balanceUpdatePage_pageTitle: null,
        balanceUpdateSummaryComponent_availableBalanceLabel: null,
        balanceUpdateSummaryComponent_balanceLabel: null,
        balanceUpdateSummaryComponent_loanLabel: null,
        basketComponentFlight_baggageInformationChanges: null,
        basketComponentFlight_basket: null,
        basketComponentFlight_btnBaggageInformationConfirmation: null,
        basketComponentFlight_closeBasket: null,
        basketComponentFlight_goToCheckout: null,
        basketComponentFlight_newItemAddedorUpdated: null,
        basketComponentFlight_package: null,
        basketComponentFlight_sendOffer: null,
        basketComponentFlight_single: null,
        basketComponentFlight_singleOfferIsCheaper: null,
        basketComponentFlight_total: null,
        basketComponentHotel_sendOffer: null,
        cancellationPoliciesComponent_cancellationDateLabel: null,
        cancellationPolicy_lblFreeCancellationDate: null,
        cancellationPolicy_lblNoCancellationPolicy: null,
        cancellationPolicy_lblNoLastBookingDateDescription: null,
        cancellationPolicy_lblPackageSchmetterlingCancellationPolicy: "Чтобы ознакомиться с условиями отмены бронирования и всеми правилами, касающимися перелетов или размещения, ознакомьтесь с подробностями соответствующего бронирования в разделе отчета о продажах системы Smart Booking.                                                                                                                                                                                                                                                                                           ",
        changeDetails_lblarrivalAirportCode: "Прибытие                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        changeDetails_lblarrivalDateTime: "Время прибытия                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ",
        changeDetails_lblbrandCode: "Brand Code                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        changeDetails_lblbrandName: "Brand Name                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        changeDetails_lblcabinType: "Тип кабины                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        changeDetails_lbldepartureAirportCode: "Departure                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           ",
        changeDetails_lbldepartureDateTime: "Departure Time                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ",
        changeDetails_lblflightClass: "Класс                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        changeDetails_lblflightIndex: "Flight Index                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ",
        changeDetails_lblflightNumber: "Номер рейса                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        changeDetails_lblflightRoute: "Flight Route                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ",
        changeDetails_lblmarketingAirlineCode: "Marketing Airline                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        changeDetails_lbloperatingAirlineCode: "Operating Airline                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        changeDetails_lblsegmentIndex: "Segment Index                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        changeDetails_lblsegmentRoute: "Segment Route                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        changeHistory_lblHistory: "Информация о старом рейсе                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           ",
        changeHistory_lblLatestEntry: "Новая информация о рейсах                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           ",
        changeHistoryTable_lblTaskOpeningTime: "Время выполнения задания                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        changeHistoryTable_lblType: "Typ                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ",
        changeHistoryTable_thArrival: "Время прибытия                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ",
        changeHistoryTable_thBrandCode: "Brand Code                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        changeHistoryTable_thBrandName: "Brand Name                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        changeHistoryTable_thCabinType: "Тип кабины                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        changeHistoryTable_thClass: "Класс                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        changeHistoryTable_thDeparture: "Время отправления                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        changeHistoryTable_thFlightNo: "Номер рейса                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        changeHistoryTable_thFrom: "Отъезд                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        changeHistoryTable_thMarketingOperating: "Marketing/ Operating                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ",
        changeHistoryTable_thRoute: "Полет                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        changeHistoryTable_thTo: "Прибытие                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        changeItemDetails_lblBody: "Внимание! Информация о вашем рейсе была изменена. &gt; Для указанного ниже бронирования было изменено время вылета/полета. Пожалуйста, свяжитесь с вашим пассажиром и предоставьте письменное подтверждение об изменении                                                                                                                                                                                                                                                                                            ",
        changeItemDetails_lblClosing: "С наилучшими пожеланиями                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        changeItemDetails_lblGreeting: "ПриветствиеУважаемое агентство,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        chatBubbleComponent_sent: null,
        chatTabComponent_placeholder: null,
        checkout_coPayCancelBtn: null,
        checkout_lblMetaPolicy: "",
        checkout_lblTaxInformation: "",
        checkoutBasket_basket_addExtraCommission: null,
        checkoutBasket_basket_infos: null,
        checkoutBasket_basket_pnrInfos: null,
        checkoutBasket_titleBasket: null,
        checkoutContact_bntFillWithAgentInfo: null,
        checkoutContact_bntFillWithFirstPaxInfo: null,
        checkoutContact_contactInformations: null,
        checkoutContact_informationText: null,
        checkoutContact_lblFilledWithAgentInfos: null,
        checkoutContact_lblFilledWithFirstPaxInfos: null,
        checkoutContact_vldMin4: null,
        checkoutFlightPayment_alertNoReservationAndNoBookingBtns: null,
        checkoutFlightPayment_CHECKOUT_FAILED: null,
        checkoutFlightPayment_INSUFFICIENT_AGENCY_BALANCE: null,
        checkoutFlightPayment_lblBooking: null,
        checkoutFlightPayment_lblReservationCancel: null,
        checkoutFlightPayment_lblReservationRedirect: null,
        checkoutFlightPayment_lblReserve: null,
        checkoutFlightPayment_NOT_SALES_OFFICE: null,
        checkoutFlightPayment_SELECTED_BRANDS_NOT_VALID_NOW: null,
        checkoutNotes_lblInformations: null,
        common_add: null,
        common_address: null,
        common_adult: null,
        common_adultPluralExt: null,
        common_agencyCommission: null,
        common_agencyExtraCommission: null,
        common_agencyId: null,
        common_agencyName: null,
        common_agencyNote: null,
        common_airlines: null,
        common_all: null,
        common_allow: null,
        common_and: null,
        common_apr: null,
        common_april: null,
        common_arrivalFlightNumber: null,
        common_arrivaltime_decrease: null,
        common_arrivaltime_increase: null,
        common_aug: null,
        common_august: null,
        common_basket: null,
        common_birthday: null,
        common_booking: null,
        common_cancel: null,
        common_cancellationTime: null,
        common_cannotBeEmpty: null,
        common_capacity_decrease: null,
        common_capacity_increase: null,
        common_checkIn: null,
        common_checkOut: null,
        common_child: null,
        common_childPluralExt: null,
        common_chooseChildAge: null,
        common_city: null,
        common_clear: null,
        common_clearAll: null,
        common_close: null,
        common_code: null,
        common_confirmationTitle: null,
        common_countryCode: null,
        common_countryPhoneCode: null,
        common_countryPhoneCodeShort: null,
        common_currency: null,
        common_day: null,
        common_dec: null,
        common_december: null,
        common_decline: null,
        common_departure_decrease: null,
        common_departure_increase: null,
        common_departureFlightNumber: null,
        common_departureTime: null,
        common_description: null,
        common_deselectAll: null,
        common_disabled: null,
        common_disabledCompanion: null,
        common_discount: null,
        common_district: null,
        common_email: null,
        common_ethnic: "Ethnic (VFR)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ",
        common_eticket: null,
        common_etickets: null,
        common_eticketStatus: null,
        common_extraSeat: null,
        common_feb: null,
        common_february: null,
        common_female: null,
        common_flight: null,
        common_flightNumber: null,
        common_flightNumberOptional: null,
        common_fri: null,
        common_friday: null,
        common_fullName: null,
        common_gender: null,
        common_generalGrandTotal: null,
        common_generalTotalPrice: null,
        common_gotodashboard: null,
        common_grandTotal: null,
        common_gsa: null,
        common_gsaName: null,
        common_guest: null,
        common_hide: null,
        common_hotel: null,
        common_hotelName: null,
        common_hour: null,
        common_id: null,
        common_infant: null,
        common_info: null,
        common_information: null,
        common_insertedDate: null,
        common_insertedUser: null,
        common_itemsSelected: null,
        common_jan: null,
        common_january: null,
        common_jul: null,
        common_july: null,
        common_jun: null,
        common_june: null,
        common_languagePreference: null,
        common_lblGrandTotal: null,
        common_lblMediaOrientationMsg: "Продолжите процесс, поместив устройство в вертикальное положение.                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        common_list: null,
        common_male: null,
        common_mar: null,
        common_march: null,
        common_marketingAirlineCodes: null,
        common_may: null,
        common_minute: null,
        common_mon: null,
        common_monday: null,
        common_my: null,
        common_name: null,
        common_nameSurname: null,
        common_nationalIdentity: null,
        common_nationality: null,
        common_nodata: null,
        common_nothingSelected: null,
        common_nov: null,
        common_november: null,
        common_oct: null,
        common_october: null,
        common_officialName: null,
        common_ok: null,
        common_okbtn: null,
        common_open: null,
        common_optionExpiryTime: null,
        common_order: null,
        common_orderId: null,
        common_passenger: null,
        common_paxCount: null,
        common_person: null,
        common_phone: null,
        common_photoGallery: null,
        common_pnrs: null,
        common_pnrStatus: null,
        common_pnrStatuses: null,
        common_price: null,
        common_price_decrease: null,
        common_price_fpdecrease: "Цена пакета, снижение                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        common_price_fpincrease: "Цена пакета, увеличивается                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        common_price_increase: null,
        common_pritax: null,
        common_provider: null,
        common_providerName: null,
        common_purchase: null,
        common_readLess: null,
        common_readMore: null,
        common_recommended: null,
        common_removeRoom: null,
        common_reservation: null,
        common_reset: null,
        common_resetbtn: null,
        common_reversable: null,
        common_room: null,
        common_rooms: null,
        common_roomSelect: null,
        common_salesTotal: null,
        common_saleTime: null,
        common_sat: null,
        common_saturday: null,
        common_save: null,
        common_search: null,
        common_searchFlight: null,
        common_searchHotel: null,
        common_searchPackages: null,
        common_searchRentACar: null,
        common_searchRoom: null,
        common_searchTransfer: null,
        common_segment: "Segment                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ",
        common_selectAll: null,
        common_sep: null,
        common_september: null,
        common_serviceFee: null,
        common_shortName: null,
        common_show: null,
        common_showAllOption: null,
        common_showLess: null,
        common_showLessOption: null,
        common_showMore: null,
        common_showMoreOption: null,
        common_star_decrease: null,
        common_star_increase: null,
        common_status: null,
        common_success: null,
        common_sun: null,
        common_sunday: null,
        common_surname: null,
        common_tax: null,
        common_thu: null,
        common_thursday: null,
        common_timezone: null,
        common_toggle: null,
        common_total: null,
        common_total_penalty: null,
        common_total_refund: null,
        common_totalPrice: null,
        common_totalRefund: null,
        common_totaltraveltime_decrease: null,
        common_totaltraveltime_increase: null,
        common_totalwaitingtime_decrease: null,
        common_totalwaitingtime_increase: null,
        common_transfers_crease: null,
        common_transfers_increase: null,
        common_tue: null,
        common_tuesday: null,
        common_type: null,
        common_unknown: null,
        common_unknownChange: "Неизвестная деталь изменения.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        common_update: null,
        common_userEmail: null,
        common_voucher: null,
        common_wed: null,
        common_wednesday: null,
        common_writeANote: "Примечание                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        common_zwischenstopps: null,
        creditCardComponent_cvvLabel: null,
        creditCardComponent_holderLabel: null,
        creditCardComponent_monthLabel: null,
        creditCardComponent_panLabel: null,
        creditCardComponent_yearLabel: null,
        customcancel_lblcustomCancel: null,
        customerList_customerList: null,
        customerManagement_btnAddNewCustomer: null,
        customerManagement_deleteCustomer: null,
        customerManagement_editCustomer: null,
        customerManagement_popupCustomerAddedSubtitle: null,
        customerManagement_popupCustomerDeleteSubtitle: null,
        customerManagement_popupCustomerEditSubtitle: null,
        customerManagement_popupNewCustomerWillBeAdded: null,
        customerManagement_thBirthdate: null,
        customerManagement_thEmail: null,
        customerManagement_thNameSurname: null,
        customerManagement_thNationalIdentity: null,
        customerManagement_thNationality: null,
        customerManagement_thPassportNumber: null,
        customerManagement_thPhone: null,
        customerSet_btnAdd: null,
        customerSet_btnClose: null,
        customerSet_lblAgencyName: null,
        customerSet_lblBirthdate: null,
        customerSet_lblCityCode: null,
        customerSet_lblCountryCode: null,
        customerSet_lblDateofExpiry: null,
        customerSet_lblEmail: null,
        customerSet_lblGender: null,
        customerSet_lblHesCode: null,
        customerSet_lblMilesCard: null,
        customerSet_lblName: null,
        customerSet_lblNationalIdentity: null,
        customerSet_lblNationality: null,
        customerSet_lblPassportNumber: null,
        customerSet_lblPhoneNumber: null,
        customerSet_lblSerialNumber: null,
        customerSet_lblSurname: null,
        customerSettings_lblAction: null,
        customerSettings_lblAddNewCustomer: null,
        customerSettings_lblBirthday: null,
        customerSettings_lblCustomerSettings: null,
        customerSettings_lblEmail: null,
        customerSettings_lblFullName: null,
        customerSettings_lblNationalIdentity: null,
        customerSettings_lblNationality: null,
        customerSettings_lblPhone: null,
        dashboard_flightForthComings: null,
        dashboard_flightGiros: null,
        dashboard_flightPerformance: null,
        dashboard_flightReservations: null,
        dashboard_hotelPerformance: null,
        dashboard_hotelReservations: null,
        dashboard_hotelUpcomingCheckins: null,
        dashboard_rentACarUpcoming: null,
        dashboard_reportsGiro: null,
        dashboard_reservations: null,
        dashboard_transferUpcoming: null,
        dashboard_upcomings: null,
        dashboardAnnouncements_noContent: null,
        dashboardCampaigns_noContent: null,
        dashboardGiro_allProductsTotal: null,
        dashboardGiro_apexAjet: null,
        dashboardGiro_apexCorendon: null,
        dashboardGiro_apexFlight: null,
        dashboardGiro_apexHotel: null,
        dashboardGiro_apexOther: null,
        dashboardGiro_apexPegasus: null,
        dashboardGiro_apexRentACar: null,
        dashboardGiro_apexSunexpress: null,
        dashboardGiro_apexTransfer: null,
        dashboardGiro_apexTurkishAirlines: null,
        dashboardGiro_giroInfoText: null,
        dashboardGiro_gross: null,
        dashboardGiro_issuedEntityCount2: null,
        dashboardGiro_issuedEntityCount3: null,
        dashboardGiro_issuedEntityCount4: null,
        dashboardGiro_issuedEntityCount5: null,
        dashboardGiro_issuedEntityCountDesc2: null,
        dashboardGiro_issuedEntityCountDesc3: null,
        dashboardGiro_issuedEntityCountDesc4: null,
        dashboardGiro_issuedEntityCountDesc5: null,
        dashboardGiro_issuedEtickets: null,
        dashboardGiro_issuedPaxCount2: null,
        dashboardGiro_issuedPaxCount3: null,
        dashboardGiro_issuedPaxCountDesc2: null,
        dashboardGiro_issuedPaxCountDesc3: null,
        dashboardGiro_issuedPNR: null,
        dashboardGiro_issuedRoomCount3: null,
        dashboardGiro_issuedRoomCountDesc3: null,
        dashboardGiro_net: null,
        dashboardGiro_reservedEntityCount2: null,
        dashboardGiro_reservedEntityCount3: null,
        dashboardGiro_reservedEntityCountDesc2: null,
        dashboardGiro_reservedEntityCountDesc3: null,
        dashboardGiro_reservedEtickets: null,
        dashboardGiro_reservedPaxCount2: null,
        dashboardGiro_reservedPaxCountDesc2: null,
        dashboardGiro_reservedPNR: null,
        dashboardGiro_total: null,
        dashboardPage_announcements: null,
        dashboardPage_arrange_off: null,
        dashboardPage_arrange_on: null,
        dashboardPage_campaigns: null,
        dashboardPage_carModelRoute: null,
        dashboardPage_checkInDate: null,
        dashboardPage_driverName: null,
        dashboardPage_flight_Flights: null,
        dashboardPage_flight_Performance: null,
        dashboardPage_flight_Reservations: null,
        dashboardPage_flightMyReservations: null,
        dashboardPage_guestName: null,
        dashboardPage_hotel_Checkins: null,
        dashboardPage_hotel_Performance: null,
        dashboardPage_hotel_Reservations: null,
        dashboardPage_hotelMyCheckIns: null,
        dashboardPage_hotelMyReservations: null,
        dashboardPage_hotelName: null,
        dashboardPage_incompleteOrder: null,
        dashboardPage_lastBookingDateTime: null,
        dashboardPage_lblAnnouncementDetail: null,
        dashboardPage_lblAnnouncementList: null,
        dashboardPage_lblCampaignList: null,
        dashboardPage_lblReadMore: null,
        dashboardPage_lblShowDetails: null,
        dashboardPage_lblValidDayCount: null,
        dashboardPage_provideRoute: null,
        dashboardPage_providerRoute: null,
        dashboardPage_rentDate: null,
        dashboardPage_reservationStatus: null,
        dashboardPage_RNR: null,
        dashboardPage_showOldPosts: null,
        dashboardPage_Time: null,
        dashboardPage_TNR: null,
        dashboardPage_totalGiro: null,
        dashboardPage_transferDate: null,
        dashboardPage_travelerName: null,
        dashboardPage_welcomeBack: null,
        dashboardPage_widgetBookingDetails: null,
        dashboardPage_widgetBookingNumber: null,
        dashboardPage_widgetFlightMyPastReservations: null,
        dashboardPage_widgetFlightMyReservations: null,
        dashboardPage_widgetForthComingFlights: null,
        dashboardPage_widgetGiroAll: null,
        dashboardPage_widgetGiroFlight: null,
        dashboardPage_widgetHotelName: null,
        dashboardPage_widgetMyReservations: null,
        dashboardPage_widgetNameSurname: null,
        dashboardPage_widgetPastReservations: null,
        dashboardPage_widgetPnr: null,
        dashboardPage_widgetProviderRoute: null,
        dashboardPage_widgetReservationDetails: null,
        dashboardPage_widgetReservationNumber: null,
        dashboardPage_widgetReservationsAll: null,
        dashboardPage_widgetReservationsFlight: null,
        dashboardPage_widgetReservationsHotel: null,
        dashboardPage_widgetRnr: null,
        dashboardPage_widgetRoute: null,
        dashboardPage_widgetTime: null,
        dashboardPage_widgetTnr: null,
        dashboardPage_widgetUpcomingCharterFlights: null,
        dashboardPage_widgetUpcomingsAll: null,
        dashboardPage_widgetUpcomingsFlight: null,
        dashboardPage_widgetUpcomingsHotel: null,
        dashboardPage_widgetUpcomingsRentACar: null,
        dashboardPage_widgetUpcomingsTransfer: null,
        dashboardPage_widgetVoucher: null,
        dashboardTable_last72Hours: null,
        dashboardTable_onlyFlightChanges: "Изменение времени                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        dashboardTable_onlyOnlineCheckIns: null,
        dashboardTable_onlyPartialPaids: null,
        dashboardTable_onlyPastReservations: null,
        dashboardTable_onlyWKSC: "Только изменение расписания                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        dashboardTable_print: null,
        DocumentModal_lblEmail: null,
        documentViewer_copyToClipboard: null,
        documentViewer_couldntSendMail: null,
        documentViewer_deselectAll: null,
        documentViewer_documentCannotGet: null,
        documentViewer_generatePDF: null,
        documentViewer_mailSentSuccessful: null,
        documentViewer_mailSentSuccessfully: null,
        documentViewer_openPdf: null,
        documentViewer_paxBasedInfoTitle: null,
        documentViewer_phEmail: null,
        documentViewer_popupCopiedToClipboard: null,
        documentViewer_printDocument: null,
        documentViewer_selectAll: null,
        documentViewer_sendEmail: null,
        documentViewer_sendToWhatsapp: null,
        documentViewer_showCancelPolicies: null,
        documentViewer_showOptionDate: null,
        documentViewer_showPrice: null,
        documentViewer_documentCannotGet_documentCannotGet: null,
        EAccTransactionTypeGroup_BONUS: null,
        EAccTransactionTypeGroup_CC_COMMISSION: null,
        EAccTransactionTypeGroup_CC_PAYMENT: null,
        EAccTransactionTypeGroup_F_COM_VPOS: null,
        EAccTransactionTypeGroup_F_PAY_VPOS: null,
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY: null,
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY_REV: null,
        EAccTransactionTypeGroup_FLIGHT_ANCILLARY_REVERSE: null,
        EAccTransactionTypeGroup_FLIGHT_COMMISSION: null,
        EAccTransactionTypeGroup_FLIGHT_COMMISSION_REVERSE: null,
        EAccTransactionTypeGroup_FLIGHT_FARE: null,
        EAccTransactionTypeGroup_FLIGHT_FARE_REVERSE: null,
        EAccTransactionTypeGroup_H_COM_VPOS: null,
        EAccTransactionTypeGroup_H_PAY_VPOS: null,
        EAccTransactionTypeGroup_HOTEL_COMMISSION: null,
        EAccTransactionTypeGroup_HOTEL_COMMISSION_REVERSE: null,
        EAccTransactionTypeGroup_HOTEL_FARE: null,
        EAccTransactionTypeGroup_HOTEL_FARE_REVERSE: null,
        EAccTransactionTypeGroup_PAYMENT: null,
        EAccTransactionTypeGroup_R_COM_VPOS: null,
        EAccTransactionTypeGroup_R_PAY_VPOS: null,
        EAccTransactionTypeGroup_RENTACAR_ANCILLARY: null,
        EAccTransactionTypeGroup_RENTACAR_ANCILLARY_REVERSE: null,
        EAccTransactionTypeGroup_RENTACAR_COMMISSION: null,
        EAccTransactionTypeGroup_RENTACAR_COMMISSION_REVERSE: null,
        EAccTransactionTypeGroup_RENTACAR_EXTRA: null,
        EAccTransactionTypeGroup_RENTACAR_EXTRA_REVERSE: null,
        EAccTransactionTypeGroup_RENTACAR_FARE: null,
        EAccTransactionTypeGroup_RENTACAR_FARE_REVERSE: null,
        EAccTransactionTypeGroup_REV_CC_PAYMENT: null,
        EAccTransactionTypeGroup_REV_F_PAY_VPOS: null,
        EAccTransactionTypeGroup_REV_H_COM_VPOS: null,
        EAccTransactionTypeGroup_REV_H_PAY_VPOS: null,
        EAccTransactionTypeGroup_REV_R_PAY_VPOS: null,
        EAccTransactionTypeGroup_REV_T_COM_VPOS: null,
        EAccTransactionTypeGroup_REV_T_PAY_VPOS: null,
        EAccTransactionTypeGroup_SYSTEM_ERROR_CORRECTION: null,
        EAccTransactionTypeGroup_T_COM_VPOS: null,
        EAccTransactionTypeGroup_T_PAY_VPOS: null,
        EAccTransactionTypeGroup_TRANSFER: null,
        EAccTransactionTypeGroup_TRANSFER_COMMISSION: null,
        EAccTransactionTypeGroup_TRANSFER_COMMISSION_REVERSE: null,
        EAccTransactionTypeGroup_TRANSFER_FARE: null,
        EAccTransactionTypeGroup_TRANSFER_FARE_REVERSE: null,
        EAccTransactionTypeGroup_UNKNOWN: null,
        EAccTransactionTypeGroup_VIRTUAL_CREDIT: null,
        EComApplication_AGENT_SPA: null,
        EComGender_FEMALE: null,
        EComGender_MALE: null,
        EFltCabinType_BUSINESS: null,
        EFltCabinType_ECONOMY: null,
        EFltCabinType_UNKNOWN: "Неизвестный                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        EFltEticketStatus_11: "Reserve - Pending                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        EFltEticketStatus_ALL: null,
        EFltEticketStatus_BAGGAGE: null,
        EFltEticketStatus_CANCELED: null,
        EFltEticketStatus_ERROR: null,
        EFltEticketStatus_FOOD: null,
        EFltEticketStatus_ISSUED: null,
        EFltEticketStatus_MEAL: null,
        EFltEticketStatus_NEW: null,
        EFltEticketStatus_OPEN: null,
        EFltEticketStatus_OTHER: null,
        EFltEticketStatus_PENDING: null,
        EFltEticketStatus_PENDING_ISSUED: null,
        EFltEticketStatus_PENDING_REFUNDED: "Pending - Refunded                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ",
        EFltEticketStatus_PENDING_RESERVED: null,
        EFltEticketStatus_PENDING_VOIDED: "Pending - Void                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ",
        EFltEticketStatus_REFUNDED: null,
        EFltEticketStatus_REISSUED: null,
        EFltEticketStatus_RESERVED: null,
        EFltEticketStatus_SEAT: null,
        EFltEticketStatus_UNKNOWN: null,
        EFltEticketStatus_VOIDED: null,
        EFltEticketStatus_WHEELCHAIR: null,
        EFltPaxType_ADULT: null,
        EFltPaxType_ADULT_WITH_INFANT: null,
        EFltPaxType_ALL: null,
        EFltPaxType_CHILD: null,
        EFltPaxType_DISABLED_COMPANION: null,
        EFltPaxType_EXTRA_SEAT: null,
        EFltPaxType_INFANT: null,
        EFltPaxType_UNKNOWN: null,
        EFltPnrStatus_9: null,
        EFltPnrStatus_ALL: null,
        EFltPnrStatus_CANCELED: null,
        EFltPnrStatus_ERROR: null,
        EFltPnrStatus_ISSUED: null,
        EFltPnrStatus_NEW: null,
        EFltPnrStatus_OPEN: null,
        EFltPnrStatus_PENDING: null,
        EFltPnrStatus_PENDING_CANCELED: null,
        EFltPnrStatus_PENDING_CANCELLED: null,
        EFltPnrStatus_PENDING_ISSUED: null,
        EFltPnrStatus_PENDING_REFUNDED: null,
        EFltPnrStatus_PENDING_RESERVED: null,
        EFltPnrStatus_PENDING_VOIDED: null,
        EFltPnrStatus_REFUNDED: null,
        EFltPnrStatus_REISSUED: null,
        EFltPnrStatus_RESERVED: null,
        EFltPnrStatus_UNKNOWN: null,
        EFltPnrStatus_VOIDED: null,
        EFltSeatTypeEnum_AISLE: null,
        EFltSeatTypeEnum_EXIT: null,
        EFltSeatTypeEnum_EXTRA_LEGROOM: "XL Сиденье                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        EFltSeatTypeEnum_STANDARD: null,
        EFltSeatTypeEnum_UNKNOWN: null,
        EFltSeatTypeEnum_WINDOW: null,
        EFltSsrFoodType_AVML: null,
        EFltSsrFoodType_BBML: null,
        EFltSsrFoodType_CHML: null,
        EFltSsrFoodType_CNML: null,
        EFltSsrFoodType_DBML: null,
        EFltSsrFoodType_FPML: null,
        EFltSsrFoodType_FSML: null,
        EFltSsrFoodType_GFML: null,
        EFltSsrFoodType_GPML: null,
        EFltSsrFoodType_HNML: null,
        EFltSsrFoodType_KSML: null,
        EFltSsrFoodType_LCML: null,
        EFltSsrFoodType_LFML: null,
        EFltSsrFoodType_LPML: null,
        EFltSsrFoodType_LSML: null,
        EFltSsrFoodType_MOML: null,
        EFltSsrFoodType_NLML: null,
        EFltSsrFoodType_NOML: null,
        EFltSsrFoodType_PMIB: null,
        EFltSsrFoodType_PMIC: null,
        EFltSsrFoodType_PMID: null,
        EFltSsrFoodType_PMIE: null,
        EFltSsrFoodType_SFML: null,
        EFltSsrFoodType_SPML: null,
        EFltSsrFoodType_TBML: null,
        EFltSsrFoodType_VGML: null,
        EFltSsrFoodType_VJML: null,
        EFltSsrFoodType_VLML: null,
        EFltSsrFoodType_VOML: null,
        EFltSsrMealType_AVML: null,
        EFltSsrMealType_BBML: null,
        EFltSsrMealType_BLML: null,
        EFltSsrMealType_CHML: null,
        EFltSsrMealType_CNML: null,
        EFltSsrMealType_DBML: null,
        EFltSsrMealType_EFltSsrMealType: null,
        EFltSsrMealType_FPML: null,
        EFltSsrMealType_FSML: null,
        EFltSsrMealType_GFML: null,
        EFltSsrMealType_GPML: null,
        EFltSsrMealType_HNML: null,
        EFltSsrMealType_KSML: null,
        EFltSsrMealType_LCML: null,
        EFltSsrMealType_LFML: null,
        EFltSsrMealType_LPML: null,
        EFltSsrMealType_LSML: null,
        EFltSsrMealType_MOML: null,
        EFltSsrMealType_NLML: null,
        EFltSsrMealType_NOML: null,
        EFltSsrMealType_PMIB: null,
        EFltSsrMealType_PMIC: null,
        EFltSsrMealType_PMID: null,
        EFltSsrMealType_PMIE: null,
        EFltSsrMealType_PRIBAS_SUN_CHML: null,
        EFltSsrMealType_PRIBAS_SUN_PMDB: null,
        EFltSsrMealType_PRIBAS_SUN_PMDC: null,
        EFltSsrMealType_PRIBAS_SUN_PMDD: null,
        EFltSsrMealType_PRIBAS_SUN_PMDE: null,
        EFltSsrMealType_PRIBAS_SUN_PMIB: null,
        EFltSsrMealType_PRIBAS_SUN_PMIC: null,
        EFltSsrMealType_PRIBAS_SUN_PMID: null,
        EFltSsrMealType_PRIBAS_SUN_PMIE: null,
        EFltSsrMealType_PRIBAS_SUN_SCSM: null,
        EFltSsrMealType_PRIBAS_SUN_SPDB: null,
        EFltSsrMealType_PRIBAS_SUN_SPDC: null,
        EFltSsrMealType_PRIBAS_SUN_SPDD: null,
        EFltSsrMealType_PRIBAS_SUN_SPDE: null,
        EFltSsrMealType_PRIBAS_SUN_SPIB: null,
        EFltSsrMealType_PRIBAS_SUN_SPIC: null,
        EFltSsrMealType_PRIBAS_SUN_SPID: null,
        EFltSsrMealType_PRIBAS_SUN_SPIE: null,
        EFltSsrMealType_PRIBAS_SUN_SPVG: null,
        EFltSsrMealType_PRIBAS_SUN_SPVL: null,
        EFltSsrMealType_PRIBAS_SUN_VGML: null,
        EFltSsrMealType_PRIBAS_SUN_VLML: null,
        EFltSsrMealType_SFML: null,
        EFltSsrMealType_SPML: null,
        EFltSsrMealType_TAML: null,
        EFltSsrMealType_TBML: null,
        EFltSsrMealType_VGML: null,
        EFltSsrMealType_VJML: null,
        EFltSsrMealType_VOML: null,
        EFltSsrType_BAGGAGE: null,
        EFltSsrType_CABIN_BAG: null,
        EFltSsrType_CHECKIN_AT_AIRPORT: null,
        EFltSsrType_FOOD: null,
        EFltSsrType_MEAL: null,
        EFltSsrType_OTHER: null,
        EFltSsrType_REISSUE_ALLOWANCE: null,
        EFltSsrType_SEAT: null,
        EFltSsrType_WHEELCHAIR: null,
        EFltSsrWheelchairType_WCBD: null,
        EFltSsrWheelchairType_WCHC: null,
        EFltSsrWheelchairType_WCHR: null,
        EFltSsrWheelchairType_WCHS: null,
        EFltSsrWheelchairType_WCLB: null,
        EFltSsrWheelchairType_WCMP: null,
        EHtlAmountType_FIXED: null,
        EHtlAmountType_RATIO: null,
        EHtlGuestType_ADULT: null,
        EHtlGuestType_CHILD: null,
        EHtlPolicyType_CANCELLATION: null,
        EHtlPolicyType_NO_SHOW: null,
        EHtlPriceRestrictionType_ALL: null,
        EHtlPriceRestrictionType_MIN_STAY: null,
        EHtlPriceRestrictionType_NO_RESTRICTION: null,
        EHtlPriceRestrictionType_QUOTA: null,
        EHtlPriceRestrictionType_RELEASE: null,
        EHtlPriceRestrictionType_STOP: null,
        EHtlPriceRestrictionType_UNKNOWN: null,
        EHtlRefundType_NONREFUNDABLE: null,
        EHtlRefundType_REFUNDABLE: null,
        EHtlVoucherStatus_ALL: null,
        EHtlVoucherStatus_BOOKED: null,
        EHtlVoucherStatus_CANCELED: null,
        EHtlVoucherStatus_ERROR: null,
        EHtlVoucherStatus_NEW: null,
        EHtlVoucherStatus_PARTIAL_PAID: null,
        EHtlVoucherStatus_REBOOKED: null,
        EHtlVoucherStatus_REFUNDED: null,
        EHtlVoucherStatus_RESERVED: null,
        EHtlVoucherStatus_UNKNOWN: null,
        EHtlVoucherStatus_VOIDED: null,
        "enum_airport-checkin": null,
        enum_all: null,
        enum_AVML: null,
        enum_baggage: null,
        enum_BBML: null,
        enum_BLML: null,
        enum_booked: null,
        enum_business: null,
        "enum_cabin-bag": null,
        enum_canceled: null,
        enum_cancellationPolicies_fixed: null,
        enum_cancellationPolicies_fixed_no_show: null,
        enum_cancellationPolicies_ratio: null,
        enum_cancellationPolicies_ratio_no_show: null,
        enum_cancelled: null,
        enum_cc_commission: null,
        enum_cc_payment: null,
        enum_checkin_at_airport: null,
        enum_CHML: null,
        enum_close: null,
        enum_closed: null,
        enum_CNML: null,
        enum_completed: null,
        enum_date1: null,
        enum_DBML: null,
        enum_duplicate: null,
        enum_economy: null,
        enum_eHtlAmountType_ALL: null,
        enum_eHtlAmountType_FIXED: null,
        enum_eHtlAmountType_RATIO: null,
        enum_eHtlAmountType_UNKNOWN: null,
        enum_EHtlRoomPriceStatus_NEW: null,
        enum_EHtlRoomPriceStatus_RESERVED: null,
        enum_EHtlVoucherStatus_ALL: null,
        enum_EHtlVoucherStatus_BOOKED: null,
        enum_EHtlVoucherStatus_CANCELLED: null,
        enum_EHtlVoucherStatus_ERROR: null,
        enum_EHtlVoucherStatus_NEW: null,
        enum_EHtlVoucherStatus_REBOOKED: null,
        enum_EHtlVoucherStatus_REFUNDED: null,
        enum_EHtlVoucherStatus_RESERVED: null,
        enum_EHtlVoucherStatus_UNKNOWN: null,
        enum_EHtlVoucherStatus_VOIDED: null,
        enum_enum_replied_by_agency: null,
        enum_error: null,
        enum_f_com_vpos: null,
        enum_f_pay_vpos: null,
        enum_fcadom: null,
        enum_fcaint: null,
        enum_fccdom: null,
        enum_fccint: null,
        enum_fcodom: null,
        enum_fcoint: null,
        enum_FPML: null,
        enum_FSML: null,
        enum_GFML: null,
        "enum_hand-wallet": null,
        enum_HNML: null,
        enum_hotel: null,
        enum_insurance: null,
        enum_invoice: null,
        enum_issued: null,
        enum_KSML: null,
        enum_LCML: null,
        enum_LFML: null,
        enum_LPML: null,
        enum_LSML: null,
        enum_meal: null,
        enum_MOML: null,
        enum_new: null,
        enum_NLML: null,
        enum_open: null,
        enum_other: null,
        enum_package_info: "Задача информации о пакете                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        enum_partial_paid: null,
        enum_payment: null,
        enum_payment_reverse: null,
        enum_pending_canceled: null,
        enum_pending_issued: null,
        enum_pending_refunded: null,
        enum_pending_reserved: null,
        enum_pending_voided: null,
        enum_pnr: null,
        enum_pnr_add: null,
        enum_pnr_ancillary: null,
        enum_pnr_ancillary_rev: null,
        enum_pnr_commission: null,
        enum_pnr_commission_reverse: null,
        enum_pnr_correction: null,
        enum_pnr_divide: null,
        enum_pnr_fare: null,
        enum_pnr_fare_reverse: null,
        enum_pnr_open: null,
        enum_pnr_reissue: null,
        enum_pnr_reverse: null,
        enum_pnr_update: null,
        enum_pnr_update_pax: null,
        enum_processing: null,
        enum_receiving_pending: null,
        enum_refunded: null,
        "enum_reissue-allowance": null,
        enum_reissued: null,
        enum_reopen: null,
        enum_replied_by_agency: null,
        enum_replied_by_provider: null,
        enum_reserved: null,
        enum_rev_f_com_vpos: null,
        enum_rev_f_pay_vpos: null,
        Enum_rnr_ancillary: null,
        enum_rnr_reissue: null,
        enum_rnr_reverse: null,
        enum_seat: null,
        enum_SFML: null,
        enum_sunflex: null,
        enum_tnr_add: null,
        enum_tnr_reissue: null,
        enum_tnr_reverse: null,
        enum_transfer: null,
        enum_unknown: null,
        enum_VGML: null,
        enum_VJML: null,
        enum_VLML: null,
        enum_void: null,
        enum_voided: null,
        enum_VOML: null,
        enum_voucher_add: null,
        enum_voucher_reissue: null,
        enum_voucher_reverse: null,
        enum_voucher_roomdateprice_update: null,
        enum_voucher_update: null,
        Enum_waiting: null,
        enum_waiting_on_agency: null,
        enum_waiting_on_operator: null,
        Enum_waiting_on_provider: null,
        enum_WCBD: null,
        enum_WCHC: null,
        enum_WCHR: null,
        enum_WCHS: null,
        enum_WCLB: null,
        enum_WCMP: null,
        enum_wheelchair: null,
        enum_wont_fix: null,
        EOdrEntityType_HOTEL: null,
        EOdrEntityType_INSURANCE: null,
        EOdrEntityType_PNR: null,
        EOdrEntityType_TRANSFER: null,
        EOdrEntityType_UNKNOWN: null,
        EOdrSearchHotelDateType_CHECKIN: null,
        EOdrSearchHotelDateType_PROCESS: null,
        EOdrSearchRentACarDateType_PICKUP: null,
        EOdrSearchRentACarDateType_PROCESS: null,
        EOdrType_FLIGHT: "Полет                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        EPayCardBrand_UNKNOWN: null,
        EProcessType_FLIGHT_ONLINE_ANCILLARY_SALE: null,
        ERacAncillaryType_ALL: null,
        ERacAncillaryType_BABY_SEAT: null,
        ERacAncillaryType_EXTRA_DAMAGE: null,
        ERacAncillaryType_EXTRA_PICKUP_DROPOFF: null,
        ERacAncillaryType_EXTRA_TRAFFIC_PENALTY: null,
        ERacAncillaryType_INSURANCE_ECO: null,
        ERacAncillaryType_INSURANCE_PREMIUM: null,
        ERacAncillaryType_INTERNET: null,
        ERacAncillaryType_MANDAT_ONEWAY_TRANSFER: null,
        ERacAncillaryType_NAVIGATION: null,
        ERacAncillaryType_SNOW_CHAIN: null,
        ERacAncillaryType_SNOW_TIRE: null,
        ERacAncillaryType_UNKNOWN: null,
        ERacBodyType_ALL: null,
        ERacBodyType_HATCHBACK: null,
        ERacBodyType_MINIVAN: null,
        ERacBodyType_SEDAN: null,
        ERacBodyType_SUV: null,
        ERacBodyType_UNKNOWN: null,
        ERacBodyType_VAN: null,
        ERacFacilityType_AIRPORT_SURCHARGE: null,
        ERacFacilityType_ALL: null,
        ERacFacilityType_COLLISION_DAMAGE_WAIVER: null,
        ERacFacilityType_FREE_CANCELLATION: null,
        ERacFacilityType_THEFT_WAIVER: null,
        ERacFacilityType_THIRD_PARTY_LIABILITY_INSURANCE: null,
        ERacFacilityType_UNKNOWN: null,
        ERacFacilityType_UNLIMITED_MILEAGE: null,
        ERacFuelType_ALL: null,
        ERacFuelType_DIESEL: null,
        ERacFuelType_ELECTRIC: null,
        ERacFuelType_GASOLINE: null,
        ERacFuelType_UNKNOWN: null,
        ERacGearType_ALL: null,
        ERacGearType_AUTOMATIC: null,
        ERacGearType_MANUEL: null,
        ERacGearType_UNKNOWN: null,
        ERacPassengerCapacity_3: null,
        ERacPassengerCapacity_5: null,
        ERacPassengerCapacity_7: null,
        ERacPassengerCapacity_9: null,
        ERacRnrStatus_ALL: null,
        ERacRnrStatus_CANCELED: null,
        ERacRnrStatus_ERROR: null,
        ERacRnrStatus_ISSUED: null,
        ERacRnrStatus_NEW: null,
        ERacRnrStatus_OPEN: null,
        ERacRnrStatus_PENDING: null,
        ERacRnrStatus_REFUNDED: null,
        ERacRnrStatus_REISSUED: null,
        ERacRnrStatus_RESERVED: null,
        ERacRnrStatus_VOIDED: null,
        EReportHotelDateType_CHECK_IN: null,
        EReportHotelDateType_CHECK_OUT: "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        EReportHotelDateType_SALE: null,
        EReportRentACarDateType_PICKUP: null,
        EReportRentACarDateType_PROCESS: null,
        EReportRentACarReportType_ANCILLARY: null,
        EReportRentACarReportType_VEHICLE: null,
        EReportSalesType_REISSUE: null,
        EReportSalesType_SALE: null,
        EReportTransferDateType_ALL: null,
        EReportTransferDateType_SALE: null,
        EReportTransferDateType_TRANSFER: null,
        EReportTransferDateType_UNKNOWN: null,
        "ERROR_CODES_AGENT_API.FLIGHT_TICKET.SUCCESS.PAYMENT_POST": null,
        "ERROR_CODES_AGENT_API.HOTEL_TICKET_RESERVED.REJECTED.INSUFFICIENT_PAYMENT": null,
        "ERROR_CODES_FLIGHT_API.FLIGHT_RESERVATION.PARAMETER.INVALID_PASSENGER_INFO": null,
        ErrorReportList_thEntityType: null,
        ESchmetterlingOrderStatus_ALL: "Все                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ",
        ESchmetterlingOrderStatus_BOOKED: "Забронировано                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        ESchmetterlingOrderStatus_CANCELED: "Отменено                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        ESchmetterlingOrderStatus_DISPLAYED: "Успешно отображено                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ",
        ESchmetterlingOrderStatus_OPTIONAL_BOOKED: "Необязательный                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ",
        ESchmetterlingOrderStatus_RESERVED: "Зарезервировано                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        ESchmetterlingOrderStatus_UNKNOWN: "Неизвестный                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        ETaskClosingType_ALL: null,
        ETaskClosingType_CANCELED: null,
        ETaskClosingType_COMPLETED: null,
        ETaskClosingType_DUBLICATE: null,
        ETaskClosingType_UNKNOWN: null,
        ETaskClosingType_WONT_FIX: null,
        ETaskStatus_ALL: null,
        ETaskStatus_CLOSED: null,
        ETaskStatus_OPEN: null,
        ETaskStatus_UNKNOWN: null,
        ETaskStatus_waiting_on_agency: null,
        ETaskSubStatus_8: null,
        ETaskSubStatus_ALL: null,
        ETaskSubStatus_CLOSED: null,
        ETaskSubStatus_NEW: null,
        ETaskSubStatus_PROCESSING: null,
        ETaskSubStatus_REPLIED_BY_AGENCY: null,
        ETaskSubStatus_REPLIED_BY_PROVIDER: null,
        ETaskSubStatus_UNKNOWN: null,
        ETaskSubStatus_WAITING: null,
        ETaskSubStatus_WAITING_ON_AGENCY: null,
        ETaskSubStatus_WAITING_ON_OPERATOR: null,
        ETaskSubStatus_WAITING_ON_PROVIDER: null,
        ETaskSubStatusETaskSubStatus_REPLIED_BY_PROVIDER: null,
        ETaskSubType_BASIC: "Basic                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        ETaskSubType_FLIGHT_CHANGE_CONFIRM_MAJOR: "Запланированные изменения                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           ",
        ETaskSubType_FLIGHT_CHANGE_CONFIRM_MINOR: "Обязательное изменение                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        ETaskType_7: null,
        ETaskType_ALL: null,
        ETaskType_PAYMENT_REVERSE: null,
        ETaskType_PNR_ADD: null,
        ETaskType_PNR_ANCILLARY: null,
        ETaskType_PNR_CORRECTION: null,
        ETaskType_PNR_DIVIDE: null,
        ETaskType_PNR_FLIGHT_CHANGE_CONFIRMATION: "Авто - Уведомление об изменении                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        ETaskType_PNR_OPEN: null,
        ETaskType_PNR_REISSUE: null,
        ETaskType_PNR_RESERVE: null,
        ETaskType_PNR_REVERSE: "Pnr Отмена - возврат                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ",
        ETaskType_PNR_UPDATE: null,
        ETaskType_PNR_UPDATE_PAX: null,
        ETaskType_TNR_ADD: null,
        ETaskType_TNR_REISSUE: null,
        ETaskType_TNR_REVERSE: null,
        ETaskType_UNKNOWN: null,
        ETaskType_VOUCHER_ADD: null,
        ETaskType_VOUCHER_NOTE: null,
        ETaskType_VOUCHER_RECONFIRMATION: null,
        ETaskType_VOUCHER_REISSUE: null,
        ETaskType_VOUCHER_REVERSE: null,
        ETaskType_VOUCHER_ROOMGUEST_UPDATE: null,
        ETaskType_VOUCHER_UPDATE: null,
        ETasLogStatus_FLIGHT: null,
        ETrfFacilityType_UNKNOWN: "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        ETrfFacilityType_VOIDABLE_BEFORE_24H: null,
        ETrfFacilityType_VOIDABLE_BEFORE_36H: null,
        ETrfLegStatus_ALL: null,
        ETrfLegStatus_CANCELED: null,
        ETrfLegStatus_ERROR: null,
        ETrfLegStatus_ISSUED: null,
        ETrfLegStatus_NEW: null,
        ETrfLegStatus_OPEN: null,
        ETrfLegStatus_PENDING: null,
        ETrfLegStatus_REFUNDED: null,
        ETrfLegStatus_REISSUED: null,
        ETrfLegStatus_RESERVED: null,
        ETrfLegStatus_UNKNOWN: null,
        ETrfLegStatus_VOIDED: null,
        ETrfTnrStatus_ALL: null,
        ETrfTnrStatus_CANCELED: null,
        ETrfTnrStatus_ERROR: null,
        ETrfTnrStatus_ISSUED: null,
        ETrfTnrStatus_NEW: null,
        ETrfTnrStatus_OPEN: null,
        ETrfTnrStatus_PENDING: null,
        ETrfTnrStatus_REFUNDED: null,
        ETrfTnrStatus_REISSUED: null,
        ETrfTnrStatus_RESERVED: null,
        ETrfTnrStatus_UNKNOWN: null,
        ETrfTnrStatus_VOIDED: null,
        ETrfTransferType_ALL: null,
        ETrfTransferType_GROUP: null,
        ETrfTransferType_INDIVIDUAL: null,
        ETrfTransferType_UNKNOWN: null,
        ETrfTravelerType_ADULT: null,
        ETrfTravelerType_CHILD: null,
        ETrfTravelerType_INFANT: null,
        ETrfVehicleCategory_ALL: null,
        ETrfVehicleCategory_CAR: null,
        ETrfVehicleCategory_MIDIBUS: null,
        ETrfVehicleCategory_MINIBUS: null,
        ETrfVehicleCategory_MINIVAN: null,
        ETrfVehicleCategory_MINIVAN_VIP: null,
        ETrfVehicleCategory_OTOBUS: null,
        ETrfVehicleCategory_UNKNOWN: null,
        ETrfVehicleCategory_VAN: null,
        filterComponent_boardType_titleLabel: null,
        filterComponent_class_selectedClassCount_label: null,
        filterComponent_class_titleLabel: null,
        filterComponent_facilities_titleLabel: null,
        filterComponent_information_titleLabel: null,
        filterComponent_prices_titleLabel: null,
        flight_fillPaxRows: null,
        flight_fillPaxRowsCancelBtn: null,
        flight_fillPaxRowsContent: null,
        flight_fillPaxRowsTooltip: null,
        flight_fillPaxRowsTooltipHighlighter: null,
        flight_fillPaxRowsTtitle: null,
        flight_flightCabinType: null,
        flight_flightClass: null,
        flight_operatingAirline: null,
        flight_paxBirthDate: null,
        flight_paxInfo: null,
        flight_paxNameSurname: null,
        flight_paxType: null,
        FLIGHT_PRIBAS_KEY57: null,
        flightBrands_lblBaggageInfoUnknown: null,
        flightBrands_lblBrandCalculate: "Рассчитать                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        flightCard_directFlight: null,
        flightCard_operatedBy: null,
        flightCard_showDetails: null,
        flightCardComponent_oneWay: null,
        flightcardcomponent_operatedBy: null,
        flightChange_lblApprove: "Подтвердить изменение                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        flightChange_lblPnrReissue: "Запрос на изменение                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ",
        flightChange_lblPnrReverse: "Запрос на отмену                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        flightChangeAlert_lblMsg: "Ваш рейс может быть изменен или отменен. Пожалуйста, проверьте.                                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        flightChangeAlert_lblTitle: "Info                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ",
        flightCheckout_agencyExtraCCommission: null,
        FlightCheckout_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: null,
        FlightCheckout_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: null,
        FlightCheckout_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: null,
        FlightCheckout_AGENT_API_FLIGHT_TICKET_PRICE_DIFFERENCE_AFTER_RESERVATION: null,
        FlightCheckout_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: null,
        flightCheckout_BrandPriceUnavailable: "Стоимость этого пакета не удалось узнать у авиакомпании.                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        flightCheckout_brandSelection: null,
        FlightCheckout_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: null,
        FlightCheckout_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNMAPPED_ERROR: null,
        flightCheckout_lblAgencyExtraCommission: null,
        flightCheckout_lblBaseTotal: null,
        flightCheckout_lblGrandTotal: null,
        flightCheckout_SELECTED_BRANDS_NOT_VALID_NOW: null,
        flightCheckout_SSR_rezervationDisabled: null,
        FlightCheckout_TITLE_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: null,
        FlightCheckout_TITLE_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: null,
        FlightCheckout_TITLE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: null,
        FlightCheckout_TITLE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: null,
        FlightCheckout_TITLE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: null,
        flightCheckoutPayment_priceDifference: null,
        flightCheckoutPayment_transectionProgress: null,
        flightInformationsComponent_baggageAllowance: null,
        flightInformationsComponent_cabinType: null,
        flightInformationsComponent_class: null,
        flightInformationsComponent_flight: null,
        flightInformationsComponent_flightInformation: null,
        flightInformationsComponent_timeChanged: null,
        flightInformationsComponent_travelDuration: null,
        "flightLegHeader_show/hideResults": null,
        flightMatrix_showHideAll: null,
        FlightOffer_flight: null,
        flightOrderDetail_btnCalculatePenalty: null,
        flightOrderDetail_lblAgencyExtraCommission: null,
        flightOrderDetail_lblAgencyServiceFee: "Услуги агентства Плата                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        flightOrderDetail_lblAncillaryTotal: null,
        flightOrderDetail_lblBaseTotal: null,
        flightOrderDetail_lblCalculatingPenalty: null,
        flightOrderDetail_lblCalculatingPenaltyTxt: null,
        flightOrderDetail_lblCancellationWithPenalty: null,
        flightOrderDetail_lblCancellationWithPenaltyTxt: null,
        flightOrderDetail_lblGrandTotal: null,
        flightOrderDetail_lblPackageGrandTotal: "общая стоимость пакета                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        flightOrderDetail_lblPaymentFeeTotal: null,
        flightOrderDetail_lblRefundWithPenalty: null,
        flightOrderDetail_lblReissueTotal: null,
        flightOrderDetail_lblReverseTotal: null,
        flightOrderDetail_lblReverseTotalAncillary: null,
        flightOrderDetail_lblSupplierReturn: "Возврат средств поставщиком                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        flightOrderDetail_msgRESERVATED_FLIGHT_CHANGED: null,
        flightOrderDetail_ticketIssuedNotificaiton: null,
        flightOrderDetail_ticketReservedNotificaiton: null,
        flightOrderDetail_txtFlight: null,
        flightPassengerInformationsComponent_passengerInformations: null,
        flightPayment_infoCreditCard: null,
        flightPayment_infoSofort: null,
        FlightPayment_INSUFFICIENT_AGENCY_BALANCE: null,
        FlightPaymentWidget_btnReverse: null,
        FlightPaymentWidget_popup_REVERSE_FLIGHT: null,
        FlightPaymentWidget_popup_title: null,
        flightPriceInformationsComponent_priceInformations: null,
        flightSaleReport_currency: null,
        flightSaleReport_flightDate: null,
        flightSaleReport_paxCount: null,
        flightSaleReport_pnrCount: null,
        flightSaleReport_refundTotal: null,
        flightSaleReport_totalAmount: null,
        flightSaleReport_transactionDate: null,
        flightSalesReport_airlineList: null,
        flightSalesReport_creationFromDate: null,
        flightSalesReport_creationToDate: null,
        flightSalesReport_currency: null,
        flightSalesReport_flightDate: null,
        flightSalesReport_optEticket: null,
        flightSalesReport_optPNR: null,
        flightSalesReport_paxCount: null,
        flightSalesReport_pnrCount: null,
        flightSalesReport_pnrStatus: null,
        flightSalesReport_providerList: null,
        flightSalesReport_refundTotal: null,
        flightSalesReport_reportType: null,
        flightSalesReport_showReissues: null,
        flightSalesReport_title: null,
        flightSalesReport_totalAmount: null,
        flightSalesReport_transactionDate: null,
        flightSalesReport_userList: null,
        flightSearchForm_lblMultiLeg: "Многоножка                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        flightSearchResult_lblFamilyPricesToggler: null,
        flightSearchResult_lblOkay: "Я понимаю                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           ",
        flightSearchResult_tourFamilyPricesToggler: "С его помощью вы можете узнать самую низкую стоимость пакета.                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        FlightSelect_AGENT_API_FLIGHT_CHECKOUT_EXCEPTION_CHECKOUT: null,
        FlightSelect_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: null,
        FlightSelect_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: null,
        FlightSelect_TITLE_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: null,
        FlightSelect_TITLE_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: null,
        flightsResults_noFlightWarning: null,
        girosWidget_gross: null,
        girosWidget_net: null,
        girosWidget_others: null,
        gtsDataTableComponent_checkIn: null,
        gtsDataTableComponent_daily: null,
        gtsDataTableComponent_emptyEntity: null,
        gtsDataTableComponent_firstLabel: null,
        gtsDataTableComponent_flightChange: "Изменение времени                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        gtsDataTableComponent_lastLabel: null,
        gtsDataTableComponent_lblWKSC: "Изменение расписания                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ",
        gtsDataTableComponent_monthly: null,
        gtsDataTableComponent_nextLabel: null,
        gtsDataTableComponent_partialPayment: null,
        gtsDataTableComponent_performanceBarChartdaily: null,
        gtsDataTableComponent_performanceBarChartmonthly: null,
        gtsDataTableComponent_performanceBarChartweekly: null,
        gtsDataTableComponent_prevLabel: null,
        gtsDataTableComponent_rowsPerPageLabel: null,
        gtsDataTableComponent_totalGiro: null,
        gtsDataTableComponent_totalPax: null,
        gtsDataTableComponent_totalPnr: null,
        gtsDataTableComponent_totalSearch: null,
        gtsDataTableComponent_totalVoucher: null,
        gtsDataTableComponent_weekly: null,
        gtsGrid_btnNextPage: null,
        gtsGrid_btnPrevPage: null,
        gtsGrid_item: null,
        gtsGrid_showAll: null,
        gtsGrid_totalItem: null,
        guestItem_txtGuest: null,
        hamburgerMenu_accTransactionList: null,
        hamburgerMenu_balanceUpdate: null,
        hamburgerMenu_customerManagement: null,
        hamburgerMenu_darkMode: null,
        hamburgerMenu_dashBoard: "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        hamburgerMenu_financeWithSubs: null,
        hamburgerMenu_flightSaleReport: null,
        hamburgerMenu_flightSalesReport: null,
        hamburgerMenu_flightSearch: null,
        hamburgerMenu_hotelSaleReport: null,
        hamburgerMenu_hotelSalesReport: null,
        hamburgerMenu_hotelSearch: null,
        hamburgerMenu_invoiceList: null,
        hamburgerMenu_lightMode: null,
        hamburgerMenu_logOut: null,
        hamburgerMenu_orderSearch: null,
        hamburgerMenu_profileSettings: null,
        hamburgerMenu_rentACarSalesReport: null,
        hamburgerMenu_rentACarSearch: null,
        hamburgerMenu_reportsWithSubs: null,
        hamburgerMenu_serviceCenter: null,
        hamburgerMenu_settings: null,
        hamburgerMenu_transferSalesReport: null,
        hamburgerMenu_transferSearch: null,
        hamburgerMenu_userAuthorization: null,
        hamburgerMenu_userSettings: null,
        "HOTEL_API.TICKET.EXCEPTION.error.hotel.not.accepts.only.man": null,
        hotel_lblAdult: null,
        hotel_lblChild: null,
        hotel_lblCustomerNationality: null,
        hotel_lblDateInfo: null,
        hotel_lblDestinationName: null,
        hotel_lblInfant: null,
        hotel_lblManuelRequest: null,
        hotel_lblRoom: null,
        hotel_lblRoomInfos: null,
        hotel_manuelFormTitle: null,
        hotel_manuelRequestCancel: null,
        hotel_manuelRequestConfirm: null,
        hotel_manuelRequestModalTitle: null,
        HOTEL_API_TICKET_HOTEL_EXCEPTION_GTS_BOOKING: null,
        hotelAutocomplete_lblCity: null,
        hotelAutocomplete_lblDisctrict: null,
        hotelAutocomplete_lblHotel: null,
        hotelAutocomplete_lblLastSearches: null,
        hotelAutocomplete_phDestination: null,
        hotelBasket_btnClear: null,
        hotelBasket_lblAddOnPrice: null,
        hotelBasket_lblGrandTotal: null,
        hotelBasket_lblRoomPrice: null,
        hotelBasket_lblTitle: null,
        hotelBasketComponent_roomPriceLabel: null,
        hotelCacheCheckout_lblCancellationPolicy: null,
        hotelCacheCheckout_lblPassengerInformations: null,
        HotelCacheCheckoutBook_WarningEmptyAreaContent: null,
        HotelCacheCheckoutBook_WarningEmptyAreaHead: null,
        hotelCacheOrderDetail_lblInfoDontForgetSave: null,
        hotelCacheOrderDetail_lblPaymentInformations: null,
        hotelCacheOrderDetail_lblSummary: null,
        hotelCacheSearch_addOn: null,
        hotelCacheSearch_lblHotelNotSelectedWarning: null,
        hotelCacheSearch_lblMultiHotelSelect: null,
        hotelCacheSearch_lblSearchInfo: "На этой странице перечислены отели поставщиков, доступные для продажи, на экране правил поиска оптовых отелей.                                                                                                                                                                                                                                                                                                                                                                                                      ",
        hotelCacheSearch_lblSelectedHotels: null,
        HotelCacheSearch_MultiSelectWarningPopup: null,
        HotelCacheSearch_MultiSelectWarningPopupInfo: null,
        HotelCacheSearch_WarningMultiSelectInfo: null,
        HotelCacheSearch_WarningMultiSelectPopup: null,
        HotelCacheSearch_WarningPopup: null,
        HotelCacheSearch_WarningPopupEmpty: null,
        HotelCacheSearch_WarningPopupEmptyInfo: null,
        HotelCacheSearch_WarningPopupInfo: null,
        HotelCacheSearch_WarningPopupToCheckout: null,
        HotelCacheSearch_WarningPopupTopInfo: null,
        hotelCard_btnFacilityShowLess: null,
        hotelCard_btnFacilityShowMore: null,
        hotelCard_btnReadLess: null,
        hotelCard_btnReadMore: null,
        hotelCard_btnSearchRoom: null,
        hotelCard_lblBoutiqueHotel: null,
        hotelCard_lblCalendarBetween: null,
        hotelCard_lblCalendarInfo: null,
        hotelCard_lblCalendarMinStay: null,
        hotelCard_lblCalendarRelease: null,
        hotelCard_lblCalendarSelectNights: null,
        hotelCard_lblCalendarStopType: null,
        hotelCard_lblCalendarSuccess: null,
        hotelCard_lblChangeDate: null,
        hotelCard_lblDescription: null,
        hotelCard_lblDiscounted: null,
        hotelCard_lblhasAddon: null,
        hotelCard_lblhasAddonTitle: null,
        hotelCard_lblhasMandatoryAddonTitle: null,
        hotelCard_lblKontenjan: null,
        hotelCard_lblLocationTitle: null,
        hotelCard_lblMinimum: null,
        hotelCard_lblMinimumStay: null,
        hotelCard_lblNights: null,
        hotelCard_lblPartialPayment: null,
        hotelCard_lblQuota: null,
        hotelCard_lblRecommended: null,
        hotelCard_lblRelease: null,
        hotelCard_lblSearchResultInspectFirst: "Результат поиска #VALUE1# в секундах                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ",
        hotelCard_lblSearchResultInspectSecond: "#VALUE1# отелей перечислены поставщиками.                                                                                                                                                                                                                                                                                                                                                                                                                                                                           ",
        hotelCard_lblSpecialCategory: null,
        hotelCard_lblStop: null,
        hotelCard_noMatchingBoardType: null,
        hotelCard_room: null,
        hotelCard_tabInfo: null,
        hotelCard_tabPhotoGallery: null,
        hotelCard_tabRooms: null,
        hotelcheckout_addOnCardfree: null,
        hotelcheckout_addOnCardWarning: null,
        hotelCheckout_addOnInsert: null,
        hotelCheckout_addOnMandatory: null,
        hotelCheckout_addOnNonMandatory: null,
        hotelCheckout_addOnReadLess: null,
        hotelCheckout_addOnReadMore: null,
        hotelCheckout_addOnRemove: null,
        hotelCheckout_agencyExtraCCommission: null,
        hotelCheckout_cmbAgencyCommission: null,
        hotelCheckout_lblAccomodationInfo: null,
        hotelCheckout_lblAddOnTotalPrice: null,
        hotelCheckout_lblAgencyCommission: null,
        hotelCheckout_lblAgencyExtraCommission: null,
        hotelCheckout_lblBoardType: null,
        hotelCheckout_lblCheckin: null,
        hotelCheckout_lblCheckout: null,
        hotelCheckout_lblCityTax: null,
        hotelCheckout_lblContactInfo: null,
        hotelCheckout_lblEmail: null,
        hotelCheckout_lblGrandTotal: null,
        hotelCheckout_lblHotelAgentTerms: null,
        hotelCheckout_lblHotelAnd: null,
        hotelCheckout_lblHotelDescriptions: null,
        hotelCheckout_lblHotelDescriptionsAlert: null,
        hotelCheckout_lblHotelDescriptionsRateHawkMetaPolicy: "",
        hotelCheckout_lblHotelDescriptionsTitle: null,
        hotelCheckout_lblHotelProviderInstructionsDescriptions: null,
        hotelCheckout_lblHotelProviderInstructionsTitle: null,
        hotelCheckout_lblHotelSupplierTerms: null,
        hotelCheckout_lblHotelTerms: null,
        hotelCheckout_lblLastBookingDate: null,
        hotelCheckout_lblLastBookingDateDescription: null,
        hotelCheckout_lblPartialPaymentAvailable: null,
        hotelCheckout_lblPartialPaymentCancelInfo: null,
        hotelCheckout_lblPartialPaymentCancellationTerms: null,
        hotelCheckout_lblPartialPaymentCancellationTermsInfo: null,
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText: null,
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText1: null,
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText2: null,
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText2Revize: null,
        hotelCheckout_lblPartialPaymentCancellationTermsInfoText3: null,
        hotelCheckout_lblPartialPaymentDeposit: null,
        hotelCheckout_lblPartialPaymentFirstDeposit: null,
        hotelCheckout_lblPartialPaymentFirstPayment: null,
        hotelCheckout_lblPartialPaymentInfo: null,
        hotelCheckout_lblPartialPaymentPaymentInfo: null,
        hotelCheckout_lblPartialPaymentReady: null,
        hotelCheckout_lblPartialPaymentRefundDisabled: null,
        hotelCheckout_lblPartialPaymentRemainingPayment: null,
        hotelCheckout_lblPartialPaymentRemainingPaymentInfo: null,
        hotelCheckout_lblPartialPaymentSecondPayment: null,
        hotelCheckout_lblPartialPaymentUsePartialPayment: null,
        hotelCheckout_lblPartialPaymentUsePartialPaymentAvailableInfo: null,
        hotelCheckout_lblPartialPaymentUsePartialPaymentDeactive: null,
        hotelCheckout_lblproviderTotal: null,
        hotelCheckout_lblRoomInfo: null,
        hotelCheckout_lblRoomName: null,
        hotelCheckout_lblRoomTotal: null,
        hotelCheckout_lblTax: null,
        hotelCheckout_lblTaxUS: null,
        hotelCheckout_lblTotalPrice: null,
        hotelCheckout_lblTotalTax: "Налог",
        hotelCheckout_PartialCancellationContentText: null,
        hotelCheckoutBasket_partialPaymentControl: null,
        hotelCheckoutBasket_txtBoardType: null,
        hotelCheckoutBasket_txtNight: null,
        hotelCheckoutBasket_txtRoom: null,
        hotelCheckoutPage_contactInfo_title: null,
        hotelCheckoutPage_descriptions_alert_text: null,
        hotelCheckoutPage_descriptions_hotelNote_text: null,
        hotelCheckoutPage_descriptions_title: null,
        hotelCheckoutPage_roomInfo_agencyCommission_label: null,
        hotelCheckoutPage_roomInfo_agencyExtraCommission_label: null,
        hotelCheckoutPage_roomInfo_title: null,
        hotelCheckoutPage_roomInfoCard_lastBookingDate_alertDescriptionText: null,
        hotelCheckoutPage_roomInfoCard_lastBookingDate_alertText: null,
        hotelCheckoutPage_roomInformation_roomBoardLabel: null,
        hotelCheckoutPage_roomInformation_roomNameLabel: null,
        hotelCheckoutPage_roomRestrictionInfo: null,
        hotelCheckoutPageRH_children: "",
        hotelCheckoutPageRH_childrenMeal: "",
        hotelCheckoutPageRH_cot: "",
        hotelCheckoutPageRH_deposit: "",
        hotelCheckoutPageRH_extraBed: "",
        hotelCheckoutPageRH_internet: "",
        hotelCheckoutPageRH_meal: "",
        hotelCheckoutPageRH_noShow: "",
        hotelCheckoutPageRH_parking: "",
        hotelCheckoutPageRH_pets: "",
        hotelCheckoutPageRH_shuttle: "",
        hotelCheckoutPageRH_visa: "",
        hotelFacilitiesModal_lblTitle: null,
        hotelFilter_btnApplyAndClose: null,
        hotelFilter_btnClearAll: null,
        hotelFilter_btnSelectAll: null,
        hotelFilter_btnShowAll: null,
        hotelFilter_btnShowLess: null,
        hotelFilter_btnShowMore: null,
        hotelFilter_lblAnd: null,
        hotelFilter_lblBoardType: null,
        hotelFilter_lblBoutiqueHotel: null,
        hotelFilter_lblClassTitle: null,
        hotelFilter_lblDiscountedHotel: null,
        hotelFilter_lblFacilityModalCancel: null,
        hotelFilter_lblFacilityModalClose: null,
        hotelFilter_lblFacilityModalSave: null,
        hotelFilter_lblFacilityTitle: null,
        hotelFilter_lblFilterByDefinitions: null,
        hotelFilter_lblFilterByStars: null,
        hotelFilter_lblFilterPartialPayment: null,
        hotelFilter_lblFilterText: null,
        hotelFilter_lblhassAddOn: null,
        hotelFilter_lblInfoTitle: null,
        hotelFilter_lblPartialPayment: null,
        hotelFilter_lblPriceTitle: null,
        hotelFilter_lblRecommendedHotel: null,
        hotelFilter_lblSearchHotel: null,
        hotelFilter_lblSelectedClassCount: null,
        hotelFilter_lblSpecialCategory: null,
        hotelFilter_lblStars: null,
        hotelOrderDetail_gtsDirect: null,
        hotelOrderDetail_lblAccomodationInfo: null,
        hotelOrderDetail_lblAgencyCommission: null,
        hotelOrderDetail_lblBaseTotal: null,
        hotelOrderDetail_lblGrandTotal: null,
        hotelOrderDetail_lblPackageGrandTotal: "общая стоимость пакета                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        hotelOrderDetail_lblproviderTotal: null,
        hotelOrderDetail_lblReverseTotal: null,
        hotelOrderDetail_lblSalesTotal: null,
        hotelOrderDetail_lblTax: null,
        hotelOrderDetail_lblTaxUS: null,
        hotelOrderDetail_lblTotalPrice: null,
        hotelRoomCard_lblDetailHighlightedFeatures: null,
        hotelRoomCard_lblDetailRoomFeatures: null,
        hotelRoomCard_lblDetailRoomInformations: null,
        hotelRoomCard_lblDetailRoomOptions: null,
        hotelRoomCard_lblDetailSeeDetails: null,
        hotelRoomCard_lblKontenjan: null,
        hotelRoomCard_lblLoadMore: "Показать больше опций                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        "hotelRoomCard_lblMinimum Konaklama": null,
        "hotelRoomCard_lblMinimum..": null,
        hotelRoomCard_lblMinimumKonaklama: null,
        hotelRoomCard_lblMinimumStay: null,
        hotelRoomCard_lblQuota: null,
        hotelRoomCard_lblRelease: null,
        hotelRoomCard_lblRoomCountTxt: "Номер                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        hotelRoomCard_lblStop: null,
        hotelRoomDetail_lblAddOnInfoDisabled: null,
        hotelRoomDetail_lblAddOnInfoFree: null,
        hotelSalesReport_agencyCommission: null,
        hotelSalesReport_filter_btnList: null,
        hotelSalesReport_filter_lblFromDate: null,
        hotelSalesReport_filter_lblReportType: null,
        hotelSalesReport_filter_lblStatus: null,
        hotelSalesReport_filter_lblToDate: null,
        hotelSalesReport_fromDate: null,
        hotelSalesReport_grandTotal: null,
        hotelSalesReport_guestCount: null,
        hotelSalesReport_reportType: null,
        hotelSalesReport_roomCount: null,
        hotelSalesReport_saleAmount: null,
        hotelSalesReport_tblMain_tdChannelGts: null,
        hotelSalesReport_tblMain_tdChannelProvider: null,
        hotelSalesReport_tblMain_thAgencyCommission: null,
        hotelSalesReport_tblMain_thAgencyName: null,
        hotelSalesReport_tblMain_thCheckinDate: null,
        hotelSalesReport_tblMain_thCheckoutDate: null,
        hotelSalesReport_tblMain_thContactName: null,
        hotelSalesReport_tblMain_thContactSurname: null,
        hotelSalesReport_tblMain_thGuestCount: null,
        hotelSalesReport_tblMain_thHotelLocation: null,
        hotelSalesReport_tblMain_thHotelName: null,
        hotelSalesReport_tblMain_thOnUsChannel: null,
        hotelSalesReport_tblMain_thOptionDaysLeft: null,
        hotelSalesReport_tblMain_thOrderId: null,
        hotelSalesReport_tblMain_thRoomCount: null,
        hotelSalesReport_tblMain_thSaleDateTime: null,
        hotelSalesReport_tblMain_thTotal: null,
        hotelSalesReport_tblMain_thVocuherStatus: null,
        hotelSalesReport_tblMain_thVoucher: null,
        hotelSalesReport_tblMain_thVoucherStatus: null,
        hotelSalesReport_title: null,
        hotelSalesReport_toDate: null,
        hotelSalesReport_totalAgencyCommission: null,
        hotelSalesReport_totalGuestCount: null,
        hotelSalesReport_totalRoomCount: null,
        hotelSalesReport_totalVoucherCount: null,
        hotelSalesReport_voucherCount: null,
        HotelSalesSummaryReportList_thAgencyId: null,
        hotelSearch_btnSearch: null,
        hotelSearch_btnShowFilter: null,
        hotelSearch_lblDate: null,
        hotelSearch_lblDestination: null,
        hotelSearch_lblEmptyFilterResult: null,
        hotelSearch_lblEmptyHotelList: null,
        hotelSearch_lblFilterClearAll: null,
        hotelSearch_lblFilterStatus: null,
        hotelSearch_lblFilterStatusOff: null,
        hotelSearch_lblFilterStatusOn: null,
        hotelSearch_lblGuest: null,
        hotelSearch_lblNationality: null,
        hotelSearch_lblNoFoundedHotels: null,
        hotelSearch_lblNonRecommendedHotels: null,
        hotelSearch_lblOnlyGtsDirect: null,
        hotelSearch_lblTotalHotelNumber: null,
        hotelSearchForm_lblArea: null,
        hotelSearchForm_lblCode: null,
        hotelSearchForm_lblMail: null,
        hotelSearchGuest_btnRemoveRoom: null,
        hotelSearchGuest_lblAdult: null,
        hotelSearchGuest_lblChild: null,
        hotelSearchGuest_lblChildAge: null,
        hotelSearchGuest_lblRoomCount: null,
        hotelSearchGuest_phAdultCount: null,
        hotelSearchGuest_phChildCouunt: null,
        hotelSearchGuest_phRoomCount: null,
        hotelSearchInformation_adult: null,
        hotelSearchInformation_child: null,
        hotelSearchInformation_data: null,
        hotelSearchInformation_date1: null,
        hotelSearchInformation_date2: null,
        hotelSearchInformation_footer: null,
        hotelSearchInformation_location: null,
        hotelSearchInformation_rooms: null,
        hotelSearchInformation_text: null,
        hotelSearchResultPage_hotelCard_informationTab_hotelDescription_title: null,
        hotelSearchResultPage_hotelCard_informationTab_hotelMap_title: null,
        invoiceList_filter_btnList: null,
        invoiceList_filter_lblFromDate: null,
        invoiceList_filter_lblOrderId: null,
        invoiceList_filter_lblPnr: null,
        invoiceList_filter_lblToDate: null,
        invoiceList_filter_phOrderId: null,
        invoiceList_filter_phPnr: null,
        invoiceList_info_FCADOM: null,
        invoiceList_info_FCAINT: null,
        invoiceList_info_FCCDOM: null,
        invoiceList_info_FCCINT: null,
        invoiceList_info_FCODOM: null,
        invoiceList_info_FCOINT: null,
        invoiceList_info_HCADOM: null,
        invoiceList_info_HCAINT: null,
        invoiceList_info_HCCDOM: null,
        invoiceList_info_HCCINT: null,
        invoiceList_info_HCODOM: null,
        invoiceList_info_HCOINT: null,
        invoiceList_info_RCAINT: null,
        invoiceList_info_RCCINT: null,
        invoiceList_info_RCOINT: null,
        invoiceList_info_TCAINT: null,
        invoiceList_info_TCCINT: null,
        invoiceList_info_TCOINT: null,
        invoiceList_tblMain_thGrandTotal: null,
        invoiceList_tblMain_thInvoiceDate: null,
        invoiceList_tblMain_thInvoiceId: null,
        invoiceList_tblMain_thInvoiceInfo: null,
        invoiceList_tblMain_thInvoiceNote: "Примечание                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        invoiceList_tblMain_thInvoiceNumber: null,
        invoiceList_tblMain_thInvoiceTotal: null,
        invoiceList_title: null,
        invoiceListPage_invoiceListDataTable_invoiceDateLabel: null,
        invoiceListPage_invoiceListDataTable_invoiceNumberLabel: null,
        invoiceListPage_pageTitle: null,
        invoiceListPage_searchForm_endDateLabel: null,
        invoiceListPage_searchForm_orderIdLabel: null,
        invoiceListPage_searchForm_pnrLabel: null,
        invoiceListPage_searchForm_startDateLabel: null,
        languageFullName_de: null,
        languageFullName_en: null,
        languageFullName_ru: null,
        languageFullName_tr: null,
        legInformation_lblEdit: null,
        legInformation_titleLegPriceInfos: null,
        legInformation_titleLegs: null,
        loginPage_resetPasswordSuccessPopupText: null,
        MasterAgency_returnTobeGsa: null,
        multiSelectComponent_itemSelected: null,
        navbarComponent_switchToDarkMode: null,
        navbarComponent_switchToLightMode: null,
        notesComp_notTime: null,
        notesComponent_infoAlert_text: null,
        NotificationComponent_btnAllowNotification: null,
        NotificationComponent_deleteAllNotificationConfirm: null,
        NotificationComponent_deleteNotificationConfirm: null,
        NotificationComponent_emptyOlder: null,
        NotificationComponent_emptyToday: null,
        NotificationComponent_emptyWeek: null,
        NotificationComponent_emptyYesterday: null,
        NotificationComponent_markAsRead: null,
        NotificationComponent_notificationAllowed: null,
        NotificationComponent_notificationNotAllowed: null,
        NotificationComponent_notificationPage: null,
        NotificationComponent_notificationSettings: null,
        NotificationComponent_notificationsHeader: null,
        NotificationComponent_notificationStatus: null,
        NotificationComponent_older: null,
        NotificationComponent_readAllConfirm: null,
        NotificationComponent_removeAll: null,
        NotificationComponent_today: null,
        NotificationComponent_week: null,
        NotificationComponent_yesterday: null,
        onBehalfOfComponent_agency: null,
        onBehalfOfComponent_gsa: null,
        onBehalfOfComponent_lblAgency: null,
        onBehalfOfComponent_lblGsa: null,
        onBehalfOfComponent_phAgency: null,
        onBehalfOfComponent_phSelectAgency: null,
        onBehalfOfComponent_placeholder: null,
        orderDetail_addOnReadLess: null,
        orderDetail_addOnReadMore: null,
        orderDetail_btnBookingConfirmation: null,
        orderDetail_btnEticket: null,
        OrderDetail_btnGetReverseInfo: null,
        orderDetail_btnReReserve: null,
        orderDetail_btnReReserve_lblDefault: null,
        orderDetail_btnReReserve_lblRezerve: null,
        orderDetail_btnReserved: null,
        orderDetail_btnRnr: null,
        orderDetail_btnRnrProvider: null,
        orderDetail_btnRnrProviderBookingDoc: null,
        orderDetail_btnTnr: null,
        orderDetail_btnVoucher: null,
        orderDetail_exOrderId: null,
        orderDetail_gtsDirect: null,
        orderDetail_lblAccommodationDate: null,
        orderDetail_lblAccommodationInfoTitle: null,
        orderDetail_lblAddon: null,
        orderDetail_lblAddonInfoTitle: null,
        orderDetail_lblAddonPrice: null,
        orderDetail_lblAdress: null,
        orderDetail_lblAdultCount: null,
        orderDetail_lblAgencyCommission: null,
        orderDetail_lblAgencyId: null,
        orderDetail_lblAgencyName: null,
        orderDetail_lblAgencyPrice: null,
        orderDetail_lblarrivalFlightDateTime: null,
        orderDetail_lblArrivalFlightNumber: null,
        orderDetail_lblBaseAmount: null,
        orderDetail_lblBirthdate: null,
        orderDetail_lblBoardType: null,
        orderDetail_lblBookingDate: null,
        orderDetail_lblCheckinDate: null,
        orderDetail_lblCheckoutDate: null,
        orderDetail_lblChildAges: null,
        orderDetail_lblChildCount: null,
        orderDetail_lbldepartureFlightDateTime: null,
        orderDetail_lblDepartureFlightNumber: null,
        orderDetail_lblDriverInfoTitle: null,
        orderDetail_lblEmail: null,
        orderDetail_lblEventDate: null,
        orderDetail_lblFlightNumber: null,
        orderDetail_lblFromDestination: null,
        orderDetail_lblGender: null,
        orderDetail_lblGrandTotal: null,
        orderDetail_lblGuestType: null,
        orderDetail_lblHotelName: null,
        orderDetail_lblLastBookingDate: null,
        orderDetail_lblLastTicketDate: null,
        orderDetail_lblLastVoidDate: null,
        orderDetail_lblLegIndex: null,
        orderDetail_lblLegStatus: null,
        orderDetail_lblName: null,
        orderDetail_lblNameSurname: null,
        orderDetail_lblNationality: null,
        orderDetail_lblOrderId: null,
        orderDetail_lblOrderStatus: null,
        orderDetail_lblPackageProviderSchimetterling: "SCHMETTERLING                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        orderDetail_lblPartialPaymentLastBookingDate: null,
        orderDetail_lblPartialPaymentLastCancelDate: null,
        orderDetail_lblPartialPaymentLastVoidDate: null,
        orderDetail_lblPartialPaymentVoidDate: null,
        orderDetail_lblPassengerEmail: null,
        orderDetail_lblPassengerInfoRoomType: null,
        orderDetail_lblPassengerInfoTitle: null,
        orderDetail_lblPassengerPhone: null,
        orderDetail_lblPhoneNumber: null,
        orderDetail_lblPlannedPickupDateTime: null,
        orderDetail_lblPriceInfoTitle: null,
        orderDetail_lblProvider: null,
        orderDetail_lblProviderRnr: null,
        orderDetail_lblRentACarInfoTitle: null,
        orderDetail_lblReservationWarnings: null,
        orderDetail_lblReserveDate: null,
        orderDetail_lblReverseDate: null,
        orderDetail_lblRnr: null,
        orderDetail_lblRnrStatus: null,
        orderDetail_lblRoomInformations: null,
        orderDetail_lblRoomType: null,
        orderDetail_lblTicketDate: null,
        orderDetail_lblTnr: null,
        orderDetail_lblTnrStatus: null,
        orderDetail_lblToDestination: null,
        orderDetail_lblTotal: null,
        orderDetail_lblTotalPrice: null,
        orderDetail_lblTransferInfoTitle: null,
        orderDetail_lblTravelerInfoTitle: null,
        orderDetail_lblUserEmail: null,
        orderDetail_lblUserPhone: null,
        orderDetail_lblVehicle: null,
        orderDetail_lblVehicleTypeInfo: null,
        orderDetail_lblViewAddon: null,
        orderDetail_lblVoucher: null,
        orderDetail_lblVoucherId: "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        orderDetail_lblVoucherStatus: null,
        orderDetail_PartialCancellationContentText1: null,
        orderDetail_PartialCancellationContentText2: null,
        orderDetail_PartialCancellationContentText3: null,
        orderDetail_PartialCancellationContentText4: null,
        orderDetail_PartialCancellationPolicy: null,
        orderDetail_PartialPaymentDeadLineBody: null,
        orderDetail_PartialPaymentDeadLineTitle: null,
        orderDetail_partialPaymentRemainingPayment: null,
        orderDetail_partialPaymentRemainingText: null,
        orderDetail_partialPaymentRemainingTitle: null,
        orderDetail_passengerInformations_ancillaryTotal: null,
        orderDetail_passengerInformations_grandTotal: null,
        orderDetail_passengerInformations_paymentFreeTotal: null,
        orderDetail_passengerInformations_reissueTotal: null,
        orderDetail_passengerInformations_reverseTotal: null,
        orderDetail_passengerInformations_salesTotal: null,
        orderDetail_provider: null,
        "OrderDetail_tblPass.thDate": null,
        "OrderDetail_tblTnrLegs.thArrivalFlightNumber": null,
        "OrderDetail_tblTnrLegs.thLegIndex": null,
        orderDetail_thAgencyId: null,
        orderDetail_thAgencyName: null,
        orderDetail_thAirlinePnr: null,
        orderDetail_thLastPurchaseDateTime: null,
        orderDetail_thOrderId: null,
        orderDetail_thPassengerEmail: null,
        orderDetail_thPassengerPhone: null,
        orderDetail_thPnr: null,
        orderDetail_thProvider: null,
        orderDetail_thReservationDateTime: null,
        orderDetail_thStatus: null,
        orderDetail_thStatusLabel: null,
        orderDetail_thTicketDateTime: null,
        orderDetail_thUserEmail: null,
        orderDetail_thUserPhone: null,
        orderDetail_titleReservationInfo: null,
        orderDetail_transferDate: null,
        orderDetailFlight_txtTaskMessage: null,
        orderDetailPage_baggage: null,
        orderDetailPage_date: null,
        orderDetailPage_eticket: null,
        orderDetailPage_fare: null,
        orderDetailPage_flightInformation: null,
        orderDetailPage_grandTotal: null,
        orderDetailPage_lblBirthDate: "Дата рождения                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        orderDetailPage_nameSurname: null,
        orderDetailPage_orderSummaryHeader_agencyIdLabel: null,
        orderDetailPage_orderSummaryHeader_agencyNameLabel: null,
        orderDetailPage_orderSummaryHeader_agencyPhoneLabel: null,
        orderDetailPage_orderSummaryHeader_contactEmailLabel: null,
        orderDetailPage_orderSummaryHeader_contactPhoneLabel: null,
        orderDetailPage_orderSummaryHeader_orderIdLabel: null,
        orderDetailPage_orderSummaryHeader_passengerEmailLabel: null,
        orderDetailPage_orderSummaryHeader_passengerPhoneLabel: null,
        orderDetailPage_orderSummaryHeader_statusLabel: null,
        orderDetailPage_orderSummaryHeader_userEmailLabel: null,
        orderDetailPage_orderSummaryHeader_userPhoneLabel: null,
        orderDetailPage_passengerInformation: null,
        orderDetailPage_passengerInformation_sectionTitle: null,
        orderDetailPage_passengerSummaryHeader_roomTypeLabel: null,
        orderDetailPage_pnrSummaryHeader_airlinePnr: null,
        orderDetailPage_pnrSummaryHeader_lastPurchaseTime: null,
        orderDetailPage_pnrSummaryHeader_provider: null,
        orderDetailPage_pnrSummaryHeader_reservationInformations: null,
        orderDetailPage_pnrSummaryHeader_reservationTime: null,
        orderDetailPage_pnrSummaryHeader_status: null,
        orderDetailPage_pnrSummaryHeader_ticketDateTime: null,
        orderDetailPage_priceInformation_sectionTitle: null,
        orderDetailPage_priceSummaryHeader_accommodationDateLabel: null,
        orderDetailPage_priceSummaryHeader_agencyCommissionLabel: null,
        orderDetailPage_priceSummaryHeader_agencyNetPriceLabel: null,
        orderDetailPage_priceSummaryHeader_roomTypeLabel: null,
        orderDetailPage_priceSummaryHeader_totalPriceLabel: null,
        orderDetailPage_serviceFee: null,
        orderDetailPage_status: null,
        orderDetailPage_tax: null,
        orderDetailPage_voucherInformation_sectionTitle: null,
        orderDetailPage_voucherRoomTypeHeader_adultNumberLabel: null,
        orderDetailPage_voucherRoomTypeHeader_boardTypeLabel: null,
        orderDetailPage_voucherRoomTypeHeader_childrenAgeLabel: null,
        orderDetailPage_voucherRoomTypeHeader_childrenNumberLabel: null,
        orderDetailPage_voucherRoomTypeHeader_roomTypeLabel: null,
        orderDetailPage_voucherSummaryHeader_checkInLabel: null,
        orderDetailPage_voucherSummaryHeader_checkOutLabel: null,
        orderDetailPage_voucherSummaryHeader_hotelNameLabel: null,
        orderDetailPage_voucherSummaryHeader_lastBookingDateTimeLabel: null,
        orderDetailPage_voucherSummaryHeader_lastVoidDateTimeLabel: null,
        orderDetailPage_voucherSummaryHeader_providerLabel: null,
        orderDetailPage_voucherSummaryHeader_reserveDateTimeLabel: null,
        orderDetailPage_voucherSummaryHeader_reverseDateTimeLabel: null,
        orderDetailPage_voucherSummaryHeader_statusLabel: null,
        orderDetailPage_voucherSummaryHeader_totalPriceLabel: null,
        orderDetailPage_voucherSummaryHeader_voucherLabel: null,
        OrderDetailPayment_btnReverse: null,
        OrderDetailPayment_NOT_SALES_OFFICE: null,
        OrderDetailPayment_popup_EXCEPTION: null,
        OrderDetailPayment_popup_FAILURE: null,
        OrderDetailPayment_popup_FAILURE_VOUCHER_NOT_REVERSABLE: null,
        OrderDetailPayment_popup_REJECTED: null,
        OrderDetailPayment_popup_REJECTED_INSUFFICIENT_PAYMENT: null,
        OrderDetailPayment_popup_SUCCESS: null,
        OrderDetailPayment_progressGif_RESERVE_HOTEL: null,
        OrderDetailPayment_progressGif_TICKET_HOTEL: null,
        OrderDetailPayment_progressGif_TicketReservedFlight: null,
        orderDetailTab_btnPartialPaid: null,
        orderDetailtransfer__lblProvider: null,
        orderSearch_creationFromDate: null,
        orderSearch_creationToDate: null,
        orderSearch_filter_btnSearch: null,
        orderSearch_filter_lblCreationFromDate: null,
        orderSearch_filter_lblCreationToDate: null,
        orderSearch_filter_lblDateType: null,
        orderSearch_filter_lblEticket: null,
        orderSearch_filter_lblHotel: null,
        orderSearch_filter_lblName: "Имя                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ",
        orderSearch_filter_lblOrderId: null,
        orderSearch_filter_lblPnr: null,
        orderSearch_filter_lblPnrStatus: null,
        orderSearch_filter_lblProviderList: null,
        orderSearch_filter_lblRentACar: null,
        orderSearch_filter_lblRnr: null,
        orderSearch_filter_lblRnrStatus: null,
        orderSearch_filter_lblSurname: null,
        orderSearch_filter_lblVoucher: null,
        orderSearch_filter_lblVoucherId: "Voucher ID",
        orderSearch_filter_lblVoucherStatus: null,
        orderSearch_filter_plhEticket: null,
        orderSearch_filter_plhName: "Имя                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ",
        orderSearch_filter_plhOrderId: null,
        orderSearch_filter_plhPnr: null,
        orderSearch_filter_plhRnr: null,
        orderSearch_filter_plhSurname: null,
        orderSearch_filter_plhVoucher: null,
        orderSearch_filter_plhVoucherId: "Voucher ID",
        orderSearch_orderSearch: null,
        orderSearch_pageTitle: null,
        orderSearch_pnrStatus: null,
        orderSearch_providerList: null,
        orderSearch_tblMain_agencyName: null,
        orderSearch_tblMain_dropOffLocation: null,
        orderSearch_tblMain_etickets: null,
        orderSearch_tblMain_gsaName: null,
        orderSearch_tblMain_insertedDate: null,
        orderSearch_tblMain_insertedUser: null,
        orderSearch_tblMain_nameSurname: null,
        orderSearch_tblMain_orderId: null,
        orderSearch_tblMain_pickUpDateTime: null,
        orderSearch_tblMain_pickUpLocation: null,
        orderSearch_tblMain_pnrs: null,
        orderSearch_tblMain_pnrStatuses: null,
        orderSearch_tblMain_provider: null,
        orderSearch_tblMain_rnr: null,
        orderSearch_tblMain_rnrStatus: null,
        orderSearch_tblMain_rooms: null,
        orderSearch_tblMain_tnrs: null,
        orderSearch_tblMain_tnrStatuses: null,
        orderSearch_tblMain_vehicle: null,
        orderSearch_tblMain_voucherId: "Voucher ID",
        orderSearch_tblMain_vouchers: null,
        orderSearch_tblMain_voucherStatuses: null,
        orderSearch_voucherStatus: null,
        orderSearch__filter_lblVoucherStatus: null,
        orderSearch_filter_lblFlight: null,
        orderSearch_filter_lblFromLocation: null,
        orderSearch_filter_lblTnr: null,
        orderSearch_filter_lblTnrStatus: null,
        orderSearch_filter_lblToLocation: null,
        orderSearch_filter_lblTransfer: null,
        orderSearch_filter_plhFromLocation: null,
        orderSearch_filter_plhTnr: null,
        orderSearch_filter_plhToLocation: null,
        otelSalesReport_tblMain_thContactSurname: null,
        passengerCard_country: null,
        passengerCard_dateofBirth: null,
        passengerCard_expiryDate: null,
        passengerCard_hesCode: null,
        passengerCard_identityNo: null,
        passengerCard_milesCard: null,
        passengerCard_milNo: null,
        passengerCard_nationality: null,
        passengerCard_passportNumber: null,
        passengerCard_serialNumber: null,
        paymentChoice_partialPaymentPaidText: null,
        paymentChoice_partialPaymentRemainingText: null,
        PaymentChoiceItem_btnLEAVE: null,
        PaymentChoiceItem_btnRESERVE: null,
        PaymentChoiceItem_btnREVERSE: null,
        PaymentChoiceItem_btnTICKET: null,
        PaymentChoiceItem_FLIGHT_UPCOMING: null,
        PaymentChoiceItem_INSUFFICIENT_AGENCY_BALANCE: null,
        PaymentChoiceItem_INSUFFICIENT_GSA_BALANCE: null,
        PaymentChoiceItem_lblCommission: null,
        PaymentChoiceItem_lblGrandTotal: null,
        PaymentChoiceItem_lblPartialPaymentDepositAmount: null,
        PaymentChoiceItem_lblRemainingBalance: null,
        PaymentChoiceItem_lblTotal: null,
        PaymentChoiceItem_NOT_SALES_OFFICE: null,
        PaymentChoiceItem_SSRS_CANT_BE_RESERVED: null,
        paymentChoiceItem_stepBack: null,
        PaymentChoiceItem_txtCommissionInfo_CREDITCARD: "За покупки и пополнение счета с помощью кредитных карт VISA, MASTER взимается фиксированная комиссия в размере 2%.  За покупки и пополнение счета с помощью кредитной карты AMEX взимается фиксированная банковская комиссия в размере 4,5 %.                                                                                                                                                                                                                                                                       ",
        PaymentChoiceItem_txtCommissionInfo_IDEAL: null,
        PaymentChoiceItem_txtCommissionInfo_SOFORT: null,
        paymentChoices_lblCARI: null,
        paymentComponent_commission: null,
        paymentComponent_paymentInformations: null,
        paymentComponent_remainingBalance: null,
        PaymentPurchaseWidget_btnReverse: null,
        PaymentPurchaseWidget_btnReverseWithPenalty: null,
        PaymentPurchaseWidget_FLIGHT_RESERVATION_LEGAL_NOTICE: null,
        PaymentPurchaseWidget_NO_AUTHORIZATED_ACTION: null,
        PaymentPurchaseWidget_NOT_SALES_OFFICE: null,
        paymentPurchaseWidget_partialPay: null,
        PaymentPurchaseWidget_popup_REVERSE_FLIGHT: null,
        PaymentPurchaseWidget_popup_REVERSE_FLIGHT_W_PENALTY: null,
        PaymentPurchaseWidget_popup_REVERSE_HOTEL: null,
        PaymentPurchaseWidget_popup_REVERSE_RENTACAR: null,
        PaymentPurchaseWidget_popup_REVERSE_TRANSFER: null,
        PaymentPurchaseWidget_popup_TICKET_FLIGHT: null,
        PaymentPurchaseWidget_popup_TICKET_HOTEL: null,
        PaymentPurchaseWidget_popup_title: null,
        PaymentPurchaseWidget_progressGif_RESERVE_HOTEL: null,
        PaymentPurchaseWidget_progressGif_RESERVE_TRANSFER: null,
        PaymentPurchaseWidget_progressGif_ReverseFlight: null,
        PaymentPurchaseWidget_progressGif_ReverseHotel: null,
        PaymentPurchaseWidget_progressGif_ReverseTransfer: null,
        PaymentPurchaseWidget_progressGif_TICKET_FINANCE: null,
        PaymentPurchaseWidget_progressGif_TICKET_HOTEL: null,
        PaymentPurchaseWidget_progressGif_TICKET_TRANSFER: null,
        PaymentPurchaseWidget_progressGif_TicketReservedFlight: null,
        PaymentPurchaseWidget_progressGif_TicketReservedHotel: null,
        pending_pending: null,
        PickupInfo_txtAirToAir: null,
        PickupInfo_txtAirToLoc: null,
        PickupInfo_txtLocToAir: null,
        PickupInfo_txtLocToLoc: null,
        PnrConfirm_bookingConfirmation: null,
        PnrConfirm_fare: null,
        PnrConfirm_grandTotal: null,
        PnrDoc_Email: null,
        pnrDoc_PopupCopiedToClipboard: null,
        PnrOffer_flight: null,
        popup_ancillaryDivideContent: null,
        popup_confirmationTitle: null,
        Popup_ERROR: null,
        Popup_exclamationTitle: null,
        Popup_HOTEL_REVERSE_FAILURE_VOUCHER_NOT_REVERSABLE: null,
        popup_informationTitle: null,
        popup_lblCheckout: null,
        Popup_lblChildrenAgesWarn: null,
        Popup_lblChildrenAgesWarnText: null,
        Popup_lblEmptySearch: null,
        Popup_lblFlightSalesReportDateRange: null,
        Popup_lblLessThanTwoCharacter: null,
        Popup_lblPackagesContent: null,
        Popup_lblPackagesTitle: null,
        Popup_lblPlsWaitForContact: null,
        Popup_lblRequestReceived: null,
        popup_lblReserve: null,
        Popup_lblSuccess: "Успешный                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        Popup_lblTransferMaintenance: null,
        Popup_lblWarning: "Error                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        Popup_ORDER_DETAIL_AUTHORIZATION_NOT_OWNER: null,
        Popup_RESERVE_HOTEL_FAILURE_RESERVATION: null,
        Popup_RESERVE_HOTEL_SUCCESS_OK: null,
        Popup_SUCCESS: null,
        popup_successTitle: null,
        "Popup_TICKET_HOTEL_EXCEPTION_error.no.enough.room.quota": null,
        Popup_TICKET_HOTEL_SUCCESS_OK: null,
        Popup_TICKETRESERVED_FLIGHT_SUCCESS_OK: null,
        Popup_TICKETRESERVED_HOTEL_SUCCESS_OK: null,
        Popup_txtDuplicatedPassenger: null,
        Popup_txtEmptySearch: null,
        Popup_txtFlightChangeError: "Вы получили ошибку при подтверждении. Пожалуйста, свяжитесь с командой нашего сервисного центра.                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        Popup_txtFlightChangeErrorApprove: "Вы получили ошибку при подтверждении. Пожалуйста, свяжитесь с командой нашего сервисного центра.                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        Popup_txtFlightChangeErrorPNR_Reissue: "Вы получили ошибку при подтверждении. Пожалуйста, свяжитесь с командой нашего сервисного центра.                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        Popup_txtFlightChangeSuccess: "Вы успешно подтвердили изменение рейса. Вы можете обратиться в сервисный центр.                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        Popup_txtFlightChangeSuccessApprove: "Вы успешно подтвердили изменение рейса. Вы можете обратиться в сервисный центр.                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        Popup_txtFlightChangeSuccessPNR_Reissue: "Ваш запрос был успешно передан нашей команде. Вы можете проследить за выполнением запроса через Центр обслуживания.                                                                                                                                                                                                                                                                                                                                                                                                 ",
        Popup_txtFlightChangeSuccessPNR_Reverse: "Ваш запрос на отмену был успешно отправлен нашим специалистам. Вы можете проследить за выполнением запроса через Центр обслуживания.                                                                                                                                                                                                                                                                                                                                                                                ",
        Popup_txtLessThanTwoCharacter: null,
        Popup_txtMealNotAvailable: null,
        Popup_txtSsrsNotAvailable: "An ssr request cannot be created for your reservation. Please contact the Service Center.                                                                                                                                                                                                                                                                                                                                                                                                                           ",
        Popup_txtWheelChairNotAvailable: null,
        Popup_txtWheelChairNowAvailable: null,
        popup_warningTitle: null,
        pricechange_btnCancel: null,
        priceChange_lblnewPrice: null,
        priceChange_lblOldPrice: null,
        priceChange_lblpriceDiff: null,
        profileSettings_lblAgencyAddress: null,
        profileSettings_lblAgencyCountryPhoneCode: null,
        profileSettings_lblAgencyCurrency: null,
        profileSettings_lblAgencyDescription: null,
        profileSettings_lblAgencyEmail: null,
        profileSettings_lblAgencyId: null,
        profileSettings_lblAgencyInfo: null,
        profileSettings_lblAgencyName: null,
        profileSettings_lblAgencyOfficialName: null,
        profileSettings_lblAgencyPhone: null,
        profileSettings_lblAgencyShortName: null,
        profileSettings_lblAgencyTimezone: null,
        profileSettings_lblBrowseProfilePhoto: null,
        profileSettings_lblDragDropFile: null,
        profileSettings_lblGsa: null,
        profileSettings_lblPasswordChangeContent: null,
        profileSettings_lblPasswordChangeTitle: null,
        profileSettings_lblPrivacyAndSecurity: null,
        profileSettings_lblPrivacyAndSecurityDescription: null,
        profileSettings_lblProfilePhoto: null,
        profileSettings_lblProfilePhotoDescription: null,
        profileSettings_lblProfileSettings: null,
        profileSettings_lblRegionLanguagePreferences: null,
        profileSettings_lblRegionLanguagePreferencesDescription: null,
        profileSettings_lblReset: null,
        profileSettings_lblResetPassword: null,
        profileSettings_lblUpdate: null,
        profileSettings_lblUserDescription: null,
        profileSettings_lblUserEmail: null,
        profileSettings_lblUserInfo: null,
        profileSettings_lblUserLanguage: null,
        profileSettings_lblUserName: null,
        profileSettings_lblUserPhone: null,
        profileSettings_lblUserSurname: null,
        profileSettings_lblUserTimezone: null,
        renatacar_tooltipINSURANCE_ECO: null,
        rentacar_airConditioning: null,
        rentacar_Ancillaries: null,
        rentacar_btnCancel: null,
        rentacar_btnSearch: null,
        rentacar_btnSearchDaily: null,
        rentacar_btnSubmit: null,
        rentacar_doorCount: null,
        rentacar_driverInformations: null,
        rentacar_dropoffDate: null,
        rentacar_extraFeatures: null,
        rentacar_filtersFuelType: null,
        rentacar_filtersTransmissionType: null,
        rentacar_filtersVehicleType: null,
        rentacar_gearType: null,
        rentacar_largeBaggage: null,
        rentacar_lblAddDropoffLocation: null,
        rentacar_lblAncillaries: null,
        rentacar_lblDropoffLocation: null,
        rentacar_lblPerDay: null,
        rentacar_lblPickupLocaton: null,
        rentacar_lblRentACarDescriptionsInfoFives: null,
        rentacar_lblRentACarDescriptionsInfoFour: null,
        rentacar_lblRentACarDescriptionsInfoOne: null,
        rentacar_lblRentACarDescriptionsInfoThree: null,
        rentacar_lblRentACarDescriptionsInfoTwo: null,
        rentacar_lblRentACarDescriptionsTitle: null,
        rentacar_lblShowAncillaries: null,
        rentacar_lblTermsOfUse: null,
        rentacar_locationSearchlbl: null,
        rentacar_orSmiliar: null,
        rentacar_passengerCapacity: null,
        rentacar_pickupDate: null,
        rentacar_ReservationDate: null,
        rentacar_smallBaggage: null,
        rentacar_suitCases: null,
        rentacarCard_minDriverAge: null,
        rentacarCard_minLicenseAge: null,
        rentacarCheckout_lblAgencyCommission: null,
        rentacarCheckout_lblAgencyExtraCommission: null,
        rentacarCheckout_lblAncillaries: null,
        rentacarCheckout_lblAncillaryTotal: null,
        rentacarCheckout_lblDropOffLocation: null,
        rentacarCheckout_lblGrandTotal: null,
        rentacarCheckout_lblLastVoidDateTime: null,
        rentacarCheckout_lblPaymentFeeTotal: null,
        rentacarCheckout_lblPickUpLocation: null,
        rentacarCheckout_lblPriceSummary: null,
        rentacarCheckout_lblReverseTotal: null,
        rentacarCheckout_lblSaleTotal: null,
        rentacarCheckout_lblVehicleTotal: null,
        rentACarOrderDetail_lblRentACarAgencyNote: null,
        rentacarOrderDetail_phOr: null,
        rentacarOrderDetail_phSimilar: null,
        rentACarSalesReport_currency: null,
        rentACarSalesReport_filter_btnList: null,
        rentACarSalesReport_filter_lblFromDate: null,
        rentACarSalesReport_filter_lblReportType: null,
        rentACarSalesReport_filter_lblStatus: null,
        rentACarSalesReport_filter_lblToDate: null,
        rentACarSalesReport_lblReportType: null,
        rentACarSalesReport_refundTotal: null,
        rentACarSalesReport_reverseAmount: null,
        rentACarSalesReport_rnrCount: null,
        rentACarSalesReport_tblMain_thAgencyCommission: null,
        rentACarSalesReport_tblMain_thAgencyName: null,
        rentACarSalesReport_tblMain_thDriverName: null,
        rentACarSalesReport_tblMain_thDriverSurname: null,
        rentACarSalesReport_tblMain_thDropOffDateTime: null,
        rentACarSalesReport_tblMain_thDropOffLocation: null,
        rentACarSalesReport_tblMain_thGrandTotal: null,
        rentACarSalesReport_tblMain_thMarkModel: null,
        rentACarSalesReport_tblMain_thOrderId: null,
        rentACarSalesReport_tblMain_thPickUpDateTime: null,
        rentACarSalesReport_tblMain_thPickUpLocation: null,
        rentACarSalesReport_tblMain_thRnr: null,
        rentACarSalesReport_tblMain_thSaleDate: null,
        rentACarSalesReport_tblMain_thStatus: null,
        rentACarSalesReport_tblMain_thTotal: null,
        rentACarSalesReport_title: null,
        rentACarSalesReport_totalAmount: null,
        rentACarSalesReport_vehicleCount: null,
        rentacarSearchForm_lblCreditCardDepositInfo: null,
        rentacarSearchInformation_date1: null,
        rentacarSearchInformation_date2: null,
        rentacarSearchInformation_locationFrom: null,
        rentacarSearchInformation_locationTo: null,
        rentacarSearchInformation_text: null,
        requestForm_activeTab: null,
        requestForm_packageTitle: "Задача откроет пакет                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ",
        requestForm_pnrTitle: null,
        requestForm_rnrTitle: null,
        requestForm_tnrTitle: null,
        requestForm_unknownTitle: null,
        requestForm_voucherTitle: null,
        requestFormComponent_infoAlert_text: null,
        requestFormComponent_textareaPlaceholder_label: null,
        reservationInfo_lblPopupPriceChangeTitle: null,
        ReservationInformation_progressGif_ReReserveFlight: null,
        resetPassword_emailEmpty: null,
        resetPassword_emailHasBeenSent: null,
        resetPassword_lowerCase: null,
        resetPassword_minChar: null,
        resetPassword_numberRequired: null,
        resetPassword_passwordResetFaile: null,
        resetPassword_passwordResetFailed: null,
        resetPassword_passwordResetMessage: null,
        resetPassword_passwordResetSuccess: null,
        resetPassword_passwordResetTitle: null,
        resetPassword_passwordsAreDifferent: null,
        resetPassword_passwordsAreDifferentOrShort: null,
        resetPassword_passwordsAreShort: null,
        resetPassword_resetPassword: null,
        resetPassword_specialChar: null,
        resetPassword_upperCase: null,
        resetPassword_userPassword: null,
        resetPassword_userPasswordConfirm: null,
        RESPONSE___EXCEPTION_QUOTA: null,
        RESPONSE___EXCEPTION_STOP: null,
        RESPONSE___EXPIRED_OK: null,
        RESPONSE__RESERVE_HOTEL_SUCCESS_OK: null,
        RESPONSE__TICKET_HOTEL_SUCCESS_OK: null,
        RESPONSE__TICKET_TRANSFER_SUCCESS_OK: null,
        RESPONSE__TICKETRESERVED_HOTEL_SUCCESS_OK: null,
        "RESPONSE__TRANSFER_API_TICKET_TRANSFER_ISSUED_::TchnGtsDatabaseService.Reservation successful": null,
        "RESPONSE__TRANSFER_API_TICKET_TRANSFER_ISSUED_::TchnGtsDatabaseService.Reservation successful orderId:": null,
        RESPONSE_429_TooManyRequests_OK: null,
        "RESPONSE_AGENT_API.HOTEL_TICKET_RESERVED.REJECTED.INSUFFICIENT_PAYMENT": null,
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.FAILURE.NO_VEHICLE_FOUND": null,
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.NOT_EXISTS.LOCATION_NOT_MAPPED_FOR_PANDORA": null,
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.REJECTED.PARAMETER.PERIOD_DAYS": null,
        "RESPONSE_AGENT_API.RENT_A_CAR_SEARCH.REJECTED.PARAMETER.PICKUP_HOURS": null,
        RESPONSE_AGENT_API_BALANCE_UPDATE_PARAMETER_OK: null,
        RESPONSE_AGENT_API_BALANCE_UPDATE_REJECTED_EXPIRED: null,
        RESPONSE_AGENT_API_BALANCE_UPDATE_REJECTED_INSUFFICIENT_PAYMENT: null,
        RESPONSE_AGENT_API_BalanceUpdate_AUTHORIZATION_NOT_SALES_OFFICE: null,
        RESPONSE_AGENT_API_CHECKOUT_EXPIRED: null,
        "RESPONSE_AGENT_API_Detail_EXCEPTION_One or more errors occurred_": null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TAKEN_OFF: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TAKEN_OFF_TITLE: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TIME_NOT_AVAILABLE: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_FLIGHT_TIME_NOT_AVAILABLE_TITLE: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_MANUAL_DIVIDED: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_MANUAL_DIVIDED_TITLE: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_ANCILLARY: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_ANCILLARY_TITLE: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_TASK: null,
        RESPONSE_AGENT_API_FLIGHT_BAGGAGE_TICKET_REJECTED_PNR_HAS_TASK_TITLE: null,
        "RESPONSE_AGENT_API_FLIGHT_CHECKOUT_ PARAMETER_NOT_RESERVABLE": null,
        "RESPONSE_AGENT_API_FLIGHT_CHECKOUT_ PARAMETER_NOT_TICKETABLE": null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_AUTHORIZATION_DIFFERENT_USER: null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXCEPTION_CHECKOUT: null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_BASKET: null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_CHECKOUT: null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_EXPIRED_SEARCH: null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_FAILURE_NO_PRICE: null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_PARAMETER_NOT_RESERVABLE: null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_RETRY_NO_RESPONSE: null,
        RESPONSE_AGENT_API_FLIGHT_CHECKOUT_SESSION_INVALID_AGENCY: null,
        RESPONSE_AGENT_API_FLIGHT_RESERVE_EXPIRED_CHECKOUT_NOT_FOUND: "Во время процесса отмены был получен тайм-аут. Пожалуйста, повторите попытку позже.                                                                                                                                                                                                                                                                                                                                                                                                                                 ",
        RESPONSE_AGENT_API_FLIGHT_RESERVE_FAILURE_RESERVATION_FAILED: null,
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_BRAND_OR_SSR_NOT_FOUND: null,
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_CHECKOUT_FAILED: null,
        RESPONSE_AGENT_API_FLIGHT_RESERVE_NOT_EXISTS_FLIGHT_NOT_FOUND: null,
        RESPONSE_AGENT_API_FLIGHT_RESERVE_SESSION_INVALID_GSA_OR_AGENCY: null,
        RESPONSE_AGENT_API_FLIGHT_REVERSE_EXPIRED_INQUIRY: null,
        RESPONSE_AGENT_API_FLIGHT_REVERSE_FAILURE_ALREADY_REVERSED: null,
        RESPONSE_AGENT_API_FLIGHT_REVERSE_FAILURE_UNREVERSABLE: null,
        RESPONSE_AGENT_API_FLIGHT_REVERSE_INQUIRY_REJECTED_PNR_EXPIRED: "Время последней покупки истекло. Вы можете отменить покупку вручную, обратившись в сервисный центр.                                                                                                                                                                                                                                                                                                                                                                                                                 ",
        RESPONSE_AGENT_API_FLIGHT_REVERSE_SUCCESS_OK: null,
        RESPONSE_AGENT_API_FLIGHT_SEARCH_SESSION_INVALID_AGENCY: null,
        RESPONSE_AGENT_API_FLIGHT_SELECT_AUTHORIZATION_DIFFERENT_USER: null,
        RESPONSE_AGENT_API_FLIGHT_SELECT_EXPIRED_BASKET: null,
        RESPONSE_AGENT_API_FLIGHT_SELECT_NOT_EXISTS_NO_PACKAGE_AVAILABLE: null,
        RESPONSE_AGENT_API_FLIGHT_SELECT_REJECTED_UNAVAILABLE_FAMILY_SELECTION: null,
        "RESPONSE_AGENT_API_FLIGHT_TICKET_ FAILURE_PRICE_DIFFERENCE": null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_EXCEPTION_PNR_RETRIEVE: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_EXCEPTION_TICKET: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_PRICE_DIFFERENCE_TITLE: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_REJECTED: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_FAILURE_REJECTED_ISSUED: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_PARAMETER_EXISTED: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_PRICE_DIFFERENCE_AFTER_RESERVATION: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_CHANGE_DETECTED: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_IMPORT_TYPE_MANUAL: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_FUNDS: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_INSUFFICIENT_PAYMENT: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_REJECTED_UNSUFFICIENT_FUNDS: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_RETRY_RESERVATION_PRICE_CHECK: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_SESSION_INVALID_AGENCY: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_SUCCESS_PAYMENT_POST: null,
        RESPONSE_AGENT_API_FLIGHT_TICKET_TIMEOUT_EXPIRED: null,
        RESPONSE_AGENT_API_HOTEL_CHECKOUT_EXPIRED_BASKET: null,
        RESPONSE_AGENT_API_HOTEL_CHECKOUT_REJECTED_CHECKOUT_NOT_AVAILABLE: null,
        RESPONSE_AGENT_API_HOTEL_DETAIL_EXPIRED_HOTEL_SEARCH: null,
        RESPONSE_AGENT_API_HOTEL_REVERSE_FAILURE_VOUCHER_CANT_RETRIEVED: null,
        RESPONSE_AGENT_API_HOTEL_SEARCH_PARAMETER_INVALID_DESTINATION: null,
        RESPONSE_AGENT_API_HOTEL_SEARCH_SESSION_INVALID_AGENCY: null,
        RESPONSE_AGENT_API_HOTEL_SELECT_EXPIRED_HOTEL_DETAIL: null,
        "RESPONSE_AGENT_API_IAgtBllSchmetterlingService.ShowOrder_FAILURE_NULL_LIST_RESPONSE": null,
        RESPONSE_AGENT_API_LOGIN_AUTHORIZATION_INVALID_CREDENTIALS: null,
        RESPONSE_AGENT_API_LOGIN_AUTHORIZATION_USER_BLOCKED: null,
        RESPONSE_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: null,
        RESPONSE_AGENT_API_LOGIN_PARAMETER_INVALID_DOMAIN_NAME: null,
        RESPONSE_AGENT_API_ORDER_DETAIL_AUTHORIZATION_NOT_OWNER: null,
        RESPONSE_AGENT_API_PWD_RESET_REJECTED_INVALID_DOMAIN_NAME: null,
        RESPONSE_AGENT_API_PWD_RESET_REJECTED_INVALID_USER: null,
        RESPONSE_AGENT_API_RENT_A_CAR_BOOK_REJECTED_INSUFFICIENT_PAYMENT: null,
        RESPONSE_AGENT_API_RENT_A_CAR_BOOK_REJECTED_PAYMENT_POST: null,
        RESPONSE_AGENT_API_RENT_A_CAR_REVERSE_FAILURE_FAILURE: null,
        RESPONSE_AGENT_API_RENT_A_CAR_SEARCH_PARAMETER_INVALID_DATES: null,
        RESPONSE_AGENT_API_TAKE_NOTE_PARAMETER_INVALID_NOTE: null,
        RESPONSE_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE: null,
        RESPONSE_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE_TITLE: null,
        RESPONSE_AGENT_API_TASK_CREATE_SUCCESS_OK: null,
        RESPONSE_AGENT_API_TICKET_EXCEPTION: null,
        RESPONSE_AGENT_API_TICKET_EXPIRED: null,
        RESPONSE_AGENT_API_TICKET_FAILURE: null,
        RESPONSE_AGENT_API_TICKET_FAILURE_PRICE_DIFFERENCE: null,
        RESPONSE_AGENT_API_TICKET_FAILURE_PRICE_DIFFERENCE_TITLE: null,
        RESPONSE_AGENT_API_TICKET_HOTEL_REJECTED_INSUFFICIENT_PAYMENT: null,
        RESPONSE_AGENT_API_TICKET_PARAMETER: null,
        RESPONSE_AGENT_API_TICKET_PRICE_DIFFERENCE: null,
        RESPONSE_AGENT_API_TICKET_PRICE_DIFFERENCE_TITLE: null,
        RESPONSE_AGENT_API_TICKET_REJECTED_PNR_STATUS: null,
        RESPONSE_AGENT_API_TICKET_RETRY: null,
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_FAILURE_PRICE_DIFFERENCE: null,
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_FAILURE_REJECTED: null,
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_CHANGE_DETECTED: null,
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_INSUFFICIENT_FUNDS: null,
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_INSUFFICIENT_PAYMENT: null,
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_REJECTED_PNR_EXPIRED: "Время последней покупки истекло. Вы можете выполнить проверку вручную, обратившись в сервисный центр.                                                                                                                                                                                                                                                                                                                                                                                                               ",
        RESPONSE_AGENT_API_TICKETRESERVED_FLIGHT_SUCCESS_OK: null,
        RESPONSE_AGENT_API_TICKETRESERVED_HOTEL_REJECTED_RETRIEVE_: null,
        RESPONSE_AGENT_API_UNKNOWN_REJECTED_HOTEL_CHECK_AVAILABILITY: null,
        RESPONSE_AGENT_PI_FLIGHT_TICKET_REJECTED_IMPORT_TYPE_MANUAL: null,
        RESPONSE_AGENT_WEB_FLIGHT_CHECKOUT_TIMEOUT_SERVICE_TIMEOUT: null,
        "RESPONSE_FLIGHT.API.FLIGHT.RESERVATION.FAILURE.INVALID.PARENT.AGE": null,
        "RESPONSE_FLIGHT.API.FLIGHT.TICKET.FAILURE.INVALID.PARENT.AGE": null,
        "RESPONSE_FLIGHT_API _FLIGHT_SSR_TICKET_FAILURE_REQ_SSR_UNAVAILABLE": null,
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.INVALID_AGE_FOR_PAX_TYPE": null,
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.INVALID_IDENTITY_INFO": null,
        "RESPONSE_FLIGHT_API.FLIGHT_RESERVATION.FAILURE.UNMAPPED_ERROR": null,
        "RESPONSE_FLIGHT_API.FLIGHT_REVERSE.FAILURE.UNMAPPED_ERROR": null,
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_EMPTY_SERVICE_RESPONSE: null,
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_EMPTY_SERVICE_RESPONSE_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_REJECTED: null,
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_REJECTED_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_FAILURE_UNMAPPED_ERROR_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_REJECTED_NOT_IMPLEMANTED_SERVICE: null,
        RESPONSE_FLIGHT_API_FLIGHT_BAGGAGE_SEARCH_REJECTED_NOT_IMPLEMANTED_SERVICE_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_REQUESTED_CLASS_NOT_AVAILABLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_CHECKOUT_NOT_EXISTS_PACKAGE_NOT_EXIST: null,
        RESPONSE_FLIGHT_API_FLIGHT_DIVIDE_PNR_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: null,
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_CONNECTION_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_EXPIRED_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_CONTINUE_WITH_INSTANT_TICKETING: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_DUBLICATE_PASSENGER: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_EMPTY_SERVICE_RESPONSE: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_EXCEEDED_MAX_PAX: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_HES_CODE_DECLINED: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_IDENTITY_OR_PASSPORT_NUMBER_MANDATORY: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_AGE_FOR_PAX_TYPE: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_CONTACT_INFO: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_INVALID_IDENTITY_INFO: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_MINIMUM_CONNECTION_TIME: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_NOT_ABLE_TO_VERIFY_LOYALTY_CARD: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_PRICE_CHANGED: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_REQUESTED_CLASS_NOT_AVAILABLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SELECTED_FLIGHT_NOT_AVAILABLE_FOR_BOOKING: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SERVICE_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_SSR_LIMIT_EXCEEDED: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_WAIT_LIST_CLOSED: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_INVALID_PASSENGER_INFO: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_PARAMETER_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_REJECTED_CONTINUE_WITH_INSTANT_TICKETING: null,
        RESPONSE_FLIGHT_API_FLIGHT_RESERVATION_REJECTED_MINIMUM_CONNECTION_TIME: null,
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_EMPTY_SERVICE_RESPONSE: null,
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_INVALID_PASSENGER_INFO: null,
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_SERVICE_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNKNOWN_ERROR_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_RETRIEVE_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_EXPIRED_REVERSE_INQUIRY_EXPIRED: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_EMPTY_SERVICE_RESPONSE: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_REJECTED: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_REVERSE_TYPE_CHANGED: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_CONNECTION_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_EMPTY_SERVICE_RESPONSE: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_SERVICE_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_INQUIRY_REJECTED_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_REJECTED_NOT_IMPLEMANTED_SERVICE: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_REJECTED_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_REVERSE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: null,
        RESPONSE_FLIGHT_API_FLIGHT_SEARCH_CONNECTION_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_CONNECTION_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INSUFFICIENT_STOCK: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INVALID_CONTACT_INFO: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_INVALID_PASSENGER_INFO: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_PNR_HAS_BEEN_MODIFIED: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATED_FLIGHT_CHANGED: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATED_PASSENGER_INFO_CHANGED: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_RESERVATION_INFO_CHANGED_MAKE_MANUALY: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_REJECTED_REQUESTED_CLASS_NOT_AVAILABLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_TICKET_TIMEOUT_SERVICE_TIMEOUT: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_FLIGHT_SCHEDULE_CHANGED: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_FLIGHT_SCHEDULE_CHANGED_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_REISSUE_NOT_AVAIL: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_REISSUE_NOT_AVAIL_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNKNOWN_ERROR_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_FAILURE_UNMAPPED_ERROR_TITLE: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_REJECTED_NOT_IMPLEMANTED_SERVICE: null,
        RESPONSE_FLIGHT_API_FLIGHT_VALIDATE_PNR_FOR_REISSUE_REJECTED_NOT_IMPLEMANTED_SERVICE_TITLE: null,
        "RESPONSE_FLIGHT_API_TICKET_EXCEPTION,": null,
        "RESPONSE_FLIGHT_API_TICKET_FAILURE,_COMMON_ERROR,": null,
        "RESPONSE_FLIGHT_API_TICKET_FAILURE,_UNKNOWN_ERROR,": null,
        RESPONSE_FLIGHT_API_TICKET_FAILURE_DUBLICATE_PASSENGER: null,
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_INSUFFICIENT_STOCK: null,
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_TICKETRESERVED_FLIGHT_FAILURE_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_UNKNOWN_EXCEPTION_UNMAPPED_ERROR: null,
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_COMMON_ERROR: null,
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_UNKNOWN_ERROR: null,
        RESPONSE_FLIGHT_API_UNKNOWN_FAILURE_UNMAPPED_ERROR: null,
        "RESPONSE_HOTEL_API.TICKET.EXCEPTION.MrCannotStay": null,
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_[holder.phone] allows digit only": null,
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_error.hotel.not.accepts.only.man": null,
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_error.no.enough.room.quota": null,
        RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_ExistingOfferId: null,
        RESPONSE_HOTEL_API_RESERVE_HOTEL_EXCEPTION_MrCannotStay: null,
        "RESPONSE_HOTEL_API_RESERVE_HOTEL_REJECTED_MR.STAY": null,
        "RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_ExistingOfferId_This offerid already requested for booking": null,
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_GTS_BOOKING: null,
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_NO_ROOM_AVAILABLE: null,
        RESPONSE_HOTEL_API_TICKET_HOTEL_EXCEPTION_ROOM_HAS_STOP: null,
        "RESPONSE_HOTEL_API_TICKET_HOTEL_REJECTED_MR.STAY": null,
        RESPONSE_HOTEL_API_TICKET_HOTEL_SUCCESS_OK: null,
        RESPONSE_PRICE_CHANGE: null,
        RESPONSE_REJECTED_NOT_AVAILABLE: null,
        RESPONSE_SUCCESS_OK: null,
        RESPONSE_TICKET_HOTEL_EXCEPTION_JP_BOOK_OCCUPANCY_NAMES_ERROR: null,
        RESPONSE_TICKET_TRANSFER_SUCCESS_OK: null,
        RESPONSE_TRANSFER_API_TICKET_TRANSFER_ISSUED_OK: null,
        RESULT_FLIGHT_API_FLIGHT_EXCEPTION_MIDDLEWARE_EXCEPTION_UNKNOWN_ERROR: null,
        RESULT_FLIGHT_API_FLIGHT_RESERVATION_FAILURE_REJECTED: null,
        roomCard_lblRoomPrice: null,
        roomCardComponent_roomDetail_buttonLabel: null,
        roomCardComponent_roomPriceLabel: null,
        router_agencyRequired: null,
        router_chooseAgency: null,
        router_contactForAuth: null,
        router_noAuth: null,
        router_noInvalidOtp: null,
        router_noInvalidOtpNote: null,
        router_titleAccTransactionList: null,
        router_titleBalanceUpdate: null,
        router_titleDashboard: null,
        router_titleDashboard2: null,
        router_titleFlightCheckout: null,
        router_titleFlightSale: null,
        router_titleFlightSalesReport: null,
        router_titleHotelCache: null,
        router_titleHotelCheckout: null,
        router_titleHotelSale: null,
        router_titleHotelSalesReport: null,
        router_titleInvoiceList: null,
        router_titleLogin: null,
        router_titleNotification: null,
        router_titleOrderDetail: null,
        router_titleOrderSearch: null,
        router_titlePackageSearch: null,
        router_titlePageNotFound: null,
        router_titlePnrDoc: null,
        router_titleRegister: null,
        router_titleRentACar: null,
        router_titleRentACarCheckut: null,
        router_titleRentACarSalesReport: null,
        router_titleSchmetterlingSalesReport: null,
        router_titleSettings: null,
        router_titleTaskList: null,
        router_titleTestDev: null,
        router_titleTransferCheckout: null,
        router_titleTransferSale: null,
        router_titleTransferSalesReport: null,
        router_titleVersion: null,
        saleReport_airlineList: null,
        saleReport_creationFromDate: null,
        saleReport_creationToDate: null,
        saleReport_optEticket: null,
        saleReport_optPNR: null,
        saleReport_pnrStatus: null,
        saleReport_providerList: null,
        saleReport_reportType: null,
        saleReport_showReissues: null,
        saleReport_userList: null,
        schmetterlingSalesReport_filter_btnList: null,
        schmetterlingSalesReport_filter_lblCustomerName: null,
        schmetterlingSalesReport_filter_lblCustomerSurname: null,
        schmetterlingSalesReport_filter_lblFromDate: null,
        schmetterlingSalesReport_filter_lblReservationNo: "Schmetterling ID                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        schmetterlingSalesReport_filter_lblStatus: "Статус                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        schmetterlingSalesReport_filter_lblToDate: null,
        schmetterlingSalesReport_filter_plhCustomerName: null,
        schmetterlingSalesReport_filter_plhCustomerSurname: null,
        schmetterlingSalesReport_filter_plhReservationNo: "Schmetterling ID                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        schmetterlingSalesReport_tblMain_thCheckInDateTime: null,
        schmetterlingSalesReport_tblMain_thCheckOutDateTime: null,
        schmetterlingSalesReport_tblMain_thCustomerNameSurname: "Имя и фамилия клиента                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ",
        schmetterlingSalesReport_tblMain_thOrderId: "Order ID                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        schmetterlingSalesReport_tblMain_thReservationNo: "Schmetterling ID                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        schmetterlingSalesReport_tblMain_thStatus: "Статус                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ",
        schmetterlingSalesReport_tblMain_thTotalPrice: null,
        schmetterlingSalesReport_tblMain_thTourOperator: null,
        schmetterlingSalesReport_tblMain_thTravelType: null,
        schmetterlingSalesReport_tblMain_thUser: null,
        schmetterlingSalesReport_title: null,
        Search_Response_AGENT_API_FLIGHT_SEARCH_SESSION_INVALID_AGENCY: null,
        Search_Response_AGENT_WEB_FLIGHT_SEARCH_TIMEOUT_SERVICE_TIMEOUT: null,
        Search_Response_FLIGHT_API_FLIGHT_SEARCH_CONNECTION_UNKNOWN_ERROR: null,
        searchComponentFlight_addLeg: null,
        searchComponentFlight_airlines: null,
        searchComponentFlight_airlinesSelected: null,
        searchComponentFlight_autocompleteArrival: null,
        searchComponentFlight_autocompleteDeparture: null,
        searchComponentFlight_cabinTypes: null,
        searchComponentFlight_cheapestPrice: "Самая низкая цена пакета                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ",
        searchComponentFlight_departureDate: null,
        searchComponentFlight_filterAirlines: null,
        searchComponentFlight_flightRules: null,
        searchComponentFlight_lastSeat: null,
        searchComponentFlight_paxes: null,
        searchComponentFlight_removeLeg: null,
        searchComponentFlight_returnDate: null,
        searchComponentFlight_searchFlight: null,
        searchComponentFlight_stops: null,
        searchComponentFlight_travelDuration: null,
        searchComponentFlight_waitingTime: null,
        searchComponentHotel_autocompleteLocationPlaceholder: null,
        searchComponentHotel_dateLabel: null,
        searchComponentHotel_destinationLabel: null,
        searchComponentHotel_guestLabel: null,
        searchComponentHotel_nationalityLabel: null,
        searchFiltersFlight_airlines: null,
        searchFiltersFlight_airlineTransfer: null,
        searchFiltersFlight_arrivalHour: null,
        searchFiltersFlight_baggageAllowance: null,
        searchFiltersFlight_business: null,
        searchFiltersFlight_cabinTypes: null,
        searchFiltersFlight_connections: null,
        searchFiltersFlight_departureHour: null,
        searchFiltersFlight_deselectAll: null,
        searchFiltersFlight_economy: null,
        searchFiltersFlight_EFltConnectionType_airlineTransfer: null,
        searchFiltersFlight_EFltConnectionType_noneStop: null,
        searchFiltersFlight_EFltConnectionType_stop: null,
        searchFiltersFlight_EFltConnectionType_UNKNOWN: null,
        searchFiltersFlight_noBaggage: null,
        searchFiltersFlight_noneStop: null,
        searchFiltersFlight_selectAll: null,
        searchFiltersFlight_stop: null,
        searchFiltersFlight_time: null,
        searchFiltersFlight_withBaggage: null,
        searchFiltersHotel_deselectAll: null,
        searchFiltersHotel_lblCustomPriceRange: null,
        SearchInformationPopupFlight_allAirports: null,
        SearchInformationPopupFlight_text: null,
        SearchOrderList_lblBrandPnr: null,
        seatMapModal_lblYanyana: null,
        SeatMapModal_progressGif: null,
        serviceCenter_AGENT_API_TASK_CREATE_REJECTED_DUBLICATE: null,
        serviceCenter_AGENT_API_TASK_CREATE_SUCCESS_OK: null,
        serviceCenter_ancillary: null,
        serviceCenter_cancellationRefund: null,
        serviceCenter_openTicket: null,
        serviceCenter_reissue: null,
        serviceCenterComponent_tabChangeDetails_label: "Изменение рейса                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     ",
        serviceCenterComponent_tabMyNotes_label: null,
        serviceCenterComponent_tabServiceCenterRequestForm_label: null,
        serviceCenterComponent_tabTaskChat_label: null,
        serviceCenterRequestForm_btnSend: null,
        serviceCenterRequestForm_popupTaskCreate: null,
        serviceCenterRequestForm_taskOpeningInfos: null,
        Set_Customer_SUCCESS_OK: null,
        setting_profileSettings: null,
        settings_customerManagement: null,
        settings_customerSettings: null,
        settings_profileSettings: null,
        settings_userAuthorization: null,
        settings_userAuths: null,
        settings_userSettings: null,
        settingsPage_customerBookSubPage_newCustomerAddButtonText: null,
        settingsPage_customerBookSubPage_pageTitle: null,
        settingsPage_lblRegionLanguagePreferencesDescription: null,
        settingsPage_navMenu_customerSettings: null,
        settingsPage_navMenu_profileSettings: null,
        settingsPage_navMenu_userRoles: null,
        settingsPage_navMenu_userSettings: null,
        settingsPage_profileSettingsSubPage_agencyInformationDescription: null,
        settingsPage_profileSettingsSubPage_agencyInformationLabel: null,
        settingsPage_profileSettingsSubPage_browseProfilePhotoButtonText: null,
        settingsPage_profileSettingsSubPage_dragDropFileText: null,
        settingsPage_profileSettingsSubPage_pageTitle: null,
        settingsPage_profileSettingsSubPage_profilePhotoDescription: null,
        settingsPage_profileSettingsSubPage_profilePhotoLabel: null,
        settingsPage_profileSettingsSubPage_regionAndLanguagePreferencesDescription: null,
        settingsPage_profileSettingsSubPage_regionAndLanguagePreferencesLabel: null,
        settingsPage_profileSettingsSubPage_userInformationDescription: null,
        settingsPage_profileSettingsSubPage_userInformationLabel: null,
        settingsPage_userAuthorizationSubPage_pageTitle: null,
        settingsPage_userSettingsSubPage_pageTitle: null,
        SignIn_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: null,
        SignIn_CookieText: null,
        SignIn_emailIsValidating: null,
        SignIn_forgotPassword: null,
        SignIn_legalText: null,
        SignIn_legalText2: null,
        SignIn_legalText3: null,
        SignIn_legalTextCookieUse: null,
        SignIn_legalTextPrivacyPolicy: null,
        SignIn_legalTextTermsOfService: null,
        SignIn_loginHeader1: null,
        SignIn_loginHeader2: null,
        SignIn_signInBtn: null,
        SignIn_TITLE_AGENT_API_LOGIN_PARAMETER_INVALID_CREDENTIALS: null,
        SignIn_userEmail: null,
        SignIn_userPassword: null,
        ssrSelection_lblSsrTitle: null,
        ssrSelectionBox_lblBAGGAGEDescription: null,
        ssrSelectionBox_lblbaggageTitle: null,
        ssrSelectionBox_lblCABIN_BAGDescription: null,
        ssrSelectionBox_lblcabin_bagTitle: null,
        ssrSelectionBox_lblcheckin_at_airportTitle: null,
        ssrSelectionBox_lblreissue_allowanceTitle: null,
        ssrSelectionBox_lblwheelchairTitle: null,
        targetAchievement_lblConfetti: null,
        targetAchievement_lblInformaton: null,
        targetAchievement_lblTarget: null,
        targetAchievement_lblTitle: null,
        taskDetails_openingNote: null,
        taskDetails_subStatus: null,
        taskDetails_taskOpenTime: null,
        taskDetails_type: null,
        taskList_filter_btnList: null,
        taskList_filter_lblFromDate: null,
        taskList_filter_lblStatus: null,
        taskList_filter_lblToDate: null,
        taskList_filter_lblType: null,
        taskList_tblMain_thAgency: null,
        taskList_tblMain_thClosingType: null,
        taskList_tblMain_thCreatedUser: null,
        taskList_tblMain_thEntity: null,
        taskList_tblMain_thEntityType: null,
        taskList_tblMain_thGsa: null,
        taskList_tblMain_thId: null,
        taskList_tblMain_thNote: null,
        taskList_tblMain_thOrderId: null,
        taskList_tblMain_thOrderStatus: null,
        taskList_tblMain_thStatus: null,
        taskList_tblMain_thSubStatus: null,
        taskList_tblMain_thTaskStatus: null,
        taskList_tblMain_thType: null,
        taskList_tblMain_thUpdatedDateTime: null,
        "TaskList_tblTaskMng.thETaskClosingType": null,
        "TaskList_tblTaskMng.thETaskSubStatus": null,
        "TaskList_tblTaskMng.thETaskType": null,
        taskList_title: null,
        taskListPage_pageTitle: null,
        taskListPage_searchForm_endDateLabel: null,
        taskListPage_searchForm_startDateLabel: null,
        taskListPage_searchForm_statusLabel: null,
        taskListPage_searchForm_taskTypeLabel: null,
        taskListPage_taskListDataTable_taskAgencyNameLabel: null,
        taskListPage_taskListDataTable_taskClosingTypeLabel: null,
        taskListPage_taskListDataTable_taskGsaLabel: null,
        taskListPage_taskListDataTable_taskIdLabel: null,
        taskListPage_taskListDataTable_taskLastUpdateTimeLabel: null,
        taskListPage_taskListDataTable_taskOpenedUserEmailLabel: null,
        taskListPage_taskListDataTable_taskOpeningDescriptionLabel: null,
        taskListPage_taskListDataTable_taskOrderIdLabel: null,
        taskListPage_taskListDataTable_taskPnrLabel: null,
        taskListPage_taskListDataTable_taskStatusLabel: null,
        taskListPage_taskListDataTable_taskSubStatusLabel: null,
        taskListPage_taskListDataTable_taskTypeLabel: null,
        taskNotes_placeholder: null,
        taxInformation_city_tax: "",
        taxInformation_electricity_fee: "",
        taxInformation_excludedTaxes: "",
        taxInformation_includedTaxes: "",
        taxInformation_service_fee: "",
        taxInformation_vat: "",
        tblOrderSearch_thAirlinePnr: null,
        test_arrayParamAdult: null,
        test_childAge1: null,
        test_childAge2: null,
        test_dynamic_text: null,
        test_test: null,
        tokenDirection_lblAppInstallation: null,
        tokenDirection_lblAppInstallationDownloadAppStore: null,
        tokenDirection_lblAppInstallationDownloadPlayStore: null,
        tokenDirection_lblAppInstallationGuideText: null,
        tokenDirection_lblAppInstallationGuideTextOrVisit: null,
        tokenDirection_lblAppInstallationOpenToken: null,
        tokenDirection_lblAppInstallationQrBelowInfo: null,
        tokenDirection_lblAppInstallationValidateToken: null,
        tokenDirection_lblExtraSecurityLayer: null,
        tokenDirection_lblExtraSecurityLayerInfo1: null,
        tokenDirection_lblExtraSecurityLayerInfo2: null,
        tokenDirection_lblExtraSecurityLayerLastPageInfo: null,
        tokenDirection_lblExtraSecurityLayerLastPageInfo2: null,
        tokenDirection_lblGeneratingVerificationCode: null,
        tokenDirection_lblGeneratingVerificationCodeText1: null,
        tokenDirection_lblGeneratingVerificationCodeText2: null,
        tokenDirection_lblGeneratingVerificationCodeText3: null,
        tokenDirection_lblLetsGo: null,
        tokenDirection_lblLoginWithToken: null,
        tokenDirection_lblLoginWithTokenText1: null,
        tokenDirection_lblLoginWithTokenText2: null,
        tokenDirection_lblSkip: null,
        tokenDirection_lblSupportService: null,
        tokenValidate_lblInfoText1: null,
        tokenValidate_lblInfoText2: null,
        tokenValidate_lblTitle: null,
        TransactionList_FLIGHT_ANCILLARY: null,
        TransactionList_PNR_COMMISSION_REVERSE: null,
        transactionListPage_AgencyBalance: null,
        transactionListPage_pageTitle: null,
        transactionListPage_searchForm_endDateLabel: null,
        transactionListPage_searchForm_startDateLabel: null,
        transactionListPage_transactionListDataTable_transactionBalanceLabel: null,
        transactionListPage_transactionListDataTable_transactionCreditLabel: null,
        transactionListPage_transactionListDataTable_transactionDateLabel: null,
        transactionListPage_transactionListDataTable_transactionDebtLabel: null,
        transactionListPage_transactionListDataTable_transactionEntityLabel: null,
        transactionListPage_transactionListDataTable_transactionInfoLabel: null,
        transactionListPage_transactionListDataTable_transactionOrderIdLabel: null,
        transactionListPage_transactionListDataTable_transactionProviderLabel: null,
        transactionListPage_transactionListDataTable_transactionTrxTypeLabel: null,
        transactionListPage_transactionListDataTable_transactionUserEmailLabel: null,
        transactionPage_transactionDataTable_balanceBeginLabel: null,
        transactionPage_transactionDataTable_balanceEndLabel: null,
        transactionPage_transactionDataTable_balanceTotalLabel: null,
        transfer_fillTravelerRows: null,
        transfer_fillTravelerRowsCancelBtn: null,
        transfer_fillTravelers: null,
        transfer_fillTravelersContent: null,
        transfer_lblAdult: null,
        transfer_lblChild: null,
        transfer_lblDepartureDate: null,
        transfer_lblExtraFeature: null,
        transfer_lblFromName: null,
        transfer_lblInfant: null,
        transfer_lblManuelRequest: null,
        transfer_lblPickupReturnDate: null,
        transfer_lblProvider: null,
        transfer_lblReturnDate: null,
        transfer_lblToName: null,
        transfer_lblTravelerCount: null,
        transfer_lblTravellers: null,
        transfer_manuelRequest: null,
        transfer_manuelRequestCancel: null,
        transfer_manuelRequestConfirm: null,
        transfer_manuelRequestModalTitle: null,
        transfer_phOrSimilar: null,
        transfer_RepresentativeVehicle: null,
        transfer_searchTransfer: null,
        transfer_travelerBirthDate: null,
        transfer_travelerNameSurname: null,
        transfer_travelerType: null,
        transferAutocomplete_lblDistrict: null,
        transferAutocomplete_lblHotel: null,
        transferAutocomplete_phFromDestination: null,
        transferAutocomplete_phToDestination: null,
        transferBasket_btnClear: null,
        transferBasket_lblTitle: null,
        transferBasket_lblTotalFare: null,
        TransferCard_btnRemoveCar: null,
        TransferCard_btnSelectCar: null,
        TransferCard_lblBaggage: null,
        TransferCard_lblCapacity: null,
        TransferCard_lblCardInformationText: null,
        TransferCard_lblInformation: null,
        TransferCard_lblMax: null,
        transferCheckout_agencyExtraCCommission: null,
        transferCheckout_lblAgencyCommission: null,
        transferCheckout_lblAgencyExtraCommission: null,
        TransferCheckout_lblAirCondition: null,
        TransferCheckout_lblArrival: null,
        TransferCheckout_lblBaggage: null,
        TransferCheckout_lblDeparture: null,
        transferCheckout_lblGrandTotal: null,
        TransferCheckout_lblImportantNote1: null,
        TransferCheckout_lblImportantNote2: null,
        TransferCheckout_lblImportantNote3: null,
        TransferCheckout_lblImportantNote4: null,
        TransferCheckout_lblImportantNote5: null,
        TransferCheckout_lblLastVoidDate: null,
        TransferCheckout_lblLightning: null,
        TransferCheckout_lblMax: null,
        TransferCheckout_lblPickUp: null,
        TransferCheckout_lblPickupTime: null,
        TransferCheckout_lblPnr: null,
        transferCheckout_lblTransferDescriptionsTitle: null,
        transferCheckout_titleGrandTotal: null,
        TransferCheckout_txtPickupInfo: null,
        TransferFilters_deselectAll: null,
        TransferFilters_lblFilters: null,
        TransferFilters_lblTransferTypes: null,
        TransferFilters_lblVehicles: null,
        TransferFilters_selectAll: null,
        TransferInformations_lblAgencyCommissionExtra: null,
        TransferInformations_lblAgencyCommissionRatio: null,
        TransferInformations_lblCar: null,
        TransferInformations_lblGrandTotal: null,
        TransferInformations_lblTotalPrice: null,
        TransferInformations_transferInformations: null,
        transferOrderDetail_lblAgencyCommission: null,
        transferOrderDetail_lblBaseTotal: null,
        transferOrderDetail_lblGrandTotal: null,
        transferOrderDetail_lblReverseTotal: null,
        transferOrderDetail_lblSalesTotal: null,
        transferOrderDetail_lblTransferAgencyNote: null,
        transferReissue_popupReiusseSuccessSubtitle: null,
        transferSalesReport_currency: null,
        transferSalesReport_filter_btnList: null,
        transferSalesReport_filter_lblFromDate: null,
        transferSalesReport_filter_lblReportType: null,
        transferSalesReport_filter_lblStatus: null,
        transferSalesReport_filter_lblToDate: null,
        transferSalesReport_legCount: null,
        transferSalesReport_refundTotal: null,
        transferSalesReport_tblMain_thAgencyCommission: null,
        transferSalesReport_tblMain_thAgencyName: null,
        transferSalesReport_tblMain_thFrom: null,
        transferSalesReport_tblMain_thFromDate: null,
        transferSalesReport_tblMain_thOrderId: null,
        transferSalesReport_tblMain_thProvider: null,
        transferSalesReport_tblMain_thSaleDate: null,
        transferSalesReport_tblMain_thStatus: null,
        transferSalesReport_tblMain_thTnr: null,
        transferSalesReport_tblMain_thTo: null,
        transferSalesReport_tblMain_thTotal: null,
        transferSalesReport_tblMain_thTransferDate: null,
        transferSalesReport_tblMain_thTransferType: null,
        transferSalesReport_tblMain_thTravelerCount: null,
        transferSalesReport_tblMain_thTravelerName: null,
        transferSalesReport_tblMain_thTravelerSurname: null,
        transferSalesReport_tblMain_thVehicleType: null,
        transferSalesReport_title: null,
        transferSalesReport_tnrCount: null,
        transferSalesReport_totalAmount: null,
        transferSalesReport_travelerCount: null,
        TransferSearch_lblCarFound: null,
        transferSearch_lblEmptyTransferList: null,
        transferSearch_lblFromDestination: null,
        transferSearch_lblToDestination: null,
        TransferSearch_lblTransferRoute: null,
        transferSearchInformation_adult: null,
        transferSearchInformation_child: null,
        transferSearchInformation_data: null,
        transferSearchInformation_date1: null,
        transferSearchInformation_date2: null,
        transferSearchInformation_infant: null,
        transferSearchInformation_locationFrom: null,
        transferSearchInformation_locationTo: null,
        transferSearchInformation_text: null,
        transferTravelerInformationsComponent_travelerInformations: null,
        userAuths_authChanged: null,
        UserAuths_authError: null,
        UserAuths_BalanceUpdate: null,
        UserAuths_CustomerSettings: null,
        UserAuths_FlightCheckout: null,
        UserAuths_FlightSale: null,
        UserAuths_FlightSalesReport: null,
        UserAuths_HotelCheckout: null,
        UserAuths_HotelSale: null,
        UserAuths_HotelSalesReport: null,
        UserAuths_InvoiceList: null,
        userAuths_lblAccTransactionList: null,
        userAuths_lblBalanceUpdate: null,
        userAuths_lblCustomerSettings: null,
        userAuths_lblDashboardV2: null,
        userAuths_lblFlightCheckout: null,
        userAuths_lblFlightSale: null,
        userAuths_lblFlightSalesReport: null,
        userAuths_lblHotelCheckout: null,
        userAuths_lblHotelSale: null,
        userAuths_lblHotelSalesReport: null,
        userAuths_lblInvoiceList: null,
        userAuths_lblNotification: null,
        userAuths_lblOrderDetail: null,
        userAuths_lblOrderSearch: null,
        userAuths_lblPersonalAuth: null,
        userAuths_lblProfileSettings: null,
        userAuths_lblRentACar: null,
        userAuths_lblRentACarCheckout: null,
        userAuths_lblRentACarSale: null,
        userAuths_lblRentACarSalesReport: null,
        userAuths_lblSchimetterling: null,
        userAuths_lblSchmetterlingSalesReport: "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ",
        userAuths_lblTaskList: null,
        userAuths_lblTransactionList: null,
        userAuths_lblTransferCheckout: null,
        userAuths_lblTransferSale: null,
        userAuths_lblTransferSalesReport: null,
        userAuths_lblUserAuths: null,
        userAuths_lblUserSettings: null,
        UserAuths_Notification: null,
        UserAuths_OrderDetail: null,
        UserAuths_OrderSearch: null,
        UserAuths_ProfileSettings: null,
        UserAuths_TaskList: null,
        userAuths_thAuth: null,
        userAuths_thId: null,
        userAuths_titleUserAuths: null,
        UserAuths_TransactionList: null,
        UserAuths_TransferCheckout: null,
        UserAuths_TransferSale: null,
        userAuths_userActive: null,
        UserAuths_UserAuths: null,
        userAuths_userPassive: null,
        UserAuths_UserSettings: null,
        userControlBox_lnkDashboard: null,
        userControlBox_lnkLogout: null,
        userControlBox_lnkSettings: null,
        userSettings_lblUserEmail: null,
        userSettings_lblUserSettings: null,
        userSettings_mailSet: null,
        widgetHeaderComponent_addNew: null,
        widgetHeaderComponent_backtoFirst: null,
        widgetHeaderComponent_highlighter: null,
        widgetHeaderComponent_removeWidget: null,
        widgetHeaderComponent_resetToDefault: null,
        widgetHeaderComponent_toggleExtras: null,
        widgetHeaderComponent_turnOnArrangement: null
    }
};

export default ghostLanguage;