<template>
   <div id="flight-search-results-wrapper">
      <PackageCompTestVue :packageOffers="packageOffers" v-if="false" />

      <AirlineMatrix />

      <!-- <v-tour name="myTour" :steps="steps" v-if="searchResponse?.searchTripType != 1"></v-tour> -->
      <div class="family-prices-toggle-wrapper" v-if="searchResponse?.searchTripType != 1">
         <div class="v-step-1">
            <label>{{ $t("flightSearchResult_lblFamilyPricesToggler") }}</label>
            <b-switch v-model="familyPricesToggle"> </b-switch>
         </div>
      </div>

      <div class="flight-legs-wrapper row gx-2 gx-lg-3">
         <div
            class="flight-leg-wrapper col"
            :class="[searchResponse?.searchTripType == 1 ? 'col-12' : 'col-12 col-md-6 flight-leg-duple-view', 'flight-leg-' + legIndex]"
            data-has-selection="false"
            v-for="(leg, legIndex) in searchResponse.legs"
            :key="legIndex">
            <FlightLeg
               :leg="leg"
               :channels="searchResponse.channels"
               :familyPricesToggle="familyPricesToggle"
               :family-prices="familyPrices"
               :cheapest="!familyPricesToggle ? null : cheapest"
               :trip-type="searchResponse.searchTripType"
               :airports="searchResponse.airports"
               :paxsubtype="searchResponse.paxSubType"
               :currency="searchResponse.currency" />
         </div>
      </div>
   </div>
</template>

<script>
//PackageCompTestVue silinecek

import AirlineMatrix from "@/components/structural/flight/search/AirlineMatrix.vue";
import FlightLeg from "@/components/structural/flight/search/search-results/FlightLeg.vue";
import PackageCompTestVue from "@/pages/PackageCompTest.vue";

export default {
   name: "FlightSearchResults",
   props: {
      searchResponse: Object,
      familyPrices: Object,
      packageOffers: Array,
      cheapest: Object,
   },
   components: {
      FlightLeg,
      AirlineMatrix,
      PackageCompTestVue,
   },
   data() {
      return {
         steps: [
            {
               target: ".v-step-1",
               content: this.$i18n.t("flightSearchResult_tourFamilyPricesToggler"),
               params: {
                  placement: "top",
                  enableScrolling: false,
               },
            },
         ],
         familyPricesToggle: false,
      };
   },

   mounted() {
      const tourCount = parseInt(this.getCookie("tourCount") || "0", 10);

      if (tourCount >= 5) {
         return;
      }

      if (this.$tours["myTour"]?.customOptions) {
         this.$tours["myTour"].customOptions.highlight = true;
         this.$tours["myTour"].customOptions.labels.buttonStop = this.$i18n.t("flightSearchResult_lblOkay");
         this.$tours["myTour"].start();
         this.$tours["myTour"].callbacks.onFinish = () => {
            console.log("Tour ended");
            this.setCookie("tourCount", tourCount + 1, 30);
         };
      }

      if (document.querySelector("#airline-matrix-container")) {
         window.scrollTo(0, document.querySelector("#airline-matrix-container")?.getBoundingClientRect()?.top);
      }
   },

   methods: {
      setCookie(name, value, days) {
         let expires = "";
         if (days) {
            let date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
         }
         document.cookie = name + "=" + value + "; path=/" + expires;
      },

      getCookie(name) {
         let nameEQ = name + "=";
         let ca = document.cookie.split(";");
         for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(nameEQ) === 0) {
               return c.substring(nameEQ.length, c.length);
            }
         }
         return null;
      },
   },
};
</script>

<style lang="scss">
$highlight-color: var(--accent-primary);

@keyframes blinkOutline {
   0%,
   100% {
      outline: 3px solid $highlight-color;
   }
   50% {
      outline: 3px solid transparent;
   }
}
.v-step {
   background: var(--accent-primary) !important;
   background: #363636 !important;
   padding: 1.5rem 3rem !important;
   border-radius: 1.5rem !important;
   max-width: auto !important;
   background-color: #4ea6ff !important;
   outline: 7px solid #4ea6ff2e;
   border: 4px dashed #2488ee;
   font-size: 1.25rem;
}
.v-tour__target--highlighted {
   box-shadow: none !important;
   // outline: 3px solid red;
   animation: blinkOutline 0.5s infinite;
}

.v-step__button {
   font-size: 1.125rem !important;
   font-weight: 500;
   background-color: #2e67b8 !important;
   border-radius: 1rem !important;
   padding: 0.25rem 1rem !important;
   outline: none !important;
   border: none !important;
   line-height: unset !important;
   height: auto !important;

   &:hover {
      background-color: #363636 !important;
      color: #fff !important;
   }
}
</style>

<style lang="scss" scoped>
#flight-search-results-wrapper {
   margin-top: 1rem;
   margin-top: 1rem;

   .family-prices-toggle-wrapper {
      display: flex;
      // background: red;
      justify-content: flex-end;
      margin-bottom: 0.75rem;

      label {
         margin-right: 0.25rem;
         font-weight: 500;
      }
   }

   .flight-legs-wrapper {
      .flight-leg-wrapper {
         &.flight-leg-duple-view {
            ::v-deep .flightcard-container {
               @media (min-width: 768px) {
                  padding: 1rem 0.75rem;
               }

               .flight-infographic-container {
                  // max-width: 260px;
               }
            }
         }
         &.flight-leg-sticky {
            @media (min-width: 576px) {
               position: sticky;
               top: 100px;
               height: max-content;
            }
            @media (min-width: 992px) {
               position: sticky;
               top: 1rem;
               height: max-content;
            }
         }
         .flight-bg {
            position: relative;
            background-color: red;

            @keyframes noFlight {
               from {
                  opacity: 0;
               }
               to {
                  opacity: 1;
               }
            }
            .no-flight-warning {
               padding: 0.5rem;
               opacity: 0;
               position: absolute;
               top: 0px;
               align-items: center;
               justify-content: center;
               display: flex;
               flex-direction: row;
               @keyframes noFlight {
                  from {
                     opacity: 0;
                  }
                  to {
                     opacity: 1;
                  }
               }
               .no-flight-warning {
                  padding: 0.5rem;
                  opacity: 0;
                  position: absolute;
                  top: 0px;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  flex-direction: row;

                  &.warning-active {
                     animation-name: noFlight;
                     animation-timing-function: ease-in-out;
                     animation-duration: 0.25s;
                     opacity: 1;
                     display: flex;
                     background-color: #fff;
                     width: 100%;
                  }
                  &.warning-active {
                     animation-name: noFlight;
                     animation-timing-function: ease-in-out;
                     animation-duration: 0.25s;
                     opacity: 1;
                     display: flex;
                     background-color: #fff;
                     width: 100%;
                  }

                  span {
                     font-size: 2rem;
                     font-weight: 500;
                     color: var(--primary-pastel);
                  }
                  .sad-face {
                     font-weight: 800;
                     margin-right: 0.75rem;
                     // display: nones;
                  }
                  .no-flight-message {
                  }
                  span {
                     font-size: 2rem;
                     font-weight: 500;
                     color: var(--primary-pastel);
                  }
                  .sad-face {
                     font-weight: 800;
                     margin-right: 0.75rem;
                     // display: nones;
                  }
                  .no-flight-message {
                  }
               }
            }
            .flight-card-container {
               background-color: #fff;
               padding: 0.5rem;
               display: grid;
            }
            .flight-card-container {
               background-color: #fff;
               padding: 0.5rem;
               display: grid;

               @media (min-width: 576px) {
                  padding: 1rem;
                  @media (min-width: 576px) {
                     padding: 1rem;
                  }
               }
            }
         }
         #lazy-loader {
            position: fixed;
            bottom: 1.5rem;
            left: 0;
            right: 0;
            margin: auto;
            width: 200px;
            padding: 0.5rem 1rem;
            border-radius: 3rem;
            background-color: #ffffff;
         }
      }
   }
}
</style>
