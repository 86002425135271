import Vue from "vue";

const state = {
   clientInformations: {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      userAgent: window.navigator.userAgent,
      isMobile: window.innerWidth <= 1200,
      isTablet: window.innerWidth > 768 && window.innerWidth < 1000,
   },
   theme: null,
   news: {},
   countryList: [],
   airportList: [],
   airlineList: [],
   gsaList: [],
   agencyList: [],
   roles: [],
   isAsideActive: true,
   errorStatus: {
      isPopupActive: false,
      type: "",
      title: "",
      subTitle: "",
      htmlContent: "",
      bind: "",
   },
   modalStatus: {
      status: false,
      ref: "",
   },
   // notification: {
   //     active: true,
   //     list: {},
   //     count: 0,
   // },
   notification: {
      count: 0,
      item: null,
   },
   startupReferences: {},
   progress: {
      status: false,
      message: null,
   },
   cookie: false,
   splashScreen: true,
   documentViewer: {
      active: false,
      isLoaded: false,
      hasPrice: false,
      activeTab: "MASTER",
      activeProductStatus: 0,
      request: {
         // identifiers: [],
         identifiers: [],
         orderId: null,
         fileType: 2,
         documentType: null,
         orderUuid: null,
         showPrice: false,
         showCancellationPolicy: false,
         showOptionDate: false,
      },
   },
   spaLastVersion: null,
   isEmailErrorPopupTriggered: false,
   loginFlags: {
      isEmailErrorPopupTriggered: false,
      isEmailConfirmed: false,
   },
   reportGirosGrandTotal: null,
   reportGirosDateInfo: null,
   reportUpcomingsDateInfo: null,
   onlyFltWksc: false,
   orderGsaType: null,
};

const mutations = {
   setSplasScreenStatue(state, statue) {
      state, statue;
      // console.log("setSplasScreenStatue"), state, statue;
   },
   setTheme(state, theme) {
      state.theme = theme;
   },
   setNews(state, news) {
      state.news = news;
   },
   setAgencyList(state, list) {
      state.agencyList = list;
   },
   setGsaList(state, list) {
      state.gsaList = list;
   },
   setCountryList(state, list) {
      state.countryList = list;
   },
   setAirportList(state, list) {
      state.airportList = list;
   },
   setAirlineList(state, list) {
      state.airlineList = list;
   },
   setErrorStatus(state, status) {
      state.errorStatus = status;
   },
   setModalStatus(state, status) {
      state.modalStatus = status;
   },
   setStartupReferences(state, status) {
      state.startupReferences = status;
   },
   setIsProgressGifActive(state, status) {
      state.progress.status = status;
   },
   showProgressGifMsg(state, message) {
      state.progress.status = true;
      state.progress.message = message;
   },
   showProgressGif(state) {
      state.progress.status = true;
      state.progress.message = "";
   },

   hideProgressGif(state) {
      state.progress.status = false;
      state.progress.message = "";
   },

   setGirosGrandTotal(state, data) {
      state.reportGirosGrandTotal = data;
   },
   setGirosDate(state, data) {
      state.reportGirosDateInfo = data;
   },
   setUpcomingsDate(state, data) {
      state.reportUpcomingsDateInfo = data;
   },
   setOrderGsaType(state, data) {
      state.orderGsaType = data;
   },
   setOnlyFltWksc(state, data) {
      state.onlyFltWksc = data;
   },
   setDocumentViewer(state, status) {
      state.documentViewer.active = status;
      // status;
      // Vue.set(state.documentViewer, "active", true);
   },
   setDocRequestToInitialState(state) {
      state.documentViewer = {
         active: false,
         isLoaded: false,
         hasPrice: false,
         activeTab: "MASTER",
         activeProductStatus: 0,
         request: {
            identifiers: [],
            orderId: null,
            fileType: 2,
            documentType: null,
            orderUuid: null,
            showPrice: false,
            showCancellationPolicy: false,
            showOptionDate: false,
         },
      };
   },

   removeRequestFromMaster(state) {
      let a = state.documentViewer.request.identifiers;
      const hasMaster = a[a.length - 1].identifier == "MASTER";
      if (hasMaster) {
         state.documentViewer.request.identifiers.pop();
      }
   },

   setDocumentSubIdentifier(state, status) {
      const addMaster = state.documentViewer.request.identifiers.length == status.index;

      if (addMaster) {
         state.documentViewer.request.identifiers.push({
            identifier: "MASTER",
            subIdentifiers: [],
         });
      }

      Vue.set(state.documentViewer.request.identifiers[status.index], "subIdentifiers", status.arr);
   },

   /* SET Notification */

   setNotification(state, notification) {
      state.notification.item = notification;
      if (notification.count != false) {
         state.notification.count++;
      }
   },
   setNotificationCount(state, count) {
      state.notification.count = count;
   },
   setSpaLastVersion(state, spaLastVersion) {
      state.spaLastVersion = spaLastVersion;
   },

   setEmailConfirmed(state, status) {
      state.loginFlags.isEmailConfirmed = status;
   },
   setActiveTabIndex(state, status) {
      state.documentViewer.activeTab = status;
   },
   // setNotificationStatus(state, status) {
   //     state.notification.active = true;
   //     let statue = status;
   //     statue.isActive = true;
   //     Vue.set(state.notification.list, Object.keys(state.notification.list).length + 1, statue);
   //     state.notification.count++;
   // },
   // deleteNotification(state, id) {
   //     Vue.set(state.notification.list[id], "isActive", false);
   // },

   // setNotification(state, notification) {
   //     Vue.set(state.notification.list, notification.id, notification);
   // },
};

const actions = {
   setTheme: function (commit, theme) {
      commit("setTheme", theme);
   },
   setNews: function (commit, news) {
      commit("setNews", news);
   },
   setCountryList: function (commit, countryList) {
      commit("setCountryList", countryList);
   },
   setErrorStatus: function (commit, status) {
      commit("setErrorStatus", status);
   },
   setModalStatus: function (commit, status) {
      commit("setModalStatus", status);
   },
   setStartupReferences: function (commit, status) {
      commit("setStartupReferences", status);
   },
   setIsProgressGifActive: function (commit, status) {
      commit("setIsProgressGifActive", status);
   },
   setSpaLastVersion: function (commit, status) {
      commit("setSpaLastVersion", status);
   },
   setEmailConfirmed: function (commit, status) {
      commit("setEmailConfirmed", status);
   },
   setActiveTabIndex: function (commit, status) {
      commit("setActiveTabIndex", status);
   },
   setGirosGrandTotal: function (commit, data) {
      commit("setGirosGrandTotal", data);
   },
   setGirosDate: function (commit, data) {
      commit("setGirosDate", data);
   },
   setUpcomingsDate: function (commit, data) {
      commit("setUpcomingsDate", data);
   },
   setOrderGsaType: function (commit, data) {
      commit("setOrderGsaType", data);
   },
   setOnlyFltWksc: function (commit, data) {
      commit("setOnlyFltWksc", data);
   },
   // arrangeNotificationList: function (commit, statue) {
   //     commit, statue;
   //     // console.warn("Notification Active::::");

   //     setInterval(arrange, 3000);
   //     const arrange = setInterval(() => {
   //         let a = Object.keys(state.notification.list).filter((key) => {
   //             return state.notification.list[key].isActive;
   //         });
   //         if (a[0] == undefined) {
   //             state.notification.active = false;
   //             clearInterval(arrange);
   //         } else {
   //             Vue.set(state.notification.list[a[0]], "isActive", false);
   //         }
   //     }, 3000);
   // },
};

const getters = {
   getTheme: (state) => state.theme,
   getNews: (state) => state.news,
   getCountryList: (state) => state.countryList,
   getErrorStatus: (state) => state.errorStatus,
   getModalStatus: (state) => state.modalStatus,
   getStartupReferences: (state) => state.startupReferences,
   getIsProgressGifActive: (state) => state.progress.status,
   spaLastVersion: (state) => state.spaLastVersion,
   isEmailErrorPopupTriggered: (state) => state.loginFlags.isEmailErrorPopupTriggered,
   isEmailConfirmed: (state) => state.loginFlags.isEmailConfirmed,
};

export const app = {
   namespaced: true,
   state,
   mutations,
   actions,
   getters,
};
